import UserRepository from "../api/repositories/user/UserRepository";
import { ResultType } from "../api/util/Result";
import config from "../config";
import Constants from "../models/Constants";
import { UserProfile, UserRoleName } from "../models/User";

type Moderation = "Published" | "Unpublished" | "Draft";
export enum PageAuthenticationAccess {
  LOGGED_OUT = "logged_out",
  LOGGED_IN = "logged_in"
}
export interface CollectionLink {
  display: UserProfile | UserRoleName;
  link: "account_profiles" | "roles";
  _id: string;
}

export interface RouteResponse {
  account_profile: CollectionLink;
  moderation: Moderation;
  roles: CollectionLink[];
  slug: string;
  _id: string;
}

export interface RoutesMapping {
  articles: RouteResponse[];
  pages: RouteResponse[];
  brandPages: RouteResponse[];
  eventsPages: RouteResponse[];
}

class RoutingHelper {
  isMoveTokenURL(link: string) {
    try {
      let url = new URL(link);
      let isMoveTokenURL = false;
      config.getMoveTokenWhiteList().forEach((moveTokenUrl: string) => {
        if (url.host.includes(moveTokenUrl)) {
          isMoveTokenURL = true;
        }
      });

      return isMoveTokenURL;
    } catch (e) {
      return false;
    }
  }

  async getMoveToken(url: string) {
    let result = await UserRepository.moveRequest(url);
    if (result.type == ResultType.SUCCESS) {
      return result.value.move_token;
    }
    return false;
  }

  isPublicRoute(route: string) {
    let publicRoutes = config.getPublicRoutes();
    return publicRoutes.includes(route);
  }

  isPrivateRoute(route: string) {
    let privateRoutes = config.getPrivateRoutes();
    return privateRoutes.includes(route);
  }

  getImagePath(path: string) {
    if (path) {
      try {
        let url = new URL(path);
        if (url.protocol) {
          return path;
        }
      } catch (e) {
        if (!path.startsWith("/")) {
          path = "/" + path;
        }
        return Constants.baseUrl + path;
      }
    }

    return "";
  }

  getFilePath(path: string, useMiddleware: boolean = false) {
    if (path) {
      try {
        let url = new URL(path);
        if (url.protocol) {
          return path;
        }
      } catch (e) {
        return useMiddleware
          ? Constants.middlewareStorageUrl + path
          : Constants.uploadsUrl + path;
      }
    }

    return "";
  }
}

export default new RoutingHelper();
