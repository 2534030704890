import { Deserializible } from "../../../util/Helper";

export class Sample extends Deserializible {
  [key: string]: any;
  public availableSamples: number | undefined = undefined;
  public sampleMaxOrderQuantity: number | undefined = undefined;
  public customerCity: string | undefined = undefined;
  public customerFirstName: string | undefined = undefined;
  public customerLastName: string | undefined = undefined;
  public customerNumber: string | undefined = undefined;
  public customerStreet: string | undefined = undefined;
  public customerTitle: string | undefined = undefined;
  public customerZipcode: string | undefined = undefined;
  public sampleName: string | undefined = undefined;

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);
    }
  }
}
