
import { Vue, Component } from "vue-property-decorator";
import { ContentRepository, LayoutComponent, ResultType } from "common";
import BaseComponent from "../../../base/BaseComponent.vue";

@Component
export default class LoginWithDocCheckModalTemplate extends BaseComponent {
  public docCheckLogin!: LayoutComponent;
  public login = LayoutComponent.createLogin();
  public selectedItem: LayoutComponent = this.login;

  constructor() {
    super();
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(async () => {
      // Bring the collection
      let docCheckLoginResponse = await ContentRepository.getCollectionItemByTypeAndId(
        "doc_check_logins",
        [this.docCheckLoginId],
        0
      );

      // Create the LayoutComponent
      if (
        docCheckLoginResponse.type === ResultType.SUCCESS &&
        docCheckLoginResponse.value &&
        docCheckLoginResponse.value.length > 0
      ) {
        this.docCheckLogin = LayoutComponent.createFromCollectionItem(
          "DocCheckLogin",
          docCheckLoginResponse.value[0]
        );
      }
    });
  }

  selectItem(item: LayoutComponent): void {
    if (item._id != this.selectedItem._id) {
      this.selectedItem = item;
    }
  }

  get docCheckLoginIcon() {
    return "/assets/doccheck/doc-check-logo.png";
  }

  get loginIcon() {
    return "/assets/userinfo/login_icon.png";
  }

  get registerLink() {
    return this.getSettingsAttribute("registerLink");
  }

  get docCheckLoginId() {
    return this.getSettingsAttribute("docCheckLoginId");
  }
}
