
import { Component } from "vue-property-decorator";
import { LayoutComponent, Store } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class PopoverWidget extends BaseWidget {
  public shared = Store;

  getComponentType() {
    return "popovers";
  }

  async mounted() {
    super.mounted();
  }

  get label() {
    return this.getSettingsAttribute("label");
  }

  get icon() {
    let icons = this.getSettingsAttribute("icon");
    if (icons && icons.length > 0) {
      return new LayoutComponent(icons[0]);
    }
    return null;
  }

  get popover() {
    const popover = this.getSettingsAttribute("content");
    if (popover) {
      return LayoutComponent.createEmpty("popover", {
        content: {
          _id: popover._id
        },
        placement: "top",
        trigger: "click"
      });
    }
    return null;
  }
}
