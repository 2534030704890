
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NewsletterFormText extends Vue {
  @Prop() formData!: any;
  @Prop() field!: string;
  @Prop() inputClass!: string;
  @Prop() inputType!: string;
}
