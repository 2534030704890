
import { Component } from "vue-property-decorator";
import {
  LayoutComponent,
  router,
  Store,
  LocalStorageSavedRoutes,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../../base/BaseComponent.vue";

@Component
export default class LoginModal extends BaseComponent {
  private routerAfterCloseModal: string = "/";
  private previousRoute!: string | null;
  constructor() {
    super();
    this.previousRoute = localStorage.getItem(
      LocalStorageSavedRoutes.PREVIOUS_ROUTE
    );

    if (this.previousRoute === "/registrieren") {
      this.previousRoute = "/";
    }
  }

  async mounted() {
    if (
      User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) &&
      this.$route.path !== this.routerAfterCloseModal
    ) {
      /**
       * Getting data we need from meta field.
       * JSON structure looks like this:
       * @property enableDocCheck - boolean: Basically decides which template to use: regular or DocCheck
       * @property docCheckLoginCollectionId - string: Id of the DocCheckLogin component - needed to actually render the DocCheck login iframe
       * @property cssClass - string: class applied to modal
       * @property registerLink (optional) - string: slug for register page
       * @property title (optional) - string: Modal title.
       */
      const meta = this.layoutComponent.settings.metaContent;
      const hasDocCheck = meta.enableDocCheck || false;
      const cssClass = meta.modalCss || false;

      this.routerAfterCloseModal = meta.routeAfterCloseModal
        ? meta.routeAfterCloseModal
        : this.previousRoute || "/";

      let loginComp: LayoutComponent;
      if (hasDocCheck) {
        // See if we have link for register button
        const registerLink = meta.registerLink || "";

        // Get the id of the docCheck collection.
        const docCheckLoginId = meta.docCheckLoginCollectionId;

        loginComp = LayoutComponent.createEmpty(
          "LoginWithDocCheckModalTemplate",
          {
            visibility: true,
            docCheckLoginId,
            registerLink
          }
        );
      } else {
        loginComp = LayoutComponent.createEmpty("LoginModalTemplate");
      }

      Store.modal.className = "modal-md modal--login" + ` ${cssClass}`;
      Store.modal.preventHideByAction = true;

      const { title } = meta;
      if (title) {
        Store.modal.title = title;
      }

      Store.modal.onModalClose = () => router.push(this.routerAfterCloseModal);
      Store.modal.setLayoutComponent(loginComp);
      Store.modal.showModal();
    }
  }
}
