
import { Component, Vue } from "vue-property-decorator";
import { ExecutionQueue, router, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Headline extends BaseComponent {
  getComponentType() {
    return "headlines";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      if (router.app.$route.hash == "#" + this.layoutComponent.id) {
        ExecutionQueue.addElement(this.scrollToElement);
      }
    });
  }

  scrollToElement() {
    const appThemeRoot = document.querySelector("#app > div");

    let menuHeight = 0;

    if (appThemeRoot) {
      menuHeight =
        parseInt(
          getComputedStyle(appThemeRoot).getPropertyValue("--menu-height")
        ) || 0;
    }

    let element = this.$refs.headline as HTMLElement;
    let offsetTop =
      element.getBoundingClientRect().top + window.scrollY - menuHeight;
    window.scrollTo(0, offsetTop);
  }

  replaceMarker(text: string) {
    if (text) {
      text = text
        .replace("$title", User.getProperty("title") || "")
        .replace("$vorname", User.getProperty("firstName") || "")
        .replace("$nachname", User.getProperty("lastName") || "")
        .replace("$username", User.getProperty("username") || "");
    }

    return text;
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get type() {
    return this.getSettingsAttribute("type");
  }
}
