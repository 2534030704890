
import { Vue, Component, Prop } from "vue-property-decorator";
import { LayoutComponent } from "common";

@Component
export default class ModelTemplate extends Vue {
  @Prop() content!: any;

  textMaxWords!: number;

  constructor() {
    super();
    this.textMaxWords = -1;
  }

  mounted() {
    this.calculateTextMaxWords();
    window.addEventListener("resize", this.calculateTextMaxWords);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateTextMaxWords);
  }

  get headline() {
    try {
      return new LayoutComponent(this.content[0].content[0]);
    } catch (e) {
      return null;
    }
  }

  get text() {
    try {
      return new LayoutComponent(this.content[0].content[1]);
    } catch (e) {
      return null;
    }
  }

  calculateTextMaxWords() {
    let elementWidth = (this.$refs.template as HTMLElement).offsetWidth;
    let divisor = 15;

    if (elementWidth > 330) {
      divisor = 14;
    }

    if (elementWidth > 360) {
      divisor = 13;
    }

    if (elementWidth > 390) {
      divisor = 12;
    }

    if (elementWidth > 420) {
      divisor = 11;
    }

    if (elementWidth > 450) {
      divisor = 10;
    }

    this.textMaxWords = Math.floor(elementWidth / divisor);
  }
}
