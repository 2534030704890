
import { Component } from "vue-property-decorator";
import isNil from "lodash/isNil";
import { Datebook, User, EventRepository, ResultType } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CalendarInvite extends BaseComponent {
  private datebook!: any;
  public isVeevaRegistered!: boolean;

  getComponentType() {
    return "";
  }

  constructor() {
    super();
    this.datebook = null;
    this.isVeevaRegistered = false;
  }

  async mounted() {
    await super.mounted();

    if (this.start) {
      this.datebook = new Datebook({
        ...(this.title && { title: this.title }),
        ...(this.location && { location: this.location }),
        ...(this.description && { description: this.description }),
        start: this.start,
        ...(this.end && { end: this.end })
      });

      this.setAlarm();
    }

    if (this.veevaId) {
      const response: any = await EventRepository.getVeevaEvent(this.veevaId);

      if (response.type == ResultType.SUCCESS) {
        this.isVeevaRegistered = !!response.value.registered;
      } else {
        this.isVeevaRegistered = false;
      }
    }
  }

  get buttonLabel() {
    return this.getSettingsAttribute("button_label");
  }

  get title() {
    return this.getAttributeOrDefault("title", null);
  }

  get location() {
    return this.getAttributeOrDefault("location", null);
  }

  get description() {
    const result = this.getAttributeOrDefault("description", null);
    return result ? this.replaceMarker(result) : null;
  }

  get start(): Date | null {
    const startDate = this.getAttributeOrDefault("start_date", null);
    const startTime = this.getAttributeOrDefault("start_time", null);

    if (startDate) {
      return startTime
        ? new Date(startDate + "T" + startTime)
        : new Date(startDate);
    }
    return null;
  }

  get end(): Date | null {
    const endDate = this.getAttributeOrDefault("end_date", null);
    const endTime = this.getAttributeOrDefault("end_time", null);

    if (endDate) {
      return endTime ? new Date(endDate + "T" + endTime) : new Date(endDate);
    }
    return null;
  }

  get showSecondaryButton() {
    return this.getSettingsAttribute("show_secondary_button");
  }

  get secondaryButtonLabel() {
    return this.getSettingsAttribute("secondary_button_label");
  }

  get secondaryButtonHref() {
    return this.getAttributeOrDefault("secondary_button_href", "#");
  }

  get veevaId() {
    const id = this.getSettingsAttribute("veeva_id");
    return id && id.trim().length > 0 ? id : null;
  }

  get alarmTime(): Number | null {
    const alarm = this.getSettingsAttribute("set_alarm");
    if (alarm.minutes_before && alarm.minutes_before.trim().length > 0) {
      return parseInt(alarm.minutes_before);
    }
    return 0;
  }

  get alarmDescription(): string | null {
    const alarm = this.getSettingsAttribute("set_alarm");
    if (alarm.description && alarm.description.trim().length > 0) {
      return alarm.description;
    }
    return null;
  }

  clickHandler() {
    this.datebook.download();
  }

  /* Set a reminder that shows before the event at a specific time (minutes before) */
  setAlarm() {
    if (this.alarmTime && this.alarmTime > 0) {
      this.datebook.setAlarm({
        action: "DISPLAY",
        ...(this.alarmDescription && { description: this.alarmDescription }),
        trigger: {
          minutes: this.alarmTime
        }
      });
    }
  }

  getAttributeOrDefault(attributeKey: string, defaultValue: string | null) {
    const result = this.getSettingsAttribute(attributeKey);
    if (!isNil(result) && result.trim().length > 0) {
      return result;
    }
    return defaultValue;
  }

  replaceMarker(text: string) {
    text = text.replace("$loginToken", User.getProperty("loginToken") || "");
    text = text.replace("$salutation", User.getProperty("salutation") || "");
    return text;
  }
}
