
import { Component } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class ExternalLinkWidget extends BaseWidget {
  getComponentType() {
    return "external_link_widgets";
  }

  async mounted() {
    await super.mounted();
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get link() {
    return this.getContentAttribute("link");
  }

  get text() {
    return this.getContentAttribute("text");
  }
}
