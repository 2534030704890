
import { Helper, LayoutComponent } from "common";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BaseContent extends Vue {
  @Prop() parentComponent!: LayoutComponent;
  @Prop() layout!: any;
  @Prop() parentClass?: string;

  getLayoutComponentsFromLayout() {
    let components: LayoutComponent[] = [];

    if (this.layout) {
      this.layout.forEach((el: any) => {
        let lc = new LayoutComponent(el);
        if (lc.visible) {
          components.push(lc);
        }
      });
    }

    return components;
  }

  getColumns(width: string) {
    let colArray: string[] = Helper.getMappedCssClassesAsArray(width);
    return colArray.join(" ");
  }

  getParentCss(width: string) {
    return this.parentClass ? this.parentClass : this.getColumns(width);
  }

  get parentCssWidth() {
    if (this.parentComponent && this.parentComponent.settings.width) {
      return this.parentComponent.settings.width;
    }
    return 12;
  }
}
