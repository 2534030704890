var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.theEvent)?_c('div',{class:['event-speaker-list', _vm.layoutComponent.class],style:(_vm.layoutComponent.style),attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(_vm.speakerCategories.length)?_c('div',{staticClass:"event-speaker-list__header"},[_c('ul',{staticClass:"event-speaker-list__categories"},_vm._l((_vm.speakerCategories),function(speakerCategory,cid){return _c('li',{key:cid,staticClass:"event-speaker-list__category",class:{ active: _vm.selectedCategoryIdx === speakerCategory },style:({ borderColor: _vm.getCategoryColor([speakerCategory]) }),on:{"click":function($event){return _vm.changeCategory(speakerCategory)}}},[_c('span',{staticClass:"event-speaker-list__category-name"},[_vm._v(_vm._s(speakerCategory))]),_c('span',{staticClass:"event-speaker-list__category-icon",style:({ color: _vm.getCategoryColor([speakerCategory]) })},[_c('svg',{staticClass:"bi bi-chevron-down",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"}})])])])}),0)]):_vm._e(),_c('div',{key:_vm.listKey,ref:`eventspeakerlist_${_vm.theEvent.speakers.id}`,class:`event-speaker-list__body row ${_vm.theEvent.speakers.speakersBodyClass}`,attrs:{"id":`speaker-list-${_vm.theEvent.speakers.id}`}},[_vm._l((_vm.speakerList),function(speaker,sid){return _c('div',{key:sid,class:[
        'event-speaker-wrapper',
        speaker.speakerClass,
        _vm.getSpeakerColumns(_vm.theEvent)
      ],attrs:{"id":`speaker-${speaker.speakerId}`,"data-position":sid},on:{"click":function($event){return _vm.toggleSpeakerInfo(speaker, sid)}}},[_c('div',{staticClass:"event-speaker",class:{
          active: _vm.lastKnownSpeakerInfoPosition == sid,
          'position-relative': !_vm.theEvent.speakers.speakerInfoFullWidth
        }},[_c('div',{staticClass:"event-speaker-image",style:({
            borderColor: _vm.getCategoryColor(speaker.speakerCategories)
          })},[_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.getImage(speaker.speakerImage),"alt":"","title":""}})]),_c('div',{staticClass:"event-speaker-text text-center"},[_c('p',{staticClass:"event-speaker-title",domProps:{"innerHTML":_vm._s(speaker.speakerTitle)}}),_c('p',{staticClass:"event-speaker-name",domProps:{"innerHTML":_vm._s(speaker.speakerName)}}),_c('span',{staticClass:"event-speaker-text__icon",style:({
              color: _vm.getCategoryColor(speaker.speakerCategories)
            })})])])])}),(_vm.currentActiveSpeakerName != '' && _vm.currentActiveSpeakerInfo != '')?_c('div',{ref:`eventspeakerinfowrapper_${_vm.theEvent.speakers.id}`,staticClass:"event-speaker-info-wrapper col-12",class:[
        {
          'event-speaker-info-wrapper--absolute': !_vm.theEvent.speakers
            .speakerInfoFullWidth
        },
        _vm.currentActiveInfoClass
      ]},[_c('div',{staticClass:"event-speaker-info",style:({
          borderColor: _vm.getCategoryColor(_vm.currentActiveSpeakerCategories)
        })},[_c('div',{staticClass:"event-speaker-info-name",domProps:{"innerHTML":_vm._s(_vm.currentActiveSpeakerName)}}),_c('div',{staticClass:"event-speaker-info-info",domProps:{"innerHTML":_vm._s(_vm.currentActiveSpeakerInfo)}})])]):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }