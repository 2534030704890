var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login mx-auto",attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType,"data-id":_vm.internalId}},[(!_vm.alreadyLoggedIn)?_c('form',{ref:"form",staticClass:"rounded",on:{"submit":_vm.validate}},[(!_vm.hide_headline || !_vm.hide_subheadline)?_c('div',{staticClass:"login__form-header row"},[(!_vm.hide_headline)?_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.headline))])]):_vm._e(),(!_vm.hide_subheadline)?_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.subheadline))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"login__form-body row"},[_c('div',{staticClass:"col-12",class:{
          'col-md-6': _vm.display_type == _vm.DISPLAY_TYPE_HORIZONTAL
        }},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"login-username"}},[_vm._v(_vm._s(_vm.username))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usernameVal),expression:"usernameVal"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateUsername,
              'is-valid': _vm.attemptSubmit && _vm.validateUsername
            },attrs:{"type":"text","id":"login-username","name":"username","placeholder":_vm.username_hint},domProps:{"value":(_vm.usernameVal)},on:{"input":function($event){if($event.target.composing)return;_vm.usernameVal=$event.target.value}}})])]),_c('div',{staticClass:"col-12",class:{
          'col-md-6': _vm.display_type == _vm.DISPLAY_TYPE_HORIZONTAL
        }},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.password))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordVal),expression:"passwordVal"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePassword,
              'is-valid': _vm.attemptSubmit && _vm.validatePassword
            },attrs:{"type":"password","autocomplete":"off","id":"login-password","name":"password","placeholder":_vm.password_hint},domProps:{"value":(_vm.passwordVal)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordVal=$event.target.value}}}),_c('a',{staticStyle:{"font-size":"smaller"},on:{"click":function($event){$event.preventDefault();return _vm.triggerPasswordReset.apply(null, arguments)}}},[_vm._v(" Passwort vergessen? ")]),(_vm.registerLink)?_c('a',{staticClass:"register-link",staticStyle:{"font-size":"smaller"},attrs:{"href":_vm.registerLink}},[_vm._v(" Registrieren ")]):_vm._e()])])]),(_vm.showError)?_c('div',{staticClass:"login--error"},[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.accountLockedError)}})]):_vm._e(),_c('div',{staticClass:"row login-submit-button-wrapper"},[_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-dark",attrs:{"type":"submit","disabled":_vm.attemptSubmit}},[_vm._v(" "+_vm._s(_vm.login_label)+" ")])]),(_vm.showOTP && _vm.deepLoginTokenIsSet)?_c('div',{staticClass:"col-12 text-center mt-3"},[_vm._m(0),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-start"},[(_vm.otpRequestSent)?_c('div',{staticClass:"alert alert-success rounded-lg"},[_vm._v(" Der Magic Link wurde angefordert. Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen. Bitte prüfen Sie auch Ihren Spam-Ordner. ")]):_vm._e(),_c('button',{staticClass:"btn btn-outline mb-2",class:{
              'order-2 mt-2': _vm.otpRequestSent
            },attrs:{"disabled":_vm.requestOTP},on:{"click":function($event){$event.preventDefault();return _vm.triggerOTPRequest()}}},[(_vm.otpRequestSent && !_vm.requestOTP)?_c('span',[_vm._v(" Magic Link erneut anfordern ")]):(!_vm.requestOTP)?_c('span',[_vm._v("Login mit Magic Link")]):(_vm.requestOTP)?_c('span',[_vm._v("Fordere Magic Link an ...")]):_vm._e()]),(!_vm.otpRequestSent)?_c('small',[_vm._v(" Sie erhalten eine E-Mail mit einem Link, zur einmaligen Anmeldung. "),_c('br'),_vm._v(" Ganz ohne Passwort. ")]):_vm._e()])]):_vm._e()])]):(_vm.user)?_c('div',{staticClass:"already-loggedin p-5 rounded text-center"},[_c('p',[_vm._v(" Sie sind eingeloggt als "+_vm._s(_vm.user.title)+" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('p',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Abmelden")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start w-50 mb-5 mt-2 mx-auto"},[_c('div',{staticClass:"login-or-line"}),_c('div',{staticClass:"mx-3"},[_vm._v("ODER")]),_c('div',{staticClass:"login-or-line"})])
}]

export { render, staticRenderFns }