
import { Component, Vue } from "vue-property-decorator";
import validator from "validator";
import { matomo, MatomoEventNames, ResultType, UserRepository } from "common";

enum ErrorType {
  USERNAME_EXISTS = "USERNAME_EXISTS",
  EMAIL_EXISTS = "EMAIL_EXISTS",
  NEW_PASSWORD_INVALID = "NEW_PASSWORD_INVALID"
}

enum ErrorDescription {
  TOO_SHORT = "TOO_SHORT",
  INSUFFICIENT_UPPERCASE = "INSUFFICIENT_UPPERCASE",
  INSUFFICIENT_SPECIAL = "INSUFFICIENT_SPECIAL",
  INSUFFICIENT_DIGIT = "INSUFFICIENT_DIGIT",
  INSUFFICIENT_LOWERCASE = "INSUFFICIENT_LOWERCASE"
}

@Component
export default class PatientRegisterForm extends Vue {
  public form: any = {
    username: "",
    email: "",
    pw: "",
    pwConfirm: ""
  };

  public attemptSubmit: boolean = false;
  public loading: boolean = false;
  public registerSucceded: boolean = false;
  public showError: boolean = false;
  public errorMessages: Array<string> = [];
  public errorPasswordConfirm: string = "";

  public static errorMessageApi =
    "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";
  public static errorMessage =
    "Leider konnten wir Ihre Anmeldung nicht entgegen nehmen. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";
  public static errorUsernameExists =
    "An account with the specified username already exists.";
  public static errorEmailExists =
    "Ein Account mit der angegebenen E-Mail-Adresse ist bereits vorhanden.";
  public static errorPWTooShort = "Ihr Passwort ist zu kurz.";
  public static errorPWInsUppercase =
    "Ihr Passwort enthält nicht genügend Großbuchstaben.";
  public static errorPWInsSpecial =
    "Ihr Passwort enthält nicht genügend Sonderzeichen.";
  public static errorPWInsLowercase =
    "Ihr Passwort enthält nicht genügend Kleinbuchstaben.";
  public static errorPWInsDigits = "Ihr Passwort hat zu wenige Ziffern.";
  public static registrationSuccessMessage =
    "Vielen Dank für Ihre Registrierung!";
  public registrationSuccessMessage: string =
    PatientRegisterForm.registrationSuccessMessage;

  validateInput(inputName: string): boolean {
    const model = this.form[inputName];

    // Validate password confirm
    if (inputName === "pwConfirm") {
      if (!validator.isEmpty(model) && !validator.equals(this.form.pw, model)) {
        this.errorPasswordConfirm =
          "Hinweis: Das Passwort stimmt nicht überein.";
      } else {
        this.errorPasswordConfirm = "";
      }

      return !validator.isEmpty(model) && validator.equals(this.form.pw, model);
    }

    return (
      !validator.isEmpty(model) &&
      (inputName === "email" ? validator.isEmail(model) : true)
    );
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  getErrorDescription(description: ErrorDescription) {
    switch (description) {
      case ErrorDescription.TOO_SHORT:
        return PatientRegisterForm.errorPWTooShort;
      case ErrorDescription.INSUFFICIENT_UPPERCASE:
        return PatientRegisterForm.errorPWInsUppercase;
      case ErrorDescription.INSUFFICIENT_LOWERCASE:
        return PatientRegisterForm.errorPWInsLowercase;
      case ErrorDescription.INSUFFICIENT_SPECIAL:
        return PatientRegisterForm.errorPWInsSpecial;
      case ErrorDescription.INSUFFICIENT_DIGIT:
        return PatientRegisterForm.errorPWInsDigits;
      default:
        return null;
    }
  }

  getErrorMessage(error_description: any) {
    switch (error_description.error) {
      case ErrorType.USERNAME_EXISTS:
        return PatientRegisterForm.errorUsernameExists;
      case ErrorType.EMAIL_EXISTS:
        return PatientRegisterForm.errorEmailExists;
      case ErrorType.NEW_PASSWORD_INVALID:
        return (
          this.getErrorDescription(error_description.details) ||
          error_description.details
        );
      default:
        return PatientRegisterForm.errorMessage;
    }
  }

  async validate() {
    this.attemptSubmit = true;

    const isValid = Object.keys(this.form).reduce(
      (result: boolean, inputName: string) => {
        return result && this.validateInput(inputName);
      },
      true
    );

    if (isValid) {
      this.errorMessages = [];
      this.loading = true;

      // Matomo tracking
      matomo.genericEvent(
        "Patient_Register",
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.ACCOUNT_SIGNUP_PATIENT
      );

      // Submit registration
      const response: any = await UserRepository.register(
        {
          username: this.form.username,
          email: this.form.email,
          password1: this.form.pw,
          password2: this.form.pwConfirm,
          urlTemplate: window.location.origin + "?activate_account=$token"
        },
        null,
        true
      );

      if (response.type === ResultType.SUCCESS) {
        this.registerSucceded = true;
        this.showError = false;
      } else {
        // Error handling
        switch (response.statusCode) {
          case 500:
            this.errorMessages.push(PatientRegisterForm.errorMessageApi);
            this.showError = true;
            break;
          case 400:
            try {
              const responseItems = response.raw
                ? JSON.parse(response.raw).description
                : [];

              if (responseItems.length) {
                responseItems.forEach((item: any) => {
                  this.errorMessages.push(this.getErrorMessage(item));
                });
              } else {
                this.errorMessages.push(PatientRegisterForm.errorMessage);
              }
            } catch (error) {
              this.errorMessages.push(PatientRegisterForm.errorMessage);
            }

            this.showError = true;
            break;
        }
      }

      // Reset
      this.loading = false;
      this.attemptSubmit = false;
    } else {
      this.focusFirstError();
    }
  }
}
