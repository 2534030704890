
import { Component, Vue, Watch } from "vue-property-decorator";
import { uniq, filter, isNil } from "lodash";
import BaseComponent from "../base/BaseComponent.vue";
import OrderListForm from "./OrderListForm.vue";
import OrderListHeader from "./OrderListHeader.vue";

export enum Step {
  ORDER = 1,
  CONTACT = 2,
  SUCCESS = 3
}

@Component({
  components: {
    OrderListForm,
    OrderListHeader
  }
})
export default class OrderList extends BaseComponent {
  public Step = Step;
  public stepsLayout!: boolean;
  public firstStepHeadline!: string;
  public secondStepHeadline!: string;
  public step!: Step;
  private possibleOrders!: Array<any>;
  public ordered!: Array<any>;
  public groups!: Array<any>;
  public selectedGroupIdx!: number;
  public product!: string;
  public checkboxText!: string;

  getComponentType() {
    return "order_lists";
  }

  constructor() {
    super();
    this.stepsLayout = true;
    this.firstStepHeadline = "1. Auswahl";
    this.secondStepHeadline = "2. Adresseingabe + bestellen";
    this.possibleOrders = [];
    this.product = "n/a";
    this.ordered = [];
    this.groups = [];
    this.selectedGroupIdx = 0;
    this.step = Step.ORDER;
    this.checkboxText =
      "Ich möchte von einem Außendienstmitarbeiter besucht werden.";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      const checkboxText = this.getContentAttribute("custom_checkbox");
      if (!isNil(checkboxText) && checkboxText.trim().length > 0) {
        this.checkboxText = checkboxText;
      }

      // set step headlines
      const firstStepHeadline = this.getContentAttribute("first_step_headline");
      if (!isNil(firstStepHeadline) && firstStepHeadline.trim().length > 0) {
        this.firstStepHeadline = firstStepHeadline;
      }

      const secondStepHeadline = this.getContentAttribute(
        "second_step_headline"
      );
      if (!isNil(secondStepHeadline) && secondStepHeadline.trim().length > 0) {
        this.secondStepHeadline = secondStepHeadline;
      }

      //set layout
      this.stepsLayout = isNil(this.layoutComponent.settings.steps_layout)
        ? true
        : this.layoutComponent.settings.steps_layout;

      //set product
      this.product = this.getContentAttribute("product");
      // Init groups
      const contentItems: Array<any> = this.getContentAttribute("content");
      if (contentItems && contentItems.length > 0) {
        this.groups.push(
          ...uniq(
            filter(
              contentItems.map(
                (contentItem: any) =>
                  contentItem.settings.group &&
                  contentItem.settings.group.trim()
              ),
              (group: any) => group && group.length > 0
            )
          )
        );
      }
    });
  }

  changeGroup(id: number) {
    this.selectedGroupIdx = this.selectedGroupIdx === id ? -1 : id;
  }

  get orderListItems() {
    return this.getContentAttribute("content");
  }

  @Watch("step")
  resetItems() {
    if (this.step === Step.ORDER) {
      this.possibleOrders = [];
      this.ordered = [];
      this.selectedGroupIdx = 0;
    }
  }

  saveValue(idx: any, value: any) {
    this.possibleOrders[idx] = Object.assign(
      {},
      this.possibleOrders[idx],
      value
    );
    this.createCompleteOrder();
  }

  createCompleteOrder() {
    let self = this;
    this.ordered = [];

    this.possibleOrders.forEach((o: any, idx: number) => {
      if (
        (o.language && o.amount) ||
        (!o.language && !o.needed.includes("language") && o.amount) ||
        (!o.amount && !o.needed.includes("amount") && o.language)
      ) {
        self.ordered[idx] = o;
      }
    });
  }

  get cleanOrder() {
    return this.ordered.filter(n => true);
  }

  gotoStep(step: Step) {
    if (step === Step.CONTACT) {
      if (this.cleanOrder.length > 0) {
        this.step = step;
      }
    } else {
      this.step = step;
    }
  }
}
