
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  LayoutComponent,
  matomo,
  ResultType,
  SavedArticleRepository,
  Store,
  User
} from "common";
import BaseWidget from "./BaseWidget.vue";
import $ from "jquery";
import axios from "axios";
import { RoutingHelper } from "common";

@Component
export default class ButtonWidget extends BaseWidget {
  @Prop({ default: null }) pdfFileProp: LayoutComponent | null;

  readonly FUNCTION_SAVE = "save";
  readonly FUNCTION_PRINT = "print";
  readonly FUNCTION_DOWNLOAD = "download";

  public saved: boolean = false;
  private savedArticles: string[] = [];

  public shared = Store;

  getComponentType() {
    return "button_widgets";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(async () => {
      if (!this.isSaveButton) {
        return;
      }

      if (!User.shouldUseLocalStorageToSaveArticles()) {
        await this.populateSavedArticles();
      }

      this.updateSavedStatus();
    });
  }

  async populateSavedArticles() {
    const resultIds = await SavedArticleRepository.get();

    if (resultIds.type !== ResultType.SUCCESS || !resultIds.value) {
      return [];
    }

    this.savedArticles = resultIds.value;
  }

  updateSavedStatus() {
    this.saved = User.shouldUseLocalStorageToSaveArticles()
      ? Store.isArticleSaved()
      : this.savedArticles.includes(Store.activePost);
  }

  updateArticle() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      Store.saveArticle(Store.activePost);
      this.updateSavedStatus();

      return;
    }

    this.saved ? this.deleteSavedArticle() : this.saveArticle();
  }

  saveArticle() {
    SavedArticleRepository.save(Store.activePost).then(() => {
      this.savedArticles.push(Store.activePost);
      this.updateSavedStatus();
    });
  }

  deleteSavedArticle() {
    SavedArticleRepository.delete(Store.activePost).then(() => {
      this.savedArticles = this.savedArticles.filter(
        id => id !== Store.activePost
      );
      this.updateSavedStatus();
    });
  }

  @Watch("shared.savedArticles")
  updateSaved() {
    if (!this.isSaveButton) {
      return;
    }

    this.updateSavedStatus();
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get function() {
    return this.getContentAttribute("function");
  }

  get icon() {
    let icons = this.getContentAttribute("icon");
    if (icons && icons.length > 0) {
      return new LayoutComponent(icons[0]);
    }
    return null;
  }

  get isSaveButton() {
    return this.function == this.FUNCTION_SAVE;
  }

  get isPrintButton() {
    return this.function == this.FUNCTION_PRINT;
  }

  get pdfFile() {
    return this.getSettingsAttribute("custom_pdf");
  }

  handlePrint() {
    const file = this.pdfFile ?? this.pdfFileProp;
    if (file) {
      const url = RoutingHelper.getFilePath(file.path, true);
      window.open(url, "_blank");
    } else window.print();
  }

  async handleDownload() {
    const file = this.pdfFile ?? this.pdfFileProp;
    if (file) {
      const url = RoutingHelper.getFilePath(file.path, true);
      axios({
        url: url,
        method: "GET",
        responseType: "blob"
      }).then((response: any) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", file.title);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }

  clickWidget() {
    switch (this.function) {
      case this.FUNCTION_SAVE:
        matomo.genericEvent(
          Store.activePost,
          matomo.MATOMO_EVENT_CATEGORY,
          "Click_Sidebar_Save_Button"
        );
        this.updateArticle();
        return;
      case this.FUNCTION_PRINT:
        matomo.genericEvent(
          Store.activePost,
          matomo.MATOMO_EVENT_CATEGORY,
          "Click_Sidebar_Print_Button"
        );
        this.handlePrint();
        return;
      case this.FUNCTION_DOWNLOAD:
        matomo.genericEvent(
          Store.activePost,
          matomo.MATOMO_EVENT_CATEGORY,
          "Click_Sidebar_Download_Button"
        );
        this.handleDownload();
        return;
      default:
        break;
    }
  }
}
