
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { LayoutComponent, RoutingHelper } from "common";

@Component
export default class BackgroundBox extends BaseComponent {
  private background_image!: any;
  private marginTop!: any;
  private paddingTop!: any;

  constructor() {
    super();
    this.background_image = null;
    this.marginTop = null;
    this.paddingTop = null;
  }

  getComponentType() {
    return "background_boxes";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    Vue.nextTick(() => {
      this.loadBackgroundImage();
    });
  }

  calculateMarginAndPaddingTop(el: any) {
    Vue.nextTick(() => {
      let floatingHeaderImage = document.querySelector(
        ".floating-header-image"
      ) as HTMLImageElement;
      if (floatingHeaderImage) {
        floatingHeaderImage.onload = () => {
          this.marginTop = floatingHeaderImage.clientHeight * 0.75;
          this.paddingTop = floatingHeaderImage.clientHeight * 0.5;
        };
      }
    });
  }

  get customStyles(): string {
    let styles = [this.layoutComponent.style];

    if (this.background_css) {
      styles.push(`background: ${this.background_css}`);
    }

    if (this.background_color) {
      styles.push(`background-color: ${this.background_color}`);
    }

    if (this.background_image) {
      styles.push(
        `background-image: url(${this.background_image}); background-position: right bottom; background-repeat: no-repeat`
      );
    }

    if (this.marginTop) {
      styles.push(`margin-top: ${this.marginTop}px`);
    }

    if (this.paddingTop) {
      styles.push(`padding-top: ${this.paddingTop}px`);
    }

    return styles.join("; ");
  }

  get background_color() {
    return this.getContentAttribute("background_color");
  }

  get background_css() {
    return this.getContentAttribute("background_css");
  }

  get customClass() {
    let classes = [this.layoutComponent.class];

    let bgColor = this.background_color;
    bgColor = bgColor.replace(/[^\d,]/g, "").split(",");

    if (bgColor.length == 3) {
      if (bgColor[0] * 0.299 + bgColor[1] * 0.587 + bgColor[2] * 0.114 > 186) {
        return classes;
      } else {
        classes.push("white-text-color");
      }
    }
    classes.push();
    return classes.join(" ");
  }

  get cssClasses() {
    return this.layoutComponent.class;
  }

  async loadBackgroundImage() {
    let image = this.getContentAttribute("background_image");
    if (image && image.length > 0) {
      image = image[0];
      let backgroundImage = await this.loadContent(
        image.settings.content.link,
        [image.settings.content._id],
        0
      );

      if (backgroundImage && backgroundImage.length > 0) {
        this.background_image = RoutingHelper.getImagePath(
          backgroundImage[0].image.path
        );
      }
    }
  }

  get floating_header_image() {
    let image = this.getContentAttribute("floating_header_image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get layout() {
    return this.getContentAttribute("content");
  }
}
