
import { Component } from "vue-property-decorator";
import moment from "moment";
import {
  ExpertForumRepository,
  ExpertForumGetThreadResponse,
  ExpertForumThread,
  ExpertForumUser,
  ExpertForumPostType,
  ResultType,
  router,
  Store
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumThreadShow extends BaseComponent {
  public thread!: ExpertForumThread;
  public expert!: ExpertForumUser;
  public patient!: ExpertForumUser;
  public isLoading: boolean;
  public attemptedReply: boolean;
  public token: string | null;
  public expertforumSlug: string;

  constructor() {
    super();

    this.isLoading = true;
    this.attemptedReply = false;
    this.token = sessionStorage.getItem(`expert-forum-${Store.frontend}`);
    this.expertforumSlug = `/${process.env.VUE_APP_EXPERTFORUM_SLUG}/forum`;
  }

  async mounted() {
    super.mounted();
    await this.getThread();
  }

  getName(type: string | null): string {
    switch (type) {
      case ExpertForumPostType.EXPERT:
        return `${this.expert.firstName} ${this.expert.lastName}`;
      case ExpertForumPostType.PATIENT:
        return this.patient.lastName;
      default:
        return "";
    }
  }

  formatDate(date: string | null): string {
    try {
      return moment(date).format("DD. MMMM YYYY hh:mm [Uhr]");
    } catch (e) {
      return "";
    }
  }

  get threadTokenFromQuery(): string {
    const token = (this as any).$route.query.token;

    return token && typeof token === "string" ? token : "";
  }

  get threadIdFromQuery(): string {
    const threadId = (this as any).$route.query.id;

    return threadId && typeof threadId === "string" ? threadId : "";
  }

  async getThread(): Promise<void> {
    if (
      (!this.token && !this.threadTokenFromQuery) ||
      !this.threadIdFromQuery
    ) {
      return this.handleFailureToGetThread();
    }

    const result = await ExpertForumRepository.getThread(
      this.token || this.threadTokenFromQuery,
      this.threadIdFromQuery
    );

    if (result.type !== ResultType.SUCCESS) {
      return this.handleFailureToGetThread();
    }

    if (!result.value || !result.value.success) {
      return this.handleFailureToGetThread();
    }

    const response = result.value as ExpertForumGetThreadResponse;

    this.thread = response.thread;
    this.expert = response.expert;
    this.patient = response.patient;
    this.isLoading = false;
  }

  handleFailureToGetThread(): void {
    console.log("Bad route");
    if (this.token || this.threadTokenFromQuery) {
      router.push("/404");
    } else {
      router.push(`/login`);
    }
  }
}
