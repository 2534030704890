
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GuidelineIcon extends BaseComponent {
  getComponentType() {
    return "guideline_icons";
  }

  async mounted() {
    await super.mounted();
  }

  get icon() {
    let icon = this.getContentAttribute("content");
    if (icon && icon.length > 0) {
      return new LayoutComponent(icon[0]);
    }

    return null;
  }

  get tooltip() {
    return this.getContentAttribute("tooltip");
  }
}
