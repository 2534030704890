export default class Bookmarker {
  listId!: string;
  collectionName!: string;
  collectionTitle!: string;
  foreignId!: string;
  foreignLabel!: string;
  sharingUrl!: string;

  constructor(data: any) {
    this.listId = data.listId;
    this.collectionName = data.collectionName;
    this.collectionTitle = data.collectionTitle;
    this.foreignId = data.foreignId;
    this.foreignLabel = data.foreignLabel;
    this.sharingUrl = data.sharingUrl;
  }

  isValid(): boolean {
    return (
      this.listId != undefined &&
      this.collectionName != undefined &&
      this.foreignId != undefined &&
      this.foreignLabel != undefined &&
      this.sharingUrl != undefined
    );
  }
}
