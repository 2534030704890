
import { Vue, Component, Prop } from "vue-property-decorator";
import { LayoutComponent } from "common";

@Component
export default class BookmarkableArticleWidgetTemplate extends Vue {
  @Prop() content!: any;
  @Prop() articleId!: any;
  @Prop() headline!: any;
  @Prop() text!: any;
  @Prop() sharingUrl!: any;
  @Prop() headlineLoaded!: any;
  @Prop() layoutComponent!: any;
  @Prop() widgetTitle!: any;
  @Prop() widgetShare!: any;
  @Prop() label!: any;
  @Prop() gotoView!: any;

  public componentType =
    this.layoutComponent && this.layoutComponent.component
      ? this.layoutComponent.component
      : null;
  public internalId =
    this.layoutComponent &&
    this.layoutComponent.settings &&
    this.layoutComponent.settings.content &&
    this.layoutComponent.settings.content._id
      ? this.layoutComponent.settings.content._id
      : null;

  get bookmarkIcon() {
    return "/assets/icons/widget-bookmark.svg";
  }

  get bookmarker() {
    return LayoutComponent.createEmpty("bookmarker", {
      collection_title: "Artikel",
      collection: {
        link: "pages",
        _id: this.articleId,
        display: ""
      }
    });
  }
}
