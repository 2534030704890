
import { Component } from "vue-property-decorator";
import {
  GlossaryRepository,
  LayoutComponent,
  ResultType,
  router
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GlossaryPopup extends BaseComponent {
  private glossaryItemKeyName: string = "glossary_item";

  public isActive: boolean;
  public selectedGlossary: object | null;
  private selectedItemId: string | null;
  private destroyPopupDelay: any;
  public glossarySelectedSlug: string | null;

  constructor() {
    super();
    this.isActive = false;
    this.selectedGlossary = null;
    this.selectedItemId = null;
    this.glossarySelectedSlug = null;
    this.glossaryHandleClick();
  }

  getComponentType() {
    return "glossaries";
  }

  async mounted() {
    await super.mounted();
  }

  get glossarySlug() {
    return this.getContentAttribute("slug");
  }

  get placeholderItem() {
    const item = this.getContentAttribute("placeholder_item");

    if (item && item.length > 0) {
      const itemContent = item[0].settings.content;
      return itemContent && itemContent._id
        ? new LayoutComponent(item[0])
        : null;
    }
    return null;
  }

  toggleDefaultPopup() {
    if (this.placeholderItem) {
      if (!this.isActive) {
        this.glossarySelectedSlug = null;
        this.selectedGlossary = this.placeholderItem;
        this.isActive = true;
      } else {
        this.glossarySelectedSlug = null;
        this.selectedGlossary = null;
        this.isActive = false;
      }
    } else {
      router.push(this.glossarySlug);
    }
  }

  generateGlossarySelectedSlug(selectedGlossaryKeyName: any) {
    const pathToGlossary: string = this.getContentAttribute("slug");
    this.glossarySelectedSlug = `${pathToGlossary}#glossary-${selectedGlossaryKeyName}`;
  }

  getSelectedGlossaryComponent(filterKeyName: string) {
    const glossaryCollection: any = this.getContentAttribute("content");

    if (glossaryCollection && glossaryCollection.length > 0) {
      const selected: any = glossaryCollection
        .filter((item: any) => {
          return item.component === this.glossaryItemKeyName;
        })
        .filter((item: any) => {
          if (this.selectedItemId) {
            return item.settings.content._id === this.selectedItemId;
          }
          // to keep the initial functionality without "item_id" prop
          return item.settings.content.display === filterKeyName;
        });

      if (selected && selected[0]) {
        const selectedId = selected[0].settings.content._id;
        clearTimeout(this.destroyPopupDelay);
        this.isActive = true;
        this.selectedGlossary = new LayoutComponent(selected[0]);
        this.generateGlossarySelectedSlug(selectedId);
      }
    }
  }

  async getSelectedItemByKey(id: string) {
    let result = await GlossaryRepository.getItemByKey(id);

    if (result.type == ResultType.SUCCESS && result.value) {
      this.selectedItemId = result.value._id;
    }
  }

  glossaryClose() {
    this.isActive = false;
    this.glossarySelectedSlug = null;

    clearTimeout(this.destroyPopupDelay);
    this.destroyPopupDelay = setTimeout(() => {
      this.selectedGlossary = null;
      this.selectedItemId = null;
    }, 100);
  }

  async glossaryOnClick(event: any) {
    let elementClicked = event.target;

    if (elementClicked && elementClicked.hasAttribute("data-glossary")) {
      let filterKeyName =
        elementClicked.getAttribute("data-glossary") ||
        elementClicked.textContent;
      await this.getSelectedItemByKey(filterKeyName);
      this.getSelectedGlossaryComponent(filterKeyName);
    }
  }

  glossaryHandleClick() {
    document.addEventListener("click", this.glossaryOnClick);
  }

  beforeUnmount() {
    document.removeEventListener("click", this.glossaryOnClick);
  }
}
