import { RoutingHelper } from "..";

export enum AlgoliaMatchLevel {
  NONE = "none",
  PARTIAL = "partial",
  FULL = "full"
}

export enum AlgoliaPageType {
  PAGES = "pages",
  BRAND_PAGES = "brand_pages",
  EVENT_PAGES = "event_pages",
  ARTICLES = "articles"
}

export class AlgoliaHighlightResult {
  public fullyHighlighted: boolean;
  public matchLevel: AlgoliaMatchLevel;
  public matchedWords: string[];
  public value: string;
  public title: string;

  public constructor(
    fullyHighlighted: boolean = false,
    matchLevel: AlgoliaMatchLevel,
    matchedWords: string[],
    value: string,
    title: string
  ) {
    this.fullyHighlighted = fullyHighlighted;
    this.matchLevel = matchLevel;
    this.matchedWords = matchedWords;
    this.title = title;
    this.value = value
      .replace(/<em>/g, "<span class='highlight font-weight-bolder'>")
      .replace(/<\/em>/g, "</span>");
  }

  public static fromJson(json: any, title: string): AlgoliaHighlightResult {
    return new AlgoliaHighlightResult(
      json.fullyHighlighted,
      json.matchLevel,
      json.matchedWords,
      json.value,
      title
    );
  }

  get formattedValue(): string {
    return this.value.substring(0, 300);
  }

  get formattedValueWithoutTitle(): string {
    this.value.replace(this.title, "").substring(0, 300);
    return this.value.replace(this.title, "").substring(0, 300);
  }

  get formattedValueLong(): string {
    return this.value.substring(0, 600);
  }

  get formattedValueLongWithoutTitle(): string {
    if (this.value.indexOf(this.title) === 0) {
      return this.value.substring(this.title.length, 600);
    }

    return this.value.substring(0, 600);
  }
}

export class AlgoliaHit {
  public _highlightResult: AlgoliaHighlightResult;
  public objectId: string;
  public pageType: AlgoliaPageType;
  public text: string;
  public slug: string;
  public link: string;
  public name: string;
  public topics: string[];
  public cancerTypes: string[];
  public contentTypes: string[];
  public siteVisitors: string[];
  public searchImage: string;

  public constructor(
    highlightResult: AlgoliaHighlightResult,
    objectId: string,
    pageType: AlgoliaPageType,
    text: string,
    slug: string,
    link: string,
    name: string,
    topics: string[],
    cancerypes: string[],
    contentTypes: string[],
    siteVisitors: string[],
    searchImage: string | null
  ) {
    this._highlightResult = highlightResult;
    this.objectId = objectId;
    this.pageType = pageType;
    this.text = text;
    this.slug = slug;
    this.link = link;
    this.name = name;
    this.topics = topics;
    this.cancerTypes = cancerypes;
    this.contentTypes = contentTypes;
    this.siteVisitors = siteVisitors;
    this.searchImage = searchImage || "";

    if (this.searchImage.length) {
      this.searchImage = RoutingHelper.getImagePath(this.searchImage);
    }
  }

  public static fromJson(json: any): AlgoliaHit {
    return new AlgoliaHit(
      AlgoliaHighlightResult.fromJson(json._highlightResult.text, json.name),
      json.objectID,
      json.pageType,
      json.text,
      json.slug,
      json.link,
      json.name,
      json.topics,
      json.cancerTypes,
      json.contentTypes,
      json.siteVisitors,
      json.searchImage
    );
  }
}

export class AlgoliaResponse {
  public exhaustiveNbHits: boolean;
  public exhaustiveTypo: boolean;
  public hits: AlgoliaHit[];
  public hitsPerPage: number;
  // Number of hits - results
  public nbHits: number;
  public nbPages: number;
  public empty: boolean;

  public constructor(
    exhaustiveNbHits: boolean,
    exhaustiveTypo: boolean,
    hits: AlgoliaHit[],
    hitsPerPage: number,
    nbHits: number,
    nbPages: number
  ) {
    this.exhaustiveNbHits = exhaustiveNbHits;
    this.exhaustiveTypo = exhaustiveTypo;
    this.hits = hits;
    this.hitsPerPage = hitsPerPage;
    this.nbHits = nbHits;
    this.nbPages = nbPages;
    this.empty = false;
  }

  public static fromJson(json: any): AlgoliaResponse {
    return new AlgoliaResponse(
      json.exhaustiveNbHits,
      json.exhaustiveTypo,
      json.hits.map((hit: any) => AlgoliaHit.fromJson(hit)),
      json.hitsPerPage,
      json.nbHits,
      json.nbPages
    );
  }

  public static empty(): AlgoliaResponse {
    const response = new AlgoliaResponse(false, false, [], 0, 0, 0);
    response.empty = true;

    return response;
  }

  public getFirstHits(amount: number): AlgoliaHit[] {
    return this.hits.slice(0, amount);
  }
}

export interface AlgoliaCategoryItem {
  name: string;
  value: string;
}

export interface AlgoliaCategory {
  name: string;
  value: string;
  items: AlgoliaCategoryItem[];
}
