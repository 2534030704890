var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.contentLoaded && _vm.content && _vm.content.length)?_c('div',{staticClass:"feed",attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[_c('div',{staticClass:"feed__header d-flex flex-wrap align-items-center justify-content-between"},[(_vm.headline)?_c(_vm.headline.component,{tag:"component",staticClass:"mr-3",attrs:{"layoutComponent":_vm.headline}}):_vm._e(),(_vm.text)?_c(_vm.text.component,{tag:"component",attrs:{"layoutComponent":_vm.text}}):_vm._e()],1),_c('div',{staticClass:"feed__body"},[_vm._l((_vm.approvedEntriesData),function(approvedFeedEntry,idx){return _c(approvedFeedEntry.component,{key:'approvedFeedEntry-' + idx,tag:"component",attrs:{"layoutComponent":approvedFeedEntry,"saveSuccessMessage":_vm.saveSuccessMessage}})}),(_vm.showAlert)?_c('div',{staticClass:"mb-5"},[_c('a-alert',{attrs:{"message":_vm.alertMessage,"type":_vm.alertType,"show-icon":"","closable":""},on:{"close":_vm.handleCloseAlert}})],1):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"post_text"}},[_vm._v("Beitrag verfassen")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"form-control",class:{
            'is-invalid': _vm.attemptSubmit && !_vm.validateComment,
            'is-valid': _vm.attemptSubmit && _vm.validateComment
          },attrs:{"id":"post_text","rows":"3"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-md-4 col-form-label",attrs:{"for":"post_author"}},[_vm._v("Meine Initialien:")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.author),expression:"author"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.attemptSubmit && !_vm.validateAuthor,
                      'is-valid': _vm.attemptSubmit && _vm.validateAuthor
                    },attrs:{"type":"text","id":"post_author","placeholder":"Text"},domProps:{"value":(_vm.author)},on:{"input":function($event){if($event.target.composing)return;_vm.author=$event.target.value}}})])])]),_c('div',{staticClass:"col mb-4 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary btn-img",attrs:{"type":"submit"}},[(_vm.loading)?_c('span',{staticClass:"spinner-border",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Beitrag einsenden ")])])])])])])],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }