
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Reference extends BaseComponent {
  getComponentType() {
    return "references";
  }

  async mounted() {
    super.mounted();
  }

  get references() {
    return this.getContentAttribute("references");
  }

  get headline() {
    return this.getContentAttribute("headline");
  }
}
