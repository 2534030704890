
import { Component, Vue } from "vue-property-decorator";
import { isNil, uniqBy, orderBy, sortBy } from "lodash";
import { ContentRepository, LayoutComponent, ResultType } from "common";
import BaseComponent from "../base/BaseComponent.vue";

interface ColumnFilter {
  label: string;
  field: string;
  dataSource: Array<any>;
  value: string | undefined;
}

@Component
export default class LcTable extends BaseComponent {
  private SPLIT_SEPARATOR: string = ",";

  private dataSource!: Array<any>;
  private localColumns!: Array<any>;
  private columnFilters!: Array<ColumnFilter>;
  private activeFilters!: any;
  private rowColorMapper!: any;

  constructor() {
    super();
    this.dataSource = [];
    this.localColumns = [];
    this.columnFilters = [];
    this.activeFilters = null;
    this.rowColorMapper = [];
  }

  getComponentType() {
    return "tables";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.tableData();
    });
  }

  async tableData() {
    if (this.content && this.content.length > 0) {
      const rowsIds: Array<string> = this.content[0].content.map(
        (row: any) => row.settings.content._id
      );
      let rowsResponse: any = await ContentRepository.getCollectionItemByTypeAndId(
        this.content[0].content[0].settings.content.link,
        rowsIds,
        0
      );
      if (rowsResponse.type == ResultType.SUCCESS) {
        if (rowsResponse.value && rowsResponse.value.length > 0) {
          // Sort rows based on initial order
          rowsResponse.value = sortBy(rowsResponse.value, (row: any) => {
            return rowsIds.indexOf(row._id);
          });

          // Map first row to header table
          this.localColumns.push(
            ...rowsResponse.value[0].row.map(
              (header: any, headerIdx: any) =>
                ({
                  key: header.settings.text,
                  dataIndex: header.settings.text,
                  cellValue: header.settings.text,
                  className:
                    header.settings.visibility !== undefined
                      ? header.settings.visibility
                        ? ""
                        : "d-none"
                      : "",
                  filterLabel: isNil(header.settings.filter_label)
                    ? ""
                    : header.settings.filter_label,
                  filterMultipleValues: isNil(
                    header.settings.filter_multiple_values
                  )
                    ? false
                    : header.settings.filter_multiple_values,
                  columnOrder: isNil(header.settings.column_order)
                    ? 0
                    : parseInt(header.settings.column_order, 10),
                  onFilter: (value: any, record: any) => {
                    if (isNil(value)) return true;

                    const filterMultipleValues: boolean = isNil(
                      header.settings.filter_multiple_values
                    )
                      ? false
                      : header.settings.filter_multiple_values;

                    const havePopover: boolean = record[
                      `${header.settings.text}`
                    ].component
                      ? true
                      : false;
                    if (!havePopover) {
                      return filterMultipleValues
                        ? record[`${header.settings.text}`]
                            .toString()
                            .includes(value)
                        : record[`${header.settings.text}`].toString() ===
                            value;
                    } else {
                      return filterMultipleValues
                        ? record[
                            `${header.settings.text}`
                          ].settings.toggle_btn_label
                            .toString()
                            .includes(value)
                        : record[
                            `${header.settings.text}`
                          ].settings.toggle_btn_label.toString() === value;
                    }
                  }
                } as any)
            )
          );

          // Init column filters
          this.columnFilters.push(
            ...this.localColumns
              .filter(column => column.filterLabel.length > 0)
              .map(
                column =>
                  ({
                    field: column.dataIndex,
                    label: column.filterLabel,
                    dataSource: [],
                    value: undefined
                  } as ColumnFilter)
              )
          );

          // Init active filters
          this.activeFilters = this.localColumns
            .filter(column => column.filterLabel.length > 0)
            .reduce((filterObj, column, index) => {
              const currentFilter = filterObj;
              currentFilter[`${column.dataIndex}`] = null;
              return currentFilter;
            }, {});

          // Map next rows to data source
          const tableRows = [];
          for (
            let rowIdx = 1;
            rowIdx < rowsResponse.value.length;
            rowIdx += 1
          ) {
            let currentRow = rowsResponse.value[rowIdx];
            let currentRowData = {
              key: currentRow._id,
              color:
                rowIdx % 2 === 1
                  ? currentRow.color
                  : currentRow.secondary_color,
              class: ""
            };

            for (
              let colIdx = 0;
              colIdx < this.localColumns.length;
              colIdx += 1
            ) {
              let popoverContent =
                currentRow.row[colIdx].settings.popover_content;

              currentRowData = {
                ...currentRowData,
                [`${this.localColumns[colIdx].dataIndex}`]: popoverContent
                  ? LayoutComponent.createFromCollectionItem(
                      "Popover",
                      popoverContent,
                      {
                        trigger: "hover",
                        toggle_btn_label: currentRow.row[colIdx].settings.text
                      }
                    )
                  : currentRow.row[colIdx].settings.text
              };

              // Populate data source for current filter
              let filterIdx = this.columnFilters.findIndex(
                filter => filter.field === this.localColumns[colIdx].dataIndex
              );
              let filterItemsToAdd: Array<string> = this.localColumns[colIdx]
                .filterMultipleValues
                ? [
                    ...currentRow.row[colIdx].settings.text
                      .toString()
                      .split(this.SPLIT_SEPARATOR)
                      .map((value: string) => value.trim())
                      .filter((value: string) => value.length > 0)
                  ]
                : [currentRow.row[colIdx].settings.text.toString()];

              if (filterIdx !== -1) {
                filterItemsToAdd.forEach((filterItem: string) => {
                  if (
                    this.columnFilters[filterIdx].dataSource.findIndex(
                      (item: any) => item === filterItem
                    ) === -1
                  ) {
                    this.columnFilters[filterIdx].dataSource.push(filterItem);
                  }
                });
              }
            }
            tableRows.push(currentRowData);
          }

          this.createRowClassNames(
            uniqBy(tableRows, "color").map(tableRow => tableRow.color)
          );
          this.dataSource.push(...tableRows);
        }
      }
    }

    return;
  }

  get columns() {
    return orderBy(
      this.localColumns.map((column, columnIdx) => ({
        ...column,
        ...(this.activeFilters !== null && {
          filteredValue: [this.activeFilters[`${column.dataIndex}`]]
        }),
        slots: { title: column.dataIndex },
        scopedSlots: { customRender: column.dataIndex }
      })),
      ["columnOrder"]
    );
  }

  handleFilterChange() {
    this.columnFilters.forEach((currentFilter: any, idx: number) => {
      this.activeFilters[`${this.columnFilters[idx].field}`] =
        currentFilter.value === undefined ? null : currentFilter.value;
    });
  }

  get footerImage() {
    const image = this.getContentAttribute("footer_image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get tableHeader() {
    return this.getContentAttribute("table_header");
  }

  getRowClassName(record: any) {
    return `${this.rowColorMapper[record.color]} ${record.class}`;
  }

  createRowClassNames(colors: Array<string>) {
    var style = document.createElement("style");
    style.type = "text/css";
    colors.forEach((color, index) => {
      style.innerHTML += `.ant-table-row-color-${index} > td { background-color: ${color} !important; } `;
      this.rowColorMapper[color] = `ant-table-row-color-${index}`;
    });

    const el = document.getElementById(this.content[0]._id);
    if (el !== null) {
      el.appendChild(style);
    }
  }
}
