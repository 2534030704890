
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CustomImage extends BaseComponent {
  /**
   * @layoutComponent
   * References cockpit type ...
   *
   * @params
   *
   * @children
   * No
   */
  // @Prop() layoutComponent!: LayoutComponent;

  get text() {
    return this.layoutComponent.settings.text
      ? this.layoutComponent.settings.text
      : "";
  }
}
