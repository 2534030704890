
import { Vue, Component, Watch } from "vue-property-decorator";
import validator from "validator";
import isNil from "lodash/isNil";
import html2pdf from "html2pdf.js";
import {
  EventBus,
  CHANNEL_EVENTLIST_CHANGED,
  CHANNEL_EVENTLIST_NAMES_CHANGED,
  CHANNEL_EVENTLIST_SPEAKER_SETTING,
  CHANNEL_EVENTREGISTERSUCCESS_CLOSED,
  CHANNEL_EVENTREGISTRATION_SUCCESS,
  CHANNEL_NEWPASSWORD_TRIGGER_RESET,
  EventRepository,
  LayoutComponent,
  LoginHandler,
  ResultType,
  router,
  Store,
  User,
  UserLoginStatus,
  UserProfile,
  UserRoleName,
  UserRepository
} from "common";
import { BaseComponent } from "vue-ui";

@Component
export default class GOBEventRegistration extends BaseComponent {
  readonly REGISTRATION_TYPE_NORMAL = "registration";
  readonly REGISTRATION_TYPE_SWP = "registration_swp";

  readonly TYPE_CLINICIAN = "CLINICIAN";
  readonly TYPE_ESTABLISHED_MEDICAL = "ESTABLISHED_MEDICAL";
  readonly TYPE_DOCTOR = "DOCTOR";

  readonly GENDER_MALE = "MALE";
  readonly GENDER_FEMALE = "FEMALE";
  readonly GENDER_DIVERSE = "DIVERSE";

  readonly DHG_YES = "JA";
  readonly DHG_NO = "NEIN";

  readonly ACCOMODATION_YES = "JA";
  readonly ACCOMODATION_NO = "NEIN";

  readonly TRAIN = "TRAIN";
  readonly PLAIN = "PLAIN";
  readonly CAR = "CAR";

  readonly VEGETARIAN = "VEGETARIAN";
  readonly VEGAN = "VEGAN";
  readonly ALLERGIES = "ALLERGIES";

  /*
    When the user checked the type "TYPE_DOCTOR",
    in the Self Registration request will be sent:
      type: "OTHER"
      role: "QUALIFIED_PERSONNEL"
  */
  readonly ROLE_QUALIFIED_PERSONNEL = "QUALIFIED_PERSONNEL";

  public selectedEvents: string[];
  public selectedEventsNames: any[];
  public speakerSetting: boolean;

  public attemptSubmit: boolean;
  public registerSucceded: boolean;
  public attemptMMCSelfRegister: boolean;
  public registerWithLogin: boolean;

  public resultMessage!: any;
  public isLoading!: boolean;
  public apiError: boolean;
  public alreadyRegisteredError: boolean;
  public sendingSelfRegistration!: boolean;
  public sendingRegisterMulti!: boolean;
  public registerUserConsent!: boolean;

  public registerType: string;
  public registerGender: string;
  public registerDhg: string;
  public reiseorganisation: boolean;
  public ansprechpartner: string;
  public registerPhoneInfoService: string;
  public registerTitle: string;
  public registerFirstName: string;
  public registerLastName: string;
  public registerPhone: string;
  public registerEmail: string;
  public registerOrt: string;
  public registerStrasse: string;
  public registerEfn: string;
  public registerInstitution: string;
  public registerDepartment: string;
  public registerPraxis: string;
  public registerAccommodation: string;
  public registerAllergies: string;
  public registerFoodOptions: string;
  public registerFoodOptionsAllergies: string;
  public registerPoster: string;
  public registerTransportation: string;
  public registerTransportationAirport: string;
  public registerTransportationTrainStation: string;
  public registerTrainBahnCard: string;
  public registerZip: string;
  public registerCity: string;
  public registerStreet: string;
  public registerStreetNbr: string;
  public registerPassword1: string;
  public registerPassword2: string;
  public registerDataPrivacyStatement: boolean;
  public registerRelatedHcp: string;
  public registerIsProfessional: boolean;

  // show/hide registerRelatedHcp & registerIsProfessional inputs
  public showFachpersonalFields: boolean;

  public errorRegisterEmail: string;
  public errorRegisterPassword1: string;
  public errorRegisterPassword2: string;
  public errorSelectedEvents: string;

  public loginUsername: string;
  public loginPassword: string;
  public loginEfn: string;

  public errorLoginUsername: string;
  public errorLoginPassword: string;

  public infoBoxIsCollapsed: boolean;
  public infoBoxContent: string;

  public institutionPlaceholder: string;
  public departmentPlaceholder: string;

  constructor() {
    super();

    let userProfileData = {
      firstName: User.getProperty("firstName"),
      lastName: User.getProperty("lastName"),
      title: User.getProperty("title"),
      zip: User.getProperty("zip"),
      email: User.getProperty("email")
    };

    this.selectedEvents = [];
    this.selectedEventsNames = [];
    this.speakerSetting = false;

    this.infoBoxIsCollapsed = true;
    this.infoBoxContent = "";

    this.attemptSubmit = false;
    this.registerSucceded = false;
    this.attemptMMCSelfRegister = false;
    this.registerWithLogin = false;
    this.isLoading = false;
    this.resultMessage = null;
    this.apiError = false;
    this.alreadyRegisteredError = false;
    this.sendingSelfRegistration = false;
    this.sendingRegisterMulti = false;
    this.registerUserConsent = false;

    this.registerType = this.TYPE_CLINICIAN;
    this.registerGender = this.GENDER_MALE;
    this.registerDhg = this.DHG_YES;
    this.reiseorganisation = false;
    this.ansprechpartner = "";
    this.registerPhoneInfoService = "";
    this.registerOrt = "";
    this.registerStrasse = "";
    this.registerTitle = userProfileData.title ? userProfileData.title : "";
    this.registerFirstName = userProfileData.firstName
      ? userProfileData.firstName
      : "";
    this.registerLastName = userProfileData.lastName
      ? userProfileData.lastName
      : "";
    this.registerPhone = "";
    this.registerEmail = userProfileData.email ? userProfileData.email : "";
    this.registerEfn = "";
    this.registerInstitution = "";
    this.registerDepartment = "";
    this.registerPraxis = "";
    this.registerAccommodation = "";
    this.registerAllergies = "";
    this.registerFoodOptions = "";
    this.registerFoodOptionsAllergies = "NO";
    this.registerTransportation = "";
    this.registerTransportationAirport = "";
    this.registerTransportationTrainStation = "";
    this.registerTrainBahnCard = "";
    this.registerPoster = "";
    this.registerZip = userProfileData.zip ? userProfileData.zip : "";
    this.registerCity = "";
    this.registerStreet = "";
    this.registerStreetNbr = "";
    this.registerPassword1 = "";
    this.registerPassword2 = "";
    this.registerDataPrivacyStatement = this.userHasAlreadyGivenConsent();
    this.registerRelatedHcp = "";
    this.registerIsProfessional = false;

    this.showFachpersonalFields = false;

    this.errorRegisterEmail = "";
    this.errorRegisterPassword1 = "";
    this.errorRegisterPassword2 = "";
    this.errorSelectedEvents = "";

    this.loginUsername = "";
    this.loginPassword = "";
    this.loginEfn = "";

    this.errorLoginUsername = "";
    this.errorLoginPassword = "";

    this.institutionPlaceholder = "Klinik*";
    this.departmentPlaceholder = "Abteilung*";
  }

  reset() {
    this.selectedEvents = [];
    this.selectedEventsNames = [];
    this.speakerSetting = false;

    this.infoBoxIsCollapsed = true;

    this.attemptSubmit = false;
    this.registerSucceded = false;
    this.attemptMMCSelfRegister = false;
    this.registerWithLogin = false;
    this.isLoading = false;
    this.resultMessage = null;
    this.apiError = false;
    this.alreadyRegisteredError = false;
    this.registerUserConsent = false;

    this.registerType = this.TYPE_CLINICIAN;
    this.registerGender = this.GENDER_MALE;
    this.registerTitle = "";
    this.registerFirstName = "";
    this.registerLastName = "";
    this.registerPhone = "";
    this.registerPhoneInfoService = "";
    this.registerEmail = "";
    this.registerEfn = "";
    this.registerStrasse = "";
    this.registerInstitution = "";
    this.registerDepartment = "";
    this.registerPraxis = "";
    this.registerAccommodation = "";
    this.registerAllergies = "";
    this.registerFoodOptions = "";
    this.registerFoodOptionsAllergies = "NO";
    this.registerTransportation = "";
    this.registerTransportationAirport = "";
    this.registerTransportationTrainStation = "";
    this.registerTrainBahnCard = "";
    this.registerPoster = "";
    this.registerOrt = "";
    this.registerZip = "";
    this.registerCity = "";
    this.registerStreet = "";
    this.registerStreetNbr = "";
    this.registerPassword1 = "";
    this.registerPassword2 = "";
    this.registerDataPrivacyStatement = this.userHasAlreadyGivenConsent();
    this.registerRelatedHcp = "";
    this.registerIsProfessional = false;

    this.showFachpersonalFields = false;

    this.errorRegisterEmail = "";
    this.errorRegisterPassword1 = "";
    this.errorRegisterPassword2 = "";
    this.errorSelectedEvents = "";

    this.loginUsername = "";
    this.loginPassword = "";
    this.loginEfn = "";

    this.errorLoginUsername = "";
    this.errorLoginPassword = "";

    this.institutionPlaceholder = "Klinik*";
    this.departmentPlaceholder = "Abteilung*";
  }

  getComponentType() {
    return "gob_event_registrations";
  }

  async mounted() {
    super.mounted();
    EventBus.$on(CHANNEL_EVENTLIST_CHANGED, (events: string[]) => {
      this.selectedEvents = events;
    });

    EventBus.$on(
      CHANNEL_EVENTLIST_NAMES_CHANGED,
      (events: any[]) => (this.selectedEventsNames = events)
    );

    EventBus.$on(
      CHANNEL_EVENTLIST_SPEAKER_SETTING,
      (speakerSetting: boolean) => {
        this.speakerSetting = speakerSetting;
      }
    );

    EventBus.$on(CHANNEL_NEWPASSWORD_TRIGGER_RESET, this.triggerPasswordReset);
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.setInfoBoxContent();
    });
  }

  async generatePdf() {
    const pdfElement = document.querySelector(
      "#GobEventRegistration"
    ) as HTMLFormElement;

    let pdfElementSize = pdfElement.length;
    if (pdfElementSize > 0) {
      for (let index = 0; index < pdfElementSize; index++) {
        const element = pdfElement[index];
        if (element.classList.contains("is-valid")) {
          element.classList.remove("is-valid");
        }
      }
    }
    pdfElement.style.fontSize = "12px";

    html2pdf()
      .from(pdfElement)
      .set({
        margin: 10,
        filename: "Barcamp 2023.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 72, letterRendering: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
      })
      .outputPdf("blob")
      .then(result => {
        let registerData = {
          email: this.registerEmail,
          dhg: this.registerDhg,
          pdfDocument: result
        };
        EventRepository.sendEventSelfregisterPdf(registerData);
      });
  }

  get isGuest() {
    return (
      User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) ||
      User.hasUserRole(UserRoleName.GUEST)
    );
  }

  userHasAlreadyGivenConsent() {
    return (
      !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) &&
      !User.hasUserProfile(UserProfile.MMC_BASIC)
    );
  }

  triggerPasswordReset() {
    Store.modal.title = "Passwort zurücksetzen";
    Store.modal.className = "modal-md";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("ResetPassword")
    );
    Store.modal.showModal();
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* GETTERS * * * * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * */

  get calendarInvite() {
    return LayoutComponent.createEmpty("CalendarInvite");
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  // BITTE SENDEN SIE IHRE ANFRAGE AB,<br />UM IHRE ANMELDUNG ABZUSCHLIESSEN.
  get headline() {
    return this.getContentAttribute("headline");
  }

  get type_mfa_label() {
    return this.getHeadlineOrDefault("type_mfa_label", "MFA");
  }

  get user_data_headline() {
    return this.getHeadlineOrDefault("user_data_headline", "Persönliche Daten");
  }

  get business_data_headline() {
    return this.getHeadlineOrDefault(
      "business_data_headline",
      "Dienstanschrift"
    );
  }

  get password_headline() {
    return this.getHeadlineOrDefault("password_headline", "Mein Passwort*");
  }

  get login_form_headline() {
    return this.getHeadlineOrDefault(
      "login_form_headline",
      "Mit Ihrem MeinMEDCAMPUS-Account anmelden*"
    );
  }

  // "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf."
  get error_message_api() {
    return this.getContentAttribute("error_message_api");
  }

  // "Leider konnten wir Ihre Anmeldung nicht entgegen nehmen. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";
  get error_message() {
    return this.getContentAttribute("error_message");
  }

  // "Vielen Dank <br/>für Ihre Anmeldung!"
  get success_headline() {
    return this.getContentAttribute("success_headline");
  }

  // NO DEFAULT TEXT
  get success_message() {
    return this.getContentAttribute("success_message");
  }

  // "Vielen Dank <br/>für Ihre Anmeldung!"
  get success_headline_needs_approval() {
    return this.getContentAttribute("success_headline_needs_approval");
  }

  // NO DEFAULT TEXT
  get success_message_needs_approval() {
    return this.getContentAttribute("success_message_needs_approval");
  }

  // "Login fehlgeschlagen. Bitte korrigieren Sie Ihre Eingabe und versuchen Sie es erneut."
  get login_failed_message() {
    return this.getContentAttribute("login_failed_message");
  }

  /*
   * Durch Abschicken dieses Formulars sind Sie automatisch zu den
   * ausgewählten Webinaren von CLL im Dialog angemeldet. Sie
   * erhalten eine Bestätigung per E-Mail.
   */
  get footer_text() {
    return this.getContentAttribute("footer_text");
  }

  /*
   * Ich bin damit einverstanden, dass meine personenbezogenen
   * Daten zum Nutzerverhalten auf der mein-medcampus.de
   * Plattform (z.B. die aufgerufenen Links, besuchte Seite)
   * Anmeldungen zu Veranstaltungen, Nutzung von Services wie
   * eCME oder Cockpit Patienteninformationen) von AstraZeneca
   * individuell ausgewertet werden. Mir ist bewusst, dass diese
   * Auswertung meines Nutzungsverhaltens eine personalisierte
   * und dynamische Ausspielung von maßgeschneiderten Inhalten,
   * die in meinen Interessensgebieten liegen (eingestellte
   * Präferenzen oder abhängig der bisher konsumierten Inhalte)
   * ermöglicht. Weitere Informationen finde ich in der
   * Datenschutzerklärung unter
   * <a
   *   href="https://www.cll-im-dialog.de/datenschutz"
   *   target="_blank">
   *   https://www.cll-im-dialog.de/datenschutz
   * </a>.
   */
  get data_privacy_text() {
    return this.getContentAttribute("data_privacy_text");
  }

  get data_privacy_text2() {
    return this.getContentAttribute("data_privacy_text2");
  }

  // Sie möchten CME-Punkte für Ihre Teilnahme erhalten?
  get data_privacy_headline() {
    return this.getContentAttribute("data_privacy_headline");
  }

  // NO DEFAULT TEXT
  get data_privacy_explanation() {
    return this.getContentAttribute("data_privacy_explanation");
  }

  // NO DEFAULT TEXT
  get data_privacy_text_reinsurance() {
    return this.getContentAttribute("data_privacy_text_reinsurance");
  }

  // Sind Sie sicher, dass Sie keine CME-Punkte erhalten möchten?
  get data_privacy_headline_reinsurance() {
    return this.getContentAttribute("data_privacy_headline_reinsurance");
  }

  // NO DEFAULT TEXT
  get data_privacy_explanation_reinsurance() {
    return this.getContentAttribute("data_privacy_explanation_reinsurance");
  }

  // Ein Account mit der angegebenen E-Mail-Adresse ist bereits vorhanden.
  get error_account_exists() {
    return this.getContentAttribute("error_account_exists");
  }

  // Das Passwort entspricht nicht den Anforderungen. Bitte verwenden Sie ein stärkeres Passwort.
  get error_new_password_invalid() {
    return this.getContentAttribute("error_new_password_invalid");
  }

  // Die ausgewählten Webinare wurden bereits gebucht.
  get error_object_exists() {
    return this.getContentAttribute("error_object_exists");
  }

  /*
   * <b>MeinMEDCAMPUS ist eine Wissensplattform von AstraZeneca.</b>
   * Sie erhalten persönlich auf Sie zugeschnittene...
   */
  get info_box_teaser_content() {
    return this.getHeadlineOrDefault("info_box_teaser_content", "");
  }

  get is_EFN_required() {
    return !!this.getContentAttribute("is_EFN_required");
  }

  get az_costs_text() {
    return this.getContentAttribute("az_costs");
  }

  get code_text() {
    return this.getContentAttribute("code");
  }

  get travel_text() {
    return this.getContentAttribute("travel");
  }

  /*
   * <b>MeinMEDCAMPUS ist eine Wissensplattform von AstraZeneca.</b>
   * Sie erhalten persönlich auf Sie zugeschnittene Informationen
   * passend zu Ihren Interessen und Therapiegebieten. Sie
   * bekommen auf Ihre Bedürfnisse zugeschnittene spannende
   * Podcasts, anschauliche Videos, aktuelle Gesundheitsnews
   * sowie interessante Inhalten zu innovativen E-Health-Themen.
   * Mit Ihrem MeinMEDCAPMUS-Profil finden Sie im
   * Veranstaltungskalender die für Ihre Interessen passenden
   * Veranstaltungen und können sich dort direkt zu interessanten
   * Fortbildungen anmelden. Dieser Service ist für Sie
   * kostenfrei.
   */
  get info_box_content() {
    return this.getHeadlineOrDefault("info_box_content", "");
  }

  get info_box_login_teaser_content() {
    return this.getHeadlineOrDefault(
      "info_box_login_teaser_content",
      this.info_box_teaser_content
    );
  }

  get info_box_login_content() {
    return this.getHeadlineOrDefault(
      "info_box_login_content",
      this.info_box_content
    );
  }

  // Bitte wählen Sie mindestens ein Webinar aus.
  get no_event_selected_error() {
    return this.getContentAttribute("no_event_selected_error");
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* VALUE VALIDATORS  * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * */

  get validateLoginUsername() {
    return !validator.isEmpty(this.loginUsername);
  }

  get validateLoginPassword() {
    return !validator.isEmpty(this.loginPassword);
  }

  get validateLoginEfn() {
    return this.is_EFN_required ? !validator.isEmpty(this.loginEfn) : true;
  }

  get validateRegisterFirstName() {
    return !validator.isEmpty(this.registerFirstName);
  }

  get validateRegisterLastName() {
    return !validator.isEmpty(this.registerLastName);
  }

  get validateRegisterEmail() {
    if (
      !validator.isEmpty(this.registerEmail) &&
      !validator.isEmail(this.registerEmail)
    ) {
      this.errorRegisterEmail =
        "Bitte geben Sie eine gültige E-Mail Adresse an.";
    } else {
      this.errorRegisterEmail = "";
    }

    return (
      !validator.isEmpty(this.registerEmail) &&
      validator.isEmail(this.registerEmail)
    );
  }

  get validateRegisterInstitution() {
    if (this.registerType === this.TYPE_CLINICIAN) {
      this.registerInstitution = this.registerPraxis;
      return !validator.isEmpty(this.registerInstitution);
    }

    return true;
  }

  get validateRegisterDepartment() {
    if (this.registerType === this.TYPE_CLINICIAN) {
      this.registerDepartment = this.registerPraxis;
      return !validator.isEmpty(this.registerDepartment);
    }

    return true;
  }

  get validateRegisterPraxis() {
    return !validator.isEmpty(this.registerPraxis);
  }

  get validateRegisterZip() {
    if (this.registerOrt) {
      let tempInfo = this.registerOrt.split(/[, ]+/);
      this.registerZip = tempInfo[0] ? tempInfo[0] : this.registerOrt;
    }
    return !validator.isEmpty(this.registerZip);
  }

  get validateRegisterOrt() {
    return !validator.isEmpty(this.registerOrt);
  }

  get validateRegisterCity() {
    if (this.registerOrt) {
      let tempInfo = this.registerOrt.split(/[, ]+/);
      this.registerCity = tempInfo[1] ? tempInfo[1] : this.registerOrt;
    }
    return !validator.isEmpty(this.registerCity);
  }

  get validateRegisterStreet() {
    return !validator.isEmpty(this.registerStreet);
  }

  get validateRegisterStreetNbr() {
    this.registerStreetNbr = this.registerStreet;
    return !validator.isEmpty(this.registerStreetNbr);
  }

  get validateRegisterTransportationAirport() {
    return !validator.isEmpty(this.registerTransportationAirport);
  }

  get validateRegisterPassword1() {
    const regex =
      "^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\u0028\\u0029\\u005B\\u005D\\u007B\\u007D\\u003F\\u0021\\u005C\\u0024\\u0025\\u0026\\u002F\\u003D\\u002A\\u002B\\u007E\\u002C\\u002E\\u003B\\u003A\\u003C\\u003E\\u002D\\u005F]).*$";

    if (!validator.matches(this.registerPassword1, regex)) {
      this.errorRegisterPassword1 =
        "Das Passwort entspricht nicht den Anforderungen.";
    } else {
      this.errorRegisterPassword1 = "";
    }

    return (
      !validator.isEmpty(this.registerPassword1) &&
      validator.matches(this.registerPassword1, regex)
    );
  }

  get validateRegisterPassword2() {
    if (!validator.equals(this.registerPassword1, this.registerPassword2)) {
      this.errorRegisterPassword2 = "Passwörter stimmen nicht überein";
    } else {
      this.errorRegisterPassword2 = "";
    }

    return (
      !validator.isEmpty(this.registerPassword2) &&
      validator.equals(this.registerPassword1, this.registerPassword2)
    );
  }

  get validateSelectedEvents() {
    if (this.selectedEvents.length === 0) {
      this.errorSelectedEvents = this.no_event_selected_error;
    } else {
      this.errorSelectedEvents = "";
    }

    return this.selectedEvents.length > 0;
  }

  get validateLoginForm() {
    return (
      this.validateSelectedEvents &&
      this.validateLoginUsername &&
      this.validateLoginPassword
    );
  }

  get validateRegistrationNormal() {
    return (
      this.validateRegisterFirstName &&
      this.validateRegisterLastName &&
      this.validateRegisterEmail &&
      this.validateRegisterInstitution &&
      this.validateRegisterDepartment &&
      this.validateRegisterPraxis &&
      this.validateRegisterZip &&
      this.validateRegisterOrt &&
      this.validateRegisterCity &&
      this.validateRegisterStreet &&
      this.validateRegisterPassword1 &&
      this.validateRegisterPassword2 &&
      this.validateSelectedEvents &&
      this.validateRegisterUserConsent
    );
  }

  get validateRegistrationSWP() {
    return (
      this.validateRegisterFirstName &&
      this.validateRegisterLastName &&
      this.validateRegisterEmail &&
      this.validateRegisterInstitution &&
      this.validateRegisterDepartment &&
      this.validateRegisterPraxis &&
      this.validateRegisterOrt &&
      this.validateRegisterZip &&
      this.validateRegisterCity &&
      this.validateRegisterStreet &&
      this.validateSelectedEvents &&
      this.validateRegisterUserConsent
    );
  }

  get validateRegisterRelatedHcp() {
    return this.showFachpersonalFields
      ? !validator.isEmpty(this.registerRelatedHcp)
      : true;
  }

  get validateRegisterIsProfessional() {
    return this.showFachpersonalFields ? this.registerIsProfessional : true;
  }

  get validateRegisterUserConsent() {
    return this.registerUserConsent ? this.registerUserConsent : false;
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* VALIDATOR FUNCTIONS * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * */

  async validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.isGuest) {
      let guestValidationResult = await this.validateGuest();
    } else {
      let userValidationResult = await this.validateLoggedInUser();
    }
    this.isLoading = false;
  }

  async validateGuest() {
    if (this.registerWithLogin) {
      if (this.validateLoginForm) {
        let result = await UserRepository.login(
          this.loginUsername,
          this.loginPassword
        );

        if (result.type == ResultType.SUCCESS) {
          await LoginHandler.login(result.value, async () => {
            this.registerDataPrivacyStatement = true;
            await this.validateLoggedInUser();
          });
          return;
        } else {
          this.resultMessage = this.login_failed_message;
          this.apiError = true;
        }
      } else {
        this.focusFirstError();
      }
    } else {
      if (this.type !== this.REGISTRATION_TYPE_SWP) {
        if (this.validateRegistrationNormal) {
          // if (!this.registerDataPrivacyStatement) {
          //   this.showEventTrackingOptInModal();
          //   EventBus.$on(
          //     CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED,
          //     async (tracking: boolean) => {
          //       this.registerDataPrivacyStatement = tracking;
          //       this.sendSelfregister();
          //       EventBus.$off(CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED);
          //     }
          //   );
          // } else {
          //   this.sendSelfregister();
          // }
          this.sendSelfregister();
        } else {
          this.focusFirstError();
        }
      } else {
        if (this.validateRegistrationSWP) {
          this.sendSelfregister();
        } else {
          this.focusFirstError();
        }
      }
    }
  }

  async validateLoggedInUser() {
    if (this.validateSelectedEvents && this.validateLoginEfn) {
      // if (!this.registerDataPrivacyStatement) {
      //   this.showEventTrackingOptInModal();
      //   EventBus.$on(
      //     CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED,
      //     async (tracking: boolean) => {
      //       this.registerDataPrivacyStatement = tracking;
      //       await this.updateUserConsentAndRegisterMulti();
      //       EventBus.$off(CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED);
      //     }
      //   );
      // } else {
      //   await this.updateUserConsentAndRegisterMulti();
      // }
      await this.updateUserConsentAndRegisterMulti();
    }
  }

  async updateUserConsentAndRegisterMulti() {
    User.setTrackingConsent(this.registerDataPrivacyStatement);
    let trackingConsentResponse = await UserRepository.updateUser(
      User.asApiUser()
    );
    if (trackingConsentResponse.type == ResultType.SUCCESS) {
      this.sendRegisterMulti();
    }
  }

  async sendSelfregister() {
    if (this.sendingSelfRegistration) {
      return;
    }

    this.sendingSelfRegistration = true;

    let data = {
      eventType: "FACE_TO_FACE",
      veevaEventIds: this.selectedEvents,
      type:
        this.registerType === this.TYPE_DOCTOR ? "OTHER" : this.registerType,
      gender: this.registerGender,
      title: this.registerTitle,
      firstName: this.registerFirstName,
      lastName: this.registerLastName,
      email: this.registerEmail,
      efn: this.registerEfn,
      dhg: this.registerDhg,
      institution: this.registerInstitution,
      department: this.registerDepartment,
      street: this.registerStreet,
      streetNbr: "",
      zip: this.registerZip,
      city: this.registerCity,
      additionalInformation: {
        dhg: this.registerDhg,
        accommodation: this.registerAccommodation,
        transportation: this.registerTransportation,
        transportationAirport: this.registerTransportationAirport,
        transportationTrainStation: this.registerTransportationTrainStation,
        transportationTrainBahnCard: this.registerTrainBahnCard,
        foodOptions: this.registerFoodOptions,
        foodOptionsAllergies: this.registerFoodOptionsAllergies,
        foodAllergies: this.registerAllergies,
        poster: this.registerPoster,
        phoneInfoService: this.registerPhoneInfoService
      },
      accountProfile: this.registerDataPrivacyStatement
        ? UserProfile.MMC_PLUS
        : UserProfile.MMC_BASIC,
      relatedHcp: this.registerRelatedHcp,
      isProfessional: this.registerIsProfessional,
      password1: "",
      password2: "",
      passwordRequired: 0
    };

    if (this.type !== this.REGISTRATION_TYPE_SWP) {
      data.password1 = this.registerPassword1;
      data.password2 = this.registerPassword2;
      data.passwordRequired = 1;
    }

    let result = await EventRepository.sendEventSelfregister(data);
    this.attemptMMCSelfRegister = true;
    this._processResult(result);
    this.sendingSelfRegistration = false;
  }

  async sendRegisterMulti() {
    if (this.sendingRegisterMulti) {
      return;
    }

    this.sendingRegisterMulti = true;

    let data = {
      eventType: "FACE_TO_FACE",
      veevaEventIds: this.selectedEvents,
      efn: this.loginEfn,
      userId: User.getProperty("id"),
      type:
        this.registerType === this.TYPE_DOCTOR ? "OTHER" : this.registerType,
      gender: this.registerGender,
      title: this.registerTitle,
      firstName: this.registerFirstName,
      lastName: this.registerLastName,
      email: this.registerEmail,
      dhg: this.registerDhg,
      institution: this.registerInstitution,
      department: this.registerDepartment,
      street: this.registerStreet,
      streetNbr: "",
      zip: this.registerZip,
      city: this.registerCity,
      additionalInformation: {
        dhg: this.registerDhg,
        accommodation: this.registerAccommodation,
        transportation: this.registerTransportation,
        transportationAirport: this.registerTransportationAirport,
        transportationTrainStation: this.registerTransportationTrainStation,
        transportationTrainBahnCard: this.registerTrainBahnCard,
        foodOptions: this.registerFoodOptions,
        foodOptionsAllergies: this.registerFoodOptionsAllergies,
        foodAllergies: this.registerAllergies,
        poster: this.registerPoster,
        phoneInfoService: this.registerPhoneInfoService
      },
      accountProfile: this.registerDataPrivacyStatement
        ? UserProfile.MMC_PLUS
        : UserProfile.MMC_BASIC,
      relatedHcp: this.registerRelatedHcp,
      isProfessional: this.registerIsProfessional,
      password1: "",
      password2: "",
      passwordRequired: 0
    };

    let result = await EventRepository.sendEventRegisterMulti(data);
    let error = this._processResult(result);

    if (data.veevaEventIds.length > 1 && error && error === "OBJECTEXISTS") {
      this.isLoading = true;
      let errorCount = 0;
      for (let i = 0; i < data.veevaEventIds.length; i++) {
        let tempData = { ...data, veevaEventIds: [data.veevaEventIds[i]] };
        let resultEventRegister = await EventRepository.sendEventRegisterMulti(
          tempData
        );
        let errorEventRegister = this._processResult(resultEventRegister);
        if (errorEventRegister && errorEventRegister === "OBJECTEXISTS") {
          errorCount++;
        }
        this.isLoading = true;
      }
      if (errorCount !== data.veevaEventIds.length) {
        this.resultMessage = "";
        this.registerSucceded = true;
      }
      this.isLoading = false;
    }

    this.sendingRegisterMulti = false;
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* WATCHER * * * * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * */

  @Watch("registerSucceded")
  emitSuccessEvent() {
    EventBus.$emit(CHANNEL_EVENTREGISTRATION_SUCCESS, this.registerSucceded);

    EventBus.$on(CHANNEL_EVENTREGISTERSUCCESS_CLOSED, (closed: boolean) => {
      this.reset();
      EventBus.$off(CHANNEL_EVENTREGISTERSUCCESS_CLOSED);

      if (
        Store.isEvents() &&
        router.currentRoute.path.includes("/haemacovery")
      ) {
        router.push("/haemacovery");
      } else {
        router.push("/");
      }
    });

    if (this.registerSucceded) {
      Store.modal.title = User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)
        ? this.success_headline_needs_approval
        : this.success_headline;
      // Add specific class based on which response is shown
      // - the one for new, self registered user or the one for existing user.
      Store.modal.className = this.attemptMMCSelfRegister
        ? "modal-lg needs-approval"
        : "modal-lg existing-account";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("event-register-success", {
          success_message: User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)
            ? this.success_message_needs_approval
            : this.success_message,
          buttons:
            User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) &&
            Store.isGynonco()
              ? [
                  {
                    label: "HIER GEHT ES ZUM VIDEO",
                    to: "/das-barcamp"
                  }
                ]
              : []
        })
      );
      Store.modal.preventHideByAction = true;
      Store.modal.showModal();
    }
  }

  @Watch("registerType")
  changeRegisterType() {
    this.showFachpersonalFields = false;

    if (this.registerType == this.TYPE_CLINICIAN) {
      this.institutionPlaceholder = "Klinik*";
      this.departmentPlaceholder = "Abteilung*";
      this.registerDhg = this.DHG_YES;
    } else if (this.registerType == this.TYPE_ESTABLISHED_MEDICAL) {
      this.institutionPlaceholder = "Klinik";
      this.departmentPlaceholder = "Abteilung";
      this.registerDhg = this.DHG_NO;
    } else if (this.registerType === this.TYPE_DOCTOR) {
      this.institutionPlaceholder = "Klinik";
      this.departmentPlaceholder = "Abteilung";
      this.showFachpersonalFields = true;
      this.registerDhg = this.DHG_NO;
    }
  }

  @Watch("registerWithLogin")
  resetInfoBoxIsCollapsed() {
    this.infoBoxIsCollapsed = true;
    this.setInfoBoxContent();
  }

  @Watch("infoBoxIsCollapsed")
  updateInfoBoxContent() {
    this.setInfoBoxContent();
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * */
  /* UTILITY STUFF * * * * * * * * * * * * * * * * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * * * * */

  switchForm(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.registerWithLogin = !this.registerWithLogin;
  }

  toogleInfoBox(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.infoBoxIsCollapsed = !this.infoBoxIsCollapsed;
  }

  setInfoBoxContent() {
    if (this.infoBoxIsCollapsed) {
      this.infoBoxContent = this.registerWithLogin
        ? this.info_box_login_teaser_content
        : this.info_box_teaser_content;
    } else {
      this.infoBoxContent = this.registerWithLogin
        ? this.info_box_login_content
        : this.info_box_content;
    }
  }

  getIsAlreadyRegistered(result: any) {
    if (result.type !== ResultType.SUCCESS) {
      return false;
    }

    if (
      !result.value ||
      !Array.isArray(result.value) ||
      result.value.length === 0
    ) {
      return false;
    }

    return result.value.filter((e: any) => !!e.alreadyRegistered).length > 0;
  }

  getAlreadyRegisteredMessage(result: any) {
    if (result.value.length === 1) {
      return "Es besteht schon eine Registrierung für das Event.";
    }

    const messages = [
      "Es besteht schon eine Registrierung für das Veranstaltungen:"
    ];

    result.value
      .filter((e: any) => !!e.alreadyRegistered)
      .map((e: any) =>
        this.selectedEventsNames.find(
          (se: any) => se.veevaId === e.veevaEventId
        )
      )
      .forEach((e: any) => messages.push(e.title));

    const successfulRegistered = result.value.filter(
      (e: any) => !e.alreadyRegistered
    );

    if (successfulRegistered.length) {
      messages.push("<br/>Sie wurden erfolgreich registriert:");

      successfulRegistered
        .map((e: any) =>
          this.selectedEventsNames.find(se => se.veevaId === e.veevaEventId)
        )
        .forEach((e: any) => messages.push(e.title));
    }

    return messages.join("<br/>");
  }

  _processResult(result: any) {
    if (this.getIsAlreadyRegistered(result)) {
      this.resultMessage = this.getAlreadyRegisteredMessage(result);
      this.registerSucceded = false;
      this.apiError = true;
      this.alreadyRegisteredError = true;
    } else if (result.type == ResultType.SUCCESS) {
      this.generatePdf();
      this.resultMessage = "";
      this.registerSucceded = true;
    } else {
      this.attemptMMCSelfRegister = false;
      switch (result.statusCode) {
        case 500:
          this.resultMessage = this.error_message_api;
          this.apiError = true;
          break;
        case 400:
          try {
            this.resultMessage = result.raw
              ? this._getErrorMessage(JSON.parse(result.raw).description[0])
              : this.error_message;

            if (result.raw) {
              this.apiError = true;
              this.isLoading = false;
              return JSON.parse(result.raw).description[0].error;
            }
          } catch (error) {
            this.resultMessage = this.error_message;
          }

          this.apiError = true;
          break;
      }
    }
    this.isLoading = false;
  }

  _getErrorMessage(error_description: any) {
    switch (error_description.error) {
      case "HCP_ACCOUNT_EXISTS":
        return this.error_account_exists;

      case "HCP_NEW_PASSWORD_INVALID":
        return this.error_new_password_invalid;

      case "OBJECTEXISTS":
        return this.error_object_exists;

      default:
        return this.error_message;
    }
  }

  // showEventTrackingOptInModal() {
  //   Store.modal.title = this.data_privacy_headline_reinsurance;
  //   Store.modal.setLayoutComponent(
  //     LayoutComponent.createEmpty("event-tracking-opt-in", {
  //       data_privacy_text_reinsurance: this.data_privacy_text_reinsurance,
  //       data_privacy_explanation_reinsurance: this
  //         .data_privacy_explanation_reinsurance,
  //       register_data_privacy_statement: this.registerDataPrivacyStatement
  //     })
  //   );
  //   Store.modal.showModal();
  // }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  getHeadlineOrDefault(attributeKey: string, defaultValue: string) {
    const headline = this.getContentAttribute(attributeKey);
    if (!isNil(headline) && headline.trim().length > 0) {
      return headline;
    }
    return defaultValue;
  }
}
