
import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import {
  ContactContext,
  ContactRepository,
  LayoutComponent,
  ResultType,
  User
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import { InformationTypeValue } from "./inputs/InformationTypeCheckbox.vue";
import { CongressValues } from "./inputs/SelectCongress.vue";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

enum FormType {
  Tagrisso = "tagrisso",
  Imfinzi = "imfinzi",
  Medical = "medical",
  Calquence = "calquence",
  Lynparza = "lynparza",
  Lokelma = "lokelma",
  Fasttrack = "fasttrack",
  Saphnelo = "saphnelo_contact",
  Covid19DA = "covid19da_contact",
  MeinAZ = "meinaz"
}

enum AlertType {
  SUCCESS = "success",
  ERROR = "error"
}

export interface InputConfig<T> {
  visible: boolean;
  required: boolean;
  cssClass: string;
  model: T;
}

class FormConfig {
  public name: InputConfig<string>;
  public email: InputConfig<string>;
  public phone: InputConfig<string>;
  public address: InputConfig<string>;
  public question: InputConfig<string>;
  public indication: InputConfig<string>;
  public contactByPhone: InputConfig<boolean>;
  public contactByEmail: InputConfig<boolean>;
  public contactByPost: InputConfig<boolean>;
  public appointment: InputConfig<boolean>;
  public privacyConsent: InputConfig<boolean>;
  public informationTypeCheckbox: InputConfig<InformationTypeValue>;
  public congress: InputConfig<CongressValues>;
  [key: string]: InputConfig<string | boolean> | any;

  constructor() {
    this.name = {
      visible: true,
      required: true,
      cssClass: "col-12",
      model: ""
    };
    this.email = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: ""
    };
    this.phone = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: ""
    };
    this.address = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: ""
    };
    this.question = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: ""
    };
    this.contactByPhone = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: false
    };
    this.contactByEmail = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: false
    };
    this.contactByPost = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: false
    };
    this.appointment = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: false
    };
    this.privacyConsent = {
      visible: true,
      required: true,
      cssClass: "col-12",
      model: false
    };
    this.informationTypeCheckbox = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: {
        forxiga: false,
        xigduo: false,
        lokelma: false,
        brilique: false
      }
    };
    this.congress = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: {
        ACCPast: false,
        EDTAPast: false,
        ADAPast: false,
        ESCPast: false,
        ESC: false,
        EASD: false,
        ASN: false,
        AHA: false
      }
    };
    this.indication = {
      visible: false,
      required: false,
      cssClass: "col-12",
      model: ""
    };
  }

  public resetModels() {
    this.name.model = "";
    this.email.model = "";
    this.phone.model = "";
    this.address.model = "";
    this.question.model = "";
    this.indication.model = "";
    this.contactByPhone.model = false;
    this.contactByEmail.model = false;
    this.appointment.model = false;
    this.contactByPost.model = false;
    this.privacyConsent.model = false;
    this.informationTypeCheckbox.model = {
      forxiga: false,
      xigduo: false,
      lokelma: false,
      brilique: false
    };
    this.congress.model = {
      ACCPast: false,
      EDTAPast: false,
      ADAPast: false,
      ESCPast: false,
      ESC: false,
      EASD: false,
      ASN: false,
      AHA: false
    };
  }
}

@Component
export default class ContactForm extends BaseComponent {
  FormType = FormType;
  attemptSubmit: Boolean = false;
  submitSuccessfull: Boolean = false;
  alreadyLoggedIn: Boolean = false;
  loading: boolean = false;
  AlertType = AlertType;
  alertType: string = "";
  showAlert: boolean = false;
  alertMessage: string = "";

  // Form default configuration
  formConfig = new FormConfig();

  @Watch("formConfig", { deep: true })
  onFormConfigChanged() {
    if (this.shouldReplacePlaceholdersWithLabels) {
      this.replacePlaceholdersWithLabels();
    }
  }

  constructor() {
    super();
    this.customLayoutComponent = LayoutComponent.createContactForm();
  }

  handleAlertMessageClose() {
    this.formConfig.resetModels();
    this.showAlert = false;
  }

  getComponentType() {
    return "contact_forms";
  }

  async mounted() {
    super.mounted();
    if (User.getProperty("accessToken")) {
      this.alreadyLoggedIn = true;
    }
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(async () => {
      this.setFormConfig();
    });
  }

  get responseType() {
    return this.getContentAttribute("type");
  }

  get sendSuccessMessage() {
    return this.getContentAttribute("send_success_message");
  }

  get user() {
    return User.toJson();
  }

  get name() {
    if (this.responseType === FormType.MeinAZ) {
      return {
        label: "Name und Vorname",
        placeholder: ""
      };
    }

    return this.getSettingsAttribute("name", true);
  }

  get email() {
    if (this.responseType === FormType.MeinAZ) {
      return {
        label: "E-Mail-Adresse",
        placeholder: ""
      };
    }

    return this.getSettingsAttribute("email", true);
  }

  get phone() {
    if (this.responseType === FormType.MeinAZ) {
      return {
        label: "Telefonnummer",
        placeholder: ""
      };
    }

    return this.getSettingsAttribute("phone", true);
  }

  get address() {
    return this.getSettingsAttribute("address", true);
  }

  get question() {
    return this.getSettingsAttribute("question", true);
  }

  get sectionTitle() {
    return this.getSettingsAttribute("sectionTitle", true);
  }

  get phoneCheck() {
    return this.getSettingsAttribute("phoneCheck", true);
  }

  get emailCheck() {
    return this.getSettingsAttribute("emailCheck", true);
  }

  get appointmentCheck() {
    return this.getSettingsAttribute("appointmentCheck", true);
  }

  get postCheck() {
    return this.getSettingsAttribute("postCheck", true);
  }

  get privacyCheck() {
    const label = this.getContentAttribute("read_privacy_label");

    if (this.responseType === FormType.MeinAZ) {
      return '<p>Hiermit erkl&auml;re ich mich bis zu meinem Widerruf damit einverstanden, dass AstraZeneca die von mir bereitgestellten Daten zum Zwecke des Erhalts von Informationen und Angeboten von AstraZeneca per angegebener Kontaktpr&auml;ferenz verwenden darf. <a href="#">Weitere Informationen zur Verarbeitung Ihrer Daten erhalten Sie hier</a>.</p><p>Sie k&ouml;nnen Ihr Einverst&auml;ndnis ohne für Sie nachteilige Folgen verweigern bzw. jederzeit mit Wirkung für die Zukunft widerrufen. Ihre Widerrufserkl&auml;rung richten Sie bitte an: AstraZeneca GmbH, Friesenweg 26, 22763 Hamburg; E-Mail: <a href="mailto:az-patienten@astrazeneca.com">az-patienten@astrazeneca.com</a>.</p>';
    }

    if (label && label.trim().length > 0) {
      return label;
    }
    return this.getSettingsAttribute("privacyCheck", true);
  }

  get submitBtnText() {
    return this.getContentAttribute("submit_btn_text") || "Senden";
  }

  get informationTypeCheckbox() {
    return this.getSettingsAttribute("informationTypeCheckbox", true);
  }

  get congress() {
    return this.getSettingsAttribute("congress", true);
  }

  get indication() {
    return this.getSettingsAttribute("indication", true);
  }

  get slug() {
    return location.pathname.substr(1);
  }

  setFormConfig() {
    const showFields = this.getContentAttribute("show_fields");

    if (showFields && showFields.length > 0) {
      showFields.forEach(
        ({
          value
        }: {
          value: { field: string; required: string; cssClass: string };
        }): void => {
          const { field, required, cssClass } = value;
          this.formConfig[field] = {
            ...this.formConfig[field],
            required: required === "true",
            cssClass,
            visible: true
          };
        }
      );
    }
  }

  replacePlaceholdersWithLabels() {
    const elements = Object.keys(this.formConfig)
      .map(key => ({
        ...this.formConfig[key],
        label: window.document.querySelector(`label[for="${key}"]`),
        key: key
      }))
      .filter(el => !!el.label);

    elements
      .filter(element => !element.model)
      .forEach(element => {
        element.label.classList.remove("d-none");
      });

    elements
      .filter(element => !!element.model)
      .forEach(element => {
        element.label.classList.add("d-none");
      });
  }

  get shouldReplacePlaceholdersWithLabels() {
    switch (this.responseType) {
      case FormType.Covid19DA:
        return true;
      default:
        return false;
    }
  }

  get requiredText() {
    switch (this.responseType) {
      case FormType.Covid19DA:
        return "Mit <span>*</span> markierte Felder sind Pflichtfelder";
      default:
        return "Pflichtfelder*";
    }
  }

  get settings() {
    switch (this.responseType) {
      case FormType.Imfinzi:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone-rouge.svg",
            mail: "/assets/brand/contact-form/mail-rouge.svg",
            calendar: "/assets/brand/contact-form/calendar-rouge.svg",
            post: "/assets/brand/contact-form/post-rouge.svg"
          },
          buttonClass: "btn-tertiary"
        };
      case FormType.Medical:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone-gold.svg",
            mail: "/assets/brand/contact-form/mail-gold.svg",
            calendar: "/assets/brand/contact-form/calendar-gold.svg",
            post: "/assets/brand/contact-form/post-gold.svg"
          },
          buttonClass: "btn-secondary"
        };
      case FormType.Calquence:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone-yellow.svg",
            mail: "/assets/brand/contact-form/mail-yellow.svg",
            calendar: "/assets/brand/contact-form/calendar-yellow.svg",
            post: "/assets/brand/contact-form/post-yellow.svg"
          },
          buttonClass: "btn-quinary"
        };
      case FormType.Lynparza:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone-blue.svg",
            mail: "/assets/brand/contact-form/mail-blue.svg",
            calendar: "/assets/brand/contact-form/calendar-blue.svg",
            post: "/assets/brand/contact-form/post-blue.svg"
          },
          buttonClass: "btn-senary"
        };
      case FormType.Tagrisso:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone.svg",
            mail: "/assets/brand/contact-form/mail.svg",
            calendar: "/assets/brand/contact-form/calendar.svg",
            post: "/assets/brand/contact-form/post.svg"
          },
          buttonClass: "btn-grape"
        };
      case FormType.Lokelma:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone-green.svg",
            mail: "/assets/brand/contact-form/mail-green.svg"
          },
          buttonClass: "btn-secondary"
        };
      default:
        return {
          icons: {
            phone: "/assets/brand/contact-form/phone.svg",
            mail: "/assets/brand/contact-form/mail.svg",
            calendar: "/assets/brand/contact-form/calendar.svg",
            post: "/assets/brand/contact-form/post.svg"
          },
          buttonClass: "btn-primary"
        };
    }
  }

  /************************************/
  // Business logic

  validateInput(inputName: string): boolean {
    const { required, visible, model } = this.formConfig[inputName];

    if (!visible || !required) {
      return true;
    }

    if (typeof model === "boolean") {
      return model;
    }

    // Validate only if we have at least one value checked.
    if (
      (inputName === "informationTypeCheckbox" || inputName === "congress") &&
      required
    ) {
      const checkboxes = Object.keys(model);
      return !!checkboxes.find(checkbox => model[checkbox]);
    }

    return (
      !validator.isEmpty(model) &&
      (inputName === "email" ? validator.isEmail(model) : true)
    );
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  async validate(e: any) {
    this.attemptSubmit = true;
    this.showAlert = false;

    const isValid = Object.keys(this.formConfig).reduce(
      (result: boolean, inputName: string) => {
        return result && this.validateInput(inputName);
      },
      true
    );

    if (isValid) {
      this.loading = true;
      const additionalCheckboxes = {
        ...(this.formConfig.informationTypeCheckbox.visible
          ? this.formConfig.informationTypeCheckbox.model
          : {}),
        ...(this.formConfig.congress.visible
          ? this.formConfig.congress.model
          : {})
      };

      const contactResponse: any = await ContactRepository.save(
        this.responseType as ContactContext,
        this.slug,
        omitBy(
          {
            recall: this.formConfig.contactByPhone.visible
              ? this.formConfig.contactByPhone.model
              : undefined,
            contact_via_email: this.formConfig.contactByEmail.visible
              ? this.formConfig.contactByEmail.model
              : undefined,
            arrange_visit: this.formConfig.appointment.visible
              ? this.formConfig.appointment.model
              : undefined,
            send_infos: this.formConfig.contactByPost.visible
              ? this.formConfig.contactByPost.model
              : undefined,
            read_privacy: this.formConfig.privacyConsent.visible
              ? this.formConfig.privacyConsent.model
              : undefined,
            question: !isEmpty(additionalCheckboxes)
              ? additionalCheckboxes
              : undefined
          },
          (v: any) => v === undefined
        ),
        omitBy(
          {
            name: this.formConfig.name.visible
              ? this.formConfig.name.model
              : undefined,
            email: this.formConfig.email.visible
              ? this.formConfig.email.model
              : undefined,
            phone: this.formConfig.phone.visible
              ? this.formConfig.phone.model
              : undefined,
            address: this.formConfig.address.visible
              ? this.formConfig.address.model
              : undefined,
            indication: this.formConfig.indication.visible
              ? this.formConfig.indication.model
              : undefined,
            question: this.formConfig.question.visible
              ? this.formConfig.question.model
              : undefined
          },
          (v: any) => v === undefined
        )
      );

      // Show notifications
      if (contactResponse.type === ResultType.SUCCESS) {
        this.alertMessage = this.sendSuccessMessage;
        this.showAlert = true;
        this.alertType = AlertType.SUCCESS;
      }

      this.loading = false;

      // Reset model
      this.attemptSubmit = false;
      this.formConfig.resetModels();
    } else {
      this.focusFirstError();
    }
  }
}
