
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  BookmarkList,
  BookmarkModel,
  BookmarkRepository,
  ContentRepository,
  ResultType,
  Store,
  User
} from "common";

export enum BookmarkTemplate {
  TEMPLATE_ARTICLE,
  TEMPLATE_3DMODEL,
  TEMPLATE_INFORMATIONMATERIAL,
  TEMPLATE_INFORMATIONPORTAL,
  TEMPLATE_VIDEO
}

@Component
export default class Bookmark extends Vue {
  @Prop({}) bookmark!: BookmarkModel;

  content!: any;

  constructor() {
    super();
    this.content = [];
  }

  mounted() {
    this.loadContent();
  }

  get bookmarkIcon() {
    return "/assets/bookmarker/selected.svg";
  }

  get bookmarkTemplate() {
    return BookmarkTemplate;
  }

  async deleteBookmark() {
    let result = await BookmarkRepository.delete(this.bookmark.id);
    if (result.type == ResultType.SUCCESS) {
      BookmarkList.delete(this.bookmark.id);
    }
  }

  async loadContent() {
    let result = null;

    if (this.template == BookmarkTemplate.TEMPLATE_ARTICLE) {
      result = await ContentRepository.getContentById(
        this.bookmark.foreignId,
        Store.appBrand,
        this.bookmark.collectionName
      );
    } else {
      result = await ContentRepository.getCollectionItemByTypeAndId(
        this.bookmark.collectionName,
        [this.bookmark.foreignId],
        0
      );
    }

    if (result && result.type == ResultType.SUCCESS) {
      // @ts-ignore
      this.content = result.value;
    }
  }

  get sharingWidget() {
    try {
      if (Array.isArray(this.content)) {
        return this.content[0].sharing_widget[0];
      }
      return this.content.sharing_widget[0];
    } catch (e) {
      return null;
    }
  }

  get template() {
    switch (this.bookmark.collectionName) {
      case "pages":
        return BookmarkTemplate.TEMPLATE_ARTICLE;
      case "youtube_videos":
        return BookmarkTemplate.TEMPLATE_VIDEO;
      case "cancer_stages":
      case "cancer_types":
        return BookmarkTemplate.TEMPLATE_3DMODEL;
      case "information_portals":
        return BookmarkTemplate.TEMPLATE_INFORMATIONPORTAL;
      case "information_materials":
        return BookmarkTemplate.TEMPLATE_INFORMATIONMATERIAL;
      default:
        return "";
    }
  }

  get user() {
    return User;
  }
}
