
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import {
  LayoutComponent,
  matomo,
  MatomoEventNames,
  QuizRepository,
  ResultType
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum View {
  INFORMATION,
  INTRODUCTION,
  QUIZ,
  THANKYOU
}

@Component
export default class LcQuiz extends BaseComponent {
  public View = View;
  // Quiz state
  public view: View = View.INFORMATION;
  public noQuestions: number = 0;
  public currentQuestionIdx: number = 0;
  private quizParticipants: number = 0;
  private quizUpdateInterval: any = undefined;
  private quizUpdateFrequency: number = 60000; // milliseconds
  public quizExpired: boolean = false;
  public loading: boolean = false;
  // Current question state
  public selectedAnswerIdx: number[] = [];
  public correctAnswerIdx: number[] = [];
  public showMessage: boolean = false;
  // Form model state
  public attemptSubmit: boolean = false;
  // Countdown state
  private counterDateFormat: string = "YYYY-MM-DD HH:mm:ss";
  private counterValue: any = null;
  private counterInterval: any = undefined;
  private amountOfWrongAnswers: number = 0;

  getComponentType() {
    return "quizzes";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.view = this.initialView;

      this.counterInterval = setInterval(() => {
        if (!moment().isBefore(this.counterEndTime)) {
          this.quizExpired = true;
        }

        if (!this.quizExpired) {
          this.counterValue = this.counterValue.subtract(1, "seconds");
        } else {
          clearInterval(this.counterInterval);
        }
      }, 1000);

      this.quizUpdateInterval = setInterval(() => {
        if (this.quizExpired) {
          clearInterval(this.quizUpdateInterval);
        }

        if (this.view === this.initialView) {
          this.updateQuiz();
          this.counterValue = this.getCounterInitialTime();
        }
      }, this.quizUpdateFrequency);

      this.quizParticipants = parseInt(
        this.getContentAttribute("participants"),
        10
      );
      this.counterValue = this.getCounterInitialTime();
    });
  }

  beforeDestroy() {
    clearInterval(this.counterInterval);
    clearInterval(this.quizUpdateInterval);
  }

  /************************************/
  // Map Cockpit props

  get headlines() {
    const retValue = {
      title: null as any,
      subTitle: null as any
    };

    let title = this.getContentAttribute("title");
    if (title && title.length > 0) {
      retValue.title = new LayoutComponent(title[0]);
      // SubTitle
      if (title.length > 1) {
        retValue.subTitle = new LayoutComponent(title[1]);
      }
    }

    return retValue;
  }

  get initialView() {
    return this.getContentAttribute("show_information_screen")
      ? View.INFORMATION
      : View.INTRODUCTION;
  }

  get participants() {
    return this.quizParticipants > 0
      ? Math.min(this.quizParticipants, this.targetParticipants)
      : this.quizParticipants;
  }

  get targetParticipants() {
    return parseInt(this.getContentAttribute("target_participants"), 10);
  }

  get counterEndTime() {
    const endDate = this.getContentAttribute("end_date");
    const endTime = this.getContentAttribute("end_time");

    return moment(`${endDate} ${endTime}`, this.counterDateFormat);
  }

  get introduction() {
    const introduction = this.getContentAttribute("introduction");
    if (introduction) {
      return new LayoutComponent(introduction[0]);
    }

    return null;
  }

  get thankyou() {
    const thankyou = this.getContentAttribute("thank_you");
    if (thankyou) {
      return new LayoutComponent(thankyou[0]);
    }

    return null;
  }

  get questions() {
    const questions = this.getContentAttribute("questions");
    if (questions && questions.length > 0) {
      const result = questions.map((currentQuestion: any) => {
        if (currentQuestion) {
          return new LayoutComponent(currentQuestion);
        }

        return null;
      });

      this.noQuestions = result.length;

      return result;
    }

    return [];
  }

  get images() {
    //@ts-ignore
    return {
      participants: "/assets/quiz/teilnehmeranzahl.svg",
      countDown: "/assets/quiz/quiz-countdown.svg"
    };
  }

  /************************************/
  // Business logic

  // Receive question state
  setAnswerState({ selectedAnswerIdx, correctAnswerIdx }: any) {
    this.selectedAnswerIdx = selectedAnswerIdx.sort();
    this.correctAnswerIdx = correctAnswerIdx.sort();

    const answerIsCorrect =
      JSON.stringify(this.selectedAnswerIdx) ===
      JSON.stringify(this.correctAnswerIdx);

    matomo.quizQuestionEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      answerIsCorrect
        ? MatomoEventNames.QUIZ_CORRECT_ANSWER
        : MatomoEventNames.QUIZ_WRONG_ANSWER,
      this.currentQuestionIdx
    );

    if (answerIsCorrect === false) {
      this.amountOfWrongAnswers += 1;
    }
  }

  nextQuestionHandler() {
    if (!this.selectedAnswerIdx.length) return;
    if (
      JSON.stringify(this.selectedAnswerIdx) !==
      JSON.stringify(this.correctAnswerIdx)
    ) {
      this.showMessage = true;
    } else {
      // Reset question state
      this.showMessage = false;
      this.correctAnswerIdx = [];
      this.selectedAnswerIdx = [];

      if (this.currentQuestionIdx + 1 === this.noQuestions) {
        this.currentQuestionIdx = 0;
      } else {
        this.currentQuestionIdx++;
      }
    }
  }

  async saveQuiz() {
    this.loading = true;

    const result = await QuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      await QuizRepository.saveQuiz(
        this.content[0]._id,
        result.value.participants + 1
      );

      this.loading = false;
    }
  }

  async updateQuiz() {
    const result = await QuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      this.quizParticipants = result.value.participants;
    }
  }

  get progressData() {
    const barsCount = 10;
    const result = [];
    const self = this;
    let activeIndex = null;

    if (self.participants && self.participants !== 0) {
      activeIndex =
        Math.ceil(self.participants / (self.targetParticipants / barsCount)) -
        1;
    }

    for (let i = 0; i < barsCount; i++) {
      if (activeIndex !== null) {
        result.push({ isActive: i === activeIndex ? true : false });
      } else {
        result.push({ isInactive: true });
      }
    }

    return result;
  }

  getCounterInitialTime() {
    let counterStartValue = null;
    const now = moment();

    if (!now.isBefore(this.counterEndTime)) {
      this.quizExpired = true;
      counterStartValue = moment("00:00:00", "HH mm ss");
    } else {
      counterStartValue = moment.duration(
        moment(this.counterEndTime, this.counterDateFormat).diff(
          moment(now, this.counterDateFormat)
        )
      );
    }
    return counterStartValue;
  }

  startQuiz() {
    matomo.genericEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.QUIZ_START
    );

    this.view = View.QUIZ;
  }

  get id() {
    return this.getContentAttribute("_id");
  }

  get countdown() {
    if (this.counterValue && !this.quizExpired) {
      const hours =
        this.counterValue.days() > 0
          ? this.counterValue.hours() + this.counterValue.days() * 24
          : this.counterValue.hours();
      const minutes = this.counterValue.minutes();
      const seconds = this.counterValue.seconds();

      const formatValue = function(value: number) {
        if (value < 10) {
          return `0${value}`;
        }
        return value;
      };

      return `${formatValue(hours)} ${formatValue(minutes)} ${formatValue(
        seconds
      )}`;
    }

    return moment(this.counterValue).format("HH mm ss");
  }

  async submitHandler() {
    this.attemptSubmit = true;

    await this.saveQuiz();
    await this.updateQuiz();
    this.counterValue = this.getCounterInitialTime();

    this.view = View.THANKYOU;

    matomo.quizQuestionEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.QUIZ_FINISH,
      this.amountOfWrongAnswers
    );

    // Reset model
    this.attemptSubmit = false;
    this.currentQuestionIdx = 0;
    this.amountOfWrongAnswers = 0;
  }

  getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  /************************************/
}
