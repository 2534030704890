var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.contentLoaded && (!_vm.layoutTypes.includes('label') || _vm.itemsToRender.length)
  )?_c('div',{class:['tag-filter', _vm.layoutComponent.class],attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[_c('div',{staticClass:"tag-filter__tags text-center mb-8"},[(_vm.layoutTypes.includes('buttons'))?_c('a-checkbox-group',{staticClass:"tag-filter__tags-inner btn-group-toggle",attrs:{"data-toggle":"buttons"},on:{"change":_vm.onCheckboxGroupChange},model:{value:(_vm.checkedTags),callback:function ($$v) {_vm.checkedTags=$$v},expression:"checkedTags"}},_vm._l((_vm.tags),function(filter,idx){return _c('label',{key:idx,class:[
          'tag-filter__tag-item btn',
          _vm.filterButtonsClass,
          _vm.checkedTags.includes(filter._id) ? 'active' : ''
        ],on:{"click":_vm.scrollToTop}},[_c('a-checkbox',{attrs:{"value":filter._id}}),_vm._v(" "+_vm._s(filter.display)+" ")],1)}),0):_vm._e(),(_vm.layoutTypes.includes('select'))?_c('div',{staticClass:"tag-filter__select"},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"allowClear":"","dropdownClassName":"meinaz-dropdown","value":_vm.onlyOneActive ? _vm.checkedTags[0] : _vm.checkedTags,"mode":_vm.onlyOneActive ? 'default' : 'multiple',"placeholder":_vm.selectPlaceholder},on:{"change":_vm.onSelectChange}},_vm._l((_vm.tags),function(filter,idx){return _c('a-select-option',{key:idx,attrs:{"value":filter._id}},[_vm._v(" "+_vm._s(filter.display)+" ")])}),1)],1):_vm._e(),(_vm.layoutTypes.includes('label'))?_c('div',{staticClass:"tag-filter__label"},_vm._l((_vm.tags),function(tag,idx){return _c('span',{key:idx,attrs:{"value":tag._id}},[_vm._v(" "+_vm._s(tag.display)+" ")])}),0):_vm._e(),(_vm.tags.length > 0)?_c('button',{staticClass:"tag-filter__reset-btn btn btn-link",attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.filterResetBtnText)},on:{"click":_vm.resetFilters}}):_vm._e()],1),_c('div',{staticClass:"tag-filter__body d-flex flex-column"},[(_vm.loading)?_c('div',{staticClass:"spinner-border my-8 mx-auto",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"tag-filter__content row"},[(_vm.collection !== 'articles')?_vm._l((_vm.itemsToRender),function(item,idx){return _c('div',{key:idx,class:[
            'article-column tag-filter__item',
            _vm.getColumns(item.settings.width)
          ]},[_c(item.component,{tag:"component",attrs:{"layoutComponent":item}})],1)}):_vm._l((_vm.itemsToRender),function(item){return _c('div',{key:item._id},[_c('DynamicViewWidget',{attrs:{"className":_vm.itemsClass,"propCockpitLink":item,"widgetType":_vm.Type.ARTICLE_WIDGET}})],1)})],2)]),(_vm.visibleItemsLength < _vm.contentItems.length)?_c('div',{staticClass:"tag-filter__footer d-flex justify-content-center"},[_c('button',{class:['btn', _vm.paginationBtnClass],domProps:{"innerHTML":_vm._s(_vm.paginationBtnText)},on:{"click":function($event){$event.preventDefault();return _vm.loadMore.apply(null, arguments)}}})]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }