import { LayoutComponent } from "../models/LayoutComponent";
import Constants, { ShareLink } from "../models/Constants";
import { Store } from "./Store";
import RoutingHelper from "./RoutingHelper";
import User, { UserLoginStatus, UserProfile } from "../models/User";
import matomo, { MatomoEventNames } from "../plugins/matomo";
import EventRepository from "../api/repositories/EventRepository";
import { ResultType } from "../api/util/Result";
import { EventBus, ACCORDION_CLICKED } from "./EventBus";
import config, { FrontendContext } from "../config";

class Helper {
  showExitRamp(link: string | null) {
    if (link == null) {
      return false;
    }

    let ignoreProtocols: string[] = ["about:", "mailto:"];

    try {
      let url = new URL(link);
      if (!ignoreProtocols.includes(url.protocol)) {
        let isWhitelisted = false;
        Constants.externalLinkWhitelist().forEach((whitelistUrl: string) => {
          if (url.host.includes(whitelistUrl)) {
            isWhitelisted = true;
          }
        });

        if (!isWhitelisted) {
          return url;
        }

        isWhitelisted =
          Constants.internalLinkBlackList().filter((blackListUrl: string) =>
            url.host.includes(blackListUrl)
          ).length === 0;

        return isWhitelisted ? false : url;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  processText(text: string) {
    return text
      .replace(/<sup>&reg;<\/sup>/gi, "&reg;")
      .replace(/<sup>®<\/sup>/gi, "&reg;")
      .replace(/&reg;/gi, "<sup>&reg;</sup>")
      .replace(/®/gi, "<sup>&reg;</sup>");
  }

  async generateMoveTokenAndOpenNewWindow(url: string, t: string = "_blank") {
    let target = window.open("", t);
    if (target === null) {
      return;
    }

    target.document.write(
      "Sie werden in Kürze weitergeleitet, bitte warten Sie einen Moment ..."
    );

    let isMoveTokenUrl = RoutingHelper.isMoveTokenURL(url);
    if (isMoveTokenUrl) {
      if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
        target.location.href = url;
        return;
      }

      Store.modal.title = "Bitte warten";
      Store.modal.className = "modal-md";
      Store.modal.setLayoutComponent(
        new LayoutComponent({
          component: "LoadingModal",
          settings: { url: url }
        })
      );
      Store.modal.showModal();

      let urlObj = new URL(url);
      let moveToken = null;

      try {
        moveToken = await RoutingHelper.getMoveToken(urlObj.origin);
      } catch (e) {
        //
      }

      Store.modal.hideModal();

      if (moveToken) {
        let splittedUrl = url.split("#");
        let finalUrl = "";

        if (splittedUrl.length > 1) {
          let firstUrlPart = splittedUrl.splice(0, 1);
          let secondUrlPart = splittedUrl.join("#");
          finalUrl = `${firstUrlPart}?wauth=${moveToken}#${secondUrlPart}`;
        } else {
          finalUrl = `${url}?wauth=${moveToken}`;
        }

        target.location.href = finalUrl;
        return;
      }
    } else {
      target.location.href = url;
      return;
    }

    target.close();
  }

  async handleLinkClick(e: any) {
    /*
     * Find click target
     * If the click target is a child of <a> find the matching <a> parent
     */
    let target = e.target as HTMLElement;
    if (target.nodeName != "A" && target.nodeName != "AREA") {
      target = target.closest("a, area") as HTMLElement;
    }

    /* Get link and html */
    let link = target.getAttribute("href");
    let linkDisabled = target.dataset.linkdisabled || false;
    let html = document.getElementsByTagName("html")[0];
    const matomoLinkClickDisabled =
      !!target.dataset.matomoClickLinkDisabled || false;

    /* Push matomo events on link click if not disabled */
    if (!matomoLinkClickDisabled) {
      handleLinkMatomoEvents(link);
    }

    /* If no link present, don't do anything */
    if (link == undefined || linkDisabled) {
      return;
    }

    /*
     * If link has form anypath#anyhashvalue it's an anchor link
     * Scroll to it via "scrollIntoView" function
     * <!> A CSS variable "--menu-height" has to be set in
     * the root of the app theme so that we know the offset.
     * As native CSS variables can not be set in the variables.scss
     * file, it needs to be saved in theme.scss
     */
    if (html && link && link.includes("#") && link.length > 1) {
      const elementId = link.split("#")[1];
      let elDataAnchor = document.querySelectorAll("[data-anchor]");
      let el = document.getElementById(elementId);

      if (!el && elDataAnchor.length > 0) {
        let opts = {
          elementId: elementId,
          elDataAnchor: elDataAnchor
        };
        EventBus.$emit(ACCORDION_CLICKED, opts);
      }
      const appThemeRoot = document.querySelector("#app > div");
      let offset = "0px";

      if (appThemeRoot) {
        offset = getComputedStyle(appThemeRoot).getPropertyValue(
          "--menu-height"
        );
      }

      if (el && appThemeRoot) {
        e.preventDefault();
        html.classList.add("smooth-scroll");

        // Add the offset to the element to which we scroll.
        el.style.setProperty("scroll-margin-top", offset);
        el.scrollIntoView(true);
        html.classList.remove("smooth-scroll");
        return;
      }
    }

    /* Check if url should show exit ramp */
    let helper = new Helper();
    let url = helper.showExitRamp(link);

    if (url) {
      /* Url is external and not on the AZ whitelist, so show the exit ramp */
      e.preventDefault();
      Store.modal.title = "Thanks for your visit";
      Store.modal.className = "modal-md cs360-modal--extern cs360-modal ";
      Store.modal.setLayoutComponent(
        new LayoutComponent({
          component: "ExitRamp",
          settings: {
            link: url,
            buttonClass: target.className
          }
        })
      );
      Store.modal.showModal();
      return;
    } else {
      if (RoutingHelper.isMoveTokenURL(link)) {
        /*
         * Url is external and needs a move token
         * Generate the move token and open the url
         */
        e.preventDefault();
        await helper.generateMoveTokenAndOpenNewWindow(link);
      }

      return;
    }
  }

  handleTMNClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    Store.modal.title = "TNM Übersicht";
    Store.modal.className = "modal-tnm";
    Store.modal.layoutComponent = LayoutComponent.createEmpty("tnm_overlay");
    Store.modal.showCloseButton = true;
    Store.modal.preventHideByAction = false;
    Store.modal.showModal();
  }

  crawlDomAndAddEventlistenerToAllLinks(allLinks: any = null) {
    if (!allLinks) {
      allLinks = document.querySelectorAll("a, area");
    }

    let helper = new Helper();

    for (var i = 0; i < allLinks.length; i++) {
      let link = allLinks[i] as HTMLLinkElement;
      link.removeEventListener("click", helper.handleLinkClick, false);
      link.addEventListener("click", helper.handleLinkClick, false);

      if (link.classList.contains("tnm-overview")) {
        link.removeEventListener("click", helper.handleTMNClick, false);
        link.addEventListener("click", helper.handleTMNClick, false);
      }
    }
  }

  smoothScrollTo(htmlElement: HTMLElement, offsetTop: number) {
    let html = document.getElementsByTagName("html")[0];

    if (htmlElement && html) {
      html.classList.add("smooth-scroll");
      window.scrollTo(0, offsetTop);
      html.classList.remove("smooth-scroll");
    }
  }

  /*
    Colum Mapping
    xl 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
    lg 1, 2, 4, 4, 6, 6, 8, 8, 10, 10, 12, 12
    md 3, 4, 4, 6, 6, 6, 8, 8, 10, 10, 12, 12
    xs 6, 6, 6, 6, 6, 12, 12, 12, 12, 12, 12, 12
  */
  public classMapper: any = {
    1: {
      lg: 1,
      md: 3,
      xs: 6
    },
    2: {
      lg: 2,
      md: 4,
      xs: 6
    },
    3: {
      lg: 3,
      md: 6,
      xs: 12
    },
    4: {
      lg: 4,
      md: 4,
      xs: 12
    },
    5: {
      lg: 5,
      md: 6,
      xs: 12
    },
    6: {
      lg: 6,
      md: 6,
      xs: 12
    },
    7: {
      lg: 7,
      md: 6,
      xs: 12
    },
    8: {
      lg: 8,
      md: 8,
      xs: 12
    },
    9: {
      lg: 9,
      md: 12,
      xs: 12
    },
    10: {
      lg: 10,
      md: 10,
      xs: 12
    },
    11: {
      lg: 12,
      md: 12,
      xs: 12
    },
    12: {
      lg: 12,
      md: 12,
      xs: 12
    }
  };

  getMappedCssClassesAsArray(width: string): string[] {
    if (!width) {
      return ["col-12"];
    } else if (width === "col") {
      return ["col"];
    } else if (width === "auto") {
      return ["col-auto"];
    }

    let w: any = parseInt(width);
    if (w > 12 || !w) {
      w = 12;
    }

    if (w < 1) {
      w = 1;
    }

    let classes = ["col-xl-" + w];

    classes.push("col-lg-" + this.classMapper[w].lg);
    classes.push("col-md-" + this.classMapper[w].md);
    classes.push("col-" + this.classMapper[w].xs);

    return classes;
  }

  /* Replace spaces and underscores from a string with '-' and transform to lowercase */
  stringFormatter = (value: string) => {
    if (value) {
      const result = value.toString().trim();
      return result.replace(/\W/g, "-").toLowerCase();
    }
    return "";
  };

  /*
   * Dispatch "cookiechange" custom event on cookie changes
   */
  cookieChangeHandler() {
    const matchPattern = /(?:wscrCookieConsent?)[^;]+/;
    let wscrCookieState: string = "";

    let lastCookie = document.cookie;
    // rename document.cookie to document._cookie, and redefine document.cookie
    const expando = "_cookie";
    const expandoIsReady = Object.prototype.hasOwnProperty.call(
      Document.prototype,
      expando
    );

    if (!expandoIsReady) {
      let nativeCookieDesc: any = Object.getOwnPropertyDescriptor(
        Document.prototype,
        "cookie"
      );

      Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
      Object.defineProperty(Document.prototype, "cookie", {
        enumerable: true,
        configurable: true,
        get() {
          return this[expando];
        },
        set(value) {
          this[expando] = value;
          let cookie = this[expando];

          // match "wscrCookieConsent" from cookies
          const wscrOldValueMatch = lastCookie.match(matchPattern);
          const wscrNewValueMatch = cookie.match(matchPattern);

          const wscrOldValue =
            wscrOldValueMatch !== null ? wscrOldValueMatch[0] : "";
          const wscrNewValue =
            wscrNewValueMatch !== null ? wscrNewValueMatch[0] : "";

          // check the wscrCookieConsent cookie change
          // dispatch the "cookiechange" event only if "wscrCookieConsent" has changed (ignore changes from other cookies)
          // keep a local state in "wscrCookieState" and compare it to "wscrNewValue" to prevent dispatching the event when the cookie change is triggered by other cookies and wscrOldValue and wscrNewValue are still different
          if (
            wscrOldValue !== wscrNewValue &&
            wscrNewValue !== wscrCookieState &&
            wscrNewValue.includes("visitor=")
          ) {
            wscrCookieState = wscrNewValue;
            try {
              // dispatch cookie-change messages to other same-origin tabs/frames
              let detail = { oldValue: lastCookie, newValue: cookie };
              this.dispatchEvent(
                new CustomEvent("cookiechange", {
                  detail: detail
                })
              );
              // channel.postMessage(detail);
            } finally {
              lastCookie = cookie;
            }
          }
        }
      });
    }
    /**
     * listen cookie-change messages from other same-origin tabs/frames
     *
     * <!> A polyfill for Safari is needed, otherwise the error blocks the
     * application flow on staging and production due to additional rules.
     */
    // const channel = new BroadcastChannel("cookie-channel");
    // channel.onmessage = (e: any) => {
    //   lastCookie = e.data.newValue;
    //   document.dispatchEvent(
    //     new CustomEvent("cookiechange", {
    //       detail: e.data
    //     })
    //   );
    // };
  }

  /**
   * Check if user is registered to one or multiple events
   * For each success registration the callback function will be executed
   * callback function will get response value as argument
   * @param veevaIds Array<string>
   * @param cb Function
   **/
  checkUserRegistrationForEvents(veevaIds: Array<string>, cb?: Function) {
    if (Array.isArray(veevaIds) && veevaIds.length > 0) {
      const promiseList = veevaIds.map((eventID: string) => {
        return EventRepository.checkUserRegistrationForEvent(eventID);
      });

      Promise.all(promiseList).then(results => {
        results.forEach(result => {
          if (result.type === ResultType.SUCCESS && result.value) {
            if (cb && typeof cb === "function") {
              cb(result.value);
            }
          }
        });
      });
    }
  }

  replacePlaceholders(
    text: string,
    placeholders: { [key: string]: Function }
  ): string {
    Object.keys(placeholders).forEach(
      key => (text = text.replace(`$${key}`, placeholders[key]()))
    );

    return text;
  }
}

export default new Helper();

export class Deserializible {
  /* extend and add following constructor to child class to be able to initialzie class from JSON object

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);
    }
  }
 */
  [key: string]: any;

  public deserialize(other: any) {
    const keys = Object.keys(this);

    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(other, key)) {
        this[key] = other[key];
      }
    }
  }
}

/**
 * Escape special characters that are used in a regular expression.
 * @param literalString string
 * @returns string
 */
export function regExpEscape(literalString: string): string {
  return literalString.replace(/[-[\]{}()*+!<=:?.\\^$|#\s,]/g, "\\$&");
}

/**
 * Used to change the FrontEnd Context used on login
 * @returns boolean
 */
export function changeContextByRoute(config: any) {
  const frontConfig = config.getFrontendConfiguration();
  const contextByRoute = config.getFrontendConfiguration().contextByRoute;
  const indexContext = contextByRoute.findIndex(
    (val: { slug: string; context: FrontendContext }) =>
      val.slug === window.location.pathname
  );
  if (indexContext > -1) {
    frontConfig.setContext = contextByRoute[indexContext].context;
  } else {
    frontConfig.setContext = frontConfig.defaultContext;
  }
}

export function getContextByUserProfile(profile: UserProfile) {
  if (profile === UserProfile.PATIENT_PROTECTION) {
    return FrontendContext.PATIENT;
  }
  if (
    profile === UserProfile.MMC_BASIC ||
    profile === UserProfile.MMC_PLUS ||
    profile === UserProfile.MMC_DOCCHECK
  ) {
    return FrontendContext.HCP;
  }
  return config.getFrontendConfiguration().defaultContext;
}

export function getContextByRoute(route: string) {
  const frontConfig = config.getFrontendConfiguration();
  const contextByRoute = config.getFrontendConfiguration().contextByRoute;
  contextByRoute.find(
    (val: { slug: string; context: FrontendContext }, index: number) => {
      if (val.slug === route) {
        if (frontConfig.context !== val.context) {
          return val.context;
        }
        return true;
      }
    }
  );
  return config.getFrontendConfiguration().defaultContext;
}

/* Push matomo events on link clicks based on link type */
function handleLinkMatomoEvents(link: string | null) {
  enum LinkTypes {
    ShareFacebook = "shareFacebook",
    ShareWhatsapp = "shareWhatsapp",
    ShareEmail = "shareEmail",
    Bk3DModell = "bk3DModell",
    Bk3DModellStaging = "bk3DModellStaging",
    Bk3DModellTest = "bk3DModellTest"
  }

  const linkTypeContent: { [key: string]: string } = {
    [LinkTypes.ShareFacebook]: ShareLink.FACEBOOK,
    [LinkTypes.ShareWhatsapp]: ShareLink.WHATSAPP,
    [LinkTypes.ShareEmail]: ShareLink.EMAIL,
    [LinkTypes.Bk3DModell]: "interaktiv.brustkrebs.de",
    [LinkTypes.Bk3DModellStaging]: "interaktiv-staging.brustkrebs.de",
    [LinkTypes.Bk3DModellTest]: "interaktiv-test.brustkrebs.de"
  };
  let matomoEventName = MatomoEventNames.CLICK_LINK;
  let matomoEventId = "general";

  /* Get link type based on href */
  const getLinkType = () => {
    for (let key of Object.keys(linkTypeContent)) {
      if (link && link.includes(linkTypeContent[key])) {
        return key;
      }
    }
  };

  /* Set matomo event name and event id variables based on link type */
  switch (getLinkType()) {
    case LinkTypes.ShareFacebook:
      matomoEventName = MatomoEventNames.SHARE_FACEBOOK;
      matomoEventId = "click_share";
      break;
    case LinkTypes.ShareWhatsapp:
      matomoEventName = MatomoEventNames.SHARE_WHATSAPP;
      matomoEventId = "click_share";
      break;
    case LinkTypes.ShareEmail:
      matomoEventName = MatomoEventNames.SHARE_EMAIL;
      matomoEventId = "click_share";
      break;
    case LinkTypes.Bk3DModell:
    case LinkTypes.Bk3DModellStaging:
    case LinkTypes.Bk3DModellTest:
      matomoEventName = MatomoEventNames.CLICK_BK_3D_LINK;
      matomoEventId = "click_share";
      break;
    default:
      matomoEventName = MatomoEventNames.CLICK_LINK;
      matomoEventId = "general";
      break;
  }

  /* Push matomo event */
  matomo.genericEvent(
    link ? link : matomoEventId,
    matomo.MATOMO_EVENT_CATEGORY,
    matomoEventName
  );
}

export class FieldInfo {
  public property: string;
  public value!: string | null;
  public placeholder: string;

  constructor(
    property: string,
    placeholder: string,
    value: string | null = null
  ) {
    this.property = property;
    this.value = value;
    this.placeholder = placeholder;
  }
}

export class Placeholders {
  public fields: FieldInfo[];
  private static helper: Helper;

  constructor(fields: FieldInfo[] = []) {
    this.fields = fields;

    if (!Placeholders.helper) {
      Placeholders.helper = new Helper();
    }
  }

  public addField(field: FieldInfo) {
    this.fields.push(field);
  }

  public updateText(text: string): string {
    const data: { [key: string]: Function } = {};

    this.fields.forEach(field => {
      data[field.property] = () => field.value;
    });

    return Placeholders.helper.replacePlaceholders(text, data);
  }
}
