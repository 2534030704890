import { Deserializible } from "../../../util/Helper";
import validator from "validator";

export class Feedback extends Deserializible {
  /* JSON:
    {
      "correctionAddress": "string",
      "correctionName": "string",
      "correctionTitle": "string",
      "feedback": "string",
      "publicationConsent": true
    };

  Form

  private feedbackConsent: string = "";
  private feedbackTitle: string = "";
  private feedbackRecipient: string = "";
  private feedbackHcaName: string = "";
  private feedbackHcaStreet: string = "";
  private feedbackHcaZip: string = "";
  private feedbackHcaCity: string = "";
  private feedbackFeedback: string = "";

  */

  [key: string]: any;

  // public data
  public correctionName: string = "";
  public correctionTitle: string = "";
  public feedback: string = "";
  public publicationConsent: boolean = true;

  // helper
  public correctionHcaName: string = "";
  public correctionHcaStreet: string = "";
  public correctionHcaZip: string = "";
  public correctionHcaCity: string = "";

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);
    }
  }

  public get correctionAddress() {
    let values: string[] = [];
    let addressParts = [
      this.correctionHcaName,
      this.correctionHcaStreet,
      this.correctionHcaZip,
      this.correctionHcaCity
    ];
    addressParts.forEach(function(value) {
      if (!validator.isEmpty(value)) {
        values.push(value);
      }
    });

    return values.join(", ");
  }

  public validateCorrectionAddressLength() {
    return this.correctionAddress.length <= 200;
  }

  public validateCorrectionAddress() {
    return (
      !validator.isEmpty(this.correctionAddress) &&
      this.validateCorrectionAddressLength()
    );
  }

  public validateCorrectionNameLength() {
    return this.correctionName.length <= 200;
  }

  public validateCorrectionName() {
    return (
      !validator.isEmpty(this.correctionName) &&
      this.validateCorrectionNameLength()
    );
  }

  public validateCorrectionTitleLength() {
    return this.correctionTitle.length <= 200;
  }

  public validateCorrectionTitle() {
    return (
      !validator.isEmpty(this.correctionTitle) &&
      this.validateCorrectionTitleLength()
    );
  }

  public validateFeedbackLength() {
    return this.feedback.length <= 2000;
  }

  public validateFeedback() {
    return !validator.isEmpty(this.feedback) && this.validateFeedbackLength();
  }

  /* all values can be empty but shall not be too long */
  public isValid() {
    return (
      this.validateCorrectionAddressLength() &&
      this.validateCorrectionNameLength() &&
      this.validateCorrectionTitleLength() &&
      this.validateFeedbackLength()
    );
  }

  public toSend() {
    let result = {
      publicationConsent: this.publicationConsent
    };

    if (this.validateCorrectionAddress()) {
      result = Object.assign({}, result, {
        correctionAddress: this.correctionAddress
      });
    }

    if (this.validateCorrectionName()) {
      result = Object.assign({}, result, {
        correctionName: this.correctionName
      });
    }

    if (this.validateCorrectionTitle()) {
      result = Object.assign({}, result, {
        correctionTitle: this.correctionTitle
      });
    }

    if (this.validateFeedback()) {
      result = Object.assign({}, result, { feedback: this.feedback });
    }

    return result;
  }
}
