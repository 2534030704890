var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:'key_' + _vm.contentKey,ref:"slide",staticClass:"test-guideline-slide",style:({
    backgroundColor: _vm.colors.theme_background_color
  }),attrs:{"data-component":"TestGuidelineSlide","data-id":_vm.getSlideId()}},[(_vm.backgroundImage != null)?_c(_vm.backgroundImage.component,_vm._b({key:'bgimg_' + _vm.contentKey,tag:"component",staticClass:"test-guideline-slide-bg"},'component',{ layoutComponent: _vm.backgroundImage },false)):_vm._e(),_c('div',{key:'cnt_' + _vm.contentKey,staticClass:"test-guideline-slide-content pt-10 pb-7 px-8 d-flex flex-column align-items-start"},[(_vm.loading)?_c('AzLogoLoader'):_vm._e(),(_vm.headline != null)?_c('div',{staticClass:"col-12 test-guideline-slide-headline"},[_c('Headline',{key:'headline_' + _vm.contentKey,attrs:{"layoutComponent":_vm.headline}}),_c('div',{staticClass:"test-guideline-slide-progress",style:({
          backgroundColor: _vm.colors.theme_primary_color
        })},[_c('div',{staticClass:"test-guideline-slide-icon"},[(_vm.icon != null)?_c(_vm.icon.component,_vm._b({tag:"component"},'component',{ layoutComponent: _vm.icon },false)):_vm._e()],1),_c('div',{staticClass:"test-guideline-slide-progress-steps"},_vm._l((_vm.totalSteps),function(step,stepId){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(step <= _vm.currentStep),expression:"step <= currentStep"}],key:stepId,staticClass:"progress-step",style:({
              left: _vm.getStepPercentage(stepId),
              borderColor: _vm.colors.theme_primary_color,
              color:
                step != _vm.currentStep ? _vm.colors.theme_primary_color : 'white',
              backgroundColor:
                step != _vm.currentStep
                  ? _vm.colors.popup_background_color
                  : _vm.colors.theme_primary_color
            }),on:{"click":function($event){return _vm.gotoParent(step)}}},[_vm._v(" "+_vm._s(step)+" ")])}),0)])],1):_vm._e(),(_vm.asPopup)?_c('div',{staticClass:"test-guideline-popup__overlay"}):_vm._e(),_c('div',{staticClass:"test-guideline-slide-content-inner d-flex flex-column",class:{
        popup: _vm.asPopup
      }},[_c('div',{staticClass:"test-guideline-slide-content-inner-elements",class:_vm.getColumns(_vm.basicLayoutComponent.settings.width)},[_c('div',{staticClass:"row"},_vm._l((_vm.getLayoutComponentsFromLayout(
              _vm.slideContent
            )),function(contentItem,idx){return _c('div',{key:idx,class:'article-column ' + _vm.getColumns(contentItem.settings.width)},[_c(contentItem.component,{tag:"component",attrs:{"layoutComponent":contentItem,"slideTemplate":_vm.template}})],1)}),0)]),_c('div',{staticClass:"test-guideline-slide__content-navigation-wrapper col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 test-guideline-slide-content-navigation prev"},[(
                _vm.previousSlide != null &&
                  !_vm.hidePrevious &&
                  (_vm.asPopup || _vm.isRadioTemplate)
              )?_c('router-link',{staticClass:"test-guideline-slide-previous",attrs:{"to":_vm.getSlideUrl(_vm.previousSlide)},on:{"click":function($event){return _vm.clickSlideButton(_vm.previousSlide)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.previousSlideBtnText)}})]):_vm._e()],1),_c('div',{staticClass:"col-12 text-right test-guideline-slide-content-navigation next"},[(_vm.nextSlide != null)?_c('router-link',{staticClass:"test-guideline-slide-next",attrs:{"to":_vm.getSlideUrl(_vm.nextSlide)},on:{"click":function($event){return _vm.clickSlideButton(_vm.nextSlide)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.nextSlideBtnText)}})]):_vm._e()],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }