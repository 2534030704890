
import { LayoutComponent } from "common";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TnmOverlay extends Vue {
  get table() {
    return LayoutComponent.createEmpty("lc_table", {
      content: {
        _id: "618ce80edf752a381a0ce558",
        display: "618ce80edf752a381a0ce558",
        link: "tables"
      }
    });
  }
}
