
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class NewsletterSubmitModal extends BaseComponent {
  constructor() {
    super();
  }

  get childrenComponents(): string | null {
    return this.getSettingsAttribute("childrenComponents");
  }
}
