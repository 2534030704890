import { Component, Vue } from "vue-property-decorator";

@Component
export default class Slider extends Vue {
  initialSpacePadding = 0;
  carouselOptions: any = {
    perPage: 1,
    perPageCustom: [
      [768, 2],
      [1024, 3]
    ],
    mouseDrag: true,
    paginationEnabled: false,
    navigationEnabled: true,
    navigationNextLabel: "",
    navigationPrevLabel: "",
    spacePadding: 0,
    autoplay: false,
    loop: false,
    sliderItemClass: "col-md-6 col-lg-4"
  };

  setCarouselOptions(options: any) {
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }
    // If we set the `spacePadding` prop with 0, the padding will not be removed.
    this.carouselOptions.spacePadding =
      window.innerWidth < 768 ? 1 : this.initialSpacePadding;
  }
}
