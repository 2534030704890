
import { Component } from "vue-property-decorator";
import { LayoutComponent, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class TrainingQuizConfirmationModal extends BaseComponent {
  private userIdx: string;
  private trainingQuizIdx: string;

  constructor() {
    super();
    this.userIdx = this.propUserId;
    this.trainingQuizIdx = this.propTrainingQuizId;
  }

  get propUserId() {
    return this.layoutComponent ? this.layoutComponent.settings.userId : null;
  }

  get propTrainingQuizId() {
    return this.layoutComponent
      ? this.layoutComponent.settings.trainingQuizId
      : null;
  }

  startRating() {
    this.hideModal();
    Store.modal.className = "modal-lg";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("training-quiz-rating-modal", {
        userId: this.userIdx,
        trainingQuizId: this.trainingQuizIdx
      })
    );
    setTimeout(() => {
      Store.modal.showModal();
    }, 1000);
  }
}
