import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class WidgetExternalRepository extends AbstractRepository {
  public async get(type: string) {
    let response = await Client.get(Constants.getWidgetExternalUrl(type), null);

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new WidgetExternalRepository();
