export default class MenuItem {
  id!: string;
  _id!: string;
  name!: string;
  external_link!: any;
  cockpit_link!: any;
  visibility!: boolean;
  children!: MenuItem[];
  class!: string;

  constructor(item: any) {
    this.id = item.settings.id;
    this.name = item.settings.name;
    this.external_link = item.settings.external_link;
    this.visibility = item.settings.visibility;
    this.children = [];
    this.class = item.settings.class;

    let self = this;

    // add children
    if (Object.keys(item).indexOf("children") != -1) {
      if (item.children instanceof Array) {
        item.children.forEach((child: any) => {
          let childMenuItem = new MenuItem(child);
          self.addChild(childMenuItem);
        });
      }
    }

    // add linked post
    if (
      item.settings.cockpit_link != "" &&
      item.settings.cockpit_link != undefined &&
      item.settings.cockpit_link != null
    ) {
      this.cockpit_link =
        item.settings.cockpit_link.display !== "slug"
          ? item.settings.cockpit_link.display
          : "";

      this._id = item.settings.cockpit_link._id || null;
    } else {
      this.cockpit_link = null;
    }
  }

  private addChild(child: MenuItem) {
    this.children.push(child);
  }

  public get has_children() {
    return this.children.length > 0;
  }

  public get is_hash() {
    return this.is_external && this.link.startsWith("#");
  }

  public get is_external() {
    return (
      this.external_link &&
      this.external_link !== null &&
      this.cockpit_link == null
    );
  }

  public get is_internal() {
    return (
      this.cockpit_link !== null &&
      (!this.external_link || this.external_link == null)
    );
  }

  public get label() {
    return this.name;
  }

  public get link(): string {
    if (this.is_external) {
      return this.external_link;
    } else {
      if (this.cockpit_link) {
        return this.cockpit_link.startsWith("/")
          ? this.cockpit_link
          : "/" + this.cockpit_link;
      }
      return "/";
    }
  }

  public getRelativeLink(route: any): string {
    if (!this.external_link || !this.cockpit_link) {
      return this.link;
    }

    if (!this.external_link.startsWith("#")) {
      return this.link;
    }

    if (
      this.cockpit_link !== route.path &&
      route.path !== `/${this.cockpit_link}`
    ) {
      return this.link;
    }

    return this.external_link;
  }

  public static getFake(id: string, slug: string, label: string) {
    return new MenuItem({
      _id: "item-" + id,
      name: "",
      external_link: "",
      visibility: false,
      cockpit_link: {
        _id: id,
        slug: slug
      }
    });
  }

  /**
   * Requires that each item has an `id` associated
   * @param parent MenuItem
   * @returns *`true`* or *`false`*
   */
  public isChildOf(parent: MenuItem) {
    return !!parent.children.find((child: MenuItem) => child.id === this.id);
  }
}
