
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { LayoutComponent } from "common";

@Component
export default class FloatingImageBox extends BaseComponent {
  getComponentType() {
    return "floating_image_boxes";
  }

  async mounted() {
    super.mounted();
  }

  get texts() {
    if (this.text) {
      let texts: LayoutComponent[] = [];
      this.text.forEach((t: any) => {
        texts.push(new LayoutComponent(t));
      });
      return texts;
    }
    return [];
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get images() {
    if (this.image) {
      let images: LayoutComponent[] = [];
      this.image.forEach((img: any) => {
        images.push(new LayoutComponent(img));
      });
      return images;
    }
    return [];
  }

  get image() {
    return this.getContentAttribute("image");
  }

  get image_position() {
    return this.getSettingsAttribute("image_position");
  }
}
