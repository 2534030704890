import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class LikePostRepository extends AbstractRepository {
  public async save(postId: string, userId?: string | null) {
    const data = {
      post_id: postId,
      ...(userId && { user_id: userId })
    };

    let response = await Client.postWithJsonResult(
      Constants.getLikePostUrl(),
      data
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async get(postId: string, userId?: string | null) {
    const data = {
      post_id: postId,
      ...(userId && { user_id: userId })
    };

    let response = await Client.getJson(Constants.getLikePostUrl(), data);

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new LikePostRepository();
