
import { Component, Vue } from "vue-property-decorator";
import {
  BookmarkRepository,
  matomo,
  MatomoEventNames,
  ResultType,
  User
} from "common";

@Component
export default class DeleteBookmkarList extends Vue {
  deletionComplete!: boolean;

  constructor() {
    super();
    this.deletionComplete = false;
  }

  async deleteBookmarkList() {
    // Matomo tracking
    matomo.genericEvent(
      "Delete_Bookmarks",
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.BOOKMARK_DELETE_ALL
    );

    // Delete bookmarks
    let result = await BookmarkRepository.deleteList(
      User.getProperty("bookmarkListId")
    );

    if (result.type == ResultType.SUCCESS) {
      this.deletionComplete = true;
    }
  }
}
