
import { Component, Vue, Prop } from "vue-property-decorator";
import { Step } from "./OrderList.vue";

@Component
export default class OrderListHeader extends Vue {
  @Prop() stepsLayout!: boolean;
  @Prop() firstStepHeadline!: string;
  @Prop() secondStepHeadline!: string;
  @Prop() step!: number;
  @Prop() groups!: Array<any>;
  @Prop() selectedGroupIdx!: number;
  @Prop() changeGroup!: (idx: number) => void;

  public Step = Step;
}
