import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class ViewedArticleRepository extends AbstractRepository {
  public async save(articleId: string) {
    const data = {
      articleId: articleId
    };

    let response = await Client.post(
      Constants.getArticleListUrl("viewed"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async get() {
    let response = await Client.getJson(
      Constants.getArticleListUrl("viewed"),
      null,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async delete(articleId: string) {
    let response = await Client.delete(
      Constants.getArticleListUrl(`viewed/${articleId}`),
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new ViewedArticleRepository();
