
import { Component, Watch } from "vue-property-decorator";
import {
  EventBus,
  ARTICLE_SAVED,
  ARTICLE_UNSAVED,
  DatabaseSavedArticleList,
  ArticleListSaved,
  ArticleListRead,
  LayoutComponent,
  Repository,
  ResultType,
  User,
  ViewedArticleRepository
} from "common";
import BaseWidget from "./BaseWidget.vue";

interface ViewWidget {
  isVisible: boolean;
  component: LayoutComponent;
}

interface ArticlePublishedEvent {
  isPublished: boolean;
  widgetId: string;
}

@Component
export default class CookieArticleList extends BaseWidget {
  readonly TYPE_SAVED = "saved";
  readonly TYPE_VISITED = "visited";

  public articleWidgetIds: Array<string> = [];
  public loading: boolean = true;

  public savedArticleIds: Array<string> = [];
  public cookieArticles: Array<ViewWidget> = [];

  getComponentType() {
    return "";
  }

  async mounted() {
    super.mounted();

    EventBus.$on(ARTICLE_SAVED, () => this.$nextTick(this.loadArticles));
    EventBus.$on(ARTICLE_UNSAVED, () => this.$nextTick(this.loadArticles));

    await this.loadArticles();
  }

  async loadArticles() {
    let articlesToFind: any = [];

    if (this.type == this.TYPE_SAVED) {
      articlesToFind = await this.getSavedArticleIds();
    } else if (this.type == this.TYPE_VISITED) {
      articlesToFind = await this.getViewedArticleIds();
    }

    await this.loadArticleWidgets(articlesToFind);
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  getCookieArticles() {
    this.articleWidgetIds.forEach((articleId: string) => {
      let comp = LayoutComponent.createEmpty("article_widget", {
        type: "horizontal",
        visibility: true,
        content: {
          _id: articleId
        }
      });

      this.cookieArticles.push({
        isVisible: true,
        component: comp
      });
    });
  }

  async loadArticleWidgets(articlesToFind: any) {
    this.loading = true;
    this.articleWidgetIds = [];
    if (articlesToFind.length > 0) {
      let slicedArticlesToFind = articlesToFind.slice(0, 3);
      let result = await Repository.getArticleWidgets(slicedArticlesToFind);

      if (result.type == ResultType.SUCCESS) {
        ((result as any).value as Array<any>)
          .sort(
            (v1: any, v2: any) =>
              slicedArticlesToFind.indexOf(v1.cockpit_link._id) -
              slicedArticlesToFind.indexOf(v2.cockpit_link._id)
          )
          .forEach((article: any) => {
            this.articleWidgetIds.push(article._id);
          });

        this.getCookieArticles();
      }
    }
    this.loading = false;
  }

  @Watch("articleListSaved.updated")
  async updateSavedArticles() {
    if (this.type == this.TYPE_SAVED) {
      let articlesToFind: any = ArticleListSaved.list;
      this.loadArticleWidgets(articlesToFind);
    }
  }

  private async getSavedArticleIds() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      return ArticleListSaved.list;
    }

    DatabaseSavedArticleList.reset();

    this.savedArticleIds = await DatabaseSavedArticleList.get();

    return this.savedArticleIds;
  }

  private async getViewedArticleIds() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      return ArticleListRead.list;
    }

    const resultIds = await ViewedArticleRepository.get();

    if (resultIds.type !== ResultType.SUCCESS || !resultIds.value) {
      return [];
    }

    return resultIds.value;
  }

  setWidgetVisibility(widgetsList: ViewWidget[], event: ArticlePublishedEvent) {
    if (event.isPublished === false) {
      const itemIdx: number = widgetsList.findIndex(
        (item: ViewWidget) =>
          item.component.settings.content._id === event.widgetId
      );

      if (itemIdx !== -1) {
        widgetsList[itemIdx].isVisible = false;
      }
    }
  }
}
