export const RAW_FILTERS = [
  {
    name: "Topics",
    value: "topic",
    items: [
      {
        name: "Nutrition and Wellness",
        value: "nutrition-and-wellness"
      },
      {
        name: "Cancer Treatment Support",
        value: "cancer-treatment-support"
      },
      {
        name: "Emotional Support",
        value: "emotional-support"
      },
      {
        name: "Managing Cancer Symptoms",
        value: "managing-cancer-symptoms"
      },
      {
        name: "Cancer Basics",
        value: "cancer-basics"
      },
      {
        name: "Clinical Trials",
        value: "clinical-trials"
      },
      {
        name: "Cancer Causes",
        value: "cancer-causes"
      },
      {
        name: "Stories of Hope",
        value: "stories-of-hope"
      }
    ]
  },
  {
    name: "Cancer Types",
    value: "cancer_type",
    items: [
      {
        name: "Biliary Tract",
        value: "biliary"
      },
      {
        name: "Blood",
        value: "blood"
      },
      {
        name: "Breast",
        value: "breast"
      },
      {
        name: "Liver",
        value: "liver"
      },
      {
        name: "Lung",
        value: "lung"
      },
      {
        name: "Ovarian",
        value: "ovarian"
      },
      {
        name: "Pancreatic",
        value: "pancreatic"
      },
      {
        name: "Prostate",
        value: "prostate"
      },
      {
        name: "Stomach",
        value: "stomach"
      }
    ]
  },
  {
    name: "Content Types",
    value: "content_type",
    items: [
      {
        name: "Articles",
        value: "articles"
      },
      {
        name: "Stories",
        value: "stories"
      },
      {
        name: "Guides",
        value: "guides"
      },
      {
        name: "Links",
        value: "links"
      }
    ]
  }
];

export const CANCER_SUPPORT_360_APP_BRAND = "cancersupport360_hub";
