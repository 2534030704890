
import { Component, Watch } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

export interface ContentOption {
  _id: string;
  display: string;
}

@Component
export default class ContentFilter extends BaseComponent {
  public selectedContentId: string = "";
  public selectedContentLayout: any = 0;
  public filterCounter: number = 0;

  getComponentType() {
    return "content_filters";
  }

  async mounted() {
    super.mounted();
    this.selectedContentId = "";
    this.selectedContentLayout = 0;
    this.filterCounter = 0;
  }

  contentDidLoad(): void {
    if (this.columns.length) {
      this.selectedContentId = this.columns[0].settings?.content?._id;
    }
    this.filterContent();
  }

  changeSelectedContentId(id: string) {
    this.selectedContentId = id;
  }

  filterContent() {
    this.selectedContentLayout = this.getContentAttribute("content").find(
      contentOption =>
        this.selectedContentId === contentOption.settings.content._id
    );
    this.filterCounter++;
  }

  get selectLabel() {
    return this.getContentAttribute("select_label");
  }

  get columns() {
    return this.getContentAttribute("content");
  }

  get filterOptions(): ContentOption[] {
    return this.getContentAttribute("content")
      .filter(
        filterOption =>
          filterOption.settings.content && filterOption.settings.visibility
      )
      .map(option => {
        return {
          _id: option.settings.content._id,
          display: option.settings.content_filter_option_display
        };
      });
  }
}
