
import { Component, Prop, Vue } from "vue-property-decorator";
import { ResizeSensor } from "css-element-queries";
import {
  EventBus,
  LCMODAL_CLOSED,
  VIDEO_FINISHED,
  matomo,
  MatomoEventNames,
  User
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

type KalturaAnalytics = {
  videoStarted: boolean;
  progressReached: number;
  sentToMatomo: boolean;
  duration: number;
};

@Component
export default class KalturaVideo extends BaseComponent {
  readonly PARTNER_ID: any = "432521";
  readonly UICONF_ID: any = "46292682";

  readonly DISPLAY_INFORMATION_TYPE_OVERLAY = "overlay";
  readonly DISPLAY_INFORMATION_TYPE_ABOVE_VIDEO = "above_video";

  id!: string;
  kalturaPlayer!: any;
  showVideoDetails!: boolean;
  wrapperWidth!: number;
  firstLoad!: boolean;
  analytics!: KalturaAnalytics;

  @Prop() placeholder!: boolean;

  constructor() {
    super();
    this.id =
      "kaltura-video-" +
      Math.random()
        .toString(36)
        .substr(2, 9);
    this.kalturaPlayer = null;
    this.showVideoDetails = true;
    this.wrapperWidth = 0;
    this.firstLoad = true;

    this.analytics = {
      videoStarted: false,
      progressReached: 0,
      sentToMatomo: false,
      duration: 0
    };

    window.onbeforeunload = this.setupMatomoEvents;
    window.addEventListener("beforeunload", this.setupMatomoEvents);
    this.$router.afterEach(this.setupMatomoEvents);

    EventBus.$on(LCMODAL_CLOSED, this.stopVideoOnModalClosed);
  }

  getComponentType() {
    return "kaltura_videos";
  }

  async mounted() {
    await super.mounted();

    // @ts-ignore
    const delayAmount = window.firstVisit ? 1500 : 0;

    setTimeout(() => {
      this.setupKaltura();
    }, delayAmount);
  }

  setupKaltura() {
    this.kalturaPlayer = this.is_interactive
      ? this.getRaptKalturaPlayer()
      : this.getDefaultKalturaPlayer();

    let kc = this.$refs.kalturaComponent as HTMLElement;
    new ResizeSensor(kc, () => {
      this.wrapperWidth = kc.offsetWidth;
    });
  }

  triggerMatomoEvent(
    kalturaEvent: MatomoEventNames,
    kalturaEventData: any | null = null
  ) {
    kalturaEventData === null
      ? matomo.genericEvent(
          this.kaltura_id,
          matomo.MATOMO_EVENT_CATEGORY,
          kalturaEvent
        )
      : matomo.genericDetailedEvent(
          this.kaltura_id,
          matomo.MATOMO_EVENT_CATEGORY,
          kalturaEvent,
          kalturaEventData
        );
  }

  setupMatomoEvents() {
    if (this.analytics.sentToMatomo) {
      return;
    }

    this.analytics.sentToMatomo = true;

    this.triggerMatomoEvent(MatomoEventNames.KALTURA_PAGE_VIEW);

    if (!this.kalturaPlayer) {
      return;
    }

    if (this.analytics.videoStarted) {
      this.triggerMatomoEvent(MatomoEventNames.KALTURA_VIDEO_PLAY);
    }

    if (this.analytics.progressReached > 0) {
      this.triggerMatomoEvent(
        MatomoEventNames.KALTURA_VIDEO_COMPLETION_RATE,
        this.analytics.progressReached
      );
    }

    if (this.analytics.progressReached === 1) {
      this.triggerMatomoEvent(MatomoEventNames.KALTURA_VIDEO_FINISHED);
    }

    this.triggerMatomoEvent(
      MatomoEventNames.KALTURA_VIDEO_VIEW_TIME,
      this.kalturaPlayer.currentTime
    );
  }

  setupDefaultPlayerMatomoEvents(kalturaPlayer: any) {
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.FIRST_PLAY,
      (event: any) => {
        this.analytics.videoStarted = true;
      }
    );

    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.ENDED,
      (event: any) => {
        this.analytics.progressReached = 1;
      }
    );

    const completionRates = [0.25, 0.5, 0.75, 0.98];

    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.TIME_UPDATE,
      (event: any) => {
        if (this.analytics.duration === 0) {
          return;
        }

        const currentTime = kalturaPlayer.currentTime;
        const currentProgress = currentTime / this.analytics.duration;

        let progress = 0;

        for (let i = 0; i < completionRates.length; i++) {
          if (currentProgress >= completionRates[i]) {
            progress = completionRates[i];
          }
        }

        if (progress <= this.analytics.progressReached) {
          return;
        }

        this.analytics.progressReached = progress;

        if (progress === 0.98) {
          this.analytics.progressReached = 1;
          EventBus.$emit(VIDEO_FINISHED, this.kaltura_id);
        }
      }
    );
  }

  setupRaptPlayerMatomoEvents(kalturaPlayer: any) {
    kalturaPlayer.addListener("node:enter", (event: any) => {
      if (!event.payload || event.payload.kind !== "start") {
        return;
      }

      if (this.analytics.videoStarted) {
        return;
      }

      this.analytics.videoStarted = true;
    });

    kalturaPlayer.addListener("player:progress", (event: any) => {
      if (!event.payload || !event.payload.progress) {
        return;
      }

      const progress = event.payload.progress;

      if (progress <= this.analytics.progressReached) {
        return;
      }

      this.analytics.progressReached = progress;

      if (progress === 0.98) {
        this.analytics.progressReached = 1;
        EventBus.$emit(VIDEO_FINISHED, this.kaltura_id);
      }
    });
  }

  displayOverlay() {
    return (
      this.display_information_type == this.DISPLAY_INFORMATION_TYPE_OVERLAY
    );
  }

  displayAbove() {
    return (
      this.display_information_type == this.DISPLAY_INFORMATION_TYPE_ABOVE_VIDEO
    );
  }

  get kaltura_id() {
    return this.getContentAttribute("kaltura_id");
  }

  get scope() {
    return this.getContentAttribute("scope");
  }

  get topic() {
    return this.getContentAttribute("topic");
  }

  get format() {
    return this.getContentAttribute("format");
  }

  get expert() {
    return this.getContentAttribute("expert");
  }

  get title() {
    return this.getContentAttribute("title");
  }

  get description() {
    return this.getContentAttribute("description");
  }

  get clinic() {
    return this.getContentAttribute("clinic");
  }

  get is_interactive() {
    return !!this.getContentAttribute("is_interactive");
  }

  get playIcon() {
    return "/assets/kaltura/play.svg";
  }

  get show_title() {
    return this.getSettingsAttribute("show_title");
  }

  get show_description() {
    return this.getSettingsAttribute("show_description");
  }

  get show_clinic() {
    return this.getSettingsAttribute("show_clinic");
  }

  get display_information_type() {
    if (
      (!this.show_title && !this.show_description && !this.show_clinic) ||
      (!this.title && !this.description && !this.clinic)
    ) {
      return "";
    }

    return this.getSettingsAttribute("display_information_type");
  }

  get info_size() {
    if (this.wrapperWidth < 250) {
      return "size-xs";
    }

    if (this.wrapperWidth < 450) {
      return "size-sm";
    }

    if (this.wrapperWidth < 650) {
      return "size-md";
    }

    if (this.wrapperWidth < 850) {
      return "size-lg";
    }

    if (this.wrapperWidth < 1000) {
      return "size-xl";
    }

    return "size-xxl";
  }

  playVideo() {
    this.kalturaPlayer.play();
  }

  getRaptKalturaPlayer() {
    // @ts-ignore
    const kalturaPlayer = PathKalturaPlayer.setup({
      targetId: this.id,
      provider: {
        partnerId: this.PARTNER_ID,
        uiConfId: this.UICONF_ID,
        ks: User.getProperty("kalturaSecret")
      },
      playback: {
        autoplay: false
      },
      rapt: {}
    });

    kalturaPlayer.loadMedia({ entryId: this.kaltura_id });

    kalturaPlayer.addListener("player:pause", (event: any) => {
      if (this.displayOverlay()) {
        this.showVideoDetails = true;
      }
    });

    kalturaPlayer.addListener("player:play", (event: any) => {
      if (this.displayOverlay()) {
        this.showVideoDetails = false;
      }
    });

    // below is a hack to get the player to play the video from where it left off when the user switches to another node
    let lastNodeTime = 0;

    // this event is triggered before the switch happens and we use it to get the time of the last node
    kalturaPlayer.addListener("hotspot:click", (event: any) => {
      if (kalturaPlayer && kalturaPlayer.currentTime) {
        lastNodeTime = kalturaPlayer.currentTime;
      }
    });

    // this event is triggered after the switch happens and we use it to play the video from the last node
    kalturaPlayer.addListener("node:enter", (event: any) => {
      if (!event.payload || event.payload.kind !== "jump") {
        return;
      }

      kalturaPlayer.seek(lastNodeTime);
    });

    this.setupRaptPlayerMatomoEvents(kalturaPlayer);

    return kalturaPlayer;
  }

  setupVideoName(response: { sources: { metadata: { name: string } } }): void {
    const element = (this as Vue).$refs.kalturaWrapper as HTMLElement;

    if (!element) {
      return;
    }

    // get video by tags
    const videoElements = element.getElementsByTagName("video");

    if (!videoElements || videoElements.length === 0) {
      return;
    }

    videoElements[0].setAttribute("data-name", response.sources.metadata.name);
  }

  getDefaultKalturaPlayer() {
    // @ts-ignore
    const kalturaPlayer = KalturaPlayer.setup({
      targetId: this.id,
      provider: {
        partnerId: this.PARTNER_ID,
        uiConfId: this.UICONF_ID,
        ks: User.getProperty("kalturaSecret")
      },
      playback: {
        autoplay: false
      }
    });

    kalturaPlayer
      .loadMedia({ entryId: this.kaltura_id })
      .then((response: any) => {
        try {
          this.analytics.duration = response.sources.duration || 0;
        } catch (e) {
          this.analytics.duration = 0;
        }

        (this as Vue).$nextTick(() => {
          this.setupVideoName(response);
        });
      });

    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.PAUSE,
      (event: any) => {
        if (this.displayOverlay()) {
          this.showVideoDetails = true;
        }
      }
    );

    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.PLAY,
      (event: any) => {
        if (this.displayOverlay()) {
          this.showVideoDetails = false;
        }
      }
    );

    this.setupDefaultPlayerMatomoEvents(kalturaPlayer);

    return kalturaPlayer;
  }

  stopVideoOnModalClosed(payload: string[]) {
    if (payload.includes(this.kaltura_id)) {
      this.kalturaPlayer.pause();
    }
  }
}
