
import { Component, Vue, Prop } from "vue-property-decorator";
import { InputConfig } from "../ContactForm.vue";

export interface CongressValues {
  ACCPast: boolean;
  EDTAPast: boolean;
  ADAPast: boolean;
  ESCPast: boolean;
  ESC: boolean;
  EASD: boolean;
  ASN: boolean;
  AHA: boolean;
}

@Component
export default class SelectCongress extends Vue {
  @Prop() formConfig!: InputConfig<CongressValues>;
  @Prop() label!: string;
  @Prop() isValid!: boolean;
  @Prop() isSubmitted!: boolean;

  constructor() {
    super();
  }
}
