
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import {
  EventBus,
  ROUTER_AFTER_CHILD,
  ExecutionQueue,
  router,
  RouterPayload,
  ROUTE_NAME_TESTGUIDELINE,
  Helper
} from "common";

@Component
export default class TestGuideline extends BaseComponent {
  showRouterView!: boolean;
  slideSlug!: string;

  constructor() {
    super();
    this.forceLoadStop = true;
    this.showRouterView = false;
    this.slideSlug = "";
  }

  beforeMount() {
    this.startGuideline();
  }

  async mounted() {
    await super.mounted();

    EventBus.$on(ROUTER_AFTER_CHILD, (payload: RouterPayload) => {
      if (payload.name == ROUTE_NAME_TESTGUIDELINE) {
        this.startGuideline();
        Vue.nextTick(() => {
          ExecutionQueue.addElement(
            Helper.crawlDomAndAddEventlistenerToAllLinks
          );
        });
      }
    });

    if (this.showRouterView) {
      ExecutionQueue.addElement(this.scrollToElement);
    }
  }

  updated() {
    if (this.showRouterView) {
      ExecutionQueue.addElement(this.scrollToElement);
    }
  }

  beforeDestroy() {
    EventBus.$off(ROUTER_AFTER_CHILD);
  }

  startGuideline() {
    this.showRouterView =
      router.currentRoute.params.guideline_id == this.settings_guideline_id;

    if (router.currentRoute.params.slide_slug) {
      return router.currentRoute.params.slide_slug;
    } else {
      this.slideSlug = this.starting_slide;
    }
  }

  get settings_guideline_id() {
    return this.getSettingsAttribute("guideline_id");
  }

  get name() {
    return this.getSettingsAttribute("name");
  }

  get starting_slide() {
    let slide = this.getSettingsAttribute("starting_slide");
    if (slide) {
      return slide.display;
    }

    return "";
  }

  get route() {
    return router.currentRoute;
  }

  scrollToElement() {
    let element = this.$refs.guideline as HTMLElement;
    let offsetTop = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo(0, offsetTop);
  }
}
