import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class TrainingQuizRatingRepository extends AbstractRepository {
  async postRating(userData: object) {
    let response = await Client.post(
      Constants.trainingQuizRatingUrl,
      userData,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }
}

export default new TrainingQuizRatingRepository();
