
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Iframe extends BaseComponent {
  public heightAddition?: number;

  constructor() {
    super();
  }

  async mounted() {
    await super.mounted();

    let iframe = this.$refs.iframe as HTMLIFrameElement;
    let self = this;
    if (iframe) {
      iframe.onload = () => {
        self.setHeight();
        iframe.contentWindow!.addEventListener("mouseup", self.setHeight);
      };
    }
  }

  get iframeSrc() {
    return this.getSettingsAttribute("src");
  }

  get dynamicHeight() {
    return this.getSettingsAttribute("dynamic_height") || false;
  }

  get height() {
    return this.getSettingsAttribute("height");
  }

  setHeight() {
    let iframe = this.$refs.iframe as HTMLIFrameElement;

    if (iframe) {
      if (!this.dynamicHeight) {
        iframe.style.height = this.height + "px";
      } else if (iframe.contentWindow!.document.body) {
        var iframeHeight = iframe.contentWindow!.outerHeight;
        var scrollHeight = iframe.contentWindow!.document.body.scrollHeight;

        if (!this.heightAddition) {
          this.heightAddition = iframe.contentWindow!.innerHeight;
        }

        iframe.style.height =
          Math.min(iframeHeight, scrollHeight + this.heightAddition) + "px";
      }
    }
  }
}
