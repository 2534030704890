
import { Component } from "vue-property-decorator";
import validator from "validator";
import { CustomContactFormRepository, ResultType } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CustomContactForm extends BaseComponent {
  attemptSubmit: Boolean;
  submitSuccessfull: Boolean;
  loading: boolean;
  error: string;
  email: string;
  answers: any[];

  constructor() {
    super();
    this.attemptSubmit = false;
    this.submitSuccessfull = false;
    this.loading = false;
    this.error = "";
    this.email = "";
    this.answers = [];
  }

  getComponentType() {
    return "custom_contact_forms";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
  }

  get placeholder() {
    return this.getContentAttribute("placeholder") || "E-Mail Adresse";
  }

  get submitBtnText() {
    return this.getContentAttribute("submit_btn_text") || "Submit";
  }

  get submitBtnClass() {
    return this.getContentAttribute("submit_btn_class") || "";
  }

  get questions() {
    return this.getContentAttribute("questions");
  }

  get successMessage() {
    return (
      this.getContentAttribute("success_message") ||
      "Ihre Anfrage wurde erfolgreich übermittelt"
    );
  }

  validateEmail(email: any) {
    return !validator.isEmpty(email) && validator.isEmail(email);
  }

  async validate(e: any) {
    this.error = "";
    this.submitSuccessfull = false;

    let validEmail = this.validateEmail(this.email);

    if (validEmail && this.answers.length) {
      this.loading = true;
      const response = [];

      for (let i = 0; i <= this.questions.length - 1; i++) {
        if (this.answers.includes(this.questions[i].value)) {
          response.push(this.questions[i].value + " = yes");
        } else {
          response.push(this.questions[i].value + " = no");
        }
      }

      this.answers = response;

      const contactResponse: any = await CustomContactFormRepository.save(
        this.email,
        this.answers
      );

      // Show notifications
      if (contactResponse.type === ResultType.SUCCESS) {
        this.submitSuccessfull = true;
      }

      this.loading = false;
    } else if (!validEmail) {
      this.error = "The email address is not valid";
    } else {
      this.error = "Something went wrong, please try again or contact us";
    }
  }
}
