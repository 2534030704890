import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class TrainingQuizRepository extends AbstractRepository {
  async getQuiz(quizId: string) {
    const data = {
      simple: 1,
      filter: {
        id: quizId
      }
    };

    let response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/get/training_quizzes`,
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return {
        participants: Number.parseInt(r[0].participants)
      } as any;
    });

    return result;
  }

  async getAnswer(questionId: string) {
    const data = {
      simple: 1,
      filter: {
        "question._id": questionId
      }
    };

    let response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/get/training_quiz_answers`,
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return {
        answer: String(r[0].answer)
      } as any;
    });

    return result;
  }

  async saveQuiz(quizId: string, participants: number) {
    const dataToSave = {
      data: {
        _id: quizId,
        participants
      }
    };

    const response = await Client.post(
      `${Constants.collectionsUrl}/save/training_quizzes`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async saveQuizResponse(userData: object) {
    const response = await Client.postWithJsonResult(
      Constants.trainingQuizFinalizeUrl,
      userData,
      this.getAdditionalHeader()
    );
    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async startQuiz(userData: object) {
    const response = await Client.getJson(
      Constants.trainingQuizUrl,
      userData,
      this.getAdditionalHeader()
    );
    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async startQuizAfterVideo(userData: object) {
    const response = await Client.putWithJsonResult(
      Constants.trainingQuizVideoUrl,
      userData,
      this.getAdditionalHeader()
    );
    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async nextQuestion(userData: object) {
    const response = await Client.postWithJsonResult(
      Constants.trainingQuizAnswerUrl,
      userData,
      this.getAdditionalHeader()
    );
    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }
}

export default new TrainingQuizRepository();
