
import { Component, Vue } from "vue-property-decorator";
import {
  EventBus,
  FILTER_ARTICLES_BY_TAG,
  FILTER_ARTICLES_BY_TAG_DONE,
  LayoutComponent,
  mapLayoutComponentToCollection,
  FilterRepository,
  ResultType,
  Store
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import DynamicViewWidget, {
  Type
} from "../widgets/dynamic/DynamicViewWidget.vue";

@Component({
  components: {
    DynamicViewWidget
  }
})
export default class TagFilter extends BaseComponent {
  public Type = Type;
  public contentItems!: any[];
  public activeFilters!: string[];
  public checkedTags!: string[] | any;
  public loading!: boolean;
  public visibleItemsLength!: number;

  constructor() {
    super();
    this.contentItems = [];
    this.activeFilters = [];
    this.checkedTags = [];
    this.loading = true;
    this.visibleItemsLength = this.pageSize;

    EventBus.$on(FILTER_ARTICLES_BY_TAG, this.triggerFilterFromExternal);
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.activeFilters = this.tags.map((item: any) => item._id);
      this.contentItems = await this.loadContent();
    });
  }

  get onlyOneActive() {
    return this.getSettingsAttribute("only_one_active");
  }

  get layoutTypes() {
    return this.getSettingsAttribute("layout") || [];
  }

  get collection() {
    return this.getSettingsAttribute("content") || null;
  }

  get tags() {
    const tags = this.getSettingsAttribute("tags");
    if (tags && tags.length > 0) {
      return tags;
    }

    return [];
  }

  get itemsClass() {
    return this.getSettingsAttribute("result_items_class") || "";
  }

  get itemsWidth() {
    return this.getSettingsAttribute("result_items_width");
  }

  get filterButtonsClass() {
    return this.getSettingsAttribute("filter_buttons_class") || "";
  }

  get filterResetBtnText() {
    return this.getSettingsAttribute("filter_reset_btn_text");
  }

  get paginationEnabled() {
    return !!this.getSettingsAttribute("enable_pagination");
  }

  get pageSize() {
    const result = parseInt(this.getSettingsAttribute("page_size"));
    return !isNaN(result) ? result : 6;
  }

  get paginationBtnText() {
    return this.getSettingsAttribute("pagination_btn_text") || "";
  }

  get paginationBtnClass() {
    return this.getSettingsAttribute("pagination_btn_class") || "";
  }

  get selectPlaceholder() {
    return this.getSettingsAttribute("select_placeholder");
  }

  get sortBy() {
    return this.getSettingsAttribute("sort_by");
  }

  get itemsToRender(): any[] {
    if (this.contentItems.length === 0) {
      return [];
    }

    const resultArr = this.paginationEnabled
      ? this.contentItems.slice(0, this.visibleItemsLength)
      : this.contentItems;

    if (this.collection === "articles") {
      return resultArr.map(item => {
        return {
          display: "",
          link: "articles",
          _id: item._id
        };
      });
    }

    return resultArr.map((item: any) =>
      LayoutComponent.createFromCollectionItem(
        mapLayoutComponentToCollection[this.collection],
        item,
        {
          class: this.itemsClass,
          width: this.itemsWidth
        }
      )
    );
  }

  async loadContent() {
    // check external filter tag from localstorage
    const activeTagNameFromLocalStorage = localStorage.getItem(
      FILTER_ARTICLES_BY_TAG
    );
    if (activeTagNameFromLocalStorage) {
      localStorage.removeItem(FILTER_ARTICLES_BY_TAG);
      const activeTagFilterId = (
        this.tags.filter(
          (item: any = {}) => item.display === activeTagNameFromLocalStorage
        )[0] || {}
      )._id;

      if (activeTagFilterId) {
        this.activeFilters = [activeTagFilterId];
        this.checkedTags = [activeTagFilterId];
      }
    }

    this.loading = true;
    let response = await FilterRepository.getCollectionFilteredByTags(
      this.collection,
      this.activeFilters,
      Store.frontend + "_hub",
      this.sortBy
    );
    this.loading = false;
    this.visibleItemsLength = this.pageSize;

    EventBus.$emit(
      FILTER_ARTICLES_BY_TAG_DONE,
      this.tags.filter((tag: any) => this.activeFilters.includes(tag._id))
    );

    if (response.type == ResultType.SUCCESS && response.value !== null) {
      return response.value;
    }

    return [];
  }

  resetFilters() {
    if (this.checkedTags.length > 0) {
      this.onCheckboxGroupChange([]);
    }
  }

  loadMore() {
    if (this.visibleItemsLength >= this.contentItems.length) {
      return;
    }
    this.visibleItemsLength += this.pageSize;
  }

  async onCheckboxGroupChange(val: string[]) {
    if (this.onlyOneActive && val.length > 1) {
      const lastTagSelected = val[val.length - 1];
      this.checkedTags = this.activeFilters.includes(lastTagSelected)
        ? []
        : [lastTagSelected];
      this.activeFilters = [lastTagSelected];
    } else {
      if (val.length > 0) {
        this.activeFilters = [...val];
      } else {
        this.activeFilters = this.tags.map((item: any) => item._id);
      }
    }

    this.contentItems = await this.loadContent();
  }

  onSelectChange(val: string[] | string | undefined) {
    if (Array.isArray(val)) {
      this.checkedTags = [...val];
    } else if (val) {
      this.checkedTags = [val];
    } else {
      this.checkedTags = [];
    }

    this.onCheckboxGroupChange(this.checkedTags);
  }

  triggerFilterFromExternal(tagName: any) {
    if (!tagName) return;

    const selectedTag: any = this.tags.filter(
      (tag: any = {}) => tag.display === tagName
    )[0];

    if (selectedTag && selectedTag._id) {
      if (this.onlyOneActive) {
        this.checkedTags = [selectedTag._id];
      } else {
        this.checkedTags = [...this.checkedTags, selectedTag._id];
      }
      this.onCheckboxGroupChange([selectedTag._id]);
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
