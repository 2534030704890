var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"expertforum-patient-register",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-username"}},[_vm._v("Benutzername*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateUsername,
              'is-valid': _vm.attemptSubmit && _vm.validateUsername
            },attrs:{"id":"register-username","type":"text","required":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-email"}},[_vm._v("e-Mail-Adresse*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateEmail,
              'is-valid': _vm.attemptSubmit && _vm.validateEmail
            },attrs:{"id":"register-email","type":"email","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorEmail)}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-password"}},[_vm._v("Passwort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePassword,
              'is-valid': _vm.attemptSubmit && _vm.validatePassword
            },attrs:{"id":"register-password","type":"password","required":"","autocomplete":"new-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorPassword)}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Passwort wiederholen*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateConfirmPassword,
              'is-valid': _vm.attemptSubmit && _vm.validateConfirmPassword
            },attrs:{"id":"confirm-password","type":"password","required":""},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}})])]),(_vm.infoTextFirst)?_c('div',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.infoTextFirst)}}):_vm._e(),(_vm.checkboxTextFirst)?_c('div',{staticClass:"col-12 mb-6"},[_c('label',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxFirst),expression:"checkboxFirst"}],class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateCheckboxFirst,
              'is-valid': _vm.attemptSubmit && _vm.validateCheckboxFirst
            },attrs:{"type":"checkbox","required":""},domProps:{"checked":Array.isArray(_vm.checkboxFirst)?_vm._i(_vm.checkboxFirst,null)>-1:(_vm.checkboxFirst)},on:{"change":function($event){var $$a=_vm.checkboxFirst,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxFirst=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxFirst=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxFirst=$$c}}}}),_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.checkboxTextFirst)}})])]):_vm._e(),(_vm.infoTextSecond)?_c('div',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.infoTextSecond)}}):_vm._e(),(_vm.checkboxTextSecond)?_c('div',{staticClass:"col-12 mb-6"},[_c('label',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSecond),expression:"checkboxSecond"}],class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateCheckboxSecond,
              'is-valid': _vm.attemptSubmit && _vm.validateCheckboxSecond
            },attrs:{"type":"checkbox","required":""},domProps:{"checked":Array.isArray(_vm.checkboxSecond)?_vm._i(_vm.checkboxSecond,null)>-1:(_vm.checkboxSecond)},on:{"change":function($event){var $$a=_vm.checkboxSecond,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSecond=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSecond=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSecond=$$c}}}}),_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.checkboxTextSecond)}})])]):_vm._e()]),(_vm.requestSent)?_c('div',{staticClass:"form-feedback"},[(_vm.apiError)?_c('div',{staticClass:"row submit-error"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.resultMessage)}})])]):_c('div',{staticClass:"row submit-success"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")])])])]):_vm._e(),(!_vm.accountCreated)?_c('div',{staticClass:"controls text-center text-md-left"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-primary"},[_c('b',{staticClass:"px-3"},[_vm._v("Registrieren")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"waiting"},[_c('span',{staticClass:"spinner-border spinner-border-md mr-2"}),_c('span',[_vm._v("Bitte warten...")])]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }