import { Success, ResultType, Result, Fail } from "../util/Result";
import BaseResponse from "../responses/BaseResponse";
import User from "../../models/User";

abstract class AbstractRepository {
  /*
   * Checks if the request was successfully and the responseCode meets the needed criteria (== 1).
   * Returns a Result.Fail() with the server's error message.
   * Transforms a BaseResponse into the passed type using the passed conversion funciton and returns it as a
   * Result.Success(Object).
   * You should switch over result.type to differentiate between ResultType.SUCCESS or ResultType.FAIL to get an
   * unwrapped result object or error message.
   */

  protected getResult<TIn extends BaseResponse, TOut>(
    response: Result<TIn>,
    convert: (data: TIn) => TOut
  ): Result<TOut> {
    switch (response.type) {
      case ResultType.SUCCESS:
        if (response.value) {
          let result = convert(response.value);
          return Success(result);
        } else {
          return Fail("Wrong Json.");
        }
      case ResultType.FAIL:
        return response;
    }
  }

  protected getAdditionalHeader() {
    return User.getProperty("accessToken") != null
      ? [{ Authorization: "Bearer " + User.getProperty("accessToken") }]
      : null;
  }

  protected getRefreshTokenBearer() {
    return User.getProperty("refreshToken") != null
      ? [{ Authorization: "Bearer " + User.getProperty("refreshToken") }]
      : null;
  }
}

export default AbstractRepository;
