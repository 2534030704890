import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class FilterRepository extends AbstractRepository {
  async getCollectionFilteredBySegmentation(
    collection: string,
    limit: number = 10,
    fill: boolean = true
  ) {
    let data: any = {
      collection: collection,
      limit: limit,
      fill: fill
    };

    let response = await Client.postWithJsonResult(
      Constants.filterUrl + "/segmentation",
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async getCollectionFilteredByTags(
    collection: string,
    tags: string[],
    appBrand: string,
    sortBy: string | null
  ) {
    let data = {
      collection,
      tags,
      app_brand: appBrand,
      sort_by: sortBy
    };

    let response = await Client.postWithJsonResult(
      Constants.apiUrl + "/cockpit/api/tagfilter",
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r : null;
    });

    return result;
  }
}

export default new FilterRepository();
