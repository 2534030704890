
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GuidelineForm extends BaseComponent {
  getComponentType() {
    return "guideline_forms";
  }

  @Prop() keepAnswers!: any;

  async mounted() {
    super.mounted();
    Vue.nextTick(() => {
      if (!this.keepAnswers) {
        localStorage.setItem("guidelineFormAnswers", JSON.stringify({}));
      }
    });
  }

  get components() {
    return this.getContentAttribute("content");
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }
}
