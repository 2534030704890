
import { Component, Vue } from "vue-property-decorator";
import { RequestResponseContent, RESPONSES } from "common";

@Component
export default class GynoncoUnsubscribeConfirmation extends Vue {
  public html: RequestResponseContent;

  constructor() {
    super();
    this.html = RESPONSES.gynoncoUnsubscribeSuccess;
  }
}
