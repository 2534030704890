import { Result, Success, Fail } from "./Result";

class Client {
  private headers: any = {
    "Content-Type": "application/json"
  };

  additionalHeaders(headers: []) {
    let additionalHeaders: any = {};
    headers.forEach((header: any) => {
      additionalHeaders = Object.assign({}, additionalHeaders, header);
    });

    return Object.assign({}, this.headers, additionalHeaders);
  }

  async post(
    url: string,
    data: any,
    additionalHeaders: any = null,
    failedMessageProcessing: boolean = false
  ): Promise<Result<any>> {
    try {
      var response = await fetch(url, {
        method: "POST",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers,
        body: JSON.stringify(data)
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      return Success({});
    } else {
      if (!failedMessageProcessing) {
        return Fail(response.statusText, response.status);
      } else {
        var raw = await response.text();
        return Fail(response.statusText, response.status, raw);
      }
    }
  }

  async put(
    url: string,
    data: any,
    additionalHeaders: any = null
  ): Promise<Result<any>> {
    try {
      var response = await fetch(url, {
        method: "PUT",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers,
        body: JSON.stringify(data)
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      return Success({});
    } else {
      return Fail(response.statusText, response.status);
    }
  }

  async postWithJsonResult(
    url: string,
    data: any,
    additionalHeaders: any = null
  ): Promise<Result<any>> {
    try {
      var response = await fetch(url, {
        method: "POST",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers,
        body: JSON.stringify(data)
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    var json = undefined;
    var html = undefined;

    if (response.ok) {
      json = await response.json();
    } else {
      html = await response.text();
    }

    if (json != undefined) {
      return Success(json);
    } else {
      return Fail(response.statusText, response.status, html);
    }
  }

  async get(url: string, data: any): Promise<Result<any>> {
    url = this.getUrlWithData(url, data);

    try {
      var response = await fetch(url);
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      let value = await response.text();
      return Success(value);
    } else {
      return Fail(response.statusText);
    }
  }

  async getJson(
    url: string,
    data: any,
    additionalHeaders: any = null
  ): Promise<Result<any>> {
    url = this.getUrlWithData(url, data);

    try {
      var response = await fetch(url, {
        method: "GET",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    var json = undefined;
    var html = undefined;

    if (response.ok) {
      json = await response.json();
    } else {
      html = await response.text();
    }

    if (json != undefined) {
      return Success(json);
    } else {
      return Fail(response.statusText, response.status, html);
    }
  }

  getUrlWithData(url: string, data: any): string {
    var i = 0;
    for (var attr in data) {
      if (i == 0) {
        url += "?";
      } else {
        url += "&";
      }
      i++;

      url += attr + "=" + encodeURIComponent(data[attr]);
    }

    return url;
  }

  async head(url: string) {
    try {
      var response = await fetch(url, {
        method: "HEAD"
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      return Success({});
    } else {
      return Fail(response.statusText);
    }
  }

  async delete(url: string, additionalHeaders: any = null) {
    try {
      var response = await fetch(url, {
        method: "DELETE",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      return Success({});
    } else {
      return Fail(response.statusText);
    }
  }

  async patchWithJsonResult(
    url: string,
    body: any,
    additionalHeaders: any = null
  ) {
    try {
      var response = await fetch(url, {
        method: "PATCH",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers,
        body: JSON.stringify(body)
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    var json = undefined;
    if (response.ok) {
      json = await response.json();
    }

    if (json != undefined) {
      return Success(json);
    } else {
      return Fail(response.statusText);
    }
  }

  async putWithJsonResult(
    url: string,
    body: any,
    additionalHeaders: any = null,
    queryParameter?: any
  ): Promise<Result<any>> {
    url = this.getUrlWithData(url, queryParameter);

    try {
      var response = await fetch(url, {
        method: "PUT",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers,
        body: JSON.stringify(body)
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    var json = undefined;
    if (response.ok) {
      json = await response.json();
    }

    if (json != undefined) {
      return Success(json);
    } else {
      var raw = await response.text();
      return Fail(response.statusText, response.status, raw);
    }
  }

  async postFile(url: string, body: any) {
    try {
      var response = await fetch(url, {
        method: "POST",
        body: body
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    var json = undefined;
    if (response.ok) {
      json = await response.json();
    }

    if (response.ok) {
      return Success(json);
    } else {
      return Fail(response.statusText);
    }
  }

  async getFile(url: string, additionalHeaders: any = null) {
    try {
      var response = await fetch(url, {
        method: "GET",
        headers:
          additionalHeaders != null
            ? this.additionalHeaders(additionalHeaders)
            : this.headers
      });
    } catch (e) {
      return Fail((e as any).message);
    }

    if (response.ok) {
      return Success(response);
    } else {
      return Fail(response.statusText);
    }
  }
}

export default new Client();
