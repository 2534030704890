
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LoginBox extends BaseComponent {
  activeContent: string = "";

  constructor() {
    super();
  }

  getComponentType() {
    return "login_boxes";
  }

  async mounted() {
    await super.mounted();
    Vue.nextTick(() => {
      (this.$refs.btn_login as HTMLElement).click();
    });
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get login() {
    return this.getContentAttribute("login");
  }

  get loginContent() {
    return this.getContentAttribute("login_content");
  }

  get loginLabel() {
    return this.getContentAttribute("login_label");
  }

  get docCheckLogin() {
    return this.getContentAttribute("doccheck_login");
  }

  get docCheckLoginContent() {
    return this.getContentAttribute("doccheck_login_content");
  }

  get docCheckLoginLabel() {
    return this.getContentAttribute("doccheck_login_label");
  }

  get registerButton() {
    return this.getContentAttribute("register_button");
  }

  get registerContent() {
    return this.getContentAttribute("register_content");
  }

  get registerLabel() {
    return this.getContentAttribute("register_label");
  }

  get loginIcon() {
    return "/assets/userinfo/logged-out.svg";
  }

  get docCheckLoginIcon() {
    return "/assets/doccheck/logo_small.svg";
  }

  get registerLogo() {
    return "/assets/doccheck/register_logo.svg";
  }

  showContent(el: string) {
    this.activeContent = el;
  }
}
