
import { Component, Prop } from "vue-property-decorator";
import validator from "validator";
import _ from "lodash";
import {
  LayoutComponent,
  ResultType,
  Store,
  TrainingQuizRepository
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum AlertType {
  SUCCESS = "success",
  ERROR = "error"
}

@Component
export default class LcTrainingQuizResult extends BaseComponent {
  @Prop() quizId!: string;
  @Prop() userId!: string;
  @Prop() thankyou!: any;
  @Prop() trainingCategory!: any;

  public loading: boolean = false;
  public attemptSubmit: boolean = false;
  public AlertType = AlertType;
  public alertType: string = "";
  public showAlert: boolean = false;
  public alertMessage: string = "";
  public saveSuccessMessage: string | undefined;
  // From model
  public name: string = "";
  public praxisort: string = "";
  public efn: string = "";

  get validateName() {
    return !validator.isEmpty(this.name);
  }

  get validatePraxisort() {
    return !validator.isEmpty(this.praxisort);
  }

  get validateEfn() {
    return !validator.isEmpty(this.efn);
  }

  get isDisabled() {
    return !(this.validateName && this.validatePraxisort && this.validateEfn);
  }

  async validate(e: any) {
    this.attemptSubmit = true;

    e.preventDefault();
    e.stopPropagation();

    if (this.validateName && this.validatePraxisort && this.validateEfn) {
      this.loading = true;

      let userData = {
        userId: this.userId,
        trainingQuizId: this.quizId,
        name: this.name,
        location: this.praxisort,
        efn: this.efn
      };

      // Save QuizEntry
      const quizResponse: any = await TrainingQuizRepository.saveQuizResponse(
        userData
      );

      if (quizResponse.type === ResultType.SUCCESS) {
        // Show notifications
        this.setAlert("success");
      }

      // Reset model
      this.attemptSubmit = false;

      Store.modal.className = "modal-lg quiz__modal-result";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("training-quiz-confirmation-modal", {
          userId: this.userId,
          trainingQuizId: this.quizId
        })
      );
      Store.modal.showModal();
    }

    this.loading = false;
  }

  setAlert(response: any) {
    const { type, message } = response;

    if (type === ResultType.SUCCESS) {
      this.showAlert = true;
      this.alertType = AlertType.SUCCESS;
    }
  }

  handleCloseAlert() {
    this.showAlert = false;
  }
}
