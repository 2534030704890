
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import BaseComponent from "../base/BaseComponent.vue";
import { ExpertForumThread, ExpertForumUser } from "common";

@Component
export default class ThreadItem extends BaseComponent {
  @Prop() thread!: ExpertForumThread;

  async mounted() {
    super.mounted();
  }

  formatDate(date: string | null): string {
    try {
      return moment(date).format("MMM DD, YYYY h:mm");
    } catch (e) {
      return "";
    }
  }

  getName(user: ExpertForumUser) {
    return user.firstName + " " + user.lastName;
  }

  goToThread() {
    this.$router.push(
      `/${process.env.VUE_APP_EXPERTFORUM_SLUG}/threads/show?id=${this.thread.id}`
    );
  }
}
