import User from "../../models/User";
import { Store } from "../../util/Store";
import { KalturaClient } from "kaltura-typescript-client";
import { KalturaSessionType } from "kaltura-typescript-client/api/types/KalturaSessionType";
import { SessionEndAction } from "kaltura-typescript-client/api/types/SessionEndAction";
import { SessionStartAction } from "kaltura-typescript-client/api/types/SessionStartAction";
import { KalturaAppToken } from "kaltura-typescript-client/api/types/KalturaAppToken";
import { KalturaAppTokenHashType } from "kaltura-typescript-client/api/types/KalturaAppTokenHashType";
import { AppTokenAddAction } from "kaltura-typescript-client/api/types/AppTokenAddAction";
import { AppTokenDeleteAction } from "kaltura-typescript-client/api/types/AppTokenDeleteAction";
import { AppTokenStartSessionAction } from "kaltura-typescript-client/api/types/AppTokenStartSessionAction";

/**
 * Handle all stuff that is kaltura session related
 * - create admin session
 * - end admin session
 * - create app token
 * - delete app token
 * - create token session
 */
class KalturaSession {
  readonly DEFAULT_EXPIRY = 86400;
  readonly DEFAULT_PRIVILEGES = "disableentitlement";
  readonly DEFAULT_CLIENT_CONFIG = {
    clientTag: "MMC",
    endpointUrl: "https://www.kaltura.com"
  };
  readonly DEFAULT_PARTNER_ID = 432521;

  readonly KALTURA_SESSION_TYPE_ADMIN = KalturaSessionType.admin;
  readonly KALTURA_SESSION_TYPE_USER = KalturaSessionType.user;
  readonly KALTURA_HASH_TYPE = KalturaAppTokenHashType.sha256;

  private static instance?: KalturaSession;

  private client!: KalturaClient;
  private secret!: string;
  private userId!: string;
  private partnerId!: number;
  private kalturaSecret!: string;
  private token?: KalturaAppToken;

  constructor() {
    this.client = new KalturaClient(this.DEFAULT_CLIENT_CONFIG);
    this.secret = Store.kalturaSessionInfo.secret;
    this.userId = Store.kalturaSessionInfo.userId;
    this.partnerId = this.DEFAULT_PARTNER_ID;
  }

  /**
   * Singleton instance for KalturaSession
   */
  public static getInstance(): KalturaSession {
    if (!this.instance) {
      this.instance = new KalturaSession();
    }
    return this.instance;
  }

  /**
   * Entrypoint for creating kaltura session for mmc user
   * 1. Starts an admin session
   * 2. Creates an app token
   * 3. Starts a token session
   */
  public async startUserSession() {
    try {
      await this.startAdminSession();
      await this.createAppToken();
      await this.startTokenSession();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Kaltura session could not be started", err);
    }
  }

  /**
   * The only way for a mmc user to end the kaltura session
   * 1. Delete the existing app token (backend and frontend)
   * 2. End the kaltura admin session
   */
  public async endUserSession() {
    await this.deleteAppToken();
    await this.endAdminSession();
  }

  /**
   * Start the kaltura session for user type admin, to generate a KS
   * that can be used for all further API calls (createToken, startTokenSession, etc.)
   */
  private async startAdminSession() {
    try {
      let secret = this.secret;
      let userId = this.userId;
      let type = this.KALTURA_SESSION_TYPE_ADMIN;
      let partnerId = this.partnerId;
      let expiry = this.DEFAULT_EXPIRY;
      let privileges = this.DEFAULT_PRIVILEGES;

      await this.getAdminClient()
        .request(
          new SessionStartAction({
            secret,
            userId,
            type,
            partnerId,
            expiry,
            privileges
          })
        )
        .then(
          (ks: any) => {
            // User.setKalturaAdminSecret(ks);
            User.updateAndSaveToStorage({
              kalturaAdminSecret: ks
            });
          },
          (err: any) => {}
        );
    } catch (e) {
      //
    }
  }

  /**
   * End the kaltura admin session
   */
  private async endAdminSession() {
    try {
      await this.getAdminClient()
        .request(new SessionEndAction({}))
        .then(
          (response: any) => {
            // User.deleteKalturaAdminSecret();
            // User.deleteKalturaSecret();
            User.updateAndSaveToStorage({
              kalturaAdminSecret: undefined,
              kalturaSecret: undefined
            });
          },
          (err: any) => {}
        );
    } catch (e) {
      // User.deleteKalturaAdminSecret();
      // User.deleteKalturaSecret();
      User.updateAndSaveToStorage({
        kalturaAdminSecret: undefined,
        kalturaSecret: undefined
      });
    }
  }

  /**
   * https://developer.kaltura.com/console/service/appToken/action/startSession
   * API call starts a kaltura token session with a given token
   * The token stores information about the generated session like expiry, userId, etc.
   */
  private async startTokenSession() {
    let id = User.getProperty("kalturaAppToken");

    if (!id || !this.token) {
      throw new Error("kaltura session could not be started");
    }

    try {
      let tokenHash = this.generateTokenHash(
        User.getProperty("kalturaAdminSecret"),
        this.token.token
      );
      let userId = this.userId;
      let type = this.KALTURA_SESSION_TYPE_USER;
      let expiry = this.DEFAULT_EXPIRY;
      let sessionPrivileges = this.DEFAULT_PRIVILEGES;

      await this.getAdminClient()
        .request(
          new AppTokenStartSessionAction({
            id,
            tokenHash,
            userId,
            type,
            expiry,
            sessionPrivileges
          })
        )
        .then(
          (response: any) => {
            // User.setKalturaSecret(response.ks);
            User.updateAndSaveToStorage({
              kalturaSecret: response.ks
            });
          },
          (err: any) => {}
        );
    } catch (e) {
      //
    }
  }

  /**
   * https://developer.kaltura.com/console/service/appToken/action/add
   * API call for add a new app token. App tokens are used to create
   * token sessions, that can be used to distinguish users
   */
  private async createAppToken() {
    /*
     * Fun fact:
     * the appToken.expiry value - in comparison to all other kaltura expiry values -
     * does not expect a duration in seconds, but a unix timestamp for the date in the
     * future to tell it how long it is valid.
     *
     * So we set this to 1 year in the future
     */
    let tokenExpiryDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );

    let appToken = new KalturaAppToken();
    appToken.expiry = tokenExpiryDate.getTime();
    appToken.hashType = this.KALTURA_HASH_TYPE;
    appToken.sessionDuration = this.DEFAULT_EXPIRY;
    appToken.sessionType = this.KALTURA_SESSION_TYPE_USER;
    appToken.sessionPrivileges = "disableentitlement";
    appToken.sessionUserId = User.getProperty("kalturaTrackingId");
    appToken.description = "";

    await this.getAdminClient()
      .request(new AppTokenAddAction({ appToken }))
      .then(
        (response: any) => {
          // User.setKalturaAppToken(response.id);
          User.updateAndSaveToStorage({
            kalturaAppToken: response.id
          });
          this.token = response as KalturaAppToken;
        },
        (err: any) => {
          //
        }
      );
  }

  /**
   * https://developer.kaltura.com/console/service/appToken/action/delete
   * API call to delete a formerly created app token
   */
  private deleteAppToken() {
    let id = User.getProperty("kalturaAppToken");

    if (!id) {
      return;
    }

    this.getAdminClient()
      .request(new AppTokenDeleteAction({ id }))
      .then(
        (response: any) => {
          // User.deleteKalturaAppToken();
          User.updateAndSaveToStorage({
            kalturaAppToken: undefined
          });
          this.token = undefined;
        },
        (err: any) => {}
      );
  }

  /**
   * Helper function to start the app token session.
   * https://developer.kaltura.com/api-docs/VPaaS-API-Getting-Started/application-tokens.html
   */
  private generateTokenHash(ks: string, tokenValue: string) {
    let crypto = require("crypto");
    let shasum = crypto.createHash("sha256");
    shasum.update(ks + tokenValue);
    return shasum.digest("hex");
  }

  /**
   * Getter function for the KalturaClient, add kaltura secret (KS) if available
   */
  public getClient(): KalturaClient {
    if (User.getProperty("kalturaSecret")) {
      let ks = User.getProperty("kalturaSecret");
      this.client.setDefaultRequestOptions({ ks });
    }

    return this.client;
  }

  /**
   * Getter function for the KalturaClient,
   * add kaltura secret (KS) from admin session if available
   */
  public getAdminClient(): KalturaClient {
    if (User.getProperty("kalturaAdminSecret")) {
      let ks = User.getProperty("kalturaAdminSecret");
      this.client.setDefaultRequestOptions({ ks });
    }

    return this.client;
  }

  public getSecret(): string {
    return this.secret;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getPartnerId(): number {
    return this.partnerId;
  }

  public getKalturaSecret(): string {
    return this.kalturaSecret;
  }
}

export default KalturaSession.getInstance();
