import { APP_DATA_LOADED, EventBus } from "../util/EventBus";
import Helper from "../util/Helper";

class ScrollToAnAnchorAfterDataLoaded {
  private checkElementTimeInterval: any = null;
  private scrollToElementTimer: any = null;
  private maxIntervalRuns: number = 10;

  constructor(scrollOffset: number = 0) {
    this.init(scrollOffset);
  }

  init(scrollOffset: number) {
    EventBus.$on(APP_DATA_LOADED, () => {
      clearInterval(this.checkElementTimeInterval);
      clearTimeout(this.scrollToElementTimer);
      let possibleIdName: any = location.hash
        ? location.hash.replace("#", "")
        : null;

      if (possibleIdName) {
        let timeIntervalRuns: number = 0;
        let alternativeOffset = 0;

        this.checkElementTimeInterval = setInterval(() => {
          timeIntervalRuns += 1;
          let elementToScroll = document.getElementById(possibleIdName);

          if (elementToScroll) {
            clearTimeout(this.scrollToElementTimer);
            this.scrollToElementTimer = setTimeout(() => {
              /**
               * If no offset is given get it based on the --menu-height CSS variable.
               */
              if (!scrollOffset) {
                const appThemeRoot = document.querySelector("#app > div");

                if (appThemeRoot) {
                  alternativeOffset =
                    parseInt(
                      getComputedStyle(appThemeRoot).getPropertyValue(
                        "--menu-height"
                      )
                    ) || 0;
                }
              }

              /**
               * Get offset based on either scrollOffset given as param to the class or
               * based on the alternativeOffset which is also used for scrolls when clicking
               * on a link in Helper.ts #148
               */
              let offsetTop = scrollOffset
                ? (elementToScroll as HTMLElement).getBoundingClientRect().top +
                  window.scrollY -
                  scrollOffset
                : (elementToScroll as HTMLElement).getBoundingClientRect().top +
                  window.scrollY -
                  alternativeOffset;

              Helper.smoothScrollTo(elementToScroll as HTMLElement, offsetTop);
            }, 1500);

            clearInterval(this.checkElementTimeInterval);
          }

          if (timeIntervalRuns >= this.maxIntervalRuns) {
            clearInterval(this.checkElementTimeInterval);
          }
        }, 500);
      }
    });
  }
}

export default ScrollToAnAnchorAfterDataLoaded;
