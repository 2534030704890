import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class GuidelineRepository extends AbstractRepository {
  async getSlideParent(slide_id: string, parent_layer: number) {
    let data = {
      slide_id: slide_id,
      parent_layer: parent_layer
    };

    let response = await Client.getJson(
      Constants.guidelineUrl,
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new GuidelineRepository();
