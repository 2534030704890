
import { BookmarkRepository, BookmarkList, ResultType, router } from "common";
import { Component } from "vue-property-decorator";
import Bookmarks from "./Bookmarks.vue";

@Component
export default class PublicBookmarks extends Bookmarks {
  private bookmarkListId!: any;

  constructor() {
    super();
    this.bookmarkList = null;
    this.bookmarkListId = router.app.$router.currentRoute.query.id;
  }

  async mounted() {
    let result = await BookmarkRepository.getList(this.bookmarkListId);

    if (result.type == ResultType.SUCCESS) {
      BookmarkList.setList(result.value);
      this.updateBookmarkList();
    }
  }
}
