import Vue from "vue";
import { Watch } from "vue-property-decorator";
import MenuItem from "../models/MenuItem";
import { LayoutComponent } from "../models/LayoutComponent";
import { Storage } from "../api/util/Storage";
import config, { EnvironmentModules } from "../config";
import Bookmark from "../models/Bookmark";
import Bookmarker from "../models/Bookmarker";
import { ResultType } from "../api/util/Result";
import { RoutesMapping } from "./RoutingHelper";

class GlobalModal {
  public visible: boolean;
  public updated: number;
  public title: string;
  public className: string;
  public layoutComponent: LayoutComponent | null;
  public showCloseButton: boolean;
  public preventHideByAction: boolean;
  public onModalClose: Function | null;

  constructor() {
    this.visible = false;
    this.title = "";
    this.updated = Date.now();
    this.className = "modal-lg";
    this.layoutComponent = null;
    this.showCloseButton = true;
    this.preventHideByAction = false;
    this.onModalClose = null;
  }

  private update() {
    this.updated = Date.now();
  }

  private reset() {
    this.visible = false;
    this.title = "";
    this.updated = Date.now();
    this.className = "modal-lg";
    this.layoutComponent = null;
    this.showCloseButton = true;
    this.preventHideByAction = false;
    this.onModalClose = null;
  }

  showModal() {
    this.visible = true;
    this.update();
  }

  hideModal() {
    this.reset();
  }

  setLayoutComponent(comp: LayoutComponent) {
    this.layoutComponent = comp;
    this.update();
  }
}

class Queue extends Vue {
  private id!: string;
  private queue!: any[];
  public updated!: number;
  public name!: string;
  private maxQueueLength!: number;

  constructor() {
    super();
    this.id = "queue_" + Math.round(Math.random() * 36 ** 12).toString(36);
    this.queue = [];
    this.updated = Date.now();
    this.maxQueueLength = 0;
  }

  public update() {
    this.updated = Date.now();

    if (this.queue.length > this.maxQueueLength) {
      this.maxQueueLength = this.queue.length;
    }
  }

  public addElement(id: any) {
    if (!this.queue.includes(id)) {
      this.queue.push(id);
      this.update();
      return this.queue;
    }
    return false;
  }

  public removeElement(id: any) {
    if (this.queue.includes(id)) {
      this.queue.splice(this.queue.indexOf(id), 1);
      this.update();
      return this.queue;
    }
    return false;
  }

  public hasElement(id: any) {
    return this.queue.includes(id);
  }

  public getQueue() {
    return this.queue;
  }

  public get isEmpty() {
    return this.queue.length == 0;
  }

  public resetQueue() {
    this.queue = [];
    this.maxQueueLength = 0;
    this.update();
  }

  public get hadData() {
    return this.maxQueueLength > 1;
  }
}

class VueContentQueue extends Queue {
  constructor() {
    super();
    this.name = "ContentQueue";
  }

  public update() {
    super.update();
    if (this.isEmpty) {
      ExecutionQueue.execute();
    }
  }

  @Watch("$route")
  resetMe() {
    this.resetQueue();
  }
}

class VueExecutionQueue extends Queue {
  constructor() {
    super();
    this.name = "ExecutionQueue";
  }

  execute() {
    this.getQueue().reduceRight((_: any, f: any, index: number) => {
      f();
      // this.getQueue().splice(index, 1);
      this.removeElement(f);
    }, null);
  }

  @Watch("$route")
  resetMe() {
    this.resetQueue();
  }

  public addElement(id: any) {
    const added = super.addElement(id);

    if (added && ContentQueue.isEmpty && ContentQueue.hadData) {
      ExecutionQueue.execute();
    }

    return added;
  }
}

class VueStore {
  readonly ENV_NONE = "NONE";
  readonly ENV_LOCAL = "LOCAL";
  readonly ENV_DEV = "DEVELOP";
  readonly ENV_TEST = "TEST";
  readonly ENV_STAGE = "STAGE";
  readonly ENV_PROD = "PRODUCTION";

  public shadowMenuTree: Array<MenuItem>;
  public activePost: string;
  public isArticle: boolean;
  public modal: GlobalModal;
  public environment: any;
  public frontend: any;
  public returnUrl: string;
  public showSuccessfullLoginPopup: boolean;
  public kalturaSessionInfo: any;
  public routesLoaded: boolean;
  public allRoutes: RoutesMapping;
  public cockpitUserRoles: any;

  constructor() {
    this.shadowMenuTree = [];
    this.activePost = "";
    this.isArticle = false;
    this.modal = new GlobalModal();
    this.environment = process.env.VUE_APP_CONFIG || this.ENV_NONE;
    this.frontend = process.env.VUE_APP_THEME || this.ENV_NONE;
    this.returnUrl = "/";
    this.showSuccessfullLoginPopup = false;
    this.routesLoaded = false;
    this.allRoutes = {
      articles: [],
      pages: [],
      brandPages: [],
      eventsPages: []
    };
    this.cockpitUserRoles = [];

    this.kalturaSessionInfo = {
      secret: process.env.VUE_APP_KALTURA_SECRET || "",
      userId: process.env.VUE_APP_KALTURA_USERID || ""
    };
  }

  public get appBrand() {
    return this.frontend + "_hub";
  }

  public saveReturnUrl(url: string) {
    this.returnUrl = this.removeParameter("wauth", url);
    Storage.saveLocal("_ww_returnurl", this.returnUrl, false, false);
  }

  removeParameter(key: string, sourceURL: string): string {
    let returnURL = sourceURL.split("?")[0],
      parameter,
      parameters = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";

    if (queryString !== "") {
      parameters = queryString.split("&");

      for (var i = parameters.length - 1; i >= 0; i -= 1) {
        parameter = parameters[i].split("=")[0];
        if (parameter === key) {
          parameters.splice(i, 1);
        }
      }

      if (parameters.length) returnURL = returnURL + "?" + parameters.join("&");
    }

    return returnURL;
  }

  public retrieveReturnUrl() {
    return Storage.getLocal("_ww_returnurl", false);
  }

  public deleteSavedReturnUrl() {
    Storage.deleteLocal("_ww_returnurl");
  }

  public hasKalturaSessionInfos() {
    return (
      this.kalturaSessionInfo.secret != "" &&
      this.kalturaSessionInfo.userId != ""
    );
  }

  public isNone() {
    return this.environment == this.ENV_NONE;
  }

  public isLocal() {
    return this.environment == this.ENV_LOCAL;
  }

  public isDev() {
    return this.environment == this.ENV_DEV;
  }

  public isTest() {
    return this.environment == this.ENV_TEST;
  }

  public isStage() {
    return this.environment == this.ENV_STAGE;
  }

  public isProd() {
    return this.environment == this.ENV_PROD;
  }

  public isHCP() {
    return this.frontend == "hcp";
  }

  public isBrand() {
    return this.frontend == "brand";
  }

  public isPatient() {
    return this.frontend == "patient";
  }

  public isCLL() {
    return this.frontend == "cll";
  }

  public isGynonco() {
    return this.frontend == "gynonco";
  }

  public isDiagnostics() {
    return this.frontend == "diagnostics";
  }

  public isTT2() {
    return this.frontend == "tt2";
  }

  public isCovid() {
    return this.frontend == "covid";
  }

  public isEvents() {
    return this.frontend == "events";
  }

  public isBiomarker() {
    return this.frontend == "biomarker";
  }

  public isEID() {
    return this.frontend == "eid";
  }

  public isBreastcancer() {
    return this.frontend == "breastcancer";
  }

  public isBSDK() {
    return this.frontend == "bsdk";
  }

  public isRSV() {
    return this.frontend == "rsv";
  }

  public isOnko() {
    return this.frontend == "onko";
  }

  public isGenwissheit() {
    return this.frontend == "genwissheit";
  }

  public isTeze() {
    return this.frontend == "teze";
  }

  public isMehrschutz() {
    return this.frontend == "mehrschutz";
  }

  public isCKD() {
    return this.frontend == "ckd";
  }

  public isCS360() {
    return this.frontend == "cancersupport360";
  }

  public matomoEnabled() {
    return false;
    // return config.modules.includes(EnvironmentModules.MATOMO);
  }

  /* Deprecated */
  /* Should be replaced with ArticleList functions systemwide */
  public isArticleSaved(id: any = null) {
    return ArticleListSaved.hasArticle(id);
  }

  /* Deprecated */
  /* Should be replaced with ArticleList functions systemwide */
  public saveArticle(id: any = null) {
    ArticleListSaved.saveArticle(id);
  }

  /* Deprecated */
  /* Should be replaced with ArticleList functions systemwide */
  public get savedArticles() {
    return ArticleListSaved.list;
  }

  /* Deprecated */
  public getMenuItemTreeAsArrayByPostId(postId: string, menuItem?: MenuItem) {
    let itemsToTraverse = null;
    let self = this;
    let tree: MenuItem[] = [];

    if (menuItem) {
      itemsToTraverse = menuItem.children;
      if (menuItem.cockpit_link) {
        if (menuItem.cockpit_link.id == postId) {
          return [menuItem];
        }
      }
    } else {
      itemsToTraverse = this.shadowMenuTree;
    }

    itemsToTraverse.forEach((item: MenuItem) => {
      let found = self.getMenuItemTreeAsArrayByPostId(postId, item);
      found.forEach((f: MenuItem) => {
        tree.push(f);
      });
    });

    if (tree.length > 0 && menuItem) {
      tree.push(menuItem);
    }

    return tree;
  }

  /* Deprecated */
  public getMenuItemByPostId(postId: string, menuItem?: MenuItem) {
    let itemsToTraverse = null;
    let self = this;
    let foundItem: MenuItem | null = null;

    if (menuItem) {
      itemsToTraverse = menuItem.children;
      if (menuItem.cockpit_link) {
        if (menuItem.cockpit_link.id == postId) {
          return menuItem;
        }
      }
    } else {
      itemsToTraverse = this.shadowMenuTree;
    }

    itemsToTraverse.forEach((item: MenuItem) => {
      if (!foundItem) {
        foundItem = self.getMenuItemByPostId(postId, item);
      }
    });

    return foundItem;
  }
}

class VueArticleList {
  protected id!: string;
  protected storageParameter!: string;
  public updated!: number;
  protected removeIfAlreadySaved!: boolean;

  constructor() {
    this.id = "al_" + Math.round(Math.random() * 36 ** 12).toString(36);
    this.storageParameter = "_";
    this.updated = Date.now();
    this.removeIfAlreadySaved = true;
  }

  get list() {
    return Storage.getLocal(this.storageParameter) || [];
  }

  set list(list: string[]) {
    Storage.saveLocal(this.storageParameter, list);
  }

  update() {
    this.updated = Date.now();
  }

  saveArticle(id: any = null) {
    let idToCheck = id || Store.activePost;
    let list = this.list;

    // push to list, if the id is not in
    if (!list.includes(idToCheck)) {
      list.push(idToCheck);
    }

    // remove from list if the id is in
    else if (this.removeIfAlreadySaved) {
      list.splice(list.indexOf(idToCheck), 1);
    }

    this.list = list;
    this.update();
  }

  // not necessary atm
  // removeArticle(id: string) {}

  hasArticle(id: string) {
    let idToCheck = id || Store.activePost;
    return this.list.includes(idToCheck);
  }
}

class VueArticleListSaved extends VueArticleList {
  constructor() {
    super();
    this.storageParameter = "_ww_st";
  }
}

class VueArticleListRead extends VueArticleList {
  constructor() {
    super();
    this.storageParameter = "_ww_r";
    this.removeIfAlreadySaved = false;
  }
}

class VueBookmarkList {
  public storageKey!: string;
  public updated!: number;
  public list!: Bookmark[];
  public bookmarkersList!: Bookmarker[];

  constructor() {
    this.storageKey = "_ww_bml";
    this.updated = Date.now();
    this.list = this.retrieve() || [];
    this.bookmarkersList = [];
  }

  /**
   * set the public "update" parameter that other classes can have a watch on
   */
  private update() {
    this.updated = Date.now();
  }

  /**
   * Sets the whole bookmark list. Checks if array elements are valid bookmarks.
   * @param list array of bookmarks
   */
  setList(list: any[]) {
    this.list = [];
    let tmpList: Bookmark[] = [];

    list.forEach((el: any) => {
      const bookmark = el instanceof Bookmark ? el : new Bookmark(el);
      if (bookmark.isValid()) {
        tmpList.push(bookmark);
      }
    });

    this.list = tmpList;
    this.save();
  }

  /**
   * Add multiple elements to bookmark list. Checks if array elements are valid bookmarks.
   * @param list array of bookmarks
   */
  addList(list: any[]) {
    let tmpList: Bookmark[] = this.list;

    list.forEach((el: any) => {
      const bookmark = el instanceof Bookmark ? el : new Bookmark(el);
      if (bookmark.isValid()) {
        tmpList.push(bookmark);
      }
    });

    this.list = tmpList;
    this.save();
  }

  /**
   * Get the list
   * @returns Bookmarklist
   */
  getList() {
    return this.list;
  }

  /**
   * Get the Bookmarkable List
   * @returns Bookmarkablelist
   */
  getBookmarkableList() {
    return this.bookmarkersList.filter(
      item =>
        !this.list.find(
          (bookmark: Bookmark) => bookmark.foreignId == item.foreignId
        )
    );
  }

  /**
   * Adds a single bookmark to the list
   * @param b Bookmark that should be added to the list
   */
  add(b: Bookmark) {
    if (!this.hasBookmark(b)) {
      this.list.push(b);
      this.save();
    }
  }

  /**
   * Adds a single Bookmarker to the bookmarkersList
   * @param b Bookmarker that should be added to the bookmarkersList
   */
  addBookmarker(b: Bookmarker) {
    if (!this.hasBookmarker(b)) {
      this.bookmarkersList.push(b);
      this.save();
    }
  }

  /**
   * Deletes a bookmark by it's id if the bookmark is found in the list
   * @param id ID of the bookmark that should be deleted
   */
  delete(id: number) {
    const bookmarkIndex = this.getIndexById(id);
    if (bookmarkIndex > -1) {
      this.list.splice(bookmarkIndex, 1);
      this.save();
    }
  }

  /**
   * Saves the bookmark list to localStorage
   */
  private save() {
    Storage.saveLocalEncoded(this.storageKey, this.list);
    this.update();
  }

  /**
   * Load the bookmark list from localStorage
   */
  private retrieve() {
    return Storage.getLocalEncoded(this.storageKey);
  }

  /**
   * Checks if a bookmark exists in the list
   * @param b The bookmark that should be checked
   * @returns true | false
   */
  hasBookmark(b: Bookmark): boolean {
    const el = this.list.find((bookmark: Bookmark) => bookmark.id == b.id);
    return el != undefined;
  }

  /**
   * Checks if a bookmarker exists in the bookmarkersList
   * @param b The bookmarker that should be checked
   * @returns true | false
   */
  hasBookmarker(b: Bookmarker): boolean {
    const el = this.bookmarkersList.find(
      (bookmarker: Bookmarker) => bookmarker.foreignId == b.foreignId
    );
    return el != undefined;
  }

  /**
   * Finds a bookmark in the list by it's id
   * @param id ID of the bookmark
   * @returns Bookmark | undefined
   */
  findById(id: number): Bookmark | undefined {
    return this.list.find((bookmark: Bookmark) => bookmark.id == id);
  }

  /**
   * Finds a bookmark by the unique combination of foreignId, collectionName and collectionTitle
   * @param foreignId collection id of the element that should be bookmarked
   * @param collectionName collection name (type) of the element that should be bookmarked (e.g. pages, cancer_stages, cancer_types, ...)
   * @param collectionTitle category of the bookmark (e.g. Artikel, 3D Modell, Video, ...)
   * @returns Bookmark | undefined
   */
  findByUniqueCombination(
    foreignId: string,
    collectionName: string
  ): Bookmark | undefined {
    return this.list.find((bookmark: Bookmark) => {
      return (
        bookmark.foreignId == foreignId &&
        bookmark.collectionName == collectionName
      );
    });
  }

  /**
   * Finds the index of a given bookmark
   * @param b The bookmark which index should be retrieved
   * @returns number | undefined
   */
  getIndex(b: Bookmark): number | undefined {
    return this.list.findIndex((bookmark: Bookmark) => bookmark.id == b.id);
  }

  /**
   * Finds the index of a given bookmark by it's id
   * @param id ID of the bookmark
   * @returns number
   */
  getIndexById(id: number): number {
    return this.list.findIndex((bookmark: Bookmark) => bookmark.id == id);
  }
}

class VueDatabaseSavedArticleList {
  private list!: Array<string>;
  private fetched!: boolean;
  private loading!: boolean;
  private repository!: any;

  constructor() {
    this.list = [];
    this.fetched = false;
    this.loading = false;
  }

  private async fetch() {
    const resultIds = await this.repository.get();

    if (resultIds.type !== ResultType.SUCCESS || !resultIds.value) {
      return [];
    }

    return resultIds.value;
  }

  private load() {
    return new Promise((resolve, reject) => {
      let amount = 0;

      const interval = setInterval(() => {
        if (this.fetched) {
          clearInterval(interval);
          resolve(true);
        }

        amount += 1;

        if (amount > 50) {
          clearInterval(interval);
          reject(false);
        }
      }, 100);
    });
  }

  public async get() {
    if (!this.repository) {
      this.repository = (
        await import("../api/repositories/SavedArticleRepository")
      ).default;
    }

    if (this.loading) {
      await this.load();
    }

    if (!this.fetched) {
      this.loading = true;
      this.list = await this.fetch();
      this.fetched = true;
      this.loading = false;
    }

    return this.list;
  }

  public reset() {
    this.list = [];
    this.fetched = false;
    this.loading = false;
  }
}

export let Store = new VueStore();
export let ContentQueue = new VueContentQueue();
export let ExecutionQueue = new VueExecutionQueue();
export let ArticleListSaved = new VueArticleListSaved();
export let ArticleListRead = new VueArticleListRead();
export let BookmarkList = new VueBookmarkList();
export let DatabaseSavedArticleList = new VueDatabaseSavedArticleList();
