
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LoadMore extends BaseComponent {
  public visibleItemsLength!: number;

  constructor() {
    super();
    this.visibleItemsLength = this.pageSize;
  }

  getComponentType() {
    return "load_more_collection";
  }

  async mounted() {
    await super.mounted();
  }

  get contentItems() {
    return this.getContentAttribute("content") || [];
  }

  get pageSize() {
    const result = parseInt(this.getSettingsAttribute("page_size"));
    return !isNaN(result) ? result : 5;
  }

  get buttonText() {
    return this.getSettingsAttribute("load_more_btn_text") || "";
  }

  get buttonClass() {
    return this.getSettingsAttribute("load_more_btn_class") || "";
  }

  get itemsToRender() {
    return this.contentItems.slice(0, this.visibleItemsLength);
  }

  clickHandler() {
    if (this.visibleItemsLength >= this.contentItems.length) {
      return;
    }
    this.visibleItemsLength += this.pageSize;
  }
}
