
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  config,
  Helper,
  LoginHandler,
  router,
  Storage,
  Store,
  User,
  UserLoginStatus
} from "common";

@Component
export default class UserInfo extends Vue {
  @Prop({ default: "/login" }) loginDestination!: string;
  @Prop({ default: false }) useDocCheck!: boolean;
  @Prop({ default: true }) showIcon!: boolean;

  shared = Storage;
  vueStore = Store;

  userIsLoggedIn: boolean = false;

  constructor() {
    super();
  }

  created() {
    this.setUserIsLoggedIn();
  }

  async logout() {
    await LoginHandler.logout();
    window.location.href = config.getFrontendConfiguration().redirectRouteAfterLogout;
  }

  @Watch("shared.updatedLocal")
  setUserIsLoggedIn() {
    if (this.useDocCheck) {
      this.userIsLoggedIn = User.hasUserLoginStatus(
        UserLoginStatus.DOCCHECK_ONLY
      );
    } else {
      this.userIsLoggedIn = User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN);
    }
  }

  get userinfo() {
    return User.getUserInfo();
  }

  get accountLink() {
    if (Store.isHCP()) {
      return "/account";
    }

    if (Store.isBreastcancer()) {
      return "/profile";
    }

    if (Store.isDev()) {
      return "https://dev.mein-medcampus.de/account";
    }

    if (Store.isStage()) {
      return "https://staging.mein-medcampus.de/account";
    }

    if (Store.isProd()) {
      return "https://www.mein-medcampus.de/account";
    }

    return "/account";
  }

  get logged_out_icon() {
    return "/assets/userinfo/logged-out.svg";
  }

  get logged_in_icon() {
    return "/assets/userinfo/logged-out.svg";
  }

  get txt_my_account() {
    if (Store.isBreastcancer()) {
      return "Mein Profil";
    }

    return "Mein Account";
  }

  get txt_logout() {
    return "Abmelden";
  }

  async gotoAccount() {
    if (Store.isHCP() || Store.isBreastcancer()) {
      return router.push(this.accountLink);
    }

    return await Helper.generateMoveTokenAndOpenNewWindow(this.accountLink);
  }
}
