
import { Component, Vue, Prop } from "vue-property-decorator";
import { InputConfig } from "../NewsletterForm.vue";

@Component
export default class NewsletterInputSelect extends Vue {
  @Prop() formData!: InputConfig<any>[];
  @Prop() field!: string;
  @Prop() inputClass!: string;
}
