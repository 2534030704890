
import { Component, Vue, Prop } from "vue-property-decorator";
import { LayoutComponent, router, Store, User, UserProfile } from "common";

@Component
export default class Upgrade extends Vue {
  @Prop({ default: null }) message!: String | null;

  handleClick() {
    if (this.isMMCBasic) {
      return this.showOptInPopup();
    }

    if (this.isMMCDocCheck) {
      return router.push("/registrierung");
    }

    return false;
  }

  showOptInPopup() {
    Store.modal.title = "Herzlich Willkommen auf MeinMEDCAMPUS";
    Store.modal.className = "modal-lg";
    Store.modal.setLayoutComponent(LayoutComponent.createEmpty("FirstVisit"));
    Store.modal.showModal();
  }

  get isMMCBasic() {
    return User.hasUserProfile(UserProfile.MMC_BASIC);
  }

  get isMMCDocCheck() {
    return User.hasUserProfile(UserProfile.MMC_DOCCHECK);
  }

  get upgradeText() {
    if (this.message) {
      return this.message;
    }

    if (this.isMMCBasic) {
      return "Da diese Inhalte nur für ausgewählte Kunden zur Verfügung gestellt werden können, bitten wir Sie, dem kostenlosen Accountupgrade zuzustimmen.";
    }

    if (this.isMMCDocCheck) {
      return "Um diesen Artikel vollständig lesen zu können, richten Sie sich bitte einen MeinMEDCAMPUS-Account ein.";
    }

    return "";
  }
}
