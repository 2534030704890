import { Deserializible } from "../../../util/Helper";

export class HistoryItem extends Deserializible {
  [key: string]: any;
  public samBrandName: String | undefined = undefined;
  public samQuantityTotal: number | undefined = undefined;
  public samName: String | undefined = undefined;
  public samDropDate: number = 0;

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);
    }
  }

  public getDropDateReadable(): string {
    let d = new Date(this.samDropDate);
    return (
      ("0" + d.getDate()).slice(-2) +
      "." +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "." +
      d.getFullYear()
    );
  }
}
