
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import {
  EventBus,
  VIDEO_FINISHED,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  ResultType,
  Store,
  TrainingQuizRepository,
  User
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum View {
  TRAINING,
  INFORMATION,
  INTRODUCTION,
  QUIZ,
  THANKYOU,
  RESULT
}

@Component
export default class LcTrainingQuiz extends BaseComponent {
  public View = View;
  // Quiz state
  public view: View = View.TRAINING;
  public noQuestions: number = 0;
  public currentQuestionIdx: number = 0;
  private quizParticipants: number = 0;
  private quizUpdateInterval: any = undefined;
  private quizUpdateFrequency: number = 60000; // milliseconds
  public quizExpired: boolean = false;
  public loading: boolean = false;
  // Current question state
  public selectedAnswerIdx: number[] = [];
  public correctAnswerIdx: number[] = [];
  private showMsg: boolean = false;
  // Form model state
  private attemptSubmit: boolean = false;
  // Countdown state
  private counterDateFormat: string = "YYYY-MM-DD HH:mm:ss";
  private counterValue: any = null;
  private counterInterval: any = undefined;
  private amountOfWrongAnswers: number = 0;
  private isQuizDone: boolean = false;
  public userId = User.getProperty("id");
  public videoCompleted: boolean = false;
  public renderComponent: boolean = true;
  public lastAnsweredQuestionId!: string;
  public previousQuestion: boolean = false;
  public lastAnsweredQuestionIdx: number = -1;

  getComponentType() {
    return "training_quizzes";
  }

  async mounted() {
    await super.mounted();
    EventBus.$on(VIDEO_FINISHED, () => {
      this.videoCompleted = true;
      setTimeout(() => {
        this.startQuiz();
      }, 3000);
    });
    EventBus.$on("prevQuest", () => {
      this.previousQuestion = true;
    });
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.getLastAnsweredQuestion();
      this.view = this.initialView;

      this.counterInterval = setInterval(() => {
        if (!moment().isBefore(this.counterEndTime)) {
          this.quizExpired = true;
        }

        if (!this.quizExpired) {
          this.counterValue = this.counterValue.subtract(1, "seconds");
        } else {
          clearInterval(this.counterInterval);
        }
      }, 1000);

      this.quizUpdateInterval = setInterval(() => {
        if (this.quizExpired) {
          clearInterval(this.quizUpdateInterval);
        }

        if (this.view === this.initialView) {
          this.updateQuiz();
          this.counterValue = this.getCounterInitialTime();
        }
      }, this.quizUpdateFrequency);

      this.quizParticipants = parseInt(
        this.getContentAttribute("participants"),
        10
      );
      this.counterValue = this.getCounterInitialTime();
    });
  }

  beforeDestroy() {
    clearInterval(this.counterInterval);
    clearInterval(this.quizUpdateInterval);
  }

  get resultClass() {
    let cssClasses = [];
    if (!this.isQuizDone) {
      cssClasses.push("disabled");
    }

    return cssClasses;
  }

  get completedClass() {
    let cssClasses = [];
    if (this.isQuizDone) {
      cssClasses.push("disabled");
    }

    return cssClasses;
  }

  get isResultDisabled() {
    return !this.isQuizDone;
  }

  get isCompleted() {
    return this.isQuizDone;
  }

  /************************************/
  // Map Cockpit props

  get headlines() {
    const retValue = {
      title: null as any,
      subTitle: null as any
    };

    let title = this.getContentAttribute("title");
    if (title && title.length > 0) {
      retValue.title = new LayoutComponent(title[0]);
      // SubTitle
      if (title.length > 1) {
        retValue.subTitle = new LayoutComponent(title[1]);
      }
    }

    return retValue;
  }

  get initialView() {
    let userData = {
      userId: this.userId,
      trainingQuizId: this.id
    };
    TrainingQuizRepository.startQuiz(userData);
    return View.TRAINING;
  }

  get participants() {
    return this.quizParticipants > 0
      ? Math.min(this.quizParticipants, this.targetParticipants)
      : this.quizParticipants;
  }

  get targetParticipants() {
    return parseInt(this.getContentAttribute("target_participants"), 10);
  }

  get counterEndTime() {
    const endDate = this.getContentAttribute("end_date");
    const endTime = this.getContentAttribute("end_time");

    return moment(`${endDate} ${endTime}`, this.counterDateFormat);
  }

  get introduction() {
    const introduction = this.getContentAttribute("introduction");
    if (introduction) {
      return new LayoutComponent(introduction[0]);
    }

    return null;
  }

  get trainingCategory() {
    let trainingCategory = this.getContentAttribute("training_category");
    return trainingCategory ? trainingCategory : null;
  }

  get deFreigabenummer() {
    let deFreigabenummer = this.getContentAttribute("de_freigabenummer");
    return deFreigabenummer ? deFreigabenummer : null;
  }

  get thankyou() {
    const thankyou = this.getContentAttribute("thank_you");
    if (thankyou) {
      return new LayoutComponent(thankyou[0]);
    }

    return null;
  }

  get training() {
    const training = this.getContentAttribute("training");
    if (training) {
      return new LayoutComponent(training[0]);
    }

    return null;
  }

  get questions() {
    const questions = this.getContentAttribute("questions");
    if (questions && questions.length > 0) {
      const result = questions.map((currentQuestion: any) => {
        if (currentQuestion) {
          return new LayoutComponent(currentQuestion);
        }

        return null;
      });

      this.noQuestions = result.length;

      return result;
    }

    return [];
  }

  get images() {
    //@ts-ignore
    return {
      participants: "/assets/quiz/teilnehmeranzahl.svg",
      countDown: "/assets/quiz/quiz-countdown.svg"
    };
  }

  /************************************/
  // Business logic

  async getLastAnsweredQuestion() {
    let userData = {
      userId: this.userId,
      trainingQuizId: this.id
    };
    const result = await TrainingQuizRepository.startQuiz(userData);
    if (result.type == ResultType.SUCCESS) {
      this.lastAnsweredQuestionId = result.value
        .lastAnsweredQuestionId as string;
    }
  }

  // Receive question state
  setAnswerState({ selectedAnswerIdx, correctAnswerIdx }: any) {
    this.selectedAnswerIdx = selectedAnswerIdx.sort();
    this.correctAnswerIdx = correctAnswerIdx.sort();

    const answerIsCorrect =
      JSON.stringify(this.selectedAnswerIdx) ===
      JSON.stringify(this.correctAnswerIdx);

    matomo.quizQuestionEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      answerIsCorrect
        ? MatomoEventNames.QUIZ_CORRECT_ANSWER
        : MatomoEventNames.QUIZ_WRONG_ANSWER,
      this.currentQuestionIdx
    );

    if (answerIsCorrect && this.currentQuestionIdx + 1 !== this.noQuestions) {
      setTimeout(() => {
        this.nextQuestionHandler();
      }, 1200);
    }

    if (answerIsCorrect && this.currentQuestionIdx + 1 === this.noQuestions) {
      setTimeout(() => {
        this.lastAnsweredQuestionIdx = this.currentQuestionIdx;
        this.submitHandler();
      }, 1200);
    }

    if (answerIsCorrect === false) {
      this.amountOfWrongAnswers += 1;
    }
  }

  nextQuestionHandler() {
    if (!this.selectedAnswerIdx.length) return;
    if (
      JSON.stringify(this.selectedAnswerIdx) !==
      JSON.stringify(this.correctAnswerIdx)
    ) {
      this.showMsg = true;
    } else {
      this.nextQuestionRequest();
      // Reset question state
      this.showMsg = false;
      this.correctAnswerIdx = [];
      this.selectedAnswerIdx = [];

      if (this.currentQuestionIdx + 1 === this.noQuestions) {
        this.lastAnsweredQuestionIdx = this.currentQuestionIdx + 1;
        this.currentQuestionIdx = 0;
      } else {
        this.lastAnsweredQuestionIdx = this.currentQuestionIdx;
        this.currentQuestionIdx++;
      }
    }
  }

  nextButtonQuestionHandler() {
    if (this.currentQuestionIdx < this.noQuestions) {
      this.currentQuestionIdx++;
    }
  }

  previousQuestionHandler() {
    if (this.currentQuestionIdx > 0) {
      this.currentQuestionIdx--;
      this.previousQuestion = true;
    }
  }

  async nextQuestionRequest() {
    this.loading = true;

    let questions = this.questions;
    let questionIdx = questions
      ? questions[this.currentQuestionIdx].settings.content[0]._id
      : null;

    let userData = {
      userId: this.userId,
      trainingQuizId: this.id,
      questionId: questionIdx
    };
    const result = await TrainingQuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      await TrainingQuizRepository.nextQuestion(userData);
    }
    this.loading = false;
  }

  async saveQuiz() {
    this.loading = true;

    const result = await TrainingQuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      await TrainingQuizRepository.saveQuiz(
        this.content[0]._id,
        result.value.participants + 1
      );

      this.loading = false;
    }
  }

  async updateQuiz() {
    const result = await TrainingQuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      this.quizParticipants = result.value.participants;
    }
  }

  get progressData() {
    const barsCount = 10;
    const result = [];
    const self = this;
    let activeIndex = null;

    if (self.participants && self.participants !== 0) {
      activeIndex =
        Math.ceil(self.participants / (self.targetParticipants / barsCount)) -
        1;
    }

    for (let i = 0; i < barsCount; i++) {
      if (activeIndex !== null) {
        result.push({ isActive: i === activeIndex ? true : false });
      } else {
        result.push({ isInactive: true });
      }
    }

    return result;
  }

  getCounterInitialTime() {
    let counterStartValue = null;
    const now = moment();

    if (!now.isBefore(this.counterEndTime)) {
      this.quizExpired = true;
      counterStartValue = moment("00:00:00", "HH mm ss");
    } else {
      counterStartValue = moment.duration(
        moment(this.counterEndTime, this.counterDateFormat).diff(
          moment(now, this.counterDateFormat)
        )
      );
    }
    return counterStartValue;
  }

  async sendStartQuiz() {
    let userData = {
      userId: this.userId,
      trainingQuizId: this.id
    };
    const result = await TrainingQuizRepository.getQuiz(this.content[0].id);
    if (result.type == ResultType.SUCCESS) {
      await TrainingQuizRepository.startQuizAfterVideo(userData);
    }
  }

  startQuiz() {
    matomo.genericEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.QUIZ_START
    );

    this.sendStartQuiz();

    let questionsIdx: Array<any> = this.questions.filter((val: any) => {
      return val.settings.content ? val.settings.content[0]._id : 0;
    });

    if (questionsIdx) {
      questionsIdx.forEach((element: any, index: number) => {
        if (
          element.settings.content[0]._id === this.lastAnsweredQuestionId &&
          this.currentQuestionIdx < questionsIdx.length
        ) {
          this.lastAnsweredQuestionIdx = index;
          this.currentQuestionIdx = index + 1;
        }
        if (this.currentQuestionIdx === questionsIdx.length) {
          this.currentQuestionIdx--;
        }
      });
    }
    this.videoCompleted = false;
    this.view = View.QUIZ;
  }

  get id() {
    return this.getContentAttribute("_id");
  }

  get countdown() {
    if (this.counterValue && !this.quizExpired) {
      const hours =
        this.counterValue.days() > 0
          ? this.counterValue.hours() + this.counterValue.days() * 24
          : this.counterValue.hours();
      const minutes = this.counterValue.minutes();
      const seconds = this.counterValue.seconds();

      const formatValue = function(value: number) {
        if (value < 10) {
          return `0${value}`;
        }
        return value;
      };

      return `${formatValue(hours)} ${formatValue(minutes)} ${formatValue(
        seconds
      )}`;
    }

    return moment(this.counterValue).format("HH mm ss");
  }

  get confirmation() {
    return this.getContentAttribute("confirmation");
  }

  async submitHandler() {
    this.nextQuestionRequest();
    this.attemptSubmit = true;

    await this.saveQuiz();
    await this.updateQuiz();
    this.counterValue = this.getCounterInitialTime();

    Store.modal.className = "modal-md modal__quiz--finished";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("training-quiz-modal")
    );
    Store.modal.showModal();
    this.isQuizDone = true;
    this.view = View.RESULT;

    matomo.quizQuestionEvent(
      this.id,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.QUIZ_FINISH,
      this.amountOfWrongAnswers
    );

    // Reset model
    this.attemptSubmit = false;
    this.currentQuestionIdx = 0;
    this.amountOfWrongAnswers = 0;
  }

  getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  /************************************/
}
