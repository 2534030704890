
import { Component } from "vue-property-decorator";
import {
  Event,
  EventRepository,
  FieldInfo,
  Placeholders,
  LayoutComponent,
  ResultType,
  SlideDeckRepository,
  User
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class SlideDeck extends BaseComponent {
  private placeholders: Placeholders;
  private activeEvent!: Event | null;

  constructor() {
    super();
    this.placeholders = new Placeholders();
    this.activeEvent = null;
  }

  getComponentType(): string {
    return "slide_decks";
  }

  async mounted(): Promise<void> {
    await super.mounted();

    this.setupPlaceholders();
  }

  async contentDidLoad(): Promise<void> {
    super.contentDidLoad();

    await this.loadEvent();
  }

  get modal(): LayoutComponent {
    return new LayoutComponent(this.getContentAttribute("modal")[0]);
  }

  get emailBody(): string {
    return this.getContentAttribute("email_body");
  }

  get veevaId(): string {
    return this.getSettingsAttribute("veeva_id");
  }

  get eventStartDate(): Date | null {
    if (!this.activeEvent) {
      return null;
    }

    return new Date(this.activeEvent.startDateTime);
  }

  get visibilityHours(): number {
    return this.getSettingsAttribute("hours");
  }

  get eventStartsInInterval(): boolean {
    if (this.visibilityHours == 0) {
      return true;
    }

    if (!this.eventStartDate) {
      return false;
    }

    const now = new Date();
    const hoursInterval = this.visibilityHours;

    const startInterval = new Date(
      this.eventStartDate.getTime() - hoursInterval * 60 * 60 * 1000
    );

    const endInterval = new Date(
      this.eventStartDate.getTime() + hoursInterval * 60 * 60 * 1000
    );

    return now >= startInterval && now <= endInterval;
  }

  get shouldDisplaySlideDeck(): boolean {
    return this.eventStartsInInterval && this.isRegistered;
  }

  get isRegistered(): boolean {
    if (!this.activeEvent) {
      return false;
    }

    if (!this.activeEvent.registered) {
      return false;
    }

    if (
      !this.activeEvent.opParticipantLink ||
      !this.activeEvent.opParticipantLink.length
    ) {
      return false;
    }

    return true;
  }

  async loadEvent() {
    const result = await EventRepository.getVeevaEvent(this.veevaId);

    if (result.type !== ResultType.SUCCESS) {
      return;
    }

    this.activeEvent = result.value;
  }

  setupPlaceholders(): void {
    [
      new FieldInfo(
        "name",
        "name",
        `${User.getProperty("firstName")} ${User.getProperty("lastName")}`
      ),
      new FieldInfo("first_name", "first_name", User.getProperty("firstName")),
      new FieldInfo("last_name", "last_name", User.getProperty("lastName")),
      new FieldInfo("email", "email", User.getProperty("email")),
      new FieldInfo("title", "title", User.getProperty("title")),
      new FieldInfo("id_duo", "id_duo", User.getProperty("idDuO"))
    ].forEach(field => this.placeholders.addField(field));
  }

  sendEmail(): void {
    const body = this.placeholders.updateText(this.emailBody);

    SlideDeckRepository.notify(body);
  }
}
