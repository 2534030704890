import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";
import { SampleRequest } from "../../api/data/samples/SampleRequest";

class SampleRepository extends AbstractRepository {
  async getAvailable(productId: string) {
    let data: any = {
      productId: productId
    };

    let response = await Client.getJson(
      Constants.sampleUrl + "/available",
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async getHistory() {
    let response = await Client.getJson(
      Constants.sampleUrl + "/history",
      null,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async putRequest(sampleRequest: SampleRequest) {
    let body = {
      productId: sampleRequest.productId,
      numberOfSamples: sampleRequest.numberOfSamples,
      rootId: sampleRequest.rootId,
      pridId: sampleRequest.pridId
    };
    let response = await Client.putWithJsonResult(
      Constants.sampleUrl + "/request",
      body,
      this.getAdditionalHeader()
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new SampleRepository();
