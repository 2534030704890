
import { Component, Vue } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class LiveInTheBoxWidget extends BaseWidget {
  getComponentType() {
    return "live_in_the_box_widgets";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(async () => {
      // todo
    });
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get videos() {
    return this.getContentAttribute("videos");
  }
}
