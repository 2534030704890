
import { Component } from "vue-property-decorator";
import validator from "validator";
import { ExpertForumRepository, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumPatientForgotPassword extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;
  public username: string;
  public email: string;
  public errorEmail: string;
  public apiError: boolean;
  public resultMessage: string;
  public requestSent: boolean;
  public website: string;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.username = "";
    this.email = "";
    this.errorEmail = "";
    this.apiError = false;
    this.resultMessage = "";
    this.requestSent = false;
    this.website = Store.frontend;
  }

  async mounted() {
    super.mounted();
  }

  get validateEmail() {
    if (!validator.isEmail(this.email)) {
      this.errorEmail = "Bitte geben Sie eine gültige E-Mail Adresse an.";
    } else {
      this.errorEmail = "";
    }

    return validator.isEmail(this.email);
  }

  get validateEmailEmpty() {
    return !validator.isEmpty(this.email);
  }

  get validateUsername() {
    return !validator.isEmpty(this.username);
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (
      (this.validateEmail && this.validateEmailEmpty) ||
      this.validateUsername
    ) {
      this.sendRequest(this.email, this.username, this.website);
    } else {
      this.focusFirstError();
    }
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
      this.requestSent = false;
      this.isLoading = false;
    }, 1);
  }

  async sendRequest(email: string, username: string, website: string) {
    let result = await ExpertForumRepository.forgotPassword(
      email,
      username,
      website
    );
    this.requestSent = true;
    this.isLoading = false;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      this.resultMessage = "E-Mail erfolgreich versendet";
      this.apiError = false;
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
