
import { Component, Vue } from "vue-property-decorator";
import validator from "validator";
import {
  Constants,
  LayoutComponent,
  NewsletterRepository,
  ResultType,
  router,
  Store
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import NewsletterInputText from "../newsletter/newsletterFormInputs/NewsletterInputText.vue";
import NewsletterInputCheckbox from "../newsletter/newsletterFormInputs/NewsletterInputCheckbox.vue";
import NewsletterInputSelect from "./newsletterFormInputs/NewsletterInputSelect.vue";

enum ComponentType {
  TEXT = "inputText",
  CHECKBOX = "inputCheckbox",
  SELECT = "inputSelect"
}

enum Genders {
  HERR = "Herr",
  FRAU = "Frau",
  DIVERS = "Divers"
}

enum Titles {
  DR = "Dr.",
  PROF = "Prof.",
  PD = "PD",
  EMPTY = ""
}

enum AlertType {
  SUCCESS = "success",
  ERROR = "error"
}

export interface InputConfig<T> {
  visible: boolean;
  required: boolean;
  label: string;
  cssClass: string;
  model: T;
  compType: string;
  type?: string;
  selectOptions?: string[];
}

interface FormField {
  value: {
    field: string;
    required: string;
    label: string;
    cssClass: string;
  };
}

class FormConfig {
  public first_name: InputConfig<string>;
  public last_name: InputConfig<string>;
  public street: InputConfig<string>;
  public number: InputConfig<string>;
  public postcode: InputConfig<string>;
  public city: InputConfig<string>;
  public phone: InputConfig<string>;
  public clinic: InputConfig<string>;
  public email: InputConfig<string>;
  public is_doctor: InputConfig<boolean>;
  public privacy_consent: InputConfig<boolean>;
  public gender: InputConfig<string>;
  public title: InputConfig<string>;
  [key: string]: InputConfig<string | boolean> | any;

  constructor() {
    this.gender = {
      visible: false,
      required: false,
      label: "Gender",
      cssClass: "col-12",
      model: Genders.HERR,
      compType: ComponentType.SELECT,
      selectOptions: [Genders.HERR, Genders.FRAU, Genders.DIVERS]
    };
    this.title = {
      visible: false,
      required: false,
      label: "Title",
      cssClass: "col-12",
      model: Titles.EMPTY,
      compType: ComponentType.SELECT,
      selectOptions: [Titles.EMPTY, Titles.PROF, Titles.PD, Titles.DR]
    };
    this.first_name = {
      visible: true,
      required: true,
      label: "First Name",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.last_name = {
      visible: true,
      required: true,
      label: "Last Name",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.street = {
      visible: false,
      required: false,
      label: "Street",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.number = {
      visible: false,
      required: false,
      label: "Street Number",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.postcode = {
      visible: false,
      required: false,
      label: "Zip Code",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.city = {
      visible: false,
      required: false,
      label: "City",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.phone = {
      visible: false,
      required: false,
      label: "Phone",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "tel"
    };
    this.clinic = {
      visible: false,
      required: false,
      label: "Clinic",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "text"
    };
    this.email = {
      visible: false,
      required: false,
      label: "Email",
      cssClass: "col-12",
      model: "",
      compType: ComponentType.TEXT,
      type: "email"
    };
    this.is_doctor = {
      visible: false,
      required: false,
      label: "I'm a Doctor",
      cssClass: "col-12",
      model: false,
      compType: ComponentType.CHECKBOX
    };
    this.privacy_consent = {
      visible: false,
      required: false,
      label: "Privacy Consent",
      cssClass: "col-12",
      model: false,
      compType: ComponentType.CHECKBOX
    };
  }

  public resetModels() {
    this.first_name.model = "";
    this.last_name.model = "";
    this.street.model = "";
    this.number.model = "";
    this.postcode.model = "";
    this.city.model = "";
    this.phone.model = "";
    this.clinic.model = "";
    this.email.model = "";
    this.is_doctor.model = false;
    this.privacy_consent.model = false;
    this.gender.model = Genders.HERR;
    this.title.model = Titles.EMPTY;
  }
}

@Component({
  components: {
    [ComponentType.TEXT]: NewsletterInputText,
    [ComponentType.CHECKBOX]: NewsletterInputCheckbox,
    [ComponentType.SELECT]: NewsletterInputSelect
  }
})
export default class NewsletterForm extends BaseComponent {
  attemptSubmit: Boolean = false;
  submitSuccessfull: Boolean = false;
  loading: boolean = false;
  // AlertType = AlertType;
  alertType: string = "";
  showAlert: boolean = false;

  // 'true' - to show the response in the default way (an alert on the form)
  // 'false' - to show the response only separated from the form (eg. in a modal)
  showDefaultAlert: boolean = true;
  alertMessage: string = "";
  ComponentType = ComponentType;

  // Form default configuration
  formConfig = new FormConfig();

  constructor() {
    super();
  }

  getComponentType() {
    return "newsletter_forms";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.setFormConfig();
    });
  }

  get submitBtnClass() {
    return this.getContentAttribute("submit_btn_class") || "btn-primary";
  }

  get submitBtnText() {
    return this.getContentAttribute("submit_btn_text") || "Submit";
  }

  get resetBtnClass() {
    return this.getContentAttribute("reset_btn_class") || "btn-secondary";
  }

  get resetBtnText() {
    return this.getContentAttribute("reset_btn_text") || "Clear";
  }

  get sendSuccessMessage() {
    return this.getContentAttribute("send_success_message");
  }

  get formDescription() {
    const formDescription = this.getContentAttribute("form_description");
    if (formDescription && formDescription.length > 0) {
      return formDescription
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get formFooter() {
    const formFooter = this.getContentAttribute("form_footer");
    if (formFooter && formFooter.length > 0) {
      return formFooter
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get isInModal() {
    return this.getContentAttribute("is_in_modal");
  }

  get showResponseInModal() {
    return this.getContentAttribute("show_response_in_modal");
  }

  get formId() {
    return this.getContentAttribute("_id");
  }

  setFormConfig() {
    const showFields = this.getContentAttribute("show_fields");

    if (showFields && showFields.length > 0) {
      showFields.forEach(({ value }: FormField): void => {
        const { field, required, label, cssClass } = value;

        this.formConfig[field] = {
          ...this.formConfig[field],
          required: required === "true",
          ...(label && { label }),
          ...(cssClass && { cssClass }),
          visible: true
        };
      });
    }
  }

  /************************************/
  // Business logic

  validateInput(inputName: string): boolean {
    const { required, visible, model } = this.formConfig[inputName];

    if (!visible || (!required && inputName !== "email")) {
      return true;
    }

    if (typeof model === "boolean") {
      return model;
    }

    return (
      !validator.isEmpty(model) &&
      (inputName === "email" ? validator.isEmail(model) : true)
    );
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  getRequestHeaders() {
    if (Store.isGynonco()) {
      return [{ "Newsletter-Access": Constants.newsletterToken }];
    }

    return null;
  }

  async validate(e: any) {
    this.attemptSubmit = true;
    this.showAlert = false;

    e.preventDefault();
    e.stopPropagation();

    const isValid = Object.keys(this.formConfig).reduce(
      (result: boolean, inputName: string) => {
        return result && this.validateInput(inputName);
      },
      true
    );

    if (isValid) {
      this.loading = true;

      const dataToSend = Object.keys(this.formConfig).reduce(
        (result: any, key: string) => {
          result[key] = this.formConfig[key].model;
          return result;
        },
        {}
      );

      const appSubBrand = this.getSubBrandPath();

      const newsletterResponse: any = await NewsletterRepository.postSubscription(
        dataToSend,
        this.formId,
        Store.frontend,
        appSubBrand,
        this.getRequestHeaders()
      );

      // Show notifications
      if (newsletterResponse.type === ResultType.SUCCESS) {
        if (this.isInModal) {
          this.showDefaultAlert = false;
        }

        if (this.showResponseInModal) {
          Store.modal.className = "modal-lg modal--haemacovery";
          Store.modal.setLayoutComponent(
            LayoutComponent.createEmpty("NewsletterSubmitModal", {
              childrenComponents: this.sendSuccessMessage
            })
          );
          Store.modal.showModal();
        } else {
          this.alertMessage = this.sendSuccessMessage;
          this.showAlert = true;
          this.alertType = AlertType.SUCCESS;
        }
      }

      this.loading = false;

      // Reset model
      this.attemptSubmit = false;
      this.formConfig.resetModels();
    } else {
      this.focusFirstError();
    }
  }

  getSubBrandPath() {
    if (Store.isBrand() || Store.isEvents()) {
      switch (true) {
        case router.app.$route.path.includes("haemacovery"):
          return "/haemacovery";

        default:
          return "";
      }
    }

    return "";
  }

  resetForm() {
    this.formConfig.resetModels();
  }
}
