import config, { FrontendModules } from "../config";
import {
  EventBus,
  ROUTER_AFTER_CHILD,
  ROUTER_AFTER_EACH
} from "../util/EventBus";
import {
  ContentQueue,
  ExecutionQueue,
  DatabaseSavedArticleList
} from "../util/Store";
import Vue from "vue";
import VueRouter from "vue-router";
import { Route, RouteConfig } from "vue-router/types/router";
import { ROUTE_NAME_TESTGUIDELINE } from "./Constants";
import { changeContextByRoute, LoginHandler } from "..";

export enum LocalStorageSavedRoutes {
  PREVIOUS_ROUTE = "prevRoute",
  CURRENT_ROUTE = "currentRoute"
}

Vue.use(VueRouter);

const routes: RouteConfig[] = [];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to: any = {}) {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        let r = (this as VueRouter).currentRoute;

        if (
          !(
            r.matched.length > 1 &&
            r.matched.some(
              (item: any = {}) => item.name === ROUTE_NAME_TESTGUIDELINE
            ) &&
            config
              .getFrontendConfiguration()
              .modules.includes(FrontendModules.TESTGUIDELINE_ROUTING)
          )
        ) {
          return { x: 0, y: 0 };
        }
      }
    }
  });

let router = createRouter();

router.beforeEach(async (to: Route, from: Route, next: any) => {
  // @ts-ignore
  window.firstVisit = from.name === null;
  changeContextByRoute(config);

  if (
    config.getFrontendConfiguration().modules.includes(FrontendModules.LOGIN)
  ) {
    await LoginHandler.handleLoginOnBootstrap(to);
  }

  if (
    config
      .getFrontendConfiguration()
      .modules.includes(FrontendModules.BATCH_LOGIN)
  ) {
    await LoginHandler.handleBatchLoginOnBootstrap();
  }

  // save previous route to local storage
  if (localStorage.getItem(LocalStorageSavedRoutes.CURRENT_ROUTE) !== to.path) {
    // @ts-ignore
    window.pathChanged = true;

    localStorage.setItem(
      LocalStorageSavedRoutes.PREVIOUS_ROUTE,
      localStorage.getItem(LocalStorageSavedRoutes.CURRENT_ROUTE) || "/"
    );
    localStorage.setItem(LocalStorageSavedRoutes.CURRENT_ROUTE, to.path || "/");
  } else {
    // @ts-ignore
    window.pathChanged = false;
  }

  DatabaseSavedArticleList.reset();
  ContentQueue.resetQueue();
  ExecutionQueue.resetQueue();
  next();
});

router.afterEach((to: Route, from: Route) => {
  /*
   * Special router event handling after testguideline childroute call
   * emit ROUTER_AFTER_CHILD if:
   * 1. the route we're coming from did already exist (no direct call)
   * 2. we're on a child route
   * 3. we're matching "testleitfaden" within one of our routes
   * 4. we enabled TESTGUIDELINE_ROUTING for the frontend
   */
  const metaId: any = from && from.meta ? from.meta.id : null;
  if (
    metaId &&
    to.matched.length > 1 &&
    to.matched.some(
      (item: any = {}) => item.name === ROUTE_NAME_TESTGUIDELINE
    ) &&
    config
      .getFrontendConfiguration()
      .modules.includes(FrontendModules.TESTGUIDELINE_ROUTING)
  ) {
    EventBus.$emit(ROUTER_AFTER_CHILD, {
      name: ROUTE_NAME_TESTGUIDELINE
    } as RouterPayload);
    return;
  }

  /* Default router event handling */
  EventBus.$emit(ROUTER_AFTER_EACH);
});

// /**
//  * Reset the routes so we don't get the duplicate route warning
//  * According to https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
//  */
// export function resetRouter() {
//   const newRouter = createRouter();
//   (router as any).matcher = (newRouter as any).matcher;
// }

export class RouterPayload {
  name!: string;
}

export default router;
