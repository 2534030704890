/*
 * How to use the EventBus:
 *
 * You can use the EventBus for all components to let them subscribe to global events/channels
 * that you can define yourself right here in the EventBus.ts
 *
 *
 * 1. Register to an event/channel
 * In the component that wants to subscribe to a certain event/channel,
 * write the following code (e.g. in mounted hook):
 *
 * EventBus.$on(CHANNEL_NAME, callback);
 *
 *
 * 2. Unsubscribe from event/channel
 * If you want to end the subscription, you write:
 *
 * EventBus.$off(CHANNEL_NAME);
 *
 * 3. Emit an event/channel, that others can subscribe to
 * Now that you know how to subscribe to an event/channel its also important to understand
 * how you can fire it, so that other components can subscribe to it.
 *
 * EventBus.$emit(CHANNEL_NAME, payload)
 *
 *
 * For examples see `src/components/v2/events/EventRegistration.vue`
 */

import Vue from "vue";

export const EventBus = new Vue();
export const CHANNEL_EVENTLIST_CHANGED = "component:eventlist-changed";
export const CHANNEL_EVENTLIST_NAMES_CHANGED =
  "component:eventlist-names-changed";
export const CHANNEL_EVENTLIST_SPEAKER_SETTING =
  "component:eventlist-speaker-setting";
export const CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED =
  "component:eventregistration-insurance-changed";
export const CHANNEL_EVENTREGISTRATION_SUCCESS =
  "component:eventregistration-success";
export const CHANNEL_EVENTREGISTERSUCCESS_CLOSED =
  "component:eventregistersuccess-closed";
export const CHANNEL_NEWPASSWORD_TRIGGER_RESET =
  "component:newpassword-trigger-reset";
export const USER_LOGIN = "user:login";
export const USER_LOGOUT = "user:logout";
export const ROUTES_LOADED = "routes:loaded";
export const EVENT_PROGRAM_ADD_FAVORITE = "eventprogram:add-favorite";
export const EVENT_PROGRAM_REMOVE_FAVORITE = "eventprogram:remove-favorite";
export const APP_INITIALIZING = "app:initializing";
export const APP_INITIALIZED = "app:initialized";
export const APP_LOGIN_HANDLED = "app:login-handled";
export const APP_DATA_LOADED = "app:data-loaded";
export const ROUTER_AFTER_EACH = "router:after-each";
export const ROUTER_AFTER_CHILD = "router:after-child";

export const SLIDE_LINK_CLICKED = "component:slide-link:clicked";
export const GUIDELINE_FORM_QUESTION_ANSWERED =
  "component:guideline-form-question:clicked";

export const READSPEAKER_LOADED = "plugin:readspeaker:loaded";

export const TNM_FILTER_SUBMITTED = "tnmfilter:submitted";
export const TNM_FILTER_RESETTED = "tnmfilter:resetted";
export const RECEPTOR_FILTER_SUBMITTED = "receptorfilter:submitted";
export const CANCER_STAGE_OPEN = "cancerstage:open";
export const CANCER_STAGE_CLOSE = "cancerstage:close";
export const CANCER_TYPE_OPEN = "cancertype:open";
export const CANCER_TYPE_CLOSE = "cancertype:close";
export const ACCORDION_CLICKED = "accordion:clicked";
export const ARTICLE_SAVED = "article:saved";
export const ARTICLE_UNSAVED = "article:unsaved";
export const LCMODAL_CLOSED = "modal:close";
export const SELECT_MENU_ITEM = "menu:select-item";
export const ANIMATION_PLAY_TRIGGERED = "lottie-animation:start";
export const VIDEO_FINISHED = "video:finished";
export const PREVIOUS_QUESTION = "quiz:previous";

export const FILTER_ARTICLES_BY_TAG = "external-tag-filter:clicked";
export const FILTER_ARTICLES_BY_TAG_DONE = "tag-filter:finished";

export const NAVBAR_DROPDOWN_VISIBILITY_CHANGED = "navbar-toggler:clicked";
