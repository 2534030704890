
import { Component, Vue } from "vue-property-decorator";
import {
  EventBus,
  TNM_FILTER_RESETTED,
  TNM_FILTER_SUBMITTED,
  CANCER_STAGE_OPEN,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  Storage,
  TNMFilters,
  TNM_FILTERS_STORAGE_KEY
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CancerStage extends BaseComponent {
  public show_details!: boolean;
  public showMe!: boolean;
  public cancerType!: string;
  public id!: string;
  private storageFilters!: string | TNMFilters;

  private cancerTypeMapping!: { [key: string]: any };

  getComponentType() {
    return "cancer_stages";
  }

  constructor() {
    super();
    this.show_details = false;
    this.showMe = true;
    this.cancerType = "no_selection";
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);

    this.cancerTypeMapping = {
      "-": "bk_hub.cancer_types.no_selection",
      no_selection: "bk_hub.cancer_types.no_selection",
      tnbc: "bk_hub.cancer_types.tnbc",
      hrplus: "bk_hub.cancer_types.HR+",
      her2plus: "bk_hub.cancer_types.her2+",
      hrplusher2plus: "bk_hub.cancer_types.hr+her2+"
    };

    this.storageFilters = Storage.getLocal(
      TNM_FILTERS_STORAGE_KEY,
      false,
      false
    );
    if (this.storageFilters) {
      this.storageFilters = JSON.parse(this.storageFilters as string);
    }

    EventBus.$on(TNM_FILTER_SUBMITTED, this.hideMeBasedOnTNMRules);
    EventBus.$on(TNM_FILTER_RESETTED, this.resetMyVisibility);
    EventBus.$on(CANCER_STAGE_OPEN, this.showDetailsBasedOnEvent);
  }

  async mounted() {
    await super.mounted();
    this.initializeBasedOnStorageFilters();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      let hash = this.$router.currentRoute.hash;

      if (`#${this.shortName}` === hash) {
        this.toggleDetails();
      }
    });
  }

  get cockpitId() {
    return this.getContentAttribute("_id");
  }

  get shortName() {
    return this.getContentAttribute("short_name");
  }

  get layout() {
    return this.getContentAttribute("content");
  }

  get tnm_categories() {
    return this.getContentAttribute("tnm_categories");
  }

  get teaserLayout() {
    let filtered = this.layout.filter((layoutElement: any, index: number) => {
      return index <= 2 && !layoutElement.settings.class.includes("cs-3d-link")
        ? layoutElement
        : false;
    });

    if (Array.isArray(filtered) && filtered.length > 2) {
      return filtered.splice(0, 2);
    }

    return filtered;
  }

  get detailLayout() {
    return this.layout.filter((layoutElement: any, index: number) => {
      return index > 2 && !layoutElement.settings.class.includes("cs-3d-link")
        ? layoutElement
        : false;
    });
  }

  get link3d() {
    return this.layout.filter((layoutElement: any, index: number) => {
      return layoutElement.settings.class.includes("cs-3d-link");
    });
  }

  get bookmarker() {
    return LayoutComponent.createEmpty("bookmarker", {
      collection_title: "3D Model",
      collection: {
        link: "cancer_stages",
        _id: this.getContentAttribute("_id"),
        display: this.getContentAttribute("id")
      }
    });
  }

  getMappedCancerType() {
    return this.cancerTypeMapping[this.cancerType];
  }

  toggleDetails() {
    this.show_details = !this.show_details;

    if (this.show_details) {
      EventBus.$emit(CANCER_STAGE_OPEN, this.id);

      // Matomo tracking
      matomo.genericEvent(
        this.shortName,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.CLICK_BUTTON_CANCER_STADIUM
      );
    }
  }

  showDetailsBasedOnEvent(id: string) {
    if (this.id != id) {
      this.show_details = false;
    }
  }

  /**
   * This method is used as an eventlistener for EventBus.TNM_FILTER_SUBMITTED
   * If the given payload fits the tnm attributes of this cancer stage, the cancer stage
   * will be shown. If they don't fit, the cancer stage will be hidden
   */
  hideMeBasedOnTNMRules(payload: any) {
    let hide: boolean = true;

    if (Array.isArray(this.tnm_categories)) {
      this.tnm_categories.forEach((tnm: any) => {
        if (
          tnm.t === payload.t.key &&
          tnm.n === payload.n.key &&
          tnm.m === payload.m.key
        ) {
          hide = false;
        }
      });
    }

    this.cancerType = payload.type.key;

    this.show(!hide);

    // Matomo tracking
    if (this.showMe) {
      matomo.genericEvent(
        this.shortName,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.SELECT_TNM_STADIUM
      );
    }

    Vue.nextTick(() => this.showDetailsIfItsPrimary());
  }

  /**
   * If the cancer stage is the first one in the list of filtered stages, open the details
   */
  showDetailsIfItsPrimary() {
    const cancerStages = window.document.getElementsByClassName("cancer-stage");
    const filteredCancerStages = Array.from(cancerStages)
      .filter((cancerStage: any) => cancerStage.style.display !== "none")
      .map((cancerStage: any) => cancerStage.id);

    if (!filteredCancerStages.length) {
      return;
    }

    const currentCancerStageIsPrimary =
      filteredCancerStages[0] === this.shortName;

    if (!currentCancerStageIsPrimary) {
      return;
    }

    this.scrollToStage();

    if (this.show_details) {
      return;
    }

    this.toggleDetails();
  }

  scrollToStage() {
    const appThemeRoot = document.querySelector("#app > div");

    let menuHeight = 0;

    if (appThemeRoot) {
      menuHeight =
        parseInt(
          getComputedStyle(appThemeRoot).getPropertyValue("--menu-height")
        ) || 0;
    }

    // @ts-ignore
    if (this.$refs.cancerStage) {
      // @ts-ignore
      // eslint-disable-next-line
      (this.$refs.cancerStage as HTMLElement).style.scrollMarginTop = `${menuHeight}px`;

      // @ts-ignore
      // scroll to the cancer stage
      (this.$refs.cancerStage as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }

  checkStorageFilters() {
    const coercedFilters = this.storageFilters as TNMFilters;
    return coercedFilters.t.key && coercedFilters.n.key && coercedFilters.m.key;
  }

  initializeBasedOnStorageFilters() {
    if (this.storageFilters && this.checkStorageFilters()) {
      this.hideMeBasedOnTNMRules(this.storageFilters);
    }
  }

  /**
   * This method is used as an eventlistener for EventBus.TNM_FILTER_RESETTED
   * It will always reset this elements visibility to true
   */
  resetMyVisibility() {
    this.show(true);
  }

  /**
   * Toggles the visibility of this element
   * Also toggles the class "no-children" for the cancer stage parent node (HTMLDivElement)
   */
  private show(show: boolean) {
    this.showMe = show;
    this.handleCSSForCancerStageParent(show);
  }

  private handleCSSForCancerStageParent(show: boolean) {
    let cancerStageNode = this.$refs.cancerStage as HTMLDivElement;
    if (cancerStageNode) {
      let cancerStageParent = cancerStageNode.parentNode as HTMLDivElement;

      if (cancerStageParent.classList.contains("article-column")) {
        this.toggleCSSClassForCancerStageParent(show, cancerStageParent);
      }
    }
  }

  private toggleCSSClassForCancerStageParent(
    show: boolean,
    el: HTMLDivElement
  ) {
    if (!show) {
      el.classList.add("no-children");
    } else {
      el.classList.remove("no-children");
    }
  }
}
