
import { Component, Vue, Prop } from "vue-property-decorator";
import validator from "validator";
import moment from "moment";
import { ContentRepository, FeedRepository, ResultType, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum AlertType {
  SUCCESS = "success",
  ERROR = "error"
}

@Component
export default class LcFeedEntry extends BaseComponent {
  @Prop() saveSuccessMessage!: string;

  public approvedSubEntriesData!: Array<any>;
  public likes!: Array<string>;
  public currentLikesNo!: number;
  public likeImage!: string;
  public attemptSubmit: boolean = false;
  public loading: boolean = false;
  public isCollapsed: boolean = true;
  public readMore: boolean = false;
  public feedMessage: string = "";
  public truncatedWordsLimit: number = 40;
  public showReadMore: boolean = false;
  public AlertType = AlertType;
  public alertType: string = "";
  public showAlert: boolean = false;
  public alertMessage: string = "";
  // Form Model
  public comment: string = "";
  public author: string = "";

  constructor() {
    super();
    this.approvedSubEntriesData = [];
    this.likes = [];
    this.currentLikesNo = 0;
    this.likeImage = "like-invert.svg";
  }

  getComponentType() {
    return "feed_entries";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.toggleReadMore();
      this.likes = this.getContentAttribute("likes");
      this.currentLikesNo = this.likes.length - 1;
      this.likeImage = this.likes.includes(User.getProperty("id").toString())
        ? "like.png"
        : "like-invert.svg";

      this.approvedSubEntries();
    });
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  async approvedSubEntries() {
    const subEntries = this.getContentAttribute("sub_entries");
    if (subEntries && subEntries.length > 0) {
      let subEntriesResponse = await ContentRepository.getCollectionItemByTypeAndId(
        subEntries[0].settings.content.link,
        subEntries.map((subEntry: any) => subEntry.settings.content._id),
        0
      );
      if (subEntriesResponse.type == ResultType.SUCCESS) {
        if (subEntriesResponse.value && subEntriesResponse.value.length > 0) {
          this.approvedSubEntriesData.push(...[...subEntriesResponse.value]);
        }
      }
    }
    return [];
  }

  /************************************/
  // Business logic

  get validateAuthor() {
    return !validator.isEmpty(this.author);
  }

  get validateComment() {
    return !validator.isEmpty(this.comment);
  }

  async validate(e: any) {
    this.attemptSubmit = true;

    e.preventDefault();
    e.stopPropagation();

    if (this.validateAuthor && this.validateComment) {
      this.loading = true;

      // Save FeedEntry
      const feedResponse: any = await FeedRepository.saveFeedEntry(
        `feed.${moment().format("YYYY-MM-DD-hh-mm-ss-SS")}`,
        this.comment,
        this.author
      );

      if (feedResponse.type === ResultType.SUCCESS) {
        // Get all SubEntries
        const subEntriesResponse = await FeedRepository.getSubEntries(
          this.content[0]._id
        );
        if (subEntriesResponse.type === ResultType.SUCCESS) {
          // Link feedEntry to a parent feedEntry
          const linkResponse: any = await FeedRepository.linkToSubEntries(
            this.content[0]._id,
            subEntriesResponse.value,
            feedResponse.value._id
          );

          // Show notifications
          this.setAlert(linkResponse);
        }

        // Show notifications
        this.setAlert(subEntriesResponse);

        // Reset model
        this.attemptSubmit = false;
        this.author = "";
        this.comment = "";
      }

      this.loading = false;
    }
  }

  async editFeedEntry() {
    const userId = User.getProperty("id").toString();

    if (!this.likes.includes(userId)) {
      const editReponse: any = await FeedRepository.editFeedEntry(
        this.content[0]._id,
        [...this.likes, userId]
      );
      if (editReponse.type === ResultType.SUCCESS) {
        this.likes = [...editReponse.value.likes];
        this.currentLikesNo++;
        this.likeImage = "like.png";
      }
    }
  }

  toggleCollapse() {
    const retValue: boolean = this.isCollapsed === true ? false : true;
    this.isCollapsed = retValue;
    return retValue;
  }

  stripHtml(value: any) {
    const container = document.createElement("div");
    container.innerHTML = value;
    const text = container.textContent || container.innerText || "";
    return text;
  }

  truncateText = (text: string, limit: number) =>
    text
      .trim()
      .split(" ")
      .slice(0, limit)
      .join(" ");

  toggleReadMore() {
    const textContent = this.stripHtml(this.text);

    this.readMore = !this.readMore;
    this.showReadMore =
      textContent.trim().split(" ").length > this.truncatedWordsLimit;

    if (this.readMore && this.showReadMore) {
      this.feedMessage =
        this.truncateText(textContent, this.truncatedWordsLimit) + "...";
    } else {
      this.feedMessage = this.text;
    }
  }

  setAlert(response: any) {
    const { type, message } = response;

    if (type === ResultType.SUCCESS) {
      this.showAlert = true;
      this.alertType = AlertType.SUCCESS;
      this.alertMessage = this.saveSuccessMessage;
    }
  }

  handleCloseAlert() {
    this.showAlert = false;
  }

  /************************************/
}
