
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class TrainingQuizRatingConfirmationModal extends BaseComponent {
  constructor() {
    super();
  }
}
