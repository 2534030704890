import EventRepository from "../api/repositories/EventRepository";
import { ResultType } from "../api/util/Result";
import { Storage } from "../api/util/Storage";
import User, { UserLoginStatus } from "../models/User";

export class EventExportList {
  readonly EVENT_FAV_LIST = "_ww_eel";

  private eventFavList!: { [key: string]: string };
  private dbItems!: EventExportDBData[];

  public updated!: number;

  constructor() {
    this.eventFavList =
      Storage.getLocal(this.EVENT_FAV_LIST, false, false) || {};

    this.dbItems = [];

    if (!User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN)) {
      this.updated = Date.now();
      return;
    }

    const userId = User.getProperty("id");

    this.getDBItems(userId).then((items: EventExportDBData[]) => {
      this.dbItems = items;
      this.updated = Date.now();
    });
  }

  static generateID(
    programId: string,
    segmentId: number,
    groupId: number,
    contentId: number
  ) {
    return `${programId}${segmentId}${groupId}_${contentId}`;
  }

  update() {
    this.updated = Date.now();
  }

  toggleItem(id: string) {
    let structure = this.getStructure(id);
    if (this.getItem(id) == structure.value) {
      this.removeItem(id);
    } else {
      this.addItem(id);
    }
  }

  async toggleDBItem(id: string, dbData: EventExportDBData) {
    const success = await (this.isDbChecked(dbData)
      ? this.removeDbItem(dbData)
      : this.addDbItem(dbData));

    if (success) {
      this.toggleItem(id);
      this.dbItems = await this.getDBItems(dbData.userId);
    }
  }

  async getDBItems(userId: string): Promise<EventExportDBData[]> {
    const result = await EventRepository.getEventUserSessionData(userId);

    if (result.type === ResultType.SUCCESS) {
      return result.value;
    } else {
      return [];
    }
  }

  async addDbItem(dbData: EventExportDBData): Promise<Boolean> {
    const result = await EventRepository.postEventUserSessionData(dbData);

    return result.type === ResultType.SUCCESS;
  }

  async removeDbItem(dbData: EventExportDBData): Promise<Boolean> {
    const result = await EventRepository.deleteEventUserSessionData(
      dbData.userId,
      dbData.sessionId
    );

    return result.type === ResultType.SUCCESS;
  }

  addItem(id: string) {
    let structure = this.getStructure(id);
    this.eventFavList[structure.key] = structure.value;

    this.saveToStorage();
  }

  removeItem(id: string) {
    let structure = this.getStructure(id);
    delete this.eventFavList[structure.key];

    this.saveToStorage();
  }

  getItem(id: string) {
    let structure = this.getStructure(id);
    return this.eventFavList[structure.key];
  }

  getList(): any {
    return this.eventFavList;
  }

  getArray(): string[] {
    let cleanedList: string[] = [];

    Object.keys(this.eventFavList).forEach((key: string) => {
      cleanedList.push(this.eventFavList[key]);
    });

    return cleanedList;
  }

  isDbChecked(dbData: EventExportDBData): boolean {
    return this.dbItems.some(
      item =>
        item.sessionId === dbData.sessionId &&
        item.workshopId === dbData.workshopId
    );
  }

  protected getStructure(id: string) {
    let idParts = id.split("_");
    return {
      key: `${idParts[0]}`,
      value: id
    };
  }

  protected saveToStorage() {
    this.update();
    Storage.saveLocal(this.EVENT_FAV_LIST, this.eventFavList, false, false);
  }
}

export interface EventExportDBData {
  sessionId: string;
  sessionName: string;
  sessionTime: string;
  workshopId: string;
  workshopName: string;
  userId: string;
  userName: string;
}

export let eventExportList = new EventExportList();
