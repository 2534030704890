
import { Component, Vue } from "vue-property-decorator";
import {
  ContentRepository,
  LayoutComponent,
  ResultType,
  RoutingHelper,
  TrainingRepository,
  User
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Training extends BaseComponent {
  public linkTarget!: string;
  public isCompleted!: boolean;
  public dbTrainings: any = [];
  private userIdx = User.getProperty("id");
  public finisedTrainings: [] = [];
  public notfinishedTrainings: [] = [];

  constructor() {
    super();
    this.linkTarget = "#";
    this.$forceUpdate();
  }

  getComponentType() {
    return "trainings";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.linkTarget = await this.getLink();
    });
    let userData = {
      userId: this.userIdx
    };
    let result = await TrainingRepository.getCompleted(userData);
    if (result.type == ResultType.SUCCESS) {
      this.dbTrainings = result.value;
    }

    this.finisedTrainings = this.content
      ? this.content.filter((training: any) =>
          this.completedTrainingIds.includes(
            // @ts-ignore
            training.quiz[0].settings.content._id
          )
        )
      : [];
    this.notfinishedTrainings = this.content
      ? this.content.filter(
          (training: any) =>
            !this.completedTrainingIds.includes(
              // @ts-ignore
              training.quiz[0].settings.content._id
            )
        )
      : [];
  }

  get completedTrainingIds(): [] {
    return this.dbTrainings
      .filter((training: any) => training.trainingCompleted)
      .map((training: any) => training.trainingQuizId);
  }

  get completedTrainings() {
    return this.finisedTrainings.length > 0 ? this.finisedTrainings : [];
  }

  get unfinishedTrainings() {
    return this.notfinishedTrainings.length > 0
      ? this.notfinishedTrainings
      : [];
  }

  async getLink() {
    let result = this.link;

    if (this.file) {
      result = RoutingHelper.getFilePath(this.file.path);
    }

    return result;
  }

  async loadFile() {
    const result = await ContentRepository.getCollectionItemByTypeAndId(
      this.file.link,
      [this.file._id],
      0
    );

    if (result.type == ResultType.SUCCESS) {
      return result.value[0].file;
    }

    return null;
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get link() {
    let link = this.getContentAttribute("training_link");
    return link ? link : "#";
  }

  get file() {
    return this.getContentAttribute("file");
  }

  get cssClass() {
    return this.layoutComponent.class;
  }

  get contentItems() {
    let contentItems = this.getContentAttribute("content");

    if (contentItems && contentItems.length > 0) {
      return contentItems.map((comp: any) => new LayoutComponent(comp));
    }
    return [];
  }

  get clickHandler() {
    return "click";
  }
}
