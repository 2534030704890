
import { Component, Vue } from "vue-property-decorator";
import BaseWidget from "./BaseWidget.vue";
import { LayoutComponent, router } from "common";

@Component
export default class ModalWidget extends BaseWidget {
  public uid!: string;

  constructor() {
    super();
    this.uid = Math.random()
      .toString(36)
      .substr(2, 9);
  }
  getComponentType() {
    return "modal_widgets";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      const query = router.app.$route.query.newsletter;

      if (query) {
        const modalToggle = this.$refs[`modal-${this.uid}`];

        if (modalToggle) {
          // Toggle modal.
          (modalToggle as HTMLElement).click();
        }
      }
    });
  }

  get modalToggleComp() {
    const modalToggleComp = this.getContentAttribute("modal_toggle");
    if (modalToggleComp && modalToggleComp.length > 0) {
      return new LayoutComponent(modalToggleComp[0]);
    }

    return null;
  }

  get headline() {
    let headline = this.getContentAttribute("modal_headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    return null;
  }

  get modalContent() {
    let content = this.getContentAttribute("modal_content");
    if (content && content.length > 0) {
      return content
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }
}
