
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  ContentRepository,
  LayoutComponent,
  ResultType,
  router,
  Store,
  Result
} from "common";
import BaseComponent from "../../base/BaseComponent.vue";
import {
  ArticleWidgetType,
  ArticleWidgetLayoutType,
  CockpitLink
} from "../ArticleWidget.vue";

export enum Type {
  IMAGE_WIDGET = "image_widget",
  ARTICLE_WIDGET = "article_widget",
  PAGE_WIDGET = "page_widget",
  TAGS_WIDGET = "tags_widget"
}

@Component
export default class DynamicViewWidget extends BaseComponent {
  /**
   * "propCockpitLink" or "articleId" can be pased via props
   * The collection name from "propCockpitLink" will be used for get content request
   * Otherwise, if "articleId" is provided, the "articles" collection will be used
   **/
  @Prop() articleId!: string;
  @Prop() propCockpitLink!: CockpitLink;

  @Prop() btnLabel!: string;
  @Prop() widgetType!: Type;
  @Prop() widgetWiewMode!: ArticleWidgetType;
  @Prop() articleWidgetLayoutType!: ArticleWidgetLayoutType;
  @Prop({ default: "" }) className!: string;

  public Type = Type;
  public loading!: boolean;
  public articleContent!: any;
  public heroContents!: any;
  public headline!: LayoutComponent | null;
  public image!: LayoutComponent | null;

  constructor() {
    super();
    this.loading = true;
    this.articleContent = null;
    this.heroContents = null;
    this.headline = null;
    this.image = null;
  }

  async mounted() {
    Vue.nextTick(async () => {
      this.articleContent = await this.loadContent();
      this.loading = false;
      this.getHeadline();
      this.getImage();
    });
  }

  async loadContent() {
    if (!this.contentId) {
      return;
    }

    const result = await ContentRepository.getContentById(
      this.contentId,
      this.appBrand ?? Store.appBrand,
      this.cockpitLink ? this.cockpitLink.link : "articles"
    );

    if (result && result.type === ResultType.SUCCESS) {
      // @ts-ignore
      return result.value;
    }

    return null;
  }

  get cockpitContent(): CockpitLink | null {
    return this.getSettingsAttribute("content") || null;
  }

  get appBrand(): string | null {
    return this.getSettingsAttribute("app_brand").display || null;
  }

  get contentId() {
    if (this.articleId) {
      return this.articleId;
    }

    if (this.cockpitLink) {
      return this.cockpitLink._id;
    }
    return null;
  }

  get type(): Type {
    if (this.widgetType) {
      return this.widgetType;
    }

    return this.getSettingsAttribute("type") || Type.ARTICLE_WIDGET;
  }

  get viewMode(): ArticleWidgetType {
    if (this.widgetWiewMode) {
      return this.widgetWiewMode;
    }

    return this.getSettingsAttribute("view_mode") || ArticleWidgetType.vertical;
  }

  get widgetLayoutType(): ArticleWidgetLayoutType {
    if (this.articleWidgetLayoutType) {
      return this.articleWidgetLayoutType;
    }

    return this.getSettingsAttribute("layout") || ArticleWidgetLayoutType.basic;
  }

  get cardType(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("card_widget_type");
  }

  get externalLinkWidget(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("external_resource_widget_link");
  }

  get headlineExternalResourceWidget(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("external_resource_widget_headline")[0];
  }

  get descriptionExternalResourceWidget(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("external_resource_widget_description")[0];
  }

  get tagsExternalResourceWidget(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("external_resource_widget_tags") || [];
  }

  get buttonExternalResourceWidget(): ArticleWidgetLayoutType {
    return this.getSettingsAttribute("external_resource_widget_button");
  }

  get articleSlug() {
    if (
      this.articleContent &&
      this.articleContent.slug &&
      this.articleContent.slug.length > 0
    ) {
      return this.articleContent.slug;
    }
    return null;
  }

  get hero() {
    try {
      return this.articleContent.hero[0];
    } catch (e) {
      return null;
    }
  }

  get fullWidgetClickable() {
    return this.getSettingsAttribute("full_widget_clickable");
  }

  get customPdfFile() {
    return this.getSettingsAttribute("custom_pdf");
  }

  async getHeadline() {
    if (this.articleContent) {
      // Get headline from widget headline article field
      const widgetHeadline = this.articleContent.view_widget_headline || null;

      if (widgetHeadline && widgetHeadline._id) {
        this.headline = LayoutComponent.createEmpty("Headline", {
          type: "h3",
          content: {
            _id: widgetHeadline._id
          }
        });
      } else if (this.hero) {
        // Geat headline from the hero if widget headline field is empty
        await this.loadHeroContent();

        if (this.heroContents != null && this.heroContents.headline) {
          const headlineEl = this.heroContents.headline.filter(
            (item: any) => item.component === "headline"
          );

          if (headlineEl && headlineEl.length > 0) {
            this.headline = LayoutComponent.createEmpty("Headline", {
              type: "h3",
              content: {
                _id: headlineEl[0].settings.content._id
              }
            });
          }
        }
      }
    }
  }

  get text() {
    if (this.articleContent) {
      // Get text from widget text article field
      const widgetText = this.articleContent.view_widget_text || null;

      if (widgetText && widgetText._id) {
        const comp = LayoutComponent.createFromCollectionItem(
          "LcText",
          widgetText,
          {
            type: "body"
          }
        );

        // Return text format based on template type
        if (this.type === Type.ARTICLE_WIDGET) {
          return [comp];
        }

        return comp;
      } else {
        // Get the text from article body if widget text field is empty
        const bodyContent =
          this.articleContent.mainmatter &&
          this.articleContent.mainmatter.length
            ? this.articleContent.mainmatter
            : [];

        // Get the first lc_text from article body
        if (bodyContent.length > 0) {
          const textItems = bodyContent.filter(
            (item: any) => item.component === "lc_text"
          );

          if (textItems.length > 0) {
            // Return text format based on template type
            if (this.type === Type.ARTICLE_WIDGET) {
              return [textItems[0]];
            }

            return LayoutComponent.createEmpty("LcText", {
              type: "body",
              content: {
                _id: textItems[0].settings.content._id
              }
            });
          }
        }
      }
    }

    return null;
  }

  get widgetBtnLabel() {
    if (this.btnLabel) {
      return this.btnLabel;
    }

    return this.articleContent && this.articleContent.view_widget_btn_label
      ? this.articleContent.view_widget_btn_label
      : "Mehr erfahren";
  }

  get bookmarkableWidgetTitle() {
    return this.articleContent ? this.articleContent.view_widget_title : null;
  }

  get showImage() {
    return this.articleContent
      ? this.articleContent.view_widget_show_image
      : null;
  }

  get articleWidget() {
    if (this.type === Type.ARTICLE_WIDGET) {
      const comp = LayoutComponent.createEmpty("ArticleWidget", {
        type: this.viewMode,
        layout: this.widgetLayoutType,
        visibility: true
      });

      return comp;
    }

    return null;
  }

  get cockpitLink(): CockpitLink | null {
    // Get data from propCockpitLink prop
    if (
      this.propCockpitLink &&
      Object.prototype.hasOwnProperty.call(this.propCockpitLink, "_id")
    ) {
      return this.propCockpitLink;

      // Get data from cockpit layout component
    } else if (
      this.cockpitContent &&
      Object.prototype.hasOwnProperty.call(this.cockpitContent, "_id")
    ) {
      return this.cockpitContent;

      // If there is no layput component ("articleId" is passed via props), use "articles" colection as link by default
    } else if (this.articleContent) {
      return {
        display: this.articleContent.id,
        link: "articles",
        _id: this.articleContent._id
      };
    }

    return null;
  }

  get articleWidgetClassName() {
    return this.className;
  }

  async loadHeroContent() {
    if (this.hero != null && this.hero.settings.content) {
      const result = await ContentRepository.getCollectionItemByTypeAndId(
        this.hero.settings.content.link,
        [this.hero.settings.content._id],
        0
      );

      if (result.type == ResultType.SUCCESS) {
        try {
          this.heroContents = result.value[0];
        } catch (e) {
          this.heroContents = null;
        }
      }
    }
  }

  async getImage() {
    if (this.articleContent) {
      const image = this.articleContent.view_widget_image || null;
      if (image && image._id) {
        const result = await ContentRepository.getCollectionItemByTypeAndId(
          "images",
          [image._id],
          0
        );

        if (result.type == ResultType.SUCCESS) {
          try {
            this.image = LayoutComponent.createFromCollectionItem(
              "LcImage",
              result.value[0]
            );
          } catch (e) {
            this.image = null;
          }
        }
      }
    }
  }

  get tags() {
    return this.articleContent?.tags || [];
  }

  get downloadFunctionalityEnabled(): boolean {
    return !!this.getSettingsAttribute("enable_download");
  }

  get printFunctionalityEnabled(): boolean {
    return !!this.getSettingsAttribute("enable_print");
  }

  get downloadButton() {
    return LayoutComponent.createEmpty("button_widget", {
      content: {
        _id: "64c2383cf2c52b00c00d6419"
      }
    });
  }

  get printButton() {
    return LayoutComponent.createEmpty("button_widget", {
      content: {
        _id: "64c8ee6865a3335b1d02c022"
      }
    });
  }

  get shareFunctionalityEnabled(): boolean {
    return this.getSettingsAttribute("enable_share");
  }

  get shareModalLayout(): LayoutComponent | null {
    let shareModal = this.getSettingsAttribute("share_modal")[0] || null;
    if (shareModal) shareModal.class = shareModal.settings?.class;
    return shareModal;
  }

  gotoView() {
    if (!this.appBrand && this.articleSlug) {
      router.push(`/${this.articleSlug}`);
    } else {
      switch (this.appBrand) {
        case "cancersupport360_hub":
          window.open("https://cancersupportplus.com/" + this.articleSlug, "_blank");
          break;
        default:
          break;
      }
    }
  }
  
  goToExternalWebsite() {
    if (this.externalLinkWidget) {
      window.open(this.externalLinkWidget, "_blank");
    }
  }
}
