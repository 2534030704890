import RoutingHelper, {
  CollectionLink,
  PageAuthenticationAccess,
  RouteResponse,
  RoutesMapping
} from "./RoutingHelper";
import config, {
  EnvironmentConfigurationInterface,
  FrontendModules
} from "../config";
import { ROUTE_NAME_TESTGUIDELINE } from "../router/Constants";
import { Route, RouteConfig } from "vue-router/types/router";
import User, {
  UserLoginStatus,
  UserProfile,
  UserRoleName
} from "../models/User";
import { LocalStorageSavedRoutes } from "../router";
import { Store } from "./Store";
import { TestGuidelineSlide } from "vue-ui";
import {
  changeContextByRoute,
  FrontendContext,
  getContextByRoute,
  getContextByUserProfile,
  LoginHandler,
  router
} from "..";

interface IRouteGenerator {
  generateRoutes(): Array<RouteConfig>;
}

export const enum ViewTypes {
  ARTICLES = "articles",
  PAGES = "pages",
  BRAND_PAGES = "brand_pages",
  EVENTS_PAGES = "events_pages"
}

interface ITestGuidelineRouter {
  getConfig(): EnvironmentConfigurationInterface;
  hasTestGuidelineModule(): boolean;
  generateTestGuidelineRoutes(
    route: RouteResponse,
    viewType: ViewTypes
  ): Array<RouteConfig>;
}

export default class RoutesGenerator
  implements IRouteGenerator, ITestGuidelineRouter {
  private routes: RoutesMapping;
  private config: EnvironmentConfigurationInterface;

  constructor(
    routes: RoutesMapping,
    config: EnvironmentConfigurationInterface
  ) {
    this.routes = { ...routes };
    this.config = config;
  }

  generateRoutes(): RouteConfig[] {
    const articles: RouteConfig[] = this.routes.articles.map(route => {
      return {
        path: "/" + route.slug,
        component: () =>
          import(/* webpackChunkName: "Article" */ "../views/Article.vue"),
        props: { id: route._id },
        meta: {
          id: route._id,
          type: ViewTypes.ARTICLES,
          authorize: {
            authentication:
              route.account_profile.display === UserProfile.LOGGED_OUT
                ? PageAuthenticationAccess.LOGGED_OUT
                : PageAuthenticationAccess.LOGGED_IN,
            roles: route.roles || []
          }
        },
        beforeEnter: (to: Route, from: Route, next: any) => {
          this.guard(to, from, next);
        },
        children: this.generateTestGuidelineRoutes(route, ViewTypes.ARTICLES)
      };
    });

    const pages: RouteConfig[] = this.routes.pages.map(route => {
      return {
        path: "/" + route.slug,
        component: () =>
          import(/* webpackChunkName: "Page" */ "../views/Page.vue"),
        props: { id: route._id },
        meta: {
          id: route._id,
          type: ViewTypes.PAGES,
          authorize: {
            authentication:
              route.account_profile.display === UserProfile.LOGGED_OUT
                ? PageAuthenticationAccess.LOGGED_OUT
                : PageAuthenticationAccess.LOGGED_IN,
            roles: route.roles || [],
            accountProfile: route.account_profile.display
          }
        },
        beforeEnter: (to: Route, from: Route, next: any) => {
          this.guard(to, from, next);
        },
        children: this.generateTestGuidelineRoutes(route, ViewTypes.PAGES)
      };
    });

    const brandPages: RouteConfig[] = this.routes.brandPages.map(route => {
      return {
        path: "/" + route.slug,
        component: () =>
          import(/* webpackChunkName: "BrandPage" */ "../views/BrandPage.vue"),
        props: { id: route._id },
        meta: {
          id: route._id,
          type: ViewTypes.BRAND_PAGES,
          authorize: {
            authentication:
              route.account_profile.display === UserProfile.LOGGED_OUT
                ? PageAuthenticationAccess.LOGGED_OUT
                : PageAuthenticationAccess.LOGGED_IN,
            roles: route.roles || []
          }
        },
        beforeEnter: (to: Route, from: Route, next: any) => {
          this.guard(to, from, next);
        }
      };
    });

    const eventsPages: RouteConfig[] = this.routes.eventsPages.map(route => {
      return {
        path: "/" + route.slug,
        component: () =>
          import(
            /* webpackChunkName: "EventsPage" */ "../views/EventsPage.vue"
          ),
        props: { id: route._id },
        meta: {
          id: route._id,
          type: ViewTypes.EVENTS_PAGES,
          authorize: {
            authentication:
              route.account_profile.display === UserProfile.LOGGED_OUT
                ? PageAuthenticationAccess.LOGGED_OUT
                : PageAuthenticationAccess.LOGGED_IN,
            roles: route.roles || []
          }
        },
        beforeEnter: (to: Route, from: Route, next: any) => {
          this.guard(to, from, next);
        }
      };
    });

    return [...articles, ...pages, ...brandPages, ...eventsPages];
  }

  getConfig(): EnvironmentConfigurationInterface {
    return this.config;
  }

  hasTestGuidelineModule(): boolean {
    return this.config
      .getFrontendConfiguration()
      .modules.includes(FrontendModules.TESTGUIDELINE_ROUTING);
  }

  generateTestGuidelineRoutes(
    route: RouteResponse,
    viewType: ViewTypes
  ): RouteConfig[] {
    if (this.hasTestGuidelineModule()) {
      const childrenRoutes = [
        {
          path: ROUTE_NAME_TESTGUIDELINE + "/:guideline_id/:slide_slug",
          /**
           * Having the same name for the guideline routes, somehow prevents refreshing
           * unfortunately there will be a warning in the console related to duplicate
           * route names.
           */
          // name: route.slug + "-" + ROUTE_NAME_TESTGUIDELINE,
          name: ROUTE_NAME_TESTGUIDELINE,
          component: TestGuidelineSlide,
          meta: { id: route._id, type: viewType },
          props: true
        }
      ];

      return childrenRoutes;
    }

    return [];
  }

  async guard(to: Route, from: Route, next: any): Promise<void> {
    if (
      config.getFrontendConfiguration().modules.includes(FrontendModules.LOGIN)
    ) {
      const isLoggedIn =
        !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) &&
        !User.hasUserLoginStatus(UserLoginStatus.DOCCHECK_PENDING);

      /**
       * Rush MWO check here - MWO users will be able to see a single page
       * and afterwards redirected toward login
       */
      if (
        config
          .getFrontendConfiguration()
          .modules.includes(FrontendModules.MAY_WATCH_ONE)
      ) {
        LoginHandler.checkMayWatchOne();
      }

      /* If user is logged in, deny access to /login */
      if (isLoggedIn && to.path == "/login") {
        next({ path: "/" });
        return;
      }

      /* Always allow on public routes */
      if (RoutingHelper.isPublicRoute(to.path)) {
        next();
        return;
      }

      /* Check if user is logged out & trying to access a private route */
      if (
        !isLoggedIn &&
        (RoutingHelper.isPrivateRoute(to.path) ||
          (to.meta &&
            to.meta.authorize &&
            to.meta.authorize.authentication ===
              PageAuthenticationAccess.LOGGED_IN))
      ) {
        // Store the link user wanted to access
        if (
          localStorage.getItem(LocalStorageSavedRoutes.PREVIOUS_ROUTE) !=
          "/login"
        ) {
          Store.saveReturnUrl(to.fullPath);
        }

        next({ path: "/login" });
        return;
      }
    }

    if (this.hasAuthorizedRole(to)) {
      next();
    } else {
      // Redirect to 404
      next({ name: "NotFound" });
    }

    const frontConfig = config.getFrontendConfiguration();
    if (
      to.meta &&
      frontConfig.context !==
        getContextByUserProfile(to.meta.authorize.accountProfile) &&
      User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN)
    ) {
      if (getContextByRoute(to.fullPath) !== frontConfig.context) {
        // Reload page after 500ms to trigger context change
        setTimeout(() => {
          window.location.reload();
        }, 500);
        User.logout();
      }
    }
  }

  /**
   * Check if the user has the necessary role to access the route
   * @param to the route user wants to access
   * @returns boolean
   */
  hasAuthorizedRole(to: Route) {
    const userRoles = User.getUserRoleNames();

    // Public route
    if (to.meta && !to.meta.authorize) {
      return true;
    }

    // Check if we have roles authorized on this route
    if (
      to.meta &&
      to.meta.authorize &&
      to.meta.authorize.roles &&
      Array.isArray(to.meta.authorize.roles)
    ) {
      const authorizedRoles = to.meta.authorize.roles;

      // The route has no roles - it means it's a public route
      if (authorizedRoles.length === 0) {
        return true;
      }

      // Check if the user has at leas one of the authorized roles
      const hasRoles = authorizedRoles.filter((role: CollectionLink) =>
        userRoles.includes(role.display as UserRoleName)
      );

      // User is authorized
      if (hasRoles.length > 0) {
        return true;
      }
    }

    return false;
  }
}
