
import { Component } from "vue-property-decorator";
import { ANIMATION_PLAY_TRIGGERED, EventBus } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Audio extends BaseComponent {
  private initialized: boolean;
  private initializingEvents: string[];
  private audioElement!: HTMLAudioElement;

  constructor() {
    super();

    this.initialized = false;
    this.initializingEvents = ["canplay"];
  }

  getComponentType(): string {
    return "audios";
  }

  async mounted(): Promise<void> {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();

    EventBus.$on(ANIMATION_PLAY_TRIGGERED, this.triggeredPlay);

    this.$nextTick(() => {
      this.audioElement = this.$refs.audio as HTMLAudioElement;

      this.initializingEvents.forEach(event => {
        window.addEventListener(event, this.loadAudio);
      });
    });
  }

  triggeredPlay(triggeredId: string) {
    if (this.layoutComponent.id !== triggeredId) {
      return;
    }

    this.play(true);
  }

  play(shortPlay = false) {
    if (shortPlay) {
      this.audioElement.removeEventListener("timeupdate", () =>
        this.timeupdate()
      );

      this.audioElement.addEventListener("timeupdate", () => this.timeupdate());
    }

    this.audioElement.play();
  }

  timeupdate(): void {
    const currentTime = this.audioElement.currentTime;

    if (currentTime <= 2) {
      return;
    }

    this.audioElement.pause();
    this.audioElement.currentTime = 0;
  }

  beforeDestroy(): void {
    EventBus.$off(ANIMATION_PLAY_TRIGGERED);
  }

  loadAudio(): void {
    if (this.initialized) {
      return;
    }

    const audio = this.$refs.audio as HTMLAudioElement;

    if (!audio) {
      return;
    }

    this.initialized = true;
    audio.load();

    this.initializingEvents.forEach(event => {
      window.removeEventListener(event, this.loadAudio);
    });
  }

  get visibility(): boolean {
    return this.layoutComponent.settings.visibility;
  }

  get link(): string {
    return this.getContentAttribute("link");
  }
}
