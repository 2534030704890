
import { Component, Vue, Watch } from "vue-property-decorator";
import intersection from "lodash/intersection";
import {
  ContentRepository,
  LayoutComponent,
  Repository,
  ResultType,
  Storage,
  Store
} from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class ArticleFilter extends BaseWidget {
  firstSelectablesSelected!: [];
  secondSelectablesSelected!: [];
  thirdSelectablesSelected!: [];
  defaultFilter!: [];

  articles!: any[];
  loading!: boolean;

  constructor() {
    super();
    this.firstSelectablesSelected = [];
    this.secondSelectablesSelected = [];
    this.thirdSelectablesSelected = [];
    this.defaultFilter = [];
    this.articles = [];
    this.loading = false;
  }

  getComponentType() {
    return "article_filters";
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      if (this.first_filter_selectables) {
        this.defaultFilter.push.apply(
          this.defaultFilter,
          this.first_filter_selectables.map((sel: any) => {
            return sel._id;
          })
        );
      }

      if (this.second_filter_selectables) {
        this.defaultFilter.push.apply(
          this.defaultFilter,
          this.second_filter_selectables.map((sel: any) => {
            return sel._id;
          })
        );
      }

      if (this.third_filter_selectables) {
        this.defaultFilter.push.apply(
          this.defaultFilter,
          this.third_filter_selectables.map((sel: any) => {
            return sel._id;
          })
        );
      }

      if (!this.loadFiltersFromLocalStorage()) {
        this.loadArticles();
      }
    });
  }

  async mounted() {
    super.mounted();
  }

  @Watch("firstSelectablesSelected")
  @Watch("secondSelectablesSelected")
  @Watch("thirdSelectablesSelected")
  async loadArticles() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.articles = [];
    let articles: any = await ContentRepository.getArticlesByFilter(
      Store.frontend + "_hub",
      this.firstSelectablesSelected,
      this.secondSelectablesSelected,
      this.thirdSelectablesSelected,
      this.defaultFilter
    );

    if (articles.type == ResultType.SUCCESS) {
      let articleWidgets = await Repository.getArticleWidgets(
        articles.value.map((article: any) => article._id),
        this.sort_by
      );
      if (articleWidgets.type == ResultType.SUCCESS) {
        articleWidgets.value.forEach((articleId: any) => {
          let comp = LayoutComponent.createEmpty("article_widget", {
            type: "vertical",
            class: this.getArticleWidgetClass(articleId, articles.value, [
              ...this.firstSelectablesSelected,
              ...this.secondSelectablesSelected,
              ...this.thirdSelectablesSelected
            ]),
            visibility: true,
            content: {
              _id: articleId._id
            }
          });

          this.articles.push(comp);
        });
      }
    }

    this.loading = false;
    this.saveFiltersToLocalStorage();
  }

  saveFiltersToLocalStorage() {
    if (!this.saveFilterState) {
      return;
    }

    let storageObj = {
      first: this.firstSelectablesSelected,
      second: this.secondSelectablesSelected,
      third: this.thirdSelectablesSelected
    };

    Storage.saveLocal("_ww_af", storageObj, false, false);
  }

  loadFiltersFromLocalStorage() {
    if (!this.saveFilterState) {
      return false;
    }

    let savedFilters = Storage.getLocal("_ww_af", false, false);
    if (savedFilters) {
      this.firstSelectablesSelected = savedFilters.first;
      this.secondSelectablesSelected = savedFilters.second;
      this.thirdSelectablesSelected = savedFilters.third;
      return true;
    }

    return false;
  }

  get name() {
    return this.getContentAttribute("name");
  }

  get first_filter_title() {
    return this.getContentAttribute("first_filter_title");
  }

  get second_filter_title() {
    return this.getContentAttribute("second_filter_title");
  }

  get third_filter_title() {
    return this.getContentAttribute("third_filter_title");
  }

  get first_filter_selectables() {
    return this.getContentAttribute("first_filter_selectables");
  }

  get second_filter_selectables() {
    return this.getContentAttribute("second_filter_selectables");
  }

  get third_filter_selectables() {
    return this.getContentAttribute("third_filter_selectables");
  }

  get sort_by() {
    return this.getContentAttribute("sort_by");
  }

  get saveFilterState() {
    return !!this.getContentAttribute("save_filter_state");
  }

  get selectedFirstTagLabels() {
    let firstLabels = [];
    if (
      this.first_filter_selectables.length >=
      this.firstSelectablesSelected.length
    ) {
      firstLabels = this.first_filter_selectables
        .filter((sel: any) => {
          // @ts-ignore
          return this.firstSelectablesSelected.includes(sel._id);
        })
        .map((sel: any) => {
          return sel.display;
        });
    }

    return firstLabels;
  }

  get selectedSecondTagLabels() {
    let secondLabels = [];
    if (
      this.second_filter_selectables.length >=
      this.secondSelectablesSelected.length
    ) {
      secondLabels = this.second_filter_selectables
        .filter((sel: any) => {
          // @ts-ignore
          return this.secondSelectablesSelected.includes(sel._id);
        })
        .map((sel: any) => {
          return sel.display;
        });
    }
    return secondLabels;
  }

  get selectedThirdTagLabels() {
    let thirdLabels = [];

    if (this.third_filter_selectables) {
      if (
        this.third_filter_selectables.length >=
        this.thirdSelectablesSelected.length
      ) {
        thirdLabels = this.third_filter_selectables
          .filter((sel: any) => {
            // @ts-ignore
            return this.thirdSelectablesSelected.includes(sel._id);
          })
          .map((sel: any) => {
            return sel.display;
          });
      }

      return thirdLabels;
    }
    return [];
  }

  get openInNewTabIds() {
    let articleFilterMeta = this.getContentAttribute("meta");
    return articleFilterMeta ? articleFilterMeta.openNewTabIds : [];
  }

  toggleFirstFilterSelection() {
    if (
      this.firstSelectablesSelected.length ===
      this.first_filter_selectables.length
    ) {
      this.firstSelectablesSelected = [];
    } else {
      this.firstSelectablesSelected = this.first_filter_selectables.map(
        (sel: any) => {
          return sel._id;
        }
      );
    }
  }

  toggleSecondFilterSelection() {
    if (
      this.secondSelectablesSelected.length ===
      this.second_filter_selectables.length
    ) {
      this.secondSelectablesSelected = [];
    } else {
      this.secondSelectablesSelected = this.second_filter_selectables.map(
        (sel: any) => {
          return sel._id;
        }
      );
    }
  }

  toggleThirdFilterSelection() {
    if (
      this.thirdSelectablesSelected.length ===
      this.third_filter_selectables.length
    ) {
      this.thirdSelectablesSelected = [];
    } else {
      this.thirdSelectablesSelected = this.third_filter_selectables.map(
        (sel: any) => {
          return sel._id;
        }
      );
    }
  }

  // Utils
  getArticleWidgetClass(
    viewWidget: any,
    articles: any[],
    selectedTags: string[]
  ): string {
    if (selectedTags.length === 0) return "";

    const index = articles.findIndex((article: any) => {
      return article._id === viewWidget.cockpit_link._id;
    });

    if (index !== -1) {
      const tags = intersection(
        articles[index].tags.map((tag: any) => tag._id),
        selectedTags
      );

      return tags.length > 0
        ? articles[index].tags
            .find((tag: any) => tag._id === tags[0])
            .display.toLowerCase()
        : "";
    }
    return "";
  }
}
