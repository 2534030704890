
import { Component, Vue } from "vue-property-decorator";
import {
  Constants,
  LayoutComponent,
  NewsletterRepository,
  ResultType,
  RequestResponseContent,
  RESPONSES,
  router,
  Store
} from "common";

@Component
export default class NewsletterSuccess extends Vue {
  public showContent: boolean = false;
  public content: RequestResponseContent;

  constructor() {
    super();
    this.content = RESPONSES.defaultSubscribe;
  }

  async mounted() {
    const uuid: any = router.app.$route.query.uuid;

    if (uuid) {
      const newsletterResponse: any = await NewsletterRepository.subscribe(
        uuid,
        this.getRequestHeaders()
      );

      if (newsletterResponse.type === ResultType.SUCCESS) {
        if (Store.isGynonco()) {
          const textComp = LayoutComponent.createEmpty(
            "GynoncoReservationSuccess"
          );

          Store.modal.className = "modal-lg modal--response";
          Store.modal.setLayoutComponent(textComp);
          Store.modal.showModal();
          return;
        }

        if (Store.isEvents()) {
          const isHaemacovery = router.app.$route.path.includes("haemacovery");

          if (isHaemacovery) {
            const textComp = LayoutComponent.createEmpty(
              "HaemacoveryReservationSuccess"
            );

            Store.modal.className = "modal-lg modal--haemacovery";
            Store.modal.setLayoutComponent(textComp);
            Store.modal.showModal();
            return;
          }
        }

        this.showContent = true;
      } else {
        router.push("/404");
      }
    } else {
      router.push("/404");
    }
  }

  getRequestHeaders() {
    if (Store.isGynonco() || Store.isEvents()) {
      return [{ "Newsletter-Access": Constants.newsletterToken }];
    }

    return null;
  }
}
