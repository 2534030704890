import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class TrainingRepository extends AbstractRepository {
  async getCompleted(userData: object) {
    const response = await Client.getJson(
      Constants.trainingsUrl,
      userData,
      this.getAdditionalHeader()
    );
    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }
}

export default new TrainingRepository();
