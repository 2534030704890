
import QRCode from "qrcode";
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class QrCode extends BaseComponent {
  readonly THEME_LIGHT = "light";
  readonly THEME_DARK = "dark";

  public generatedQRCode: string = "";

  getComponentType() {
    return "qr_codes";
  }

  async mounted() {
    await super.mounted();
    await this.generateQrCode();
  }

  async generateQrCode() {
    this.generatedQRCode = await QRCode.toDataURL(this.qrContent, {
      scale: 5,
      margin: 0,
      color: {
        dark: "#000",
        light: "#fff"
      }
    });
  }

  // contentDidLoad() {
  //   super.contentDidLoad();
  // }

  get generatedQRCodeDescription() {
    return this.qrDescription || this.qrContent;
  }

  get qrContent() {
    return this.getContentAttribute("qr_content");
  }

  get qrDescription() {
    return this.getContentAttribute("qr_description");
  }

  get theme() {
    return this.getSettingsAttribute("theme");
  }

  get cssClasses() {
    let classes = [this.layoutComponent.class];

    // if (this.theme == this.THEME_LIGHT) {
    //   classes.push("white");
    // }

    // classes.push();

    return classes.join(" ");
  }
}
