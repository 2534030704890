
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() title!: string;
  @Prop({ default: "modal-md" }) className!: string;
  @Prop({ default: true }) showCloseButton!: boolean;
  @Prop({ default: false }) preventHideByAction!: boolean;
  @Prop() onModalClose!: Function | null;

  created() {
    document.removeEventListener("keyup", this.escapeHandler);
    document.addEventListener("keyup", this.escapeHandler);
  }

  beforeDestroy() {
    let modalBackdrop = document.getElementsByClassName("modal-backdrop");
    if (modalBackdrop && modalBackdrop.length > 0) {
      modalBackdrop[0].remove();
    }

    document.body.classList.remove("modal-open");
    this.checkIfHidden();
  }

  mounted() {
    this.showIfRequested();
  }

  @Watch("$route")
  destroyMe() {
    document.removeEventListener("keyup", this.escapeHandler);
    this.$emit("hide");
  }

  @Watch("visible")
  showIfRequested() {
    if (this.visible) {
      let modalCaller = document.getElementById("modal-caller");
      if (modalCaller != null) {
        modalCaller.click();
      }
    }
  }

  checkIfHidden() {
    let el = this.$refs.modal as HTMLElement;
    if (el) {
      if (!el.classList.contains("show") && el.getAttribute("aria-modal")) {
        this.destroyMe();
      }
    }
  }

  escapeHandler(e: KeyboardEvent) {
    if (e.key == "Escape") {
      this.checkIfHidden();
    }
  }

  /**
   * Is executed only when modal close button is clicked
   * Set "preventHideByAction" to "true" if you want to prevent the user from closing the modal by clicking the backdrop or by ESC key
   */
  onCloseBtnHandler() {
    if (this.onModalClose && typeof this.onModalClose === "function") {
      this.onModalClose();
    }
  }
}
