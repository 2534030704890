import Client from "../../util/Client";
import config, { FrontendContext } from "../../../config";
import Constants, { UserEndpoint } from "../../../models/Constants";
import User from "../../../models/User";
import AbstractRepository from "../AbstractRepository";
import { changeContextByRoute } from "../../../util/Helper";

interface UserRepositoryInterface {
  login(username: string, password: string): any;
  loginWithToken(token: any): any;
  loginWithOTPToken(otp: any, token: any): any;
  loginBatch(number: string, product: string): any;
  requestOTP(magicLink: any): any;
  refreshLogin(): any;
  changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordRepeat: string
  ): any;
  newPassword(
    newPassword: string,
    newPasswordRepeat: string,
    token?: string
  ): any;
  resetPassword(email: string, urlTemplate: string): any;
  changePasswordByResetToken(
    resetToken: any,
    newPassword: string,
    newPasswordRepeat: string
  ): any;
  checkResetToken(resetToken: any): any;
  getIdentity(): any;
  moveRequest(target: string): any;
  moveFullfill(moveToken: any, target: string): any;
  activateUser(token: string): any;
  register(
    data: any,
    additionalHeaders?: any,
    failedMessageProcessing?: boolean
  ): any;
  delete(): any;
  updateUser(user: any): any;
}

class UserRepository extends AbstractRepository {
  /* logic the same for all usertypes */
  async login(username: string, password: string) {
    let data: any = {
      email: username,
      password: password
    };
    let response = await Client.postWithJsonResult(
      Constants.userLoginUrl,
      data
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  /* logic the same for all usertypes */
  async refreshLogin() {
    let response = await Client.getJson(
      Constants.userRefreshUrl,
      null,
      this.getRefreshTokenBearer()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  /* logic the same for all usertypes */
  async moveRequest(target: string) {
    let data = {
      target: target
    };

    let response = await Client.postWithJsonResult(
      Constants.userMoveRequestUrl,
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  /* logic the same for all usertypes */
  async moveFullfill(moveToken: any, target: string) {
    let data = {
      move_token: moveToken,
      target: target
    };
    let response = await Client.postWithJsonResult(
      Constants.userMoveFulfillUrl,
      data
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }
}

class HCPRepository extends UserRepository implements UserRepositoryInterface {
  async loginWithToken(token: any) {
    let data: any = {
      token: token
    };
    let response = await Client.postWithJsonResult(
      Constants.userLoginTokenUrl,
      data
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async loginWithOTPToken(otp: any, token: any) {
    let data: any = {
      otp: otp,
      token: token
    };
    let response = await Client.postWithJsonResult(
      Constants.userLoginOTPUrl,
      data
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async requestOTP(magicLink: any) {
    let data: any = {
      magicLink: magicLink
    };
    let response = await Client.post(
      Constants.userOTPUrl,
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordRepeat: string
  ) {
    let data: any = {
      email: User.getProperty("email") ? User.getProperty("email") : "",
      passwordOld: oldPassword,
      passwordNew1: newPassword,
      passwordNew2: newPasswordRepeat
    };

    let response = await Client.postWithJsonResult(
      Constants.userChangePasswordUrl,
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async newPassword(
    newPassword: string,
    newPasswordRepeat: string,
    token?: string
  ) {
    let data: any = {
      token: token ? token : "",
      passwordNew1: newPassword,
      passwordNew2: newPasswordRepeat
    };

    let response = await Client.postWithJsonResult(
      Constants.userNewPasswordUrl,
      data
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async resetPassword(email: string, urlTemplate: string) {
    let data: any = {
      email: email
    };

    await Client.post(Constants.userPasswordResetUrl, data);

    //sic, we dont need the answer here for possibiliets to get valid email addresses by checking for non-errors
  }

  async changePasswordByResetToken(
    resetToken: any,
    newPassword: string,
    newPasswordRepeat: string
  ) {
    let data: any = {
      passwordResetToken: resetToken,
      passwordNew1: newPassword,
      passwordNew2: newPasswordRepeat
    };

    let response = await Client.postWithJsonResult(
      Constants.userChangePasswordByTokenUrl,
      data
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async checkResetToken(resetToken: any) {
    let data: any = {
      passwordResetToken: resetToken
    };

    let response = await Client.postWithJsonResult(
      Constants.userCheckResetTokenUrl,
      data
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async getIdentity() {
    let response = await Client.getJson(
      Constants.userIdentityUrl,
      null,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async updateUser(user: any) {
    let data = {
      hcp: Object.assign({}, user)
    };

    let response = await Client.put(
      Constants.userUrl,
      data,
      this.getAdditionalHeader()
    );

    return response;
  }

  /* endpoint not supported at the moment */
  activateUser(token: string) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  register() {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  delete() {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  loginBatch(number: string, product: string) {
    throw new Error("Method not implemented.");
  }
}

class PatientRepository extends UserRepository
  implements UserRepositoryInterface {
  async changePasswordByResetToken(
    resetToken: any,
    newPassword: string,
    newPasswordRepeat: string
  ) {
    let data: any = {
      resetToken: resetToken,
      passwordNew1: newPassword,
      passwordNew2: newPasswordRepeat
    };

    const response = await Client.post(
      Constants.userChangePasswordByTokenUrl,
      data,
      null,
      true
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async resetPassword(email: string, urlTemplate: string) {
    let data: any = {
      email: email,
      urlTemplate: urlTemplate
    };

    await Client.post(Constants.userPasswordResetUrl, data);
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
    newPasswordRepeat: string
  ) {
    let data = {
      passwordOld: oldPassword,
      passwordNew1: newPassword,
      passwordNew2: newPasswordRepeat
    };

    let url = Constants.getUserUrlFor(UserEndpoint.CHANGE_PASSWORD);
    let response = await Client.post(url, data, this.getAdditionalHeader());

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async loginBatch(number: string, product: string) {
    let url = Constants.userBatchLoginUrl;
    let data = {
      number: number,
      product: product
    };

    let response = await Client.postWithJsonResult(
      url,
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async delete() {
    let url = Constants.userUrl + "/" + User.getProperty("id");
    let response = await Client.delete(url, this.getAdditionalHeader());
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async activateUser(token: string) {
    let response = await Client.postWithJsonResult(Constants.userActivateUrl, {
      token: token
    });

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async register(
    data: any,
    additionalHeaders?: any,
    failedMessageProcessing?: boolean
  ) {
    let response = await Client.post(
      Constants.userRegisterUrl,
      data,
      additionalHeaders || null,
      failedMessageProcessing || false
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  /* endpoint not supported at the moment */
  loginWithToken(token: any) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  loginWithOTPToken(otp: any, token: any) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  requestOTP(magicLink: any) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  newPassword(newPassword: string, newPasswordRepeat: string, token?: string) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  checkResetToken(resetToken: any) {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  getIdentity() {
    throw new Error("Method not implemented.");
  }

  /* endpoint not supported at the moment */
  updateUser(user: any) {
    throw new Error("Method not implemented.");
  }
}

class UserRepositoryFactory {
  private static instance?: UserRepositoryInterface;

  public static getInstance() {
    if (!this.instance) {
      changeContextByRoute(config);
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.PATIENT:
          this.instance = new PatientRepository();
          break;
        case FrontendContext.HCP:
        default:
          this.instance = new HCPRepository();
          break;
      }
    }

    return this.instance;
  }
}

export default UserRepositoryFactory.getInstance();
