
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  ContentRepository,
  Helper,
  LayoutComponent,
  MenuItem,
  ResultType,
  Store
} from "common";

@Component
export default class BaseLayout extends Vue {
  @Prop() meta!: any;
  @Prop() data!: any;
  @Prop() dataLoaded!: boolean;

  public mainmenu!: MenuItem[] | null;
  public submenu!: MenuItem[];
  public footermenu!: MenuItem[] | null;
  ta!: any;

  constructor() {
    super();
    this.mainmenu = [];
    this.submenu = [];
    this.footermenu = [];
    this.ta = null;
  }

  created() {
    this.loadTargetAudience();

    // Set scrollbar width CSS var.
    let scrollbarWidth = this.getScrollbarWidth();
    document.body.style.setProperty("--scrollbar-width", scrollbarWidth + "px");

    // @ts-ignore
    let cookiePolicyId = process.env.VUE_APP_THEME_COOKIEREPORTS_ID;
    const hasExistingPolicy = document.querySelector(
      'script[src="//policy.cookiereports.com/' + 
        // @ts-ignore
        process.env.VUE_APP_THEME_COOKIEREPORTS_ID +
        '_panel-en-gb.js"'
    );
    if (cookiePolicyId && !hasExistingPolicy) {
      var script = document.createElement("script");
      script.src =
        "//policy.cookiereports.com/" +
        // @ts-ignore
        process.env.VUE_APP_THEME_COOKIEREPORTS_ID +
        "_panel-en-gb.js";
      script.async = true;
      document.body.insertAdjacentElement('beforeend',script);
    }

    // Initialize cookie change handler
    // "cookieChangeHandler" function needs futher investigation because it causes "Uncaught RangeError: Maximum call stack size exceeded" error and breaks some of the sites
    Helper.cookieChangeHandler();
  }

  async loadTargetAudience() {
    this.ta = null;
    let result = await ContentRepository.getTargetAudience(Store.appBrand);
    if (result.type == ResultType.SUCCESS) {
      if (result.value.length > 0) {
        this.ta = result.value[0];
      }
    }
  }

  get targetAudience() {
    if (this.ta != null) {
      if (this.ta.content) {
        return new LayoutComponent(this.ta.content[0]);
      }
    }

    return null;
  }

  getScrollbarWidth() {
    let inner = document.createElement("p");
    inner.style.width = "100%";
    inner.style.height = "200px";

    let outer = document.createElement("div");
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    let w1 = inner.offsetWidth;
    outer.style.overflow = "auto";
    let w2 = inner.offsetWidth;
    if (w1 == w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return w1 - w2;
  }
}
