
import { Component, Vue } from "vue-property-decorator";
import {
  AccessControlManager,
  Resources,
  LayoutComponent,
  matomo,
  router,
  SampleRequest,
  Store,
  User,
  UserLoginStatus,
  UserState
} from "common";
import ButtonWidget from "../widgets/ButtonWidget.vue";
import TransparencyComponent from "./account/TransparencyComponent.vue";
import AccountTab from "./account/AccountTab.vue";

export enum Tabs {
  TAB_ACCOUNT = "account",
  TAB_SAMPLES = "samples",
  TAB_TRANSPARENCY = "transparency",
  TAB_DEFAULT = TAB_ACCOUNT
}

@Component({
  components: {
    TransparencyComponent,
    ButtonWidget,
    AccountTab
  }
})
export default class Account extends Vue {
  readonly CALL_CASE_DEFAULT: string = "DEFAULT";
  readonly CALL_CASE_LOGGEDIN_NO_CONSENT: string = "LOGGEDIN_NO_CONSENT";
  readonly CALL_CASE_LOGGEDIN_WITHOUT_PW: string = "LOGGEDIN_WITHOUT_PW";
  readonly CALL_CASE_SAMPLEREQUEST: string = "SAMPLEREQUEST";

  store = Store;
  selectedTab: string = Tabs.TAB_DEFAULT;

  public login = LayoutComponent.createLogin();
  public sampleRequest = LayoutComponent.createEmpty(
    "SampleRequestComponent",
    this.prepareSampleRequest()
  );

  public tabList = [
    {
      id: Tabs.TAB_ACCOUNT,
      title: "Mein Account"
    },
    {
      id: Tabs.TAB_TRANSPARENCY,
      title: "GeO/Transparenzinitiative"
    }
  ];

  get Tabs() {
    return Tabs;
  }

  beforeMount() {
    this.store.activePost = "account";
  }

  mounted() {
    if (User.hasUserState(UserState.PASSWORDCHANGEREQ)) {
      Store.modal.title = "Password vergeben";
      Store.modal.className = "modal-md";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("NewPassword")
      );
      Store.modal.showModal();
    }

    Vue.nextTick(() => {
      let hash = this.$router.currentRoute.hash;
      let foundTab = this.tabList.find((t: any) => `#${t.id}` == hash);

      if (foundTab) {
        this.selectedTab = foundTab.id;
      }
    });
  }

  private prepareSampleRequest() {
    return {
      sampleRequest: new SampleRequest({
        productId: router.app.$route.query.productId,
        rootId: router.app.$route.query.rootId,
        pridId: router.app.$route.query.pridId,
        numberOfSamples: undefined
      })
    };
  }

  get hcpStatus() {
    return User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN)
      ? User.getProperty("hcpStatus")
      : "";
  }

  get hasAccess() {
    return AccessControlManager.userHasAccess(Resources.HCP_ACCOUNT);

    // if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
    //   return false;
    // }

    // return (
    //   User.getProperty("login_with_pw") && User.hasUserState(UserState.ACTIVE)
    // );
  }

  get loggedInWithPw() {
    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN)) {
      return User.getProperty("login_with_pw");
    }
    return true;
  }

  get callCase() {
    /* User visits account in sample request mode */
    if (
      !this.loggedInWithPw &&
      router.app.$route.query.productId &&
      router.app.$route.query.rootId &&
      router.app.$route.query.pridId
    ) {
      return this.CALL_CASE_SAMPLEREQUEST;
    }

    /* User visits account without consent given */
    // Note (Henning): This function is not needed anylonger since we always have tracking consent set to "true"
    /*if (!this.loggedInWithPw && !User.getTrackingConsent()) {
      return this.CALL_CASE_LOGGEDIN_NO_CONSENT;
    }*/

    /* User visits account just without pw login */
    if (!this.loggedInWithPw) {
      return this.CALL_CASE_LOGGEDIN_WITHOUT_PW;
    }

    /* default case */
    return this.CALL_CASE_DEFAULT;
  }

  get loginHeadline() {
    if (this.callCase == this.CALL_CASE_SAMPLEREQUEST) {
      return "Bitte anmelden, um Produktmuster anfordern zu können";
    } else if (this.callCase == this.CALL_CASE_LOGGEDIN_NO_CONSENT) {
      return "Bitte anmelden, um den Accountstatus zu ändern";
    } else {
      return "Bitte anmelden, um die Profileinstellungen zu öffnen";
    }
  }

  track(tabId: string) {
    matomo.click(
      tabId,
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "/account_tab_change"
    );
  }
}
