
import { Component, Vue } from "vue-property-decorator";
import {
  LayoutComponent,
  Repository,
  ResultType,
  User,
  UserLoginStatus
} from "common";

@Component
export default class ZipCodeAssets extends Vue {
  zip!: any;
  loading!: boolean;

  constructor() {
    super();
    this.zip = null;
    this.loading = true;
  }

  async created() {
    if (!User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      let result = await Repository.getZip(User.getProperty("zip"));
      this.loading = false;
      if (result.type == ResultType.SUCCESS) {
        this.zip = result.value;
      }
    }
  }

  get userZip() {
    if (!User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      return User.getProperty("zip");
    }

    return null;
  }

  get files() {
    return this.zip.files;
  }

  getComponent(file: any) {
    return LayoutComponent.createFromCollectionItem("download", file, {
      show_preview_image: true
    });
  }
}
