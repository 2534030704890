
import { Component } from "vue-property-decorator";
import { BaseComponent } from "vue-ui";
import { ExecutionQueue } from "common";

@Component
export default class ReadingTimeEstimation extends BaseComponent {
  readingTimeEstimation!: any;
  loading!: boolean;

  constructor() {
    super();
    this.readingTimeEstimation = "loading";
    this.loading = true;
  }

  getComponentType() {
    return "";
  }

  async mounted() {
    await super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    this.getReadingTimeEstimation();
  }

  get wpm() {
    const wpmFromSettings: any = this.getSettingsAttribute("words_per_minute");
    return wpmFromSettings ? parseInt(wpmFromSettings, 10) : 225;
  }

  get staticReadingTime() {
    return this.getSettingsAttribute("static_reading_time_estimation");
  }

  getReadingTimeEstimation() {
    if (this.staticReadingTime) {
      this.readingTimeEstimation = this.staticReadingTime;
      this.loading = false;
    }

    ExecutionQueue.addElement(() => {
      this.readingTimeEstimation = this.calculateReadingTimeEstimation();
      this.loading = false;
    });
  }

  calculateReadingTimeEstimation() {
    const articleEl: any = document.getElementsByClassName("article")[0];
    const words: any = (articleEl ? articleEl.innerText || "" : "")
      .trim()
      .split(/\s+/).length;
    return `${Math.ceil(words / this.wpm)} Min.`;
  }
}
