import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class RatingRepository extends AbstractRepository {
  async postRating(url: string, value: number) {
    let data = {
      url: url,
      value: value
    };

    let response = await Client.post(
      Constants.ratingUrl,
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new RatingRepository();
