
import { Component } from "vue-property-decorator";
import { BaseComponent, ListItem, Detail as EventDetail } from "vue-ui";
import {
  EventRepository,
  ResultType,
  User,
  UserLoginStatus,
  UserProfile
} from "common";

@Component({
  components: {
    ListItem,
    EventDetail
  }
})
export default class Events extends BaseComponent {
  public events!: any;
  private dateRange: {};

  private attrs = {
    dates: { start: null, end: null }
  };

  private categories = [
    { id: 1, title: "Themengebiet 1" },
    { id: 2, title: "Themengebiet 2" },
    { id: 3, title: "Themengebiet 3" },
    { id: 4, title: "Themengebiet 4" }
  ];

  public eventTabs = [
    {
      title: "Alle Events",
      identifier: "all",
      active: true
    },
    {
      title: "Meine Events",
      identifier: "registered",
      active: false
    }
  ];

  private activeTab = "all";
  private activeCategory = 0;
  private restrictedContentMessage!: string;
  public activeEvent!: any;

  constructor() {
    super();
    this.events = null;
    this.activeEvent = null;
    this.activeCategory = 0;

    this.dateRange = { start: new Date("04-01-2020"), end: new Date() };
    this.restrictedContentMessage = "";
  }

  created() {
    if (User.hasUserProfile(UserProfile.MMC_DOCCHECK)) {
      if (this.$route.query && this.$route.query.event_id) {
        this.restrictedContentMessage =
          "Um diesen Artikel vollständig lesen zu können, richten Sie sich bitte einen MeinMEDCAMPUS-Account ein.";
      } else {
        this.restrictedContentMessage =
          'Um Zugriff zu den Veranstaltungen auf <a href="http://mein-medcampus.de/" title="http://mein-medcampus.de/">Mein-Medcampus.de</a> zu bekommen, richten Sie sich bitte einen MeinMEDCAMPUS-Account ein.';
      }
    } else {
      if (this.$route.query && this.$route.query.event_id) {
        this.loadEvent(this.$route.query.event_id);
      } else {
        this.loadEvents(this.activeTab);
      }
    }
  }

  navigateTab(tabId: string) {
    this.activeTab = tabId;
    this.loadEvents(tabId);
  }

  async loadEvent(eventId: any) {
    let result = await EventRepository.getVeevaEvent(eventId);
    if (result.type == ResultType.SUCCESS) {
      this.activeEvent = result.value;
    } else {
      this.activeEvent = [];
    }
  }

  async loadEvents(type = "all") {
    let result = await EventRepository.getEvents(type);
    if (result.type == ResultType.SUCCESS) {
      this.events = result.value;
    } else {
      this.events = [];
    }
  }

  get has_access() {
    return !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT);
  }
}
