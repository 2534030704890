
import { Component } from "vue-property-decorator";
import { BaseComponent, LoadingAnimation } from "vue-ui";
import {
  ExecutionQueue,
  HistoryItem,
  ResultType,
  router,
  SampleRepository,
  Sample as SampleModel,
  SampleRequest as SampleRequestModel
} from "common";

export enum OrderStates {
  NO_ORDER,
  CHECK_AVAILABLE,
  BEFORE_REQUEST,
  AFTER_REQUEST
}

@Component({
  components: {
    LoadingAnimation
  }
})
export default class SampleRequest extends BaseComponent {
  //TODO default with ""
  orderState: OrderStates = OrderStates.NO_ORDER;

  internalContentLoaded: boolean = false;
  historyItems: Array<HistoryItem> = [];
  historyError: boolean = false;
  sampleRequestError: boolean = false;
  errorMessages: Array<String> = [];
  sample: SampleModel;
  anchor: string = "sampleRequest";
  sampleQuantity: number = 0;

  public constructor() {
    super();
    this.sample = new SampleModel();
  }

  get OrderStates() {
    return OrderStates;
  }

  async mounted() {
    await super.mounted();

    if (router.app.$route.hash == "#" + this.anchor) {
      ExecutionQueue.addElement(this.scrollToElement);
    }

    if (this.sampleRequest.isValid()) {
      this.orderState = OrderStates.CHECK_AVAILABLE;
      await this.getAvailable();
    } else {
      this.orderState = OrderStates.NO_ORDER;
    }

    await this.getHistory();
  }

  scrollToElement() {
    let element = this.$refs.anchor as HTMLElement;
    let offsetTop = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo(0, offsetTop);
  }

  async getAvailable() {
    let response = await SampleRepository.getAvailable(
      this.sampleRequest.productId as string
    );

    if (response.type == ResultType.SUCCESS) {
      this.sample = new SampleModel(response.value);
      this.sampleQuantity = Math.min(
        this.sample.availableSamples || 0,
        this.sample.sampleMaxOrderQuantity || 0
      );

      if (this.sampleQuantity) {
        this.sampleRequest.numberOfSamples = 1;
      }

      this.orderState = OrderStates.BEFORE_REQUEST;
    } else {
      this.sampleRequestError = true;
      this.errorHandling(response);
      this.resetOrderProcess();
    }
  }

  async requestSamples() {
    let response = await SampleRepository.putRequest(this.sampleRequest);
    if (response.type == ResultType.SUCCESS) {
      this.orderState = OrderStates.AFTER_REQUEST;
      await this.getHistory();
    } else {
      this.sampleRequestError = true;
      this.errorHandling(response);
      this.resetOrderProcess();
    }
  }

  async getHistory() {
    this.historyItems = [];

    let response = await SampleRepository.getHistory();

    if (response.type == ResultType.SUCCESS) {
      response.value.forEach((historyItem: any) => {
        this.historyItems.push(new HistoryItem(historyItem));
      });
    } else {
      this.historyError = true;
    }

    /*this.historyItems.push(
      new HistoryItem({
        samBrandName: "Brilique",
        samDropDate: 999999,
        samName: "90mg",
        samQuantityTotal: 2
      })
    );*/

    this.historyItems.sort(function(a, b) {
      if (typeof a !== "undefined" && typeof b !== "undefined") {
        if (a.samDropDate > b.samDropDate) {
          return -1;
        }
        if (a.samDropDate < b.samDropDate) {
          return 1;
        }
      }
      return 0;
    });

    this.internalContentLoaded = true;
  }
  get sampleRequest(): SampleRequestModel {
    return this.layoutComponent.settings.sampleRequest
      ? this.layoutComponent.settings.sampleRequest
      : new SampleRequestModel({
          productId: router.app.$route.query.productId,
          rootId: router.app.$route.query.rootId,
          pridId: router.app.$route.query.pridId,
          numberOfSamples: undefined
        });
  }

  resetOrderProcess() {
    this.orderState = OrderStates.NO_ORDER;
  }

  goHome() {
    this.resetOrderProcess();
    router.push("/");
  }

  errorHandling(response: any) {
    try {
      const responseItems = response.raw
        ? JSON.parse(response.raw).description
        : [];

      if (responseItems.length) {
        responseItems.forEach((item: any) => {
          if (item.error == "SAMPLE_REQUEST_ALREADY_ORDERED") {
            this.errorMessages.push(
              "Sie können mit dieser E-Mail kein weiteres Muster anfordern. Bitte kontaktieren Sie unseren Außendienstmitarbeiter."
            );
          }
        });
      } else {
        this.errorMessages = ["Fehler bei der Bestellung"];
      }
    } catch (error) {
      this.errorMessages = ["Fehler bei der Bestellung"];
    }
  }
}
