
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import validator from "validator";
import {
  ContentRepository,
  FeedRepository,
  LayoutComponent,
  ResultType
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum AlertType {
  SUCCESS = "success",
  ERROR = "error"
}

@Component
export default class LcFeed extends BaseComponent {
  public approvedEntriesData!: Array<LayoutComponent>;
  public attemptSubmit: boolean = false;
  public loading: boolean = false;
  public AlertType = AlertType;
  public alertType: string = "";
  public showAlert: boolean = false;
  public alertMessage: string = "";
  // Form Model
  public comment: string = "";
  public author: string = "";

  constructor() {
    super();
    this.approvedEntriesData = [];
  }

  getComponentType() {
    return "feeds";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.approvedEntries();
    });
  }

  // Display only approved entities
  async approvedEntries() {
    const feedEntries: Array<any> = this.getContentAttribute("feed_entries");
    if (feedEntries && feedEntries.length > 0) {
      let entriesResponse = await ContentRepository.getCollectionItemByTypeAndId(
        feedEntries[0].settings.content.link,
        feedEntries.map((entry: any) => entry.settings.content._id),
        0
      );
      if (entriesResponse.type == ResultType.SUCCESS) {
        if (entriesResponse.value && entriesResponse.value.length > 0) {
          // Match feedEntries with approvedFeedEntries
          const approvedIds = [...entriesResponse.value].map(
            (entry: any) => entry._id
          );
          const approvedFeeds = feedEntries.filter((feedEntry: any) =>
            approvedIds.includes(feedEntry.settings.content._id)
          );
          this.approvedEntriesData.push(
            ...approvedFeeds.map(
              (approvedFeed: any) => new LayoutComponent(approvedFeed)
            )
          );
        }
      }
    }
    return [];
  }

  /************************************/
  // Map Cockpit props

  get headline() {
    let header = this.getContentAttribute("header");
    if (header && header.length > 0) {
      return new LayoutComponent(header[0]);
    }
    return null;
  }

  get text() {
    let header = this.getContentAttribute("header");
    if (header && header.length > 0) {
      return new LayoutComponent(header[1]);
    }
    return null;
  }

  get saveSuccessMessage() {
    return this.getContentAttribute("save_success_message");
  }

  /************************************/
  // Business logic

  get validateAuthor() {
    return !validator.isEmpty(this.author);
  }

  get validateComment() {
    return !validator.isEmpty(this.comment);
  }

  async validate(e: any) {
    this.attemptSubmit = true;

    e.preventDefault();
    e.stopPropagation();

    if (this.validateAuthor && this.validateComment) {
      // Check if the content exists
      if (this.content.length > 0) {
        this.loading = true;

        // Save FeedEntry
        const feedEntryResponse: any = await FeedRepository.saveFeedEntry(
          `feed.${moment().format("YYYY-MM-DD-hh-mm-ss-SS")}`,
          this.comment,
          this.author
        );

        if (feedEntryResponse.type == ResultType.SUCCESS) {
          // Get feed metadata
          const feedResponse: any = await FeedRepository.getFeed(
            this.content[0].id
          );

          if (feedResponse.type == ResultType.SUCCESS) {
            // Link feedEntry to feed
            const linkResponse = await FeedRepository.linkToFeed(
              feedResponse.value,
              feedEntryResponse.value
            );

            // Show notification
            this.alertMessage = this.saveSuccessMessage;
            this.showAlert = true;
            this.alertType = AlertType.SUCCESS;
          }

          this.loading = false;
        }

        // Reset model
        this.attemptSubmit = false;
        this.author = "";
        this.comment = "";
      }
    }
  }

  handleCloseAlert() {
    this.showAlert = false;
  }

  /************************************/
}
