
import { Component, Vue, Watch } from "vue-property-decorator";
import { ArticleListSaved, LayoutComponent, router } from "common";
import BaseWidget from "./BaseWidget.vue";

enum PageWidgetType {
  horizontal = "horizontal",
  vertical = "vertical"
}

@Component
export default class PageWidget extends BaseWidget {
  private PageWidgetType = PageWidgetType;

  private slug: any = null;

  private loadedPageImage: boolean = false;
  private loadedPageHeadline: boolean = false;
  private loadedPageText: boolean = false;

  getComponentType() {
    return "view_widgets";
  }

  @Watch("loadedPageImage")
  @Watch("loadedPageHeadline")
  @Watch("loadedPageText")
  checkIfEverythingHasLoaded() {
    if (
      this.loadedPageImage &&
      this.loadedPageHeadline &&
      this.loadedPageText
    ) {
      this.$emit("contentDidLoad");
    }
  }

  imageLoaded() {
    this.loadedPageImage = true;
  }

  headlineLoaded() {
    this.loadedPageHeadline = true;
  }

  textLoaded() {
    this.loadedPageText = true;
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(async () => {
      let cockpitLink = this.getContentAttribute("cockpit_link");
      if (cockpitLink) {
        let slugResponse = await this.loadContent(
          cockpitLink.link,
          [cockpitLink._id],
          0,
          {
            slug: 1,
            _id: 0
          }
        );

        if (slugResponse) {
          this.slug = slugResponse[0].slug;
        }
      }
    });
  }

  get article_id() {
    let cockpitLink = this.getContentAttribute("cockpit_link");
    if (cockpitLink) {
      return cockpitLink._id;
    }
    return null;
  }

  get headline() {
    let headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    this.loadedPageHeadline = true;
    return null;
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    this.loadedPageImage = true;
    return null;
  }

  get text() {
    let text = this.getContentAttribute("text");
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }
    this.loadedPageText = true;
    return null;
  }

  get label() {
    let label = this.getContentAttribute("label");
    if (label && label.length > 0) {
      return label;
    }
    return "Bereich aufrufen";
  }

  get type() {
    let type = this.getSettingsAttribute("type");
    return type ? type : PageWidgetType.horizontal;
  }

  get link() {
    return "/" + this.slug;
  }

  gotoView() {
    if (this.link) {
      router.push(this.link);
    }
  }

  saveArticle() {
    ArticleListSaved.saveArticle(this.article_id);
  }
}
