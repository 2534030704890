
import { Component, Vue, Prop } from "vue-property-decorator";
import { InputConfig } from "../ContactForm.vue";

export interface InformationTypeValue {
  forxiga: boolean;
  xigduo: boolean;
  lokelma: boolean;
  brilique: boolean;
}

@Component
export default class InformationTypeCheckbox extends Vue {
  @Prop() formConfig!: InputConfig<InformationTypeValue>;
  @Prop() label!: string;
  @Prop() isValid!: boolean;
  @Prop() isSubmitted!: boolean;

  constructor() {
    super();
  }
}
