import UserRepository from "../api/repositories/user/UserRepository";
import { ResultType } from "../api/util/Result";
import config, { FrontendContext } from "../config";
import router from "../router";
import { CryptoHelper } from "../util/CryptoHelper";
import LoginHandler from "../util/LoginHandler";
import User, { UserRoleName } from "./User";
import { Route } from "vue-router/types/router";

export interface TokenHandlerInterface {
  getTokens(route?: Route): LoginTokenInterface[];
}

class TokenHandler {
  private static instance?: TokenHandlerInterface;

  public static getInstance() {
    if (!this.instance) {
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.PATIENT:
          this.instance = new PatientTokenHandler();
          break;
        case FrontendContext.HCP:
        default:
          this.instance = new HCPTokenHandler();
          break;
      }
    }
    return this.instance;
  }

  public static routeQueryHasIndex(queryIndex: string, route?: Route): boolean {
    if (route) {
      return route.query && Object.keys(route.query).indexOf(queryIndex) != -1;
    }
    return (
      router.app.$route &&
      router.app.$route.query &&
      Object.keys(router.app.$route.query).indexOf(queryIndex) != -1
    );
  }

  public static getRouteQueryValueAtIndex(
    queryIndex: string,
    route?: Route
  ): any {
    if (route && route.query) {
      return route.query[queryIndex];
    }

    return router.app.$route.query[queryIndex];
  }
}

export class PatientTokenHandler extends TokenHandler
  implements TokenHandlerInterface {
  getTokens(route?: Route): LoginTokenInterface[] {
    let tokens: LoginTokenInterface[] = [];

    if (TokenHandler.routeQueryHasIndex("activate_account", route)) {
      tokens.push(
        new ActivationToken(
          TokenHandler.getRouteQueryValueAtIndex("activate_account", route)
        )
      );
    }

    if (TokenHandler.routeQueryHasIndex("wauth", route)) {
      tokens.push(
        new MoveToken(TokenHandler.getRouteQueryValueAtIndex("wauth", route))
      );
    }

    return tokens;
  }
}

export class HCPTokenHandler extends TokenHandler
  implements TokenHandlerInterface {
  getTokens(route?: Route): LoginTokenInterface[] {
    let tokens: LoginTokenInterface[] = [];

    if (TokenHandler.routeQueryHasIndex("otp", route)) {
      tokens.push(
        new OTPToken(TokenHandler.getRouteQueryValueAtIndex("otp", route))
      );
    }

    if (TokenHandler.routeQueryHasIndex("dccr", route)) {
      tokens.push(
        new DocCheckToken(TokenHandler.getRouteQueryValueAtIndex("dccr", route))
      );
    }

    if (TokenHandler.routeQueryHasIndex("token", route)) {
      tokens.push(
        new DeepLoginToken(
          TokenHandler.getRouteQueryValueAtIndex("token", route)
        )
      );
    }

    if (TokenHandler.routeQueryHasIndex("azid", route)) {
      tokens.push(
        new DeepLoginToken(
          TokenHandler.getRouteQueryValueAtIndex("azid", route)
        )
      );
    }

    if (TokenHandler.routeQueryHasIndex("wauth", route)) {
      tokens.push(
        new MoveToken(TokenHandler.getRouteQueryValueAtIndex("wauth", route))
      );
    }

    return tokens;
  }
}

export interface LoginTokenInterface {
  login(route?: Route): any;
  getType(): TokenType;
  getValue(): string | null;
  isValid(): boolean;
}

export enum TokenType {
  DeepLoginToken = "DeepLoginToken",
  MoveToken = "MoveToken",
  OTPToken = "OTPToken",
  DocCheckToken = "DocCheckToken",
  ActivationToken = "ActivationToken",
  InvalidToken = "InvalidToken"
}

class DeepLoginToken implements LoginTokenInterface {
  private value?: string;

  constructor(value: string) {
    this.value = value;
  }

  getType(): TokenType {
    return TokenType.DeepLoginToken;
  }

  getValue(): string | null {
    return this.value ? this.value : null;
  }

  isValid(): boolean {
    return this.getValue() !== null;
  }

  async login() {
    let result = await UserRepository.loginWithToken(this.getValue());
    if (result.type == ResultType.SUCCESS) {
      let userLoginResult = await LoginHandler.login(result.value, async () => {
        // User.setDeepLoginToken(this.getValue());
        User.updateAndSaveToStorage({
          deepLoginToken: this.getValue()
        });
      });

      return userLoginResult;
    }

    return false;
  }
}

class MoveToken implements LoginTokenInterface {
  private value?: string;

  constructor(value: string) {
    this.value = value;
  }

  getType(): TokenType {
    return TokenType.MoveToken;
  }

  getValue(): string | null {
    return this.value ? this.value : null;
  }

  isValid(): boolean {
    return this.getValue() !== null;
  }

  async login() {
    let result = await UserRepository.moveFullfill(
      this.getValue(),
      window.location.origin
    );

    if (result.type == ResultType.SUCCESS) {
      let userLoginResult = await LoginHandler.login(result.value, async () => {
        if (User.hasUserRole(UserRoleName.GUEST)) {
          // User.setMayWatchOne("i solemnly swear that i am up to no good.");
          User.updateAndSaveToStorage({
            mayWatchOne: "i solemnly swear that i am up to no good."
          });
        }
      });

      return userLoginResult;
    }

    return false;
  }
}

class OTPToken implements LoginTokenInterface {
  private value?: string;

  constructor(value: string) {
    this.value = value;
  }

  getType(): TokenType {
    return TokenType.OTPToken;
  }

  getValue(): string | null {
    return this.value ? this.value : null;
  }

  isValid(): boolean {
    return this.getValue() !== null;
  }

  async login(route?: Route) {
    try {
      let otpValue = this.getValue();
      if (otpValue == null) {
        return false;
      }

      let otp = decodeURI(otpValue as string);
      let deepLoginToken = TokenHandler.getRouteQueryValueAtIndex(
        "token",
        route
      );

      let result = await UserRepository.loginWithOTPToken(otp, deepLoginToken);
      if (result.type == ResultType.SUCCESS) {
        let userLoginResult = await LoginHandler.login(
          result.value,
          async () => {
            //
          }
        );

        return userLoginResult;
      }

      return false;
    } catch (e) {
      return false;
    }
  }
}

class DocCheckToken implements LoginTokenInterface {
  private value?: string;

  constructor(value: string) {
    this.value = value;
  }

  getType(): TokenType {
    return TokenType.DocCheckToken;
  }

  getValue(): string | null {
    return this.value ? this.value : null;
  }

  isValid(): boolean {
    return this.getValue() !== null;
  }

  async login(route?: Route) {
    // all necessary DocCheck related parameters
    let sessionId = TokenHandler.getRouteQueryValueAtIndex("session_id", route);
    let uniqueKey = TokenHandler.getRouteQueryValueAtIndex("uniquekey", route);
    let dccr = TokenHandler.getRouteQueryValueAtIndex("dccr", route);
    let token = TokenHandler.getRouteQueryValueAtIndex("token", route);
    let loginId = TokenHandler.getRouteQueryValueAtIndex("login_id", route);

    let docCheckLogin = false;

    // do DocCheck login
    if (sessionId && uniqueKey && dccr && loginId) {
      let checkSum = CryptoHelper.decrypt(sessionId, dccr);
      let userCheckSum = User.getProperty("docCheckCheckSum");
      if (checkSum && userCheckSum && checkSum === userCheckSum) {
        User.updateAndSaveToStorage({
          docCheckSessionId: sessionId,
          docCheckUserId: uniqueKey,
          docCheckCheckSum: undefined
        });
        // User.setDocCheckSessionId(sessionId);
        // User.setDocCheckUserId(uniqueKey);
        // User.deleteDocCheckCheckSum();
        docCheckLogin = true;
      }
    }

    // do token login
    if (token) {
      let dlt = new DeepLoginToken(token);
      let dltResult = await dlt.login();
      return dltResult && docCheckLogin;
    }

    return docCheckLogin;
  }
}

class ActivationToken implements LoginTokenInterface {
  private value?: string;

  constructor(value: string) {
    this.value = value;
  }

  async login() {
    if (this.isValid()) {
      let result = await UserRepository.activateUser(this.getValue() as string);
      if (result.type == ResultType.SUCCESS) {
        let userLoginResult = await LoginHandler.login(result.value);
        return userLoginResult;
      }
    }

    return false;
  }

  getType(): TokenType {
    return TokenType.ActivationToken;
  }

  getValue(): string | null {
    return this.value ? this.value : null;
  }

  isValid(): boolean {
    return this.getValue() !== null;
  }
}

class InvalidToken implements LoginTokenInterface {
  login() {
    //
  }

  getType(): TokenType {
    return TokenType.InvalidToken;
  }

  getValue(): string | null {
    return null;
  }

  isValid(): boolean {
    return false;
  }
}

export default TokenHandler.getInstance();
