
import { Component } from "vue-property-decorator";
import { ResultType, User, UserRepository } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class TrackingOptIn extends BaseComponent {
  async approveTracking() {
    User.setTrackingConsent(true);
    await this.updateUser();
  }

  async declineTracking() {
    User.setTrackingConsent(false);
    await this.updateUser();
  }

  async updateUser() {
    let trackingConsentResponse = await UserRepository.updateUser(
      User.asApiUser()
    );
    if (trackingConsentResponse.type == ResultType.SUCCESS) {
      if (this.additionalUrl) {
        window.open(this.additionalUrl, "_blank");
      }
      window.location.href = this.redirectUrl;
    }
  }

  get redirectUrl() {
    let redirectUrl = this.getSettingsAttribute("redirectUrl");
    return redirectUrl ? redirectUrl : "/";
  }

  get additionalUrl() {
    return this.getSettingsAttribute("additionalUrl");
  }
}
