
import { Component } from "vue-property-decorator";
import lottie from "lottie-web";
import { EventBus, ANIMATION_PLAY_TRIGGERED, RoutingHelper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum EffectType {
  Hover = "hover",
  Click = "click",
  Scroll = "scroll"
}

type Effect = {
  type: EffectType;
  selector: string;
  startFrame: number;
  endFrame: number;
  repeat: boolean;
  audioId: string | null;
};

@Component
export default class LottieAnimation extends BaseComponent {
  private animation: any;
  private blockedSelectors: any[];
  private lottieElement!: HTMLElement | null;
  private userIsInteracting: boolean;

  constructor() {
    super();

    this.animation = null;
    this.blockedSelectors = [];
    this.userIsInteracting = false;
  }

  async mounted(): Promise<void> {
    super.mounted();
  }

  get jsonContent(): { path: string } {
    return this.getSettingsAttribute("data");
  }

  get jsonFilePath(): string {
    return RoutingHelper.getFilePath(this.jsonContent.path, true);
  }

  get effects(): Effect[] {
    return this.getSettingsAttribute("effects");
  }

  get autoplay() {
    return this.getSettingsAttribute("autoplay");
  }

  getLottieElement(selector: string): Element | null {
    if (!this.lottieElement) {
      return null;
    }

    return this.lottieElement.querySelector(selector);
  }

  contentDidLoad(): void {
    super.contentDidLoad();

    this.lottieElement = this.$refs.lottie as HTMLElement;

    if (!this.lottieElement) {
      return;
    }

    const hasScrollEffect = this.effects.some(
      effect => effect.type === EffectType.Scroll
    );

    this.animation = lottie.loadAnimation({
      container: this.lottieElement,
      renderer: "svg",
      loop: hasScrollEffect,
      autoplay: this.autoplay,
      path: this.jsonFilePath
    });

    setTimeout(() => {
      this.setupEffects();

      if (hasScrollEffect) {
        window.addEventListener("scroll", () => {
          if (this.userIsInteracting) {
            this.animation.play();
          }
        });

        setInterval(() => {
          this.animation.pause();
        }, 350);
      }
    }, 900);
  }

  setupScrollEffect(effect: Effect): void {
    if (!this.lottieElement) {
      return;
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.userIsInteracting = entry.isIntersecting;
      });
    });

    observer.observe(this.lottieElement);
  }

  setupEffects(): void {
    this.effects.forEach(effect => {
      if (effect.type === EffectType.Scroll) {
        this.setupScrollEffect(effect);
      }

      const element = this.getLottieElement(effect.selector);

      if (!element) {
        return;
      }

      const events = [];

      if (effect.type === EffectType.Hover) {
        events.push("mouseenter");
      }

      if (effect.type === EffectType.Click) {
        events.push("click");
        // @ts-ignore
        element.style.cursor = "pointer";
      }

      events.forEach(event => {
        element.addEventListener(event, () => {
          if (this.blockedSelectors.includes(effect.selector)) {
            return;
          }

          this.animation.playSegments(
            [effect.startFrame, effect.endFrame],
            true
          );

          if (effect.audioId) {
            EventBus.$emit(ANIMATION_PLAY_TRIGGERED, effect.audioId);
          }
        });
      });
    });
  }
}
