
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CookieIframe extends Vue {
  get cookie_policy_id() {
    //@ts-ignore
    return process.env.VUE_APP_THEME_COOKIEREPORTS_ID;
  }
}
