var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-wrapper form-wrapper--contact",class:_vm.layoutComponent.class,attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(!_vm.showAlert)?_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.validate.apply(null, arguments)}}},[_c('information-type-checkbox',{attrs:{"formConfig":_vm.formConfig.informationTypeCheckbox,"label":_vm.informationTypeCheckbox,"isSubmitted":_vm.attemptSubmit,"isValid":_vm.validateInput('informationTypeCheckbox')}}),_c('select-congress',{attrs:{"formConfig":_vm.formConfig.congress,"label":_vm.congress,"isSubmitted":_vm.attemptSubmit,"isValid":_vm.validateInput('congress')}}),(_vm.formConfig.name.visible)?_c('div',{class:['form-group', _vm.formConfig.name.cssClass]},[_c('label',{class:{ required: _vm.formConfig.name.required },attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.name.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.name.model),expression:"formConfig.name.model"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('name'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('name')
        },attrs:{"type":"text","id":"name","name":"name","placeholder":_vm.name.placeholder},domProps:{"value":(_vm.formConfig.name.model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formConfig.name, "model", $event.target.value)}}})]):_vm._e(),(_vm.formConfig.email.visible)?_c('div',{class:['form-group', _vm.formConfig.email.cssClass]},[_c('label',{class:{ required: _vm.formConfig.email.required },attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.email.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.email.model),expression:"formConfig.email.model"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('email'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('email')
        },attrs:{"type":"email","id":"email","name":"email","placeholder":_vm.email.placeholder},domProps:{"value":(_vm.formConfig.email.model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formConfig.email, "model", $event.target.value)}}})]):_vm._e(),(_vm.formConfig.phone.visible)?_c('div',{class:['form-group', _vm.formConfig.phone.cssClass]},[_c('label',{class:{ required: _vm.formConfig.phone.required },attrs:{"for":"phone"}},[_vm._v(_vm._s(_vm.phone.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.phone.model),expression:"formConfig.phone.model"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('phone'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('phone')
        },attrs:{"type":"tel","id":"phone","name":"phone","placeholder":_vm.phone.placeholder},domProps:{"value":(_vm.formConfig.phone.model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formConfig.phone, "model", $event.target.value)}}})]):_vm._e(),(_vm.formConfig.address.visible)?_c('div',{class:['form-group', _vm.formConfig.address.cssClass]},[_c('label',{class:{ required: _vm.formConfig.address.required },attrs:{"for":"address"}},[_vm._v(_vm._s(_vm.address.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.address.model),expression:"formConfig.address.model"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('address'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('address')
        },attrs:{"type":"text","id":"address","name":"address","placeholder":_vm.address.placeholder},domProps:{"value":(_vm.formConfig.address.model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formConfig.address, "model", $event.target.value)}}})]):_vm._e(),_c('select-indication',{attrs:{"formConfig":_vm.formConfig.indication,"indicationSettings":_vm.indication,"isSubmitted":_vm.attemptSubmit,"isValid":_vm.validateInput('indication')}}),(_vm.formConfig.question.visible)?_c('div',{class:['form-group', _vm.formConfig.question.cssClass]},[_c('label',{class:{ required: _vm.formConfig.question.required },attrs:{"for":"question"}},[_vm._v(_vm._s(_vm.question.label))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.question.model),expression:"formConfig.question.model"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('question'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('question')
        },attrs:{"id":"question","name":"question","placeholder":_vm.question.placeholder,"rows":"3"},domProps:{"value":(_vm.formConfig.question.model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formConfig.question, "model", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"mb-8 mandatory-explanation",domProps:{"innerHTML":_vm._s(_vm.requiredText)}}),(
          _vm.formConfig.contactByPhone.visible ||
            _vm.formConfig.contactByEmail.visible ||
            _vm.formConfig.contactByPost.visible ||
            _vm.formConfig.appointment.visible
        )?_c('p',{staticClass:"mb-6 section-title"},[_vm._v(" "+_vm._s(_vm.sectionTitle)+" ")]):_vm._e(),_c('div',{staticClass:"form-group mb-4"},[(_vm.formConfig.contactByPhone.visible)?_c('label',{staticClass:"form-check form-check--icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.contactByPhone.model),expression:"formConfig.contactByPhone.model"}],staticClass:"form-check-input",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateInput('contactByPhone'),
              'is-valid': _vm.attemptSubmit && _vm.validateInput('contactByPhone')
            },attrs:{"type":"checkbox","value":"","id":"contactByPhone","name":"contact_by_phone"},domProps:{"checked":Array.isArray(_vm.formConfig.contactByPhone.model)?_vm._i(_vm.formConfig.contactByPhone.model,"")>-1:(_vm.formConfig.contactByPhone.model)},on:{"change":function($event){var $$a=_vm.formConfig.contactByPhone.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formConfig.contactByPhone, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formConfig.contactByPhone, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formConfig.contactByPhone, "model", $$c)}}}}),_c('span'),_c('span',{staticClass:"form-check-label",attrs:{"for":"contactByPhone"}},[_c('img',{staticClass:"form-check-label__icon",attrs:{"src":_vm.settings.icons.phone,"alt":"icon"}}),_vm._v(" "+_vm._s(_vm.phoneCheck)+" ")])]):_vm._e(),(_vm.formConfig.contactByEmail.visible)?_c('label',{staticClass:"form-check form-check--icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.contactByEmail.model),expression:"formConfig.contactByEmail.model"}],staticClass:"form-check-input",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateInput('contactByEmail'),
              'is-valid': _vm.attemptSubmit && _vm.validateInput('contactByEmail')
            },attrs:{"type":"checkbox","value":"","id":"contactByEmail","name":"contact_by_email"},domProps:{"checked":Array.isArray(_vm.formConfig.contactByEmail.model)?_vm._i(_vm.formConfig.contactByEmail.model,"")>-1:(_vm.formConfig.contactByEmail.model)},on:{"change":function($event){var $$a=_vm.formConfig.contactByEmail.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formConfig.contactByEmail, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formConfig.contactByEmail, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formConfig.contactByEmail, "model", $$c)}}}}),_c('span'),_c('div',{staticClass:"form-check-label",attrs:{"for":"contactByEmail"}},[_c('img',{staticClass:"form-check-label__icon",attrs:{"src":_vm.settings.icons.mail,"alt":"icon"}}),_vm._v(" "+_vm._s(_vm.emailCheck)+" ")])]):_vm._e(),(_vm.formConfig.appointment.visible)?_c('label',{staticClass:"form-check form-check--icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.appointment.model),expression:"formConfig.appointment.model"}],staticClass:"form-check-input",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateInput('appointment'),
              'is-valid': _vm.attemptSubmit && _vm.validateInput('appointment')
            },attrs:{"type":"checkbox","value":"","id":"appointment","name":"appointment"},domProps:{"checked":Array.isArray(_vm.formConfig.appointment.model)?_vm._i(_vm.formConfig.appointment.model,"")>-1:(_vm.formConfig.appointment.model)},on:{"change":function($event){var $$a=_vm.formConfig.appointment.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formConfig.appointment, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formConfig.appointment, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formConfig.appointment, "model", $$c)}}}}),_c('span'),_c('div',{staticClass:"form-check-label",attrs:{"for":"appointment"}},[_c('img',{staticClass:"form-check-label__icon",attrs:{"src":_vm.settings.icons.calendar,"alt":"icon"}}),_vm._v(" "+_vm._s(_vm.appointmentCheck)+" ")])]):_vm._e(),(_vm.formConfig.contactByPost.visible)?_c('label',{staticClass:"form-check form-check--icon"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.contactByPost.model),expression:"formConfig.contactByPost.model"}],staticClass:"form-check-input",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateInput('contactByPost'),
              'is-valid': _vm.attemptSubmit && _vm.validateInput('contactByPost')
            },attrs:{"type":"checkbox","value":"","id":"contactByPost","name":"contact_by_post"},domProps:{"checked":Array.isArray(_vm.formConfig.contactByPost.model)?_vm._i(_vm.formConfig.contactByPost.model,"")>-1:(_vm.formConfig.contactByPost.model)},on:{"change":function($event){var $$a=_vm.formConfig.contactByPost.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formConfig.contactByPost, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formConfig.contactByPost, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formConfig.contactByPost, "model", $$c)}}}}),_c('span'),_c('div',{staticClass:"form-check-label",attrs:{"for":"contactByPost"}},[_c('img',{staticClass:"form-check-label__icon",attrs:{"src":_vm.settings.icons.post,"alt":"icon"}}),_vm._v(" "+_vm._s(_vm.postCheck)+" ")])]):_vm._e(),(_vm.formConfig.privacyConsent.visible)?_c('label',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formConfig.privacyConsent.model),expression:"formConfig.privacyConsent.model"}],staticClass:"form-check-input",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateInput('privacyConsent'),
              'is-valid': _vm.attemptSubmit && _vm.validateInput('privacyConsent')
            },attrs:{"type":"checkbox","value":"","id":"privacy","name":"privacy_consent"},domProps:{"checked":Array.isArray(_vm.formConfig.privacyConsent.model)?_vm._i(_vm.formConfig.privacyConsent.model,"")>-1:(_vm.formConfig.privacyConsent.model)},on:{"change":function($event){var $$a=_vm.formConfig.privacyConsent.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formConfig.privacyConsent, "model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formConfig.privacyConsent, "model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formConfig.privacyConsent, "model", $$c)}}}}),_c('span'),_c('span',{staticClass:"form-check-label u-text-small",attrs:{"for":"privacy"},domProps:{"innerHTML":_vm._s(_vm.privacyCheck)}})]):_vm._e()]),_c('button',{staticClass:"btn btn-img",class:_vm.settings.buttonClass,attrs:{"type":"submit"}},[(_vm.loading)?_c('span',{staticClass:"spinner-border",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.submitBtnText)+" ")])])],1):_vm._e(),(_vm.showAlert)?_c('div',{staticClass:"mb-5 contact-alert-message"},[_c('a-alert',{attrs:{"message":_vm.alertMessage,"type":_vm.alertType,"show-icon":"","closable":"","after-close":_vm.handleAlertMessageClose}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }