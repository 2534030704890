
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ButtonWebWorld extends BaseComponent {
  constructor() {
    super();
  }

  get is_external() {
    try {
      let url = new URL(this.layoutComponent.settings.button_link);
      if (url.protocol) {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  get button_icon() {
    if (this.layoutComponent.settings.button_custom_icon) {
      return this.layoutComponent.settings.button_custom_icon;
    }

    /*if (this.layoutComponent.settings.button_icon) {
      return Constants.baseUrl + this.layoutComponent.settings.button_icon.path;
    }*/
    return "";
  }

  get button_link() {
    return this.getSettingsAttribute("button_link");
  }

  get button_text() {
    return this.getSettingsAttribute("button_text");
  }
}
