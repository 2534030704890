
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { LayoutComponent } from "common";

@Component
export default class Randomizer extends BaseComponent {
  public itemToRender!: LayoutComponent | null;

  constructor() {
    super();
    this.itemToRender = null;
  }

  getComponentType() {
    return "randomizers";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.itemToRender = this.randomize(this.randomizerContent);
    });
  }

  get randomizerContent() {
    let content = this.getContentAttribute("content");
    if (content && content.length > 0) {
      return content
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return [];
  }

  randomize(arr: Array<any>) {
    if (arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    return null;
  }
}
