import { UserInterface } from "../../models/User";

export function userProperty(
  options: any = null
): (target: UserInterface, propertyKey: string) => void {
  return (target, key) => {
    if (Object.getOwnPropertyDescriptor(target, "userProperties") == null) {
      target.userProperties = [];
    }

    if (Object.getOwnPropertyDescriptor(target, "apiUserProperties") == null) {
      target.apiUserProperties = [];
    }

    target.userProperties.push(key);

    if (options != null) {
      if (options.apiUser == true) {
        target.apiUserProperties.push(key);
      }
    }

    /*
     * Since target is now specific to, append properties defined in parent.
     */
    let parentTarget = Object.getPrototypeOf(target);
    let parentUserProperties = parentTarget.userProperties;
    let parentApiUserProperties = parentTarget.apiUserProperties;

    if (parentUserProperties) {
      parentUserProperties.forEach((val: any) => {
        if (target.userProperties.find((v: any) => v == val) == null) {
          target.userProperties.push(val);
        }
      });
    }

    if (parentApiUserProperties) {
      parentApiUserProperties.forEach((val: any) => {
        if (target.apiUserProperties.find((v: any) => v == val) == null) {
          target.apiUserProperties.push(val);
        }
      });
    }
  };
}
