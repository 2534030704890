
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LoadingModal extends BaseComponent {
  get url() {
    let url = this.getSettingsAttribute("url");
    return url != undefined ? url : null;
  }

  get showUrl() {
    if (this.url == null) {
      return false;
    }

    let showUrl = this.getSettingsAttribute("show_url");
    return showUrl != undefined ? showUrl : true;
  }

  get text() {
    let text = this.getSettingsAttribute("text");
    if (!text) {
      return "Sie werden in Kürze weitergeleitet, bitte warten Sie einen Moment ...";
    }

    return text;
  }

  closeRamp() {
    (this.$refs.closeramp as HTMLElement).click();
  }
}
