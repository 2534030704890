
import { Component } from "vue-property-decorator";
import {
  ArticleRepository,
  ResultType,
  Store,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../../base/BaseComponent.vue";
import { Type } from "./DynamicViewWidget.vue";

@Component
export default class DynamicSidebarArticlesWidget extends BaseComponent {
  public Type = Type;
  public widgetsToRender!: Array<any>;
  private loading!: boolean;
  private isUserLoggedIn!: boolean;

  constructor() {
    super();
    this.widgetsToRender = [];
    this.loading = true;
    this.isUserLoggedIn = false;
  }

  async mounted() {
    this.isUserLoggedIn = User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN);
    this.setWidgetsToRender();
    this.loading = false;
    this.getDynamicContent();
  }

  setWidgetsToRender() {
    const staticContent = this.getSettingsAttribute("static_content");
    if (staticContent && staticContent.length > 0) {
      staticContent.forEach(
        (item: any) => item._id && this.widgetsToRender.push(item)
      );
    }
  }

  async getDynamicContent() {
    if (this.isUserLoggedIn) {
      const idsToExclude = this.widgetsToRender.map((item: any) => item._id);
      const result = await ArticleRepository.getDynamicArticlesBySegmentation(
        Store.activePost,
        idsToExclude
      );

      if (result && result.type === ResultType.SUCCESS) {
        this.widgetsToRender.push(
          // @ts-ignore
          ...result.value.map((id: string) => {
            return {
              display: "",
              link: "articles",
              _id: id
            };
          })
        );
      }
    }
  }
}
