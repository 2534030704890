
import { Component } from "vue-property-decorator";
import { LayoutComponent, ResultType, User, UserRepository } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class FirstVisit extends BaseComponent {
  public attemptSubmit: boolean = false;
  public sendingRequest: boolean = false;
  public consent01: boolean = false;
  public error: string =
    "Es ist ein Fehler beim Erstellen Ihres Passwortes aufgetreten. Bitte versuchen Sie es später noch einmal.";

  public apiError: boolean = false;

  async validate(e: any) {
    this.apiError = false;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateConsent01) {
      if (!this.consent01) {
        this.showModal(
          "",
          LayoutComponent.createEmpty("tracking_opt_in", {
            redirectUrl: window.location.href
          })
        );
      } else {
        User.setTrackingConsent(this.consent01);
        this.sendingRequest = true;
        let trackingConsentResponse = await UserRepository.updateUser(
          User.asApiUser()
        );
        this.sendingRequest = false;

        if (trackingConsentResponse.type == ResultType.SUCCESS) {
          window.location.reload();
        }
      }
    }
  }

  handleError() {
    this.consent01 = false;
    alert(this.error);
  }

  reset() {
    this.consent01 = false;
    this.attemptSubmit = false;
    this.sendingRequest = false;
    this.apiError = false;
  }

  get validateConsent01() {
    return true;
  }
}
