import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";
import { LayoutComponent } from "../../models/LayoutComponent";

class Repository extends AbstractRepository {
  /**
   * Filters and now sorts the view_widgets collection
   * @param ids that have to be filtered
   * @param sortBy property by which to sort
   * @returns object[]
   */
  async getArticleWidgets(ids: string[], sortBy?: string) {
    let data = {
      fields: {
        _id: 1,
        cockpit_link: 1
      },
      filter: {
        "cockpit_link._id": { $in: ids },
        filterable: { $eq: true }
      },
      simple: 1,
      populate: 0
    };

    if (sortBy) {
      // Add "sortable" property as filter
      Object.defineProperty(data.filter, "sortable", {
        value: true,
        enumerable: true
      });

      // Specify the property by which to sort and the order (ASC = 1 or DESC = -1)
      Object.defineProperty(data, "sort", {
        value: { [sortBy]: 1 },
        enumerable: true
      });
    }

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("view_widgets"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, LayoutComponent[]>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async getDominantColor(imgPath: any) {
    let data = {
      url: imgPath
    };

    let response = await Client.postWithJsonResult(
      Constants.dominantColorUrl,
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async placeOrder(data: any, headers?: any) {
    let response = await Client.post(
      Constants.placeOrderUrl,
      data,
      headers || this.getAdditionalHeader()
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async getDebug() {
    let response = await Client.getJson(Constants.debugUrl, null);
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async getZip(zip: string) {
    let data = {
      filter: {
        zip: {
          $in: [zip]
        }
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("zip"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r[0] : null;
    });

    return result;
  }

  async getEditorsPickAndTeaserSlider(segment: string[]) {
    let data = {
      filter: {
        segment: { $in: [...segment] }
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries(
        "dynamic_editors_pick_and_teaser_widget_sliders"
      ),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r : null;
    });

    return result;
  }

  async getAppBrandUrl(appBrand: string) {
    let response = await Client.getJson(Constants.appBrandUrl, {
      brand_name: appBrand
    });

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new Repository();
