var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.contentLoaded)?_c('div',{staticClass:"skeleton__lcimage"}):(_vm.visibility)?_c('div',{class:['map-box', _vm.layoutComponent.class],attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType,"data-id":_vm.internalId}},[_c('div',{staticClass:"map-box__img-wrapper"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedItemToShow.imgToShow),expression:"!selectedItemToShow.imgToShow"}],staticClass:"img-fluid map-box__image",attrs:{"src":_vm.path}}),_vm._l((_vm.multipleImages),function(imgToShow,imgIdx){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedItemToShow.imgToShow === imgToShow),expression:"selectedItemToShow.imgToShow === imgToShow"}],key:imgIdx,staticClass:"img-fluid map-box__image",attrs:{"src":imgToShow}})}),_vm._l((_vm.metaContent),function(area){return [_vm._l((area.pins),function(pin,pinIdx){return [(pin.text)?_c('a-popover',{key:pin.uniqueId,attrs:{"title":area.id,"trigger":_vm.popoverTriggerOn,"overlayClassName":_vm.popoverClassName,"placement":pin.placement || 'left',"getPopupContainer":_vm.popoverAppendTo},on:{"visibleChange":e => pin && _vm.onTooltipVisibleChange(pin, e)},model:{value:(_vm.visibleId[pin.uniqueId]),callback:function ($$v) {_vm.$set(_vm.visibleId, pin.uniqueId, $$v)},expression:"visibleId[pin.uniqueId]"}},[_c('template',{slot:"content"},[(_vm.popoverShowCloseIcon)?_c('div',{staticClass:"popover-close-mask",on:{"click":function($event){return _vm.onCloseClick(pin)}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(pin.text)}})]),_c('div',{staticClass:"map-box__pin",style:({
              width: pin.width,
              height: pin.height,
              backgroundColor: pin.color || 'transparent',
              top: pin.top,
              left: pin.left
            })},[(pin.pinDisplay)?_c('span',{staticClass:"map-box__pin-display",domProps:{"innerHTML":_vm._s(pin.pinDisplay)}}):_vm._e()])],2):_c('div',{key:pin.uniqueId,staticClass:"map-box__pin",style:({
            zIndex: _vm.getZindex(pin, pinIdx, area.pins.length),
            cursor: 'pointer',
            width: pin.width,
            height: pin.height,
            backgroundColor: pin.color || 'transparent',
            top: pin.top,
            left: pin.left
          }),on:{"click":function($event){return _vm.onPinClick(pin, pinIdx)}}})]})]}),(_vm.loading)?_c('div',{staticClass:"map-box__spinner d-flex justify-content-center"},[_vm._m(0)]):_vm._e()],2),(_vm.title)?_c('div',{staticClass:"map-box__title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('div',{staticClass:"map-box__content"},_vm._l((_vm.itemsToRender),function(item){return _c(item.component,{key:item._id,tag:"component",staticClass:"map-box__content-item",attrs:{"layoutComponent":item}})}),1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"spinner-border m-6",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }