
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { RoutingHelper } from "common";

@Component({
  components: {}
})
export default class PdfPreview extends BaseComponent {
  public page!: number;
  public pageCount!: number;
  public rotate!: number;
  public loading!: boolean;
  public filePath!: any;
  public error!: string;
  public pdfComponent!: any;

  constructor() {
    super();
    this.page = 1;
    this.pageCount = 0;
    this.rotate = this.default_rotation;
    this.loading = true;
    this.filePath = "";
    this.error = "";
    this.pdfComponent = null;
  }

  async mounted() {
    await super.mounted();

    // Lazy load pdfvuer library
    const pdfvuer = await import(/* webpackChunkName: "pdfvuer" */ "pdfvuer");

    // Set pdfComponent to dynamically created Pdfvuer component
    this.pdfComponent = pdfvuer.default;

    Vue.nextTick(() => {
      this.filePath = pdfvuer.createLoadingTask(this.getFilePath());
      this.filePath.then((pdf: any) => {
        this.pageCount = pdf.numPages;
      });
    });
  }

  getComponentType() {
    return "files";
  }

  prevPage() {
    if (this.page > 1) {
      this.page -= 1;
    }
  }

  nextPage() {
    if (this.page < this.pageCount) {
      this.page += 1;
    }
  }

  contentDidLoad() {
    super.contentDidLoad();
  }

  getFilePath() {
    let file = this.getContentAttribute("file");

    try {
      if (file.mime == "application/pdf") {
        return RoutingHelper.getFilePath(file.path, true);
      } else {
        this.error = "Die anzuzeigende Datei ist kein PDF.";
        return "";
      }
    } catch (e) {
      this.error = "PDF konnte nicht geladen werden.";
      return "";
    }
  }

  get show_navigation() {
    return this.getSettingsAttribute("show_navigation");
  }

  get show_page_numbers() {
    return this.getSettingsAttribute("show_page_numbers");
  }

  get show_print_button() {
    return this.getSettingsAttribute("show_print_button");
  }

  get default_rotation() {
    return this.getSettingsAttribute("default_rotation")
      ? parseInt(this.getSettingsAttribute("default_rotation"))
      : 0;
  }
}
