
import { Component, Watch } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseWidget from "./BaseWidget.vue";

// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class ArticleSlider extends BaseWidget {
  public allArticlesFinishedLoading: boolean = false;
  private articleCount: number = 0;
  private numberOfLoadedArticles: number = 0;
  private initialSpacePadding = 0;
  public carouselOptions: any = {
    perPage: 1,
    perPageCustom: [
      [768, 2],
      [1024, 3]
    ],
    mouseDrag: true,
    paginationEnabled: false,
    navigationEnabled: true,
    navigationNextLabel: "",
    navigationPrevLabel: "",
    spacePadding: 0,
    autoplay: false,
    loop: false,
    sliderItemClass: "col-md-6 col-lg-4"
  };

  getComponentType() {
    return "view_widgets";
  }

  constructor() {
    super();
    this.forceLoadStop = true;
    this.contentLoaded = true;
  }

  async mounted() {
    super.mounted();
    this.setCarouselOptions();
    this.articleCount = this.articles.length;

    // The `spacePadding` prop will be used only on desktop.
    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  beforeDestroy() {
    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }
  }

  @Watch("allArticlesFinishedLoading")
  focusCarousel() {
    (this.$refs.carousel as any).computeCarouselWidth();
  }

  articleContentLoaded() {
    this.numberOfLoadedArticles++;
    if (this.numberOfLoadedArticles >= this.articleCount) {
      this.allArticlesFinishedLoading = true;
    }
  }

  get articles() {
    return this.getSettingsAttribute("content");
  }

  getArticle(rawWidget: any) {
    let widget: any = {
      component: "article_widget",
      settings: {
        content: rawWidget,
        type: "vertical"
      }
    };
    return new LayoutComponent(widget);
  }

  setCarouselOptions() {
    const options = this.getSettingsAttribute("carousel_options");
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }
    // If we set the `spacePadding` prop with 0, the padding will not be removed.
    this.carouselOptions.spacePadding =
      window.innerWidth < 768 ? 1 : this.initialSpacePadding;
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }
}
