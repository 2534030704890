import { AlgoliaResponse } from "../../models/AlgoliaSearch";
import Constants from "../../models/Constants";
import Client from "../util/Client";
import { Result } from "../util/Result";
import AbstractRepository from "./AbstractRepository";

class AlgoliaSearchRepository extends AbstractRepository {
  public async search(
    brand: string,
    query: string
  ): Promise<Result<AlgoliaResponse>> {
    const response = await Client.getJson(
      `${Constants.apiUrl}/search/${brand}/${query}`,
      {},
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) =>
      AlgoliaResponse.fromJson(r)
    );
  }
}

export default new AlgoliaSearchRepository();
