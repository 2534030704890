import { Store } from "../util/Store";
import Constants from "../models/Constants";
import { LayoutComponent } from "../models/LayoutComponent";

class Rating {
  protected modalDelay: any = Constants.ratingModalDelay
    ? parseInt(Constants.ratingModalDelay)
    : null;
  protected modalTimer: any = null;
  protected sessionStorageKey: string = "rating_modal";
  protected modalWasOpen: boolean = !!sessionStorage.getItem(
    this.sessionStorageKey
  );

  constructor() {
    if (this.modalDelay && !this.modalWasOpen) {
      this.initializeModal();
    }
  }

  private initializeModal = () => {
    this.clearModalTimer();
    this.modalTimer = setTimeout(() => {
      this.showRatingModal();
      this.clearModalTimer();
    }, this.modalDelay);
  };

  private clearModalTimer = () => {
    clearTimeout(this.modalTimer);
  };

  private showRatingModal = () => {
    Store.modal.className = "modal-lg modal--rating";
    Store.modal.setLayoutComponent(LayoutComponent.createEmpty("RatingModal"));
    Store.modal.showModal();
    this.setSessionsStorage();
  };

  private setSessionsStorage = () => {
    sessionStorage.setItem(this.sessionStorageKey, "true");
  };
}

export default Rating;
