
import { Component, Watch } from "vue-property-decorator";
import {
  Breadcrumb,
  ContentRepository,
  Store,
  MenuItem as MenuItemModel
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Breadcrumbs extends BaseComponent {
  public breadcrumbs!: Breadcrumb[];

  store = Store;
  mainmenu: any;
  unwatchRoute: (() => void) | null = null;

  constructor() {
    super();
    this.breadcrumbs = [];
    this.unwatchRoute = null;
  }

  get cssClasses() {
    return this.layoutComponent.class;
  }

  async mounted() {
    // Fetch the main menu
    const appBrand = Store.appBrand;
    await ContentRepository.getMenu(appBrand + ".main").then((result: any) => {
      this.mainmenu = result.value;
      console.log("MAIN_MENU", this.mainmenu);
      console.log("this.$route.meta.id", this.$route.meta.id);
      // Initialize the breadcrumbs
      this.breadcrumbs = updateBreadcrumbs(
        this.mainmenu,
        this.$route.meta.id,
        this.breadcrumbs
      );
      console.log("BREADCRUMBS", this.breadcrumbs);

      // Watch for changes to the route
      this.unwatchRoute = this.$watch("$route", () => {
        this.breadcrumbs = updateBreadcrumbs(
          this.mainmenu,
          this.$route.meta.id,
          this.breadcrumbs
        );
      });
    });
  }

  beforeDestroy() {
    if (this.unwatchRoute !== null) {
      this.unwatchRoute();
    }
  }
}

function removeToggleElement(navItemsArr: MenuItemModel[]) {
  for (let i = 0; i < navItemsArr.length - 1; i++) {
    /* Checking if in array exists toggle elements and removing them.
    After every toggle element in menu there is a link for this element.
    Link id is the toggle element id + 1, example: toggleId:"item-3a", toggleLinkId:"item-3a1" 
    For more informtion check the cockpit menu structure */
    if (
      navItemsArr[i].id + 1 == navItemsArr[i + 1].id &&
      !navItemsArr[i].cockpit_link &&
      navItemsArr[i].external_link == "#"
    ) {
      navItemsArr.splice(i, 1);
      i--;
    }
  }
}

function updateBreadcrumbs(
  mainmenu: any,
  pageId: string,
  breadcrumbs: Breadcrumb[]
) {
  const homePage = mainmenu.find((obj: { link: string }) => obj.link === "/");

  let currentPage = searchItem(mainmenu, pageId);
  let newBreadcrumbs: any[] = [];
  let tree: Breadcrumb[] = [];

  console.log("currentPage", currentPage);

  const newArray = pushBreadcrumbsItemsInArray(currentPage);
  removeToggleElement(newArray);
  if (newArray) {
    newArray.forEach((item, index) => {
      // if (item.link != "/") {
      //   newBreadcrumbs.push(new Breadcrumb(item.name, item.link));
      // }
      let copiedItem = item;
      if (
        index !== 0 &&
        !copiedItem?.cockpit_link &&
        copiedItem?.external_link === "#"
      ) {
        copiedItem = copiedItem?.children[0];
      }

      newBreadcrumbs.push(
        new Breadcrumb(
          copiedItem.name
            .replace("</span><span>", " ")
            .replace("<span>", "")
            .replace("</span>", ""),
          copiedItem.link
        )
      );
    });
  }

  if (tree.length == 0 && currentPage._id !== homePage._id) {
    tree.push(new Breadcrumb(homePage.name, homePage.link));
    tree.forEach(breadcrumb => newBreadcrumbs.push(breadcrumb));

    if (newBreadcrumbs.length > 2) {
      newBreadcrumbs = newBreadcrumbs.reverse();
      const maxNumber = newBreadcrumbs.length - 1;
      newBreadcrumbs = [
        ...newBreadcrumbs.slice(0, -maxNumber),
        ...newBreadcrumbs.slice(-maxNumber).reverse()
      ];
    } else {
      newBreadcrumbs = newBreadcrumbs.reverse();
    }
  }
  return newBreadcrumbs;
}

/**
 * This function search for current page, according to pageId,
 * and get the tree of current page and it's children if exists;
 * @param items
 * @param pageId
 */
function searchItem(items: any, pageId: string) {
  const found = searchParentChildren(items, pageId);
  let foundItem = null;
  if (found) {
    foundItem = found;
    return foundItem;
  } else {
    return false;
  }
}

/**
 * This method returns a tree of parents and its children;
 * @param children
 * @param pageId
 * @param parent
 */
function searchParentChildren(
  children: string | any[],
  pageId: string,
  parent = []
) {
  if (!children) return false;
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (child._id === pageId) {
      return [parent, child];
    } else if (child.children) {
      const found = searchParentChildren(child.children, pageId, [
        parent,
        child
      ]);
      if (found) {
        return found;
      }
    }
  }
  return false;
}

/**
 * This method extract the items from every element and create an array of them;
 * Flatten the array (remove nested arrays);
 *
 * @param arrays
 */
function pushBreadcrumbsItemsInArray(arrays: any[][]) {
  return arrays.flat(Infinity).map(item => item);
}
