
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { config, FrontendContext, Store } from "common";

@Component
export default class ExitRamp extends BaseComponent {
  get link() {
    return this.layoutComponent ? this.layoutComponent.settings.link : null;
  }

  get buttonClass() {
    return this.layoutComponent
      ? this.layoutComponent.settings.buttonClass
      : "";
  }

  get crossIcon() {
    return require("@/assets/cross-dark.svg");
  }

  get exitRampText() {
    switch (Store.appBrand) {
      case "cancersupport360_hub":
        return this.buttonClass.includes("external-link") ? `You are now leaving <a href="https://cancersupportplus.com">CancerSupportPlus.com</a>
                </br></br>
                If you click “Continue”, you will leave <a href="https://cancersupportplus.com">CancerSupportPlus.com</a> and be taken to a site that will have information about treatments for cancer.` :
                `You are now leaving <a href="https://cancersupportplus.com">CancerSupportPlus.com</a>
                </br></br>
                CancerSupport+ is not responsible for the content you are about to access. We provide links to external websites as a convenience and for educational purposes only.`;
      default:
        return  `You are now leaving <a href="https://cancersupportplus-hcp.com">CancerSupport+ for Healthcare Professionals.</a>
                </br></br>
                CancerSupport+ for Healthcare Professionals is not responsible for the content you are about to access. We provide links to external websites as a convenience and for educational purposes only.`;
    }
  }

  closeRamp() {
    (this.$refs.closeramp as HTMLElement).click();
  }
}
