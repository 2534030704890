
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  EventBus,
  LCMODAL_CLOSED,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  Storage,
  Store
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

enum PlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  VIDEO_CUED = 5
}

@Component
export default class YoutubeVideo extends BaseComponent {
  @Prop({ default: "" }) urlData!: string;

  private player: any;
  public id!: string;
  private scriptEl!: HTMLScriptElement;
  private scriptSrc!: string;
  private checkInterval!: any;
  public aspectRatio!: number;
  private isCookieOverlayActive!: any;
  public cookieReportsURL!: string;

  constructor() {
    super();
    this.id =
      "youtube-video-" +
      Math.random()
        .toString(36)
        .substr(2, 9);
    this.scriptEl = document.createElement("script");
    this.scriptSrc = "https://www.youtube.com/iframe_api";
    this.checkInterval = null;
    this.aspectRatio = 9 / 16; // 16:9
    this.isCookieOverlayActive = true;
    this.cookieReportsURL = `https://policy.cookiereports.com/${process.env.VUE_APP_THEME_COOKIEREPORTS_ID}.html`;

    EventBus.$on(LCMODAL_CLOSED, this.stopVideoOnModalClosed);
  }

  getComponentType() {
    return "youtube_videos";
  }

  async mounted() {
    await super.mounted();

    if (this.isCookieOverlayEnabled) {
      this.setCookiesOverlay();
      // commented until the errors from "cookieChangeHandler" function will be solved
      document.addEventListener("cookiechange", this.setCookiesOverlay);
    }
  }

  async contentDidLoad() {
    super.contentDidLoad();
    this.scriptEl.src = this.scriptSrc;

    Vue.nextTick(() => {
      // Set aspect ratio based on width and height
      if (
        this.width &&
        this.height &&
        this.aspectRatio !== this.height / this.width
      ) {
        this.aspectRatio = this.height / this.width;
      }

      // load youtube api and create player
      if (!this.isCookieOverlayEnabled) {
        this.loadApiScript();
        this.createPlayer();
      }
    });
  }

  beforeDestroy() {
    clearInterval(this.checkInterval);
    document.removeEventListener("cookiechange", this.setCookiesOverlay);
    if (this.player) {
      this.player.destroy();
    }
  }

  /************************************/
  // Map Cockpit props
  get videoID() {
    return this.getContentAttribute("youtube_video_id");
  }

  get width() {
    const width = this.getContentAttribute("width");
    return width ? parseInt(width, 10) : null;
  }

  get height() {
    const height = this.getContentAttribute("height");
    return height ? parseInt(height, 10) : null;
  }

  get headlineText() {
    return this.getContentAttribute("headline_text");
  }

  get autoplay() {
    return this.getSettingsAttribute("autoplay") & 1;
  }

  get showControls() {
    return this.getSettingsAttribute("show_controls") & 1;
  }

  get allowFullscreen() {
    return this.getSettingsAttribute("allow_fullscreen") & 1;
  }

  get loopVideo() {
    return this.getSettingsAttribute("loop") & 1;
  }

  get showHeadline() {
    return this.getSettingsAttribute("show_headline");
  }

  get placeholderImage() {
    let image = this.getContentAttribute("placeholder_image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get isCookieOverlayEnabled() {
    const cookieConsent = this.getSettingsAttribute("cookie_consent");

    // Replace true with the cookie result
    return cookieConsent && this.isCookieOverlayActive && true;
  }

  get cookieOverlayContentHeader() {
    let cookieOverlayHeader = this.getContentAttribute(
      "cookie_overlay_content"
    );
    if (cookieOverlayHeader && cookieOverlayHeader.length > 0) {
      return cookieOverlayHeader
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get cookieOverlayContentFooter() {
    let cookieOverlayFooter = this.getContentAttribute("cookie_overlay_footer");
    if (cookieOverlayFooter && cookieOverlayFooter.length > 0) {
      return cookieOverlayFooter
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get enableBookmarker() {
    return this.getContentAttribute("enable_bookmarker");
  }

  get bookmarkerLabel() {
    return this.getContentAttribute("bookmarker_label");
  }

  get collectionId() {
    return this.getContentAttribute("_id");
  }

  get bookmarker() {
    if (this.enableBookmarker) {
      const comp = LayoutComponent.createEmpty("Bookmarker", {
        label: this.bookmarkerLabel,
        collection: {
          link: "youtube_videos",
          _id: this.collectionId
        },
        collection_title: "Video"
      });

      return comp;
    }

    return null;
  }

  get compID() {
    return this.collectionId ? `yt-${this.collectionId}` : undefined;
  }

  get urlParamsData() {
    return this.urlData || `#${this.compID}`;
  }

  /************************************/
  // Business Logic
  loadApiScript() {
    if (this.scriptEl.src) {
      const scripts: any = document.getElementsByTagName("script");
      const scriptExists = Array.from(scripts).filter(
        (item: any) => item.src === this.scriptEl.src
      );

      if (!scriptExists.length) {
        scripts[0].parentNode.insertBefore(this.scriptEl, scripts[0]);
      }

      (window as any).onYouTubeIframeAPIReady = () => {
        (window as any).youtubeApiReady = true;
        if (Store.matomoEnabled()) {
          // Enable Youtube video tracking
          const paq = (window as any)._paq || [];
          paq.push(["setConsentGiven"]);
          paq.push(["MediaAnalytics::scanForMedia"]);
        }
      };

      (window as any).onPlayerStateChange = (state: any) => {
        this.matomoTrackingHandler(state);
      };
    }
  }

  createPlayer() {
    clearInterval(this.checkInterval);
    this.checkInterval = setInterval(() => {
      if ((window as any).youtubeApiReady) {
        // Create player
        // @ts-ignore: Cannot find name "YT"
        this.player = new YT.Player(this.id, {
          width: "100%",
          videoId: this.videoID,
          host: "https://www.youtube-nocookie.com",
          events: {
            onReady: (window as any).onPlayerReady,
            onStateChange: (window as any).onPlayerStateChange
          },
          functions: {
            getPlayerState: (window as any).getPlayerState,
            getCurrentTime: (window as any).getCurrentTime
          },
          playerVars: {
            enablejsapi: 1,
            origin: window.location.href,
            autoplay: this.autoplay,
            controls: this.showControls,
            fs: this.allowFullscreen,
            loop: this.loopVideo,
            playlist: this.loopVideo ? this.videoID : null
          }
        });
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    }, 500);
  }

  setCookiesOverlay() {
    const wscrCookies = Storage.get("wscrCookieConsent", false, true);
    let isCookieConsentGiven = false;
    if (wscrCookies) {
      // check if level 3 cookies are set to "true"
      isCookieConsentGiven = wscrCookies.split("&")[2].endsWith("true");
    } else {
      isCookieConsentGiven = false;
    }

    if (isCookieConsentGiven) {
      // remove all videos overlay
      // init all videos
      // be sure that on the refresh page the videos are ready (without overlay) also initialize isCookieOverlayActive = false
      this.isCookieOverlayActive = false;
      this.loadApiScript();
      this.createPlayer();
    } else {
      this.isCookieOverlayActive = true;
    }
  }

  matomoTrackingHandler(playerState: any) {
    const { data } = playerState;

    // Video playing
    if (playerState && data === PlayerState.PLAYING) {
      matomo.youtubeVideoEvents(
        this.collectionId,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.PLAY_YOUTUBE_VIDEO
      );
    }

    // Video paused
    if (playerState && data === PlayerState.PAUSED) {
      const playTime = new Date(1000 * this.player.getCurrentTime())
        .toISOString()
        .substr(11, 8);
      matomo.youtubeVideoEvents(
        this.collectionId,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.STOP_YOUTUBE_VIDEO,
        playTime
      );
    }

    // Video ended
    if (playerState && data === PlayerState.ENDED) {
      const playTime = new Date(1000 * this.player.getCurrentTime())
        .toISOString()
        .substr(11, 8);
      matomo.youtubeVideoEvents(
        this.collectionId,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.COMPLETE_YOUTUBE_VIDEO,
        playTime
      );
    }
  }

  stopVideoOnModalClosed(payload: string[]) {
    if (payload.includes(this.layoutComponent._id)) {
      this.player.pauseVideo();
    }
  }
}
