import Constants from "../../models/Constants";
import AbstractRepository from "./AbstractRepository";
import fetchJsonp from "fetch-jsonp";

class SuggestionsRepository extends AbstractRepository {
  public async search(query: string): Promise<string[]> {
    const params = {
      client: "firefox",
      q: query,
      hl: "de"
    };

    const queryParams = new URLSearchParams(params).toString();
    const url = `${Constants.suggestionsUrl}?${queryParams}`;

    return await fetchJsonp(url)
      .then(async (response: any) => {
        const json = await response.json();

        if (!json[1]) {
          return [] as string[];
        }

        return json[1] as string[];
      })
      .catch((error: any) => [] as string[]);
  }
}

export default new SuggestionsRepository();
