
import { ResultType, router, UserRepository } from "common";
import validator from "validator";
import { Component, Vue } from "vue-property-decorator";

enum ErrorType {
  TOO_SHORT = "TOO_SHORT",
  INSUFFICIENT_UPPERCASE = "INSUFFICIENT_UPPERCASE",
  INSUFFICIENT_SPECIAL = "INSUFFICIENT_SPECIAL",
  INSUFFICIENT_DIGIT = "INSUFFICIENT_DIGIT",
  INSUFFICIENT_LOWERCASE = "INSUFFICIENT_LOWERCASE"
}

@Component
export default class PatientPasswordReset extends Vue {
  public password!: string;
  public passwordRepeat!: string;
  public passUpdateSucceeded: boolean = false;
  public attemptSubmit: boolean = false;
  public loading: boolean = false;
  public showError: boolean = false;
  public errorMessages: Array<string> = [];

  // Error messages
  public static errorMessageApi =
    "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";
  public static errorMessage =
    "Ihr Passwort muss mindestens acht Zeichen, eine Ziffer, einen Großbuchstaben und ein Sonderzeichen enthalten.";
  public static errorPWTooShort = "Ihr Passwort ist zu kurz.";
  public static errorPWInsUppercase =
    "Ihr Passwort enthält nicht genügend Großbuchstaben.";
  public static errorPWInsSpecial =
    "Ihr Passwort enthält nicht genügend Sonderzeichen.";
  public static errorPWInsLowercase =
    "Ihr Passwort enthält nicht genügend Kleinbuchstaben.";
  public static errorPWInsDigits = "Ihr Passwort hat zu wenige Ziffern.";
  public static successMessage = "Passwort erfolgreich aktualisiert.";
  public successMessage = PatientPasswordReset.successMessage;

  constructor() {
    super();
    this.password = "";
    this.passwordRepeat = "";
  }

  get validatePassword() {
    return (
      !validator.isEmpty(this.password) &&
      validator.equals(this.password, this.passwordRepeat)
    );
  }

  get validatePasswordRepeat() {
    return (
      !validator.isEmpty(this.passwordRepeat) &&
      validator.equals(this.password, this.passwordRepeat)
    );
  }

  get resetToken() {
    return router.currentRoute.query.resetToken;
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  getErrorMessage(errorType: any) {
    switch (errorType.details) {
      case ErrorType.TOO_SHORT:
        return PatientPasswordReset.errorPWTooShort;
      case ErrorType.INSUFFICIENT_UPPERCASE:
        return PatientPasswordReset.errorPWInsUppercase;
      case ErrorType.INSUFFICIENT_SPECIAL:
        return PatientPasswordReset.errorPWInsSpecial;
      case ErrorType.INSUFFICIENT_DIGIT:
        return PatientPasswordReset.errorPWInsDigits;
      case ErrorType.INSUFFICIENT_LOWERCASE:
        return PatientPasswordReset.errorPWInsLowercase;
      default:
        return PatientPasswordReset.errorMessage;
    }
  }

  async validate() {
    this.attemptSubmit = true;

    if (this.validatePassword && this.validatePasswordRepeat) {
      let result = await UserRepository.changePasswordByResetToken(
        this.resetToken,
        this.password,
        this.passwordRepeat
      );
      this.errorMessages = [];
      this.loading = true;

      if (result.type === ResultType.SUCCESS) {
        this.passUpdateSucceeded = true;
        this.showError = false;
      } else {
        // Error handling
        switch (result.statusCode) {
          case 500:
            this.errorMessages.push(PatientPasswordReset.errorMessageApi);
            this.showError = true;
            break;
          case 400:
            try {
              const responseItems = result.raw
                ? JSON.parse(result.raw).description
                : [];

              if (responseItems.length) {
                responseItems.forEach((item: any) => {
                  this.errorMessages.push(this.getErrorMessage(item));
                });
              } else {
                this.errorMessages.push(PatientPasswordReset.errorMessage);
              }
            } catch (error) {
              this.errorMessages.push(PatientPasswordReset.errorMessage);
            }

            this.showError = true;
            break;
        }
      }

      // Reset
      this.loading = false;
      this.attemptSubmit = false;
    } else {
      this.focusFirstError();
    }
  }
}
