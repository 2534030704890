
import { Component } from "vue-property-decorator";
import validator from "validator";
import { ExpertForumRepository, router, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumDeleteAccount extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;
  public requestSent: boolean;
  public confirmation: boolean;

  public apiError: boolean;
  public resultMessage: string;
  public token: string;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.requestSent = false;
    this.confirmation = false;
    this.apiError = false;
    this.resultMessage = "";
    this.token = sessionStorage.getItem(`expert-forum-${Store.frontend}`) || "";
  }

  async mounted() {
    super.mounted();

    if (!this.token) {
      router.push(`/login`);
    }
  }

  get validateConfirmation() {
    return this.confirmation;
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateConfirmation) {
      this.sendRequest();
    } else {
      this.requestSent = true;
      this.isLoading = false;
      this.apiError = true;
      this.resultMessage =
        "Sie müssen die obige Bedingung überprüfen, um fortzufahren";
    }
  }

  async sendRequest() {
    let result = await ExpertForumRepository.deleteAccount(this.token);
    this.requestSent = true;
    this.isLoading = false;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      this.resultMessage = "Konto gelöscht.";
      this.apiError = false;
      this.attemptSubmit = false;

      const website = Store.frontend;
      sessionStorage.removeItem(`expert-forum-${website}`);
      setTimeout(() => {
        router.push(`/login`);
      }, 5000);
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
