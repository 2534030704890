var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"expertforum-patient-forgot-password",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2"},[_vm._v(" Um Ihr Passwort zurückzusetzen, geben Sie bitte entweder Ihren Benutzernamen oder Ihre E-Mail-Adresse ein: ")]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Benutzername*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.attemptSubmit && _vm.validateUsername && _vm.validateEmailEmpty,
              'is-valid':
                _vm.attemptSubmit && (_vm.validateUsername || _vm.validateEmailEmpty)
            },attrs:{"id":"username","type":"text"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("e-Mail-Adresse*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.attemptSubmit &&
                !(
                  (_vm.validateEmail && _vm.validateEmailEmpty) ||
                  (!_vm.validateEmailEmpty && _vm.validateUsername)
                ),
              'is-valid':
                _vm.attemptSubmit &&
                ((_vm.validateEmail && _vm.validateEmailEmpty) ||
                  (!_vm.validateEmailEmpty && _vm.validateUsername))
            },attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorEmail)}})])])]),(_vm.requestSent)?_c('div',{staticClass:"form-feedback"},[(_vm.apiError)?_c('div',{staticClass:"row submit-error"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.resultMessage)}}),_c('div',{staticClass:"divider small"})])]):_c('div',{staticClass:"row submit-success"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")]),_c('div',{staticClass:"divider small"})])])]):_vm._e(),_c('div',{staticClass:"controls"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-primary"},[_c('b',[_vm._v("Absenden")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"waiting"},[_c('span',{staticClass:"spinner-border spinner-border-md mr-2"}),_c('span',[_vm._v("Bitte warten...")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }