
import { Component } from "vue-property-decorator";
import { CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED, EventBus } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class EventTrackingOptIn extends BaseComponent {
  registerDataPrivacyStatement!: boolean;

  constructor() {
    super();
    this.registerDataPrivacyStatement = false;
  }

  async send() {
    EventBus.$emit(
      CHANNEL_EVENTREGISTRATION_INSURANCE_CHANGED,
      this.registerDataPrivacyStatement
    );
    this.closeModal();
  }

  get data_privacy_text_reinsurance() {
    return this.getSettingsAttribute("data_privacy_text_reinsurance");
  }

  get data_privacy_explanation_reinsurance() {
    return this.getSettingsAttribute("data_privacy_explanation_reinsurance");
  }

  get register_data_privacy_statement() {
    return this.getSettingsAttribute("register_data_privacy_statement");
  }

  closeModal() {
    (this.$refs.closemodal as HTMLElement).click();
  }
}
