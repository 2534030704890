
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { router, Storage, Store } from "common";

@Component
export default class ExpertforumUserInfo extends Vue {
  userIsLoggedIn: boolean = false;
  userIsOnMyAccount: boolean = false;

  constructor() {
    super();
  }

  created() {
    this.setUserIsLoggedIn();
  }

  async logout() {
    this.userIsLoggedIn = false;

    sessionStorage.removeItem(`expert-forum-${this.website}`);
    sessionStorage.removeItem(`expert-forum-${this.website}-user`);
    router.push(`/login`);
  }

  @Watch("$route")
  async isOnMyAccount() {
    this.userIsOnMyAccount = this.$router.currentRoute.path === `/my-account`;
  }

  setUserIsLoggedIn() {
    this.userIsLoggedIn = !!this.username && !!this.token;
  }

  get username() {
    return sessionStorage.getItem(`expert-forum-${this.website}-user`);
  }

  get token() {
    return sessionStorage.getItem(`expert-forum-${this.website}`);
  }

  get website() {
    return Store.frontend;
  }

  get accountLink() {
    return `/my-account`;
  }

  get loginLink() {
    return `/login`;
  }

  get logged_out_icon() {
    return "/assets/userinfo/logged-out.svg";
  }

  get logged_in_icon() {
    return "/assets/userinfo/logged-out.svg";
  }

  get txt_my_account() {
    return "Mein Konto";
  }

  get txt_logout() {
    return "Logout";
  }

  get txt_login() {
    return "Login zum Expertenforum";
  }
}
