
import { Component, Prop, Vue } from "vue-property-decorator";
import { ResultType, UserRepository } from "common";
import validator from "validator";

@Component
export default class ChangePassword extends Vue {
  @Prop({ default: false }) has_access!: boolean;

  public oldPasswordVal!: string;
  public newPasswordVal!: string;
  public newPasswordRepeatVal!: string;
  public attemptSubmit!: boolean;
  public sendingRequest!: boolean;
  public submitSuccessfull!: boolean;

  constructor() {
    super();
    this.attemptSubmit = false;
    this.sendingRequest = false;
    this.submitSuccessfull = false;
    this.oldPasswordVal = "";
    this.newPasswordVal = "";
    this.newPasswordRepeatVal = "";
  }

  get validateOldPassword() {
    return !validator.isEmpty(this.oldPasswordVal);
  }

  get validateNewPassword() {
    return (
      !validator.isEmpty(this.newPasswordVal) &&
      validator.equals(this.newPasswordVal, this.newPasswordRepeatVal)
    );
  }

  get validateNewPasswordRepeat() {
    return (
      !validator.isEmpty(this.newPasswordRepeatVal) &&
      validator.equals(this.newPasswordVal, this.newPasswordRepeatVal)
    );
  }

  async validate(e: any) {
    let self = this;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (
      this.validateOldPassword &&
      this.validateNewPassword &&
      this.validateNewPasswordRepeat
    ) {
      this.sendingRequest = true;
      let result = await UserRepository.changePassword(
        this.oldPasswordVal,
        this.newPasswordVal,
        this.newPasswordRepeatVal
      );
      if (result.type == ResultType.SUCCESS) {
        this.submitSuccessfull = true;
        this.resetPWChange();
      } else {
        alert(
          "Es ist ein Fehler beim Ändern Ihres Passwortes aufgetreten. Bitte versuchen Sie es später noch einmal."
        );
      }
      this.sendingRequest = false;
    }
  }

  resetPWChange() {
    this.oldPasswordVal = "";
    this.newPasswordVal = "";
    this.newPasswordRepeatVal = "";
    this.sendingRequest = false;
    this.attemptSubmit = false;
  }
}
