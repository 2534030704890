
import { Component, Vue, Prop } from "vue-property-decorator";
import { ContentRepository, LayoutComponent, ResultType, router } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Indication extends BaseComponent {
  @Prop({ default: true }) handleClick!: boolean;

  private link!: any;

  constructor() {
    super();
    this.link = null;
  }

  getComponentType() {
    return "indications";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.setLink();
    });
  }

  gotoLink() {
    if (this.handleClick) {
      if (this.link) {
        router.push("/" + this.link);
      }
    } else {
      this.$emit("click");
    }
  }

  get icon() {
    let icon = this.getContentAttribute("icon");
    if (icon && icon.length > 0) {
      return new LayoutComponent(icon[0]);
    }
    return null;
  }

  get title() {
    return this.getContentAttribute("title");
  }

  get background() {
    return this.getContentAttribute("background");
  }

  async setLink() {
    let link = this.getContentAttribute("cockpit_link");
    if (link) {
      let linkResponse = await ContentRepository.getCollectionItemByTypeAndId(
        link.link,
        [link._id],
        0,
        { _id: 0, slug: 1 }
      );

      if (linkResponse.type == ResultType.SUCCESS) {
        if (linkResponse.value) {
          this.link = linkResponse.value[0].slug;
        }
      }
    }
  }
}
