
import BaseComponent from "../base/BaseComponent.vue";
import { Component } from "vue-property-decorator";

@Component
export default class BatchLoginStep extends BaseComponent {
  getComponentType() {
    return "batch_login_steps";
  }

  constructor() {
    super();
  }

  async mounted() {
    await super.mounted();
  }

  get layout() {
    return this.getContentAttribute("layout");
  }

  get button_label_no() {
    return this.getContentAttribute("button_label_no");
  }

  get button_label_yes() {
    return this.getContentAttribute("button_label_yes");
  }

  get buttonsAreSet() {
    return (
      this.button_label_no &&
      this.button_label_no.trim() != "" &&
      this.button_label_yes.trim() != ""
    );
  }

  clickNo() {
    this.$emit("no");
  }

  clickYes() {
    this.$emit("yes");
  }
}
