
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Hero extends BaseComponent {
  getComponentType() {
    return "heroes";
  }

  async mounted() {
    super.mounted();
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return image
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get headlineElements() {
    let headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return headline
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get cssClasses() {
    return this.layoutComponent.class;
  }
  get customStyles(): string {
    return this.layoutComponent.style;
  }
}
