
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
import { LayoutComponent, QuizRepository, ResultType } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LcQuizQuestions extends BaseComponent {
  @Prop() showMessage!: boolean;

  public selectedAnswerIdx: number[] = [];
  public correctAnswerIdx: number[] = [];
  public isMultiple: boolean = false;

  public readonly infoMessage: string =
    "Leider war Ihre Antwort nicht korrekt! Bitte vesuchen Sie es noch einmal.";

  getComponentType() {
    return "quiz_questions";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.getCorrectAnswer();
    });
  }

  /************************************/
  // Map Cockpit props

  get text() {
    let text = this.getContentAttribute("question");
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }

    return null;
  }

  getAnswer(key: string) {
    let answer = this.getContentAttribute(key);
    if (answer) {
      return new LayoutComponent(answer[0]);
    }
    return null;
  }

  get answers() {
    if (!this.content || !this.content.length) return [];

    const answers: any[] = [];
    const content = this.content[0] as Object;

    Object.keys(content)
      .filter(
        (key: string) => key.includes("answer") && !key.includes("result")
      )
      .filter((key: string) => {
        const attribute = this.getContentAttribute(key);
        return !!attribute && Array.isArray(attribute) && attribute.length;
      })
      .forEach((key: string) => answers.push(this.getAnswer(key)));

    return answers;
  }

  get answersResults() {
    if (!this.content || !this.content.length) return [];

    const results: any[] = [];
    const content = this.content[0] as Object;

    Object.keys(content)
      .filter((key: string) => key.includes("answer") && key.includes("result"))
      .filter((key: string) => {
        const attribute = this.getContentAttribute(key);
        return !!attribute && Array.isArray(attribute) && attribute.length;
      })
      .forEach((key: string) => results.push(this.getAnswer(key)));

    return results;
  }

  get multipleAnswersResult() {
    const correctAnswers = this.correctAnswerIdx.sort();
    const selectAnswers = this.selectedAnswerIdx.sort();
    const isFullyCorrect =
      JSON.stringify(correctAnswers) === JSON.stringify(selectAnswers);
    const isPartiallyCorrect =
      selectAnswers.filter((id: number) => correctAnswers.includes(id)).length >
      0;

    if (isFullyCorrect) {
      return this.getAnswer("correct_for_multiple");
    } else if (isPartiallyCorrect) {
      return this.getAnswer("partially_correct_for_multiple");
    } else {
      return this.getAnswer("incorrect_for_multiple");
    }
  }

  /************************************/
  // Business logic

  // Save local state and send data to parent
  selectAnswer(answerIdx: number) {
    if (this.selectedAnswerIdx.includes(answerIdx)) {
      this.selectedAnswerIdx = _.without(this.selectedAnswerIdx, answerIdx);
    } else if (this.isMultiple) {
      this.selectedAnswerIdx.push(answerIdx);
    } else {
      this.selectedAnswerIdx = [answerIdx];
    }

    this.$emit("selectAnswer", {
      selectedAnswerIdx: this.selectedAnswerIdx,
      correctAnswerIdx: this.correctAnswerIdx
    });
  }

  async getCorrectAnswer() {
    const result = await QuizRepository.getAnswer(this.contentIds[0]);
    if (result.type == ResultType.SUCCESS) {
      const answer = result.value.answer as string;
      this.correctAnswerIdx = answer.includes(", ")
        ? answer.split(", ").map((val: string) => Number.parseInt(val))
        : [Number.parseInt(answer)];
      this.isMultiple = this.correctAnswerIdx.length > 1;
    }
  }

  getCssClasses(answerIdx: number) {
    return {
      "quiz__answer--selected": this.selectedAnswerIdx.includes(answerIdx),
      "quiz__answer--correct":
        this.correctAnswerIdx.includes(answerIdx) &&
        this.selectedAnswerIdx.includes(answerIdx)
    };
  }

  /************************************/
}
