export class LayoutComponent {
  public _id!: string;
  component!: string;
  settings!: { [key: string]: any };
  columns!: LayoutComponent[];
  children!: LayoutComponent[];

  constructor(component: any) {
    this._id = Math.round(Math.random() * 36 ** 12).toString(36);

    // Map name
    this.component = this.mapName(component.component);
    this.settings = component.settings;
    this.columns = [];
    this.children = [];

    let self = this;

    // add children
    if (Object.keys(component).indexOf("children") != -1) {
      component.children.forEach((child: any) => {
        let childComp = new LayoutComponent(child);
        self.addChild(childComp);
      });
    }

    // add columns if component is grid
    if (Object.keys(component).indexOf("columns") != -1) {
      component.columns.forEach((column: any) => {
        column.component = "Column";
        let columnComp = new LayoutComponent(column);
        self.addColumn(columnComp);
      });
    }
  }

  static createEmpty(component: string, settings: any = null) {
    let c = new LayoutComponent({
      component: component,
      settings: {
        visibility: true
      },
      columns: [],
      children: []
    });

    if (settings) {
      c.settings = settings;
    }

    return c;
  }

  static createLogin(
    headline: string = "Melden Sie sich an, um alle Inhalte zu sehen",
    error: string = "Wir konnten Sie nicht anmelden."
  ) {
    return new LayoutComponent({
      component: "Login",
      settings: {
        headline: headline,
        subheadline: "Mit Ihrem MeinMEDCAMPUS-Account anmelden",
        username: "E-Mail-Adresse",
        password: "Passwort",
        reset_label: "Passwort vergessen?",
        login_label: "Einloggen",
        success: "Sie haben sich erfolgreich angemeldet.",
        error: "Wir konnten Sie nicht anmelden.",
        visibility: true,
        username_hint: "E-Mail-Adresse",
        password_hint: "Passwort"
      }
    });
  }

  setSettingsValue(settingsKey: string, settingsValue: string) {
    this.settings[settingsKey] = settingsValue;
  }

  static createFromCollectionItem(
    component: string,
    item: any,
    extraSettings?: any
  ) {
    let ignoreItemKeys: any = [
      "_by",
      "_created",
      "_link",
      "_mby",
      "_modified",
      "component",
      "settings"
    ];

    item.component = component;
    item.settings = Object.assign(
      {
        class: "",
        id: "",
        roles: null,
        style: "",
        visibility: true,
        width: "12",
        content: {}
      },
      extraSettings
    );

    Object.keys(item).forEach((itemKey: any) => {
      if (!ignoreItemKeys.includes(itemKey)) {
        item.settings.content[itemKey] = item[itemKey];
      }
    });

    return new LayoutComponent(item);
  }

  private addColumn(col: LayoutComponent) {
    this.columns!.push(col);
  }

  private addChild(child: LayoutComponent) {
    this.children!.push(child);
  }

  private mapName(componentName: string) {
    let mappedName: string = "";
    let nameParts = componentName.split("_");

    nameParts.forEach((namePart: string) => {
      mappedName += namePart[0].toUpperCase() + namePart.slice(1);
    });

    return mappedName;
  }

  get id(): string {
    return this.settings.id;
  }

  get style(): string {
    return this.settings.style;
  }

  get class(): string {
    return this.settings.class;
  }

  get visible(): boolean {
    return this.settings.visibility;
  }

  static createContactForm() {
    return new LayoutComponent({
      component: "ContactForm",
      settings: {
        name: {
          label: "Name",
          placeholder: "Name"
        },
        email: {
          label: "E-Mail",
          placeholder: "E-Mail"
        },
        phone: {
          label: "Telefon",
          placeholder: "Telefon"
        },
        address: {
          label: "Anschrift",
          placeholder: "Anschrift"
        },
        question: {
          label: "Frage (optional)",
          placeholder: "Frage"
        },
        sectionTitle: "Wie dürfen wir Kontakt mit Ihnen aufnehmen?",
        phoneCheck: "Ich wünsche einen Rückruf durch AstraZeneca",
        emailCheck: "Ich wünsche eine Kontaktaufnahme via Email",
        appointmentCheck:
          "Ich möchte einen Termin für einen Besuch eines AstraZeneca Mitarbeiters vereinbaren",
        postCheck: "Ich möchte Informationen per Post erhalten",
        privacyCheck:
          "Ich erkläre mich einverstanden, dass die AstraZeneca GmbH die von mir angegebenen Daten speichert, auswertet und nutzt, um mir bedarfsgerechte Informationen zukommen zu lassen. Die Daten werden ausschließlich von der AstraZeneca GmbH genutzt und dürfen nicht für andere Zwecke verwendet werden. Diese Einverständniserklärung kann ich jederzeit widerrufen, z.B. per E-Mail an <span class='privacy-optout-email'>optout-astrazeneca@astrazeneca.com</span>",
        informationTypeCheckbox:
          "Wählen Sie die Fachinformation aus, die wir Ihnen zukommen lassen sollen.",
        congress:
          "Wählen Sie die Kongresse aus, von denen wir Ihnen ein Slidekit zukommen lassen sollen.",
        indication: {
          label: "Indikation",
          placeholder: "Bitte wählen Sie eine Indikation aus"
        }
      }
    });
  }
}
