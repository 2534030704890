var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"patient-register"},[_c('h3',{staticClass:"patient-register__headline"},[_vm._v("Register")]),(!_vm.registerSucceded)?_c('form',{staticClass:"patient-register__form",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.validate()}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Benutzername")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('username'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('username')
        },attrs:{"id":"username","type":"text","name":"username","placeholder":"Benutzername"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "username", $event.target.value)}}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" Hinweis: Benutzernamen können Buchstaben und Zahlen mit einem Minuszeichen \"-\" oder einem Unterstrich \"_\" als Trennzeichen enthalten. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-Mail-Adresse")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('email'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('email')
        },attrs:{"id":"email","type":"text","name":"email","placeholder":"E-Mail"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pw"}},[_vm._v("Passwort")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pw),expression:"form.pw"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('pw'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('pw')
        },attrs:{"id":"pw","type":"password","name":"","placeholder":"Passwort"},domProps:{"value":(_vm.form.pw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "pw", $event.target.value)}}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" Hinweis: Passwörter müssen mindestens 8 Zeichen lang sein und mindestens 1 Großbuchstaben (A-Z), 1 Zahl (0-9) und 1 Sonderzeichen (!”§$%&/()=?*#) enthalten. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pw_confirm"}},[_vm._v("Passwort bestätigen")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pwConfirm),expression:"form.pwConfirm"}],staticClass:"form-control",class:{
          'is-invalid': _vm.attemptSubmit && !_vm.validateInput('pwConfirm'),
          'is-valid': _vm.attemptSubmit && _vm.validateInput('pwConfirm')
        },attrs:{"id":"pw_confirm","type":"password","name":"pw_confirm","placeholder":"Passwort wiederholen"},domProps:{"value":(_vm.form.pwConfirm)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "pwConfirm", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorPasswordConfirm)}})]),_c('div',{staticClass:"patient-register__submit text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner-border",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Register ")])]),(_vm.showError)?_c('div',{staticClass:"patient-register__errors"},_vm._l((_vm.errorMessages),function(error,idx){return _c('div',{key:idx,staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e(),_c('div',{staticClass:"patient-register__footer pt-10"},[_c('p',{staticClass:"mb-8"},[_vm._v(" Wenn Sie bereits ein Konto haben, können Sie sich ganz einfach hier "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Login")]),_vm._v(". ")],1),_c('p',{staticClass:"small"},[_vm._v(" Für die Bereitstellung Ihres Nutzerkontos verarbeiten wir Ihre hier erhobenen Daten. Sie können das Nutzerkonto jederzeit in den Einstellungen löschen. Weitere Informationen zum Datenschutz finden Sie in der "),_c('router-link',{attrs:{"to":"/privacy"}},[_vm._v("Datenschutzerklärung")]),_vm._v(". ")],1)])]):_c('div',{staticClass:"patient-register__success p-3 mt-5 mb-8 rounded"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._m(0),_c('div',{staticClass:"patient-register__success-text px-3 flex-fill",domProps:{"innerHTML":_vm._s(_vm.registrationSuccessMessage)}})]),_c('div',{staticClass:"text-right mt-4"},[_c('router-link',{staticClass:"btn btn-link",attrs:{"to":"/"}},[_vm._v(" Zurück zur Startseite ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"patient-register__success-icon"},[_c('i',{staticClass:"far fa-check-circle display-4 text-success"})])
}]

export { render, staticRenderFns }