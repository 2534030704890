import config, {
  EnvironmentModules,
  FrontendContext,
  FrontendModules
} from "../config";
import LoginHandler from "./LoginHandler";
import RouteLoader from "./RouteLoader";
import Rating from "../plugins/rating";
import Tealium from "../plugins/tealium";
import ReadSpeaker from "../plugins/readspeaker";
import { RouteConfig } from "vue-router/types/router";
import RoutesGenerator from "./RouteGenerator";
import { Store } from "..";
import { changeContextByRoute } from "../util/Helper";

export interface RedirectRoute {
  from: string;
  to: string;
}

export interface BootstrapInterface {
  init(): void;
  initRoutes(redirectList?: RedirectRoute[]): Promise<RouteConfig[]>;
}

class Bootstrap {
  async initRoutes(redirectList?: RedirectRoute[]): Promise<RouteConfig[]> {
    /* STEP 1: Add /debug route to all dev and stage frontends */
    const debugRoute: RouteConfig[] = this.getDebugRoute();

    /* STEP 2: Load public routes configured in config.ts */
    const publicRoutes: RouteConfig[] = this.getPublicRoutes();

    /* STEP 3: Load private routes if configured in config.ts */
    const privateRoutes: RouteConfig[] = await this.getPrivateRoutes();

    /* Last: catch all other routes and redirect to 404 */
    const notFound: RouteConfig = {
      path: "/:catchAll(.*)",
      redirect: { name: "NotFound" }
    };

    if (redirectList) {
      const redirectRoutes = redirectList.map((route: RedirectRoute) => {
        return {
          path: encodeURI(route.from),
          redirect: {
            path: encodeURI(route.to)
          }
        };
      });

      // <!> It's important that notFound route is the last one.
      return [
        ...debugRoute,
        ...publicRoutes,
        ...privateRoutes,
        ...redirectRoutes,
        notFound
      ];
    }

    // <!> It's important that notFound route is the last one.
    return [...debugRoute, ...publicRoutes, ...privateRoutes, notFound];
  }

  private getDebugRoute() {
    if (config.modules.includes(EnvironmentModules.DEBUG)) {
      return RouteLoader.getDebugRoute();
    }
    return [];
  }

  private getPublicRoutes() {
    return RouteLoader.getPublicRoutes();
  }

  private async getPrivateRoutes() {
    await RouteLoader.loadPrivateRoutes();
    const routeGenerator = new RoutesGenerator(Store.allRoutes, config);
    return routeGenerator.generateRoutes();
  }
}

class BootstrapHCP extends Bootstrap implements BootstrapInterface {
  async init() {
    /* Login handling if set in config.ts */
    // await this.addLoginHandling();

    /* Rating handling */
    await this.ratingHandling();

    /* Tealium */
    this.tealiumHandling();

    /* ReadSpeaker */
    this.readSpeakerHandling();
  }

  private async addLoginHandling() {
    if (
      config.getFrontendConfiguration().modules.includes(FrontendModules.LOGIN)
    ) {
      await LoginHandler.handleLoginOnBootstrap();
    }

    if (
      config
        .getFrontendConfiguration()
        .modules.includes(FrontendModules.BATCH_LOGIN)
    ) {
      await LoginHandler.handleBatchLoginOnBootstrap();
    }
  }

  private async ratingHandling() {
    new Rating();
  }

  private tealiumHandling() {
    Tealium.getInstance().initialize();
  }

  private readSpeakerHandling() {
    ReadSpeaker.getInstance().initialize();
  }
}

class BootstrapPatient extends Bootstrap implements BootstrapInterface {
  async init() {
    /* Login handling if set in config.ts */
    // await this.addLoginHandling();
  }

  private async addLoginHandling() {
    if (
      config.getFrontendConfiguration().modules.includes(FrontendModules.LOGIN)
    ) {
      await LoginHandler.handleLoginOnBootstrap();
    }

    if (
      config
        .getFrontendConfiguration()
        .modules.includes(FrontendModules.BATCH_LOGIN)
    ) {
      await LoginHandler.handleBatchLoginOnBootstrap();
    }
  }
}

class BootstrapFactory {
  private static instance?: BootstrapInterface;

  public static getBootstrap() {
    changeContextByRoute(config);
    if (!this.instance) {
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.PATIENT:
          this.instance = new BootstrapPatient();
          break;
        case FrontendContext.HCP:
        default:
          this.instance = new BootstrapHCP();
          break;
      }
    }

    return this.instance;
  }
}

export default BootstrapFactory.getBootstrap();
