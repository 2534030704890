
import { Component, Vue } from "vue-property-decorator";
import {
  EventRepository,
  LayoutComponent,
  ResultType,
  RoutingHelper,
  ContentRepository
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CardImage extends BaseComponent {
  public linkTarget!: string;
  public isVeevaRegistered!: boolean;
  public opParticipantLink!: string | null;

  constructor() {
    super();
    this.linkTarget = "#";
    this.isVeevaRegistered = false;
    this.opParticipantLink = null;
  }

  getComponentType() {
    return "card_images";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      if (this.file && Object.prototype.hasOwnProperty.call(this.file, "_id")) {
        const file = await this.loadFile();
        this.linkTarget = RoutingHelper.getFilePath(file.path);
      } else {
        this.linkTarget = await this.getLink();
      }
    });
  }

  async getLink() {
    let result = this.link;

    if (this.veevaId) {
      const response: any = await EventRepository.getVeevaEvent(this.veevaId);

      if (response.type == ResultType.SUCCESS) {
        this.isVeevaRegistered = !!response.value.registered;
        this.opParticipantLink = response.value.opParticipantLink;

        if (response.value.opParticipantLink) {
          result = response.value.opParticipantLink;
        }
      } else {
        this.isVeevaRegistered = false;
        this.opParticipantLink = null;
      }
    }

    if (this.file) {
      result = RoutingHelper.getFilePath(this.file.path);
    }

    return result;
  }

  async loadFile() {
    const result = await ContentRepository.getCollectionItemByTypeAndId(
      this.file.link,
      [this.file._id],
      0
    );

    if (result.type == ResultType.SUCCESS) {
      return result.value[0].file;
    }

    return null;
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get link() {
    return this.getContentAttribute("link");
  }

  get veevaId() {
    const id = this.getContentAttribute("veeva_id");
    return id && id.trim().length > 0 ? id : null;
  }

  get file() {
    return this.getContentAttribute("file");
  }

  get target() {
    return this.layoutComponent.settings.target;
  }

  get cssClass() {
    return this.layoutComponent.class;
  }

  get contentItems() {
    let contentItems = this.getContentAttribute("content");

    if (contentItems && contentItems.length > 0) {
      return contentItems.map((comp: any) => new LayoutComponent(comp));
    }
    return [];
  }

  get footer() {
    let footer = this.getContentAttribute("footer");

    if (footer && footer.length > 0) {
      return footer
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }
    return [];
  }

  get clickHandler() {
    return this.linkDisabled ? "click" : null;
  }

  get linkDisabled() {
    return !!this.getContentAttribute("link_disabled");
  }
}
