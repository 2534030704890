
import { Component, Vue } from "vue-property-decorator";
import { isNil } from "lodash";
import { Helper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Link extends BaseComponent {
  readonly TYPE_FILLED = "filled";
  readonly TYPE_OUTLINED = "outlined";
  readonly TYPE_OUTLINED_NOBORDER = "outlined no border";

  readonly THEME_LIGHT = "light";
  readonly THEME_DARK = "dark";
  static readonly TO_TOP_BUTTON_OFFSET = 300;

  getComponentType() {
    return "links";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      Helper.crawlDomAndAddEventlistenerToAllLinks([this.$refs.link]);

      const element = this.$refs.link as HTMLElement;

      if (element.classList.contains("link--top")) {
        this.handleGoToTopLink(element);
      }
    });
  }

  get link() {
    return this.getContentAttribute("link");
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get ariaLabel() {
    return this.getContentAttribute("aria_label");
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  get theme() {
    return this.getSettingsAttribute("theme");
  }

  get target() {
    return isNil(this.layoutComponent.settings.target)
      ? "_self"
      : this.layoutComponent.settings.target;
  }

  get cssClasses() {
    let classes = [this.layoutComponent.class];

    if (this.type == this.TYPE_FILLED) {
      classes.push("btn-primary");
    } else if (this.type == this.TYPE_OUTLINED) {
      classes.push("btn-outline");
    } else if (this.type == this.TYPE_OUTLINED_NOBORDER) {
      classes.push("btn-outline no-border");
    }

    if (this.theme == this.THEME_LIGHT) {
      classes.push("white");
    }

    classes.push();
    return classes.join(" ");
  }

  handleGoToTopLinkClass(element: HTMLElement) {
    if (window.pageYOffset <= Link.TO_TOP_BUTTON_OFFSET) {
      element.classList.add("link--not-scrolled");
    } else {
      element.classList.remove("link--not-scrolled");
    }
  }

  handleGoToTopLink(element: HTMLElement) {
    this.handleGoToTopLinkClass(element);

    window.document.addEventListener(
      "scroll",
      this.handleGoToTopLinkClass.bind(this, element)
    );
  }
}
