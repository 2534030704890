
import { Component } from "vue-property-decorator";
import { matomo, MatomoEventNames, router } from "common";
import { notification } from "ant-design-vue";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CopyToClipboard extends BaseComponent {
  getComponentType() {
    return "";
  }

  async mounted() {
    await super.mounted();
  }

  get textContent() {
    const text = this.getSettingsAttribute("text");
    // Return text or URL if text field is empty
    if (text && text.trim().length) {
      return text;
    }

    return (
      window.location.protocol +
      "//" +
      window.location.host +
      router.currentRoute.path
    );
  }

  get buttonLabel() {
    return this.getSettingsAttribute("button_label");
  }

  get notifText() {
    return this.getSettingsAttribute("notif_text");
  }

  clickHandler() {
    navigator.clipboard.writeText(this.textContent);

    if (this.notifText) {
      notification.destroy();
      notification.open({
        message: this.notifText,
        class: "copy-notification",
        duration: 5,
        description: ""
      });
    }
    // Matomo tracking
    matomo.genericEvent(
      this.textContent,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.SHARE_LINK
    );
  }
}
