
import { Component, Prop, Vue } from "vue-property-decorator";
import { LayoutComponent, router } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class TrainingItem extends BaseComponent {
  @Prop() item!: any;
  @Prop() completedTrainings!: any;
  @Prop() isCompleted!: boolean;

  public isDone!: boolean;
  public trainingTut!: any;
  public quizIdx!: string;
  public showAlert!: boolean;

  constructor() {
    super();
    this.isDone = this.isTrainingCompleted;
    this.trainingTut = this.trainingTutorial;
    this.quizIdx = this.trainingQuizId;
    this.showAlert = false;
  }

  getComponentType() {
    return "trainings";
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.trainingTut = this.trainingTutorial;
    });
  }

  get trainingTutorial() {
    let modalContent = this.item.training_tutorial;
    if (modalContent && modalContent.length > 0) {
      return new LayoutComponent(modalContent[0]);
    }
    return null;
  }

  get isTrainingCompleted() {
    if (this.completedTrainings && this.completedTrainings.length > 0) {
      let me = this;
      let trainings = this.completedTrainings;
      let isTrainingDone: boolean = false;
      return isTrainingDone;
    }
    return false;
  }

  get trainingQuizId() {
    let quiz = this.item.quiz;
    let quizId;
    if (quiz && quiz.length > 0) {
      quizId = quiz[0].settings.content._id;
    }
    return quizId;
  }

  clickHandler(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.isCompleted) {
      let modalContent = this.trainingTutorial;
      if (modalContent) {
        this.showModal("", modalContent, "modal-xl");
      } else {
        router.push(this.item.training_link);
      }
    } else {
      this.showAlert = !this.showAlert;
    }
  }

  get cssClass() {
    let imcompleteClass = "";
    if (!this.isDone) {
      imcompleteClass = "incomplete";
    }
    switch (this.item.category) {
      case "Diabetologie":
        return "card--diabetologie " + imcompleteClass;
      case "Pneumologie":
        return "card--pneumologie " + imcompleteClass;
      case "Kardiologie":
        return "card--kardiologie " + imcompleteClass;
      case "Nephrologie":
        return "card--nephrologie " + imcompleteClass;
      case "Große Lunge":
        return "card--lunge " + imcompleteClass;
      case "Multimodale Behandlung im ambulanten Setting":
        return "card--multimodale " + imcompleteClass;
      case "Folgeerkrankungen":
        return "card--folgeerkrankungen " + imcompleteClass;
      case "Präzisionsmedizin":
        return "card--prazisionsmedizin " + imcompleteClass;
      case "Labordiagnostik":
        return "card--labordiagnostik " + imcompleteClass;
      default:
        return " " + imcompleteClass;
    }
  }

  get getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }
}
