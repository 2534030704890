
import { Component, Prop, Vue } from "vue-property-decorator";
import { AlgoliaCategory } from "common";

@Component({})
export default class SearchBar extends Vue {
  @Prop({ default: "Example: Support, Nutrition " })
  placeholder!: string;
  @Prop({ required: true }) brand!: string;
  @Prop({ default: 5 }) maxResults!: number;
  @Prop({ default: 3 }) minQueryLength!: number;
  @Prop({ default: "/search" }) allResultsRoute!: string;
  @Prop({ default: () => [] }) categories!: AlgoliaCategory[];
  @Prop({ default: "/faq" }) faqLink!: string | null;

  public searchQuery!: string;
  public opened!: boolean;

  constructor() {
    super();

    this.searchQuery = "";
    this.opened = false;
  }

  public performSearch(): void {
    if (this.searchQuery.length < this.minQueryLength) {
      return;
    }

    this.doClose();
    this.$router.push({
      path: this.allResultsRoute,
      query: { q: this.searchQuery }
    });
  }

  public doClose(): void {
    this.opened = false;
    this.$emit("close");
  }

  public doOpen(): void {
    this.opened = true;
    this.$emit("open");
  }

  public goToCategory(category: string, item: string): void {
    this.doClose();

    const query = {
      q: this.searchQuery
    } as any;

    query[category] = item;

    this.$router.push({
      path: this.allResultsRoute,
      query: query
    });
  }

  public goToFAQ(): void {
    this.doClose();
    this.$router.push("/faq");
  }
}
