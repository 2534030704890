
import { Vue, Component } from "vue-property-decorator";
import {
  AlgoliaSearchRepository,
  AlgoliaHit,
  AlgoliaResponse,
  ResultType,
  ConfiguratorRepository
} from "common";
import SearchFiltersCancer360 from "../../../../../packages/vue-ui/components/content/SearchFilters.vue";
import { CANCER_SUPPORT_360_APP_BRAND, RAW_FILTERS } from "./constants";
import { ConfiguratorPackage } from "common/api/repositories/ConfiguratorRepository";

export interface FilterItem {
  name: string;
  value: string;
  selected: boolean;
  amount: number;
}

export interface Filter {
  name: string;
  value: string;
  expanded: boolean;
  showAmount: number;
  items: FilterItem[];
}

interface ResultFilterList {
  topics: FilterItem[];
  cancerTypes: FilterItem[];
  contentTypes: FilterItem[];
  siteVisitors: FilterItem[];
}

interface FilterableAlgoliaHit extends AlgoliaHit {
  filters: ResultFilterList;
  hasFilters: boolean;
}

@Component({})
export default class PackageView extends Vue {
  public searchQuery!: string;
  public filters!: Filter[];
  public algoliaResponse: AlgoliaResponse;
  public resultsPerPage: number;
  public currentPage: number;

  public configuratorPackage: ConfiguratorPackage | null = null;
  public configuratorPackageExists: boolean = false;
  public selectedPageSlugs: string[] = [];

  constructor() {
    super();
    this.searchQuery = ""; //this.searchQueryFromToken;
    this.algoliaResponse = AlgoliaResponse.empty();
    this.resultsPerPage = 11;
    this.currentPage = 1;
    this.filters = [];
  }

  async mounted(): Promise<void> {
    if ("code" in this.$route.query) {
      const result = await ConfiguratorRepository.getPackage(
        this.$route.query.code as string
      );

      if (result.type === ResultType.SUCCESS) {
        this.configuratorPackageExists = true;
        this.configuratorPackage = result.value;
        this.selectedPageSlugs = this.configuratorPackage!.slugs;
      } else {
        this.configuratorPackageExists = false;
        return;
      }
    }

    await this.performSearch();
  }

  contentDidLoad(): void {
    this.setupFilters();
  }

  public setupFilters(): void {
    this.filters = [];

    this.rawFilters.forEach((filter: Filter) => {
      this.filters.push({
        name: filter.name,
        value: filter.value,
        expanded: false,
        showAmount: 5,
        items: filter.items.map((item: FilterItem) => {
          return {
            name: item.name,
            value: item.value,
            selected: this.inQueryParams(item.value),
            amount: 0
          };
        })
      });
    });
  }

  public inQueryParams(item: string): boolean {
    for (const key in this.$route.query) {
      if (!Object.prototype.hasOwnProperty.call(this.$route.query, key)) {
        continue;
      }

      const value = this.$route.query[key];

      if (Array.isArray(value)) {
        if (value.includes(item)) {
          return true;
        }
      } else {
        if (value === item) {
          return true;
        }
      }
    }

    return false;
  }

  public goToNextPage(): void {
    if (!this.hasNextPage) {
      return;
    }

    this.currentPage++;
  }

  public goToPreviousPage(): void {
    if (!this.hasPreviousPage) {
      return;
    }

    this.currentPage--;
  }

  public goToPage(page: number): void {
    if (page < 1 || page > this.numberOfPages) {
      return;
    }

    this.currentPage = page;
  }

  public async performSearch(): Promise<void> {
    const response = await AlgoliaSearchRepository.search(this.brand, "");

    this.algoliaResponse =
      response.type === ResultType.SUCCESS
        ? response.value
        : AlgoliaResponse.empty();

    this.setupFilters();

    this.algoliaResponse.hits = this.algoliaResponse.hits.map(
      (hit: AlgoliaHit): FilterableAlgoliaHit =>
        this.getFilterItemsForResult(hit)
    );

    this.algoliaResponse.hits.forEach((hit: AlgoliaHit) => {
      this.getFilterItemsForResultFilter(
        hit as FilterableAlgoliaHit,
        "topics",
        "topic"
      ).forEach((topic: FilterItem) => {
        topic.amount += 1;
      });

      this.getFilterItemsForResultFilter(
        hit as FilterableAlgoliaHit,
        "cancerTypes",
        "cancer_type"
      ).forEach((cancerType: FilterItem) => {
        cancerType.amount += 1;
      });

      this.getFilterItemsForResultFilter(
        hit as FilterableAlgoliaHit,
        "contentTypes",
        "content_type"
      ).forEach((contentType: FilterItem) => {
        contentType.amount += 1;
      });

      this.getFilterItemsForResultFilter(
        hit as FilterableAlgoliaHit,
        "siteVisitors",
        "site_visitor"
      ).forEach((siteVisitor: FilterItem) => {
        siteVisitor.amount += 1;
      });
    });

    this.currentPage = 1;
  }

  public toggleFilter(filter: Filter): void {
    filter.expanded = !filter.expanded;
  }

  public applyFilter(item: FilterItem): void {
    item.selected = !item.selected;

    this.currentPage = 1;
  }

  public toggleShowMore(filter: Filter): void {
    filter.showAmount = filter.showAmount === 5 ? filter.items.length : 5;
  }

  public doClear(): void {
    this.searchQuery = "";
    this.performSearch();
  }

  public openResult(result: AlgoliaHit): void {
    window.open("https://cancersupportplus.com/" + result.slug, "_blank");
  }

  public getFilterItemsForResultFilter(
    result: AlgoliaHit,
    resultFilterKey: string,
    filterKey: string
  ): FilterItem[] {
    const filter = this.filters.find(filter => filter.value === filterKey);

    if (!filter) {
      return [];
    }

    return filter.items.filter(item =>
      (result as any)[resultFilterKey].includes(item.value)
    );
  }

  public getFilterItemsForResult(result: AlgoliaHit): FilterableAlgoliaHit {
    const topics = this.getFilterItemsForResultFilter(
      result,
      "topics",
      "topic"
    );
    const cancerTypes = this.getFilterItemsForResultFilter(
      result,
      "cancerTypes",
      "cancer_type"
    );
    const contentTypes = this.getFilterItemsForResultFilter(
      result,
      "contentTypes",
      "content_type"
    );
    const siteVisitors = this.getFilterItemsForResultFilter(
      result,
      "siteVisitors",
      "site_visitor"
    );

    return {
      ...result,
      filters: {
        topics,
        cancerTypes,
        contentTypes,
        siteVisitors
      },
      hasFilters:
        topics.length > 0 ||
        cancerTypes.length > 0 ||
        contentTypes.length > 0 ||
        siteVisitors.length > 0
    };
  }

  get cssClasses(): string {
    return "";
  }

  get searchQueryFromToken(): string {
    return (this.$route.query.q || "") as string;
  }

  get brand(): string {
    return CANCER_SUPPORT_360_APP_BRAND;
  }

  get rawFilters(): Filter[] {
    return RAW_FILTERS as Filter[];
  }

  get activeFilterItems(): FilterItem[] {
    return this.filters
      .map(filter => filter.items.filter(item => item.selected))
      .flat();
  }

  get numberOfPages(): number {
    return Math.ceil(this.clientFilteredResultsAmount / this.resultsPerPage);
  }

  get pages(): number[] {
    const pages = [];

    if (this.numberOfPages <= 5) {
      for (let i = 1; i <= this.numberOfPages; i++) {
        pages.push(i);
      }
    } else if (this.currentPage <= 3) {
      for (let i = 1; i <= 5; i++) {
        pages.push(i);
      }

      pages.push(0);
      pages.push(this.numberOfPages);
    } else if (this.currentPage >= this.numberOfPages - 2) {
      pages.push(1);
      pages.push(0);

      for (let i = this.numberOfPages - 4; i <= this.numberOfPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      pages.push(0);

      for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
        pages.push(i);
      }

      pages.push(0);
      pages.push(this.numberOfPages);
    }

    return pages;
  }

  get clientFilteredResults(): FilterableAlgoliaHit[] {
    return this.algoliaResponse.hits.filter(hit =>
      this.selectedPageSlugs.includes(hit.slug)
    ) as FilterableAlgoliaHit[];
  }

  get currentResults(): FilterableAlgoliaHit[] {
    return this.clientFilteredResults.slice(
      (this.currentPage - 1) * this.resultsPerPage,
      this.currentPage * this.resultsPerPage
    );
  }

  get clientFilteredResultsAmount(): number {
    return this.clientFilteredResults.length;
  }

  get hasPreviousPage(): boolean {
    return this.currentPage > 1;
  }

  get hasNextPage(): boolean {
    return (
      this.currentPage * this.resultsPerPage < this.clientFilteredResultsAmount
    );
  }
}
