
import { Component, Prop } from "vue-property-decorator";
import validator from "validator";
import {
  config,
  FrontendContext,
  EventBus,
  CHANNEL_NEWPASSWORD_TRIGGER_RESET,
  LayoutComponent,
  LoginHandler,
  LOGIN_ACCOUNT_LOCKED_MESSAGE,
  LOGIN_INVALID_ACCOUNT_MESSAGE,
  matomo,
  MatomoEventNames,
  ResultType,
  router,
  Store,
  User,
  UserRepository
} from "common";
import { BaseComponent } from "vue-ui";

@Component
export default class Login extends BaseComponent {
  @Prop({ default: false }) alwaysShow!: boolean;
  @Prop({ default: false }) reload!: boolean;
  @Prop({ default: "" }) optionalHeadline!: string;
  @Prop({ default: "" }) optionalErrorMsg!: string;
  @Prop({ default: false }) showOTP!: boolean;
  @Prop({ default: null }) redirectAfterLogin!: string | null;
  @Prop({ default: "" }) registerLink!: string;

  attemptSubmit: Boolean = false;
  submitSuccessfull: Boolean = false;
  alreadyLoggedIn: Boolean = false;
  usernameVal: string = "";
  passwordVal: string = "";

  requestOTP: boolean = false;
  otpRequestSent: boolean = false;

  readonly DISPLAY_TYPE_HORIZONTAL = "horizontal";
  readonly DISPLAY_TYPE_VERTICAL = "vertical";

  private showError: boolean;
  private accountLockedError: string;

  constructor() {
    super();

    this.showError = false;
    this.accountLockedError = "";

    this.customLayoutComponent = LayoutComponent.createLogin();
    if (this.optionalHeadline != "") {
      this.customLayoutComponent.setSettingsValue(
        "headline",
        this.optionalHeadline
      );
    }

    if (this.optionalErrorMsg != "") {
      this.customLayoutComponent.setSettingsValue(
        "error",
        this.optionalErrorMsg
      );
    }

    // Custom redirect to specific route after login
    if (this.redirectAfterLogin && this.redirectAfterLogin.trim().length) {
      Store.saveReturnUrl(this.redirectAfterLogin);
    }
  }

  async mounted() {
    await super.mounted();
    if (User.getProperty("accessToken") && !this.alwaysShow) {
      this.alreadyLoggedIn = true;
    }

    if (this.always_show) {
      this.alreadyLoggedIn = false;
    }

    this.usernameVal = User.getProperty("email") || "";

    if (this.resetToken) {
      this.resetPassword();
    }

    EventBus.$on(CHANNEL_NEWPASSWORD_TRIGGER_RESET, this.triggerPasswordReset);
  }

  async validate(e: any) {
    let self = this;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateUsername && this.validatePassword) {
      // Matomo tracking
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.PATIENT:
          matomo.genericEvent(
            "Login_Patient",
            matomo.MATOMO_EVENT_CATEGORY,
            MatomoEventNames.ACCOUNT_LOGIN_PATIENT
          );
          break;
        case FrontendContext.HCP:
        default:
          matomo.genericEvent(
            "Login_HCP",
            matomo.MATOMO_EVENT_CATEGORY,
            MatomoEventNames.ACCOUNT_LOGIN_HCP
          );
          break;
      }

      // Submit login
      let result = await UserRepository.login(
        this.usernameVal,
        this.passwordVal
      );

      if (result.type == ResultType.SUCCESS) {
        this.showError = false;
        self.submitSuccessfull = true;
        let loginResult = await LoginHandler.login(result.value, async () => {
          this.alreadyLoggedIn = true;
          Store.showSuccessfullLoginPopup = true;

          if (this.reload) {
            delete router.currentRoute.query.token;
            delete router.currentRoute.query.wauth;
            delete router.currentRoute.query.otp;

            let pathToReload: string = `${router.currentRoute.path}?`;
            let keyValues: string[] = [];
            for (const [key, value] of Object.entries(
              router.currentRoute.query
            )) {
              keyValues.push(`${key}=${value}`);
            }

            pathToReload += keyValues.join("&");
            pathToReload += "&r";
            pathToReload += router.currentRoute.hash;

            window.location.href = pathToReload;
          } else {
            let returnUrl = Store.retrieveReturnUrl();
            Store.deleteSavedReturnUrl();
            router.push(returnUrl ? returnUrl : "/");
          }
        });

        if (!loginResult) {
          alert(this.error);
        }
      } else {
        // Show account locked error
        if (LoginHandler.handleLockedAccount(result)) {
          this.showError = true;
          this.accountLockedError = LOGIN_ACCOUNT_LOCKED_MESSAGE;
        } else if (LoginHandler.handleInvalidAccount(result)) {
          this.showError = true;
          this.accountLockedError = LOGIN_INVALID_ACCOUNT_MESSAGE;
        } else {
          this.showError = true;
          this.accountLockedError =
            "Ein Fehler ist aufgetreten, wenden Sie sich an per eMail an: service.center@astrazeneca.com";
        }
      }
    }
    this.attemptSubmit = false;
  }

  reset() {
    this.attemptSubmit = false;
    this.submitSuccessfull = false;
    this.alreadyLoggedIn = false;
    this.usernameVal = "";
    this.passwordVal = "";
  }

  triggerPasswordReset() {
    Store.modal.title = "Passwort zurücksetzen";
    Store.modal.className = "modal-md modal--forgot-password";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("ResetPassword")
    );
    Store.modal.showModal();
  }

  async triggerOTPRequest() {
    if (!this.deepLoginTokenIsSet) {
      return;
    }

    this.requestOTP = true;

    let path = router.currentRoute.path + "?";
    router.currentRoute.query.otp = "$OTP";
    router.currentRoute.query.token = User.getProperty("deepLoginToken");

    Object.keys(router.currentRoute.query).forEach((key: string) => {
      path += `${key}=${router.currentRoute.query[key]}&`;
    });

    path = path.slice(0, -1);
    path += router.currentRoute.hash;

    let magicLink = window.location.origin + path;

    await UserRepository.requestOTP(magicLink).then((response: any) => {
      this.requestOTP = false;
      this.otpRequestSent = true;
    });
  }

  resetPassword() {
    Store.modal.title = "Passwort vergeben";
    Store.modal.className = "modal-md";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("NewPassword", {
        resetToken: this.resetToken
      })
    );
    Store.modal.showModal();
  }

  get user() {
    return User.toJson();
  }

  get deepLoginTokenIsSet() {
    return User.getProperty("deepLoginToken") !== null;
  }

  get validateUsername() {
    return !validator.isEmpty(this.usernameVal);
  }

  get validatePassword() {
    return !validator.isEmpty(this.passwordVal);
  }

  get resetToken() {
    return router.app.$route.query.resettoken;
  }

  get headline() {
    return this.getSettingsAttribute("headline", true);
  }

  get subheadline() {
    return this.getSettingsAttribute("subheadline", true);
  }

  get username() {
    return this.getSettingsAttribute("username", true);
  }

  get username_hint() {
    return this.getSettingsAttribute("username_hint", true);
  }

  get password() {
    return this.getSettingsAttribute("password", true);
  }

  get password_hint() {
    return this.getSettingsAttribute("password_hint", true);
  }

  get reset_label() {
    return this.getSettingsAttribute("reset_label", true);
  }

  get reset_url() {
    return this.getSettingsAttribute("reset_url", true);
  }

  get login_label() {
    return this.getSettingsAttribute("login_label", true);
  }

  get login_url() {
    return this.getSettingsAttribute("login_url", true);
  }

  get register_text() {
    return this.getSettingsAttribute("register_text", true);
  }

  get register_label() {
    return this.getSettingsAttribute("register_label", true);
  }

  get register_url() {
    return this.getSettingsAttribute("register_url", true);
  }

  get success() {
    return this.getSettingsAttribute("success", true);
  }

  get error() {
    return this.getSettingsAttribute("error", true);
  }

  get always_show() {
    return this.getSettingsAttribute("always_show");
  }

  get hide_headline() {
    return this.getSettingsAttribute("hide_headline");
  }

  get hide_subheadline() {
    return this.getSettingsAttribute("hide_subheadline");
  }

  get display_type() {
    return (
      this.getSettingsAttribute("display_type") || this.DISPLAY_TYPE_HORIZONTAL
    );
  }

  async logout() {
    await LoginHandler.logout();
    window.location.href = config.getFrontendConfiguration().redirectRouteAfterLogout;
  }
}
