
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class MandatoryText extends BaseComponent {
  get mandatory_text() {
    return this.getSettingsAttribute("mandatory_text");
  }

  get button_text() {
    let btnText = this.getSettingsAttribute("button_text");
    return btnText === null ? "Schließen" : btnText;
  }

  get button_css_class() {
    let btnClass = this.getSettingsAttribute("button_css_class");
    return btnClass === null ? "btn btn-outline" : btnClass;
  }

  get onAcceptHandler() {
    let callback = this.getSettingsAttribute("onAccept");

    return () => {
      if (callback !== null) {
        callback();
      }
    };
  }
}
