
import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GlossaryItem extends BaseComponent {
  @Prop() isPopup!: boolean;
  @Prop() slug!: string;
  @Prop() useAccordionItems!: boolean;

  constructor() {
    super();
  }

  getComponentType() {
    return "glossary_items";
  }

  async mounted() {
    await super.mounted();
  }

  get keyword() {
    return this.getContentAttribute("keyword");
  }

  get itemKey() {
    return this.getContentAttribute("item_key");
  }

  get definition() {
    return this.getContentAttribute("definition");
  }

  get popupFooterContent() {
    return this.getContentAttribute("popup_footer_content");
  }

  get href() {
    return this.getContentAttribute("href");
  }
}
