
import { Component, Vue } from "vue-property-decorator";
import { EventBus, ACCORDION_CLICKED, ExecutionQueue, router } from "common";
import BaseComponent from "../base/BaseComponent.vue";

export enum ToggleAllState {
  OPEN = "OPEN",
  CLOSE = "CLOSE"
}

@Component
export default class Accordion extends BaseComponent {
  public ToggleAllState = ToggleAllState;
  public id!: string;
  public _itemIndices: any;
  /**
   * Keep track of items that have been toggled.
   */
  public toggledItems: string[];

  /** Keep track of what the toggle all button should do. */
  public toggleAllState: ToggleAllState;
  public _itemAnchors: any;
  public updated: any;
  public itemToScrollTo: any;

  constructor() {
    super();
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);
    this.updated = Date.now();
    this.itemToScrollTo = null;
    this.toggledItems = [];
    this.toggleAllState = ToggleAllState.OPEN;
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      if (router.app.$route.hash != "") {
        let itemToScrollTo = this.itemAnchors.find((a: string) => {
          return router.app.$route.hash == "#" + a;
        });

        if (itemToScrollTo) {
          this.itemToScrollTo = itemToScrollTo;
          ExecutionQueue.addElement(this.scrollToItem);
        }
      }
      let me = this;
      EventBus.$on(ACCORDION_CLICKED, (opts: { accordionId: any }) => {
        //@ts-ignore
        opts.elDataAnchor.forEach((accordionData: { dataset: string }) => {
          //@ts-ignore
          if (accordionData.dataset.anchor === opts.elementId) {
            //@ts-ignore
            me.itemToScrollTo = opts.elementId;
            ExecutionQueue.addElement(() => me.scrollToItem(accordionData));
            ExecutionQueue.execute();
          }
        });
      });
      this.itemToScrollTo = me.itemToScrollTo;
    });
  }

  getComponentType() {
    return "accordion_items";
  }

  getHeadlineIcon(idx: number) {
    return "/assets/accordion/chevr-" + this.itemIndices[idx] + ".svg";
  }

  toggleHeadlineIcon(idx: number) {
    let self = this;
    Object.keys(this._itemIndices).forEach(function(index) {
      if (parseInt(index) != idx) {
        self._itemIndices[index] = "down";
      }
    });

    this.updated = Date.now();
    this._itemIndices[idx] = this._itemIndices[idx] == "down" ? "up" : "down";
  }

  trackItemByExpandedState(idx: number) {
    const stringIndex = idx.toString();

    if (this.toggledItems.includes(stringIndex)) {
      // Remove the item if already in the array.
      this.toggledItems = this.toggledItems.filter(val => val !== stringIndex);
    } else {
      // Add the item.
      this.toggledItems.push(stringIndex);
    }

    // Change state if all items are expanded.
    if (this.toggledItems.length === this.content.length) {
      this.changeToggleState();
    }
  }

  handleToggleItem(idx: number) {
    this.toggleHeadlineIcon(idx);
    this.trackItemByExpandedState(idx);
  }

  /**
   * Update the state of the 'toggle all' button so we know of
   * which items to keep track of & which items to target.
   *
   * For 'CLOSE' - we must keep track of all open items in order to close them
   *
   * For 'OPEN' - we must keep track of all closed items in order to open them
   */
  changeToggleState() {
    this.toggledItems = [];
    if (this.toggleAllState === ToggleAllState.OPEN) {
      this.toggleAllState = ToggleAllState.CLOSE;
      for (let i = 0; i < this.content.length; i++) {
        this.toggledItems.push(`${i}`);
      }
    } else {
      this.toggleAllState = ToggleAllState.OPEN;
    }
  }

  getExpandBtnText() {
    return this.toggleAllState === ToggleAllState.OPEN
      ? this.expandAllButtonText
      : this.collapseAllButtonText;
  }

  get keepAllOpen() {
    return this.getSettingsAttribute("keep_all_open") || false;
  }

  get expandAllButton() {
    return this.getSettingsAttribute("has_expand_all_button");
  }

  get expandAllButtonText() {
    const text = this.getSettingsAttribute("expand_all_button_text");
    return text.trim().length > 0 ? text.trim() : "Expand all";
  }

  get collapseAllButtonText() {
    const text = this.getSettingsAttribute("collapse_all_button_text");
    return text.trim().length > 0 ? text.trim() : "Collapse all";
  }

  get itemIndices() {
    if (this.contentLoaded && this.updated) {
      if (this._itemIndices) {
        return this._itemIndices;
      } else {
        this._itemIndices = {};
        this.content.forEach((el: any, idx: any) => {
          this._itemIndices[idx] = "down";
        });
        return this._itemIndices;
      }
    }
    return {};
  }

  get itemAnchors() {
    if (this.contentLoaded && this.updated) {
      if (this._itemAnchors) {
        return this._itemAnchors;
      } else {
        this._itemAnchors = [];
        this.content.forEach((el: any) => {
          this._itemAnchors.push(el.anchor);
        });
        return this._itemAnchors;
      }
    }
    return [];
  }

  async mounted() {
    super.mounted();
  }

  scrollToItem(accordionData: any) {
    if (this.itemToScrollTo) {
      //@ts-ignore
      // eslint-disable-next-line
      let element = this.$refs[this.itemToScrollTo] ? (this.$refs[this.itemToScrollTo][0] as HTMLElement) : accordionData;
      if (element) {
        const scrollOffset =
          parseInt(this.getSettingsAttribute("scroll_offset"), 10) || 0;
        let elementButton = element.querySelector(
          'button[aria-expanded="false"]'
        );
        if (elementButton) {
          if (this.toggledItems.length < 1) {
            elementButton.click();
          }
        }

        let offsetTop =
          element.getBoundingClientRect().top + window.scrollY - scrollOffset;

        window.scrollTo(0, offsetTop);
      }
    }
  }
}
