import UserRepository from "../api/repositories/user/UserRepository";
import { ResultType } from "../api/util/Result";
import config, { FrontendContext } from "../config";
import TokenHandler, { LoginTokenInterface } from "../models/Token";
import User, {
  UserLoginError,
  UserLoginStatus,
  UserRoleName
} from "../models/User";
import router from "../router";
import { APP_LOGIN_HANDLED, EventBus } from "./EventBus";
import { Store } from "./Store";
import { Route } from "vue-router/types/router";
import Helper, { changeContextByRoute } from "./Helper";

export const LOGIN_ACCOUNT_LOCKED_MESSAGE =
  "Ihr Account ist gesperrt. Bitte benutzen Sie die 'Passwort vergessen' Funktion, um ein neues Passwort zu vergeben und den Account wieder zu entsperren.";
export const LOGIN_INVALID_ACCOUNT_MESSAGE = "Ungültige Anmeldeinformationen.";

interface LoginHandlerInterface {
  login(userData: any, callback?: any): any;
  handleLoginOnBootstrap(route?: Route): any;
  handleBatchLoginOnBootstrap(): any;
  loginWithToken(route?: Route): any;
  refreshLogin(): any;
  checkMayWatchOne(): boolean | Promise<boolean>;
  logout(): void;
  handleLockedAccount(failReponse: any): boolean;
  handleInvalidAccount(failReponse: any): boolean;
}

class LoginHandlerBasic {
  async login(userData: any, callback?: any) {
    if (userData) {
      let userLogin = await User.login(userData);
      if (userLogin && callback) {
        await callback();
      }

      return userLogin;
    }

    return false;
  }

  async loginWithToken(route?: Route) {
    let tokens: LoginTokenInterface[] = TokenHandler.getTokens(route);

    for (let token of tokens) {
      if (token.isValid()) {
        /* 1. Logout possible existing user (Ticket: #11089) */
        if (!User.hasUserLoginStatus(UserLoginStatus.DOCCHECK_PENDING)) {
          await User.logout();
        }

        /* 2. Try to log user in with token */
        let tokenLoginResult = await token.login(route);
        if (tokenLoginResult) {
          return true;
        }
      }
    }

    return false;
  }

  async refreshLogin() {
    /*
     * No login refresh necessary if the user
     * is only authorized via doccheck
     */
    if (User.hasUserLoginStatus(UserLoginStatus.DOCCHECK_ONLY)) {
      return;
    }

    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN)) {
      let result = await UserRepository.refreshLogin();
      if (result.type == ResultType.SUCCESS) {
        User.updateAndSaveToStorage({
          accessToken: result.value.access_token
        });
        await User.refreshSessions();
      } else {
        await User.logout();
      }
    } else {
      await User.logout();
    }
  }

  checkMayWatchOne(): boolean | Promise<boolean> {
    return true;
  }

  async logout() {
    await User.logout();
  }

  handleLockedAccount(failReponse: any): boolean {
    if (failReponse.raw && typeof failReponse.raw == "string") {
      const failedLoginData = JSON.parse(failReponse.raw);

      if (
        failedLoginData.message &&
        (failedLoginData.message == UserLoginError.HCP_ACCOUNT_LOCKED ||
          failedLoginData.message == UserLoginError.ACCOUNT_LOCKED)
      ) {
        return true;
      }
    }

    return false;
  }

  handleInvalidAccount(failReponse: any): boolean {
    if (failReponse.raw && typeof failReponse.raw == "string") {
      const failedLoginData = JSON.parse(failReponse.raw);

      if (
        failedLoginData.message &&
        failedLoginData.message == UserLoginError.HCP_INVALID_LOGIN
      ) {
        return true;
      }
    }

    return false;
  }
}

class LoginHandlerHCP extends LoginHandlerBasic
  implements LoginHandlerInterface {
  async handleLoginOnBootstrap(route?: Route) {
    // 1. try to login with token
    let loginWithTokenResult = await this.loginWithToken(route);

    // 2. if this was not successfull or not necessary, try to refresh existing login
    if (!loginWithTokenResult) {
      await this.refreshLogin();
    }

    EventBus.$emit(APP_LOGIN_HANDLED);

    // 3. refresh login every 14 minutes 50 seconds
    window.setInterval(() => {
      this.refreshLogin();
    }, 890000);

    // 4. redirect to return url if:
    // 4.1 user is logged in or doccheck authorized
    // 4.2 user DOES NOT have role GUEST
    if (
      !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) ||
      User.hasUserLoginStatus(UserLoginStatus.DOCCHECK_ONLY)
    ) {
      if (!User.hasUserRole(UserRoleName.GUEST)) {
        let returnUrl = Store.retrieveReturnUrl();
        if (returnUrl) {
          Store.deleteSavedReturnUrl();
          router.push(returnUrl);
        }
      } else {
        Store.deleteSavedReturnUrl();
      }
    }
  }

  // handleBatchLoginOnBootstrap() {
  //   throw new Error("Method not implemented.");
  // }
  async handleBatchLoginOnBootstrap() {
    this.refreshLogin();
    window.setInterval(() => {
      this.refreshLogin();
    }, 890000);
  }

  async checkMayWatchOne(): Promise<boolean> {
    if (User.hasUserRole(UserRoleName.GUEST)) {
      if (!User.getProperty("mayWatchOne")) {
        User.updateAndSaveToStorage({
          mayWatchOne: "i solemnly swear that i am up to no good."
        });
        return true;
      } else {
        await this.logout();
        Store.saveReturnUrl(router.currentRoute.fullPath);
        router.push("/nutzerkonto-anlegen");
        return false;
      }
    }
    return true;
  }
}

class LoginHandlerPatient extends LoginHandlerBasic
  implements LoginHandlerInterface {
  async handleLoginOnBootstrap(route?: Route) {
    // 1. try to login with token
    let loginWithTokenResult = await this.loginWithToken(route);

    // 2. if this was not successfull or not necessary, try to refresh existing login
    if (!loginWithTokenResult) {
      await this.refreshLogin();
    }

    EventBus.$emit(APP_LOGIN_HANDLED);

    // 3. refresh login every 14 minutes 50 seconds
    window.setInterval(() => {
      this.refreshLogin();
    }, 890000);

    // TODO - Route to returnUrl
  }

  async handleBatchLoginOnBootstrap() {
    this.refreshLogin();
    window.setInterval(() => {
      this.refreshLogin();
    }, 890000);
  }
}

class LoginHandlerFactory {
  private static instance?: LoginHandlerInterface;

  static getInstance() {
    if (!this.instance) {
      changeContextByRoute(config);
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.PATIENT:
          this.instance = new LoginHandlerPatient();
          break;
        case FrontendContext.HCP:
        default:
          this.instance = new LoginHandlerHCP();
          break;
      }
    }

    return this.instance;
  }
}

export default LoginHandlerFactory.getInstance();
