
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { LayoutComponent } from "common";
import BaseComponentDynamic from "../base/BaseComponentDynamic";
import Slider from "../../mixins/Slider";

// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class DynamicArticleSlider extends mixins(
  Slider,
  BaseComponentDynamic
) {
  constructor() {
    super();
    this.collection = "view_widgets";
    this.limit = parseInt(this.pagingLimit);
  }

  async mounted() {
    await this.mountedHook();
    this.setCarouselOptions(this.carouselOptionsFromSettings);

    // // The `spacePadding` prop will be used only on desktop.
    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  beforeDestroy() {
    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }
  }

  @Watch("allArticlesFinishedLoading")
  focusCarousel() {
    (this.$refs.carousel as any).computeCarouselWidth();
  }

  getArticle(rawWidget: any) {
    let widget: any = {
      component: "article_widget",
      settings: {
        content: rawWidget,
        type: "vertical"
      }
    };
    return new LayoutComponent(widget);
  }

  get carouselOptionsFromSettings() {
    return this.getSettingsAttribute("carousel_options");
  }

  get pagingLimit() {
    return this.getSettingsAttribute("paging_limit");
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }
}
