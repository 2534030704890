
import { Component, Prop, Vue } from "vue-property-decorator";
import { Filter } from "./LcSearchResults.vue";
import { AlgoliaResponse } from "common";

@Component
export default class SearchFiltersCancer360 extends Vue {
  @Prop() filters!: Filter[];
  @Prop() activeFilterItems!: Filter[];
  @Prop() applyFilter!: (filterItem: Filter) => void;
  @Prop() toggleFilter!: (filter: Filter) => void;
  @Prop() toggleShowMore!: (filter: Filter) => void;
  @Prop() algoliaResponse!: AlgoliaResponse;
  @Prop() displayingData!: Record<string, string>;
  public isOpen: boolean;

  constructor() {
    super();
    this.isOpen = false;
  }

  public onFilterClick(): void {
    this.isOpen = true;
    console.log(this.filters, this.algoliaResponse, this.displayingData);
  }

  public doClose(): void {
    this.isOpen = false;
  }
}
