import ContentRepository from "../api/repositories/ContentRepository";
import { ResultType } from "../api/util/Result";
import config, { FrontendModules } from "../config";
import MenuItem from "../models/MenuItem";
import router from "../router";
import { Store } from "../util/Store";

const PAGES_CONFIGURATION = {
  "/": "",
  // === Level 1 ===
  "cancer-types": "home",
  // === Level 2 ===
  "biliary-tract-cancer": "cancer-types",
  // === Level 2 ===
  "blood-cancer": "cancer-types",
  // === Level 3 ===
  "blood-cancer/mantle-cell-lymphoma": "blood-cancer",
  // === Level 3 ===
  "blood-cancer/chronic-lymphocytic-leukemia-small-lymphocytic-lymphoma":
    "blood-cancer",
  // === Level 2 ===
  "breast-cancer": "cancer-types",
  // === Level 3 ===
  "breast-cancer/germline-brca-mutated": "breast-cancer",
  // === Level 3 ===
  "breast-cancer/metastatic-breast-cancer": "breast-cancer",
  // === Level 3 ===
  "breast-cancer/low-metastatic-breast-cancer": "breast-cancer",
  // === Level 2 ===
  "liver-cancer": "cancer-types",
  // === Level 2 ===
  "lung-cancer": "cancer-types",
  // === Level 3 ===
  "lung-cancer/non-small-cell-lung-cancer": "lung-cancer",
  // === Level 3 ===
  "lung-cancer/small-cell-lung-cancer": "lung-cancer",
  // === Level 2 ===
  "ovarian-cancer": "cancer-types",
  // === Level 2 ===
  "pancreatic-cancer": "cancer-types",
  // === Level 2 ===
  "prostate-cancer": "cancer-types",
  // === Level 2 ===
  "stomach-cancer": "cancer-types",
  // === Level 1 ===
  "cancer-support": "home",
  // cancer-basics  === Level 2 ===
  // === Level 3 ===
  faq: "cancer-support",
  // === Level 3 ===
  "is-cancer-genetic": "cancer-support",
  // === Level 3 ===
  "genetic-testing-for-cancer": "cancer-support",
  // === Level 3 ===
  "cancer-and-clinical-trials": "cancer-support",
  // === Level 3 ===
  "participating-in-a-clinical-trial": "cancer-support",
  // wellness-and-nutrition  === Level 2 ===
  // === Level 3 ===
  "nutrition-and-cancer": "cancer-support",
  // === Level 3 ===
  "how-to-tell-people-you-have-cancer": "cancer-support",
  // === Level 3 ===
  "dealing-with-emotions-of-cancer": "cancer-support",
  // cancer-treatments-support  === Level 2 ===
  // === Level 3 ===
  "creating-your-cancer-treatment-plan": "cancer-support",
  // === Level 3 ===
  "manage-your-cancer-care": "cancer-support",
  // === Level 3 ===
  "facing-cancer-treatment-alone": "cancer-support",
  // managing-cancer-symptoms  === Level 2 ===
  "the-basics-of-cancer-pain": "cancer-support",
  // === Level 3 ===
  "finding-relief-from-cancer-pain": "cancer-support",
  // === Level 3 ===
  "coping-with-cancer-fatigue": "cancer-support",
  // === Level 2 ===
  "stories-of-hope": "cancer-support",
  // === Level 3 ===
  "daves-story": "stories-of-hope",
  // === Level 3 ===
  "stephanies-story": "stories-of-hope",
  // === Level 3 ===
  "racheles-story": "stories-of-hope",
  // === Level 1 ===
  treatments: "home",
  // === Level 1 ===
  "financial-assistance": "home",
  // === Level 1 - Others ===
  "about-us": "home",
  Search: "home",
  "package-view": "home",
  "getbodychecked": ""
};

enum AZ_US_APP_BRAND {
  HCP = "hcp_hub",
  CS360 = "cancersupport360_hub"
}

export default class Tealium {
  private static instance?: Tealium;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Tealium();
    }

    return this.instance;
  }

  initialize() {
    /*
     * Check if Tealium should be integrated by frontend
     */
    if (
      config
        .getFrontendConfiguration()
        .modules.includes(FrontendModules.TEALIUM)
    ) {
      let tealiumSpecifier = process.env.VUE_APP_TEALIUM_SPECIFIER;
      let pageName = router.currentRoute.path;
      let pageSlug = router.currentRoute.path;

      if (pageName == "/") {
        pageSlug = "home";
        pageName = "home";
      } else if (pageName.length > 1) {
        pageSlug = pageSlug.substring(1);
        pageName = pageName.substring(1).replace("/", "_");
      }

      const pageSection: string = Object.keys(PAGES_CONFIGURATION).includes(
        pageSlug
      )
        ? (PAGES_CONFIGURATION as any)[pageSlug]
        : "";

      if (tealiumSpecifier && tealiumSpecifier.trim() != "") {
        let tealiumHead = document.createElement("script");
        tealiumHead.setAttribute(
          "src",
          "//tags.tiqcdn.com/utag/" + tealiumSpecifier + "/utag.sync.js"
        );

        let tealium01 = document.createElement("script");
        tealium01.type = "text/javascript";
        tealium01.text = `
          var utag_data = {
            page_type: "Vue",
            page_name: "${pageName}",
            page_section: "${pageSection}",
            event_type: "View",
            event_name: "View ${pageName}",
            tealium_event: "page view"
          };
          window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
          window.utag_cfg_ovrd.noview = true;
        `;

        let tealium02 = document.createElement("script");
        tealium02.type = "text/javascript";
        tealium02.text =
          "(function(a,b,c,d){" +
          "a='//tags.tiqcdn.com/utag/" +
          tealiumSpecifier +
          "/utag.js';" +
          "b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;" +
          "a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);" +
          "})();";

        // Insert head script into the head section
        document.head.appendChild(tealiumHead);

        // Insert both scripts right after the opening body tag
        document.body.insertBefore(tealium01, document.body.firstChild);
        tealium01!.parentNode!.insertBefore(tealium02, tealium01.nextSibling);

        const initialTealiumLoadInterval = setInterval(() => {
          if ((window as any)?.utag === undefined) {
            return;
          }

          (window as any)?.utag?.view({
            page_type: "Vue",
            page_name: pageName,
            event_type: "View",
            event_name: "View " + pageName,
            tealium_event: "page view",
            ...(Store.appBrand === AZ_US_APP_BRAND.CS360
              ? {
                  page_section: pageSection
                }
              : {})
          });

          clearInterval(initialTealiumLoadInterval);
        }, 100);
      }
    }
  }

  update() {
    if (
      config
        .getFrontendConfiguration()
        .modules.includes(FrontendModules.TEALIUM)
    ) {
      let pageName = router.currentRoute.path;
      let pageSlug = router.currentRoute.path;

      if (pageName == "/") {
        pageSlug = "home";
        pageName = "home";
      } else if (pageName.length > 1) {
        pageSlug = pageSlug.substring(1);
        pageName = pageName.substring(1).replace("/", "_");
      }

      const pageSection: string = Object.keys(PAGES_CONFIGURATION).includes(
        pageSlug
      )
        ? (PAGES_CONFIGURATION as any)[pageSlug]
        : "";

      (window as any)?.utag?.view({
        page_type: "Vue",
        page_name: pageName,
        event_type: "View",
        event_name: "View " + pageName,
        tealium_event: "page view",
        ...(Store.appBrand === AZ_US_APP_BRAND.CS360
          ? {
              page_section: pageSection
            }
          : {})
      });
    }
  }

  // update() {
  //   if (
  //     config
  //       .getFrontendConfiguration()
  //       .modules.includes(FrontendModules.TEALIUM)
  //   ) {
  //     //@ts-ignore
  //     if (typeof utag_data !== typeof undefined && utag_data) {
  //       let pageName = router.currentRoute.path;

  //       if (pageName == "/") {
  //         pageName = "home";
  //       } else if (pageName.length > 1) {
  //         pageName = pageName.substring(1).replace("/", "_");
  //       }

  //       //@ts-ignore
  //       utag_data.page_name = pageName;
  //     }
  //   }
  // }
}
