
import { Component } from "vue-property-decorator";
import validator from "validator";
import { ExpertForumRepository, router, Store, LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumPatientLogin extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;
  public username: string;
  public password: string;
  public apiError: boolean;
  public resultMessage: string;
  public requestSent: boolean;
  public website: string;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.username = "";
    this.password = "";
    this.apiError = false;
    this.resultMessage = "";
    this.requestSent = false;
    this.website = Store.frontend;
  }

  async mounted() {
    super.mounted();
  }

  get forgotPasswordModal() {
    return this.getSettingsAttribute("forgot_password_modal");
  }

  showModal() {
    Store.modal.title = "Passwort vergessen";
    Store.modal.className = `modal-lg modal-dialog--bg-primary`;
    Store.modal.showCloseButton = true;
    Store.modal.setLayoutComponent(
      new LayoutComponent({
        component: "ExpertforumPatientForgotPassword",
        settings: {
          visibility: true,
          width: 12
        }
      })
    );

    Store.modal.showModal();
  }

  get validateUsername() {
    return !validator.isEmpty(this.username);
  }

  get validatePassword() {
    return !validator.isEmpty(this.password);
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateUsername && this.validatePassword) {
      this.sendRequest(this.username, this.password, this.website);
    } else {
      this.focusFirstError();
    }
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
      this.requestSent = false;
      this.isLoading = false;
    }, 1);
  }

  async sendRequest(username: string, password: string, website: string) {
    let result = await ExpertForumRepository.login(username, password, website);
    this.requestSent = true;
    this.isLoading = false;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      this.apiError = false;
      this.resultMessage = "Einloggen mit Erfolg";

      sessionStorage.setItem(
        `expert-forum-${this.website}`,
        result.value.token
      );

      sessionStorage.setItem(
        `expert-forum-${this.website}-user`,
        result.value.username
      );

      window.location.href = `/my-account`;
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
