
import { Component } from "vue-property-decorator";
import { EventRepository, ResultType, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class RegisterModal extends BaseComponent {
  public isLoading!: boolean;
  public resultMessage!: any;

  constructor() {
    super();
    this.isLoading = false;
    this.resultMessage = null;
  }

  get title() {
    return this.getSettingsAttribute("title");
  }

  get firstName() {
    return this.getSettingsAttribute("firstName");
  }

  get lastName() {
    return this.getSettingsAttribute("lastName");
  }

  get email() {
    return this.getSettingsAttribute("email");
  }

  get e() {
    return this.getSettingsAttribute("event");
  }

  registerForEvent() {
    this.isLoading = true;
    this.sendRegister();
  }

  async sendRegister() {
    let result = await EventRepository.sendEventRegister(this.e.id);

    if (Store.matomoEnabled()) {
      // @ts-ignore
      window._paq.push([
        "trackEvent",
        // @ts-ignore
        process.env.VUE_APP_MATOMO_EVENT_CATEGORY,
        "Register_Event",
        this.e.id
      ]);
    }

    if (result.type == ResultType.SUCCESS) {
      this.resultMessage = "Sie wurden erfolgreich angemeldet.";
    } else {
      switch (result.statusCode) {
        case 500:
          this.resultMessage = "Es ist ein unbekannter Fehler aufgetreten";
          break;
        case 400:
          this.resultMessage = "Es liegt bereits eine Registrierung vor";
          break;
      }
    }
    this.isLoading = false;
  }
}
