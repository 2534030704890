
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  @Prop({ default: 0 }) progress!: number;

  get progress_width() {
    return this.progress * 100;
  }
}
