var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.contentLoaded)?_c('div',[_c('div',{staticClass:"skeleton__interact"})]):_c('div',{ref:"interactive",staticClass:"interact-when-in-view",class:[
    _vm.layoutComponent.class,
    {
      visible: _vm.isInViewport,
      'playstate-paused': !_vm.userIsScrolling
    }
  ],style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType,"data-id":_vm.internalId}},_vm._l((_vm.getLayoutComponentsFromLayout(_vm.layout)),function(comp,idx){return _c(comp.component,{key:idx,tag:"component",attrs:{"layoutComponent":comp}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }