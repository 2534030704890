
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { LayoutComponent } from "common";
import BaseComponentDynamic from "../base/BaseComponentDynamic";
import Slider from "../../mixins/Slider";

// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class DynamicEditorsPickAndTeaserWidgetSlider extends mixins(
  Slider,
  BaseComponentDynamic
) {
  //loading!: boolean;

  carouselOptions: any = {
    perPage: 1,
    perPageCustom: null,
    mouseDrag: true,
    paginationEnabled: false,
    navigationEnabled: true,
    navigationNextLabel: "<i class='fas fa-chevron-right fa-lg'></i>",
    navigationPrevLabel: "<i class='fas fa-chevron-left fa-lg'></i>",
    spacePadding: 0,
    autoplay: true,
    autoplayTimeout: 5000,
    loop: true,
    viewWidgetCssClass: "col-12 col-md-9",
    teaserCssClass: "col-12 col-md-3"
  };

  constructor() {
    super();
    this.collection = "dynamic_editors_pick_and_teaser_widget_sliders";
    this.limit = parseInt(this.pagingLimit);
    this.fill = false;
  }

  async mounted() {
    await this.mountedHook();
    this.setCarouselOptions(this.carouselOptionsFromSettings);
  }

  getComponentType() {
    return this.collection;
  }

  // get userSegments() {
  //   if (!User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
  //     const segments = User.getProperty("segments");
  //     return segments.map((item: any) => item.therapeuticArea);
  //   }

  //   return null;
  // }

  getTeaserComponent(teaser: any) {
    return LayoutComponent.createFromCollectionItem("teaserWidget", teaser);
  }

  getViewWidgetComponent(widget: any) {
    return LayoutComponent.createFromCollectionItem("view_widget", widget);
  }

  get carouselOptionsFromSettings() {
    return this.getSettingsAttribute("slider_options");
  }

  get pagingLimit() {
    return this.getSettingsAttribute("paging_limit");
  }
}
