
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { LayoutComponent } from "common";

export enum Type {
  SCALE = "scale",
  TEXTAREA = "textarea"
}

export enum Layout {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
  LABEL_TOP = "label_top"
}

interface QuestionState {
  questionId: string | null;
  answerId: string | null;
  isValid: boolean;
  value: string | null;
}

@Component
export default class FeedbackFormQuestion extends BaseComponent {
  @Prop() shouldReset!: boolean;
  @Prop() shouldValidate!: boolean;

  public Type = Type;
  private Layout = Layout;
  public type!: Type;
  private layout!: Layout;
  public groupedChecks: Array<any>;
  public selectedAnswer: QuestionState;
  public textareaModel: string = "";
  public readonly validateErrorMessage: string =
    "Bitte wählen Sie eine Antwort!";

  constructor() {
    super();
    this.groupedChecks = [];
    this.type = Type.SCALE;
    this.layout = Layout.HORIZONTAL;
    this.selectedAnswer = {
      questionId: null,
      answerId: null,
      isValid: false,
      value: null
    };
  }

  getComponentType() {
    return "feedback_form_questions";
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.type = this.questionType;
      this.layout = this.layoutComponent.settings.layout;
      this.selectedAnswer = {
        questionId: this.id,
        answerId: this.defaultInputValue,
        isValid: !this.required,
        value: null
      };
      this.$emit("initQuestion", { ...this.selectedAnswer });
    });
  }

  async mounted() {
    super.mounted();
  }

  // Map Cockpit props
  get questionText() {
    let text = this.getContentAttribute("question");
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }
    return null;
  }

  get id() {
    return this.getContentAttribute("_id");
  }

  get questionType() {
    return this.getContentAttribute("type");
  }

  get required() {
    const isRequired = this.getSettingsAttribute("required");
    return isRequired === null ? true : isRequired;
  }

  get answers(): Array<LayoutComponent> {
    const result: Array<LayoutComponent> = [];
    const answers = this.getContentAttribute("answers");

    if (answers && answers.length > 0) {
      answers.forEach((item: any) => {
        result.push(new LayoutComponent(item));
      });
    }

    return result;
  }

  getAnswerId(index: number): string | null {
    const answers = this.getContentAttribute("answers");
    if (answers && answers.length > 0) {
      return answers[index].settings.content._id;
    }

    return null;
  }

  get footerLeftLabel() {
    return this.getContentAttribute("footer_left_label");
  }

  get footerRightLabel() {
    return this.getContentAttribute("footer_right_label");
  }

  // Business logic
  get answerClassObject() {
    return {
      "form-check-inline": this.layout === Layout.HORIZONTAL,
      "form-group": this.layout === Layout.VERTICAL,
      "form-check-inline label-top": this.layout === Layout.LABEL_TOP
    };
  }

  onCheckSelect(e: any) {
    this.groupedChecks = [];

    if (e.target.checked) {
      this.groupedChecks.push(e.target.value);
    }

    this.selectedAnswer = {
      ...this.selectedAnswer,
      answerId: this.getAnswerId(parseInt(e.target.value, 10)),
      isValid: this.required ? !!this.groupedChecks.length : true,
      value: e.target.value
    };
    this.$emit("selectAnswer", { ...this.selectedAnswer });
  }

  onInputChange() {
    this.selectedAnswer = {
      ...this.selectedAnswer,
      answerId: this.textareaModel,
      isValid: this.required ? !!this.textareaModel.length : true,
      value: this.textareaModel
    };
    this.$emit("selectAnswer", { ...this.selectedAnswer });
  }

  @Watch("shouldReset")
  resetInput() {
    this.groupedChecks = [];
    this.textareaModel = "";
    this.selectedAnswer = {
      ...this.selectedAnswer,
      answerId: this.defaultInputValue,
      isValid: !this.required,
      value: null
    };

    this.$emit("initQuestion", { ...this.selectedAnswer });
  }

  @Watch("shouldValidate")
  validateInputs() {}

  // Utils
  get defaultInputValue() {
    return this.required ? null : "";
  }
}
