
import { Component } from "vue-property-decorator";
import validator from "validator";
import { ExpertForumRepository, Store, matomo, MatomoEventNames } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumPatientRegister extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;

  public email: string;
  public username: string;
  public password: string;
  public confirmPassword: string;

  public errorEmail: string;
  public errorPassword: string;
  public apiError: boolean;
  public resultMessage: string;
  public requestSent: boolean;
  public accountCreated: boolean;
  public passwordOptions: any;

  public checkboxFirst: any;
  public checkboxSecond: any;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.email = "";
    this.username = "";
    this.password = "";
    this.confirmPassword = "";
    this.errorEmail = "";
    this.errorPassword = "";
    this.apiError = false;
    this.resultMessage = "";
    this.requestSent = false;
    this.passwordOptions = {
      minLength: 10,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1
    };
    this.checkboxFirst = false;
    this.checkboxSecond = false;
    this.accountCreated = false;
  }

  async mounted() {
    super.mounted();
  }

  get infoTextFirst(): string {
    return this.getSettingsAttribute("info_text_1");
  }
  get checkboxTextFirst(): string {
    return this.getSettingsAttribute("checkbox_text_1");
  }
  get infoTextSecond(): string {
    return this.getSettingsAttribute("info_text_2");
  }
  get checkboxTextSecond(): string {
    return this.getSettingsAttribute("checkbox_text_2");
  }

  get validateEmail() {
    if (validator.isEmpty(this.email) || !validator.isEmail(this.email)) {
      this.errorEmail = "Bitte geben Sie eine gültige E-Mail Adresse an.";
    } else {
      this.errorEmail = "";
    }

    return !validator.isEmpty(this.email) && validator.isEmail(this.email);
  }

  get validateUsername() {
    return !validator.isEmpty(this.username);
  }

  get validatePassword() {
    if (
      validator.isEmpty(this.password) ||
      !validator.isStrongPassword(this.password, this.passwordOptions)
    ) {
      this.errorPassword =
        "Das Passwort muss mindestens 10 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten";
    } else {
      this.errorPassword = "";
    }

    return (
      !validator.isEmpty(this.password) &&
      validator.isStrongPassword(this.password, this.passwordOptions)
    );
  }

  get validateConfirmPassword() {
    if (this.checkboxTextFirst) {
      return this.checkboxFirst;
    }

    return true;
  }

  get validateCheckboxFirst() {
    if (this.checkboxTextSecond) {
      return this.checkboxSecond;
    }

    return true;
  }

  get validateCheckboxSecond() {
    return (
      !validator.isEmpty(this.confirmPassword) &&
      validator.equals(this.confirmPassword, this.password)
    );
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (
      this.validateEmail &&
      this.validateUsername &&
      this.validatePassword &&
      this.validateConfirmPassword &&
      this.validateCheckboxFirst &&
      this.validateCheckboxSecond
    ) {
      const website = Store.frontend;
      this.sendRequest(this.email, this.username, this.password, website);
    } else {
      this.focusFirstError();
    }
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
      this.requestSent = false;
      this.isLoading = false;
    }, 1);
  }

  async sendRequest(
    email: string,
    username: string,
    password: string,
    website: string
  ) {
    let result = await ExpertForumRepository.register(
      email,
      username,
      password,
      website
    );
    this.requestSent = true;
    this.isLoading = false;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      // Matomo tracking
      matomo.genericEvent(
        this.username,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.EXPERT_FORUM_REGISTER
      );

      this.resultMessage =
        "Vielen Dank für Ihre Registrierung, bitte bestätigen Sie diese mit einem Klick auf die E-Mail, die wir Ihnen soeben gesendet haben (bitte überprüfen Sie auch Ihren SPAM Ordner)​";
      this.email = "";
      this.username = "";
      this.password = "";
      this.confirmPassword = "";
      this.checkboxFirst = false;
      this.checkboxSecond = false;
      this.apiError = false;
      this.attemptSubmit = false;
      this.accountCreated = true;
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
