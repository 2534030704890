
import { Component, Vue } from "vue-property-decorator";
import { LayoutComponent, Store, ExecutionQueue } from "common";
import BaseComponent from "../base/BaseComponent.vue";
// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

export enum EventType {
  MOUSE_ENTER = "mouseenter",
  MOUSE_LEAVE = "mouseleave",
  CLICK = "click"
}

export enum NavActionType {
  PREV = "previous",
  NEXT = "next"
}

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class Slider extends BaseComponent {
  private EventType = EventType;
  private NavActionType = NavActionType;
  readonly GRID_MD_BREAKPOINT = 768;

  public currentSlideNum: number = 0;

  private initialSpacePadding = 0;
  public carouselOptions: any = {
    perPage: 1,
    perPageCustom: [
      [768, 2],
      [1024, 3]
    ],
    mouseDrag: true,
    paginationEnabled: false,
    paginationPadding: 10,
    paginationSize: 10,
    paginationColor: "#efefef",
    paginationActiveColor: "#000000",
    navigationEnabled: true,
    navigationNextLabel: "<i class='fas fa-chevron-right fa-lg'></i>",
    navigationPrevLabel: "<i class='fas fa-chevron-left fa-lg'></i>",
    spacePadding: 0,
    spacePaddingMaxOffsetFactor: 0,
    autoplay: false,
    autoplayTimeout: 5000,
    loop: true,
    adjustableHeight: false,
    navigationClickTargetSize: 8,
    scrollPerPage: true
  };

  // Slide preview
  private slidePreviewOffset: number = 0.3; // number between 0 and 1
  private carouselRef!: any;
  private navNextBtnEl!: HTMLElement;
  private navPrevBtnEl!: HTMLElement;
  private previewPrevActive: boolean = false;
  private previewNextActive: boolean = false;

  getComponentType() {
    return "sliders";
  }

  async mounted() {
    super.mounted();
    this.setCarouselOptions();
    setTimeout(() => {
      if (this.carouselRef) {
        this.carouselRef.onResize();
        this.carouselRef.goToPage(0);
      }
      ExecutionQueue.addElement(this.scrollSliderToLeft);
    }, 250);

    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  scrollSliderToLeft() {
    if(document.getElementById("carousel-left")){
      document.getElementById("carousel-left").getElementsByClassName("VueCarousel")[0].getElementsByClassName("VueCarousel-wrapper")[0].scrollLeft += 220;
    }
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.carouselRef = this.$refs.carousel as any;
      // Slide preview
      if (
        this.slidePreviewEnabled &&
        this.carouselOptions.navigationEnabled &&
        this.carouselRef
      ) {
        if (this.previewOffsetValue) {
          this.slidePreviewOffset = this.previewOffsetValue;
        }

        const navButtons = this.carouselRef.$el.querySelectorAll(
          "button.VueCarousel-navigation-button"
        );
        this.navPrevBtnEl = navButtons[0];
        this.navNextBtnEl = navButtons[1];

        // Attach slide preview event listeners to the navigation buttons
        [EventType.MOUSE_ENTER, EventType.MOUSE_LEAVE, EventType.CLICK].forEach(
          (event: any) => {
            this.navPrevBtnEl.addEventListener(event, this.prevBtnListener);
          }
        );

        [EventType.MOUSE_ENTER, EventType.MOUSE_LEAVE, EventType.CLICK].forEach(
          (event: any) => {
            this.navNextBtnEl.addEventListener(event, this.nextBtnListener);
          }
        );
      }
    });
  }

  beforeDestroy() {
    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }

    // Remove slide preview event listeners
    if (this.slidePreviewEnabled && this.carouselOptions.navigationEnabled) {
      [EventType.MOUSE_ENTER, EventType.MOUSE_LEAVE, EventType.CLICK].forEach(
        (event: any) => {
          this.navPrevBtnEl.removeEventListener(event, this.prevBtnListener);
        }
      );

      [EventType.MOUSE_ENTER, EventType.MOUSE_LEAVE, EventType.CLICK].forEach(
        (event: any) => {
          this.navNextBtnEl.removeEventListener(event, this.nextBtnListener);
        }
      );
    }
  }

  /************************************/
  // Map Cockpit props

  get filteredSliderItems() {
    let sliderItems = this.getContentAttribute("slider_items");

    if (sliderItems && sliderItems.length > 0) {
      return sliderItems.filter((layoutComponent: LayoutComponent) => {
        return layoutComponent && layoutComponent.settings.visibility;
      });
    }

    return [];
  }

  get sliderItemsNo() {
    return this.filteredSliderItems.length;
  }

  get sliderItems() {
    return this.filteredSliderItems.map(
      (layoutComponent: LayoutComponent) => new LayoutComponent(layoutComponent)
    );
  }

  get nextSlideBtnText() {
    const nextBtnText = this.getSettingsAttribute("additional_next_btn_text");
    return nextBtnText ? nextBtnText.trim() : null;
  }

  get slidePreviewEnabled() {
    return !!this.getSettingsAttribute("slide_preview");
  }

  // Offset must be a number between 0 and 1 (default: 0.3)
  get previewOffsetValue() {
    const offset = this.getSettingsAttribute("slide_preview_offset");
    if (offset && offset.trim().length) {
      return this.formatToTwoDecimal(parseInt(offset, 10) / 100);
    }
    return null;
  }

  /************************************/
  // Utils

  navigateToSlide() {
    if (this.currentSlideNum < this.sliderItemsNo - 1) {
      this.currentSlideNum++;
    } else {
      this.currentSlideNum = 0;
    }
  }

  pageChangeHandler(slideNum: number) {
    this.currentSlideNum = slideNum;
  }

  setCarouselOptions() {
    const options = this.getSettingsAttribute("slider_options");
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }

    this.carouselOptions.spacePadding =
      window.innerWidth < this.GRID_MD_BREAKPOINT
        ? 1
        : this.initialSpacePadding;
  }

  get sliderClasses() {
    return {
      [`active-slide-${this.currentSlideNum}`]: Number.isInteger(
        this.currentSlideNum
      ),
      "preview-prev": this.previewPrevActive,
      "preview-next": this.previewNextActive
    };
  }

  formatToTwoDecimal(num: number) {
    if (!isNaN(num)) {
      return Math.round(num * 100) / 100;
    }
    return;
  }

  /**
   * Translate the slider to the previous or next slide when hovering the navigation buttons
   * @param event
   * @param action
   */
  slidePreviewHandler(ev: any, action: NavActionType) {
    const previewIsActive = !Number.isInteger(this.currentSlideNum);
    switch (ev.type) {
      // On mouse enter event
      case EventType.MOUSE_ENTER:
        if (action === NavActionType.PREV && this.currentSlideNum > 0) {
          this.previewPrevActive = true;
          this.carouselRef.goToPage(
            this.formatToTwoDecimal(
              this.currentSlideNum - this.slidePreviewOffset
            )
          );
        } else if (
          action === NavActionType.NEXT &&
          this.currentSlideNum < this.sliderItemsNo - 1
        ) {
          this.previewNextActive = true;
          this.carouselRef.goToPage(
            this.formatToTwoDecimal(
              this.currentSlideNum + this.slidePreviewOffset
            )
          );
        }
        break;
      // On mouse leave event
      case EventType.MOUSE_LEAVE:
        this.previewPrevActive = false;
        this.previewNextActive = false;

        if (
          action === NavActionType.PREV &&
          this.currentSlideNum > 0 &&
          previewIsActive
        ) {
          this.carouselRef.goToPage(
            this.formatToTwoDecimal(
              this.currentSlideNum + this.slidePreviewOffset
            )
          );
        } else if (action === NavActionType.NEXT && previewIsActive) {
          this.carouselRef.goToPage(
            this.formatToTwoDecimal(
              this.currentSlideNum - this.slidePreviewOffset
            )
          );
        }
        break;
      case EventType.CLICK:
        /**
         * This is a specific usecase for Brustkrebs hero sliders only
         * We might need to find a better solution here in the future
         */
        if (
          Store.isBreastcancer() &&
          this.$el.parentElement &&
          this.$el.parentElement.className === "hero-texts" &&
          this.carouselRef.getBrowserWidth() >= 768
        ) {
          if (action === NavActionType.NEXT) {
            this.carouselRef.goToPage(this.slidePreviewOffset);
          } else {
            this.carouselRef.goToPage(0);
            this.previewPrevActive = false;
            this.previewNextActive = false;
          }
          /** End of BK specific usecase **/
        } else if (
          (action === NavActionType.PREV || action === NavActionType.NEXT) &&
          previewIsActive
        ) {
          this.previewPrevActive = false;
          this.previewNextActive = false;
          if (this.slidePreviewOffset >= 0.5) {
            this.carouselRef.goToPage(
              Math.floor(this.currentSlideNum - (1 - this.slidePreviewOffset))
            );
          } else {
            this.carouselRef.goToPage(
              Math.ceil(this.currentSlideNum - (1 - this.slidePreviewOffset))
            );
          }
        }
        break;
      default:
        break;
    }
  }

  prevBtnListener(ev: any) {
    this.slidePreviewHandler(ev, NavActionType.PREV);
  }

  nextBtnListener(ev: any) {
    this.slidePreviewHandler(ev, NavActionType.NEXT);
  }

  /************************************/
}
