
import { Component, Prop } from "vue-property-decorator";
import { EventBus, LCMODAL_CLOSED, LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";
import $ from "jquery";

@Component
export default class LcModal extends BaseComponent {
  @Prop({ default: false }) appendToLayoutContent!: boolean;

  public uid!: string;
  private visibilityObserver!: any;
  private contentVideosIds!: Array<string>;

  constructor() {
    super();
    this.uid = Math.random()
      .toString(36)
      .substr(2, 9);
    this.visibilityObserver = null;
    this.contentVideosIds = [];
  }

  getComponentType() {
    return "modals";
  }

  async mounted() {
    await super.mounted();

    /*
     * Use MutationObserver to watch modal visibility
     * If there are Kaltura videos in modal content, emit an event with video id's
     * Listem for this event in kaltura video component and stop the videos when modal is closed
     */
    this.visibilityObserver = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = (m.target as any).getAttribute(m.attributeName);
        this.$nextTick(() => {
          const classList = newValue.split(" ");
          if (!classList.includes("show") && this.contentVideosIds.length > 0) {
            EventBus.$emit(LCMODAL_CLOSED, this.contentVideosIds);
          }

          if (classList.includes("show")) {
            this.$emit("open");
          }
        });
      }
    });

    this.visibilityObserver.observe(this.$refs.modalDialog as HTMLElement, {
      attributes: true,
      attributeFilter: ["class"]
    });

    this.openModal();
  }

  beforeDestroy() {
    this.visibilityObserver.disconnect();
  }

  openModalFromProps() {
    $(`#modal-${this.uid}`)
      .appendTo("#layout-content")
      .modal("show");
  }

  private openModal() {
    // @ts-ignore
    const currentRoute = this.$router.currentRoute;

    if (currentRoute.path !== "/trixeo") {
      return;
    }

    if (!currentRoute.query.video) {
      return;
    }

    if (currentRoute.query.video != this.internalId) {
      return;
    }

    // @ts-ignore
    this.$refs.modalToggle.click();
  }

  get toggleBtnLabel() {
    return this.getSettingsAttribute("toggle_btn_label");
  }

  get toggleBtnClasses() {
    return this.getSettingsAttribute("toggle_btn_classes");
  }

  get headline() {
    let headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    return null;
  }

  get modalContent() {
    let content = this.getContentAttribute("content");
    if (content && content.length > 0) {
      return content
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => {
          if (
            comp.component === "kaltura_video" &&
            comp.settings.content.display &&
            comp.settings.content.display.length > 0
          ) {
            this.contentVideosIds.push(comp.settings.content.display);
          }

          return new LayoutComponent(comp);
        });
    }

    return null;
  }
}
