
import { Component, Vue } from "vue-property-decorator";
import { LayoutComponent, router } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class EditorsPickWidget extends BaseWidget {
  private slug: any = null;

  getComponentType() {
    return "view_widgets";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(async () => {
      let cockpitLink = this.getContentAttribute("cockpit_link");
      if (cockpitLink) {
        let slugResponse = await this.loadContent(
          cockpitLink.link,
          [cockpitLink._id],
          0,
          {
            slug: 1,
            _id: 0
          }
        );

        if (slugResponse) {
          this.slug = slugResponse[0].slug;
        }
      }
    });
  }

  get headline() {
    let headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    return null;
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get text() {
    let text = this.getContentAttribute("text");
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }
    return null;
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get link() {
    return "/" + this.slug;
  }

  gotoView() {
    if (this.slug) {
      router.push(this.link);
    }
  }
}
