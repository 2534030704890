
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class PrintPage extends BaseComponent {
  getComponentType() {
    return "";
  }

  async mounted() {
    await super.mounted();
  }

  get buttonLabel() {
    return this.getSettingsAttribute("button_label");
  }

  clickHandler() {
    window.print();
  }
}
