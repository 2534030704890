
import { Component, Vue } from "vue-property-decorator";
import { ExecutionQueue } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class InteractWhenInView extends BaseComponent {
  public isInViewport!: boolean;
  public userIsScrolling!: boolean;
  public countingElements!: HTMLElement[];
  public countingValues!: Array<string[]>;
  private countingTimeouts!: any[];

  constructor() {
    super();

    this.isInViewport = false;
    this.userIsScrolling = false;
    this.countingElements = [];
    this.countingValues = [];
    this.countingTimeouts = [];
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    Vue.nextTick(() => {
      if (this.$refs.interactive) {
        ExecutionQueue.addElement(this.saveCountingData);
      }

      const observer = new IntersectionObserver(this.handleElementInViewport);
      // @ts-ignore
      observer.observe(this.$refs.interactive);

      window.addEventListener("scroll", () => {
        this.userIsScrolling = true;
      });

      setInterval(() => {
        this.userIsScrolling = false;
      }, 300);
    });
  }

  extractNumber(text: string) {
    let stripHtmlTags = text.replace(/(<([^>]+)>)/gi, "");
    stripHtmlTags = stripHtmlTags.replace(/&nbsp;/gi, "");
    return stripHtmlTags.replace(/[^0-9,.]/g, "");
  }

  getCountingValues(child: HTMLElement): string[] {
    const currentValue = child.innerHTML;
    const extractedNumber = this.extractNumber(currentValue);
    const number = parseFloat(extractedNumber.replace(",", "."));
    const hasDecimal =
      extractedNumber.indexOf(",") > -1 || extractedNumber.indexOf(".") > -1;

    if (isNaN(number)) {
      return [];
    }

    const valuesToSet: string[] = [];
    const numberFormatter = new Intl.NumberFormat("de-DE");

    for (let i = 0; i <= 25; i++) {
      const numberToSave = parseFloat(
        ((number / 25) * i).toFixed(hasDecimal ? 1 : 0)
      );
      valuesToSet.push(numberFormatter.format(numberToSave));
    }

    valuesToSet.push(currentValue);
    return valuesToSet;
  }

  saveCountingData() {
    // @ts-ignore
    const countingValues = this.$refs.interactive.querySelectorAll(
      ".counting-animation"
    );
    if (countingValues.length > 0) {
      countingValues.forEach((el: HTMLElement) => {
        this.countingElements.push(el);
        this.countingValues.push(this.getCountingValues(el));
      });
    }
  }

  countingAnimation(
    child: HTMLElement,
    valuesToSet: string[],
    currentValue: string = "",
    numberToReplace: string = ""
  ) {
    valuesToSet.forEach((value, index) => {
      this.countingTimeouts.push(
        setTimeout(() => {
          child.innerHTML = currentValue.replace(numberToReplace, value);
          if (index === valuesToSet.length - 1) {
            child.innerHTML = valuesToSet[index];
          }
        }, index * 50)
      );
    });
  }

  checkForCoutingAnimations() {
    if (
      !this.$refs.interactive ||
      !this.countingElements.length ||
      !this.countingValues.length
    ) {
      return;
    }

    // Initial delay for the animation start
    let delayAnimationStart = 1000;

    this.countingElements.forEach((child: any, idx: number) => {
      const valuesToSet = this.countingValues[idx];
      const currentValue = child.innerHTML;
      const extractedNumber = this.extractNumber(currentValue);

      // Set numbers initially with 0
      child.innerHTML = currentValue.replace(extractedNumber, valuesToSet[0]);

      // Animate counting
      // Push counting timeouts to an array an clear timeouts once component goes out of view
      this.countingTimeouts.push(
        setTimeout(() => {
          this.countingAnimation(
            child,
            valuesToSet,
            currentValue,
            extractedNumber
          );
        }, delayAnimationStart)
      );

      // Update the delay for the next animation
      delayAnimationStart = delayAnimationStart + valuesToSet.length * 50;
    });
  }

  getComponentType() {
    return "view_interactables";
  }

  get layout() {
    return this.getContentAttribute("content");
  }

  handleElementInViewport(entries: any, observer: IntersectionObserver) {
    if (
      entries.some(
        // @ts-ignore
        (entry: IntersectionObserverEntry) => entry.isIntersecting === true
      )
    ) {
      this.isInViewport = true;
      this.checkForCoutingAnimations();
    } else {
      this.isInViewport = false;

      // Clear timeouts when component goes out of view
      this.countingTimeouts.forEach(item => clearTimeout(item));
    }
  }
}
