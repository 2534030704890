
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { isNil } from "lodash";
import { LayoutComponent } from "common";

@Component
export default class Divider extends Vue {
  @Prop() layoutComponent!: LayoutComponent;

  readonly HEIGHT_TINY = "tiny";
  readonly HEIGHT_SMALL = "small";
  readonly HEIGHT_MEDIUM = "medium";
  readonly HEIGHT_BIG = "big";
  readonly HEIGHT_HUGE = "huge";
  readonly HEIGHT_ENORMOUS = "enormous";

  get height() {
    return this.layoutComponent.settings.height;
  }

  get withLine() {
    return isNil(this.layoutComponent.settings.with_line)
      ? false
      : this.layoutComponent.settings.with_line;
  }
}
