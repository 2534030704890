import { sortBy } from "lodash";
import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";
import MenuItem from "../../models/MenuItem";
import { ResultType } from "../util/Result";
import User from "../../models/User";
import { RoutesMapping } from "../../util/RoutingHelper";

class ContentRepository extends AbstractRepository {
  /**
   * Get all routes for the current site (hcp_hub, brand_hub, patient_hub or cll_hub)
   *
   * TODO:
   * This methods is getting out of control. We should consider splitting route
   * loading to the frontends, so every frontend just loads all the routes
   * necessary for that specific frontend.
   *
   * @param appBrand
   */
  async getRoutes(appBrand: string) {
    const articlesRoutesURL = `${Constants.cockpitUrl}/routes/articles/${appBrand}`;
    const brandPagesRoutesURL = `${Constants.cockpitUrl}/routes/brand_pages/${appBrand}`;
    const pagesRoutesURL = `${Constants.cockpitUrl}/routes/pages/${appBrand}`;
    const eventsPagesRoutesURL = `${Constants.cockpitUrl}/routes/events_pages/${appBrand}`;

    let responseArticles = await Client.getJson(articlesRoutesURL, null);
    let responsePages = await Client.getJson(pagesRoutesURL, null);
    let responseBrandPages = await Client.getJson(brandPagesRoutesURL, null);
    let responseEventsPages = await Client.getJson(eventsPagesRoutesURL, null);

    const allRoutes: RoutesMapping = {
      articles: [],
      pages: [],
      brandPages: [],
      eventsPages: []
    };

    if (responseArticles.type == ResultType.SUCCESS) {
      allRoutes.articles = [...responseArticles.value];
    }

    if (responsePages.type == ResultType.SUCCESS) {
      allRoutes.pages = [...responsePages.value];
    }

    if (responseBrandPages.type == ResultType.SUCCESS) {
      allRoutes.brandPages = [...responseBrandPages.value];
    }

    if (responseEventsPages.type == ResultType.SUCCESS) {
      allRoutes.eventsPages = [...responseEventsPages.value];
    }

    return allRoutes;
  }

  /**
   * Get menu with given id
   * @param id
   */
  async getMenu(id: string) {
    let data = {
      filter: {
        id: id
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("menues"),
      data
    );

    let result = this.getResult<any, MenuItem[]>(response, (r: any) => {
      let menuItems: MenuItem[] = [];

      if (r.length > 0) {
        r[0].items.forEach((item: any) => {
          menuItems.push(new MenuItem(item));
        });
      }

      return menuItems;
    });
    return result;
  }

  /**
   * Get a single post
   * @param id
   * @param appBrand
   * @param type
   */
  async getContentById(id: string, appBrand: string, type: string) {
    let data = {
      filter: {
        _id: id,
        app_brand: { $in: [appBrand] }
      },
      simple: 1,
      populate: 0
    };

    if (User.getProperty("isInDraftMode")) {
      //@ts-ignore
      data.draft = true;
    }

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries(type),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      let post = r.length > 0 ? r[0] : null;
      return post;
    });
    return result;
  }

  /**
   * Get a single collection item by type and id
   * @param type
   * @param ids
   * @param populate
   * @param fields
   */
  async getCollectionItemByTypeAndId(
    type: string,
    ids: string[],
    populate: number,
    fields: any = null
  ) {
    let data: any = {
      filter: {
        _id: { $in: ids }
      },
      simple: 1,
      populate: populate
    };

    if (fields) {
      data.fields = fields;
    }

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries(type),
      data,
      this.getAdditionalHeader()
    );

    if (ids.length > 1 && response.type === ResultType.SUCCESS) {
      response.value = sortBy(response.value, (entry: any) => {
        return ids.indexOf(entry._id);
      });
    }

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r : null;
    });

    return result;
  }

  /**
   * Filter items by role and ids
   * @param type string
   * @param ids string[]
   * @param roles role[]
   * @returns collection items
   */
  async getCollectionItemsByRolesAndIds(
    type: string,
    ids: string[],
    roles: string[]
  ) {
    let data = {
      collection: type,
      collectionIds: ids,
      roles
    };

    let response = await Client.postWithJsonResult(
      Constants.apiUrl + "/cockpit/api/rolefilter",
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult(response, (r: any) => {
      return r.length > 0 ? r : null;
    });

    return result;
  }

  /**
   * Get a single configurator post, identified by a slug
   * @param slug
   */
  async getConfiguratorPostBySlug(slug: string, appBrand: string) {
    let data = {
      filter: {
        slug: slug,
        app_brand: { $in: [appBrand] }
      },
      simple: 1,
      populate: 0
    };

    let responseConfiguratorArticles = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_articles"),
      data,
      this.getAdditionalHeader()
    );

    let resultArticles = this.getResult<any, any>(
      responseConfiguratorArticles,
      (a: any) => {
        let article = a.length > 0 ? a[0] : null;
        return article;
      }
    );

    // UNCOMMENT THIS LINE TO JUST USE "configurator_articles"
    // return resultArticles;

    // // // // // // // // // // // // // // // // // // // // //
    // // // // // REMOVE EVERYTHING FROM HERE // // // // // //
    // // // // // // // // // // // // // // // // // // // // //
    if (resultArticles.type == ResultType.SUCCESS && resultArticles.value) {
      return resultArticles;
    }

    let responseConfiguratorStubs = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_stubs"),
      data,
      this.getAdditionalHeader()
    );

    let resultStubs = this.getResult<any, any>(
      responseConfiguratorStubs,
      (s: any) => {
        let stub = s.length > 0 ? s[0] : null;
        return stub;
      }
    );

    return resultStubs;
    // // // // // // // // // // // // // // // // // // // // //
    // // // // // // // // TO HERE // // // // // // // // // //
    // // // // // // // // // // // // // // // // // // // // //
  }

  async getOfflabelArticleBySlug(slug: string, appBrand: string) {
    let data = {
      filter: {
        slug: slug,
        app_brand: { $in: [appBrand] }
      },
      simple: 1,
      populate: 0
    };

    let responseOfflabelArticles = await Client.postWithJsonResult(
      Constants.collectionEntries("offlabel_articles"),
      data,
      this.getAdditionalHeader()
    );

    let resultOfflabelArticles = this.getResult<any, any>(
      responseOfflabelArticles,
      (a: any) => {
        let article = a.length > 0 ? a[0] : null;
        return article;
      }
    );

    return resultOfflabelArticles;
  }

  async getTestGuidlineSlideBySlug(slug: string) {
    let data = {
      filter: {
        slug: slug
      },
      simple: 1,
      popuplate: 0
    };

    let responseTestGuidlineSlides = await Client.postWithJsonResult(
      Constants.collectionEntries("test_guideline_slides"),
      data,
      this.getAdditionalHeader()
    );

    let resultTestGuidlineSlides = this.getResult<any, any>(
      responseTestGuidlineSlides,
      (tgl: any) => {
        let slide = tgl.length > 0 ? tgl[0] : null;
        return slide;
      }
    );

    return resultTestGuidlineSlides;
  }

  /**
   * Get a list of article ids by certain filter tags
   * @param appBrand
   * @param firstFilter
   * @param secondFilter
   * @param thirdFilter
   * @param defaultFilter
   */
  async getArticlesByFilter(
    appBrand: string,
    firstFilter: any,
    secondFilter: any,
    thirdFilter: any,
    defaultFilter: any
  ) {
    let data = {
      first_filter: firstFilter,
      second_filter: secondFilter,
      third_filter: thirdFilter,
      default_filter: defaultFilter,
      app_brand: appBrand,
      collection: "articles"
    };

    let response = await Client.postWithJsonResult(
      Constants.apiUrl + "/cockpit/api/filteredcontent",
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async getTargetAudience(app_brand: string) {
    let data = {
      filter: {
        app_brand: app_brand
      },
      fields: {
        content: 1
      },
      simple: 1,
      popuplate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("target_audiences"),
      data
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new ContentRepository();
