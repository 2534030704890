
import { Component } from "vue-property-decorator";
import {
  ExpertForumThread,
  ExpertForumUser,
  ExpertForumRepository,
  ExpertForumGetAllThreadsResponse,
  ResultType,
  router,
  Store
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import ThreadItem from "./ThreadItem.vue";

@Component({
  components: {
    "thread-item": ThreadItem
  }
})
export default class ThreadList extends BaseComponent {
  public threads: ExpertForumThread[];
  public patient: ExpertForumUser | null;
  public token: string;
  public apiError: boolean;
  public resultMessage: string;

  constructor() {
    super();

    this.contentLoaded = false;
    this.threads = [];
    this.patient = null;
    this.token = sessionStorage.getItem(`expert-forum-${Store.frontend}`) || "";
    this.apiError = false;
    this.resultMessage = "";
  }

  async mounted() {
    super.mounted();

    if (this.token) {
      this.getThreads();
    } else {
      router.push(`/login`);
    }
  }

  async getThreads() {
    const result = await ExpertForumRepository.getThreads(this.token);

    if (
      result.type === ResultType.SUCCESS &&
      result.value &&
      result.value.success
    ) {
      const response = result.value as ExpertForumGetAllThreadsResponse;
      this.threads = response.threads;
      this.patient = response.patient;
    } else {
      try {
        this.resultMessage = "";

        // @ts-ignore
        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }

    this.contentLoaded = true;
  }
}
