import EventRepository from "../api/repositories/EventRepository";
import { ResultType } from "../api/util/Result";
import moment from "moment";
import { defaultTo } from "lodash";
import User, { UserLoginStatus } from "./User";

export class EventDisplayOptions {
  showEventId!: boolean;
  showDescription!: boolean;
  showTitle!: boolean;
  showDateTimeString!: boolean;
  showSpeaker!: boolean;
  selectable!: boolean;
  showRegisterButton!: boolean;
  showCategory!: boolean;

  constructor(
    showEventId: boolean,
    showDescription: boolean,
    showTitle: boolean,
    showDateTimeString: boolean,
    showSpeaker: boolean,
    selectable: boolean,
    showRegisterButton: boolean,
    showCategory: boolean
  ) {
    this.showEventId = showEventId;
    this.showDescription = showDescription;
    this.showTitle = showTitle;
    this.showDateTimeString = showDateTimeString;
    this.showSpeaker = showSpeaker;
    this.selectable = selectable;
    this.showRegisterButton = showRegisterButton;
    this.showCategory = showCategory;
  }
}

export class EventProgramSegmentGroupContent {
  class!: string;
  title!: string;
  description!: string;
  descriptionText!: string;
  footer!: string;
  role!: string;
  showTitle!: boolean;

  constructor(segmentGroupContent: any) {
    this.class = segmentGroupContent.class || "";
    this.title = segmentGroupContent.title || "";
    this.description = segmentGroupContent.description || "";
    this.descriptionText = segmentGroupContent.descriptionText || "";
    this.footer = segmentGroupContent.footer || "";
    this.role = segmentGroupContent.role || "";
    this.showTitle =
      segmentGroupContent.showTitle != undefined
        ? segmentGroupContent.showTitle
        : true;
  }
}

export class EventProgramSegmentGroup {
  class!: string;
  name!: string;
  role!: string;
  content!: EventProgramSegmentGroupContent[];

  constructor(segmentGroup: any) {
    this.class = segmentGroup.class || "";
    this.name = segmentGroup.name || "";
    this.role = segmentGroup.role || "";
    this.content = [];

    if (segmentGroup.content) {
      segmentGroup.content.forEach((s: any) => {
        this.content.push(new EventProgramSegmentGroupContent(s));
      });
    }
  }
}

export class EventProgramSegment {
  startTime!: string;
  endTime!: string;
  segmentContent!: string;
  segmentContentText!: string;
  highlighted!: boolean;
  role!: string;
  groups!: EventProgramSegmentGroup[];

  constructor(segment: any) {
    this.startTime = segment.startTime || "";
    this.endTime = segment.endTime || "";
    this.segmentContent = segment.segmentContent || "";
    this.segmentContentText = segment.segmentContentText || "";
    this.highlighted = segment.highlighted || false;
    this.role = segment.role || "";
    this.groups = [];

    if (segment.groups) {
      segment.groups.forEach((s: any) => {
        this.groups.push(new EventProgramSegmentGroup(s));
      });
    }
  }
}

export class EventProgram {
  id!: string;
  date!: string;
  wrapperClass!: string;
  class!: string;
  segments!: EventProgramSegment[];

  constructor(program: any) {
    this.id =
      program.id || "ep_" + Math.round(Math.random() * 36 ** 12).toString(36);
    this.date = program.date || "";
    this.wrapperClass = program.wrapperClass || "";
    this.class = program.class || "";
    this.segments = [];

    if (program.segments) {
      program.segments.forEach((s: any) => {
        this.segments.push(new EventProgramSegment(s));
      });
    }
  }
}

interface Dimensions2D {
  width: number;
  height: number;
}

interface Position2D {
  x: number;
  y: number;
}

export interface PDFSegment {
  key: string;
  position: Position2D;
  sources: string[];
}

export class EventPDFPage {
  backgroundSrc!: string;
  backgroundSize!: Dimensions2D;
  emptySegmentPlaceholder!: string;
  segmentSize!: Dimensions2D;
  segments!: PDFSegment[];
}

export class EventSpeakerCategory {
  name!: string;
  color!: string;
  order!: number;

  constructor(speakersCategory: any) {
    this.name = speakersCategory.name || "";
    this.color = speakersCategory.color || "";
    this.order = speakersCategory.order || 0;
  }
}

export class EventSpeaker {
  speakerId!: string;
  speakerTitle!: string;
  speakerName!: string;
  speakerImage!: string;
  speakerInfo!: string;
  speakerClass!: string;
  speakerCategories!: string[];

  constructor(speaker: any) {
    this.speakerId =
      speaker.speakerId ||
      `${Math.random()
        .toString(36)
        .substr(2, 9)}`;
    this.speakerTitle = speaker.speakerTitle || "";
    this.speakerName = speaker.speakerName || "";
    this.speakerImage = speaker.speakerImage || "";
    this.speakerInfo = speaker.speakerInfo || "";
    this.speakerClass = speaker.speakerClass || "";
    this.speakerCategories = [];

    if (speaker.speakerCategories) {
      this.speakerCategories.push(...speaker.speakerCategories);
    }
  }
}

export class EventSpeakers {
  id!: string;
  speakersBodyClass!: string;
  speakerWidth!: string;
  speakerInfoFullWidth!: boolean;
  categories!: EventSpeakerCategory[];
  data!: EventSpeaker[];

  constructor(speakers: any) {
    this.id = "";
    this.speakersBodyClass = "";
    this.speakerWidth = "4";
    this.speakerInfoFullWidth = true;
    this.data = [];
    this.categories = [];

    if (speakers) {
      this.id =
        speakers.id ||
        `${Math.random()
          .toString(36)
          .substr(2, 9)}`;
      this.speakersBodyClass = speakers.speakersBodyClass || "";
      this.speakerWidth = speakers.speakerWidth || "4";
      this.speakerInfoFullWidth = defaultTo(
        speakers.speakerInfoFullWidth,
        true
      );

      if (speakers.data) {
        speakers.data.forEach((s: any) => {
          this.data.push(new EventSpeaker(s));
        });
      }

      if (speakers.categories) {
        speakers.categories.forEach((c: any) => {
          this.categories.push(new EventSpeakerCategory(c));
        });
      }
    }
  }
}

export default class Event {
  id!: string;
  idVeeva!: string;
  veevaId!: string;
  eventTitle!: string;
  eventDescription!: string;
  endDateTime!: string;
  agenda!: string;
  locationCity!: string;
  locationLat!: any;
  locationLon!: any;
  locationName!: string;
  locationStreetNbr!: string;
  locationZip!: string;
  landingPageLink!: string;
  onlinePlatform!: string;
  sessionsEmbedded!: any;
  opParticipantLink!: string;
  registrationType!: string;
  registered!: boolean;
  startDateTime!: string;
  documents!: any[];
  sessions!: any[];
  dateTimeString!: string;
  category!: string;

  live!: boolean;
  expired!: boolean;

  subscribeAsSpeaker!: boolean;
  showSpeakerSubscription!: boolean;

  program!: EventProgram[];
  pdfPages!: EventPDFPage[];
  speakers!: EventSpeakers;

  constructor(item: any) {
    this.id = item.id;
    this.idVeeva = item.idVeeva;
    this.veevaId = item.veevaId;
    this.eventTitle = item.eventTitle;
    this.eventDescription = item.eventDescription;
    this.endDateTime = item.endDateTime;
    this.agenda = item.agenda;
    this.locationCity = item.locationCity;
    this.locationLat = item.locationLat;
    this.locationLon = item.locationLon;
    this.locationName = item.locationName;
    this.locationStreetNbr = item.locationStreetNbr;
    this.locationZip = item.locationZip;
    this.landingPageLink = item.landingPageLink;
    this.onlinePlatform = item.onlinePlatform;
    this.sessionsEmbedded = item.sessionsEmbedded;
    this.opParticipantLink = item.opParticipantLink;
    this.registrationType = item.registrationType;
    this.registered = item.registered || false;
    this.startDateTime = item.startDateTime;
    this.documents = item.documents;
    this.sessions = item.sessions;
    this.dateTimeString = item.dateTimeString;
    this.category = item.category;
    this.live = item.live || false;
    this.expired = item.expired || false;
    this.subscribeAsSpeaker = item.subscribeAsSpeaker || false;
    this.showSpeakerSubscription = item.showSpeakerSubscription || false;
    this.program = [];
    this.pdfPages = item.pdfPages;
    this.speakers = new EventSpeakers(item.speakers);

    if (item.program) {
      item.program.forEach((p: any) => {
        this.program.push(new EventProgram(p));
      });
    }

    moment.locale("de");
    this.validateEventDateTime();
    this.checkEventOnVeeva();
  }

  validateEventDateTime() {
    let currentDateTime = moment();
    const diffInDaysFromStart = moment(this.startDateTime).diff(
      currentDateTime,
      "days"
    );
    const diffInDaysFromEnd = moment(this.endDateTime).diff(
      currentDateTime,
      "days"
    );

    if (diffInDaysFromStart < 5 && diffInDaysFromEnd > -1) {
      // if it is less than 5 days before the event starts and the event is not finished

      const diffInHours = moment(this.endDateTime).diff(
        currentDateTime,
        "hours"
      );

      if (diffInHours >= -1) {
        this.live = true;
      }
    } else if (diffInDaysFromEnd <= -5) {
      // if 5 days have passed since the event ended
      this.expired = true;
    }
  }

  async checkEventOnVeeva() {
    if (!this.veevaId) {
      return;
    }

    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      return;
    }

    const result = await EventRepository.getVeevaEvent(this.veevaId);

    if (result.type == ResultType.SUCCESS) {
      this.registered = result.value.registered;
      this.startDateTime = result.value.startDateTime;
      this.endDateTime = result.value.endDateTime;
      this.onlinePlatform = result.value.onlinePlatform;
      this.registrationType = result.value.registrationType;
      this.opParticipantLink = result.value.opParticipantLink;
      this.dateTimeString = `${moment(this.startDateTime).format(
        "dddd I DD.MM.YYYY I HH:mm"
      )}-${moment(this.endDateTime).format("HH:mm")} Uhr`;

      //revalidate live events based on api data
      this.validateEventDateTime();
    } else {
      this.registered = false;
    }
  }
}
