export interface RequestResponseContent {
  title: string;
  content: string;
}

export const RESPONSES: { [key: string]: RequestResponseContent } = {
  defaultSubscribe: {
    title: "",
    content: `
    <div class="mb-6">
        Ihre Anmeldung war erfolgreich. Ab sofort informieren wir Sie als Ihr
        neuer Partner in der Rheumatologie via E-Mail über innovative Therapien
        bei Kollagenosen wie SLE und anderen systemischen Autoimmunerkrankungen.
    </div>
    <div>Mit freundlichen Grüßen</div>
    <div>Ihr Immunologie-Team von AstraZenec</div>
    `
  },
  defaultUnsubscribe: {
    title: "<h1>We're sorry to see you go...</h1>",
    content: "<p>You have unsubscribed successfully.</p>"
  },
  gynoncoUnsubscribeSuccess: {
    title: '<h2 class="i-am-an-apple faded">Eintrag gelöscht</h2>',
    content: `<p><b>Sie wurden nicht für das GNY ONCO BARCAMP 2023 vorgemerkt.</b></p>

    <p class="font-weight-light">Sollten Sie sich doch für die Veranstaltung vormerken wollen, starten Sie den Prozess bitte neu.</p>

    <p class="font-weight-light">Mit freundlichen Grüßen<br/>
    Ihr Team von AstraZeneca & MSD</p>`
  },
  gynoncoReservationSuccess: {
    title: '<h2 class="i-am-an-apple faded">Sie sind vorgemerkt</h2>',
    content: `<p><b>Vielen Dank, dass Sie sich für das GYN ONCO BARCAMP 2023 vorgemerkt haben!</b></p>

    <p class="font-weight-light">Zum Anmeldestart erhalten Sie detaillierte Informationen zum Ablauf und zum Programm des GYN ONCO BARCAMPS 2023. Durch die Nutzung der Vormerk-funktion sind Sie nicht automatisch zum GYN ONCO BARCAMP 2023 angemeldet. Sie werden zum Anmeldestart erneut kontaktiert.</p>

    <p class="font-weight-light">Mit freundlichen Grüßen<br/>
    Ihr Team von AstraZeneca & MSD</p>`
  },
  haemacoveryReservationSuccess: {
    title: "<h2>Sie sind vorgemerkt</h2>",
    content: `<h3>Vielen Dank, dass Sie sich für die Hämacovery vorgemerkt haben!</h3>
    <p>Zum Anmeldestart erhalten Sie detaillierte Informationen zum Ablauf und zum Programm der Hämacovery 2022. Durch die Nutzung der Vormerk-funktion sind Sie nicht automatisch zur Hämacovery 2022 angemeldet. Sie werden zum Anmeldestart erneut kontaktiert.</p>
    <p>Mit freundlichen Grüßen
    Ihr Team von AstraZeneca</p>
    `
  },
  haemacoveryUnsubscribeSuccess: {
    title: "<h2>Eintrag gelöscht</h2>",
    content: `<h3>Sie wurden nicht für die Hämacovery 2022 vorgemerkt.</h3>
    <p>Sollten Sie sich doch für die Veranstaltung vormerken wollen, starten Sie den Prozess bitte neu.</p>
    <p>Mit freundlichen Grüßen
    Ihr Team von AstraZeneca
    </p>
    `
  }
};
