import { Deserializible } from "../../../util/Helper";

export class SampleRequest extends Deserializible {
  public productId: string | undefined = undefined;
  public numberOfSamples: number | undefined = undefined;
  public rootId: string | undefined = undefined;
  public pridId: string | undefined = undefined;

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);
    }
  }

  public isValid(): boolean {
    return (
      typeof this.productId !== "undefined" &&
      typeof this.rootId !== "undefined" &&
      typeof this.pridId != "undefined"
    );
  }
}
