export interface ExpertForumUser {
  firstName: string;
  lastName: string;
  email: string;
}

export enum ExpertForumPostType {
  EXPERT = "expert",
  PATIENT = "patient"
}

export interface ExpertForumThread {
  id: string;
  title: string;
  body: string;
  patientToken: string;
  answer: string;
  answeredAt: string;
  expert: ExpertForumUser;
  createdAt: string;
}

export class ExpertForumErrors {
  errors: { [key: string]: any };

  constructor(errors: { [key: string]: any } = {}) {
    this.errors = errors;
  }

  hasErrors(): boolean {
    return (
      Object.keys(this.errors).filter(key => this.hasError(key)).length > 0
    );
  }

  hasError(key: string): boolean {
    if (this.errors[key] === undefined) {
      return false;
    }

    const errors = this.errors[key];

    if (typeof errors === "string" || errors instanceof String) {
      return errors.length > 0;
    }

    if (Array.isArray(errors)) {
      return errors.length > 0;
    }

    return false;
  }

  getError(key: string): string | null {
    if (!this.hasError(key)) {
      return null;
    }

    const errors = this.errors[key];

    if (typeof errors === "string" || errors instanceof String) {
      return errors as string;
    }

    return errors[0] as string;
  }

  clear(): void {
    this.errors = {};
  }

  addSystemError(error: string | null = null): void {
    this.errors["system"] =
      error || "Something went wrong. Please try again later.";
  }

  addError(key: string, error: string | Array<string>): void {
    if (!this.errors[key]) {
      this.errors[key] = [];
    }

    if (typeof error === "string" || error instanceof String) {
      this.errors[key].push(error as string);
    } else if (Array.isArray(error)) {
      this.errors[key] = [...this.errors[key], ...error];
    }
  }

  addErrors(errors: { [key: string]: any } | null): void {
    if (!errors) {
      return;
    }

    Object.keys(errors).forEach(key => {
      this.addError(key, errors[key]);
    });
  }

  static getSystemErrorKeys(): string[] {
    return ["system", "token", "originToken"];
  }

  hasSystemError(): boolean {
    return ExpertForumErrors.getSystemErrorKeys().some(key =>
      this.hasError(key)
    );
  }

  getSystemError(): string | null {
    return ExpertForumErrors.getSystemErrorKeys().reduce(
      (error: string | null, key: string) => {
        if (this.hasError(key)) {
          return this.getError(key);
        }

        return error;
      },
      null
    );
  }
}
