import { Deserializible } from "../../../util/Helper";

export class Spendings extends Deserializible {
  /* JSON:
    {
      conferenceFees: 0,
      feedbackDate: "2021-04-15T11:50:43.981Z",
      fees: 0,
      hcaCity: "string",
      hcaName: "string",
      hcaStreet: "string",
      hcaZip: "string",
      recipient: "string",
      reimbursement: 0,
      salutation: "string",
      title: "string",
      travelExpenses: 0,
      travelExpensesString: "string",
      publicationConsent: true,
    }; */

  [key: string]: any;
  public conferenceFees: number | undefined = undefined;
  public conferenceFeesString: string | undefined = undefined;
  public feedbackDate: string | undefined = undefined;
  public fees: number | undefined = undefined;
  public feesString: string | undefined = undefined;
  public hcaCity: string | undefined = undefined;
  public hcaName: string | undefined = undefined;
  public hcaStreet: string | undefined = undefined;
  public hcaZip: string | undefined = undefined;
  public recipient: string | undefined = undefined;
  public reimbursement: number | undefined = undefined;
  public reimbursementString: string | undefined = undefined;
  public salutation: string | undefined = undefined;
  public title: string | undefined = undefined;
  public travelExpenses: number | undefined = undefined;
  public travelExpensesString: string | undefined = undefined;
  public publicationConsent: boolean | undefined = undefined;

  constructor(other?: any) {
    super();
    if (other) {
      this.deserialize(other);

      let self = this;
      new Map([
        [this.fees, "feesString"],
        [this.conferenceFees, "conferenceFeesString"],
        [this.reimbursement, "reimbursementString"],
        [this.travelExpenses, "travelExpensesString"]
      ]).forEach(function(value, key, map) {
        if (key != undefined) {
          self[value] = key.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR"
          });
        }
      });
    }
  }

  public isValid() {
    return this.hcaName !== undefined;
  }
}
