export class CryptoHelper {
  static generateUniqueKey(length: number = 16) {
    var crypto = require("crypto");
    return crypto.randomBytes(length).toString("base64");
  }

  static generateChecksum(value: string) {
    var crypto = require("crypto");
    return crypto
      .createHash("sha256")
      .update(value)
      .digest("base64");
  }

  static encrypt(value: string, key: string, separator: string = "~") {
    var crypto = require("crypto");

    var cryptoCipher = crypto.createCipher("aes-128-cbc", key);
    var encryptedValue = cryptoCipher.update(value, "utf8", "hex");
    encryptedValue += cryptoCipher.final("hex");

    return encryptedValue;
  }

  static decrypt(value: string, key: string, separator: string = "~") {
    var crypto = require("crypto");

    var cryptoCipher = crypto.createDecipher("aes-128-cbc", key);
    var decryptedValue = cryptoCipher.update(value, "hex", "utf8");
    decryptedValue += cryptoCipher.final("utf8");

    return decryptedValue;
  }
}
