
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  EventBus,
  ARTICLE_SAVED,
  ARTICLE_UNSAVED,
  Helper,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  Repository,
  ResultType,
  router,
  SavedArticleRepository,
  Store,
  ArticleListSaved,
  DatabaseSavedArticleList,
  User,
  UserProfile
} from "common";
import BaseWidget from "./BaseWidget.vue";
import DefaultArticleWidgetTemplate from "./templates/DefaultArticleWidgetTemplate.vue";
import BookmarkableArticleWidgetTemplate from "./templates/BookmarkableArticleWidgetTemplate.vue";

export enum ArticleWidgetType {
  horizontal = "horizontal",
  vertical = "vertical"
}

export enum ArticleWidgetLayoutType {
  basic = "basic",
  bookmarkable = "bookmarkable"
}

export interface CockpitLink {
  display: string;
  link: string;
  _id: string;
}

@Component({
  components: {
    BookmarkableArticleWidgetTemplate,
    DefaultArticleWidgetTemplate
  }
})
export default class ArticleWidget extends BaseWidget {
  @Prop() openNewTabIds!: Array<string>;
  @Prop({ default: null }) propHeadline!: LayoutComponent | null;

  @Prop({
    default() {
      return [];
    }
  })
  propText!: Array<any>;
  @Prop({ default: null }) propImage!: LayoutComponent | null;
  @Prop({ default: null }) cockpitLink!: CockpitLink | null;
  @Prop({ default: null }) btnLabel!: string | null;
  @Prop({ default: null }) widgetTitle!: string | null;
  @Prop({ default: null }) showImage!: string | null;

  public ArticleWidgetType = ArticleWidgetType;
  public ArticleWidgetLayoutType = ArticleWidgetLayoutType;

  public slug: any = null;
  public targetAppBrand: string | null = null;
  public appBrandURL: string | null = null;
  public articleLink: any = null;
  public accountProfile: any = null;
  public sharingWidget: LayoutComponent | null = null;

  public loadedArticleImage: boolean = false;
  public loadedArticleHeadline: boolean = false;
  public loadedArticleText: boolean = false;
  public published: boolean = false;

  public saved!: boolean;
  public articleListSaved = ArticleListSaved;

  getComponentType() {
    return "view_widgets";
  }

  constructor() {
    super();
    this.saved = false;
  }

  @Watch("articleListSaved.updated")
  updateSaved() {
    this.updateSavedStatus();
  }

  @Watch("loadedArticleImage")
  @Watch("loadedArticleHeadline")
  @Watch("loadedArticleText")
  checkIfEverythingHasLoaded() {
    if (
      this.loadedArticleImage &&
      this.loadedArticleHeadline &&
      this.loadedArticleText
    ) {
      this.$emit("contentDidLoad");
    }
  }

  async updateSavedStatus() {
    this.saved = User.shouldUseLocalStorageToSaveArticles()
      ? Store.isArticleSaved(this.article_id)
      : await this.checkIfArticleIsSavedInTheDatabase();
  }

  async checkIfArticleIsSavedInTheDatabase() {
    const articleIds = await DatabaseSavedArticleList.get();

    return articleIds.includes(this.article_id);
  }

  imageLoaded() {
    this.loadedArticleImage = true;
  }

  headlineLoaded() {
    this.loadedArticleHeadline = true;
  }

  textLoaded() {
    this.loadedArticleText = true;
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.saved = Store.isArticleSaved(this.article_id);

      let cockpitLink =
        this.cockpitLink || this.getContentAttribute("cockpit_link");

      if (cockpitLink) {
        let contentResponse = await this.loadContent(
          cockpitLink.link,
          [cockpitLink._id],
          0,
          {
            slug: 1,
            account_profile: 1,
            sharing_widget: 1,
            _id: 0,
            app_brand: 1
          }
        );

        if (contentResponse) {
          this.slug = contentResponse[0].slug;
          this.accountProfile = contentResponse[0].account_profile.display;
          const widgetShare = this.getContentAttribute("widget_share");
          const shareComp = contentResponse[0].sharing_widget;
          this.targetAppBrand = contentResponse[0].app_brand.display || null;

          if (widgetShare && widgetShare.length) {
            this.sharingWidget = new LayoutComponent(widgetShare[0]);
          } else if (shareComp && shareComp.length) {
            this.sharingWidget = new LayoutComponent(shareComp[0]);
          } else {
            this.sharingWidget = null;
          }

          if (contentResponse[0].moderation === "Published") {
            this.$emit("articlePublished", {
              isPublished: true,
              widgetId: this.widgetId
            });
            this.published = true;
          }
        } else {
          this.$emit("articlePublished", {
            isPublished: false,
            widgetId: this.widgetId
          });
        }
      }

      let cockpitLinkId: any;
      try {
        cockpitLinkId = this.getContentAttribute("text").filter(
          (item: { component: string }) => item.component === "link"
        )[0].settings.content._id;
      } catch (e) {
        cockpitLinkId = null;
      }

      if (cockpitLinkId) {
        let cockpitLinkResult = await this.loadContent(
          "links",
          [cockpitLinkId],
          0,
          {
            link: 1
          }
        );

        if (cockpitLinkResult && cockpitLinkResult.length > 0) {
          this.articleLink = cockpitLinkResult[0].link;
        }
      }

      this.getAppBrandURL();
      this.updateSavedStatus();
    });
  }

  get article_id() {
    let cockpitLink =
      this.cockpitLink || this.getContentAttribute("cockpit_link");
    if (cockpitLink) {
      return cockpitLink._id;
    }
    return null;
  }

  get headline() {
    if (this.propHeadline) {
      return this.propHeadline;
    }

    const headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    this.loadedArticleHeadline = true;
    return null;
  }

  get image() {
    if (this.propImage) {
      return this.propImage;
    }

    const image = this.getContentAttribute("image");

    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    this.loadedArticleImage = true;
    return null;
  }

  get show_image() {
    if (this.showImage !== null) {
      return this.showImage;
    }

    let showImage = this.getContentAttribute("show_image");
    if (showImage == undefined) {
      return true;
    }

    return showImage;
  }

  get text() {
    if (this.propText.length) {
      this.loadedArticleText = true;
      return this.propText;
    }

    this.loadedArticleText = true;
    return this.getContentAttribute("text");
  }

  get label() {
    return this.btnLabel || this.getContentAttribute("label");
  }

  get type() {
    let type = this.getSettingsAttribute("type");
    return type ? type : ArticleWidgetType.vertical;
  }

  get widgetLayoutType() {
    return this.getSettingsAttribute("layout");
  }

  get link() {
    return "/" + this.slug;
  }

  get absoluteLink() {
    return window.location.host + this.link;
  }

  get widgetId() {
    return this.getContentAttribute("_id");
  }

  async getAppBrandURL() {
    if (this.targetAppBrand && this.targetAppBrand !== Store.appBrand) {
      const result = await Repository.getAppBrandUrl(this.targetAppBrand);

      if (result && result.type === ResultType.SUCCESS) {
        this.appBrandURL =
          result.value.app_url && result.value.app_url.length > 0
            ? result.value.app_url
            : null;
      }
    }
  }

  gotoView() {
    if (this.articleLink) {
      let widgetTargetBlank = this.getSettingsAttribute("open_in_new_tab");

      if (
        (this.openNewTabIds && this.openNewTabIds.includes(this.widgetId)) ||
        widgetTargetBlank
      ) {
        if (Helper.showExitRamp(this.articleLink)) {
          this.showModal(
            "Vielen Dank für Ihren Besuch",
            new LayoutComponent({
              component: "ExitRamp",
              settings: {
                link: this.articleLink
              }
            }),
            "modal-md"
          );
        } else {
          window.open(this.articleLink, "_blank");
        }
      } else {
        window.location.href = this.articleLink;
      }
      return;
    }

    if (this.link) {
      // Compose the URL with the domain if linked content (article, page..) is on a different app_brand
      if (this.appBrandURL) {
        const url =
          window.location.protocol + "//" + this.appBrandURL + this.link;
        window.open(url, "_blank");
      } else {
        router.push(this.link);
      }
    }

    // Matomo tracking
    matomo.genericEvent(
      this.article_id,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.CLICK_ARTICLE_WIDGET_BUTTON
    );
  }

  updateArticle() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      return Store.saveArticle(this.article_id);
    }

    this.saved ? this.deleteSavedArticle() : this.saveArticle();
  }

  saveArticle() {
    SavedArticleRepository.save(this.article_id).then(() => {
      this.saved = true;
      EventBus.$emit(ARTICLE_SAVED, this.article_id);
    });
  }

  deleteSavedArticle() {
    SavedArticleRepository.delete(this.article_id).then(() => {
      this.saved = false;
      EventBus.$emit(ARTICLE_UNSAVED, this.article_id);
    });
  }

  get userNoTracking() {
    return !User.hasUserProfile(UserProfile.MMC_PLUS);
  }

  get bookmarkableWidgetTitle() {
    return this.widgetTitle || this.getContentAttribute("widget_title");
  }
}
