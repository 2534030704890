
import { Component, Watch } from "vue-property-decorator";
import { EventBus, GUIDELINE_FORM_QUESTION_ANSWERED } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GuidelineFormQuestion extends BaseComponent {
  public checksModel: Array<string> = [];

  getComponentType() {
    return "guideline_form_questions";
  }

  async mounted() {
    super.mounted();
  }

  get question() {
    return this.getContentAttribute("question");
  }

  get answers() {
    return this.getContentAttribute("answers");
  }

  get inputType() {
    let multiple_answers = this.getSettingsAttribute("multiple_answers");
    return multiple_answers ? "checkbox" : "radio";
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  @Watch("checksModel")
  questionAnswered() {
    EventBus.$emit(GUIDELINE_FORM_QUESTION_ANSWERED, this.checksModel);
    let guidelineFormAnswers = JSON.parse(
      localStorage.getItem("guidelineFormAnswers") || "{}"
    );
    guidelineFormAnswers[this.internalId] = this.checksModel;
    localStorage.setItem(
      "guidelineFormAnswers",
      JSON.stringify(guidelineFormAnswers)
    );
  }
}
