
import { Component, Vue } from "vue-property-decorator";
import { Store, User } from "common";
import BaseComponentVue from "../base/BaseComponent.vue";

@Component
export default class PresentationOverlay extends BaseComponentVue {
  async mounted() {
    await super.mounted();
    Vue.nextTick(() => {
      let iframe = this.$refs.presentation_iframe as HTMLIFrameElement;
      if (iframe) {
        iframe.onload = () => {
          if (iframe.contentWindow) {
            iframe.contentWindow.focus();
          }
        };
      }
    });
  }

  get slides_link() {
    return this.getSettingsAttribute("slides_link");
  }

  // Add URL query params for matomo (used for matomo instance from slidekit)
  get computedUrl() {
    //@ts-ignore
    const matomoEventIsBranded =
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY === "WebWorld_Branded" ? 1 : 0;
    //@ts-ignore
    const matomoSiteId = isNaN(parseInt(process.env.VUE_APP_MATOMO_SITE_ID))
      ? null
      : process.env.VUE_APP_MATOMO_SITE_ID;

    // Add query params only if matomo is enabled and user has tracking consent given
    if (
      Store.matomoEnabled() &&
      User.getTrackingConsent() &&
      matomoSiteId !== null
    ) {
      return `${this.slides_link}?siteid=${matomoSiteId}&isbranded=${matomoEventIsBranded}`;
    }
    return this.slides_link;
  }
}
