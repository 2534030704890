
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  ContentRepository,
  EventBus,
  SLIDE_LINK_CLICKED,
  GUIDELINE_FORM_QUESTION_ANSWERED,
  GuidelineRepository,
  LayoutComponent,
  matomo,
  ResultType,
  router,
  ROUTE_NAME_TESTGUIDELINE
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import { SlideLinkTarget } from "./SlideLink.vue";

export enum SlideTemplate {
  DEFAULT = "default",
  RADIO = "radio-buttons"
}

@Component
export default class TestGuidelineSlide extends BaseComponent {
  @Prop() guideline_id!: string;
  @Prop() slide_slug!: string;

  slide!: any;
  indicationCollection!: any;
  loading!: boolean;
  nextSelectedSlide!: string | null;
  formOptionsChecked!: boolean;

  contentKey!: any;

  constructor() {
    super();
    this.slide = null;
    this.indicationCollection = null;
    this.loading = true;
    this.contentKey = Date.now() + "_" + this.guideline_id;
    this.nextSelectedSlide = null;
    this.formOptionsChecked = false;
  }

  getComponentType() {
    return "test_guideline_slides";
  }

  async beforeMount() {
    EventBus.$on(
      GUIDELINE_FORM_QUESTION_ANSWERED,
      (optionsChecked: string[]) => {
        this.formOptionsChecked = optionsChecked.length > 0;
      }
    );

    // Slide link click listener
    EventBus.$on(SLIDE_LINK_CLICKED, this.handleSlideLinkClick);

    this.setThemeStyles();

    await this.loadSlide();
    await this.loadIndication();
    this.loading = false;
  }

  beforeDestroy() {
    // Remove listener for slide link click
    EventBus.$off(SLIDE_LINK_CLICKED, this.handleSlideLinkClick);
  }

  @Watch("slide_slug")
  async loadSlide() {
    this.formOptionsChecked = false;

    let response = await ContentRepository.getTestGuidlineSlideBySlug(
      this.slide_slug
    );

    if (response.type == ResultType.SUCCESS) {
      this.slide = response.value;
      this.contentKey = Date.now() + "_" + this.guideline_id;
      this.viewSlide(this.slide_slug);
    }
  }

  get backgroundImage() {
    if (this.slide != null) {
      if (
        this.slide.background_image &&
        this.slide.background_image.length > 0
      ) {
        return new LayoutComponent(this.slide.background_image[0]);
      }
    }

    return null;
  }

  get icon() {
    if (this.slide != null) {
      if (this.slide.icon.length > 0) {
        return new LayoutComponent(this.slide.icon[0]);
      }
    }

    return null;
  }

  get totalSteps() {
    let steps = [];
    if (this.maxStep != null) {
      for (var i = 1; i <= this.maxStep; i++) {
        steps.push(i);
      }
    }

    return steps;
  }

  get slideContent() {
    if (this.slide != null) {
      return this.slide.content;
    }

    return null;
  }

  get currentStep() {
    if (this.slide != null) {
      return this.slide.current_step;
    }

    return null;
  }

  get maxStep() {
    if (this.slide != null) {
      return this.slide.max_step;
    }

    return null;
  }

  get nextSlide() {
    // Get selected slide if radio buttons template
    if (this.template === SlideTemplate.RADIO) {
      return this.nextSelectedSlide;
      // Get selected slide if default template
    } else {
      return this.slide && this.slide.next_slide
        ? this.slide.next_slide.display
        : null;
    }
  }

  get nextSlideBtnText() {
    if (this.slide != null) {
      if (this.slide && this.slide.next_slide_btn_text) {
        return this.slide.next_slide_btn_text;
      }
    }

    return "Weiter";
  }

  get previousSlide() {
    if (this.slide != null) {
      if (this.slide && this.slide.previous_slide) {
        return this.slide.previous_slide.display;
      }
    }

    return null;
  }

  get previousSlideBtnText() {
    if (this.slide != null) {
      if (this.slide && this.slide.previous_slide_btn_text) {
        return this.slide.previous_slide_btn_text;
      }
    }

    return "Zurück";
  }

  get asPopup() {
    if (this.slide != null) {
      return this.slide.as_popup;
    }

    return null;
  }

  get hidePrevious() {
    if (this.slide != null) {
      return !!this.slide.hide_previous_slide;
    }

    return false;
  }

  get template(): SlideTemplate {
    if (this.slide !== null && this.slide.template) {
      return this.slide.template;
    }
    return SlideTemplate.DEFAULT;
  }

  get basicLayoutComponent() {
    return LayoutComponent.createEmpty("test-guideline-slide", {
      width: 12
    });
  }

  get headline() {
    if (this.slide != null) {
      if (this.slide.headline && this.slide.headline.length > 0) {
        let headline = new LayoutComponent(this.slide.headline[0]);
        headline.settings.style = `color: ${this.colors.theme_primary_color}`;
        return headline;
      }
    }

    return null;
  }

  get indication(): any {
    if (this.slide != null) {
      if (this.slide.indication) {
        return this.slide.indication.display;
      }
    }

    return null;
  }

  get colors() {
    if (this.slide && this.indicationCollection != null) {
      return this.indicationCollection.meta;
    }

    return {
      theme_primary_color: "rgb(200, 200, 200)",
      theme_secondary_color: "rgb(200, 200, 200)",
      theme_background_color: "rgb(255, 255, 255)",
      theme_text_color: "rgb(255, 255, 255)",
      button_text_color: "rgb(255, 255, 255)",
      download_background_color: "rgb(200, 200, 200)",
      download_text_color: "rgb(255, 255, 255)",
      popup_background_color: "rgb(200, 200, 200)",
      popup_text_color: "rgb(255, 255, 255)"
    };
  }

  get isRadioTemplate() {
    return this.template === SlideTemplate.RADIO;
  }

  @Watch("slide")
  async loadIndication() {
    if (this.indication != null) {
      let response = await ContentRepository.getCollectionItemByTypeAndId(
        "indications",
        [this.slide.indication._id],
        0
      );

      if (response.type == ResultType.SUCCESS) {
        this.indicationCollection = response.value[0];
      }
    } else {
      this.indicationCollection = null;
    }
  }

  @Watch("colors")
  setThemeStyles() {
    const slideRefElement = this.$refs.slide as HTMLElement;

    if (slideRefElement) {
      slideRefElement.style.setProperty(
        "--download-button-background",
        this.colors.download_background_color
      );

      slideRefElement.style.setProperty(
        "--download-button-color",
        this.colors.download_text_color
      );

      slideRefElement.style.setProperty(
        "--theme-primary-color",
        this.colors.theme_primary_color
      );

      slideRefElement.style.setProperty(
        "--theme-secondary-color",
        this.colors.theme_secondary_color
      );

      slideRefElement.style.setProperty(
        "--button-text-color",
        this.colors.button_text_color
      );

      slideRefElement.style.setProperty(
        "--popup-background-color",
        this.colors.popup_background_color
      );

      slideRefElement.style.setProperty(
        "--popup-text-color",
        this.colors.popup_text_color
      );
    }
  }

  getSlideUrl(slideSlug: string) {
    let route = router.currentRoute;

    if (route.matched.length > 0) {
      let path = `${route.matched[0].path}/${ROUTE_NAME_TESTGUIDELINE}/${this.guideline_id}/${slideSlug}`;
      return path;
    }

    return null;
  }

  gotoSlide(path: any) {
    if (path != null) {
      router.push(path);
    }
  }

  getStepPercentage(step: number) {
    if (step == 0) {
      return "0%";
    }

    if (step == this.totalSteps.length - 1) {
      return "100%";
    }

    let stepWidth = 100 / (this.totalSteps.length - 1);
    return stepWidth * step + "%";
  }

  async gotoParent(step: number) {
    let slideId = this.slide._id;

    let slideParentResponse = await GuidelineRepository.getSlideParent(
      slideId,
      step
    );

    if (slideParentResponse.type == ResultType.SUCCESS) {
      let parent = slideParentResponse.value[0];
      if (parent) {
        if (parent.slug) {
          this.clickSlideButton(parent.slug);
          this.gotoSlide(parent.slug);
        }
      }
    }
  }

  clickSlideButton(slug: string) {
    matomo.genericEvent(
      slug,
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "Click_TestGuideline_Button"
    );
  }

  viewSlide(slug: string) {
    matomo.genericEvent(
      slug,
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "View_TestGuideline_Slide"
    );
  }

  getSlideId() {
    if (this.slide && this.slide._id) {
      return this.slide._id;
    }

    return null;
  }

  handleSlideLinkClick(slide: SlideLinkTarget) {
    const slideLink =
      this.formOptionsChecked && slide.conditional
        ? slide.conditional
        : slide.default;

    if (this.template === SlideTemplate.RADIO) {
      this.nextSelectedSlide = slideLink;
    } else {
      this.clickSlideButton(slideLink);
      this.gotoSlide(this.getSlideUrl(slideLink));
    }
  }
}
