
import { Component, Vue } from "vue-property-decorator";
import uniqBy from "lodash/uniqBy";
import isBoolean from "lodash/isBoolean";
import {
  EventBus,
  CHANNEL_EVENTLIST_CHANGED,
  CHANNEL_EVENTLIST_NAMES_CHANGED,
  CHANNEL_EVENTLIST_SPEAKER_SETTING,
  CHANNEL_EVENTREGISTRATION_SUCCESS,
  Event,
  EventDisplayOptions,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import EventListItem from "./EventListItem.vue";
import EventListItemLive from "./EventListItemLive.vue";

@Component({
  components: {
    EventListItem,
    EventListItemLive
  }
})
export default class EventList extends BaseComponent {
  public events!: Event[];
  private selectedEvents!: Event[];
  public allEvents: boolean;
  public visible!: boolean;

  constructor() {
    super();
    this.events = [];
    this.selectedEvents = [];
    this.allEvents = false;
    this.visible = false;
  }

  getComponentType() {
    return "event_lists";
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.visible = this.layoutComponent
        ? this.layoutComponent.visible
        : false;
      this.loadEvents();
    });
  }

  async mounted() {
    super.mounted();
    EventBus.$on(CHANNEL_EVENTREGISTRATION_SUCCESS, (success: boolean) => {
      this.visible = !success;
    });
  }

  async loadEvents() {
    this.events = [];
    const events: Array<any> = [];

    if (this.meta) {
      const metaContent: any = await this.loadContent(
        this.meta.link,
        [this.meta._id],
        0
      );
      if (metaContent && metaContent.length > 0) {
        events.push(...metaContent[0].meta);
      }
    }

    uniqBy(events, (event: any) => event.veevaId).forEach((event: any) => {
      this.events.push(new Event(event));
    });

    if (this.events.length === 1) {
      this.selectEvent(this.events[0]);
    }
  }

  get isGuest() {
    return User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT);
  }

  get list_type() {
    return this.getContentAttribute("list_type") || "all";
  }

  get meta() {
    return this.getContentAttribute("meta");
  }

  get eventDisplayOptions() {
    return new EventDisplayOptions(
      this.showEventId,
      this.showDescription,
      this.showTitle,
      this.showDateTimeString,
      this.showSpeaker,
      this.selectable,
      this.showRegisterButton,
      this.showCategory
    );
  }

  get showEventId() {
    return true;
  }

  get showDescription() {
    return this.getSettingsAttribute("show_description");
  }

  get showTitle() {
    return this.getSettingsAttribute("show_title");
  }

  get showDateTimeString() {
    return this.getSettingsAttribute("show_datetime_string");
  }

  get showSpeaker() {
    return this.getSettingsAttribute("show_speaker");
  }

  get selectable() {
    return this.getSettingsAttribute("selectable");
  }

  get showRegisterButton() {
    return this.getSettingsAttribute("show_register_button");
  }

  get showCategory() {
    return this.getSettingsAttribute("show_category");
  }

  get cssClass() {
    return this.layoutComponent.class;
  }

  get liveEvents() {
    return this.events.filter((e: Event) => {
      return e.live;
    });
  }

  get expiredEvents() {
    return this.events.filter((e: Event) => {
      return e.expired;
    });
  }

  get selectedUnregisteredEvents() {
    return [
      ...new Set(
        this.selectedEvents
          .filter((e: Event) => {
            return !e.registered;
          })
          .map((e: Event) => {
            return e.veevaId;
          })
      )
    ];
  }

  get isOnlyOneEventAllowed() {
    const isMultiple = this.getSettingsAttribute("multiple");

    if (isBoolean(isMultiple)) {
      return isMultiple === false;
    }

    return false;
  }

  selectAllEvents(e: any) {
    let selectedEvents: Event[] = [];

    if (e.target.checked) {
      this.allEvents = true;
      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if (!event.registered && !event.expired) {
          selectedEvents.push(event);
        }
      }
    }

    this.selectedEvents = selectedEvents;
    EventBus.$emit(CHANNEL_EVENTLIST_CHANGED, this.selectedUnregisteredEvents);
    EventBus.$emit(
      CHANNEL_EVENTLIST_NAMES_CHANGED,
      this.getSelectedEventsNames()
    );
  }

  selectEvent(event: Event) {
    if (event.registered || event.expired) {
      return false;
    }

    let selectedIndex: any = null;
    let selectedEvent: any = this.selectedEvents.find(
      (e: Event, idx: number) => {
        if (e.id == event.id) {
          selectedIndex = idx;
          return true;
        }
      }
    );

    if (!selectedEvent) {
      this.selectedEvents.push(event);
      if (this.isOnlyOneEventAllowed) {
        this.selectedEvents = [event];
      }
    } else {
      this.selectedEvents.splice(selectedIndex, 1);
    }

    EventBus.$emit(CHANNEL_EVENTLIST_CHANGED, this.selectedUnregisteredEvents);
    EventBus.$emit(
      CHANNEL_EVENTLIST_NAMES_CHANGED,
      this.getSelectedEventsNames()
    );

    this.allEvents = false;

    return true;
  }

  selectEventSpeaker(event: Event) {
    if (this.isSelected(event)) {
      event.subscribeAsSpeaker = !event.subscribeAsSpeaker;
      EventBus.$emit(
        CHANNEL_EVENTLIST_SPEAKER_SETTING,
        event.subscribeAsSpeaker
      );
    }
  }

  isSelected(event: Event) {
    return this.selectedEvents.find((e: Event) => {
      return e.id == event.id;
    });
  }

  getSelectedEventsNames() {
    return this.selectedEvents.map(e => ({
      veevaId: e.veevaId,
      title: e.eventTitle
    }));
  }
}
