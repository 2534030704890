
import { Component, Vue, Prop } from "vue-property-decorator";
import { EventProgram } from "common";

@Component
export default class EventProgramBaseLayout extends Vue {
  @Prop() program!: EventProgram;

  constructor() {
    super();
  }

  getDateString(milliseconds: number) {
    let weekdays = [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ];

    let months = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ];

    let date = new Date(milliseconds);
    let weekday = date.getDay();
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    return `${weekdays[weekday]}, ${day}. ${months[month]} ${year}`;
  }
}
