import ContentRepository from "../api/repositories/ContentRepository";
import { ResultType } from "../api/util/Result";

export enum DataLoaderTypes {
  ContentLoader,
  OfflabelArticleLoader,
  ConfiguratorPostLoader
}

export interface DataLoaderInterface {
  id: string;
  appBrand: string;
  type?: string;
  loadData(): any;
}

export class DataLoaderFactory {
  public static getDataLoader(
    dataLoaderType: DataLoaderTypes,
    id: string,
    appBrand: string,
    type?: string
  ): DataLoaderInterface | null {
    if (dataLoaderType == DataLoaderTypes.ContentLoader) {
      return new ContentLoader(id, appBrand, type);
    }

    if (dataLoaderType == DataLoaderTypes.OfflabelArticleLoader) {
      return new OfflabelArticleLoader(id, appBrand, type);
    }

    if (dataLoaderType == DataLoaderTypes.ConfiguratorPostLoader) {
      return new ConfiguratorPostLoader(id, appBrand, type);
    }

    return null;
  }
}

class ContentLoader implements DataLoaderInterface {
  id: string;
  appBrand: string;
  type?: string;

  constructor(id: string, appBrand: string, type?: string) {
    this.id = id;
    this.appBrand = appBrand;
    this.type = type;
  }

  async loadData() {
    try {
      let result = await ContentRepository.getContentById(
        this.id,
        this.appBrand,
        this.type!
      );

      if (result.type == ResultType.SUCCESS) {
        return result.value;
      }

      return null;
    } catch (e) {
      throw Error(
        `ContentLoader could not load data with the following error: ${e}`
      );
    }
  }
}

class OfflabelArticleLoader implements DataLoaderInterface {
  id: string;
  appBrand: string;
  type?: string;

  constructor(id: string, appBrand: string, type?: string) {
    this.id = id;
    this.appBrand = appBrand;
    this.type = type;
  }

  async loadData() {
    try {
      let result = await ContentRepository.getOfflabelArticleBySlug(
        this.id,
        this.appBrand
      );

      if (result.type == ResultType.SUCCESS) {
        return result.value;
      }

      return null;
    } catch (e) {
      throw Error(
        `OfflabelArticleLoader could not load data with the following error: ${e}`
      );
    }
  }
}

class ConfiguratorPostLoader implements DataLoaderInterface {
  id: string;
  appBrand: string;
  type?: string;

  constructor(id: string, appBrand: string, type?: string) {
    this.id = id;
    this.appBrand = appBrand;
    this.type = type;
  }

  async loadData() {
    try {
      let result = await ContentRepository.getConfiguratorPostBySlug(
        this.id,
        this.appBrand
      );

      if (result.type == ResultType.SUCCESS) {
        return result.value;
      }

      return null;
    } catch (e) {
      throw Error(
        `ConfiguratorPostLoader could not load data with the following error: ${e}`
      );
    }
  }
}
