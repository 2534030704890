
import { Component, Vue } from "vue-property-decorator";
import validator from "validator";
import {
  EventBus,
  CHANNEL_NEWPASSWORD_TRIGGER_RESET,
  LoginHandler,
  ResultType,
  User,
  UserRepository
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class NewPassword extends BaseComponent {
  public newPasswordVal: string = "";
  public newPasswordRepeatVal: string = "";
  public attemptSubmit: boolean = false;
  public sendingRequest: boolean = false;
  public error: string =
    "Es ist ein Fehler beim Erstellen Ihres Passwortes aufgetreten. Bitte versuchen Sie es später noch einmal.";

  public errorPasswordField: string = "";
  public errorRepeatPasswordField: string = "";

  public apiError: boolean = false;
  public wrongResetToken: boolean = false;

  async mounted() {
    await super.mounted();

    Vue.nextTick(async () => {
      if (this.isResetForm()) {
        let checkTokenResult = await UserRepository.checkResetToken(
          this.resetToken
        );

        if (checkTokenResult.type == ResultType.FAIL) {
          this.wrongResetToken = true;
        }
      }
    });
  }

  async validate(e: any) {
    this.apiError = false;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateNewPassword && this.validateNewPasswordRepeat) {
      this.sendingRequest = true;

      let result: any;

      if (!this.isResetForm()) {
        result = await UserRepository.newPassword(
          this.newPasswordVal,
          this.newPasswordRepeatVal,
          User.getProperty("deepLoginToken")
        );
      } else {
        result = await UserRepository.changePasswordByResetToken(
          this.resetToken,
          this.newPasswordVal,
          this.newPasswordRepeatVal
        );
      }
      if (result.type == ResultType.SUCCESS) {
        let loginResult = await LoginHandler.login(result.value, () => {
          window.location.replace(window.location.pathname);
        });

        if (!loginResult) {
          this.handleError();
        }
      } else {
        if (result.raw) {
          // new password
          if (result.raw.includes("HCP_NEW_PASSWORD_INVALID")) {
            this.errorPasswordField = "";

            if (result.raw.includes("PASSWORD_SAME_AS_OLD_PASSWORD")) {
              this.errorPasswordField +=
                "Passwort darf nicht dem alten Passwort entsprechen<br />";
            }

            if (result.raw.includes("EMPTY_PASSWORD")) {
              this.errorPasswordField += "Passwort nicht lang genug<br />";
            }

            if (result.raw.includes("TOO_SHORT")) {
              this.errorPasswordField += "Passwort nicht lang genug<br />";
            }

            if (result.raw.includes("TOO_LONG")) {
              this.errorPasswordField += "Passwort zu lang<br />";
            }

            if (
              result.raw.includes("INSUFFICIENT_LOWERCASE") ||
              result.raw.includes("INSUFFICIENT_UPPERCASE") ||
              result.raw.includes("INSUFFICIENT_DIGIT") ||
              result.raw.includes("INSUFFICIENT_ALPHABETICAL") ||
              result.raw.includes("INSUFFICIENT_SPECIAL")
            ) {
              this.errorPasswordField +=
                "es sind nicht alle erforderliche Zeichen verwendet wurden<br />";
            }

            this.apiError = true;
          }

          if (result.raw.includes("OBJECTNOTFOUND")) {
            this.errorPasswordField =
              "Der Resetlink ist ungültig. Bitte fordern Sie einen neuen Link an.<br />";
            this.apiError = true;
          }
        }
      }

      this.sendingRequest = false;
    }
  }

  handleError() {
    // this.consent01 = false;
    alert(this.error);
  }

  reset() {
    this.newPasswordVal = "";
    this.newPasswordRepeatVal = "";
    // this.consent01 = false;
    this.attemptSubmit = false;
    this.sendingRequest = false;
    this.errorPasswordField = "";
    this.errorRepeatPasswordField = "";
    this.apiError = false;
    this.wrongResetToken = false;
  }

  get validateNewPassword() {
    if (!validator.equals(this.newPasswordVal, this.newPasswordRepeatVal)) {
      this.errorRepeatPasswordField = "Passworte stimmen nicht überein";
    } else {
      this.errorRepeatPasswordField = "";
    }

    return (
      !validator.isEmpty(this.newPasswordVal) &&
      validator.equals(this.newPasswordVal, this.newPasswordRepeatVal) &&
      !this.apiError
    );
  }

  get validateNewPasswordRepeat() {
    if (!validator.equals(this.newPasswordVal, this.newPasswordRepeatVal)) {
      this.errorRepeatPasswordField = "Passworte stimmen nicht überein";
    } else {
      this.errorRepeatPasswordField = "";
    }

    return (
      !validator.isEmpty(this.newPasswordRepeatVal) &&
      validator.equals(this.newPasswordVal, this.newPasswordRepeatVal) &&
      !this.apiError
    );
  }

  get resetToken(): string | null {
    return this.getSettingsAttribute("resetToken");
  }

  isResetForm(): boolean {
    return this.resetToken !== null;
  }

  switchToResetPassword() {
    EventBus.$emit(CHANNEL_NEWPASSWORD_TRIGGER_RESET);
  }
}
