
import { Component } from "vue-property-decorator";
import validator from "validator";
import BaseComponent from "../base/BaseComponent.vue";
import { config, Store, UserRepository } from "common";

@Component
export default class ResetPassword extends BaseComponent {
  public email: string = "";
  public urlTemplate: string =
    window.location.origin + "/reset-password?resetToken=$token";
  public beforeRequest: boolean = true;

  async validate(e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.emailIsEmpty) {
      this.beforeRequest = false;
      await UserRepository.resetPassword(this.email, this.urlTemplate);
    }
  }

  get emailIsEmpty() {
    if (validator.isEmpty(this.email) || !validator.isEmail(this.email)) {
      return true;
    }
    return false;
  }

  get dataDismiss() {
    if (Store.isBreastcancer()) {
      return false;
    }

    return "modal";
  }

  dismissAlt(e: Event) {
    if (Store.isBreastcancer()) {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = config.getFrontendConfiguration().redirectRouteAfterLogout;
    }
  }
}
