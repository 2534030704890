
import { Component, Vue } from "vue-property-decorator";
import {
  EventBus,
  CANCER_TYPE_OPEN,
  RECEPTOR_FILTER_SUBMITTED,
  TNM_FILTER_RESETTED,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  Storage,
  TNMFilters,
  TNM_FILTERS_STORAGE_KEY
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class CancerType extends BaseComponent {
  public show_details!: boolean;
  public showMe!: boolean;
  public id!: string;
  private storageFilters!: string | TNMFilters;

  getComponentType() {
    return "cancer_types";
  }

  constructor() {
    super();
    this.show_details = false;
    this.showMe = true;
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);

    this.storageFilters = Storage.getLocal(
      TNM_FILTERS_STORAGE_KEY,
      false,
      false
    );
    if (this.storageFilters) {
      this.storageFilters = JSON.parse(this.storageFilters as string);
    }

    EventBus.$on(RECEPTOR_FILTER_SUBMITTED, this.hideMeBasedOnSelectedType);
    EventBus.$on(TNM_FILTER_RESETTED, this.resetMyVisibility);
    EventBus.$on(CANCER_TYPE_OPEN, this.showDetailsBasedOnEvent);
  }

  async mounted() {
    await super.mounted();
    this.initializeBasedOnStorageFilters();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      let hash = this.$router.currentRoute.hash;

      if (`#${this.shortName}` === hash) {
        this.toggleDetails();
      }
    });
  }

  get shortName() {
    return this.getContentAttribute("short_name");
  }

  get layout() {
    return this.getContentAttribute("content");
  }

  get teaserLayout() {
    return this.layout.filter((layoutElement: any, index: number) => {
      return index <= 1 ? layoutElement : false;
    });
  }

  get detailLayout() {
    return this.layout.filter((layoutElement: any, index: number) => {
      return index > 1 ? layoutElement : false;
    });
  }

  get bookmarker() {
    return LayoutComponent.createEmpty("bookmarker", {
      collection_title: "3D Model",
      collection: {
        link: "cancer_types",
        _id: this.getContentAttribute("_id"),
        display: this.getContentAttribute("id")
      }
    });
  }

  toggleDetails() {
    this.show_details = !this.show_details;

    if (this.show_details) {
      EventBus.$emit(CANCER_TYPE_OPEN, this.id);
      // Matomo tracking
      matomo.genericEvent(
        this.shortName,
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.CLICK_BUTTON_CANCER_TYPE
      );
    }
  }

  hideMeBasedOnSelectedType(payload: any) {
    // Handle HR+/HER+
    const selectedTypes = payload.type.value.split("/");
    if (!selectedTypes.includes(this.shortName)) {
      this.show(false);
    } else {
      Vue.nextTick(() => {
        this.scrollToType();
      });

      // Break so we don't reset the flag
      if (this.show_details) {
        return;
      }
      this.show(true);
      this.toggleDetails();
    }
  }

  scrollToType() {
    const appThemeRoot = document.querySelector("#app > div");

    let menuHeight = 0;

    if (appThemeRoot) {
      menuHeight =
        parseInt(
          getComputedStyle(appThemeRoot).getPropertyValue("--menu-height")
        ) || 0;
    }

    // @ts-ignore
    if (this.$refs.cancerType) {
      // @ts-ignore
      // eslint-disable-next-line
      (this.$refs.cancerType as HTMLElement).style.scrollMarginTop = `${menuHeight}px`;

      // @ts-ignore
      // scroll to the cancer stage
      (this.$refs.cancerType as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }

  checkStorageFilters() {
    if (this.storageFilters) {
      switch ((this.storageFilters as TNMFilters).type.key) {
        case "tnbc":
        case "hrplus":
        case "her2plus":
        case "hrplusher2plus":
          return true;
        case "no_selection":
        default:
          return false;
      }
    }
  }

  initializeBasedOnStorageFilters() {
    if (this.checkStorageFilters()) {
      this.hideMeBasedOnSelectedType(this.storageFilters);
    }
  }

  /**
   * This method is used as an eventlistener for EventBus.TNM_FILTER_RESETTED
   * It will always reset this elements visibility to true
   */
  resetMyVisibility() {
    this.show(true);
  }

  private show(show: boolean) {
    this.showMe = show;
    this.handleCSSForCancerTypeParent(show);
  }

  showDetailsBasedOnEvent(id: string) {
    if (this.id != id) {
      this.show_details = false;
    }
  }

  private handleCSSForCancerTypeParent(show: boolean) {
    let cancerTypeNode = this.$refs.cancerType as HTMLDivElement;
    if (cancerTypeNode) {
      let cancerTypeParent = cancerTypeNode.parentNode as HTMLDivElement;

      if (cancerTypeParent.classList.contains("article-column")) {
        this.toggleCSSClassForCancerTypeParent(show, cancerTypeParent);
      }
    }
  }

  private toggleCSSClassForCancerTypeParent(show: boolean, el: HTMLDivElement) {
    if (!show) {
      el.classList.add("no-children");
    } else {
      el.classList.remove("no-children");
    }
  }
}
