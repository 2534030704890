import config, { FrontendContext } from "../config";
import router from "../router";
import { EventBus, ROUTES_LOADED } from "../util/EventBus";
import { changeContextByRoute } from "../util/Helper";

export enum ShareLink {
  FACEBOOK = "https://www.facebook.com/sharer/sharer.php?u=",
  WHATSAPP = "https://api.whatsapp.com/send?text=",
  EMAIL = "mailto:?&subject=&body="
}

export enum UserEndpoint {
  IDENTITY = "IDENTITY",
  LOGIN = "LOGIN",
  LOGIN_TOKEN = "LOGIN_TOKEN",
  LOGIN_OTP = "LOGIN_OTP",
  LOGIN_BATCH = "LOGIN_BATCH",
  REFRESH = "REFRESH",
  OTP = "OTP",
  NEW_PASSWORD = "NEW_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  PASSWORD_RESET = "PASSWORD_RESET",
  CHANGE_PASSWORD_BY_TOKEN = "CHANGE_PASSWORD_BY_TOKEN",
  CHECK_RESET_TOKEN = "CHECK_RESET_TOKEN",
  MOVE_REQUEST = "MOVE_REQUEST",
  MOVE_FULFILL = "MOVE_FULFILL",
  ACTIVATE = "ACTIVATE",
  REGISTER = "REGISTER",
  PROTECTED = "PROTECTED"
}

export class HCPUserEndpoint {
  public endpoints: { [key: string]: any } = {
    IDENTITY: "/identity",
    LOGIN: "/login",
    LOGIN_TOKEN: "/login/token",
    LOGIN_OTP: "/login/otp",
    LOGIN_BATCH: "/login/batch",
    REFRESH: "/refresh",
    OTP: "/otp",
    NEW_PASSWORD: "/newpassword",
    CHANGE_PASSWORD: "/changepassword",
    PASSWORD_RESET: "/passwordreset",
    CHANGE_PASSWORD_BY_TOKEN: "/changepasswordbyresettoken",
    CHECK_RESET_TOKEN: "/checkresettoken",
    MOVE_REQUEST: "/move/request",
    MOVE_FULFILL: "/move/fulfill",
    ACTIVATE: "",
    REGISTER: "",
    PROTECTED: ""
  };
}

export class PatientUserEndpoint {
  public endpoints: { [key: string]: any } = {
    IDENTITY: "",
    LOGIN: "/login/password",
    LOGIN_TOKEN: "",
    LOGIN_OTP: "",
    LOGIN_BATCH: "/login/batch",
    REFRESH: "/refresh",
    OTP: "",
    NEW_PASSWORD: "/newpassword",
    CHANGE_PASSWORD: "/password/change",
    PASSWORD_RESET: "/password/reset",
    CHANGE_PASSWORD_BY_TOKEN: "/password/changebytoken",
    CHECK_RESET_TOKEN: "",
    MOVE_REQUEST: "/move/request",
    MOVE_FULFILL: "/move/fulfill",
    ACTIVATE: "/activate",
    REGISTER: "/register",
    PROTECTED: "/protected"
  };
}

export const mapLayoutComponentToCollection: { [key: string]: string } = {
  card_images: "CardImage",
  card_videos: "CardVideo",
  podcasts: "Podcast"
};

export default class Constants {
  static baseUrl = process.env.VUE_APP_COCKPIT_BASE_URL;
  static apiUrl = process.env.VUE_APP_API_URL;

  static storageUrl = Constants.baseUrl + "/storage/uploads";
  static uploadsUrl = Constants.baseUrl + "/storage/uploads";
  static middlewareStorageUrl = Constants.apiUrl + "/cdn/storage/uploads";

  static cockpitUrl = Constants.apiUrl + "/cockpit";
  static collectionsUrl = Constants.cockpitUrl + "/api/collections";
  static configurationUrl = Constants.apiUrl + "/configuration";
  static debugUrl = Constants.apiUrl + "/debug";
  static dominantColorUrl = Constants.apiUrl + "/image/dominant_color";
  static eventUrl = Constants.apiUrl + "/event";
  static filterUrl = Constants.apiUrl + "/filter";
  static guidelineUrl = Constants.apiUrl + "/guideline/parent";
  static newsletterUrl = Constants.apiUrl + "/newsletter";
  static offlabelUrl = Constants.apiUrl + "/offlabel";
  static placeOrderUrl = Constants.apiUrl + "/order";
  static ratingUrl = Constants.apiUrl + "/rating";
  static sampleUrl = Constants.apiUrl + "/samples";
  static transparencyUrl = Constants.apiUrl + "/transparency";
  static dynamicArticlesUrl = Constants.apiUrl + "/dynamic-articles";
  static appBrandUrl = Constants.apiUrl + "/app_brand";
  static suggestionsUrl = "http://suggestqueries.google.com/complete/search";
  static slideDeckUrl = Constants.apiUrl + "/slide-deck";
  static trainingQuizUrl = Constants.apiUrl + "/training-quiz";
  static trainingQuizVideoUrl = Constants.apiUrl + "/training-quiz/video";
  static trainingQuizAnswerUrl = Constants.apiUrl + "/training-quiz/answer";
  static trainingQuizFinalizeUrl = Constants.apiUrl + "/training-quiz/finalize";
  static trainingQuizRatingUrl = Constants.apiUrl + "/training-quiz/rating";
  static trainingsUrl = Constants.apiUrl + "/training-quiz/trainings";

  static expertforumApiUrl = process.env.VUE_APP_EXPERTFORUM_API_URL;
  static expertforumToken = process.env.VUE_APP_EXPERTFORUM_TOKEN;
  static expertforumSlug = process.env.VUE_APP_EXPERTFORUM_SLUG;

  static userUrl = Constants.getUserUrl();
  static userIdentityUrl = Constants.getUserUrlFor(UserEndpoint.IDENTITY);
  static userLoginUrl = Constants.getUserUrlFor(UserEndpoint.LOGIN);
  static userLoginTokenUrl = Constants.getUserUrlFor(UserEndpoint.LOGIN_TOKEN);
  static userLoginOTPUrl = Constants.getUserUrlFor(UserEndpoint.LOGIN_OTP);
  static userRefreshUrl = Constants.getUserUrlFor(UserEndpoint.REFRESH);
  static userOTPUrl = Constants.getUserUrlFor(UserEndpoint.OTP);
  static userBatchLoginUrl = Constants.getUserUrlFor(UserEndpoint.LOGIN_BATCH);
  static userNewPasswordUrl = Constants.getUserUrlFor(
    UserEndpoint.NEW_PASSWORD
  );
  static userChangePasswordUrl = Constants.getUserUrlFor(
    UserEndpoint.CHANGE_PASSWORD
  );
  static userPasswordResetUrl = Constants.getUserUrlFor(
    UserEndpoint.PASSWORD_RESET
  );
  static userChangePasswordByTokenUrl = Constants.getUserUrlFor(
    UserEndpoint.CHANGE_PASSWORD_BY_TOKEN
  );
  static userCheckResetTokenUrl = Constants.getUserUrlFor(
    UserEndpoint.CHECK_RESET_TOKEN
  );
  static userMoveRequestUrl = Constants.getUserUrlFor(
    UserEndpoint.MOVE_REQUEST
  );
  static userMoveFulfillUrl = Constants.getUserUrlFor(
    UserEndpoint.MOVE_FULFILL
  );
  static userActivateUrl = Constants.getUserUrlFor(UserEndpoint.ACTIVATE);
  static userRegisterUrl = Constants.getUserUrlFor(UserEndpoint.REGISTER);
  static userProtectedUrl = Constants.getUserUrlFor(UserEndpoint.PROTECTED);

  static orderListToken = process.env.VUE_APP_ORDERLIST_TOKEN;
  static ratingModalDelay = process.env.VUE_APP_RATING_MODAL_DELAY;
  static newsletterToken = process.env.VUE_APP_NEWSLETTER_TOKEN;

  static getUserUrl() {
    changeContextByRoute(config);

    if (config.getFrontendConfiguration().context == FrontendContext.PATIENT) {
      return Constants.apiUrl + "/patient";
    }

    return Constants.apiUrl + "/hcp";
  }

  static getUserUrlFor(e: UserEndpoint) {
    let userUrl = this.getUserUrl();
    changeContextByRoute(config);

    let endpoint =
      config.getFrontendConfiguration().context == FrontendContext.PATIENT
        ? new PatientUserEndpoint()
        : new HCPUserEndpoint();

    return endpoint.endpoints[e] != "" ? userUrl + endpoint.endpoints[e] : "";
  }

  static getBookmarksUrl() {
    return Constants.apiUrl + "/bookmarks";
  }

  static getBookmarkUrl(append: string = "") {
    if (append.length > 0) {
      append = "/" + append;
    }

    return Constants.apiUrl + "/bookmark" + append;
  }

  static getBookmarkListUrl(append: string = "") {
    if (append.length > 0) {
      append = "/" + append;
    }

    return Constants.apiUrl + "/bookmarklist" + append;
  }

  static speakerImageUrl(imageId: any) {
    return Constants.eventUrl + "/speakerImage/" + imageId;
  }

  static eventDocumentUrl(docId: any) {
    return Constants.eventUrl + "/document/" + docId;
  }

  static eventShowUrl(eventId: any, userId: any) {
    return Constants.eventUrl + "/" + eventId; // + '/user/' + userId; does not work currently
  }

  static veevaEventShowUrl(veevaEventId: any) {
    return Constants.eventUrl + "/veeva/" + veevaEventId;
  }

  static eventRegisterUrl() {
    return Constants.eventUrl + "/register";
  }

  static eventSelfregisterUrl() {
    return Constants.eventUrl + "/selfregister";
  }

  static eventSelfregisterPdfUrl() {
    return Constants.eventUrl + "/gob-registration";
  }

  static eventRegisterMultiUrl() {
    return Constants.eventRegisterUrl() + "/multi";
  }

  static eventsListUrl(listType: string) {
    return Constants.eventUrl + "/list/" + listType;
  }

  static eventUserSessionsUrl() {
    return Constants.eventUrl + "/sessions";
  }

  static collectionSchema(collectionName: string) {
    return Constants.collectionsUrl + "/collection/" + collectionName;
  }

  static collectionEntries(collectionName: string) {
    return Constants.collectionsUrl + "/get/" + collectionName;
  }

  static getArticleListUrl(append: string = "") {
    if (append.length > 0) {
      append = "/" + append;
    }

    return `${Constants.apiUrl}/articles${append}`;
  }

  static externalLinkWhitelist(): string[] {
    return config.getExternalLinkWhiteList();
  }

  static internalLinkBlackList(): string[] {
    return config.getInternalLinkBlackList();
  }

  static getLikePostUrl(append: string = "") {
    if (append.length > 0) {
      append = "/" + append;
    }

    return `${Constants.apiUrl}/likes${append}`;
  }

  static getWidgetExternalUrl(type: string) {
    if (type === "AnzAktiv") {
      return "https://services7.arcgis.com/mOBPykOjAyBO2ZKk/arcgis/rest/services/rki_key_data_v/FeatureServer/0/query?where=AdmUnitID+%3D+0%0D%0A&outFields=AdmUnitId%2C+AnzAktiv%2C+Inz7T%2C+AnzAktivNeu&f=pgeojson";
    } else if (type === "Inzidenz_7-Tage") {
      return "https://raw.githubusercontent.com/robert-koch-institut/COVID-19_7-Tage-Inzidenz_in_Deutschland/main/COVID-19-Faelle_7-Tage-Inzidenz_Deutschland.csv";
    } else if (type === "PS_7_Tage_R_Wert") {
      return "https://raw.githubusercontent.com/robert-koch-institut/SARS-CoV-2-Nowcasting_und_-R-Schaetzung/main/Nowcast_R_aktuell.csv";
    } else if (type === "7T_Hospitalisierung_Inzidenz") {
      return "https://raw.githubusercontent.com/robert-koch-institut/COVID-19-Hospitalisierungen_in_Deutschland/master/Aktuell_Deutschland_COVID-19-Hospitalisierungen.csv";
    } else if (type === "fixierte_7T_Hospitalisierung_Inzidenz") {
      return "https://raw.githubusercontent.com/robert-koch-institut/COVID-19-Hospitalisierungen_in_Deutschland/master/Aktuell_Deutschland_adjustierte-COVID-19-Hospitalisierungen.csv";
    }

    return "";
  }

  static getParameters() {
    return {
      baseUrl: Constants.baseUrl,
      apiUrl: Constants.apiUrl,
      storageUrl: Constants.storageUrl,
      uploadsUrl: Constants.uploadsUrl,
      middlewareStorageUrl: Constants.middlewareStorageUrl,
      cockpitUrl: Constants.cockpitUrl,
      collectionsUrl: Constants.collectionsUrl,
      appBrandUrl: Constants.appBrandUrl,
      dominantColorUrl: Constants.dominantColorUrl,
      dynamicArticlesUrl: Constants.dynamicArticlesUrl,
      eventUrl: Constants.eventUrl,
      placeOrderUrl: Constants.placeOrderUrl,
      configurationUrl: Constants.configurationUrl,
      debugUrl: Constants.debugUrl,
      offlabelUrl: Constants.offlabelUrl,
      sampleUrl: Constants.sampleUrl,
      filterUrl: Constants.filterUrl,
      transparencyUrl: Constants.transparencyUrl,
      ratingUrl: Constants.ratingUrl,
      guidelineUrl: Constants.guidelineUrl,
      newsletterUrl: Constants.newsletterUrl,
      userUrl: Constants.userUrl,
      userIdentityUrl: Constants.userIdentityUrl,
      userLoginUrl: Constants.userLoginUrl,
      userLoginTokenUrl: Constants.userLoginTokenUrl,
      userLoginOTPUrl: Constants.userLoginOTPUrl,
      userRefreshUrl: Constants.userRefreshUrl,
      userOTPUrl: Constants.userOTPUrl,
      userNewPasswordUrl: Constants.userNewPasswordUrl,
      userChangePasswordUrl: Constants.userChangePasswordUrl,
      userPasswordResetUrl: Constants.userPasswordResetUrl,
      userChangePasswordByTokenUrl: Constants.userChangePasswordByTokenUrl,
      userCheckResetTokenUrl: Constants.userCheckResetTokenUrl,
      userMoveRequestUrl: Constants.userMoveRequestUrl,
      userMoveFulfillUrl: Constants.userMoveFulfillUrl,
      userActivateUrl: Constants.userActivateUrl,
      userRegisterUrl: Constants.userRegisterUrl,
      userProtectedUrl: Constants.userProtectedUrl,
      orderListToken: Constants.orderListToken,
      ratingModalDelay: Constants.ratingModalDelay,
      newsletterToken: Constants.newsletterToken
    };
  }
}
