
import { Component } from "vue-property-decorator";
import { isNil } from "lodash";
import BaseComponent from "../base/BaseComponent.vue";
import { ShareLink, User } from "common";

@Component
export default class Share extends BaseComponent {
  async mounted() {
    super.mounted();
  }

  clickHandler(event: UIEvent) {
    if (this.hasToCopyToClipboard) {
      event.preventDefault();

      navigator.clipboard.writeText(this.shareLink);
    }
  }

  get user() {
    return User;
  }

  get bookmarkListId() {
    return this.user.getProperty("bookmarkListId");
  }

  get shareLink() {
    if (this.shareBookmarkList) {
      return `${window.location.origin}/shared-bookmarks?id=${this.bookmarkListId}`;
    }

    return window.location.href;
  }

  get hasToCopyToClipboard() {
    return !this.type;
  }

  get link() {
    if (!this.type) {
      return "#";
    }

    return `${this.type}${this.shareLink}`;
  }

  get label() {
    return this.getSettingsAttribute("btn_label");
  }

  get type() {
    switch (this.getSettingsAttribute("type")) {
      case "facebook":
        return ShareLink.FACEBOOK;
      case "whatsapp":
        return ShareLink.WHATSAPP;
      case "email":
        return ShareLink.EMAIL;
      default:
        return null;
    }
  }

  get target() {
    return isNil(this.getSettingsAttribute("target"))
      ? "_self"
      : this.getSettingsAttribute("target");
  }

  get customShareUrl() {
    const url = this.getSettingsAttribute("custom_share_url");
    return url && url.trim().length > 0 ? url : null;
  }

  get shareBookmarkList() {
    return !!this.getSettingsAttribute("share_bookmark_list");
  }
}
