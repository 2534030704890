
import { BookmarkRepository, BookmarkList, ResultType, User } from "common";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Bookmarks extends Vue {
  public bookmarkList!: any;

  constructor() {
    super();
    this.bookmarkList = null;
  }

  async mounted() {
    let result = await BookmarkRepository.getList(
      this.user.getProperty("bookmarkListId")
    );

    if (result.type == ResultType.SUCCESS) {
      BookmarkList.setList(result.value);
      this.updateBookmarkList();
    }
  }

  get user() {
    return User;
  }

  getBookmarkKey(id: number) {
    return id + "_" + Date.now();
  }

  updateBookmarkList() {
    this.bookmarkList = BookmarkList.getList();
  }
}
