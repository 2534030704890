
import { Component, Vue } from "vue-property-decorator";
import {
  LayoutComponent,
  matomo,
  MatomoEventNames,
  MimeTypes,
  RoutingHelper
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Download extends BaseComponent {
  public externalFileSize!: number | 0;
  public externalFileType: any;
  public renderComponent!: boolean;

  getComponentType() {
    return ["files", "external_files"];
  }

  async mounted() {
    super.mounted();
    this.renderComponent = false;
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      // let fetchUrl = this.getContentAttribute("external_file");
      // if (fetchUrl) {
      //   const response = await fetch(fetchUrl)
      //     .then(response => response.blob())
      //     .then(blob => {
      //       return blob;
      //     });
      //   this.externalFileSize = response.size;
      //   this.externalFileType = response.type;
      // }
      this.renderComponent = true;
      this.$forceUpdate();
    });
  }

  get file() {
    let file = this.getContentAttribute("file");
    return file ? file : null;
  }

  get external_file() {
    let externalFile = this.getContentAttribute("external_file");
    return externalFile ? externalFile : null;
  }

  clickHandler(ev: any): any {
    if (this.use_readspeaker && this.path != null) {
      ev.preventDefault();
      window.open(this.path, "dcrwin");
    }

    // Matomo tracking
    matomo.genericEvent(
      this.path || "click_download",
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.CLICK_DOWNLOAD
    );
  }

  get path() {
    if (!this.file && !this.external_file) {
      return null;
    }

    if (this.use_readspeaker) {
      let encodedUrl;
      if (this.file) {
        encodedUrl = encodeURIComponent(
          RoutingHelper.getFilePath(this.file.path)
        );
      }
      if (this.external_file) {
        encodedUrl = this.external_file;
      }
      return `//docreader.readspeaker.com/docreader/?cid=chhfg&lang=de_de&url=${encodedUrl}`;
    } else {
      if (this.file) {
        return RoutingHelper.getFilePath(this.file.path);
      }
      if (this.external_file) {
        return this.external_file;
      }
    }

    return null;
  }

  get label() {
    return this.getSettingsAttribute("label") || "Download";
  }

  get size() {
    if (this.file) {
      return (
        Math.round((this.file.size / 1024 / 1024 + Number.EPSILON) * 100) / 100
      );
    }
    if (this.external_file && this.externalFileSize) {
      return (
        Math.round(
          (this.externalFileSize / 1024 / 1024 + Number.EPSILON) * 100
        ) / 100
      );
    }
    return 0;
  }

  get type() {
    if (this.file) {
      return this.getFileExtension(this.file.mime);
    }

    if (this.external_file && this.externalFileType) {
      return this.getFileExtension(this.externalFileType);
    }

    return "";
  }

  get show_preview_image() {
    return this.getSettingsAttribute("show_preview_image") || false;
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get icon() {
    return "/assets/download/download.svg";
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }

  get use_readspeaker() {
    return !!this.getSettingsAttribute("use_readspeaker") || false;
  }

  getFileExtension(type: String) {
    for (let [extension, mimeType] of Object.entries(MimeTypes)) {
      if (type == mimeType) {
        return extension;
      }
    }

    let mimeArr = type.split("/");
    if (mimeArr.length > 0) {
      return mimeArr[mimeArr.length - 1].toUpperCase();
    }

    return "";
  }
}
