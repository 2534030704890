
import { Component, Vue } from "vue-property-decorator";
import { LayoutComponent, router } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class TextWidget extends BaseWidget {
  private slug: any = null;

  getComponentType() {
    return "view_widgets";
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(async () => {
      let cockpitLink = this.getContentAttribute("cockpit_link");
      if (cockpitLink) {
        let slugResponse = await this.loadContent(
          cockpitLink.link,
          [cockpitLink._id],
          0,
          {
            slug: 1,
            _id: 0
          }
        );

        if (slugResponse) {
          this.slug = slugResponse[0].slug;
        }
      }
    });
  }

  get headlines() {
    if (this.headline && this.headline.length > 0) {
      let headlines: LayoutComponent[] = [];
      this.headline.forEach((hl: any) => {
        let hllc = new LayoutComponent(hl);
        if (hllc.visible) {
          headlines.push(hllc);
        }
      });
      return headlines;
    }
    return [];
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get link() {
    return "/" + this.slug;
  }

  gotoView() {
    if (this.link) {
      router.push(this.link);
    }
  }
}
