
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { MenuItem as MenuItemModel } from "common";

@Component
export default class Menu extends BaseComponent {
  getComponentType() {
    return "menues";
  }

  async mounted() {
    await super.mounted();
  }

  get items() {
    let items = this.getContentAttribute("items");
    let menuItems: MenuItemModel[] = [];
    if (items) {
      items.forEach((item: any) => {
        menuItems.push(new MenuItemModel(item));
      });
    }

    return menuItems;
  }

  get visibleMenuItems(): MenuItemModel[] {
    if (this.items == null) {
      return [];
    }

    return this.items.filter((item: any) => {
      return item.visibility;
    });
  }
}
