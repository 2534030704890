
import { Component, Prop } from "vue-property-decorator";
import { Event, EventDisplayOptions } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class EventListItem extends BaseComponent {
  @Prop() event!: Event;
  @Prop() selected!: boolean;
  @Prop() displayOptions!: EventDisplayOptions;

  selectEvent() {
    this.$emit("selectEvent", this.event);
  }

  selectEventSpeaker() {
    this.$emit("selectEventSpeaker", this.event);
  }

  speakerSelected() {
    return this.event.subscribeAsSpeaker;
  }

  speakerSelectable() {
    return this.event.showSpeakerSubscription;
  }
}
