
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class EventWidget extends BaseWidget {
  getComponentType() {
    return "event_widgets";
  }

  async mounted() {
    super.mounted();
  }

  get images() {
    if (this.image) {
      let images: LayoutComponent[] = [];
      this.image.forEach((img: any) => {
        images.push(new LayoutComponent(img));
      });
      return images;
    }
    return [];
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get image() {
    return this.getContentAttribute("image");
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get label_location() {
    return this.getContentAttribute("label_location");
  }

  get label_calendar() {
    return this.getContentAttribute("label_calendar");
  }

  get icon_calendar() {
    let icons = this.getContentAttribute("icon_calendar");
    if (icons && icons.length > 0) {
      return new LayoutComponent(icons[0]);
    }
    return null;
  }

  get icon_location() {
    let icons = this.getContentAttribute("icon_location");
    if (icons && icons.length > 0) {
      return new LayoutComponent(icons[0]);
    }
    return null;
  }
}
