
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class DigitalBusinessCard extends BaseComponent {
  public cardData!: any;

  constructor() {
    super();
    this.cardData = {};
  }

  getComponentType() {
    return "digital_business_cards";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.cardData = this.getCardData();
    });
  }

  get metaData() {
    return this.getContentAttribute("meta");
  }

  get cardId() {
    return this.getSettingsAttribute("card_id");
  }

  private getCardData() {
    if (this.metaData && this.metaData.length) {
      const result = this.metaData.find((item: any) => item.id == this.cardId);
      return result ? result : {};
    }

    return {};
  }

  private getImageSrc(img: string) {
    return img && img.length > 0 ? img : `/assets/speakerDefault.png`;
  }
}
