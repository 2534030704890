
import { Component, Vue, Watch } from "vue-property-decorator";
import validator from "validator";
import { EventRepository, ResultType, UserProfile } from "common";

@Component
export default class Selfregister extends Vue {
  readonly TYPE_CLINICIAN = "CLINICIAN";
  readonly TYPE_ESTABLISHED_MEDICAL = "ESTABLISHED_MEDICAL";
  readonly TYPE_PHARMACIST = "PHARMACIST";

  readonly GENDER_MALE = "MALE";
  readonly GENDER_FEMALE = "FEMALE";
  readonly GENDER_DIVERSE = "DIVERSE";

  public infoBoxIsCollapsed: boolean;

  public attemptSubmit: boolean;
  public registerSucceded: boolean;
  public resultMessage!: any;
  public isLoading!: boolean;
  public apiError: boolean;

  public registerType: string;
  public registerGender: string;
  public registerTitle: string;
  public registerFirstName: string;
  public registerLastName: string;
  public registerPhone: string;
  public registerEmail: string;
  public registerEfn: string;
  public registerInstitution: string;
  public registerDepartment: string;
  public registerZip: string;
  public registerCity: string;
  public registerStreet: string;
  public registerStreetNbr: string;
  public registerPassword1: string;
  public registerPassword2: string;
  public registerDataPrivacyStatement: boolean;

  public institutionPlaceholder: string;
  public departmentPlaceholder: string;

  public errorRegisterEmail: string;
  public errorRegisterPassword1: string;
  public errorRegisterPassword2: string;
  public errorRegisterEvents: string;

  public static error_message_api =
    "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";

  public static error_message =
    "Leider konnten wir Ihre Anmeldung nicht entgegen nehmen. Bitte versuchen Sie es erneut oder nehmen Sie Kontakt mit uns auf.";

  public static success_message = "Vielen Dank <br/>für Ihre Anmeldung!";

  constructor() {
    super();

    this.infoBoxIsCollapsed = true;

    this.attemptSubmit = false;
    this.registerSucceded = false;
    this.isLoading = false;
    this.resultMessage = null;
    this.apiError = false;

    this.registerType = "CLINICIAN";
    this.registerGender = "MALE";
    this.registerTitle = "";
    this.registerFirstName = "";
    this.registerLastName = "";
    this.registerPhone = "";
    this.registerEmail = "";
    this.registerEfn = "";
    this.registerInstitution = "";
    this.registerDepartment = "";
    this.registerZip = "";
    this.registerCity = "";
    this.registerStreet = "";
    this.registerStreetNbr = "";
    this.registerPassword1 = "";
    this.registerPassword2 = "";
    this.registerDataPrivacyStatement = false;

    this.institutionPlaceholder = "Klinik*";
    this.departmentPlaceholder = "Abteilung*";

    this.errorRegisterEmail = "";
    this.errorRegisterPassword1 = "";
    this.errorRegisterPassword2 = "";
    this.errorRegisterEvents = "";
  }

  async validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (
      this.validateRegisterFirstName &&
      this.validateRegisterLastName &&
      this.validateRegisterEmail &&
      this.validateRegisterInstitution &&
      this.validateRegisterDepartment &&
      this.validateRegisterZip &&
      this.validateRegisterCity &&
      this.validateRegisterStreet &&
      this.validateRegisterStreetNbr &&
      this.validateRegisterPassword1 &&
      this.validateRegisterPassword2
    ) {
      this.sendSelfregister({
        veevaEventIds: ["AZDa5D1i0000005Uj9EAE"],
        type: this.registerType,
        gender: this.registerGender,
        title: this.registerTitle,
        firstName: this.registerFirstName,
        lastName: this.registerLastName,
        email: this.registerEmail,
        efn: this.registerEfn,
        institution: this.registerInstitution,
        department: this.registerDepartment,
        street: this.registerStreet,
        streetNbr: this.registerStreetNbr,
        zip: this.registerZip,
        city: this.registerCity,
        password1: this.registerPassword1,
        password2: this.registerPassword2,
        accountProfile: this.registerDataPrivacyStatement
          ? UserProfile.MMC_PLUS
          : UserProfile.MMC_BASIC
      });
    } else {
      this.focusFirstError();
    }

    this.isLoading = false;
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
    }, 1);
  }

  async sendSelfregister(data: any) {
    let result = await EventRepository.sendEventSelfregister(data);
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.type == ResultType.SUCCESS) {
      this.resultMessage = Selfregister.success_message;
      this.registerSucceded = true;
    } else {
      switch (result.statusCode) {
        case 500:
          this.resultMessage = Selfregister.error_message_api;
          this.apiError = true;
          break;
        case 400:
          try {
            this.resultMessage = result.raw
              ? this._getErrorMessage(JSON.parse(result.raw).description[0])
              : Selfregister.error_message;
          } catch (error) {
            this.resultMessage = Selfregister.error_message;
          }

          this.apiError = true;
          break;
      }
    }
    this.isLoading = false;
  }

  _getErrorMessage(error_description: any) {
    switch (error_description.error) {
      case "HCP_ACCOUNT_EXISTS":
        return "Ein Account mit der angegebenen E-Mail-Adresse ist bereits vorhanden.";

      case "HCP_NEW_PASSWORD_INVALID":
        return "Das Passwort entspricht nicht den Anforderungen. Bitte verwenden Sie ein stärkeres Passwort.";

      default:
        return Selfregister.error_message;
    }
  }

  toogleInfoBox(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.infoBoxIsCollapsed = !this.infoBoxIsCollapsed;
  }

  get validateRegisterFirstName() {
    return !validator.isEmpty(this.registerFirstName);
  }

  get validateRegisterLastName() {
    return !validator.isEmpty(this.registerLastName);
  }

  get validateRegisterEmail() {
    if (
      validator.isEmpty(this.registerEmail) ||
      !validator.isEmail(this.registerEmail)
    ) {
      this.errorRegisterEmail =
        "Bitte geben Sie eine gültige E-Mail Adresse an.";
    } else {
      this.errorRegisterEmail = "";
    }

    return (
      !validator.isEmpty(this.registerEmail) &&
      validator.isEmail(this.registerEmail)
    );
  }

  get validateRegisterInstitution() {
    if (this.registerType == this.TYPE_CLINICIAN) {
      return !validator.isEmpty(this.registerInstitution);
    }

    return true;
  }

  get validateRegisterDepartment() {
    if (this.registerType == this.TYPE_CLINICIAN) {
      return !validator.isEmpty(this.registerDepartment);
    }

    return true;
  }

  get validateRegisterZip() {
    return !validator.isEmpty(this.registerZip);
  }

  get validateRegisterCity() {
    return !validator.isEmpty(this.registerCity);
  }

  get validateRegisterStreet() {
    return !validator.isEmpty(this.registerStreet);
  }

  get validateRegisterStreetNbr() {
    return !validator.isEmpty(this.registerStreetNbr);
  }

  get validateRegisterPassword1() {
    const regex =
      "^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\u0028\\u0029\\u005B\\u005D\\u007B\\u007D\\u003F\\u0021\\u005C\\u0024\\u0025\\u0026\\u002F\\u003D\\u002A\\u002B\\u007E\\u002C\\u002E\\u003B\\u003A\\u003C\\u003E\\u002D\\u005F]).*$";

    if (!validator.matches(this.registerPassword1, regex)) {
      this.errorRegisterPassword1 =
        "Das Passwort entspricht nicht den Anforderungen.";
    } else {
      this.errorRegisterPassword1 = "";
    }

    return (
      !validator.isEmpty(this.registerPassword1) &&
      validator.matches(this.registerPassword1, regex)
    );
  }

  get validateRegisterPassword2() {
    if (!validator.equals(this.registerPassword1, this.registerPassword2)) {
      this.errorRegisterPassword2 = "Passwörter stimmen nicht überein";
    } else {
      this.errorRegisterPassword2 = "";
    }

    return (
      !validator.isEmpty(this.registerPassword2) &&
      validator.equals(this.registerPassword1, this.registerPassword2)
    );
  }

  @Watch("registerType")
  changeRegisterType() {
    if (this.registerType == this.TYPE_CLINICIAN) {
      this.institutionPlaceholder = "Klinik*";
      this.departmentPlaceholder = "Abteilung*";
    } else if (this.registerType == this.TYPE_ESTABLISHED_MEDICAL) {
      this.institutionPlaceholder = "Klinik";
      this.departmentPlaceholder = "Abteilung";
    }
  }
}
