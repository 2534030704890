import { Store } from "./util/Store";

export enum FrontendModules {
  LOGIN = "LOGIN",
  BATCH_LOGIN = "BATCH_LOGIN",
  TESTGUIDELINE_ROUTING = "TESTGUIDELINE_ROUTING",
  TEALIUM = "TEALIUM",
  READSPEAKER = "READSPEAKER",
  EXPERT_FORUM = "EXPERT_FORUM",
  MAY_WATCH_ONE = "MAY_WATCH_ONE"
}

export enum FrontendContext {
  HCP = "HCP",
  PATIENT = "PATIENT"
}

export enum EnvironmentModules {
  MATOMO = "MATOMO",
  DEBUG = "DEBUG"
}

export enum ConfigurationEnvironment {
  LOCAL = "LOCAL",
  DEVELOP = "DEVELOP",
  TEST = "TEST",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION"
}

export class MappedRoute {
  public id!: string;
  public slug!: string;

  constructor(id: string, slug: string) {
    this.id = id;
    this.slug = slug;
  }
}

interface ConfigurationInterface {
  getMoveTokenWhiteList(): string[];
  getExternalLinkWhiteList(): string[];
  getInternalLinkBlackList(): string[];
  getPublicRoutes(): string[];
  getPrivateRoutes(): string[];
  getMappedPublicRoutes(): MappedRoute[];
}

export interface EnvironmentConfigurationInterface
  extends ConfigurationInterface {
  getFrontendConfiguration(): FrontendConfigurationInterface;
  getEnv(): ConfigurationEnvironment;
  modules: EnvironmentModules[];
}

interface FrontendConfigurationInterface extends ConfigurationInterface {
  defaultContext: FrontendContext;
  modules: FrontendModules[];
  context: FrontendContext;
  redirectRouteAfterLogout: string;
  meta: { [key: string]: any };
  contextByRoute: { slug: string; context: FrontendContext }[];
  setContext: FrontendContext;
}

class Config implements ConfigurationInterface {
  protected generalConfig = new GeneralConfig();
  protected frontendConfig!: FrontendConfigurationInterface;

  constructor(frontendConfig: FrontendConfigurationInterface) {
    this.frontendConfig = frontendConfig;
  }

  getMoveTokenWhiteList(): string[] {
    let generalMoveTokenWhiteList: string[] = this.generalConfig.getMoveTokenWhiteList();
    let frontendMoveTokenWhiteList: string[] = this.frontendConfig.getMoveTokenWhiteList();
    return generalMoveTokenWhiteList.concat(frontendMoveTokenWhiteList);
  }

  getExternalLinkWhiteList(): string[] {
    let generalExternalLinkWhiteList: string[] = this.generalConfig.getExternalLinkWhiteList();
    let frontendExternalLinkWhiteList: string[] = this.frontendConfig.getExternalLinkWhiteList();
    return generalExternalLinkWhiteList.concat(frontendExternalLinkWhiteList);
  }

  getInternalLinkBlackList(): string[] {
    let generalExternalLinkBlackList: string[] = this.generalConfig.getInternalLinkBlackList();
    let frontendExternalLinkBlackList: string[] = this.frontendConfig.getInternalLinkBlackList();
    return generalExternalLinkBlackList.concat(frontendExternalLinkBlackList);
  }

  getPublicRoutes(): string[] {
    let generalPublicRoutes: string[] = this.generalConfig.getPublicRoutes();
    let frontendPublicRoutes: string[] = this.frontendConfig.getPublicRoutes();
    return generalPublicRoutes.concat(frontendPublicRoutes);
  }

  getPrivateRoutes(): string[] {
    let generalPrivateRoutes: string[] = this.generalConfig.getPrivateRoutes();
    let frontendPrivateRoutes: string[] = this.frontendConfig.getPrivateRoutes();
    return generalPrivateRoutes.concat(frontendPrivateRoutes);
  }

  getMappedPublicRoutes(): MappedRoute[] {
    let generalMappedPublicRoutes: MappedRoute[] = this.generalConfig.getMappedPublicRoutes();
    let frontendMappedPublicRoutes: MappedRoute[] = this.frontendConfig.getMappedPublicRoutes();
    return generalMappedPublicRoutes.concat(frontendMappedPublicRoutes);
  }

  getEnv() {
    return "";
  }

  toJson(): any {
    return {
      env: this.getEnv(),
      moveTokenWhiteList: this.getMoveTokenWhiteList(),
      externalLinkWhiteList: this.getExternalLinkWhiteList(),
      internalLinkBlackList: this.getInternalLinkBlackList(),
      publicRoutes: this.getPublicRoutes(),
      privateRoutes: this.getPrivateRoutes(),
      mappedPublicRoutes: this.getMappedPublicRoutes(),
      frontendModules: this.frontendConfig.modules,
      frontendContext: this.frontendConfig.context
    };
  }
}

/**
 * General configuration for all environments
 * and all frontends
 */
class GeneralConfig implements ConfigurationInterface {
  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [
      "azprivacy.astrazeneca.com",
      "astrazeneca-us.com",
      // "policy.cookiereports.com",
      "do-not-sell-my-personal-information.astrazeneca.com",
      "us-cookienotice.astrazeneca.com",
      // == AZ US ==
      // cancer support 360
      "cancersupportplus.com",
      "cancer-support-360.azus.digital",
      // hcp
      "cancersupportplus-hcp.com",
    ];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [
      "/imprint",
      "/privacy",
      "/terms_of_use",
      "/contact",
      "/404",
      "/error",
      "/null",
      "/doccheck",
      "/login"
    ];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

/**
 * Production configuration for all frontends
 */
class ProductionConfig extends Config
  implements EnvironmentConfigurationInterface {
  constructor(frontendConfig: FrontendConfigurationInterface) {
    super(frontendConfig);
  }

  getEnv(): ConfigurationEnvironment {
    return ConfigurationEnvironment.PRODUCTION;
  }

  get modules(): EnvironmentModules[] {
    return [EnvironmentModules.MATOMO];
  }

  getFrontendConfiguration(): FrontendConfigurationInterface {
    return this.frontendConfig;
  }

  getMoveTokenWhiteList(): string[] {
    let productionMoveTokenWhitelist = [
      "az-produkte.de",
      "mein-medcampus.de",
      "gynonco-barcamp.de",
      "team-typ2.de",
      "cll-im-dialog.de",
      "test-to-treat.de"
    ];
    return super.getMoveTokenWhiteList().concat(productionMoveTokenWhitelist);
  }

  getExternalLinkWhiteList(): string[] {
    let productionExternalLinkWhitelist: string[] = [];
    return super
      .getExternalLinkWhiteList()
      .concat(productionExternalLinkWhitelist);
  }

  getInternalLinkBlackList(): string[] {
    let productionInternalLinkBlacklist: string[] = [];
    return super
      .getInternalLinkBlackList()
      .concat(productionInternalLinkBlacklist);
  }

  getPublicRoutes(): string[] {
    let productionPublicRoutes: string[] = [];
    return super.getPublicRoutes().concat(productionPublicRoutes);
  }

  getPrivateRoutes(): string[] {
    let productionPrivateRoutes: string[] = [];
    return super.getPrivateRoutes().concat(productionPrivateRoutes);
  }

  getMappedPublicRoutes(): MappedRoute[] {
    let productionPublicMappedRoutes: MappedRoute[] = [];
    return super.getMappedPublicRoutes().concat(productionPublicMappedRoutes);
  }
}

/**
 * Staging configuration for all frontends
 */
class StagingConfig extends Config
  implements EnvironmentConfigurationInterface {
  constructor(frontendConfig: FrontendConfigurationInterface) {
    super(frontendConfig);
  }

  getEnv(): ConfigurationEnvironment {
    return ConfigurationEnvironment.STAGING;
  }

  get modules(): EnvironmentModules[] {
    return [EnvironmentModules.MATOMO, EnvironmentModules.DEBUG];
  }

  getFrontendConfiguration(): FrontendConfigurationInterface {
    return this.frontendConfig;
  }

  getMoveTokenWhiteList(): string[] {
    let stagingMoveTokenWhiteList = [
      "az-produkte.de",
      "mein-medcampus.de",
      "gynonco-barcamp.de",
      "team-typ2.de",
      "cll-im-dialog.de",
      "test-to-treat.de"
    ];
    return super.getMoveTokenWhiteList().concat(stagingMoveTokenWhiteList);
  }

  getExternalLinkWhiteList(): string[] {
    let stagingExternalLinkWhitelist: string[] = [];
    return super
      .getExternalLinkWhiteList()
      .concat(stagingExternalLinkWhitelist);
  }

  getInternalLinkBlackList(): string[] {
    let stagingInternalLinkBlacklist: string[] = [];
    return super
      .getInternalLinkBlackList()
      .concat(stagingInternalLinkBlacklist);
  }

  getPublicRoutes(): string[] {
    let stagingPublicRoutes: string[] = [];
    return super.getPublicRoutes().concat(stagingPublicRoutes);
  }

  getPrivateRoutes(): string[] {
    let stagingPrivateRoutes: string[] = [];
    return super.getPrivateRoutes().concat(stagingPrivateRoutes);
  }

  getMappedPublicRoutes(): MappedRoute[] {
    let stagingPublicMappedRoutes: MappedRoute[] = [];
    return super.getMappedPublicRoutes().concat(stagingPublicMappedRoutes);
  }
}

/**
 * Test configuration for all frontends
 */
class TestConfig extends Config implements EnvironmentConfigurationInterface {
  constructor(frontendConfig: FrontendConfigurationInterface) {
    super(frontendConfig);
  }

  getEnv(): ConfigurationEnvironment {
    return ConfigurationEnvironment.TEST;
  }

  get modules(): EnvironmentModules[] {
    return [EnvironmentModules.MATOMO, EnvironmentModules.DEBUG];
  }

  getFrontendConfiguration(): FrontendConfigurationInterface {
    return this.frontendConfig;
  }

  getMoveTokenWhiteList(): string[] {
    let testMoveTokenWhiteList = [
      "az-produkte.de",
      "mein-medcampus.de",
      "gynonco-barcamp.de",
      "team-typ2.de",
      "cll-im-dialog.de",
      "test-to-treat.de"
    ];
    return super.getMoveTokenWhiteList().concat(testMoveTokenWhiteList);
  }

  getExternalLinkWhiteList(): string[] {
    let testExternalLinkWhitelist: string[] = [];
    return super.getExternalLinkWhiteList().concat(testExternalLinkWhitelist);
  }

  getInternalLinkBlackList(): string[] {
    let testInternalLinkBlacklist: string[] = [];
    return super.getInternalLinkBlackList().concat(testInternalLinkBlacklist);
  }

  getPublicRoutes(): string[] {
    let testPublicRoutes: string[] = ["/doccheck-login"];
    return super.getPublicRoutes().concat(testPublicRoutes);
  }

  getPrivateRoutes(): string[] {
    let testPrivateRoutes: string[] = [];
    return super.getPrivateRoutes().concat(testPrivateRoutes);
  }

  getMappedPublicRoutes(): MappedRoute[] {
    let testPublicMappedRoutes: MappedRoute[] = [];
    return super.getMappedPublicRoutes().concat(testPublicMappedRoutes);
  }
}

/**
 * Dev configuration for all frontends
 */
class DevConfig extends Config implements EnvironmentConfigurationInterface {
  constructor(frontendConfig: FrontendConfigurationInterface) {
    super(frontendConfig);
  }

  getEnv(): ConfigurationEnvironment {
    return ConfigurationEnvironment.DEVELOP;
  }

  get modules(): EnvironmentModules[] {
    return [EnvironmentModules.DEBUG];
  }

  getFrontendConfiguration(): FrontendConfigurationInterface {
    return this.frontendConfig;
  }

  getMoveTokenWhiteList(): string[] {
    let devMoveTokenWhiteList: string[] = [
      "dev.az-produkte.de",
      "dev.mein-medcampus.de"
    ];
    return super.getMoveTokenWhiteList().concat(devMoveTokenWhiteList);
  }

  getExternalLinkWhiteList(): string[] {
    let devExternalLinkWhiteList: string[] = [];
    return super.getExternalLinkWhiteList().concat(devExternalLinkWhiteList);
  }

  getInternalLinkBlackList(): string[] {
    let devInternalLinkBlacklist: string[] = [];
    return super.getInternalLinkBlackList().concat(devInternalLinkBlacklist);
  }

  getPublicRoutes(): string[] {
    let devPublicRoutes: string[] = ["/debug", "/doccheck-login"];
    return super.getPublicRoutes().concat(devPublicRoutes);
  }

  getPrivateRoutes(): string[] {
    let devPrivateRoutes: string[] = [];
    return super.getPrivateRoutes().concat(devPrivateRoutes);
  }

  getMappedPublicRoutes(): MappedRoute[] {
    let devPublicMappedRoutes: MappedRoute[] = [];
    return super.getMappedPublicRoutes().concat(devPublicMappedRoutes);
  }
}

class LocalConfig extends DevConfig {
  getEnv(): ConfigurationEnvironment {
    return ConfigurationEnvironment.LOCAL;
  }
}

/**
 * HCP special configuration
 */
class HCPHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/login";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [
      FrontendModules.TESTGUIDELINE_ROUTING,
      FrontendModules.MAY_WATCH_ONE,
      FrontendModules.TEALIUM
    ];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return ["konsortium-familiaerer-brustkrebs.de"];
  }

  getPublicRoutes(): string[] {
    return ["/login", "/registrierung", "/nutzerkonto-anlegen"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [
      new MappedRoute("6006e788c11aba2fc72d5681", "/imprint"),
      new MappedRoute("6007f073da037738e5648fe1", "/privacy"),
      new MappedRoute("6007f1496bd8190e9356eb61", "/terms_of_use"),
      new MappedRoute("6007f27f6bd8190e9356eb63", "/contact"),
      new MappedRoute("6007ef757468a533282dd751", "/login"),
      new MappedRoute("6007efe67ce29b430c66e654", "/registrierung"),
      new MappedRoute("6007f30b7ce29b430c66e656", "/nutzerkonto-anlegen")
    ];
  }
}

class BrandHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;
  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/login";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [
      FrontendModules.LOGIN,
      FrontendModules.TEALIUM,
      FrontendModules.MAY_WATCH_ONE
    ];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return ["konsortium-familiaerer-brustkrebs.de"];
  }

  getPublicRoutes(): string[] {
    return ["/login", "/nutzerkonto-anlegen"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [
      new MappedRoute("6009441835cc38637611c945", "/imprint"),
      new MappedRoute("6009454537d638795066f2e1", "/privacy"),
      new MappedRoute("600945a31023cc69da3432d3", "/terms_of_use"),
      new MappedRoute("600946fc8fedd661503453e1", "/contact"),
      new MappedRoute("600942b635cc38637611c941", "/login"),
      new MappedRoute("6009435435cc38637611c943", "/nutzerkonto-anlegen")
    ];
  }
}

class GynoncoHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [
      "/",
      "/anmeldung",
      "/anmeldung/",
      "/anmeldung_swp",
      "/das-barcamp",
      "/feedback",
      "/login",
      "/home2022",
      "/das-barcamp2022",
      "/newsletter/subscribe",
      "/newsletter/unsubscribe"
    ];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class CLLHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class TT2HubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return ["/", "/registrieren", "/referenten", "/login", "/programm"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class EventsHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN, FrontendModules.MAY_WATCH_ONE];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [
      "/",
      "/login",
      "/neue-dimension-cll",
      "/diacomon",
      "/diacomon/anmelden",
      "/diacomon/programm",
      "/diacomon/referenten",
      "/kongress-updates-cvrm",
      "/kongress-updates-cvrm/acc-2022",
      "/kongress-updates-cvrm/era-edta-2022",
      "/kongress-updates-cvrm/esc-hf-2022",
      "/kongress-updates-cvrm/ada-2022",
      "/kongress-updates-cvrm/esc-2022",
      "/kongress-updates-cvrm/easd-2022",
      "/kongress-updates-cvrm/asn-2022",
      "/kongress-updates-cvrm/aha-2022",
      "/kongress-updates-cvrm/service-material",
      "/haemacovery",
      "/haemacovery/newsletter/subscribe",
      "/haemacovery/newsletter/unsubscribe",
      "/haemacovery/programm/first",
      "/haemacovery/programm/second",
      "/haemacovery/komitee",
      "/haemacovery/event",
      "/haemacovery/register",
      "/haemacovery/allgemein",
      "/Covid19DA",
      "/Covid19DA/allgemeinmedizin",
      "/Covid19DA/nephrologie",
      "/Covid19DA/haematologie-onko",
      "/Covid19DA/transplantationsme",
      "/Covid19DA/rheumatologie",
      "/tixacil",
      "/tixacil/anmelden",
      "/tixacil/programm",
      "/tixacil/referenten",
      "/cardiospot",
      "/cardiospot/register",
      "/science-exchange-pdf",
      "/afp-highlight-symposium"
    ];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [new MappedRoute("609e3cc72ab3e509226d36b2", "/login")];
  }
}

class BiomarkerHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN, FrontendModules.TESTGUIDELINE_ROUTING];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return ["/", "/login", "/registrieren", "/rechtliches"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class EIDHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/login";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return ["/login", "/registrierung"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class BreastcancerHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.PATIENT;
  private appContext: FrontendContext = FrontendContext.PATIENT;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return ["interaktiv.brustkrebs.de", "interaktiv-test.brustkrebs.de"];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return ["gen-wissheit.de", "pink-brustkrebs.de", "mum-hat-brustkrebs.de"];
  }

  getPublicRoutes(): string[] {
    return ["/shared-bookmarks"];
  }

  getPrivateRoutes(): string[] {
    return ["/profile", "/bookmarks"];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class BSDKHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/login";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.READSPEAKER];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class RSVHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.PATIENT;
  private appContext: FrontendContext = FrontendContext.PATIENT;

  get meta() {
    return {
      batch_product: "Synagis retail"
    };
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.BATCH_LOGIN];
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [
      "/privat",
      "/privat-was-ist-rsv",
      "/privat-warum-hat-mein-kind-ein-erhoehtes-risiko",
      "/privat-was-ist-synagis",
      "/privat-wie-bekommt-mein-kind-synagis",
      "/privat-weitere-informationen"
    ];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class CovidHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {
      batch_product: "Vaxzevria"
    };
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.BATCH_LOGIN];
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return ["/"];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class OnkoHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return ["/login"];
  }

  getPrivateRoutes(): string[] {
    return ["/unsere-klinische-strategie-2", "/unsere-medikamente-pipeline-2"];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class GenwissheitHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [
      "konsortium-familiaerer-brustkrebs.de",
      "wegweiser-eierstockkrebs.de"
    ];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class MehrschutzHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {
      batch_product: "Mehr-Schutz"
    };
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [
      {
        slug: "/mehrinfo",
        context: FrontendContext.PATIENT
      },
      {
        slug: "/mehrinfo/patientinnen",
        context: FrontendContext.PATIENT
      }
    ];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.LOGIN, FrontendModules.BATCH_LOGIN];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [
      "/erkaeltungszeit",
      "/monoklonale-antikorper",
      "/immunschwaeche-in-Corona-Zeiten",
      "/mehr-schutz-bei-immunsuppression"
    ];
  }

  getPrivateRoutes(): string[] {
    return ["/hcp"];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class CKDHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.TEALIUM];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class DiagnosticsHubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.TEALIUM];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class CancerSupport360HubConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [FrontendModules.TEALIUM];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return ["/404"];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [new MappedRoute("64ccf0f5cdf2adf58607e996", "/404")];
  }
}

/**
 * Empty fallback configuration
 */
class DefaultFrontendConfig implements FrontendConfigurationInterface {
  defaultContext = FrontendContext.HCP;
  private appContext: FrontendContext = FrontendContext.HCP;

  get meta() {
    return {};
  }

  get redirectRouteAfterLogout(): string {
    return "/login";
  }

  get context(): FrontendContext {
    return this.appContext;
  }

  set setContext(context: FrontendContext) {
    this.appContext = context;
  }

  get contextByRoute(): { slug: string; context: FrontendContext }[] {
    return [];
  }

  get modules(): FrontendModules[] {
    return [];
  }

  getMoveTokenWhiteList(): string[] {
    return [];
  }

  getExternalLinkWhiteList(): string[] {
    return [];
  }

  getInternalLinkBlackList(): string[] {
    return [];
  }

  getPublicRoutes(): string[] {
    return [];
  }

  getPrivateRoutes(): string[] {
    return [];
  }

  getMappedPublicRoutes(): MappedRoute[] {
    return [];
  }
}

class ConfigurationFactory {
  private static config?: EnvironmentConfigurationInterface;

  public static getConfiguration(): EnvironmentConfigurationInterface {
    if (!this.config) {
      let frontendConfig: FrontendConfigurationInterface = new DefaultFrontendConfig();

      if (Store.isHCP()) {
        frontendConfig = new HCPHubConfig();
      }

      if (Store.isBrand()) {
        frontendConfig = new BrandHubConfig();
      }

      if (Store.isGynonco()) {
        frontendConfig = new GynoncoHubConfig();
      }

      if (Store.isTT2()) {
        frontendConfig = new TT2HubConfig();
      }

      if (Store.isCLL()) {
        frontendConfig = new CLLHubConfig();
      }

      if (Store.isEvents()) {
        frontendConfig = new EventsHubConfig();
      }

      if (Store.isBiomarker()) {
        frontendConfig = new BiomarkerHubConfig();
      }

      if (Store.isEID()) {
        frontendConfig = new EIDHubConfig();
      }

      if (Store.isBreastcancer()) {
        frontendConfig = new BreastcancerHubConfig();
      }

      if (Store.isBSDK()) {
        frontendConfig = new BSDKHubConfig();
      }

      if (Store.isRSV()) {
        frontendConfig = new RSVHubConfig();
      }

      if (Store.isCovid()) {
        frontendConfig = new CovidHubConfig();
      }

      if (Store.isOnko()) {
        frontendConfig = new OnkoHubConfig();
      }

      if (Store.isGenwissheit()) {
        frontendConfig = new GenwissheitHubConfig();
      }

      if (Store.isMehrschutz()) {
        frontendConfig = new MehrschutzHubConfig();
      }

      if (Store.isCKD()) {
        frontendConfig = new CKDHubConfig();
      }

      if (Store.isDiagnostics()) {
        frontendConfig = new DiagnosticsHubConfig();
      }

      if (Store.isCS360()) {
        frontendConfig = new CancerSupport360HubConfig();
      }

      /*
       * No special configuration needed for
       * patient_hub, newnormal_hub
       *
       * If needed:
       * 1. Create a new class (e.g. PatientHubConfig)
       * 2. Let it implement the interface FrontendConfigurationInterface
       * 3. add your needed parameters
       * 4. initialize it here
       */

      if (Store.isProd()) {
        this.config = new ProductionConfig(frontendConfig);
      } else if (Store.isStage()) {
        this.config = new StagingConfig(frontendConfig);
      } else if (Store.isTest()) {
        this.config = new TestConfig(frontendConfig);
      } else if (Store.isDev()) {
        this.config = new DevConfig(frontendConfig);
      } else {
        this.config = new LocalConfig(frontendConfig);
      }
    }

    return this.config;
  }
}

export default ConfigurationFactory.getConfiguration();
