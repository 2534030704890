
import { Component } from "vue-property-decorator";
import { LayoutComponent, matomo, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Presentation extends BaseComponent {
  async mounted() {
    await super.mounted();
  }

  get slides_link() {
    return this.getSettingsAttribute("slides_link");
  }

  get button_label() {
    return this.getSettingsAttribute("button_label") || "Zu den Highlights";
  }

  showPresentation() {
    this.trackClick();
    Store.modal.title = "";
    Store.modal.className = "modal-custom-xl";
    Store.modal.setLayoutComponent(
      new LayoutComponent({
        component: "presentation_overlay",
        settings: {
          slides_link: this.slides_link
        }
      })
    );
    Store.modal.showModal();
  }

  trackClick() {
    matomo.click(
      "asco",
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "Click_Presentation"
    );
  }
}
