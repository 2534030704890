import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class GlossaryRepository extends AbstractRepository {
  async getItemByKey(itemKey: string) {
    let data = {
      filter: {
        item_key: {
          $in: [itemKey]
        }
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("glossary_items"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r[0] : null;
    });

    return result;
  }

  async getFilteredItems(ids: any[], group: any, collectionName: any) {
    let data = {
      filter: {
        _id: { $in: ids },
        group: {
          $in: group ? [group] : null
        }
      },
      sort: {
        item_key: 1
      },
      simple: 1,
      populate: 1
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries(collectionName),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r.length > 0 ? r : [];
    });

    return result;
  }
}

export default new GlossaryRepository();
