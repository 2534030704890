
import { Component, Vue, Prop } from "vue-property-decorator";
import { TNMFilterElement } from "common";

@Component
export default class TnmFilterSelect extends Vue {
  @Prop({ default: "t" }) type!: string;
  @Prop({ default: [] }) values!: TNMFilterElement[];
  @Prop({ default: false }) isSet!: boolean;
  @Prop({ default: { key: "", value: "" } }) selected!: TNMFilterElement;

  placementClass!: string;

  constructor() {
    super();
    this.placementClass = "bottom-start";
  }

  selectValue(type: string, element: TNMFilterElement) {
    this.$emit("select", type, element);
  }

  mounted() {
    let self = this;
    var xPlacementObserver = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "x-placement"
        ) {
          let xPlacement = (self.$refs
            .dropdownMenu as HTMLDivElement).getAttribute("x-placement");
          if (xPlacement) {
            self.placementClass = xPlacement;
          }
        }
      });
    });

    xPlacementObserver.observe(this.$refs.dropdownMenu as HTMLDivElement, {
      attributes: true
    });
  }
}
