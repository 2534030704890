
import uniqueId from "lodash/uniqueId";
import flattenDeep from "lodash/flattenDeep";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ContentRepository,
  Constants,
  LayoutComponent,
  ResultType,
  RoutingHelper
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

interface ContentItem {
  collection: string;
  component: string;
  ids: string[];
}

interface Pin {
  placement?: string;
  top: string;
  left: string;
  width?: string;
  height?: string;
  color?: string;
  text?: string;
  imgToShow?: string;
  pinDisplay?: string;
  contentToShow?: ContentItem[];
  uniqueId?: any;
}

@Component
export default class LcMap extends BaseComponent {
  public selectedItemToShow: any = {};
  public visibleId: any = {};
  public visibleContentData!: ContentItem[];
  public itemsToRender!: LayoutComponent[];
  public loading: boolean;

  constructor() {
    super();
    this.visibleContentData = [];
    this.itemsToRender = [];
    this.loading = false;
  }

  getComponentType() {
    return "maps";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      // Set content data for the default content if available
      this.setVisibleContentData(null, this.defaultContent);
    });
  }

  get path() {
    let image = this.getContentAttribute("map_image");
    if (image) {
      return RoutingHelper.getImagePath(image.path);
    }
    return "";
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  get metaContent() {
    const result: any = this.getContentAttribute("meta")["map"];

    return result.map((item: any) => {
      return {
        ...item,
        pins: item.pins.map((pin: Pin) => {
          return {
            ...pin,
            ...(pin &&
              pin.imgToShow && {
                imgToShow: this.computeImagePath(pin.imgToShow)
              }),
            uniqueId: uniqueId("pin_")
          };
        })
      };
    });
  }

  get title() {
    return this.getContentAttribute("title");
  }

  // Extended map for images toggle
  /**
   * Get a list with all images that can be shown
   * @return {array}
   */
  get multipleImages() {
    const images: any = uniq(
      flattenDeep(
        this.getContentAttribute("meta")["map"].map((item: any) => {
          return item.pins.map((pin: Pin) => {
            return pin.imgToShow ? this.computeImagePath(pin.imgToShow) : "";
          });
        })
      )
    );

    return images;
  }

  /**
   * Popover trigger event
   * @return {string}
   */
  get popoverTriggerOn() {
    const options: any = (this.getContentAttribute("meta") || {}).options;

    if (options) {
      return options.isOnClick ? "click" : "hover";
    }

    return "hover";
  }

  /**
   * Show close icon on popover
   * @return {boolean}
   */
  get popoverShowCloseIcon() {
    const options: any = (this.getContentAttribute("meta") || {}).options;

    if (options) {
      return !!options.popupCloseIcon;
    }

    return false;
  }

  /**
   * Popover custom class (Ex: theme-1)
   * @return {string}
   */
  get popoverClassName() {
    const options: any = (this.getContentAttribute("meta") || {}).options;

    if (options) {
      return options.popoverClass || "";
    }

    return "";
  }

  /**
   * Default content to show when the first image is loaded
   * @return {array}
   */
  get defaultContent(): Array<any> {
    const options: any = (this.getContentAttribute("meta") || {}).options;

    if (options) {
      return options.defaultContent || [];
    }

    return [];
  }

  /**
   * Close the popover and reset selected item
   * @param pin
   */
  onCloseClick(pin: Pin) {
    this.selectedItemToShow = {};
    this.visibleId[pin.uniqueId] = false;
  }

  /**
   * Set or reset selected item based on popover visibility
   * @param pin
   * @param {boolean} e - visible state
   */
  onTooltipVisibleChange(pin: Pin, e: boolean) {
    if (e) {
      this.selectedItemToShow = pin || {};
    } else {
      this.selectedItemToShow = {};
    }
  }

  /**
   * The element when the popover is appended
   * @return {HTMLElement | null}
   */
  popoverAppendTo() {
    let elementToPopoverAppend: any;
    const options: any = (this.getContentAttribute("meta") || {}).options;
    if (options && options.popupAppendTo) {
      elementToPopoverAppend = document.getElementsByClassName(
        options.popupAppendTo
      )[0];
    }
    return (
      elementToPopoverAppend ||
      document.getElementById("layout-content") ||
      null
    );
  }

  /**
   * Set selectedItem on click
   * @param pin
   * @param pinIdx
   */
  onPinClick(pin: Pin, pinIdx: any) {
    if (this.selectedItemToShow.imgToShow === pin.imgToShow) {
      this.selectedItemToShow = {};
    } else {
      this.selectedItemToShow = { ...pin, idx: pinIdx };
    }
  }

  /**
   * Create the full image url
   * @param imagePath
   * @return {string}
   */
  computeImagePath(imagePath: string) {
    let formatImagePath = imagePath;

    if (!imagePath) {
      return "";
    }

    try {
      let pathUrl = new URL(formatImagePath);
      if (pathUrl.protocol) {
        return formatImagePath;
      }
    } catch (e) {
      if (formatImagePath && !formatImagePath.startsWith("/")) {
        formatImagePath = "/" + imagePath;
      }
      return Constants.storageUrl + formatImagePath;
    }
    return "";
  }

  /**
   * Set pin style z-index related to selected item
   * @param pin
   * @param pinIdx
   * @param pinsLength
   */
  getZindex(pin: Pin, pinIdx: any, pinsLength: any) {
    if (pinIdx === 0) {
      if (this.selectedItemToShow.idx === undefined) {
        return 1;
      }

      if (this.selectedItemToShow.idx === pinsLength - 1) {
        return 1;
      }
    }

    if (pinIdx === this.selectedItemToShow.idx + 1) {
      return 1;
    }

    return null;
  }

  /**
   * Set visible content id's related to selected item
   * @param pin
   */
  setVisibleContentData(pin: Pin | null, defaultContent?: any[]) {
    if (pin && pin.contentToShow) {
      this.visibleContentData = pin.contentToShow;
    } else if (defaultContent) {
      this.visibleContentData = defaultContent;
    } else {
      this.visibleContentData = [];
    }
  }

  /**
   * Get content to render
   */
  getItemsToRender() {
    this.itemsToRender = [];

    if (this.visibleContentData.length > 0) {
      this.loading = true;

      const promiseList = this.visibleContentData.map((item: ContentItem) => {
        return ContentRepository.getCollectionItemByTypeAndId(
          item.collection,
          item.ids,
          0
        );
      });

      Promise.all(promiseList).then(results => {
        results.forEach(result => {
          if (
            result.type === ResultType.SUCCESS &&
            result.value &&
            result.value.length > 0
          ) {
            // Match component name with response
            result.value.forEach((resultItem: any) => {
              this.visibleContentData.some(item => {
                if (item.ids.includes(resultItem._id)) {
                  // Create layout component
                  this.itemsToRender.push(
                    LayoutComponent.createFromCollectionItem(
                      item.component,
                      resultItem
                    )
                  );
                }
              });
            });
          }
        });
        this.loading = false;
      });
    }
  }

  @Watch("selectedItemToShow")
  setContentToShow() {
    if (isEmpty(this.selectedItemToShow) && this.defaultContent.length) {
      // Set content data for the default content if available
      this.setVisibleContentData(null, this.defaultContent);
    } else {
      this.setVisibleContentData(this.selectedItemToShow);
    }
  }

  @Watch("visibleContentData")
  getContentToRender() {
    this.getItemsToRender();
  }
}
