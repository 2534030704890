
import { Component } from "vue-property-decorator";
import { isNil } from "lodash";
import { Helper, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class DynamicSharingEmail extends BaseComponent {
  getComponentType(): string {
    return "dynamic_sharing_emails";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
  }

  get availablePlaceholders(): { [key: string]: Function } {
    return {
      seoTitle: this.getSeoTitle,
      seoDescription: this.getSeoDescription,
      ogTitle: this.getOGTitle,
      ogDescription: this.getOGDescription,
      ogUrl: this.getOGUrl,
      bookmarkListId: this.getBookmarkListId
    };
  }

  getSeoTitle(): string {
    return window.document.title || "";
  }

  getSeoDescription(): string {
    const description = window.document.querySelector(
      'meta[name="description"]'
    );

    if (description) {
      // @ts-ignore
      return description.content;
    }

    return window.document.title;
  }

  getOGTitle(): string {
    const ogTitle = window.document.querySelector('meta[property="og:title"]');

    if (ogTitle) {
      // @ts-ignore
      return ogTitle.content;
    }

    return window.document.title;
  }

  getOGDescription(): string {
    const ogDescription = window.document.querySelector(
      'meta[property="og:description"]'
    );

    if (ogDescription) {
      // @ts-ignore
      return ogDescription.content;
    }

    const description = window.document.querySelector(
      'meta[name="description"]'
    );

    if (description) {
      // @ts-ignore
      return description.content;
    }

    return "";
  }

  getOGUrl(): string {
    return window.location.href;
  }

  getBookmarkListId(): string {
    return this.user.getProperty("bookmarkListId") || "";
  }

  get dynamicBody(): string {
    return encodeURIComponent(
      Helper.replacePlaceholders(this.body, this.availablePlaceholders)
    );
  }

  get dynamicSubject(): string {
    return encodeURIComponent(
      Helper.replacePlaceholders(this.subject, this.availablePlaceholders)
    );
  }

  get subject(): string {
    return this.getContentAttribute("subject");
  }

  get body(): any {
    return this.getContentAttribute("body");
  }

  get user() {
    return User;
  }

  get label() {
    return this.getSettingsAttribute("btn_label");
  }

  get target() {
    return isNil(this.getSettingsAttribute("target"))
      ? "_self"
      : this.getSettingsAttribute("target");
  }
}
