var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"create-password",attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(!_vm.wrongResetToken)?_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(!_vm.isResetForm())?_c('h3',[_vm._v(" Jetzt anmelden und einmalig ein Passwort vergeben ")]):_c('h3',[_vm._v("Vergeben Sie ein neues Passwort")])])]),(!_vm.isResetForm())?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_vm._v(" Nutzen Sie die Vorteile der Passwortvergabe und schützen Sie Ihre persönlichen Daten vor unbefugtem Zugriff. Zusätzlich erhalten Sie die Möglichkeit, sich mit Ihrem persönlichen Nutzernamen (E-Mail Adresse) und Passwort auf MeinMEDCAMPUS und weiteren Seiten von AstraZeneca anmelden zu können. ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label-comp",attrs:{"for":"new-password"}},[_vm._v("Bitte vergeben Sie ein Passwort für Ihren Account")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPasswordVal),expression:"newPasswordVal"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateNewPassword,
              'is-valid': _vm.attemptSubmit && _vm.validateNewPassword
            },attrs:{"type":"password","id":"new-password"},domProps:{"value":(_vm.newPasswordVal)},on:{"input":function($event){if($event.target.composing)return;_vm.newPasswordVal=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorPasswordField)}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" Ihr Passwort muss mind. 8 Zeichen lang sein, Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen enthalten. ")])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label-comp",attrs:{"for":"new-password-repeat"}},[_vm._v("Bitte wiederholen Sie Ihr Passwort")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPasswordRepeatVal),expression:"newPasswordRepeatVal"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateNewPasswordRepeat,
              'is-valid': _vm.attemptSubmit && _vm.validateNewPasswordRepeat
            },attrs:{"type":"password","id":"new-password-repeat"},domProps:{"value":(_vm.newPasswordRepeatVal)},on:{"input":function($event){if($event.target.composing)return;_vm.newPasswordRepeatVal=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorRepeatPasswordField)}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right mt-2"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"submit","disabled":_vm.sendingRequest}},[_vm._v(" Bestätigen und anmelden ")])])])]):_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 text-right mt-2"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.switchToResetPassword()}}},[_vm._v(" Anfordern ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Der von Ihnen aufgerufene Reset-Link ist leider ungültig. Bitte fordern Sie einen neuen Link an ")])])
}]

export { render, staticRenderFns }