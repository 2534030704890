
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Column extends BaseComponent {
  @Prop() columnWidth!: String | null;

  getComponentType() {
    return "columns";
  }

  async mounted() {
    super.mounted();
  }

  get components() {
    return this.getContentAttribute("content");
  }

  get alignment() {
    let alignment = this.getSettingsAttribute("alignment");
    return alignment ? alignment : "left";
  }
  get cssClasses() {
    return this.layoutComponent.settings.class;
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  get id() {
    return this.layoutComponent.settings.id;
  }
}
