
import { Component } from "vue-property-decorator";
import { CHANNEL_EVENTREGISTERSUCCESS_CLOSED, EventBus } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class EventRegisterSuccess extends BaseComponent {
  get success_message() {
    return this.getSettingsAttribute("success_message");
  }

  get buttons() {
    return this.getSettingsAttribute("buttons");
  }

  closeModal() {
    EventBus.$emit(CHANNEL_EVENTREGISTERSUCCESS_CLOSED, true);
    (this.$refs.closemodal as HTMLElement).click();
  }
}
