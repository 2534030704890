
import { Component, Prop } from "vue-property-decorator";
import {
  Event,
  EventDisplayOptions,
  LayoutComponent,
  Store,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class EventListItemLive extends BaseComponent {
  @Prop() event!: Event;
  @Prop() selected!: boolean;
  @Prop() displayOptions!: EventDisplayOptions;

  selectEvent() {
    this.$emit("selectEvent", this.event);
  }

  openParticipantLink() {
    if (!(this.event.opParticipantLink && this.event.registered)) {
      return;
    }

    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      return;
    }

    if (User.getTrackingConsent()) {
      window.open(this.event.opParticipantLink, "_blank");
    } else {
      Store.modal.title = "SIE MÖCHTEN CME-PUNKTE FÜR IHRE TEILNAHME ERHALTEN?";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("TrackingOptIn", {
          redirectUrl: window.location.href,
          additionalUrl: this.event.opParticipantLink
        })
      );
      Store.modal.showModal();
    }
  }
}
