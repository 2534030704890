var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"quiz__body-inner",attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[_c('div',{staticClass:"quiz__question full-width mb-5"},[(_vm.text)?_c(_vm.text.component,{tag:"component",staticClass:"quiz__question--body",attrs:{"layoutComponent":_vm.text}}):_vm._e()],1),_c('div',{staticClass:"quiz__answers"},_vm._l((_vm.answers),function(answer,answerIdx){return _c('div',{key:'answer-' + answerIdx,class:[
        'quiz__answer',
        _vm.getCssClasses(answerIdx),
        _vm.layoutComponent.settings.class
      ],on:{"click":function($event){return _vm.selectAnswer(answerIdx)}}},[_c('span',{staticClass:"quiz__answer--number"},[_vm._v(_vm._s(answerIdx + 1))]),_c('label',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedAnswerIdx.includes(answerIdx)},on:{"click":function($event){return _vm.selectAnswer(answerIdx)}}}),_c('span'),_c('div',{staticClass:"form-check-label"},[_c(answer.component,{tag:"component",attrs:{"layoutComponent":answer}})],1)])])}),0),(!_vm.isMultiple)?[_vm._l((_vm.selectedAnswerIdx),function(selectedAnswer,key){return [(
          _vm.answersResults[selectedAnswer] &&
            _vm.answersResults[selectedAnswer].settings.content
        )?_c(_vm.answersResults[selectedAnswer].component,{key:key,tag:"component",staticClass:"quiz__answer-result",attrs:{"layoutComponent":_vm.answersResults[selectedAnswer]}}):_vm._e()]})]:[(_vm.selectedAnswerIdx.length)?_c(_vm.multipleAnswersResult && _vm.multipleAnswersResult.component,{key:Math.random()
          .toString(19)
          .slice(2),tag:"component",staticClass:"quiz__answer-result",attrs:{"layoutComponent":_vm.multipleAnswersResult}}):_vm._e()],(_vm.showMessage)?_c('div',{staticClass:"question-message__text"},[_c('a-alert',{attrs:{"message":_vm.infoMessage,"show-icon":""}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }