
import { Component } from "vue-property-decorator";
import { CryptoHelper, router, Store, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class DocCheckListener extends BaseComponent {
  // getComponentType() {
  //   return "doc_check_logins";
  // }
  // async mounted() {
  //   await super.mounted();
  // }
  // contentDidLoad() {
  //   super.contentDidLoad();
  //   Vue.nextTick(() => {
  //     let sessionId = router.currentRoute.query.session_id as string;
  //     let uniqueKey = router.currentRoute.query.uniquekey as string;
  //     let dccr = router.currentRoute.query.dccr as string;
  //     let token = router.currentRoute.query.token as string;
  //     let loginId = router.currentRoute.query.login_id as string;
  //     if (sessionId && uniqueKey && dccr && loginId) {
  //       let checkSum = CryptoHelper.decrypt(sessionId, dccr);
  //       let userCheckSum = User.getDocCheckCheckSum();
  //       if (checkSum && userCheckSum && checkSum === userCheckSum) {
  //         User.setDocCheckSessionId(sessionId);
  //         User.setDocCheckUserId(uniqueKey);
  //         User.deleteDocCheckCheckSum();
  //         if (!token) {
  //           let returnUrl = this.return_url;
  //           Store.deleteSavedReturnUrl();
  //           // return router.push(returnUrl);
  //         }
  //         return;
  //       }
  //     }
  //     if (!token) {
  //       // return router.push("/login");
  //     }
  //   });
  // }
  // get id() {
  //   return this.getContentAttribute("id");
  // }
  // get return_url() {
  //   let returnUrl = Store.retrieveReturnUrl();
  //   if (returnUrl) {
  //     return returnUrl;
  //   }
  //   return this.getContentAttribute("return_url") || "/";
  // }
}
