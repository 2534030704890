
import { Vue, Component, Prop } from "vue-property-decorator";
import { LayoutComponent } from "common";

@Component
export default class VideoTemplate extends Vue {
  @Prop() content!: any;

  get placeholderImage() {
    try {
      return new LayoutComponent(this.content[0].placeholder_image[0]);
    } catch (e) {
      return null;
    }
  }

  get headlineText() {
    try {
      return this.content[0].headline_text;
    } catch (e) {
      return null;
    }
  }
}
