
import { Component, Vue, Prop } from "vue-property-decorator";
import { LayoutComponent, Store } from "common";

@Component
export default class VirtualLungIframe extends Vue {
  @Prop() layoutComponent!: any;

  async mounted() {
    if (!this.showIFrame) {
      return;
    }

    var iFrame = document.getElementById(
      "app-virtual-lung"
    ) as HTMLIFrameElement;

    if (!iFrame) {
      return;
    }

    iFrame.onload = () => {
      var iframeDocument =
        iFrame.contentDocument || iFrame.contentWindow!.document;
      if (iframeDocument) {
        var splash = iframeDocument.getElementById("splash");
        if (splash) {
          splash.click();
        }
      }
    };
  }

  clickTeaser() {
    Store.modal.title = "Virtual Lung";
    Store.modal.className = "modal-custom-xl";
    Store.modal.setLayoutComponent(
      new LayoutComponent({
        component: "VirtualLungIframe",
        settings: {
          showIFrame: true
        }
      })
    );
    Store.modal.showModal();
  }

  get showIFrame() {
    if (Object.keys(this.layoutComponent.settings).includes("showIFrame")) {
      return this.layoutComponent.settings.showIFrame;
    }
    return false;
  }
}
