
import { Component, Vue } from "vue-property-decorator";
import {
  config,
  FrontendContext,
  LoginHandler,
  matomo,
  MatomoEventNames,
  UserRepository
} from "common";

@Component
export default class DeleteProfile extends Vue {
  async deleteProfile() {
    // Matomo tracking
    const matomoDeleteEventID =
      config.getFrontendConfiguration().context === FrontendContext.PATIENT
        ? "Account_Delete_Patient"
        : "Account_Delete_HCP";

    matomo.genericEvent(
      matomoDeleteEventID,
      matomo.MATOMO_EVENT_CATEGORY,
      MatomoEventNames.ACCOUNT_DELETE
    );

    // Delete profile
    await UserRepository.delete();
    await LoginHandler.logout();
    window.location.href = config.getFrontendConfiguration().redirectRouteAfterLogout;
  }
}
