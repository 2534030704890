
import { Component, Vue, Prop } from "vue-property-decorator";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import {
  Event,
  EventSpeaker,
  EventSpeakerCategory,
  Helper,
  router,
  RoutingHelper
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

/**
 *  By using the `scrollTo` feature, we need to set a fixed height from css for images,
 *  in order to avoid miscalculated element position if the scrollTo method is called
 *  before all the images are loaded.
 */

@Component
export default class EventSpeakerList extends BaseComponent {
  @Prop({ default: 1 }) eventId!: number;

  public listKey!: string;
  public theEvent!: any;
  public speakerCategories!: string[];
  public selectedCategoryIdx!: string;
  public lastKnownSpeakerInfoPosition?: number;
  public currentActiveSpeakerName?: string;
  public currentActiveSpeakerInfo?: string;
  public currentActiveInfoClass?: string;
  public currentActiveSpeakerCategories?: string[];

  constructor() {
    super();
    this.theEvent = null;
    this.speakerCategories = [];
    this.selectedCategoryIdx = "";
    this.currentActiveSpeakerName = "";
    this.currentActiveSpeakerInfo = "";
    this.currentActiveInfoClass = "";
    this.currentActiveSpeakerCategories = [];
    this.lastKnownSpeakerInfoPosition = -1;
    this.listKey = this.getUniqueKey();
  }

  getComponentType() {
    return "event_speaker_lists";
  }

  async mounted() {
    await super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();
    await this.loadEvent();

    Vue.nextTick(() => {
      // Get the speaker element and toggle it active.
      const speakerElement = this.showSpeakerInfoBox();

      // Wait for all speakers to load and call scroll.
      setTimeout(() => {
        if (speakerElement) {
          // ScrollTo
          Helper.smoothScrollTo(
            speakerElement as HTMLElement,
            (speakerElement as HTMLElement).getBoundingClientRect().top +
              window.scrollY
          );
        }
      }, 100);
    });
  }

  async loadEvent() {
    const events: Array<any> = [];

    if (this.meta) {
      const metaContent: any = await this.loadContent(
        this.meta.link,
        [this.meta._id],
        0
      );
      if (metaContent && metaContent.length > 0) {
        events.push(...metaContent[0].meta);
      }
    }

    let eventIdToSearchFor: any = null;

    if (this.cockpitEventId) {
      eventIdToSearchFor = this.cockpitEventId;
    } else {
      eventIdToSearchFor = this.eventId;
    }

    let event = events.find((e: any) => e.id == eventIdToSearchFor);

    if (event) {
      this.theEvent = new Event(event);
      // Map & Order categories
      this.theEvent.speakers.data.forEach((currentSpeaker: any) => {
        this.speakerCategories.push(...currentSpeaker.speakerCategories);
      });
      this.speakerCategories = uniq(this.speakerCategories);

      if (this.theEvent.speakers.categories.length > 1) {
        this.speakerCategories = sortBy(
          this.speakerCategories,
          (speakerCategory: string) => {
            const currentCategory: EventSpeakerCategory = this.theEvent.speakers.categories.find(
              (category: EventSpeakerCategory) =>
                category.name === speakerCategory
            );
            return currentCategory ? currentCategory.order : 0;
          }
        );
      }
    }
  }

  get cockpitEventId() {
    return this.getSettingsAttribute("event_id") || null;
  }

  get meta() {
    return this.getContentAttribute("meta");
  }

  get speakerList() {
    if (
      this.speakerCategories.length === 0 ||
      this.selectedCategoryIdx.length === 0
    ) {
      return this.theEvent.speakers.data;
    }

    return filter(this.theEvent.speakers.data, (speaker: any) => {
      return speaker.speakerCategories.includes(this.selectedCategoryIdx);
    });
  }

  toggleSpeakerInfo(speaker: EventSpeaker, position: number) {
    if (speaker.speakerInfo.length === 0) {
      return;
    }

    if (this.lastKnownSpeakerInfoPosition == position) {
      this.resetSpeakerInfoBox();
      return;
    }

    /* Update speaker information values */
    this.lastKnownSpeakerInfoPosition = position;
    this.currentActiveSpeakerName = `${speaker.speakerTitle} ${speaker.speakerName}`;
    this.currentActiveSpeakerInfo = speaker.speakerInfo;
    this.currentActiveSpeakerCategories = speaker.speakerCategories;
    this.currentActiveInfoClass = this.theEvent.speakers.speakerInfoFullWidth
      ? ""
      : "invisible";

    let newSpeakerInfoPosition = this.getNewSpeakerInfoPosition(position);
    let elementToAddBefore: any = document.querySelector(
      `#speaker-list-${this.theEvent.speakers.id} > .event-speaker-wrapper[data-position='${newSpeakerInfoPosition}']`
    );

    Vue.nextTick(() => {
      let eventSpeakerList = this.$refs[
        `eventspeakerlist_${this.theEvent.speakers.id}`
      ] as HTMLElement;
      let speakerWrapper = this.$refs[
        `eventspeakerinfowrapper_${this.theEvent.speakers.id}`
      ] as HTMLElement;

      speakerWrapper.remove();

      if (this.theEvent.speakers.speakerInfoFullWidth) {
        eventSpeakerList.insertBefore(speakerWrapper, elementToAddBefore);
      } else {
        const documentScrollHeight = document.documentElement.scrollHeight;

        elementToAddBefore.appendChild(speakerWrapper);

        this.currentActiveInfoClass = this.isInViewport(
          speakerWrapper,
          documentScrollHeight
        )
          ? ""
          : "event-speaker-info-wrapper--top";
      }
    });
  }

  private showSpeakerInfoBox() {
    if (
      this.theEvent &&
      Object.keys(router.app.$route.query).indexOf("speakerlistid") != -1 &&
      Object.keys(router.app.$route.query).indexOf("speakerid") != -1
    ) {
      const { speakerlistid, speakerid } = router.app.$route.query;
      let speakerElement: any = document.querySelector(
        `#speaker-list-${speakerlistid} > #speaker-${speakerid}`
      );

      if (
        speakerElement &&
        speakerElement.firstElementChild &&
        !speakerElement.firstElementChild.classList.contains("active")
      ) {
        speakerElement.click();
        return speakerElement;
      }
    }

    return null;
  }

  private getNewSpeakerInfoPosition(position: number) {
    let currentBreakpoint = "lg";
    if (window.innerWidth <= 768) currentBreakpoint = "xs";
    else if (window.innerWidth <= 1024) currentBreakpoint = "md";

    const value =
      Helper.classMapper[this.theEvent.speakers.speakerWidth][
        currentBreakpoint
      ];
    const rowCount = 12 / value;
    return this.theEvent.speakers.speakerInfoFullWidth
      ? rowCount + position - (position % rowCount)
      : position;
  }

  private resetSpeakerInfoBox() {
    this.lastKnownSpeakerInfoPosition = -1;
    this.currentActiveSpeakerName = "";
    this.currentActiveSpeakerInfo = "";
    this.currentActiveInfoClass = "";
    this.currentActiveSpeakerCategories = [];
  }

  public getSpeakerColumns(event: Event) {
    let colArray: string[] = Helper.getMappedCssClassesAsArray(
      event.speakers.speakerWidth
    );
    return colArray.join(" ");
  }

  private isInViewport(element: any, height: number) {
    const rect = element.getBoundingClientRect();

    if (rect && rect.height > 0) {
      return (
        rect.top + rect.height + document.documentElement.scrollTop <= height
      );
    }

    return false;
  }

  public getImage(img: string) {
    return img && img.length > 0
      ? RoutingHelper.getImagePath(img)
      : `/assets/event/speakerDefault.png`;
  }

  public changeCategory(newCategory: string) {
    this.listKey = this.getUniqueKey();
    this.resetSpeakerInfoBox();

    this.selectedCategoryIdx =
      this.selectedCategoryIdx === newCategory ? "" : newCategory;
  }

  public getCategoryColor(categoriesToFind: string[]) {
    const categories: any[] = this.theEvent.speakers.categories;

    if (categories.length === 0 || categoriesToFind.length === 0) {
      return;
    }

    const category = categories.find(
      (currentCategory: any) =>
        currentCategory.name ===
        (categoriesToFind.length > 1 && this.selectedCategoryIdx !== ""
          ? categoriesToFind.find((c: any) => c === this.selectedCategoryIdx)
          : categoriesToFind[0])
    );

    if (category) {
      return category.color;
    }

    return;
  }

  getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }
}
