
import { Component, Vue, Prop } from "vue-property-decorator";
import { jsPDF } from "jspdf";
import {
  Constants,
  Event,
  EventPDFPage,
  EventProgram as EventProgramModel,
  EventProgramSegment,
  EventProgramSegmentGroup,
  EventProgramSegmentGroupContent,
  eventExportList,
  LayoutComponent,
  PDFSegment,
  Store,
  User,
  UserLoginStatus,
  UserRoleName
} from "common";
import BaseComponent from "../base/BaseComponent.vue";
import EventProgramGroupsWithPDFExportLayout from "./eventProgramLayouts/EventProgramGroupsWithPDFExportLayout.vue";

enum EventProgramLayout {
  default = "default",
  groups = "groups",
  exportable_groups = "exportable_groups"
}

@Component({
  components: {
    "event-program-groups-with-pdf-export-layout": EventProgramGroupsWithPDFExportLayout
  }
})
export default class EventProgram extends BaseComponent {
  public EventProgramLayout = EventProgramLayout;

  @Prop({ default: 1 }) eventId!: number;

  public theEvent!: Event | null;
  public exportable: boolean = false;

  constructor() {
    super();
    this.theEvent = null;
  }

  async exportPDF() {
    this.exportable = true;

    Store.modal.title = "Bitte warten ...";
    Store.modal.showCloseButton = false;
    Store.modal.preventHideByAction = true;
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("loading-modal", {
        text: "Ihr PDF wird erstellt und steht in Kürze zum Download bereit."
      })
    );
    Store.modal.showModal();

    await Vue.nextTick(async () => {
      const { pdfPages } = this.theEvent as Event;
      let doc = new jsPDF("p", "mm");
      let eventFavorites = eventExportList.getList();

      if (pdfPages && pdfPages.length) {
        pdfPages.forEach((page: EventPDFPage, idx: number) => {
          // Create the bg
          let pageBg = document.createElement("img");
          pageBg.src = Constants.middlewareStorageUrl + page.backgroundSrc;

          // Add a new page
          if (idx > 0) {
            doc.addPage();
          }
          doc.addImage(
            pageBg,
            "JPG",
            0,
            0,
            page.backgroundSize.width,
            page.backgroundSize.height
          );

          // Create the segment images
          page.segments.forEach((segment: PDFSegment) => {
            let image = document.createElement("img");

            // Check if segment has favorite
            if (eventFavorites[segment.key]) {
              // Get the index of the image
              // <!> NOTE: Hopefully we won't have more than 10 content groups per segment
              let indexPosition = eventFavorites[segment.key].length - 1; // Position of the index
              let imageIdx = eventFavorites[segment.key][indexPosition];

              // Add the image to the pdf
              image.src =
                Constants.middlewareStorageUrl + segment.sources[imageIdx];
              doc.addImage(
                image,
                "JPG",
                segment.position.x,
                segment.position.y,
                page.segmentSize.width,
                page.segmentSize.height
              );
            } else {
              // Use default empty placeholder
              image.src =
                Constants.middlewareStorageUrl + page.emptySegmentPlaceholder;
              doc.addImage(
                image,
                "JPG",
                segment.position.x,
                segment.position.y,
                page.segmentSize.width,
                page.segmentSize.height
              );
            }
          });
        });
      }

      doc.save(`mein-${process.env.VUE_APP_THEME}-barcamp.pdf`);

      this.exportable = false;
      Store.modal.hideModal();
    });
  }

  getComponentType() {
    return "event_programs";
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      await this.loadEvent();
      this.hideCustomPopover();
    });
  }

  async loadEvent() {
    const events: Array<any> = [];

    if (this.meta) {
      const metaContent: any = await this.loadContent(
        this.meta.link,
        [this.meta._id],
        0
      );
      if (metaContent && metaContent.length > 0) {
        events.push(...metaContent[0].meta);
      }
    }

    let eventIdToSearchFor: any = null;

    if (this.cockpitEventId) {
      eventIdToSearchFor = this.cockpitEventId;
    } else {
      eventIdToSearchFor = this.eventId;
    }

    let event = events.find((e: any) => e.id == eventIdToSearchFor);

    if (event) {
      this.theEvent = new Event(event);
    }
  }

  async mounted() {
    await super.mounted();
  }

  get cockpitEventId() {
    return this.getSettingsAttribute("event_id") || null;
  }

  get meta() {
    return this.getContentAttribute("meta");
  }

  get useDatabase() {
    return this.getContentAttribute("use_database") || false;
  }

  get layoutType() {
    return (
      this.getContentAttribute("layout_type") || EventProgramLayout.default
    );
  }

  get layoutClass() {
    if (this.layoutType !== EventProgramLayout.default) {
      return `event-program--${this.layoutType}`;
    }
    return "";
  }
  get cssClass() {
    return this.getSettingsAttribute("class");
  }

  get program() {
    if (this.theEvent) {
      const userRoles = User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)
        ? []
        : User.getUserRoleNames();
      const program = this.theEvent.program;
      const filteredProgram: EventProgramModel[] = [];

      program.forEach((currentProgram: EventProgramModel) => {
        let newProgram: EventProgramModel = new EventProgramModel({
          id: currentProgram.id,
          date: currentProgram.date,
          wrapperClass: currentProgram.wrapperClass,
          class: currentProgram.class,
          segments: []
        });

        currentProgram.segments.forEach(
          (currentSegment: EventProgramSegment) => {
            if (
              currentSegment.role.length === 0 ||
              userRoles.includes(currentSegment.role as UserRoleName)
            ) {
              newProgram.segments.push({
                startTime: currentSegment.startTime,
                endTime: currentSegment.endTime,
                highlighted: currentSegment.highlighted,
                segmentContent: currentSegment.segmentContent,
                role: currentSegment.role,
                groups: this.getFilteredGroups(currentSegment.groups, userRoles)
              });
            }
          }
        );

        if (newProgram.segments.length > 0) {
          filteredProgram.push(newProgram);
        }
      });
      return filteredProgram;
    }

    return [];
  }

  // Utils

  getFilteredGroups(
    groups: EventProgramSegmentGroup[],
    userRoles: Array<UserRoleName>
  ): EventProgramSegmentGroup[] {
    const filteredGroups: EventProgramSegmentGroup[] = [];

    for (let idx = 0; idx < groups.length; idx += 1) {
      const currentGroup: EventProgramSegmentGroup = groups[idx];
      // check group role
      if (
        currentGroup.role.length === 0 ||
        userRoles.includes(currentGroup.role as UserRoleName)
      ) {
        // filter content based on user role
        const content: EventProgramSegmentGroupContent[] = currentGroup.content.filter(
          (currentContent: EventProgramSegmentGroupContent) =>
            currentContent.role.length === 0 ||
            userRoles.includes(currentContent.role as UserRoleName)
        );
        // add group
        if (content.length > 0) {
          filteredGroups.push({
            class: currentGroup.class,
            name: currentGroup.name,
            role: currentGroup.role,
            content
          });
        }
      }
    }

    return filteredGroups;
  }

  get exportBtnIcon() {
    return "/assets/" + Store.frontend + "/eventprogram/export-icon.svg";
  }

  hideCustomPopover() {
    // Added event listener on click/toggle for details to close the popup and open only one instance at a time
    // @ts-ignore
    [...document.getElementsByTagName("details")].forEach(
      (element, index, arr) => {
        element.addEventListener(
          "click",
          elem =>
            element.open &&
            arr.forEach(
              details => details != elem.target && (details.open = false)
            )
        );
        element.addEventListener(
          "toggle",
          elem =>
            element.open &&
            arr.forEach(
              details => details != elem.target && (details.open = false)
            )
        );
        document.addEventListener(
          "click",
          elem =>
            element.open &&
            arr.forEach(
              details => details != elem.target && (details.open = false)
            )
        );
      }
    );
  }
}
