
import { Vue, Component } from "vue-property-decorator";
import { LayoutComponent, LocalStorageSavedRoutes, Store, TEXT } from "common";

@Component
export default class LoginModalTemplate extends Vue {
  public registerSlug: string = "/registrieren";
  public login = LayoutComponent.createLogin();
  public loginTitle: string = "";
  public previousRoute!: string | null;

  constructor() {
    super();

    if (Store.isGynonco()) {
      this.loginTitle = TEXT.gynoco.loginModalTitle;
    }

    this.previousRoute =
      localStorage.getItem(LocalStorageSavedRoutes.PREVIOUS_ROUTE) || null;
  }

  get hcpLoginLink() {
    return "https://mein-medcampus.de/login";
  }
}
