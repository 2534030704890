
import { Component, Vue } from "vue-property-decorator";
import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class GuidelineFormDownload extends BaseComponent {
  getComponentType() {
    return "guideline_form_downloads";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
  }

  get textButton() {
    return this.getContentAttribute("text_button");
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  get layoutGuidelineForm() {
    return this.getLayoutComponentsFromLayout(
      this.getContentAttribute("guideline_form")
    )[0];
  }

  get buttonOnSameSlide() {
    return this.getContentAttribute("button_is_on_same_slide");
  }

  get template() {
    return this.getContentAttribute("template");
  }

  get componentId() {
    return this.getContentAttribute("id");
  }

  async download() {
    const target = document.querySelector("#template-wrapper") as HTMLElement;
    if (!target) {
      return;
    }

    if (["mammaMainForm", "mammaSecondForm"].includes(this.template)) {
      target.style.setProperty("--theme-primary-color", "#ff3399");
    }

    if (this.buttonOnSameSlide) {
      let guidelineForm = document.querySelector(".guideline-form");
      let guidelineFormClone = document.querySelector("#guideline-form-clone");
      if (guidelineForm && guidelineFormClone) {
        guidelineFormClone.replaceWith(guidelineForm.cloneNode(true));
      }

      guidelineFormClone = document.querySelector(
        "#template-wrapper .guideline-form"
      );
      if (guidelineFormClone) {
        guidelineFormClone.setAttribute("id", "guideline-form-clone");
      }
    } else {
      let guidelineFormAnswers = JSON.parse(
        localStorage.getItem("guidelineFormAnswers") || "{}"
      );

      for (const [key, answer] of Object.entries(guidelineFormAnswers)) {
        if (Array.isArray(answer)) {
          answer.forEach(id => {
            let input = document.getElementById(
              `check_${key}_${id}`
            ) as HTMLInputElement;
            if (input) {
              input.checked = true;
            }
          });
        } else {
          let input = document.getElementById(
            `check_${key}_${answer}`
          ) as HTMLInputElement;
          if (input) {
            input.checked = true;
          }
        }
      }
    }

    await Vue.nextTick(async () => {
      // const targetArea = target.getBoundingClientRect();

      // const canvas = await html2canvas(target, {
      //   backgroundColor: null,
      //   width: targetArea.width,
      //   height: targetArea.height,
      //   scrollX: 0,
      //   scrollY: -window.scrollY,
      // });

      const doc = new jsPDF({ orientation: "p", unit: "px", format: "a4" });
      doc.setFontSize(12);

      const width = doc.internal.pageSize.getWidth() - 20;
      // const height = (width * targetArea.height) / targetArea.width;

      // const canvasImage = canvas.toDataURL("image/png");

      // doc.addImage(
      //   canvasImage,
      //   "PNG",
      //   20,
      //   20,
      //   width,
      //   height
      // );

      target.style.width = width - 40 + "px";

      doc.html(target, {
        callback: function(doc) {
          doc.save("guideline-form.pdf");
        },
        x: 0,
        y: 0,
        margin: 24,
        width: width,
        windowWidth: width,
        autoPaging: "text"
      });
    });
  }
}
