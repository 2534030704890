var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-wrapper form-wrapper--newsletter",class:_vm.layoutComponent.class,attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(_vm.showDefaultAlert)?_c('form',{staticClass:"form row",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"form-description"},_vm._l((_vm.formDescription),function(comp,key,idx){return _c(comp.component,{key:idx,tag:"component",attrs:{"layoutComponent":comp}})}),1),_vm._l((_vm.formConfig),function(comp,key,idx){return [_c(comp.compType,{key:idx,tag:"component",attrs:{"formData":_vm.formConfig,"field":key,"inputType":comp.type,"inputClass":{
          'is-invalid':
            (comp.required || comp.type === 'email') &&
            _vm.attemptSubmit &&
            !_vm.validateInput(key),
          'is-valid':
            (comp.required || comp.type === 'email') &&
            _vm.attemptSubmit &&
            _vm.validateInput(key)
        }}})]}),_vm._m(0),(_vm.showAlert)?_c('div',{staticClass:"form__alert col-12 mb-5"},[_c('a-alert',{attrs:{"type":_vm.alertType,"show-icon":"","closable":""},domProps:{"innerHTML":_vm._s(_vm.alertMessage)}})],1):_vm._e(),_c('div',{staticClass:"form__action col-12"},[_c('button',{staticClass:"btn btn--submit mr-4",class:_vm.submitBtnClass,attrs:{"type":"submit"}},[(_vm.loading)?_c('span',{staticClass:"spinner-border",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.submitBtnText)+" ")]),_c('button',{staticClass:"btn btn--reset",class:_vm.resetBtnClass,attrs:{"type":"button"},on:{"click":() => {
            _vm.resetForm();
            _vm.saveSuccess = false;
          }}},[_vm._v(" "+_vm._s(_vm.resetBtnText)+" ")])]),_c('div',{staticClass:"form-footer"},_vm._l((_vm.formFooter),function(comp,key,idx){return _c(comp.component,{key:idx,tag:"component",attrs:{"layoutComponent":comp}})}),1)],2):(!_vm.showDefaultAlert)?_c('div',{staticClass:"modal-success-message",domProps:{"innerHTML":_vm._s(_vm.sendSuccessMessage)}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form__legend col-12 mt-5"},[_c('p',{staticClass:"small"},[_vm._v("Mit * markierte Felder sind Pflichtfelder")])])
}]

export { render, staticRenderFns }