
import { Component, Vue } from "vue-property-decorator";
import { isEmpty, has } from "lodash";
import { RoutingHelper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LcVideo extends BaseComponent {
  private poster!: any;
  private videoRef!: any;
  public clickableAreas!: Array<any>;
  // Play in viewport scroll offset (percentage)
  private scrollOffset: number = 17;

  constructor() {
    super();
    this.poster = null;
    this.clickableAreas = [];
  }

  getComponentType() {
    return ["files", "external_files"];
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    Vue.nextTick(() => {
      if (this.file) {
        this.videoRef = this.$refs[this.file._id] as any;
      }

      if (this.playInView && this.videoRef) {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleScroll);
        this.playHandler();
      }
    });

    let image = this.getContentAttribute("image");
    if (image) {
      let poster = await this.loadContent("images", [
        image[0].settings.content._id
      ]);

      if (poster) {
        this.poster = poster[0];
      }
    }

    this.mapClickableAreas();
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  }

  handleScroll() {
    if (this.videoRef) {
      this.playHandler();
    }
  }

  isInViewport(element: any) {
    const rect = element.getBoundingClientRect();
    const offset = (rect.height * this.scrollOffset) / 100;

    if (rect && rect.height > 0 && rect.width > 0) {
      return (
        rect.top >= -offset &&
        rect.left + 50 >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) +
            offset &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    return false;
  }

  playHandler() {
    if (!this.isInViewport(this.videoRef)) {
      this.videoRef.pause();
    }
  }

  mapClickableAreas() {
    if (!isEmpty(this.metaContent) && has(this.metaContent, "image_map")) {
      this.clickableAreas.push(
        ...this.metaContent.image_map.areas.map((area: any) => {
          const { href, alt } = area;
          const coords = area.coords
            .split(",")
            .map((coord: string) => Number.parseInt(coord));

          return {
            href,
            alt,
            style: {
              left: `${coords[0]}px`,
              top: `${coords[1]}px`,
              width: `${coords[2] - coords[0]}px`,
              height: `${coords[3] - coords[1]}px`
            }
          };
        })
      );
    }
  }

  get file() {
    let file = this.getContentAttribute("file");
    return file ? file : null;
  }

  get filePath() {
    return this.file ? RoutingHelper.getFilePath(this.file.path) : null;
  }

  get playInView() {
    return !!this.layoutComponent.settings["play_in_view"];
  }

  get external_file() {
    let externalFile = this.getContentAttribute("external_file");
    return externalFile ? externalFile : null;
  }

  get video_poster() {
    if (this.poster) {
      return RoutingHelper.getImagePath(this.poster.image.path);
    }
    return null;
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  get metaContent() {
    return this.getContentAttribute("meta");
  }
}
