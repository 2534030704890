
import { Vue, Watch, Prop } from "vue-property-decorator";
import {
  ContentRepository,
  Helper,
  LayoutComponent,
  ResultType,
  Store,
  ContentQueue,
  User,
  UserLoginStatus
} from "common";

export default class BaseComponent extends Vue {
  @Prop() layoutComponent!: LayoutComponent;

  public customLayoutComponent?: LayoutComponent;

  public content!: any;
  public contentLoaded!: boolean;

  public maximumReLoadingAttempts!: number;
  public contentReLoadingAttempts!: number;

  public forceLoadStop!: boolean;

  private randomId!: string;

  public componentType: string | null;
  public internalId: string | null;

  constructor() {
    super();
    this.content = null;
    this.contentLoaded = false;
    this.maximumReLoadingAttempts = 3;
    this.contentReLoadingAttempts = 0;
    this.forceLoadStop = false;
    this.componentType = "";
    this.internalId = "";
    this.randomId = this.generateRandomId();
  }

  async mounted() {
    if (this.forceLoadStop) {
      return;
    }

    this.componentType =
      this.layoutComponent && this.layoutComponent.component
        ? this.layoutComponent.component
        : null;
    this.internalId =
      this.layoutComponent &&
      this.layoutComponent.settings &&
      this.layoutComponent.settings.content &&
      this.layoutComponent.settings.content._id
        ? this.layoutComponent.settings.content._id
        : null;

    let componentId = this.layoutComponent._id;
    ContentQueue.addElement(componentId ? componentId : this.randomId);

    /* The content of the SCF corresponds to multiple collection links, we need to load every content type */
    if (Array.isArray(this.getComponentType())) {
      let tmpContent: any = [];
      let self = this;
      await this.asyncForEach(
        this.getComponentType() as string[],
        async (type: string) => {
          let typeContent = await this.loadContent(type, self.contentIds);
          if (typeContent) {
            tmpContent = tmpContent.concat(typeContent);
          }
        }
      );
      this.content = tmpContent;
      this.contentLoaded = true;
      ContentQueue.removeElement(componentId ? componentId : this.randomId);
    } else {
      /* The content corresponds to only one collection link, so we just load that one */
      this.content = await this.loadContent(
        this.getComponentType() as string,
        this.contentIds
      );
      this.contentLoaded = true;
      ContentQueue.removeElement(componentId ? componentId : this.randomId);
    }
  }

  async asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  /**
   * Override in specific SCF
   */
  getComponentType(): string | string[] {
    return "";
  }

  get contentIds() {
    if (this.layoutComponent && this.layoutComponent.settings.content) {
      if (Array.isArray(this.layoutComponent.settings.content)) {
        let mapped = this.layoutComponent.settings.content.map((el: any) => {
          return el._id;
        });

        return mapped;
      }

      return [this.layoutComponent.settings.content._id];
    }
    return [];
  }

  getContentAttribute(attribute: string, index: number = 0): any {
    if (this.content != null && this.content.length > 0) {
      if (Object.keys(this.content[index]).includes(attribute)) {
        return this.content[index][attribute];
      }
    }
    return null;
  }

  getSettingsAttribute(attribute: string, useCustom: boolean = false): any {
    if (this.layoutComponent != null && !useCustom) {
      if (Object.keys(this.layoutComponent.settings).includes(attribute)) {
        return this.layoutComponent.settings[attribute];
      }
    } else if (this.customLayoutComponent && useCustom) {
      if (
        Object.keys(this.customLayoutComponent.settings).includes(attribute)
      ) {
        return this.customLayoutComponent.settings[attribute];
      }
    }
    return null;
  }

  getLayoutComponentsFromLayout(layout: any) {
    let components: LayoutComponent[] = [];

    if (layout) {
      layout.forEach((el: any) => {
        let lc = new LayoutComponent(el);
        if (lc.visible) {
          components.push(lc);
        }
      });
    }

    return components;
  }

  getColumns(width: string) {
    let colArray: string[] = Helper.getMappedCssClassesAsArray(width);
    return colArray.join(" ");
  }

  async loadContent(
    type: string,
    ids: string[],
    populate: number = 0,
    fields: any = null
  ): Promise<any> {
    const isLoggedIn =
      !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT) &&
      !User.hasUserLoginStatus(UserLoginStatus.DOCCHECK_PENDING);

    const user = User.retrieveFromStorage();
    const isMWO = user && user.mayWatchOne;

    /* Filter view_widgets by role */
    if (isLoggedIn && !isMWO && type === "view_widgets") {
      const userRoles = User.getUserRoleNames();
      let response = await ContentRepository.getCollectionItemsByRolesAndIds(
        type,
        ids,
        userRoles
      );

      if (response.type === ResultType.SUCCESS) {
        return response.value;
      }
    } else {
      if (this.getComponentType() != "") {
        let self = this;
        let response = await ContentRepository.getCollectionItemByTypeAndId(
          type,
          ids,
          populate,
          fields
        );

        if (response.type == ResultType.SUCCESS) {
          return response.value;
        } else {
          if (this.contentReLoadingAttempts < this.maximumReLoadingAttempts) {
            this.contentReLoadingAttempts++;
            return this.loadContent(type, ids, populate, fields);
          }
        }
      }
    }

    return null;
  }

  showModal(
    title: string,
    component: LayoutComponent,
    className: string = "modal-lg"
  ) {
    Store.modal.title = title;
    Store.modal.className = className;
    Store.modal.setLayoutComponent(component);
    Store.modal.showModal();
  }

  hideModal() {
    Store.modal.hideModal();
  }

  @Watch("contentLoaded")
  contentDidLoad() {
    this.$emit("contentDidLoad", this.content);
  }

  generateRandomId(): string {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }
}
