
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { RoutingHelper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class OrderListItem extends BaseComponent {
  @Prop({ default: false }) isOrdered!: boolean;
  @Prop() isVisible!: boolean;

  public lang: string = "";
  public amt: string = "";

  getComponentType() {
    return "order_list_items";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    Vue.nextTick(() => {
      this.$emit("update", {
        text: this.label,
        language: null,
        amount: null,
        needed: [this.languages.length > 0 ? "language" : "", "amount"]
      });
    });
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get description() {
    const descriptionText = this.getContentAttribute("description");
    return descriptionText && descriptionText.trim().length > 0
      ? descriptionText
      : null;
  }

  get packaging() {
    return this.getContentAttribute("packaging");
  }

  get languages() {
    let languages = this.getContentAttribute("languages");
    return languages ? languages : [];
  }

  get amount() {
    let amount = this.getContentAttribute("amount");

    if (Array.isArray(amount)) {
      amount = amount.sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true })
      );
    }

    return amount;
  }

  get thumbnail() {
    let thumbnail = this.getContentAttribute("thumbnail");
    if (thumbnail) {
      return RoutingHelper.getImagePath(thumbnail.path);
    }
    return "";
  }

  @Watch("lang")
  changeLanguage() {
    this.$emit("update", { language: this.lang });
  }

  @Watch("amt")
  changeAmount() {
    this.$emit("update", { amount: this.amt });
  }

  removeFromOrder() {
    this.amt = "";
    this.lang = "";
  }

  popoverAppendTo() {
    return document.getElementById("layout-content") || null;
  }
}
