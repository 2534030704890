
import { Component, Vue, Prop } from "vue-property-decorator";
import BaseContent from "../../base/BaseContent.vue";

@Component({
  components: {
    BaseContent
  }
})
export default class DefaultArticleWidgetTemplate extends Vue {
  @Prop() classList!: any;
  @Prop() image!: any;
  @Prop() show_image!: any;
  @Prop() imageLoaded!: any;
  @Prop() headline!: any;
  @Prop() headlineLoaded!: any;
  @Prop() layoutComponent!: any;
  @Prop() text!: any;
  @Prop() userNoTracking!: any;
  @Prop() accountProfile!: any;
  @Prop() saveArticle!: any;
  @Prop() saved!: any;
  @Prop() gotoView!: any;
  @Prop() contentLoaded!: any;
}
