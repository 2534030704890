
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LoadingAnimation extends Vue {
  @Prop({ default: "#000" }) color!: string;
  @Prop({ default: 6 }) size!: number;

  get first_position() {
    return this.size;
  }

  get second_position() {
    return this.size * 4.33;
  }

  get third_position() {
    return this.size * 7.66;
  }
}
