import AbstractRepository from "./AbstractRepository";
import { LayoutComponent } from "../../models/LayoutComponent";
import Constants from "../../models/Constants";
import Client from "../util/Client";
import { Fail, ResultType } from "../util/Result";

export interface ConfiguratorPackage {
  _id: string;
  code: string;
  slugs: string[];
}

class ConfiguratorRepository extends AbstractRepository {
  async getPackage(code: string) {
    let data = {
      fields: {
        _id: 1,
        code: 1,
        slugs: 1
      },
      filter: {
        code
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_package"),
      data,
      this.getAdditionalHeader()
    );

    if (response.type === ResultType.SUCCESS && !response.value.length) {
      return Fail("abc", 404);
    }

    if (response.type === ResultType.SUCCESS && response.value.length) {
      response.value = response.value[0];
    }

    const result = this.getResult<any, ConfiguratorPackage>(
      response,
      (r: any) => ({
        ...r,
        slugs: r.slugs.split(" ")
      })
    );

    return result;
  }

  /**
   * Configurator Step 1
   * @param ids
   */
  async getIndications() {
    let data = {
      fields: {
        _id: 1
      },
      filter: {
        configurator_content: true
      },
      sort: {
        order: 1
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("indications"),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, LayoutComponent[]>(response, (r: any) => {
      let indications: LayoutComponent[] = [];
      r.forEach((indication: any) => {
        indications.push(
          LayoutComponent.createFromCollectionItem("indication", indication)
        );
      });
      return indications;
    });
    return result;
  }

  /**
   * Configurator Step 2
   * @param indication
   */
  async getLanguages(indication: string) {
    let data = {
      fields: {
        language: 1
      },
      filter: {
        "indication.display": { $eq: indication }
      },
      sort: {
        _created: 1
      },
      simple: 1
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_snippets"),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      let languages: any[] = [];
      r.forEach((language: any) => {
        if (!languages.includes(language.language)) {
          languages.push(language.language);
        }
      });
      return languages;
    });
    return result;
  }

  /**
   * Configurator Step 3
   * @param indication
   * @param language
   */
  async getCheckboxes(indication: string, language: string) {
    let data = {
      fields: {
        category: 1,
        title: 1,
        element: 1,
        group: 1,
        pageSlug: 1
      },
      filter: {
        "indication.display": { $eq: indication },
        language: { $eq: language }
      },
      simple: 1
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_snippets"),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async getOrder(ids: any[]) {
    let data = {
      filter: {
        id: { $in: ids }
      },
      simple: 1
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("configuration_order_by_title"),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  /**
   * Configurator Step 4
   * @param ids
   */
  async getContent(ids: string[]) {
    let data = {
      fields: {
        title: 1,
        category: 1,
        content: 1
      },
      filter: {
        _id: { $in: ids }
      },
      simple: 1,
      populate: 20
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("configurator_snippets"),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      let content: any = {};
      r.forEach((c: any) => {
        if (!Object.keys(content).includes(c.category)) {
          content[c.category] = [];
        }

        if (
          content[c.category].find((el: any) => el.title == c.title) == null
        ) {
          let components: LayoutComponent[] = [];
          let arr = c.content;

          arr.forEach((compTmp: any) => {
            components.push(new LayoutComponent(compTmp));
          });

          c.content = components;
          content[c.category].push(c);
        }
      });
      return content;
    });
    return result;
  }

  /**
   * Configurator Step 5
   * @param code
   * @param slugs
   */
  async createPackage(code: string, slugs: string) {
    const dataToSave = {
      data: {
        code,
        slugs
      }
    };

    const response = await Client.post(
      `${Constants.collectionsUrl}/save/configurator_package`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  /**
   * Configurator Step 5
   * @param indication
   * @param snippets
   */
  async create(indication: string, snippets: string[], subTerritory: string) {
    let data = {
      indication: indication,
      configurator_snippets: snippets,
      sub_territory: subTerritory
    };

    let response = await Client.postWithJsonResult(
      Constants.configurationUrl,
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  /**
   * Configurator Printpage
   */
  async getPrintPage() {
    let data = {
      filter: {
        id: "patient_hub.print_stub"
      },
      simple: 1,
      populate: 20
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("pages"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      let post = r.length > 0 ? r[0] : null;
      let layoutComponents: LayoutComponent[] = [];
      if (post) {
        let layout = post.layout;
        layout.forEach((c: any) => {
          layoutComponents.push(new LayoutComponent(c));
        });
      }
      return layoutComponents;
    });

    return result;
  }
}

export default new ConfiguratorRepository();
