export const enum ResultType {
  SUCCESS,
  FAIL
}

interface SuccessInterface<T> {
  type: ResultType.SUCCESS;
  value: T;
}

interface FailInterface {
  type: ResultType.FAIL;
  message: string;
  statusCode?: number;
  raw?: string;
}

export function Success<T>(val: T): SuccessInterface<T> {
  return {
    type: ResultType.SUCCESS,
    value: val
  };
}

// @ts-ignore
export function Fail(message: string, statusCode?: number, raw?: string): Fail {
  return {
    type: ResultType.FAIL,
    message: message,
    statusCode: statusCode,
    raw: raw
  };
}

export function convertResult<T, E>(
  result: Result<T>,
  convert: (data: T) => E
): Result<E> {
  switch (result.type) {
    case ResultType.SUCCESS:
      return Success(convert(result.value));
    case ResultType.FAIL:
      return result;
  }
}

export type Result<T> = SuccessInterface<T> | FailInterface;
