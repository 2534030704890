
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Informationportal extends BaseComponent {
  getComponentType() {
    return "information_portals";
  }

  constructor() {
    super();
  }

  async mounted() {
    await super.mounted();
  }

  get name() {
    return this.getContentAttribute("name");
  }

  get website() {
    return this.getContentAttribute("website");
  }

  get phone() {
    return this.getContentAttribute("phone");
  }

  get email() {
    return this.getContentAttribute("email");
  }

  get address() {
    return this.getContentAttribute("address");
  }

  get description() {
    return this.getContentAttribute("description");
  }

  get link() {
    if (this.website) {
      if (!this.website.includes("http")) {
        return `https://${this.website}`;
      }
      return this.website;
    }

    return "#";
  }

  get bookmarker() {
    return LayoutComponent.createEmpty("bookmarker", {
      collection_title: "Informationsportal",
      collection: {
        link: "information_portals",
        _id: this.getContentAttribute("_id"),
        display: this.getContentAttribute("id")
      }
    });
  }

  get sharingIcon() {
    return "/assets/bookmarker/share.svg";
  }
}
