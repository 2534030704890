
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import validator from "validator";
import {
  APP_LOGIN_HANDLED,
  EventBus,
  config,
  FrontendModules,
  ContentRepository,
  LoginHandler,
  ResultType,
  router,
  Store,
  UserRepository
} from "common";
import AZLogoLoader from "../util/AZLogoLoader.vue";

@Component
export default class BatchLogin extends BaseComponent {
  password!: string;
  attemptSubmit!: Boolean;
  error?: string;
  defaultRedirect!: string;
  step!: number;
  maxSteps!: number;

  getComponentType() {
    return "batch_logins";
  }

  constructor() {
    super();
    this.password = "";
    this.attemptSubmit = false;
    this.defaultRedirect = "/";
    this.step = -1;
    this.maxSteps = -1;
    this.error = "";
  }

  get validatePassword() {
    return !validator.isEmpty(this.password);
  }

  get batchLoginIsActive() {
    return config
      .getFrontendConfiguration()
      .modules.includes(FrontendModules.BATCH_LOGIN);
  }

  get defaultRedirectPage() {
    return this.getContentAttribute("default_redirect_page");
  }

  get errorMessage() {
    return this.getContentAttribute("error_message");
  }

  get description() {
    return this.getContentAttribute("description");
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get clickThroughSteps() {
    return this.getContentAttribute("click_through_steps");
  }

  get currentClickThroughStep() {
    if (this.step == -999) {
      return [this.clickThroughAbort[0]];
    }

    return [this.clickThroughSteps[this.step]];
  }

  get showSteps() {
    return this.step < this.maxSteps;
  }

  get clickThroughAbort() {
    return this.getContentAttribute("click_through_abort");
  }

  async mounted() {
    await super.mounted();
    if (
      localStorage.currentRoute &&
      !localStorage.currentRoute.includes("/mehrinfo")
    ) {
      document.body.classList.add("no-scroll");
    }
  }

  beforeDestroy() {
    document.body.classList.remove("no-scroll");
  }

  async contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.initSteps();
      await this.loadDefaultRedirectPath();
    });
  }

  async loadDefaultRedirectPath() {
    let page = this.defaultRedirectPage;
    if (page) {
      let slugResponse = await ContentRepository.getCollectionItemByTypeAndId(
        page.link,
        [page._id],
        0,
        {
          slug: 1
        }
      );

      if (slugResponse.type == ResultType.SUCCESS) {
        let slug =
          slugResponse.value[0].slug === "/" ? "" : slugResponse.value[0].slug;
        this.defaultRedirect = "/" + slug;
      }
    }
  }

  async validate() {
    this.attemptSubmit = true;
    this.unsetError();
    let product = this.retrieveProductFromConfig();
    if (this.validatePassword && this.batchLoginIsActive && product) {
      let result = await UserRepository.loginBatch(this.password, product);
      if (result.type == ResultType.SUCCESS) {
        LoginHandler.login(result.value, () => {
          EventBus.$emit(APP_LOGIN_HANDLED);
          let returnUrl = Store.retrieveReturnUrl() || this.defaultRedirect;
          Store.deleteSavedReturnUrl();
          router.push(returnUrl);
        });
      } else {
        this.error = this.errorMessage;
      }
    }
    this.attemptSubmit = false;
  }

  initSteps() {
    if (this.clickThroughSteps) {
      this.step = 0;
      this.maxSteps = this.clickThroughSteps.length;
    }
  }

  retrieveProductFromConfig() {
    return this.batchLoginIsActive
      ? config.getFrontendConfiguration().meta.batch_product
      : undefined;
  }

  unsetError() {
    this.error = undefined;
  }

  abort() {
    this.step = -999;
    setTimeout(() => {
      window.location.href = config.getFrontendConfiguration().redirectRouteAfterLogout;
    }, 500);
  }

  nextStep() {
    this.step++;
  }
}
