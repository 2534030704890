import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class CustomContactFormRepository extends AbstractRepository {
  async save(email: String, answers: Object) {
    const data = {
      email: email,
      answers: answers
    };

    let response = await Client.postWithJsonResult(
      `${Constants.apiUrl}/custom-contact`,
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new CustomContactFormRepository();
