var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.contentLoaded)?_c('div',{staticClass:"skeleton__lcimage"}):(_vm.visibility)?_c('div',{staticClass:"lc-image-wrapper",class:{
    'lc-image-wrapper--lightbox': _vm.lightbox && !Object.entries(_vm.imageMap).length
  }},[(_vm.lazyLoad)?_c('img',{staticClass:"lazy rs_skip img-fluid lc-image",class:_vm.allCssClasses,style:(_vm.customStyles),attrs:{"data-src":_vm.path,"alt":_vm.alt ?? _vm.metaTitle,"title":_vm.metaTitle,"usemap":Object.entries(_vm.imageMap).length ? `#${_vm.imageMap.name}` : null,"data-component":_vm.componentType,"data-id":_vm.internalId,"id":_vm.layoutComponent.id},on:{"click":function($event){_vm.lightbox && !Object.entries(_vm.imageMap).length
        ? (_vm.lightboxVisible = true)
        : null}}}):_c('img',{staticClass:"rs_skip img-fluid lc-image",class:_vm.allCssClasses,style:(_vm.customStyles),attrs:{"src":_vm.path,"alt":_vm.alt ?? _vm.metaTitle,"title":_vm.metaTitle,"usemap":Object.entries(_vm.imageMap).length ? `#${_vm.imageMap.name}` : null,"data-component":_vm.componentType,"data-id":_vm.internalId,"id":_vm.layoutComponent.id},on:{"click":function($event){_vm.lightbox && !Object.entries(_vm.imageMap).length
        ? (_vm.lightboxVisible = true)
        : null}}}),(_vm.lightbox && !Object.entries(_vm.imageMap).length)?_c('button',{staticClass:"btn btn-sm btn-primary lightbox-btn",on:{"click":function($event){_vm.lightboxVisible = true}}},[_c('a-icon',{attrs:{"type":"zoom-in"}})],1):_vm._e(),(_vm.lightbox && !Object.entries(_vm.imageMap).length)?_c('vue-easy-lightbox',{attrs:{"escDisabled":"","moveDisabled":"","visible":_vm.lightboxVisible,"imgs":[_vm.path]},on:{"hide":function($event){_vm.lightboxVisible = false}}}):_vm._e(),(Object.entries(_vm.imageMap).length)?_c('map',{attrs:{"name":_vm.imageMap.name}},_vm._l((_vm.imageMap.areas),function(area,areaIdx){return _c('area',{key:areaIdx,attrs:{"shape":area.shape,"coords":area.coords,"alt":area.alt,"href":area.href}})}),0):_vm._e(),(_vm.title)?_c('div',{staticClass:"lc-image-title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }