
import { Component } from "vue-property-decorator";
import { LayoutComponent, router } from "common";
import BaseWidget from "./BaseWidget.vue";

// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class EditorsPickSlider extends BaseWidget {
  private slugs: any = [];
  private initialSpacePadding = 0;
  public carouselOptions: any = {
    perPage: 1,
    mouseDrag: true,
    paginationEnabled: false,
    navigationEnabled: true,
    navigationNextLabel: "",
    navigationPrevLabel: "",
    spacePadding: 0,
    autoplay: true,
    autoplayTimeout: 5000,
    loop: true
  };

  getComponentType() {
    return "view_widgets";
  }

  async mounted() {
    super.mounted();
    this.setCarouselOptions();

    // The `spacePadding` prop will be used only on desktop.
    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  beforeDestroy() {
    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }
  }

  getWidgetHeadline(widget: any) {
    let headline = widget.headline;
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    return null;
  }

  getWidgetImage(widget: any) {
    let image = widget.image;
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  getWidgetText(widget: any) {
    let text = widget.text;
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }
    return null;
  }

  async loadWidgetSlug(widget: any) {
    let cockpitLink = widget.cockpit_link;

    if (cockpitLink) {
      let slugResponse = await this.loadContent(
        cockpitLink.link,
        [cockpitLink._id],
        0,
        {
          slug: 1,
          _id: 0
        }
      );

      if (slugResponse) {
        this.slugs[widget.id] = slugResponse[0].slug;
        return true;
      }
    }

    return false;
  }

  gotoView(widget: any) {
    router.push({ path: `/${this.slugs[widget.id]}` });
  }

  setCarouselOptions() {
    const options = this.layoutComponent.settings.carousel_options;
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }
    // If we set the `spacePadding` prop with 0, the padding will not be removed.
    this.carouselOptions.spacePadding =
      window.innerWidth < 768 ? 1 : this.initialSpacePadding;
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }
}
