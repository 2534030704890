
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContentRepository, LayoutComponent, router, ResultType } from "common";
// @ts-ignore
import { Carousel, Slide } from "vue-carousel";
import BaseComponent from "../base/BaseComponent.vue";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class KalturaVideoSlider extends BaseComponent {
  readonly GRID_MD_BREAKPOINT = 768;

  @Prop({ default: null }) alternativeVideoList!: Array<LayoutComponent> | null;
  @Prop({ default: null }) altSliderTexts!: any;
  @Prop({ default: false }) shouldUpdate!: boolean;

  private sliderItemsCount!: number;
  private sliderItemsLoaded!: number;
  public itemsToRender!: any;
  public loaded!: boolean;
  public videoToRender!: LayoutComponent | null;
  private setVideoCompDelay: any;
  private urlParams!: string;

  private initialSpacePadding = 0;
  public carouselOptions: any = {
    perPage: 1,
    perPageCustom: [
      [768, 2],
      [1024, 3]
    ],
    mouseDrag: true,
    paginationEnabled: true,
    navigationEnabled: true,
    navigationNextLabel: "<i class='fas fa-chevron-right fa-lg'></i>",
    navigationPrevLabel: "<i class='fas fa-chevron-left fa-lg'></i>",
    spacePadding: 0,
    autoplay: false,
    autoplayTimeout: 5000,
    loop: true
  };

  constructor() {
    super();
    this.itemsToRender = [];
    this.videoToRender = null;
    this.sliderItemsCount = 0;
    this.sliderItemsLoaded = 0;
    this.loaded = false;
    this.urlParams = "";
  }

  getComponentType() {
    return "kaltura_video_sliders";
  }

  async mounted() {
    super.mounted();
    this.setCarouselOptions();

    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  async contentDidLoad() {
    super.contentDidLoad();
    this.sliderItemsCount = this.sliderItemsNo;
    this.getContentItems();
  }

  beforeDestroy() {
    clearTimeout(this.setVideoCompDelay);

    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }
  }

  /************************************/
  // Map Cockpit props

  get loadFirstVideo() {
    return this.getSettingsAttribute("load_first_video");
  }

  get sliderText() {
    if (this.altSliderTexts != null) {
      return this.altSliderTexts;
    }

    return this.getContentAttribute("slider_text");
  }

  get filteredSliderItems() {
    let sliderItems = [];
    // Load slider items from an alternative video list (alternativeVideoList prop)
    if (
      Array.isArray(this.alternativeVideoList) &&
      this.alternativeVideoList.length > 0
    ) {
      sliderItems = [...this.alternativeVideoList];
      // Load slider items from cockpit collection
    } else {
      sliderItems = this.getContentAttribute("slider_items");
    }

    if (sliderItems && sliderItems.length > 0) {
      return sliderItems.filter((layoutComponent: LayoutComponent) => {
        return layoutComponent && layoutComponent.settings.visibility;
      });
    }

    return [];
  }

  get sliderItemsNo() {
    return this.filteredSliderItems.length;
  }

  get sliderItems() {
    return this.filteredSliderItems.map(
      (layoutComponent: LayoutComponent) => new LayoutComponent(layoutComponent)
    );
  }

  get compID() {
    const id = this.getSettingsAttribute("content")._id || null;
    return id ? `yt-slider-${id}` : null;
  }

  get urlParamsData() {
    return this.urlParams;
  }

  /************************************/
  // Utils

  sliderItemContentLoaded() {
    this.sliderItemsLoaded++;
    if (this.sliderItemsLoaded >= this.sliderItemsCount) {
      this.loaded = true;
    }
  }

  setCarouselOptions() {
    const options = this.getSettingsAttribute("slider_options");
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }

    this.carouselOptions.spacePadding =
      window.innerWidth < this.GRID_MD_BREAKPOINT
        ? 1
        : this.initialSpacePadding;
  }

  async getContentItems() {
    if (this.filteredSliderItems && this.filteredSliderItems.length > 0) {
      const itemsResponse = await ContentRepository.getCollectionItemByTypeAndId(
        this.filteredSliderItems[0].settings.content.link,
        this.filteredSliderItems.map(
          (entry: any) => entry.settings.content._id
        ),
        0
      );

      if (itemsResponse.type == ResultType.SUCCESS) {
        // Add placeholder image layout component to items to render
        this.sliderItems.forEach((item: any) => {
          const responseItem = itemsResponse.value.filter(
            (respItem: any) => respItem._id === item.settings.content._id
          );
          const placeholderImage = Object.prototype.hasOwnProperty.call(
            responseItem[0],
            "placeholder_image"
          )
            ? responseItem[0].placeholder_image
            : null;

          if (placeholderImage && placeholderImage.length) {
            Object.assign(item, {
              placeholderImage: new LayoutComponent(placeholderImage[0])
            });
          }
          this.itemsToRender.push(item);
        });
      }
    }

    return [];
  }

  checkSelectedSlide(selectedComp: LayoutComponent) {
    if (this.videoToRender && selectedComp) {
      const videoToRenderId = this.videoToRender
        ? this.videoToRender.settings.content._id
        : null;
      const selectedVideoId = selectedComp
        ? selectedComp.settings.content._id
        : null;
      return videoToRenderId === selectedVideoId ? true : false;
    }

    return false;
  }

  getUrlParams(activeSlide: number) {
    return `?activeslide=${activeSlide}#${this.compID}`;
  }

  onSlideClick(comp: LayoutComponent, idx: number) {
    clearTimeout(this.setVideoCompDelay);

    if (this.checkSelectedSlide(comp)) {
      return;
    }

    if (this.videoToRender) {
      this.videoToRender = null;
    }
    this.urlParams = this.getUrlParams(idx);
    this.setVideoCompDelay = setTimeout(() => {
      this.videoToRender = comp;
    }, 1);
  }

  @Watch("itemsToRender")
  showfirstVideo() {
    if (this.loadFirstVideo && this.itemsToRender.length) {
      this.urlParams = this.getUrlParams(0);
      this.videoToRender = this.itemsToRender[0];
    }
  }

  @Watch("loaded")
  activeSlideHandler() {
    // If there is a "activeslide" query param in the url, navigate to the according slide and render the video
    if (Object.keys(router.app.$route.query).indexOf("activeslide") !== -1) {
      const activeSlide = parseInt(
        router.app.$route.query.activeslide as any,
        10
      );

      if (activeSlide > 0 || !this.loadFirstVideo) {
        if (this.videoToRender) {
          this.videoToRender = null;
        }
        this.urlParams = this.getUrlParams(activeSlide);

        // Navigate to slide and set the video to render
        this.setVideoCompDelay = setTimeout(() => {
          (this.$refs.carousel as any).goToPage(activeSlide);
          this.videoToRender = this.itemsToRender[activeSlide];
        }, 1);
      }
    }

    return null;
  }

  @Watch("shouldUpdate")
  computeWidthHandler() {
    // Force the carousel to recalculate the width in order to render correctly when the cancer stage / accordion is expanded
    if (this.shouldUpdate) {
      (this.$refs.carousel as any).computeCarouselWidth();
    }
  }

  /************************************/
}
