
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class HighlightBox extends BaseComponent {
  getComponentType() {
    return "highlight_boxes";
  }

  async mounted() {
    super.mounted();
  }

  get headlines() {
    if (this.headline) {
      let headlines: LayoutComponent[] = [];
      this.headline.forEach((hl: any) => {
        headlines.push(new LayoutComponent(hl));
      });
      return headlines;
    }
    return [];
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get texts() {
    if (this.text) {
      let texts: LayoutComponent[] = [];
      this.text.forEach((t: any) => {
        texts.push(new LayoutComponent(t));
      });
      return texts;
    }
    return [];
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get background() {
    return this.getSettingsAttribute("background");
  }
}
