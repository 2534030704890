import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class NewsletterRepository extends AbstractRepository {
  async postSubscription(
    data: any,
    id: string | null,
    hub: string,
    path: string,
    headers?: any
  ) {
    const dataToSend = { details: data, context: id, hub, path };
    let response = await Client.post(
      Constants.newsletterUrl + "/subscription",
      dataToSend,
      headers || this.getAdditionalHeader()
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async subscribe(uuid: any, headers?: any) {
    let response = await Client.getFile(
      `${Constants.newsletterUrl}/${uuid}/subscribe`,
      headers || this.getAdditionalHeader()
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async unsubscribe(uuid: any, headers?: any) {
    let response = await Client.delete(
      `${Constants.newsletterUrl}/${uuid}/unsubscribe`,
      headers || this.getAdditionalHeader()
    );
    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new NewsletterRepository();
