
import { Component, Watch } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";
import $ from "jquery";

@Component
export default class Dropdown extends BaseComponent {
  $refs!: {
    dropdown: HTMLElement;
  };

  constructor() {
    super();
  }

  getComponentType() {
    return "dropdowns";
  }

  async mounted() {
    super.mounted();

    if (this.isArticleNavigation()) {
      this.attachToSuperHeader();
    }
  }

  contentDidLoad() {
    super.contentDidLoad();
  }

  get contentItems() {
    let contentItems = this.getContentAttribute("content");

    if (contentItems && contentItems.length > 0) {
      return contentItems.map((comp: any) => new LayoutComponent(comp));
    }

    return [];
  }

  get displayText() {
    return this.getContentAttribute("display_text");
  }

  isArticle() {
    return $("#layout-content>.row").data().component === "Article";
  }

  isArticleNavigation() {
    if (this.isArticle()) {
      return this.layoutComponent.class.includes(
        "article-navigation__dropdown"
      );
    }

    return false;
  }

  attachToSuperHeader() {
    const superHeader = document.querySelector(".super-header");
    const dropdown = this.$refs.dropdown;

    if (superHeader) {
      superHeader.appendChild(dropdown);
    }

    // Removes residual Dropdowns
    $(".dropdown")
      .not(":last")
      .remove();
  }
}
