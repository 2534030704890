
import { Component, Prop } from "vue-property-decorator";
import { LayoutComponent, User, UserProfile } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class OfflabelWidget extends BaseWidget {
  @Prop() userNoTracking!: any;
  @Prop() accountProfile!: any;
  @Prop() showUpgrade!: any;
  static email: any;
  static itemOfInterest: any;
  static offlabelStubId: any;

  getComponentType() {
    return "offlabel_widgets";
  }

  async mounted() {
    await super.mounted();
  }

  get headline() {
    let headline = this.getContentAttribute("headline");
    if (headline && headline.length > 0) {
      return new LayoutComponent(headline[0]);
    }
    return null;
  }

  get text() {
    let text = this.getContentAttribute("text");
    if (text && text.length > 0) {
      return new LayoutComponent(text[0]);
    }
    return null;
  }

  get textMMCPlus() {
    let textMMCPlus = this.getContentAttribute("text_mmc_plus");
    if (textMMCPlus && textMMCPlus.length > 0) {
      return new LayoutComponent(textMMCPlus[0]);
    }
    return null;
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get offlabelStubId() {
    let stub = this.getContentAttribute("stub_id");
    return stub._id;
  }

  get itemOfInterest() {
    return this.getContentAttribute("item_of_interest");
  }

  get email() {
    return this.getContentAttribute("email");
  }

  get showContactDetails() {
    return this.getContentAttribute("show_contact_details");
  }

  get userIsMMCPlus() {
    return User.hasUserProfile(UserProfile.MMC_PLUS);
  }

  openOfflabelPopup() {
    this.showModal(
      "Bitte wählen Sie die Inhalte aus, die Sie interessieren",
      new LayoutComponent({
        component: "OfflabelModal",
        settings: {
          offlabelStubId: this.offlabelStubId,
          itemOfInterest: this.itemOfInterest,
          email: this.email,
          showContactDetails: this.showContactDetails
        }
      }),
      "modal-lg"
    );
  }

  openOfflabelPopupUpgrade() {
    this.showModal(
      "Bitte bestätigen Sie unsere Benutzerbedingungen",
      new LayoutComponent({
        component: "FirstVisitOfflabel",
        settings: {
          offlabelStubId: this.offlabelStubId,
          itemOfInterest: this.itemOfInterest,
          email: this.email
        }
      }),
      "modal-lg"
    );
  }
}
