import Constants from "../../models/Constants";
import { ExpertForumThread, ExpertForumUser } from "../../models/ExpertForum";
import Client from "../util/Client";
import { Result } from "../util/Result";

export interface ExpertForumGetThreadResponse {
  success: boolean;
  thread: ExpertForumThread;
  expert: ExpertForumUser;
  patient: ExpertForumUser;
}

export interface ExpertForumGetAllThreadsResponse {
  success: boolean;
  threads: ExpertForumThread[];
  patient: ExpertForumUser;
}

class ExpertForumRepository {
  async register(
    email: string,
    username: string,
    password: string,
    website: string
  ) {
    const data = {
      email,
      username,
      password,
      website,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/register",
      data
    );

    return response;
  }

  async login(username: string, password: string, website: string) {
    const data = {
      username,
      password,
      website,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/login",
      data
    );

    return response;
  }

  async forgotPassword(email: string, username: string, website: string) {
    const data = {
      email,
      username,
      website,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/forgot-password",
      data
    );

    return response;
  }

  async resetPassword(password: string, website: string, token: string) {
    const data = {
      password,
      website,
      token,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/reset-password",
      data
    );

    return response;
  }

  async changePassword(
    currentPassword: string,
    newPassword: string,
    website: string,
    token: string
  ) {
    const data = {
      currentPassword,
      newPassword,
      website,
      token,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/change-password",
      data
    );

    return response;
  }

  async deleteAccount(token: string) {
    const data = {
      token,
      originToken: Constants.expertforumToken
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/delete-account",
      data
    );

    return response;
  }

  async postThread(
    token: string,
    website: string,
    thread: {
      title: string;
      body: string;
    }
  ) {
    const data = {
      originToken: Constants.expertforumToken,
      token,
      website,
      thread
    };

    let response = await Client.postWithJsonResult(
      Constants.expertforumApiUrl + "/threads",
      data
    );

    return response;
  }

  async getThreads(token: string) {
    const data = {
      originToken: Constants.expertforumToken,
      token
    };

    let response = await Client.getJson(
      Constants.expertforumApiUrl + "/threads",
      data
    );

    return response;
  }

  async getThread(token: string, thread: string): Promise<Result<any>> {
    const data = {
      originToken: Constants.expertforumToken,
      token,
      thread
    };

    let response = await Client.getJson(
      Constants.expertforumApiUrl + "/threads/details",
      data
    );

    return response;
  }
}

export default new ExpertForumRepository();
