
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class HorizontalLayout extends BaseComponent {
  getComponentType() {
    return "horizontal_layouts";
  }

  async mounted() {
    super.mounted();
  }

  get columns() {
    return this.getContentAttribute("content");
  }
}
