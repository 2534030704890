
import { Component } from "vue-property-decorator";
import { Helper, LayoutComponent, matomo, router } from "common";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class TeaserWidget extends BaseWidget {
  private getToken: boolean = false;

  getComponentType() {
    return "teaser_widgets";
  }

  async mounted() {
    super.mounted();
  }

  get id() {
    return this.getContentAttribute("id");
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get subheadline() {
    return this.getContentAttribute("subheadline");
  }

  get url() {
    return this.getContentAttribute("url");
  }

  get mandatory_text() {
    return this.getContentAttribute("mandatory_text");
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get is_external() {
    try {
      let url = new URL(this.url);
      if (url.protocol) {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  showMTModal() {
    if (this.mandatory_text && this.mandatory_text.length > 0) {
      this.showModal(
        "Pflichtangaben",
        new LayoutComponent(this.mandatory_text[0])
      );
    }
  }

  get show_mandatory_text_link() {
    return this.mandatory_text && this.mandatory_text.length > 0;
  }

  /**
   * Four cases for teaser widget links:
   * 1. is internal (e.g. /brilique)
   * 2. is external and needs exit ramp (e.g. example.com)
   * 3. is external needs no exit ramp and no move token (e.g. astrazeneca.de)
   * 4. is external needs no exit ramp but move token (e.g. az-produkte.de)
   */
  async gotoLink() {
    matomo.click(
      this.id,
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "Click_Banner"
    );
    if (this.is_external) {
      // CASE 2 - if link is no astra zeneca link, show exit ramp
      let url = Helper.showExitRamp(this.url);
      if (url) {
        this.showModal(
          "Vielen Dank für Ihren Besuch",
          new LayoutComponent({
            component: "ExitRamp",
            settings: {
              link: this.url
            }
          }),
          "modal-md"
        );
      }
      // CASES 3 and 4 - otherwise just take me there (with or without movetoken)
      else {
        await Helper.generateMoveTokenAndOpenNewWindow(this.url);
      }
    }
    // CASE 1
    else {
      router.push(this.url);
    }
  }
}
