
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class HeroSlider extends BaseComponent {
  getComponentType() {
    return "hero_sliders";
  }

  async mounted() {
    super.mounted();
  }

  get heroes() {
    return this.getContentAttribute("heroes").filter((c: LayoutComponent) => {
      return c.settings.visibility;
    });
  }

  get cssClasses() {
    return this.layoutComponent.class;
  }
  get customStyles(): string {
    return this.layoutComponent.style;
  }
}
