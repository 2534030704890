import {
  FilterRepository,
  LayoutComponent,
  ResultType,
  ContentQueue,
  ContentRepository
} from "common";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class BaseComponentDynamic extends Vue {
  @Prop() layoutComponent!: LayoutComponent;

  loading!: boolean;
  contentLoaded!: boolean;
  collection!: string;
  content!: any;
  contentElementsCount!: number;
  contentElementsLoadedCount!: number;
  contentElementsFinishedLoading!: boolean;
  limit!: number;
  fill!: boolean;

  private maximumReLoadingAttempts: number = 2;
  private contentReLoadingAttempts: number = 0;

  private randomId: string = "";


  constructor() {
    super();
    this.loading = true;
    this.contentLoaded = false;
    this.collection = "";
    this.content = [];
    this.limit = 10;
    this.fill = true;
    this.contentElementsCount = 0;
    this.contentElementsLoadedCount = 0;
    this.contentElementsFinishedLoading = false;
    this.randomId = this.generateRandomId();
  }

  /**
   * Call in mixed in vue components on mounted()
   */
  async mountedHook() {
    if (
      this.layoutComponent.settings.content &&
      this.layoutComponent.settings.content.length > 0
    ) {
      const staticContent = this.layoutComponent.settings.content;
      const componentId = staticContent._id;
      ContentQueue.addElement(componentId ? componentId : this.randomId);

      let tmpContent: any = [];
      let self = this;
      await this.asyncForEach(staticContent, async (slide: any) => {
        let typeContent = await this.loadStaticConent(
          slide.link,
          self.contentIds
        );
        if (typeContent) {
          tmpContent = tmpContent.concat(typeContent);
        }
      });
      this.content = tmpContent;
      this.contentLoaded = true;
      ContentQueue.removeElement(componentId ? componentId : this.randomId);
    } else {
      this.content = await this.loadDynamicContent();
    }

    this.contentElementsCount = this.content.length;
    this.loading = false;
    this.contentLoaded = true;
  }

  async loadStaticConent(
    type: string,
    ids: string[],
    populate: number = 0,
    fields: any = null
  ): Promise<any> {
    if (this.getComponentType() != "") {
      let response = await ContentRepository.getCollectionItemByTypeAndId(
        type,
        ids,
        populate,
        fields
      );

      if (response.type == ResultType.SUCCESS) {
        return response.value;
      } else {
        if (this.contentReLoadingAttempts < this.maximumReLoadingAttempts) {
          this.contentReLoadingAttempts++;
          return this.loadStaticConent(type, ids, populate, fields);
        }
      }
    }
  }

  get contentIds() {
    if (this.layoutComponent.settings.content) {
      if (Array.isArray(this.layoutComponent.settings.content)) {
        let mapped = this.layoutComponent.settings.content.map((el: any) => {
          return el._id;
        });

        return mapped;
      }

      return [this.layoutComponent.settings.content._id];
    }
    return [];
  }


  async loadDynamicContent() {
    let response = await FilterRepository.getCollectionFilteredBySegmentation(
      this.collection,
      this.limit,
      this.fill
    );

    if (response.type == ResultType.SUCCESS) {
      return response.value;
    }

    return [];
  }

  getSettingsAttribute(attribute: string): any {
    if (this.layoutComponent != null) {
      if (Object.keys(this.layoutComponent.settings).includes(attribute)) {
        return this.layoutComponent.settings[attribute];
      }
    }
    return null;
  }

  contentElementLoaded() {
    this.contentElementsLoadedCount++;
    if (this.contentElementsLoadedCount >= this.contentElementsCount) {
      this.contentElementsFinishedLoading = true;
      this.$emit("contentElementsFinishedLoading", this.content);
    }
  }

  /**
   * Override in specific SCF
   */
  getComponentType(): string | string[] {
    return "";
  }

  @Watch("contentLoaded")
  contentDidLoad() {
    this.$emit("contentDidLoad", this.content);
  }

  generateRandomId(): string {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  async asyncForEach(array: any, callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
}
