import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class TranparencyRepository extends AbstractRepository {
  async getSpendings() {
    let response = await Client.getJson(
      Constants.transparencyUrl + "/spendings",
      null,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async putFeedback(data: any) {
    let response = await Client.put(
      Constants.transparencyUrl + "/feedback",
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new TranparencyRepository();
