var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eventlist-item",attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(_vm.displayOptions.showCategory)?_c('h3',[_vm._v(_vm._s(_vm.event.category))]):_vm._e(),_c('div',{staticClass:"eventlist-item-row d-flex align-items-top justify-content-start",class:{
      selectable:
        _vm.displayOptions.selectable && !_vm.event.registered && !_vm.event.expired
    },on:{"click":function($event){_vm.displayOptions.selectable ? _vm.selectEvent() : null}}},[_c('div',{staticClass:"eventlist-item-left"},[_c('div',{staticClass:"eventlist-item-checkmark",class:{
          active: _vm.event.registered || _vm.selected
        },attrs:{"data-event-id":_vm.event.id}},[(_vm.displayOptions.showEventId)?_c('span',{staticClass:"eventlist-item-checkmark-eventid"},[_vm._v(_vm._s(_vm.event.id))]):_vm._e()]),(_vm.displayOptions.showRegisterButton)?_c('div',{staticClass:"eventlist-item-register-button"},[(_vm.event.registered)?_c('span',{staticClass:"btn btn-primary-outline btn-xs"},[_c('b',[_vm._v("Angemeldet")])]):_vm._e(),(!_vm.event.registered && !_vm.event.expired)?_c('router-link',{staticClass:"btn btn-primary btn-xs",attrs:{"to":"/anmeldung"}},[_c('b',[_vm._v("Anmelden")])]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"eventlist-item-right"},[(_vm.displayOptions.showDateTimeString)?_c('div',{staticClass:"eventlist-item-datetime"},[_vm._v(" "+_vm._s(_vm.event.dateTimeString)+" ")]):_vm._e(),(_vm.displayOptions.showTitle)?_c('div',{staticClass:"eventlist-item-title",domProps:{"innerHTML":_vm._s(_vm.event.eventTitle)}}):_vm._e(),(_vm.displayOptions.showSpeaker)?_c('div',{staticClass:"eventlist-item-speakers"},_vm._l((_vm.event.sessions),function(session){return _c('p',{key:session.speakerName,staticClass:"small"},[_c('b',[_vm._v(_vm._s(session.speakerName))]),(session.speakerInstitution)?_c('span',[_vm._v(", "+_vm._s(session.speakerInstitution))]):_vm._e()])}),0):_vm._e(),(_vm.displayOptions.showDescription)?_c('div',{staticClass:"eventlist-item-content",domProps:{"innerHTML":_vm._s(_vm.event.eventDescription)}}):_vm._e()])]),(_vm.speakerSelectable())?_c('div',{staticClass:"eventlist-item-row d-flex align-items-top justify-content-start speaker-selection",class:{
      selectable:
        _vm.displayOptions.selectable && !_vm.event.registered && !_vm.event.expired
    },on:{"click":function($event){_vm.selected ? _vm.selectEventSpeaker() : null}}},[_c('div',{staticClass:"eventlist-item-left"},[_c('div',{staticClass:"eventlist-item-checkmark",class:{
          active: _vm.selected && _vm.speakerSelected()
        },attrs:{"data-event-id":_vm.event.id}})]),_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eventlist-item-right"},[_c('div',{staticClass:"eventlist-item-title"},[_vm._v(" Ich möchte auch als ReferentIn teilnehmen. ")]),_c('div',{staticClass:"eventlist-item-content"},[_vm._v(" Bitte kontaktieren Sie mich bzgl. meiner Beitragseinreichung per E-Mail. ")])])
}]

export { render, staticRenderFns }