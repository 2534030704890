import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class FeedRepository extends AbstractRepository {
  async getFeed(id: string) {
    const data = {
      simple: 1,
      filter: {
        id
      },
      populate: 0
    };

    const response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/get/feeds`,
      data,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      if (r && r.length > 0) {
        return r[0];
      }
      return {};
    });

    return result;
  }

  async getSubEntries(id: string) {
    const data = {
      simple: 1,
      filter: {
        _id: {
          $in: [id]
        }
      },
      populate: 0
    };

    const response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/get/feed_entries`,
      data,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      if (r && r.length > 0) {
        return r[0].sub_entries;
      }
      return [];
    });

    return result;
  }

  async editFeedEntry(id: string, likes: Array<string>) {
    const dataToSave = {
      data: {
        _id: id,
        likes: likes
      }
    };

    const response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/save/feed_entries`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async saveFeedEntry(id: string, text: string, author: string) {
    const dataToSave = {
      data: {
        id,
        text,
        author,
        likes: [""],
        sub_entries: []
      }
    };

    const response = await Client.postWithJsonResult(
      `${Constants.collectionsUrl}/save/feed_entries`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async linkToFeed(feed: any, feedEntry: any) {
    const dataToSave = {
      data: {
        _id: feed._id,
        feed_entries: [
          ...feed.feed_entries,
          {
            children: [],
            component: "lc_feed_entries",
            settings: {
              class: "",
              content: {
                _id: feedEntry._id,
                display: feedEntry.id,
                link: "feed_entries"
              },
              id: "",
              roles: null,
              style: "",
              sub_entries: null,
              visibility: true,
              width: "12"
            }
          }
        ]
      }
    };

    const response = await Client.post(
      `${Constants.collectionsUrl}/save/feeds`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async linkToSubEntries(
    feedEntryId: string,
    subEntries: Array<any>,
    subFeedEntryId: string
  ) {
    const dataToSave = {
      data: {
        _id: feedEntryId,
        sub_entries: [
          ...subEntries,
          {
            component: "lc_feed_entries",
            settings: {
              content: {
                _id: subFeedEntryId,
                link: "feed_entries"
              }
            }
          }
        ]
      }
    };

    const response = await Client.post(
      `${Constants.collectionsUrl}/save/feed_entries`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new FeedRepository();
