
import { Component } from "vue-property-decorator";
import {
  BookmarkList,
  BookmarkRepository,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  ResultType,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../../base/BaseComponent.vue";

const collectionEventNames: any = {
  Video: MatomoEventNames.BOOKMARK_VIDEO,
  Artikel: MatomoEventNames.BOOKMARK_ARTICLE,
  "3D Model": MatomoEventNames.BOOKMARK_3D_MODEL,
  Informationsportal: MatomoEventNames.BOOKMARK_INFORMATION_PORTAL,
  Informationsmaterialien: MatomoEventNames.BOOKMARK_INFORMATION_MATERIAL
};

@Component
export default class BookmarkerAll extends BaseComponent {
  loading!: boolean;

  getComponentType() {
    return "bookmarker_all";
  }

  constructor() {
    super();
    this.loading = false;
  }

  async mounted() {
    await super.mounted();
  }

  get label() {
    return this.getSettingsAttribute("label");
  }

  get btnClass() {
    return this.getSettingsAttribute("btn_class");
  }

  async bookmarkItAll() {
    if (!this.loading && this.loggedIn) {
      let bookmarkableList = BookmarkList.getBookmarkableList();

      if (bookmarkableList) {
        this.loading = true;
        let result = await BookmarkRepository.createList(bookmarkableList);
        this.loading = false;

        bookmarkableList.forEach(bookmark => {
          // Matomo tracking
          matomo.genericEvent(
            bookmark.foreignId,
            matomo.MATOMO_EVENT_CATEGORY,
            collectionEventNames[bookmark.collectionTitle]
          );
        });

        if (result.type == ResultType.SUCCESS) {
          BookmarkList.addList(result.value);
        }
      }
    }
  }

  get popover() {
    return LayoutComponent.createEmpty("popover", {
      width: 12,
      visibility: true,
      content: {
        _id: "617fe9fac60c79755c408057",
        display: "",
        type: "popovers"
      },
      toggle_btn_label: this.label,
      toggle_btn_classes: "btn-primary " + this.btnClass,
      placement: "bottom",
      trigger: "click",
      overlay_classes: ""
    });
  }

  get loggedIn() {
    return User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN);
  }
}
