
import { Component } from "vue-property-decorator";
import { LayoutComponent, Store, TrainingQuizRatingRepository } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class TrainingQuizRatingModal extends BaseComponent {
  private userIdx: string;
  private trainingQuizIdx: string;
  private trainingRating!: number;
  private informationRating!: number;
  private userFeedback!: string;
  private loading!: boolean;

  constructor() {
    super();
    this.userIdx = this.propUserIdx;
    this.trainingQuizIdx = this.propTrainingQuizIdx;
    this.trainingRating = 0;
    this.informationRating = 0;
    this.userFeedback = "";
    this.loading = false;
  }

  get propUserIdx() {
    return this.layoutComponent ? this.layoutComponent.settings.userId : null;
  }

  get propTrainingQuizIdx() {
    return this.layoutComponent
      ? this.layoutComponent.settings.trainingQuizId
      : null;
  }

  get isDisabled() {
    return !(this.trainingRating && this.informationRating);
  }

  onRatingValueChange() {
    if (this.trainingRating && this.informationRating && !this.loading) {
      let userData = {
        userId: this.userIdx,
        trainingQuizId: this.trainingQuizIdx,
        trainingRating: this.trainingRating,
        informationRating: this.informationRating,
        userFeedback: this.userFeedback
      };

      this.loading = true;
      TrainingQuizRatingRepository.postRating(userData).then(() => {
        this.loading = false;
      });
    }
  }

  submitRating() {
    if (this.trainingRating && this.informationRating && !this.loading) {
      let userData = {
        userId: this.userIdx,
        trainingQuizId: this.trainingQuizIdx,
        trainingRating: this.trainingRating,
        informationRating: this.informationRating,
        userFeedback: this.userFeedback
      };

      this.loading = true;
      TrainingQuizRatingRepository.postRating(userData).then(() => {
        this.loading = false;
      });

      this.hideModal();

      Store.modal.className = "modal-md modal__quiz--finished";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("training-quiz-rating-confirmation-modal")
      );
      Store.modal.showModal();
      setTimeout(() => {
        this.$router.push("/dmp-and-more#trainings");
      }, 2000);
    }
  }
}
