
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  APP_DATA_LOADED,
  APP_INITIALIZED,
  ROUTER_AFTER_EACH,
  EventBus,
  Bootstrap,
  ExecutionQueue,
  Store,
  Helper,
  router,
  Tealium,
  User,
  config,
  changeContextByRoute,
  DataLoaderFactory,
  DataLoaderTypes,
  UserLoginStatus
} from "common";

export interface AppInterface {
  defaultTitle: string;
  layout: string;
  checkRouteAndLoadData(): void;
}

// let count = 0;

@Component
export default class App extends Vue {
  public data!: any;
  public dataLoaded!: boolean;
  public initialized!: boolean;

  user = User;
  userLoggedIn: boolean;
  $route: any;

  constructor() {
    super();
    this.data = {};
    this.dataLoaded = false;
    this.initialized = false;
    this.userLoggedIn =
      this.user.getUserLoginStatus() === UserLoginStatus.LOGGED_IN;
  }

  get modal() {
    return Store.modal;
  }

  get theme(): string | undefined {
    // @ts-ignore
    return process.env.VUE_APP_THEME;
  }

  /**
   * will be overwritten by AppHCP, AppBrand, AppPatient
   */
  get defaultTitle(): string {
    return "";
  }

  /**
   * will be overwritten by AppHCP, AppBrand, AppPatient
   */
  get layout(): string {
    return "";
  }

  /**
   * beforeCreated lifecycle hook for App
   */
  async beforeCreate() {
    EventBus.$on(ROUTER_AFTER_EACH, async () => {
      // console.log("ROUTE-" + ++count);
      if (this.initialized) {
        await this.checkRouteAndLoadData();
        Tealium.getInstance().update();
        // console.log("ROUTE-" + ++count);
      } else {
        changeContextByRoute(config);
        await Bootstrap.init();
        await this.checkRouteAndLoadData();
        this.initialized = true;
        // console.log("ROUTE", window.utag);
      }

      this.userLoggedIn =
        this.user.getUserLoginStatus() === UserLoginStatus.LOGGED_IN;
    });
  }

  /**
   * will be overidden by child app entrypoints
   */
  async checkRouteAndLoadData() {}

  /*
   * Lifecycle hooks
   * Should not be used by app entrypoints due to possible asynchronous
   * requests within the lifecycle methods
   */
  beforeCreateHook() {}
  createdHook() {}
  mountedHook() {}

  /**
   * Hooks
   */
  @Watch("initialized")
  initializedHook() {
    EventBus.$emit(APP_INITIALIZED);
  }

  @Watch("dataLoaded")
  dataLoadedHook() {
    if (this.dataLoaded) {
      ExecutionQueue.addElement(Helper.crawlDomAndAddEventlistenerToAllLinks);
      EventBus.$emit(APP_DATA_LOADED);
    }
  }

  resetData() {
    this.data = {};
    this.dataLoaded = false;
  }

  async loadDataWithContentLoader() {
    this.resetData();
    const metaId: any =
      this.$route && this.$route.meta ? this.$route.meta.id : null;
    const metaType: any =
      this.$route && this.$route.meta ? this.$route.meta.type : null;
    if (metaId == null) {
      return false;
    }

    let contentLoader = DataLoaderFactory.getDataLoader(
      DataLoaderTypes.ContentLoader,
      metaId,
      Store.appBrand,
      metaType
    );

    let data = await contentLoader!.loadData();
    return await this.setData(data);
  }

  async setData(data: any) {
    if (data) {
      this.data = data;
      this.dataLoaded = true;
      this.setDocumentTitle();
      return true;
    } else {
      await router.push("/404");
      return false;
    }
  }

  setDocumentTitle() {
    if (this.data) {
      /* Set meta title */
      if (this.data.seo_title) {
        document.title = this.data.seo_title;
      } else {
        document.title = this.defaultTitle;
      }

      /* Set meta description */
      if (this.data.seo_description) {
        let metaDescription = document.querySelector("meta[name=description]");
        if (metaDescription) {
          (metaDescription as HTMLMetaElement).content = this.data.seo_description;
        } else {
          let meta = document.createElement("meta");
          meta.name = "description";
          meta.content = this.data.seo_description;

          document.head.insertBefore(
            meta,
            document.head.querySelector("title")!
          );
        }
      }

      /* Set meta keywords */
      if (this.data.seo_keywords) {
        let metaKeywords = document.querySelector("meta[name=keywords]");
        if (metaKeywords) {
          (metaKeywords as HTMLMetaElement).content = this.data.seo_keywords;
        } else {
          let meta = document.createElement("meta");
          meta.name = "keywords";
          meta.content = this.data.seo_keywords;

          document.head.insertBefore(
            meta,
            document.head.querySelector("title")!
          );
        }
      }

      const ogTags = {
        og_title: "og:title",
        og_description: "og:description",
        og_image: "og:image",
        og_url: "og:url",
        og_type: "og:type"
      };

      for (const key in ogTags) {
        if (!this.data[key]) {
          continue;
        }

        const property = (ogTags as any)[key];

        let ogTag = document.querySelector(`meta[property='${property}']`);

        if (ogTag) {
          (ogTag as HTMLMetaElement).content = this.data[key];
          continue;
        }

        let meta = document.createElement("meta");
        meta.name = property;
        meta.content = this.data[key];

        document.head.insertBefore(meta, document.head.querySelector("title")!);
      }

      if(this.data.canonical_tag) {

        let meta = document.createElement("link");
        meta.rel = "canonical";
        meta.href = this.data.canonical_tag;

        document.head.insertBefore(meta, null);
      }
    }
  }
}
