import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class CancerRepository extends AbstractRepository {
  async getYoutubeVideos(cancerStageId: string, cancerType: string) {
    let data = {
      fields: {
        youtube_videos: 1
      },
      filter: {
        "cancer_stage._id": { $eq: cancerStageId },
        "cancer_type.display": { $eq: cancerType }
      },
      simple: 1,
      populate: 0
    };

    let url = `${Constants.collectionsUrl}/get/cancer_stage_type_video_mappings`;

    let response = await Client.postWithJsonResult(
      url,
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new CancerRepository();
