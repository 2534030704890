
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { CancerRepository, LayoutComponent, ResultType } from "common";

@Component
export default class DynamicCancerStageTypeVideoMapper extends Vue {
  @Prop() cancerStageId!: string;
  @Prop() cancerType!: string;
  @Prop() shouldUpdate!: boolean;

  public result!: any;

  public key!: any;

  constructor() {
    super();
    this.result = null;
    this.key = "dcstvp_" + Date.now();
  }

  created() {}

  mounted() {
    this.loadYoutubeVideos();
  }

  @Watch("cancerStageId")
  @Watch("cancerType")
  update() {
    this.loadYoutubeVideos();
  }

  async loadYoutubeVideos() {
    let result = await CancerRepository.getYoutubeVideos(
      this.cancerStageId,
      this.cancerType
    );

    if (result.type == ResultType.SUCCESS) {
      this.result = result.value[0];
      this.key = "dcstvp_" + Date.now();
    }
  }

  get youtubeVideos() {
    if (this.result) {
      let mappedVideos = this.result.youtube_videos.map((video: any) => {
        return LayoutComponent.createFromCollectionItem(
          "youtube_video",
          video,
          {
            show_controls: true,
            allow_fullscreen: true,
            loop: false,
            cookie_consent: false,
            show_headline: false
          }
        );
      });

      return mappedVideos;
    }

    return null;
  }

  get sliderTexts() {
    return [
      {
        component: "headline",
        settings: {
          id: "",
          class: "h4 font-weight-normal",
          style: "",
          content: {
            _id: "61781fcd807fe7750d226d11",
            link: "headlines",
            display: "Weitere PatientInnen Videos"
          },
          type: "h3",
          width: "12",
          visibility: true,
          roles: null
        }
      }
    ];
  }

  get youtubeVideoSlider() {
    return LayoutComponent.createEmpty("youtube_video_slider", {
      load_first_video: true,
      content: {
        _id: "dcstvp_" + this.cancerStageId
      }
    });
  }
}
