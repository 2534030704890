
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import validator from "validator";
import { Constants, matomo, Repository, ResultType, Store } from "common";
import { Step } from "./OrderList.vue";

@Component
export default class OrderListForm extends Vue {
  @Prop() stepsLayout!: boolean;
  @Prop() step!: Step;
  @Prop() product!: string;
  @Prop() checkboxText!: string;
  @Prop() cleanOrder!: () => Array<any>;
  @Prop() ordered!: Array<any>;

  public Step = Step;

  public firstnameVal: string = "";
  public lastnameVal: string = "";
  public phoneVal: string = "";
  public mailVal: string = "";
  public streetVal: string = "";
  public streetnumberVal: string = "";
  public postalcodeVal: string = "";
  public cityVal: string = "";
  public consent01: boolean = false;

  public attemptSubmit: boolean = false;
  public submitSuccessfull: Boolean = false;
  public sendingRequest: boolean = false;

  get validatefirstname() {
    return !validator.isEmpty(this.firstnameVal);
  }

  get validatelastname() {
    return !validator.isEmpty(this.lastnameVal);
  }

  get validatephone() {
    return !validator.isEmpty(this.phoneVal);
  }

  get validatemail() {
    return !validator.isEmpty(this.mailVal) && validator.isEmail(this.mailVal);
  }

  get validatestreet() {
    return !validator.isEmpty(this.streetVal);
  }

  get validatestreetnumber() {
    return !validator.isEmpty(this.streetnumberVal);
  }

  get validatepostalcode() {
    return (
      !validator.isEmpty(this.postalcodeVal) &&
      validator.isPostalCode(this.postalcodeVal, "DE")
    );
  }

  get validatecity() {
    return !validator.isEmpty(this.cityVal);
  }

  @Watch("step")
  reset() {
    if (this.step === Step.ORDER) {
      this.firstnameVal = "";
      this.lastnameVal = "";
      this.phoneVal = "";
      this.mailVal = "";
      this.streetVal = "";
      this.streetnumberVal = "";
      this.postalcodeVal = "";
      this.cityVal = "";
      this.consent01 = false;
      this.attemptSubmit = false;
      this.submitSuccessfull = false;
      this.sendingRequest = false;
    }
  }

  getData() {
    return {
      details: {
        firstname: this.firstnameVal,
        lastname: this.lastnameVal,
        phone: this.phoneVal,
        mail: this.mailVal,
        street: this.streetVal,
        streetnumber: this.streetnumberVal,
        postalcode: this.postalcodeVal,
        city: this.cityVal,
        consent: this.consent01,
        ordered: this.cleanOrder,
        product: this.product
      }
    };
  }

  getRequestHeaders() {
    if (Store.isCovid()) {
      return [{ "Order-Token": Constants.orderListToken }];
    }

    return null;
  }

  async validate(e: any) {
    this.attemptSubmit = true;

    if (
      this.validatefirstname &&
      this.validatelastname &&
      this.validatephone &&
      this.validatemail &&
      this.validatestreet &&
      this.validatestreetnumber &&
      this.validatepostalcode &&
      this.validatecity
    ) {
      matomo.genericEvent(
        this.product,
        matomo.MATOMO_EVENT_CATEGORY,
        "Click_Order"
      );
      this.sendingRequest = true;

      let data = this.getData();
      const requestHeaders = Store.isCovid() ? Constants.orderListToken : null;

      let result = await Repository.placeOrder(data, this.getRequestHeaders());
      if (result.type === ResultType.SUCCESS) {
        this.$emit("goToStep", { step: Step.SUCCESS });
      }

      this.sendingRequest = false;
    }
  }
}
