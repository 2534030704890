var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"expertforum-patient-login",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"login-username"}},[_vm._v("Benutzername / e-Mail-Adresse*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateUsername,
              'is-valid': _vm.attemptSubmit && _vm.validateUsername
            },attrs:{"id":"login-username","type":"text","required":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Passwort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePassword,
              'is-valid': _vm.attemptSubmit && _vm.validatePassword
            },attrs:{"id":"login-password","type":"password","required":"","autocomplete":"new-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])])]),(_vm.requestSent)?_c('div',{staticClass:"form-feedback"},[(_vm.apiError)?_c('div',{staticClass:"row submit-error"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.resultMessage)}})])]):_c('div',{staticClass:"row submit-success"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")])])])]):_vm._e(),_c('div',{staticClass:"controls text-center text-md-left mt-4"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-primary"},[_c('b',{staticClass:"px-3"},[_vm._v("Einloggen")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"waiting"},[_c('span',{staticClass:"spinner-border spinner-border-md mr-2"}),_c('span',[_vm._v("Bitte warten...")])]):_vm._e(),_c('a',{staticClass:"btn btn-link",on:{"click":function($event){return _vm.showModal()}}},[_vm._v("Passwort vergessen")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }