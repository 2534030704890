
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Chat extends BaseComponent {
  getComponentType() {
    return "chats";
  }

  addLiveChat() {
    let scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", "../../vendor/livechat.js");
    document.head.appendChild(scriptTag);
  }

  async mounted() {
    super.mounted();

    this.addLiveChat();
  }

  beforeDestroy() {
    // https://developers.livechat.com/docs/extending-chat-widget/javascript-api/#destroy
    (window as any).LiveChatWidget.call("destroy");

    // Remove scripts
    const liveChatScripts: any = document.getElementsByTagName("script");
    const scriptIdxs = [...liveChatScripts].map((script, idx) =>
      script.src.includes("livechat") ? idx : -1
    );

    let idxAdjustment = 0;
    for (let idx = 0; idx < scriptIdxs.length; idx += 1) {
      if (scriptIdxs[idx + idxAdjustment] !== -1) {
        liveChatScripts[scriptIdxs[idx + idxAdjustment]].remove();
        idxAdjustment -= 1;
      }
    }
  }
}
