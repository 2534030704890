import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class BookmarkRepository extends AbstractRepository {
  public async create(bookmark: any) {
    let url = Constants.getBookmarkUrl();
    let response = await Client.postWithJsonResult(
      url,
      bookmark,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async createList(bookmarkList: Array<any>) {
    let url = Constants.getBookmarksUrl();
    let response = await Client.postWithJsonResult(
      url,
      bookmarkList,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async get(id: string) {
    let url = Constants.getBookmarkUrl(id);
    let response = await Client.getJson(url, null, this.getAdditionalHeader());
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async delete(id: number) {
    let url = Constants.getBookmarkUrl(`${id}`);
    let response = await Client.delete(url, this.getAdditionalHeader());
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async getList(listId: string) {
    let url = Constants.getBookmarkListUrl(listId);
    let response = await Client.getJson(url, null, this.getAdditionalHeader());
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async deleteList(listId: string) {
    let url = Constants.getBookmarkListUrl(listId);
    let response = await Client.delete(url, this.getAdditionalHeader());
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new BookmarkRepository();
