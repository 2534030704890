
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class AnchorList extends BaseComponent {
  visibleItems: any[] = [];

  constructor() {
    super();
  }

  getComponentType() {
    return "anchor_lists";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    window.addEventListener("scroll", this.handleScroll);
  }

  get items() {
    return this.layoutComponent.settings.items;
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  handleScroll() {
    this.checkAnchorItemsInView();
  }

  inView(element: any) {
    const position = element.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return !(position.bottom < 0 || position.top - windowHeight >= 0);
  }

  checkAnchorItemsInView() {
    this.visibleItems = [];

    this.items.forEach((item: any) => {
      const element = document.querySelector(item.value.link);

      if (element === null) {
        return;
      }

      if (this.inView(element)) {
        this.visibleItems.push(item);
      }

      item.value.cssClass = "";
    });

    this.visibleItems.slice(-1)[0].value.cssClass = "active";
  }
}
