
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";
// @ts-ignore
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class ImageSlider extends BaseComponent {
  readonly GRID_MD_BREAKPOINT = 768;

  private sliderItemsCount = 0;
  private sliderItemsLoaded = 0;
  public loaded = false;

  private initialSpacePadding = 0;
  public carouselOptions: any = {
    perPage: 1,
    perPageCustom: [
      [768, 2],
      [1024, 3]
    ],
    mouseDrag: true,
    paginationEnabled: false,
    navigationEnabled: true,
    navigationNextLabel: "<i class='fas fa-chevron-right fa-lg'></i>",
    navigationPrevLabel: "<i class='fas fa-chevron-left fa-lg'></i>",
    spacePadding: 0,
    autoplay: false,
    autoplayTimeout: 5000,
    loop: true
  };

  getComponentType() {
    return "image_sliders";
  }

  async mounted() {
    super.mounted();
    this.setCarouselOptions();

    this.sliderItemsCount = this.sliderItemsNo;

    if (this.initialSpacePadding !== 0) {
      this.calcultateSpacePadding();
      window.addEventListener("resize", this.calcultateSpacePadding);
    }
  }

  beforeDestroy() {
    if (this.initialSpacePadding !== 0) {
      window.removeEventListener("resize", this.calcultateSpacePadding);
    }
  }

  /************************************/
  // Map Cockpit props

  get filteredSliderItems() {
    let sliderItems = this.getContentAttribute("slider_items");

    if (sliderItems && sliderItems.length > 0) {
      return sliderItems.filter((layoutComponent: LayoutComponent) => {
        return layoutComponent && layoutComponent.settings.visibility;
      });
    }

    return [];
  }

  get sliderItemsNo() {
    return this.filteredSliderItems.length;
  }

  get sliderItems() {
    return this.filteredSliderItems.map(
      (layoutComponent: LayoutComponent) => new LayoutComponent(layoutComponent)
    );
  }

  /************************************/
  // Utils

  sliderItemContentLoaded() {
    this.sliderItemsLoaded++;
    if (this.sliderItemsLoaded >= this.sliderItemsCount) {
      this.loaded = true;
    }
  }

  setCarouselOptions() {
    const options = this.getSettingsAttribute("slider_options");
    if (options) {
      Object.assign(this.carouselOptions, options);
    }
    this.initialSpacePadding = this.carouselOptions.spacePadding;
  }

  calcultateSpacePadding() {
    if (this.$refs.slider === null) {
      return;
    }

    this.carouselOptions.spacePadding =
      window.innerWidth < this.GRID_MD_BREAKPOINT
        ? 1
        : this.initialSpacePadding;
  }

  /************************************/
}
