
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContentRepository, LayoutComponent, ResultType } from "common";

@Component
export default class ArticleTemplate extends Vue {
  @Prop() content!: any;

  loadedContents!: any;
  textMaxWords!: number;

  constructor() {
    super();
    this.loadedContents = null;
    this.textMaxWords = -1;
  }

  mounted() {
    this.calculateTextMaxWords();
    window.addEventListener("resize", this.calculateTextMaxWords);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateTextMaxWords);
  }

  get hero() {
    try {
      return this.content.hero[0];
    } catch (e) {
      return null;
    }
  }

  get headline() {
    if (this.loadedContents != null) {
      for (let headline of this.loadedContents.headline) {
        if (headline.component === "headline") {
          return new LayoutComponent(headline);
        }
      }
    }

    return null;
  }

  get text() {
    if (this.loadedContents != null) {
      for (let text of this.loadedContents.text) {
        if (text.component === "lc_text") {
          return new LayoutComponent(text);
        }
      }
    }

    return null;
  }

  @Watch("hero")
  async loadHeroContent() {
    if (this.hero != null) {
      let result = await ContentRepository.getCollectionItemByTypeAndId(
        this.hero.settings.content.link,
        [this.hero.settings.content._id],
        0
      );

      if (result.type == ResultType.SUCCESS) {
        try {
          this.loadedContents = result.value[0];
        } catch (e) {
          this.loadedContents = null;
        }
      }
    }
  }

  calculateTextMaxWords() {
    let elementWidth = (this.$refs.template as HTMLElement).offsetWidth;
    let divisor = 15;

    if (elementWidth > 330) {
      divisor = 14;
    }

    if (elementWidth > 360) {
      divisor = 13;
    }

    if (elementWidth > 390) {
      divisor = 12;
    }

    if (elementWidth > 420) {
      divisor = 11;
    }

    if (elementWidth > 450) {
      divisor = 10;
    }

    this.textMaxWords = Math.floor(elementWidth / divisor);
  }
}
