
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class OfflabelConfirmationModal extends BaseComponent {
  constructor() {
    super();
  }

  openOfflabelPopup() {
    this.showModal(
      "Bitte wählen Sie die Inhalte aus, die Sie interessieren",
      new LayoutComponent({
        component: "OfflabelModal",
        settings: {
          offlabelStubId: this.offlabelStubId,
          itemOfInterest: this.itemOfInterest,
          email: this.email,
          showContactDetails: this.showContactDetails
        }
      }),
      "modal-lg"
    );
  }

  get itemOfInterest() {
    return this.layoutComponent
      ? this.layoutComponent.settings.itemOfInterest
      : null;
  }

  get email() {
    return this.layoutComponent ? this.layoutComponent.settings.email : null;
  }

  get showContactDetails() {
    return this.layoutComponent
      ? this.layoutComponent.settings.showContactDetails
      : false;
  }

  get offlabelStubId() {
    return this.layoutComponent
      ? this.layoutComponent.settings.offlabelStubId
      : null;
  }
}
