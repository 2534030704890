import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class OfflabelRepository extends AbstractRepository {
  async getOfflabelSnippetsForOfflineStub(offlineStubId: string) {
    let data = {
      filter: {
        "offlabel_stub._id": offlineStubId
      },
      simple: 1,
      populate: 0
    };

    let response = await Client.postWithJsonResult(
      Constants.collectionEntries("offlabel_snippets"),
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async requestOfflabelArticleCreation(
    offlabel_stub: string,
    offlabel_snippets: Array<string>,
    offlabel_content: Array<Object>
  ) {
    let data = {
      offlabel_stub: offlabel_stub,
      offlabel_snippets: offlabel_snippets,
      offlabel_content: offlabel_content
    };

    return await Client.postWithJsonResult(
      Constants.offlabelUrl,
      data,
      this.getAdditionalHeader()
    );
  }
}

export default new OfflabelRepository();
