
import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { Constants, Event, EventRepository, ResultType } from "common";

@Component
export default class ListItem extends BaseComponent {
  @Prop() event!: Event;

  public storageUrl!: string;
  public eventImage!: any;

  constructor() {
    super();
    this.storageUrl = Constants.storageUrl;
    this.eventImage = null;
  }

  async mounted() {
    this.eventImage = await this.loadImage();
  }

  async loadImage() {
    try {
      let docId = this.event.documents[0].id;
      let imageUrl = await EventRepository.getEventDocument(docId)
        .then(this.validateResponse)
        .then(response => response.blob())
        .then(blob => {
          return URL.createObjectURL(blob);
        });
      return imageUrl;
    } catch (e) {
      return null;
    }
  }

  validateResponse(response: any) {
    if (response.type == ResultType.FAIL) {
      throw Error(response.statusText);
    }

    return response.value;
  }
}
