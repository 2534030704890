
import { Component } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Popover extends BaseComponent {
  public visible!: boolean;

  constructor() {
    super();
    this.visible = false;
  }

  getComponentType() {
    return "popovers";
  }

  async mounted() {
    await super.mounted();
  }

  /************************************/
  // Map Cockpit props

  get toggleBtnLabel() {
    return this.getSettingsAttribute("toggle_btn_label");
  }

  get toggleBtnClasses() {
    return this.getSettingsAttribute("toggle_btn_classes");
  }

  get placement() {
    return this.getSettingsAttribute("placement") || "top";
  }

  get triggerOn() {
    return this.getSettingsAttribute("trigger");
  }

  get overlayClass() {
    return this.getSettingsAttribute("overlay_classes");
  }

  get headerText() {
    return this.getContentAttribute("header_text");
  }

  get popoverContent() {
    let content = this.getContentAttribute("content");
    if (content && content.length > 0) {
      return content
        .filter((comp: any) => comp.settings.visibility)
        .map((comp: any) => new LayoutComponent(comp));
    }

    return null;
  }

  /************************************/
  // Utils

  popoverAppendTo() {
    let elementToPopoverAppend: any;
    const appendTo = this.getContentAttribute("append_to");
    if (appendTo) {
      elementToPopoverAppend = document.querySelector(appendTo);
    }

    return (
      elementToPopoverAppend ||
      document.getElementById("layout-content") ||
      null
    );
  }

  toggleVisibility() {
    this.visible = !this.visible;
  }
}
