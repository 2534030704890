
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import BaseComponent from "../../base/BaseComponent.vue";
import {
  BookmarkModel as Bookmark,
  BookmarkerModel,
  BookmarkRepository,
  BookmarkList,
  LayoutComponent,
  matomo,
  MatomoEventNames,
  ResultType,
  router,
  User,
  UserLoginStatus
} from "common";

const collectionEventNames: any = {
  Video: MatomoEventNames.BOOKMARK_VIDEO,
  Artikel: MatomoEventNames.BOOKMARK_ARTICLE,
  "3D Model": MatomoEventNames.BOOKMARK_3D_MODEL,
  Informationsportal: MatomoEventNames.BOOKMARK_INFORMATION_PORTAL,
  Informationsmaterialien: MatomoEventNames.BOOKMARK_INFORMATION_MATERIAL
};

@Component
export default class Bookmarker extends BaseComponent {
  @Prop({ default: "" }) urlParams!: string;
  @Prop({ default: "" }) alternativeSharingUrl!: string;

  hover!: boolean;
  loading!: boolean;
  isBookmarked!: boolean;
  bookmarkId!: number;
  bookmarkList = BookmarkList;

  getComponentType() {
    return "";
  }

  constructor() {
    super();
    this.hover = false;
    this.loading = false;
    this.isBookmarked = false;
  }

  async mounted() {
    await super.mounted();
    Vue.nextTick(() => {
      this.setIsBookmarked();
    });
  }

  get label() {
    const label = this.getSettingsAttribute("label");
    if (label && label.trim().length > 0) {
      return label;
    }
    return null;
  }

  get bookmarkTitle() {
    return `Bookmark ${this.collectionTitle}`;
  }

  get collectionTitle() {
    return this.getSettingsAttribute("collection_title");
  }

  get collectionName() {
    try {
      return this.getSettingsAttribute("collection").link;
    } catch (e) {
      return "";
    }
  }

  get foreignId() {
    try {
      return this.getSettingsAttribute("collection")._id;
    } catch (e) {
      return "";
    }
  }

  get sharingUrl() {
    if (this.alternativeSharingUrl != "") {
      return this.alternativeSharingUrl;
    }

    if (this.urlParams) {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        router.currentRoute.path +
        this.urlParams
      );
    }

    return (
      window.location.protocol +
      "//" +
      window.location.host +
      router.currentRoute.fullPath
    );
  }

  @Watch("bookmarkList.updated")
  @Watch("foreignId")
  setIsBookmarked() {
    let bookmark = BookmarkList.findByUniqueCombination(
      this.foreignId,
      this.collectionName
    );

    if (bookmark && bookmark.id) {
      this.bookmarkId = bookmark.id;
    }

    this.isBookmarked = bookmark != undefined;

    BookmarkList.addBookmarker(new BookmarkerModel(this.getBookmarkObject()));
  }

  getBookmarkObject() {
    return {
      listId: User.getProperty("bookmarkListId"),
      collectionTitle: this.collectionTitle,
      collectionName: this.collectionName,
      foreignId: this.foreignId,
      foreignLabel: "tbd",
      sharingUrl: this.sharingUrl
    };
  }

  async bookmarkIt() {
    if (!this.loading && this.loggedIn) {
      if (!this.isBookmarked) {
        this.loading = true;
        let result = await BookmarkRepository.create(this.getBookmarkObject());
        this.loading = false;

        // Matomo tracking
        matomo.genericEvent(
          this.foreignId,
          matomo.MATOMO_EVENT_CATEGORY,
          collectionEventNames[this.collectionTitle]
        );

        if (result.type == ResultType.SUCCESS) {
          BookmarkList.add(new Bookmark(result.value));
        }
      } else if (this.bookmarkId) {
        let result = await BookmarkRepository.delete(this.bookmarkId);
        if (result.type == ResultType.SUCCESS) {
          BookmarkList.delete(this.bookmarkId);
        }
      }
    }
  }

  get popover() {
    return LayoutComponent.createEmpty("popover", {
      width: 12,
      visibility: true,
      content: {
        _id: "617fe9fac60c79755c408057",
        display: "",
        type: "popovers"
      },
      toggle_btn_label: "",
      toggle_btn_classes: "bookmarker-image",
      placement: "top",
      trigger: "click",
      overlay_classes: ""
    });
  }

  get loggedIn() {
    return User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN);
  }
}
