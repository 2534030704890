
import { Vue, Component } from "vue-property-decorator";
import { LayoutComponent, router, Store } from "common";

@Component
export default class PatientRegisterModal extends Vue {
  mounted() {
    const registerComp = LayoutComponent.createEmpty("RegisterModalTemplate");

    Store.modal.className = "modal-md modal--register";
    Store.modal.preventHideByAction = true;
    Store.modal.onModalClose = () => router.push("/");
    Store.modal.setLayoutComponent(registerComp);
    Store.modal.showModal();
  }
}
