import Constants from "../../models/Constants";
import Client from "../util/Client";
import { Result } from "../util/Result";
import AbstractRepository from "./AbstractRepository";

class SlideDeckRepository extends AbstractRepository {
  public async notify(body: string): Promise<Result<any>> {
    return await Client.post(
      `${Constants.slideDeckUrl}/notify`,
      { body },
      this.getAdditionalHeader()
    );
  }
}

export default new SlideDeckRepository();
