import { Storage } from "../api/util/Storage";
import { BookmarkList, Store } from "../util/Store";
import { EventBus, USER_LOGIN, USER_LOGOUT } from "../util/EventBus";
import KalturaSession from "../api/util/KalturaSession";
import { userProperty } from "../util/decorators/userProperty.decorator";
import config, { FrontendContext } from "../config";
import { changeContextByRoute } from "../util/Helper";

/*
User Permission Frontend -> DRAFT

- UserGroup
  - HCP, HCP_TOKEN, PATIENT, LOGGED_OUT
- UserProfile
  - MMC_DOCCHECK, MMC_BASIC, MMC_PLUS
- UserRole
  - GUEST, DOCTOR, QUALIFIED_PERSONNEL, OTHER
- UserState
  - ACTIVE, PASSWORDCHANGEREQ
- User
  - {#1, UserGroup #1, UserProfile, UserRole, UserState}, {#2, UserGroup #1, UserProfile, UserRole, UserState}, {#3, UserGroup #2, UserProfile, UserRole, UserState}, {...}
- Resource
  - account, ...
- ResourcePolicy
  - {Resource, Access: {UserGroup, UserProfile, UserRole, UserState}}, {...}
    - e.g.
      {Resource: account, Access: {HCP, MMC_BASIC, ANY, ANY}},
      {Resource: account, Access: {PATIENT, ANY, ANY, ANY}},
      {Resource: account, Access: {HCP, MMC_PLUS, ANY, ACTIVE}},
*/

/** Based on the FrontendContext (HCP or PATIENT) --> see src/config.ts */
export enum UserGroup {
  HCP = "HCP",
  HCP_TOKEN = "HCP_TOKEN",
  PATIENT = "PATIENT",
  PATIENT_BATCH_NUMBER = "PATIENT_BATCH_NUMBER", // Chargennummer Identifikation für bestimmte Seiten -> TODO
  LOGGED_OUT = "LOGGED_OUT"
}

/** Based on the hcp user attribute "accountProfile"  */
export enum UserProfile {
  PATIENT_PROTECTION = "PATIENT_PROTECTION",
  MMC_DOCCHECK = "MMC_DOCCHECK",
  MMC_BASIC = "MMC_BASIC",
  MMC_PLUS = "MMC_PLUS",
  LOGGED_OUT = "logged_out"
}

/** Based on the hcp user attribute "roles" */
export enum UserRoleName {
  GUEST = "GUEST",
  DOCTOR = "DOCTOR",
  QUALIFIED_PERSONNEL = "QUALIFIED_PERSONNEL",
  PHARMACIST = "PHARMACIST",
  OTHER = "OTHER"
}

export enum InstitutionRoleName {
  EMPLOYED_DOCTOR = "EMPLOYED_DOCTOR",
  PHARMACY_OWNER = "PHARMACY_OWNER",
  PHARMACY_DIRECTOR = "PHARMACY_DIRECTOR",
  MEDICAL_ASSISTANT = "MEDICAL_ASSISTANT",
  AFFILIATED_DOCTOR = "AFFILIATED_DOCTOR",
  HEAD_NURSE_PSYCHIATRY = "HEAD_NURSE_PSYCHIATRY",
  OCCUPATIONAL_THERAPIST = "OCCUPATIONAL_THERAPIST",
  PROFESSIONAL_STAFF = "PROFESSIONAL_STAFF",
  CLINIC_STAFF = "CLINIC_STAFF",
  NURSE = "NURSE",
  LTD_PHYSICIAN = "LTD_PHYSICIAN",
  MED_CLERK_MEDICAL_ASSISTANT = "MED_CLERK_MEDICAL_ASSISTANT",
  PHARMACY_PHARMACIST = "PHARMACY_PHARMACIST",
  NURSING_STAFF = "NURSING_STAFF",
  MALE_NURSE = "MALE_NURSE",
  PRACTICE_OWNER = "PRACTIVE_OWNER",
  PSYCHOLOGIST = "PSYCHOLOGIST",
  PARAMEDIC = "PARAMEDIC",
  OTHER = "OTHER",
  STUDY_STAFF = "STUDY_STAFF"
}

export class UserRole {
  public name: UserRoleName;
  public institutionRoleName: InstitutionRoleName;

  constructor(name?: UserRoleName, institutionRoleName?: InstitutionRoleName) {
    this.name = name || UserRoleName.OTHER;
    this.institutionRoleName = institutionRoleName || InstitutionRoleName.OTHER;
  }
}

/** Based on the hcp user attribute "hcpStatus" */
export enum UserState {
  ACTIVE = "ACTIVE",
  PASSWORDCHANGEREQ = "PASSWORDCHANGEREQ"
}

export enum UserLoginStatus {
  LOGGED_OUT = "LOGGED_OUT",
  LOGGED_IN = "LOGGED_IN",
  DOCCHECK_ONLY = "DOCCHECK_ONLY",
  DOCCHECK_PENDING = "DOCCHECK_PENDING"
}

export enum UserLoginError {
  HCP_ACCOUNT_LOCKED = "HCP_ACCOUNT_LOCKED",
  ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
  HCP_INVALID_LOGIN = "HCP_INVALID_LOGIN"
}

/*export enum UserCookie {
  COOKIE_PARAM_USER = "_ww_u",
  COOKIE_PARAM_REFRESHTOKEN = "_ww_rt",
  COOKIE_PARAM_ACCESSTOKEN = "_ww_at",
  COOKIE_PARAM_DEEPLOGINTOKEN = "_ww_dlt",
  COOKIE_PARAM_KALTURA_ADMIN_SECRET = "_ww_kas",
  COOKIE_PARAM_KALTURA_SECRET = "_ww_ks",
  COOKIE_PARAM_KALTURA_APP_TOKEN = "_ww_kat",
  COOKIE_PARAM_MAYWATCHONE = "_ww_mwo",
  COOKIE_PARAM_DOCCHECK_SESSION_ID = "_ww_dc_sid",
  COOKIE_PARAM_DOCKCHECK_CHECKSUM = "_ww_dc_cs",
  COOKIE_PARAM_DOCKCHECK_USERID = "_ww_dc_uid"
}*/

export interface UserInterface {
  userProperties: any;
  apiUserProperties: any;
  updatedAt: any;
  toJson(): UserProperties;
  asApiUser(): UserProperties;
  update(properties: UserProperties): UserInterface;
  saveToStorage(): boolean;
  updateAndSaveToStorage(properties: UserProperties): UserInterface;
  retrieveFromStorage(): any;
  delete(): void;
  login(loginData: any): any;
  logout(): any;
  getProperty(property: string): any;
  hasProperty(property: string): boolean;
  getUserLoginStatus(): UserLoginStatus | undefined;
  getUserGroups(): UserGroup[] | undefined;
  getUserProfile(): UserProfile | undefined;
  getUserRoles(): UserRole[] | undefined;
  getUserRoleNames(): UserRoleName[];
  getUserState(): UserState | undefined;
  hasUserLoginStatus(value: UserLoginStatus): boolean;
  hasUserGroup(value: UserGroup): boolean;
  hasUserProfile(value: UserProfile): boolean;
  hasUserRole(value: UserRoleName): boolean;
  hasInstitutionRoleName(value: InstitutionRoleName): boolean;
  hasUserState(value: UserState): boolean;
  getTrackingConsent(): boolean;
  setTrackingConsent(consent: boolean): void;
  refreshSessions(): void;
  getUserInfo(): string;
  shouldUseLocalStorageToSaveArticles(): boolean;
}

export class UserProperties {
  [key: string]: any;
}

export class BasicUser implements UserInterface {
  [key: string]: any;

  public readonly COOKIE_PARAM_USER = "_ww_u";

  /* Timestamp for user update */
  public updatedAt!: any;
  public storedAt?: any;

  /* Basic user parameters */
  @userProperty({ apiUser: true }) public id?: string;
  @userProperty({ apiUser: true }) public email?: string;

  /* Basic user token parameters */
  @userProperty() public accessToken?: string;
  @userProperty() public refreshToken?: string;
  @userProperty() public kalturaAdminSecret?: string;
  @userProperty() public kalturaSecret?: string;
  @userProperty() public kalturaAppToken?: string;

  /* Given from interface */
  public userProperties: any;
  public apiUserProperties: any;

  constructor() {
    let userFromStorage = this.retrieveFromStorage();
    if (userFromStorage) {
      this.update(userFromStorage).saveToStorage();
    } else {
      this.updatedAt = Date.now();
    }
  }

  private updateUpdatedAt() {
    this.updatedAt = Date.now();
  }

  private updateStoredAt() {
    this.storedAt = Date.now();
  }

  update(properties: UserProperties): UserInterface {
    Object.keys(properties).forEach((key: string) => {
      if (this.userProperties.includes(key)) {
        try {
          this[key] = properties[key];
        } catch (e) {
          //
        }
      }
    });

    this.updateUpdatedAt();
    return this;
  }

  saveToStorage(): boolean {
    Storage.saveLocalEncoded(this.COOKIE_PARAM_USER, this.toJson());
    this.updateStoredAt();
    return true;
  }

  updateAndSaveToStorage(properties: UserProperties): UserInterface {
    this.update(properties).saveToStorage();
    return this;
  }

  retrieveFromStorage(): UserProperties {
    return Storage.getLocalEncoded(this.COOKIE_PARAM_USER);
  }

  delete() {
    Storage.deleteLocal(this.COOKIE_PARAM_USER);

    this.userProperties.forEach((property: string) => {
      try {
        this[property] = undefined;
      } catch (e) {
        //
      }
    });

    this.updateUpdatedAt();
  }

  toJson(): UserProperties {
    let user: UserProperties = {};
    this.userProperties.forEach((property: string) => {
      user[property] = this[property];
    });
    return user;
  }

  asApiUser(): UserProperties {
    let user: UserProperties = {};
    this.apiUserProperties.forEach((property: string) => {
      user[property] = this[property];
    });

    return user;
  }

  getProperty(property: string) {
    if (this.userProperties.includes(property)) {
      return this[property];
    }

    return null;
  }

  hasProperty(property: string): boolean {
    return this.getProperty(property) != null;
  }

  login(loginData: any) {
    throw new Error("Method not implemented.");
  }

  async logout() {
    await this.endKalturaSession();
    this.delete();
    EventBus.$emit(USER_LOGOUT);
    return true;
  }

  getUserGroups(): UserGroup[] | undefined {
    return undefined;
  }

  getUserProfile(): UserProfile | undefined {
    return undefined;
  }

  getUserRoles(): UserRole[] | undefined {
    return undefined;
  }

  getUserRoleNames(): UserRoleName[] {
    return [];
  }

  getUserState(): UserState | undefined {
    return undefined;
  }

  getUserLoginStatus(): UserLoginStatus | undefined {
    return undefined;
  }

  hasUserLoginStatus(value: UserLoginStatus): boolean {
    return this.getUserLoginStatus() == value;
  }

  hasUserGroup(value: UserGroup): boolean {
    if (this.getUserGroups() != undefined) {
      return this.getUserGroups()!.includes(value);
    }

    return false;
  }

  hasUserProfile(value: UserProfile): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    return this.accountProfile == value;
  }

  hasUserRole(value: UserRoleName): boolean {
    if (this.getUserRoles() === undefined) {
      return false;
    }

    if (this.getUserRoles()!.length == 0) {
      return false;
    }

    return this.getUserRoles()!.some(
      (role: UserRole) =>
        role.name.toLocaleLowerCase() === value.toLocaleLowerCase()
    );
  }

  hasInstitutionRoleName(value: InstitutionRoleName): boolean {
    if (this.getUserRoles() === undefined) {
      return false;
    }

    if (this.getUserRoles()!.length == 0) {
      return false;
    }

    return this.getUserRoles()!.some(
      (role: UserRole) => role.institutionRoleName == value
    );
  }

  hasUserState(value: UserState): boolean {
    return this.getUserState() == value;
  }

  getTrackingConsent(): boolean {
    const wscrCookies = Storage.get("wscrCookieConsent", false, true);
    let isCookieConsentGiven = false;
    if (wscrCookies) {
      // check if level 2 cookies are set to "true"
      isCookieConsentGiven = wscrCookies.split("&")[1].endsWith("true");
    }

    // For HCP tracking is always enabled.
    return Store.isHCP() ? true : !!isCookieConsentGiven;
  }

  setTrackingConsent(consent: boolean): void {}

  refreshSessions(): void {}

  protected async restartKalturaSession() {
    await this.endKalturaSession();
    await this.startKalturaSession();
  }

  protected async startKalturaSession() {
    if (
      this.getUserLoginStatus() == UserLoginStatus.LOGGED_IN &&
      Store.hasKalturaSessionInfos()
    ) {
      await KalturaSession.startUserSession();
    }
  }

  protected async endKalturaSession() {
    await KalturaSession.endUserSession();
  }

  getUserInfo(): string {
    return "";
  }

  shouldUseLocalStorageToSaveArticles() {
    return true;
  }
}

export class UserHCP extends BasicUser {
  /* HCP user special parameters */
  @userProperty({ apiUser: true }) public title?: string;
  @userProperty({ apiUser: true }) public firstName?: string;
  @userProperty({ apiUser: true }) public lastName?: string;
  @userProperty({ apiUser: true }) public salutation?: string;
  @userProperty({ apiUser: true }) public login_with_pw?: boolean;
  @userProperty({ apiUser: true }) public loginToken?: string;
  @userProperty({ apiUser: true }) public idDuO?: string;
  @userProperty({ apiUser: true }) public idVeeva?: string;
  @userProperty({ apiUser: true }) public segmentations?: any;
  @userProperty({ apiUser: true }) public subTerritory?: string;
  @userProperty({ apiUser: true }) public accountProfile?: UserProfile;
  @userProperty({ apiUser: true }) public hcpStatus?: UserState;
  @userProperty({ apiUser: true }) public roles?: any;
  @userProperty({ apiUser: true }) public zip?: string;
  @userProperty() public draftMode?: boolean;

  /* HCP user special tokens */
  @userProperty() public deepLoginToken?: string;
  @userProperty() public mayWatchOne?: string;
  @userProperty() public docCheckSessionId?: string;
  @userProperty() public docCheckCheckSum?: string;
  @userProperty() public docCheckUserId?: string;

  constructor() {
    super();

    if (this.draftMode == undefined) {
      this.draftMode = false;
    }
  }

  @userProperty()
  public get matomoTrackingId() {
    if (this.idDuO != undefined) {
      if (this.docCheckUserId != undefined) {
        return this.idDuO + "_|_" + this.docCheckUserId;
      }

      return this.idDuO;
    }

    return null;
  }

  @userProperty()
  public get kalturaTrackingId() {
    if (this.idDuO != undefined) {
      return this.idDuO;
    }
    return null;
  }

  @userProperty()
  public get isInDraftMode() {
    if (Store.isProd()) {
      return false;
    }

    if (!this.isLoggedIn()) {
      return false;
    }

    return this.draftMode || false;
  }

  /**
   * This method only provides functionality to save the
   * userdata to localStorage and create a session for Kaltura
   * For the login method containing the api call
   * please use src/util/LoginHandler.ts
   */
  async login(loginData: any) {
    try {
      this.update({
        id: loginData.hcp.id, // default prop from BasicUser
        email: loginData.hcp.email, // default prop from BasicUser
        accessToken: loginData.access_token, // default prop from BasicUser
        refreshToken: loginData.refresh_token, // default prop from BasicUser
        title: loginData.hcp.title,
        firstName: loginData.hcp.firstName,
        lastName: loginData.hcp.lastName,
        loginToken: loginData.hcp.loginToken,
        salutation: loginData.hcp.salutation,
        login_with_pw: loginData.hcp.login_with_pw,
        idDuO: loginData.hcp.idDuO,
        idVeeva: loginData.hcp.idVeeva,
        segmentations: loginData.hcp.segmentations,
        subTerritory: loginData.hcp.subTerritory,
        accountProfile: loginData.hcp.accountProfile,
        hcpStatus: loginData.hcp.hcpStatus,
        roles: loginData.hcp.roles || [],
        zip: loginData.hcp.zip
      }).saveToStorage();
      await this.startKalturaSession();
      return true;
    } catch (e) {
      return false;
    }
  }

  // async logout() {
  //   super.logout();
  //   // await this.endKalturaSession();
  //   // this.delete();
  //   // EventBus.$emit(USER_LOGOUT);
  //   // return true;
  // }

  getUserGroups(): UserGroup[] | undefined {
    if (!this.isLoggedIn()) {
      return [UserGroup.LOGGED_OUT];
    }

    if (this.login_with_pw) {
      return [UserGroup.HCP];
    }

    if (!this.login_with_pw) {
      return [UserGroup.HCP_TOKEN];
    }

    return undefined;
  }

  getUserProfile(): UserProfile | undefined {
    return this.accountProfile;
  }

  getUserRoles(): UserRole[] | undefined {
    return this.roles;
  }

  getUserRoleNames(): UserRoleName[] {
    if (!this.roles) {
      return [];
    }

    // For DocCheck users - return Doctor role
    if (
      this.hasUserProfile(UserProfile.MMC_DOCCHECK) &&
      this.roles.length == 0
    ) {
      return [UserRoleName.DOCTOR];
    }

    // Treat MWO users as doctors
    if (this.mayWatchOne) {
      return [...this.roles.map((role: any) => role.name), UserRoleName.DOCTOR];
    }

    return this.roles.map((role: any) => role.name);
  }

  getUserState(): UserState | undefined {
    return this.hcpStatus;
  }

  getUserLoginStatus(): UserLoginStatus {
    /*
     * Login status priority:
     * VEEVA > DOCCHECK > LOGGED OUT
     */
    if (this.isLoggedIn()) {
      return UserLoginStatus.LOGGED_IN;
    }

    if (this.isDocCheckAuthorizedOnly()) {
      return UserLoginStatus.DOCCHECK_ONLY;
    }

    if (this.isDocCheckPending()) {
      return UserLoginStatus.DOCCHECK_PENDING;
    }

    return UserLoginStatus.LOGGED_OUT;
  }

  public setTrackingConsent(consent: boolean) {
    if (!this.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      this.update({
        accountProfile: consent ? UserProfile.MMC_PLUS : UserProfile.MMC_BASIC
      }).saveToStorage();
    }
  }

  private isLoggedIn() {
    /*
     * The user is logged in if he has an accesstoken AND a refresh token
     * id and email are taken into consideration here, too. Since they
     * are necessary properties for all types of users.
     */
    return (
      this.accessToken != undefined &&
      this.refreshToken != undefined &&
      this.id != undefined &&
      this.email != undefined
    );
  }

  private isDocCheckAuthorizedOnly() {
    /*
     * The user is only doccheck authorized (no veeva) if he does NEITHER have
     * an access token NOR a refresh token BUT ONLY doccheck session_id and user_id
     */
    return (
      this.docCheckSessionId != undefined &&
      this.docCheckUserId != undefined &&
      this.accessToken == undefined &&
      this.refreshToken == undefined
    );
  }

  private isDocCheckPending() {
    return (
      this.docCheckCheckSum != undefined &&
      this.docCheckSessionId == undefined &&
      this.docCheckUserId == undefined &&
      this.accessToken == undefined &&
      this.refreshToken == undefined
    );
  }

  refreshSessions(): void {
    this.restartKalturaSession();
  }

  getUserInfo(): string {
    if (!this.getProperty("title")) {
      return `${this.getProperty("firstName")} ${this.getProperty("lastName")}`;
    }

    return `${this.getProperty("title")} ${this.getProperty("lastName")}`;
  }

  shouldUseLocalStorageToSaveArticles() {
    return (
      !this.hasUserLoginStatus(UserLoginStatus.LOGGED_IN) ||
      this.docCheckUserId != undefined
    );
  }
}

export class UserPatient extends BasicUser {
  @userProperty({ apiUser: true }) public username?: string;
  @userProperty() public bookmarkListId?: string;

  constructor() {
    super();
  }

  @userProperty()
  public get matomoTrackingId() {
    return null;
    // return this.id;
    // TODO -> check if logged in
  }

  @userProperty()
  public get kalturaTrackingId() {
    return null;
    // return this.id;
    // TODO -> check if logged in
  }

  /**
   * This method only provides functionality to save the
   * userdata to localStorage and create a session for Kaltura
   * For the login method containing the api call
   * please use src/util/LoginHandler.ts
   */
  async login(loginData: any) {
    try {
      this.update({
        id: loginData.patient.id, // default prop from BasicUser
        email: loginData.patient.email, // default prop from BasicUser
        accessToken: loginData.access_token, // default prop from BasicUser
        refreshToken: loginData.refresh_token, // default prop from BasicUser
        username: loginData.patient.username,
        bookmarkListId: loginData.patient.bookmarkListId
      }).saveToStorage();
      await this.startKalturaSession();
      EventBus.$emit(USER_LOGIN);
      return true;
    } catch (e) {
      return false;
    }
  }

  getUserGroups(): UserGroup[] | undefined {
    return this.isLoggedIn() ? [UserGroup.PATIENT] : [UserGroup.LOGGED_OUT];
  }

  getUserLoginStatus(): UserLoginStatus {
    return this.isLoggedIn()
      ? UserLoginStatus.LOGGED_IN
      : UserLoginStatus.LOGGED_OUT;
  }

  private isLoggedIn() {
    /*
     * The user is logged in if he has an accesstoken AND a refresh token
     * id and email are taken into consideration here, too. Since they
     * are necessary properties for all types of users.
     */
    return (
      this.accessToken != undefined &&
      this.refreshToken != undefined &&
      this.id != undefined &&
      this.email != undefined
    );
  }

  getUserInfo(): string {
    return `${this.getProperty("username")}`;
  }

  delete() {
    super.delete();
    Storage.deleteLocal(BookmarkList.storageKey);
  }
}

// class User {
//   private static instance?: User;
//   private storedUser?: any;
//   private storedRefreshToken?: any;
//   private storedAccessToken?: any;
//   private storedDeepLoginToken?: any;
//   private storedKalturaAdminSecret?: any;
//   private storedKalturaSecret?: any;
//   private storedKalturaAppToken?: any;
//   private storedMayWatchOne?: any;

//   private storedDocCheckSessionId?: any;
//   private storedDocCheckCheckSum?: any;
//   private storedDocCheckUserId?: any;

//   public updated!: any;

//   readonly ROLE_GUEST = "GUEST";

//   readonly COOKIE_PARAM_USER = "_ww_u";
//   readonly COOKIE_PARAM_REFRESHTOKEN = "_ww_rt";
//   readonly COOKIE_PARAM_ACCESSTOKEN = "_ww_at";
//   readonly COOKIE_PARAM_DEEPLOGINTOKEN = "_ww_dlt";
//   readonly COOKIE_PARAM_KALTURA_ADMIN_SECRET = "_ww_kas";
//   readonly COOKIE_PARAM_KALTURA_SECRET = "_ww_ks";
//   readonly COOKIE_PARAM_KALTURA_APP_TOKEN = "_ww_kat";
//   readonly COOKIE_PARAM_MAYWATCHONE = "_ww_mwo";

//   readonly COOKIE_PARAM_DOCCHECK_SESSION_ID = "_ww_dc_sid";
//   readonly COOKIE_PARAM_DOCKCHECK_CHECKSUM = "_ww_dc_cs";
//   readonly COOKIE_PARAM_DOCKCHECK_USERID = "_ww_dc_uid";

//   constructor() {
//     this.storedUser = Storage.getLocal(this.COOKIE_PARAM_USER, false, true);
//     this.storedRefreshToken = Storage.getLocal(
//       this.COOKIE_PARAM_REFRESHTOKEN,
//       false,
//       true
//     );

//     this.storedAccessToken = Storage.getLocal(
//       this.COOKIE_PARAM_ACCESSTOKEN,
//       false,
//       true
//     );

//     this.storedDeepLoginToken = Storage.getLocal(
//       this.COOKIE_PARAM_DEEPLOGINTOKEN,
//       false,
//       true
//     );

//     this.storedKalturaAdminSecret = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_ADMIN_SECRET,
//       false,
//       true
//     );

//     this.storedKalturaSecret = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_SECRET,
//       false,
//       true
//     );

//     this.storedKalturaAppToken = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_APP_TOKEN,
//       false,
//       true
//     );

//     this.storedMayWatchOne = Storage.getLocal(
//       this.COOKIE_PARAM_MAYWATCHONE,
//       false,
//       true
//     );

//     this.storedDocCheckSessionId = Storage.getLocal(
//       this.COOKIE_PARAM_DOCCHECK_SESSION_ID,
//       false,
//       true
//     );

//     this.storedDocCheckCheckSum = Storage.getLocal(
//       this.COOKIE_PARAM_DOCKCHECK_CHECKSUM,
//       true,
//       true
//     );

//     this.storedDocCheckUserId = Storage.getLocal(
//       this.COOKIE_PARAM_DOCKCHECK_USERID,
//       false,
//       true
//     );

//     this.updated = Date.now();
//   }

//   private update() {
//     this.updated = Date.now();
//   }

//   public getUser() {
//     return this.storedUser;
//   }

//   public getRefreshToken() {
//     return this.storedRefreshToken;
//   }

//   public getAccessToken() {
//     return this.storedAccessToken;
//   }

//   public getDeepLoginToken() {
//     return this.storedDeepLoginToken;
//   }

//   public getKalturaAdminSecret() {
//     return this.storedKalturaAdminSecret;
//   }

//   public getKalturaSecret() {
//     if (this.storedKalturaSecret) {
//       return this.storedKalturaSecret;
//     } else if (this.storedKalturaAdminSecret) {
//       return this.storedKalturaAdminSecret;
//     }
//     return null;
//   }

//   public getKalturaAppToken() {
//     return this.storedKalturaAppToken;
//   }

//   public getMayWatchOne() {
//     return this.storedMayWatchOne;
//   }

//   public getDocCheckSessionId() {
//     return this.storedDocCheckSessionId;
//   }

//   public getDocCheckUserId() {
//     return this.storedDocCheckUserId;
//   }

//   public getDocCheckCheckSum() {
//     return this.storedDocCheckCheckSum;
//   }

//   public deleteUser() {
//     Storage.deleteLocal(this.COOKIE_PARAM_USER);
//     this.storedUser = null;
//   }

//   public deleteRefreshToken() {
//     Storage.deleteLocal(this.COOKIE_PARAM_REFRESHTOKEN);
//     this.storedRefreshToken = null;
//   }

//   public deleteAccessToken() {
//     Storage.deleteLocal(this.COOKIE_PARAM_ACCESSTOKEN);
//     this.storedAccessToken = null;
//   }

//   public deleteDeepLoginToken() {
//     Storage.deleteLocal(this.COOKIE_PARAM_DEEPLOGINTOKEN);
//     this.storedDeepLoginToken = null;
//   }

//   public deleteKalturaAdminSecret() {
//     Storage.deleteLocal(this.COOKIE_PARAM_KALTURA_ADMIN_SECRET);
//     this.storedKalturaAdminSecret = null;
//   }

//   public deleteKalturaSecret() {
//     Storage.deleteLocal(this.COOKIE_PARAM_KALTURA_SECRET);
//     this.storedKalturaSecret = null;
//   }

//   public deleteKalturaAppToken() {
//     Storage.deleteLocal(this.COOKIE_PARAM_KALTURA_APP_TOKEN);
//     this.storedKalturaAppToken = null;
//   }

//   public deleteMayWatchOne() {
//     Storage.deleteLocal(this.COOKIE_PARAM_MAYWATCHONE);
//     this.storedMayWatchOne = null;
//   }

//   public deleteDocCheckSessionId() {
//     Storage.deleteLocal(this.COOKIE_PARAM_DOCCHECK_SESSION_ID);
//     this.storedDocCheckSessionId = null;
//   }

//   public deleteDocCheckCheckSum() {
//     Storage.deleteLocal(this.COOKIE_PARAM_DOCKCHECK_CHECKSUM, true);
//     this.storedDocCheckCheckSum = null;
//   }

//   public deleteDocCheckUserId() {
//     Storage.deleteLocal(this.COOKIE_PARAM_DOCKCHECK_USERID);
//     this.storedDocCheckUserId = null;
//   }

//   public setUser(userObj: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_USER, userObj, false, true);
//     this.storedUser = Storage.getLocal(this.COOKIE_PARAM_USER, false, true);
//     this.update();
//   }

//   public setRefreshToken(token: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_REFRESHTOKEN, token, false, true);
//     this.storedRefreshToken = Storage.getLocal(
//       this.COOKIE_PARAM_REFRESHTOKEN,
//       false,
//       true
//     );
//   }

//   public setAccessToken(token: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_ACCESSTOKEN, token, false, true);
//     this.storedAccessToken = Storage.getLocal(
//       this.COOKIE_PARAM_ACCESSTOKEN,
//       false,
//       true
//     );
//   }

//   public setDeepLoginToken(token: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_DEEPLOGINTOKEN, token, false, true);
//     this.storedDeepLoginToken = Storage.getLocal(
//       this.COOKIE_PARAM_DEEPLOGINTOKEN,
//       false,
//       true
//     );
//   }

//   public setKalturaAdminSecret(ks: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_KALTURA_ADMIN_SECRET, ks, false, true);
//     this.storedKalturaAdminSecret = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_ADMIN_SECRET,
//       false,
//       true
//     );
//   }

//   public setKalturaSecret(ks: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_KALTURA_SECRET, ks, false, true);
//     this.storedKalturaSecret = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_SECRET,
//       false,
//       true
//     );
//   }

//   public setKalturaAppToken(token: any) {
//     Storage.saveLocal(this.COOKIE_PARAM_KALTURA_APP_TOKEN, token, false, true);
//     this.storedKalturaAppToken = Storage.getLocal(
//       this.COOKIE_PARAM_KALTURA_APP_TOKEN,
//       false,
//       true
//     );
//   }

//   public setMayWatchOne(val: string) {
//     Storage.saveLocal(this.COOKIE_PARAM_MAYWATCHONE, val, false, true);
//     this.storedMayWatchOne = Storage.getLocal(
//       this.COOKIE_PARAM_MAYWATCHONE,
//       false,
//       true
//     );
//   }

//   public setDocCheckSessionId(val: string) {
//     Storage.saveLocal(this.COOKIE_PARAM_DOCCHECK_SESSION_ID, val, false, true);
//     this.storedDocCheckSessionId = Storage.getLocal(
//       this.COOKIE_PARAM_DOCCHECK_SESSION_ID,
//       false,
//       true
//     );
//   }

//   public setDocCheckCheckSum(val: string) {
//     Storage.saveLocal(this.COOKIE_PARAM_DOCKCHECK_CHECKSUM, val, true, true);
//     this.storedDocCheckCheckSum = Storage.getLocal(
//       this.COOKIE_PARAM_DOCKCHECK_CHECKSUM,
//       true,
//       true
//     );
//   }

//   public setDocCheckUserId(val: string) {
//     Storage.saveLocal(this.COOKIE_PARAM_DOCKCHECK_USERID, val, false, true);
//     this.storedDocCheckUserId = val;
//   }

//   public getTrackingConsentRaw() {
//     if (!this.isGuest()) {
//       return this.getUser().accountProfile;
//     }
//     return null;
//   }

//   public getTrackingConsent() {
//     return true;
//   }

//   public setTrackingConsent(consent: boolean) {
//     if (!this.isGuest()) {
//       let user = Storage.getLocal(this.COOKIE_PARAM_USER, false, true);
//       user.accountProfile = consent
//         ? UserProfile.MMC_PLUS
//         : UserProfile.MMC_BASIC;
//       this.setUser(user);
//     }
//   }

//   public setDraftMode(val: boolean) {
//     let user = Storage.getLocal(this.COOKIE_PARAM_USER, false, true);
//     user.draft_mode = val;
//     this.setUser(user);
//   }

//   public getDraftMode() {
//     return this.getUser().draft_mode;
//   }

//   public isInDraftMode() {
//     if (Store.isProd()) {
//       return false;
//     }

//     if (this.isGuest()) {
//       return false;
//     }

//     return this.getDraftMode();
//   }

//   public getTrackingId() {
//     if (!this.isGuest()) {
//       if (this.isDocCheckAuthorized()) {
//         return this.getUser().idDuO + "_|_" + this.getDocCheckUserId();
//       } else {
//         return this.getUser().idDuO;
//       }
//     }

//     return null;
//   }

//   public getId() {
//     if (!this.isGuest()) {
//       return this.getUser().idDuO;
//     }
//     return null;
//   }

//   public isGuest() {
//     return this.getUser() ? false : true;
//   }

//   public isStatusActive() {
//     // if (!this.isGuest()) {
//     //   return this.getUser().hcpStatus == Store.USER_STATE_ACTIVE;
//     // }

//     return false;
//   }

//   public isStatusPasswordChangeReq() {
//     // if (!this.isGuest()) {
//     //   return this.getUser().hcpStatus == Store.USER_STATE_PASSWORDCHANGEREQ;
//     // }

//     return false;
//   }

//   public hasRoleGuest() {
//     return this.hasRole(this.ROLE_GUEST);
//   }

//   public hasRole(role: string) {
//     if (this.isGuest()) {
//       return false;
//     }

//     return this.getUser().roles.find((r: string) => {
//       return r == role;
//     });
//   }

//   public hasProfile(profile: UserProfile) {
//     if (this.isGuest()) {
//       return false;
//     }

//     return this.getUser().accountProfile == profile;
//   }

//   public static getInstance(): User {
//     if (!this.instance) {
//       this.instance = new User();
//     }
//     return this.instance;
//   }

//   public async login(data: any) {
//     try {
//       let userObj = {
//         title: data.user.title,
//         firstName: data.user.firstName,
//         lastName: data.user.lastName,
//         salutation: data.user.salutation,
//         email: data.user.email,
//         login_with_pw: data.user.login_with_pw,
//         id: data.user.id,
//         idDuO: data.user.idDuO,
//         idVeeva: data.user.idVeeva,
//         segmentations: data.user.segmentations,
//         subTerritory: data.user.subTerritory,
//         accountProfile: data.user.accountProfile,
//         hcpStatus: data.user.hcpStatus,
//         roles: data.user.roles || [],
//         zip: data.user.zip
//       };
//       this.setUser(userObj);
//       this.setAccessToken(data.access_token);
//       this.setRefreshToken(data.refresh_token);
//       await this.startKalturaSession();
//       EventBus.$emit(USER_LOGIN);
//       return true;
//     } catch (e) {
//       return false;
//     }
//   }

//   public async logout() {
//     await this.endKalturaSession();
//     this.deleteRefreshToken();
//     this.deleteUser();
//     this.deleteAccessToken();
//     this.deleteDeepLoginToken();
//     this.deleteMayWatchOne();
//     this.deleteDocCheckUserId();
//     this.deleteDocCheckSessionId();
//     EventBus.$emit(USER_LOGOUT);
//   }

//   public async logoutWithoutDocCheck() {
//     await this.endKalturaSession();
//     this.deleteRefreshToken();
//     this.deleteUser();
//     this.deleteAccessToken();
//     this.deleteDeepLoginToken();
//     this.deleteMayWatchOne();
//     EventBus.$emit(USER_LOGOUT);
//   }

//   public async restartKalturaSession() {
//     await this.endKalturaSession();
//     await this.startKalturaSession();
//   }

//   public async startKalturaSession() {
//     if (!this.isGuest() && Store.hasKalturaSessionInfos()) {
//       await KalturaSession.startUserSession();
//     }
//   }

//   public async endKalturaSession() {
//     await KalturaSession.endUserSession();
//   }

//   public isDocCheckAuthorized() {
//     return this.getDocCheckSessionId() && this.getDocCheckUserId();
//   }
// }

export class UserFactory {
  private static instance?: UserInterface;

  public static getInstance(): UserInterface {
    changeContextByRoute(config);
    if (!this.instance) {
      switch (config.getFrontendConfiguration().context) {
        case FrontendContext.HCP:
          this.instance = new UserHCP();
          break;
        case FrontendContext.PATIENT:
          this.instance = new UserPatient();
          break;
        default:
          this.instance = new UserHCP();
          break;
      }
    }

    return this.instance;
  }
}

//export default User.getInstance();
export default UserFactory.getInstance();
