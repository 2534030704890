import { ICalendar } from "datebook";
// Types
// @ts-ignore
import CalendarOptions from "datebook/src/types/CalendarOptions";
// @ts-ignore
import ICSAlarm from "datebook/src/types/ICSAlarm";
// @ts-ignore
import CalendarAttendee from "datebook/src/types/CalendarAttendee";
// @ts-ignore
import CalendarRecurrence from "datebook/src/types/CalendarRecurrence";

/**
 * Datebook library: https://github.com/jshor/datebook
 * Types: https://github.com/jshor/datebook/tree/master/src/types
 **/

export interface DatebookInterface {
  icalendar: any;
  setOptions(options: CalendarOptions, configInstance?: any): any;
  getOptions(): CalendarOptions | {};
  download(): any;
  setAlarm(options: ICSAlarm): any;
  addEvent(options: CalendarOptions): any;
}

class DatebookConfig {
  public title: string | null;
  public location: string | null;
  public description: string | null;
  public start: Date | null;
  public end: Date | null;
  public attendees: CalendarAttendee[];
  recurrence: CalendarRecurrence | null;
  [key: string]: any;

  constructor() {
    this.title = null;
    this.location = null;
    this.description = null;
    this.start = null;
    this.end = null;
    this.attendees = [];
    this.recurrence = null;
  }
}

export default class Datebook implements DatebookInterface {
  public icalendar!: any;

  // Create config instance
  public datebookConfig = new DatebookConfig();

  constructor(options: CalendarOptions) {
    this.icalendar = null;
    this.setOptions(options);

    // Create ICalendar instance
    if (Object.entries(this.getOptions()).length > 0) {
      this.icalendar = new ICalendar(this.getOptions() as CalendarOptions);
    }
  }

  setOptions(options: CalendarOptions, configInstance?: any) {
    const instance = configInstance || this.datebookConfig;
    Object.keys(options).forEach((item: string) => {
      instance[item] = (options as any)[item];
    });
  }

  getOptions(configInstance?: any): CalendarOptions | {} {
    const instance = configInstance || this.datebookConfig;

    const filteredEntries = Object.entries(instance).filter(
      ([key, value]) => value !== null
    );
    return Object.fromEntries(filteredEntries);
  }

  download() {
    this.icalendar.download();
  }

  /** Set a reminder that shows before the event at a specific time **/
  setAlarm(options: ICSAlarm) {
    if (
      this.icalendar &&
      Object.prototype.hasOwnProperty.call(options, "trigger") &&
      Object.prototype.hasOwnProperty.call(options, "action")
    ) {
      this.icalendar.addAlarm(options);
    }
  }

  /** Add another event to the same .ics file **/
  addEvent(options: CalendarOptions) {
    const config = new DatebookConfig();
    this.setOptions(options, config);

    if (Object.entries(this.getOptions()).length > 0) {
      const event = new ICalendar(this.getOptions(config) as CalendarOptions);
      this.icalendar.addEvent(event);
    }
  }
}
