export class TNMFilterElement {
  public key!: string;
  public value!: string;
}

export interface TNMFilters {
  t: TNMFilterElement;
  n: TNMFilterElement;
  m: TNMFilterElement;
  type: TNMFilterElement;
}

export const TNM_FILTERS_STORAGE_KEY = "tnm_filters";
