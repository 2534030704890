import User, {
  InstitutionRoleName,
  UserGroup,
  UserProfile,
  UserRoleName,
  UserState
} from "./User";

/**
 * Available policies
 * A policy is a combination of
 * group, profile, role and state of a user
 * all values may be set to "any" in case of any possible values
 */
class Policy {
  userGroup!: UserGroup | "any";
  userInstitutionRole!: InstitutionRoleName | "any";
  userProfile!: UserProfile | "any";
  userRoleName!: UserRoleName | "any";
  userState!: UserState | "any";

  constructor(
    group: UserGroup | "any",
    institutionRole: InstitutionRoleName | "any",
    profile: UserProfile | "any",
    role: UserRoleName | "any",
    state: UserState | "any"
  ) {
    this.userGroup = group;
    this.userInstitutionRole = institutionRole;
    this.userProfile = profile;
    this.userRoleName = role;
    this.userState = state;
  }
}

export enum Resources {
  HCP_ACCOUNT = "hcp:account",
  HCP_MWO = "hcp:mwo"
}

/**
 * Available Resource policies that handle
 * if a user has access to a certain resource
 * which is defined via the given policy
 */
class ResourcePolicy {
  resource!: Resources;
  policy!: Policy;

  constructor(resource: Resources, policy: Policy) {
    this.resource = resource;
    this.policy = policy;
  }

  allowAll(): boolean {
    return (
      this.policy.userGroup == "any" &&
      this.policy.userInstitutionRole == "any" &&
      this.policy.userProfile == "any" &&
      this.policy.userRoleName == "any" &&
      this.policy.userState == "any"
    );
  }
}

/**
 * AccessControlManager handles the access control of a user to given resources
 * via all available resource policies
 */
class AccessControlManager {
  private resourcePolicies: ResourcePolicy[] = [
    new ResourcePolicy(
      Resources.HCP_ACCOUNT,
      new Policy(UserGroup.HCP, "any", "any", "any", UserState.ACTIVE)
    ),
    new ResourcePolicy(
      Resources.HCP_MWO,
      new Policy("any", "any", "any", "any", "any")
    ),
    new ResourcePolicy(
      Resources.HCP_ACCOUNT,
      new Policy(
        UserGroup.HCP,
        "any",
        "any",
        UserRoleName.PHARMACIST,
        UserState.ACTIVE
      )
    )
  ];

  userHasAccess(resource: Resources): boolean {
    let resourcePoliciesForResource = this.getResourcePolicies(resource);
    return resourcePoliciesForResource.some(
      (resourcePolicy: ResourcePolicy) => {
        if (resourcePolicy.allowAll()) {
          return true;
        }

        const matchesUserGroup =
          resourcePolicy.policy.userGroup == "any" ||
          User.hasUserGroup(resourcePolicy.policy.userGroup);

        const matchesInstitutionRole =
          resourcePolicy.policy.userInstitutionRole == "any" ||
          User.hasInstitutionRoleName(
            resourcePolicy.policy.userInstitutionRole
          );

        const matchesUserProfile =
          resourcePolicy.policy.userProfile == "any" ||
          User.hasUserProfile(resourcePolicy.policy.userProfile);

        const matchesUserRole =
          resourcePolicy.policy.userRoleName == "any" ||
          User.hasUserRole(resourcePolicy.policy.userRoleName);

        const matchesUserState =
          resourcePolicy.policy.userState == "any" ||
          User.hasUserState(resourcePolicy.policy.userState);

        if (
          matchesUserGroup &&
          matchesInstitutionRole &&
          matchesUserProfile &&
          matchesUserRole &&
          matchesUserState
        ) {
          return true;
        }
      }
    );
  }

  private getResourcePolicies(resource: Resources): any {
    return this.resourcePolicies.filter((el: ResourcePolicy) => {
      return el.resource === resource;
    });
  }
}

export default new AccessControlManager();
