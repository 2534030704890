
import { Component } from "vue-property-decorator";
import { LayoutComponent, Repository, ResultType } from "common";
import BaseComponent from "../base/BaseComponent.vue";
import {
  ArticleListRead,
  ArticleListSaved,
  DatabaseSavedArticleList
} from "common";
import { User, UserLoginStatus } from "common";
import { ViewedArticleRepository } from "common";

interface ViewWidget {
  isVisible: boolean;
  component: LayoutComponent;
}

interface ArticlePublishedEvent {
  isPublished: boolean;
  widgetId: string;
}

@Component
export default class Myarticles extends BaseComponent {
  private savedArticleWidgetIds!: Array<string>;
  private visitedArticleWidgetIds!: Array<string>;

  public savedArticleIds!: Array<string>;
  public visitedArticles!: Array<ViewWidget>;
  public savedArticles!: Array<ViewWidget>;

  constructor() {
    super();
    this.savedArticleWidgetIds = [];
    this.visitedArticleWidgetIds = [];
    this.savedArticleIds = [];
    this.visitedArticles = [];
    this.savedArticles = [];
  }

  async mounted() {
    super.mounted();
    this.getSavedArticleWidgetsFromArticleIds();
    this.getVisitedArticleWidgetsFromArticleIds();
  }

  async getSavedArticleWidgetsFromArticleIds() {
    const resultIds = await this.getSavedArticleIds();

    let result = await Repository.getArticleWidgets(resultIds);

    this.savedArticleWidgetIds = [];
    const articleIds: Array<string> = [];

    if (result.type == ResultType.SUCCESS) {
      if (result.value) {
        result.value
          .sort(
            (v1: any, v2: any) =>
              resultIds.indexOf(v1.cockpit_link._id) -
              resultIds.indexOf(v2.cockpit_link._id)
          )
          .forEach((v: any) => {
            const articleId = v.cockpit_link._id;

            if (!articleIds.includes(articleId)) {
              this.savedArticleWidgetIds.push(v._id);
              articleIds.push(v.cockpit_link._id);
            }
          });

        this.getArticleWidgets(this.savedArticleWidgetIds, this.savedArticles);
      }
    }
  }

  async getVisitedArticleWidgetsFromArticleIds() {
    this.visitedArticleWidgetIds = [];
    let visitedArticles = await this.getViewedArticleIds();

    let result = await Repository.getArticleWidgets(visitedArticles);
    if (result.type == ResultType.SUCCESS) {
      if (result.value) {
        result.value
          .sort(
            (v1: any, v2: any) =>
              visitedArticles.indexOf(v1.cockpit_link._id) -
              visitedArticles.indexOf(v2.cockpit_link._id)
          )
          .forEach((v: any) => {
            this.visitedArticleWidgetIds.push(v._id);
          });

        this.getArticleWidgets(
          this.visitedArticleWidgetIds,
          this.visitedArticles
        );
      }
    }
  }

  private async getSavedArticleIds() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      return ArticleListSaved.list;
    }

    this.savedArticleIds = await DatabaseSavedArticleList.get();

    return this.savedArticleIds;
  }

  private async getViewedArticleIds() {
    if (User.shouldUseLocalStorageToSaveArticles()) {
      return ArticleListRead.list;
    }

    const resultIds = await ViewedArticleRepository.get();

    if (resultIds.type !== ResultType.SUCCESS || !resultIds.value) {
      return [];
    }

    return resultIds.value;
  }

  get has_access() {
    return !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT);
  }

  getArticleWidgets(
    articleWidgetIds: Array<string>,
    componentList: Array<ViewWidget>
  ) {
    articleWidgetIds.forEach((articleId: string) => {
      let comp = LayoutComponent.createEmpty("article_widget", {
        type: "vertical",
        visibility: true,
        content: {
          _id: articleId
        }
      });

      componentList.push({
        isVisible: true,
        component: comp
      });
    });
  }

  setWidgetVisibility(widgetsList: ViewWidget[], event: ArticlePublishedEvent) {
    if (event.isPublished === false) {
      const itemIdx: number = widgetsList.findIndex(
        (item: ViewWidget) =>
          item.component.settings.content._id === event.widgetId
      );

      if (itemIdx !== -1) {
        widgetsList[itemIdx].isVisible = false;
      }
    }
  }
}
