
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImageMap from "image-map";
import { isEmpty, has } from "lodash";
import { RoutingHelper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LCImage extends BaseComponent {
  @Prop() className!: string;
  @Prop({ default: true }) lazyLoad!: boolean;

  public imageMap: object = {};
  public lightboxVisible: boolean = false;

  getComponentType() {
    return "images";
  }

  contentDidLoad() {
    super.contentDidLoad();

    if (!isEmpty(this.metaContent) && has(this.metaContent, "image_map")) {
      this.imageMap = this.metaContent.image_map;
    }

    Vue.nextTick(() => {
      if (Object.entries(this.imageMap).length) {
        ImageMap(`img[usemap="#${(this.imageMap as any).name}"]`);
        // force update
        window.dispatchEvent(new Event("resize"));
      }

      if (this.lazyLoad) {
        // @ts-ignore
        this.lazyLoadInstance.update();
      }
    });
  }

  async mounted() {
    super.mounted();
  }

  get path() {
    let image = this.getContentAttribute("image");
    if (image) {
      return RoutingHelper.getImagePath(image.path);
    }
    return "";
  }

  get title() {
    return this.getContentAttribute("title");
  }

  get metaTitle() {
    const image = this.getContentAttribute("image");
    let result = "";

    if (image) {
      const { path } = image;
      const fileName =
        path && path.length ? path.substring(path.lastIndexOf("/") + 1) : "";

      if (!isEmpty(image.meta) && has(image.meta, "title")) {
        result = image.meta.title || fileName;
      } else {
        result = fileName;
      }
    }

    return result;
  }

  get allCssClasses() {
    let allClasses = [];
    if (this.className) {
      allClasses.push(this.className);
    }

    if (this.layoutComponent.class != "") {
      allClasses.push(this.layoutComponent.class);
    }
    return allClasses.join(" ");
  }

  get customStyles(): string {
    return this.layoutComponent.style;
  }

  get metaContent() {
    return this.getContentAttribute("meta");
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  get lightbox() {
    return this.getContentAttribute("lightbox");
  }

  get alt() {
    return this.getContentAttribute("alt_tag");
  }
}
