
import { Vue, Component } from "vue-property-decorator";
import QRCode from "qrcode";
import _ from "lodash";
import { BaseComponent, LoadingAnimation } from "vue-ui";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  ConfiguratorRepository,
  Helper,
  LayoutComponent,
  ResultType,
  User,
  UserLoginStatus,
  ContentRepository,
  AlgoliaHit
} from "common";
import ConfiguratorProgress1 from "./ConfiguratorProgress1.vue";
import ReviewContent from "./ReviewContent.vue";
import AddContent from "./AddContent.vue";
import { v4 as uuidv4 } from "uuid";
import { notification } from "ant-design-vue";

@Component({
  components: {
    LoadingAnimation,
    ReviewContent
  }
})
export default class Configurator extends BaseComponent {
  /**
   * Current step and progress, the user is in
   */
  public step = 1;

  get progressSection() {
    if (this.step == 1) {
      return 1;
    }

    return this.step - 1;
  }

  /**
   * Chosen by user throughout the process
   */

  // === Progress 1, Step 1-2 ===
  public condition: LayoutComponent | null = null;
  public conditionPageSlug: string = "";
  public indication: string | null = null;
  public indicationId: string | null = null;
  public language: string = "english";
  // ============================

  // ==== Progress 2, Step 3 ====
  public content: any[] = [];
  public preview: any[] = [];
  // ============================

  // ==== Progress 3, Step 4 ====
  public packageCode: string = "";
  public generatedUrl: string = "";
  public generatedQRCode: string = "";
  public generatedQRCodePrint: string = "";
  public copied: boolean = false;
  // ============================

  public contentTab: "review" | "edit" = "review";

  public changeContentTab(tab: "review" | "edit") {
    this.contentTab = tab;
  }

  /**
   * Hardcoded texts
   */

  public titleProgress1 = "Diagnosis";
  public titleProgress2 = "Content Review";
  public titleProgress3 = "Share with Patient";

  public titleStep1 = "1. Diagnosis";
  public titleStep2 = "2. Select Language";
  public titleStep3 = "3. Select Content";

  get titleStep5() {
    return "4. Share With Your Patient";
  }

  get titleStep4() {
    return "4 Results";
  }

  get patientHubLink() {
    return this.generatedUrl;
  }

  get mailLink() {
    const subject = "Give me a subject";
    const body = `
      Content for your patient %0D%0D
      You can find the information page for your patient under the following link: %0D%0D
      ${this.patientHubLink}
    `;

    return `mailto:?subject=${subject}&body=${body}`;
  }

  /**
   * filled by api requests
   */
  public choosableIndications!: LayoutComponent[];
  public isChoosableIndicationsLoading: boolean = false;
  public choosableContent!: any[];
  public indicationsKey!: string;
  public order!: any[];

  public previousSelected!: any;

  public loadingStep1: boolean = false;
  public loadingStep2: boolean = false;
  public loadingStep3: boolean = false;
  public loadingStep4: boolean = false;
  public loadingStep5: boolean = false;
  public loadingPrintPage: boolean = false;
  public printPageComponents: LayoutComponent[] = [];

  constructor() {
    super();
    this.choosableIndications = [];
    this.choosableContent = [];
    this.indicationsKey = "ind-" + Date.now();
    this.previousSelected = null;
  }

  ommitedContent(content: any, key: any): Pick<any, any> {
    return _.omit(content, key);
  }

  updateIndicationsKey() {
    this.indicationsKey = "ind-" + Date.now();
  }

  // ===============================
  // ========== Step 1 =============
  // ===============================
  async mounted() {
    const PageShellElement = document.querySelector(".hcp-hub");
    PageShellElement!.classList.add("hcp_hub_configurator");

    super.mounted();

    await this.getIndications();
  }

  async getIndications() {
    this.loadingStep1 = true;

    this.isChoosableIndicationsLoading = true;
    let result = await ConfiguratorRepository.getIndications();
    this.isChoosableIndicationsLoading = false;

    if (result.type == ResultType.SUCCESS) {
      this.choosableIndications = result.value;
    }

    this.resetToStep1();
    this.loadingStep1 = false;
  }

  indicationLoaded(idx: number, indicationContent: any) {
    this.choosableIndications[idx].settings.title = indicationContent[0].title;
    this.choosableIndications[idx].settings.id = indicationContent[0].id;
  }

  async choseIndication(idx: number) {
    let self = this;

    // if (idx !== 4) {
    //   return;
    // }

    this.choosableIndications.forEach((ind: LayoutComponent, index) => {
      if (index == idx) {
        if (ind.class == "" || ind.class == null) {
          ind.settings.class = "selected";
          self.condition = ind;
          self.indication = ind.settings.title;
          self.indicationId = ind.settings.id;
        } else {
          ind.settings.class = "";
          self.condition = null;
          self.indication = null;
          self.indicationId = null;
        }
      } else {
        ind.settings.class = "";
      }
    });

    // This line triggers re-renders and recall-ing api to get indications again
    // I don't know why it exists here
    // this.updateIndicationsKey();

    if (this.indication) {
      this.step = 2;
      this.content = [];
      await this.getCheckboxes();
    } else {
      this.step = 1;
      this.resetToStep1();
    }
  }
  // ===============================
  // ===============================
  // ===============================

  getSelectedContent() {
    if (!this.choosableContent || !this.choosableContent.length) return [];

    return this.choosableContent[0].order.filter((p: any) =>
      this.content.includes(p._id)
    );
  }

  get selectedContentTitle() {
    if (!this.choosableContent || !this.choosableContent.length) return "";

    return this.choosableContent[0].order
      .filter((p: any) => this.content.includes(p._id))
      .map((p: any) => p.title);
  }

  get indicationHasSubPages() {
    if (!this.choosableContent || !this.choosableContent.length) return false;

    return this.choosableContent[0].order.length > 0;
  }

  get selectedContentPageSlugs() {
    if (!this.choosableContent || !this.choosableContent.length) return [];

    return [
      this.conditionPageSlug,
      ...this.choosableContent[0].order
        .filter((p: any) => this.content.includes(p._id))
        .map((p: any) => p.pageSlug)
    ];
  }
  public selectedPageSlugs: string[] = [];
  public removeSlug(pageSlug: string) {
    this.selectedPageSlugs = this.selectedPageSlugs.filter(p => p !== pageSlug);
    notification.open({
      message: "Article removed from the package",
      class: "manage-content-notification",
      duration: 3,
      description: "",
      placement: "bottom-center"
    });
  }
  public addSlug(pageSlug: string) {
    this.selectedPageSlugs.push(pageSlug);
    notification.open({
      message: "Article added to the package",
      class: "manage-content-notification",
      duration: 3,
      description: "",
      placement: "bottom-center"
    });
  }

  getSelectedContentPageSlugs() {
    if (!this.choosableContent || !this.choosableContent.length) return [];

    return this.choosableContent[0].order
      .filter((p: any) => this.content.includes(p._id))
      .map((p: any) => p.pageSlug);
  }

  // ===============================
  // ========== Step 2 =============
  // ===============================
  async getCheckboxes() {
    const conditionCollection = await ContentRepository.getCollectionItemByTypeAndId(
      "indications",
      [this.condition!.settings.content._id],
      0
    );

    if (conditionCollection.type === ResultType.FAIL) {
      return;
    }

    const conditionCollectionItem = conditionCollection.value[0];

    if (this.indication != null) {
      this.loadingStep2 = true;

      let result = await ConfiguratorRepository.getCheckboxes(
        this.indication,
        this.language
      );

      if (result.type == ResultType.SUCCESS) {
        let orderResult = await ConfiguratorRepository.getOrder(
          [conditionCollectionItem.category] // uniq(result.value.map((c: any) => c.category))
        );
        if (orderResult.type == ResultType.SUCCESS) {
          let chooseableContentTmp = result.value;
          let orderTmp = orderResult.value;

          orderTmp.forEach((oTmp: any) => {
            let resultsForCategory = chooseableContentTmp.filter(
              (cct: any) => cct.category == oTmp.id
            );
            resultsForCategory.forEach((rfc: any) => {
              let oTmpOrderElement = oTmp.order.find(
                (oTmpEl: any) => oTmpEl._id == rfc._id
              );
              oTmp.order[oTmp.order.indexOf(oTmpOrderElement)] = rfc;
            });

            oTmp.order = oTmp.order.filter(
              (oTmpOrder: any) => oTmpOrder.category != undefined
            );
            oTmp.selects = oTmp.order.reduce(
              (acc: any, el: any, idx: number) => {
                if (el.element != "") {
                  let parent = acc.find((p: any) => p.title == el.title);
                  let pIdx = !parent ? acc.length : acc.indexOf(parent);
                  if (!parent) {
                    acc.push({
                      title: el.title,
                      children: {
                        default: []
                      }
                    });
                  }

                  if (el.group != "") {
                    if (!Object.keys(acc[pIdx].children).includes(el.group)) {
                      acc[pIdx].children[el.group] = [];
                    }
                    acc[pIdx].children[el.group].push(el);
                  } else {
                    acc[pIdx].children.default.push(el);
                  }
                }
                return acc;
              },
              []
            );

            oTmp.order = oTmp.order.reduce((acc: any, el: any, idx: number) => {
              if ((el.element && el.element.trim() == "") || !el.element) {
                acc.push(el);
              } else {
                if (!acc.find((accEl: any) => accEl.title == el.title)) {
                  acc.push({
                    title: el.title,
                    select: true
                  });
                }
              }
              return acc;
            }, []);
          });

          orderTmp.sort(function(a: any, b: any) {
            if (a.index > b.index) return 1;
            if (a.index < b.index) return -1;
            return 0;
          });

          this.conditionPageSlug = orderTmp[0].order[0].pageSlug;
          orderTmp[0].order = orderTmp[0].order.slice(1);

          this.choosableContent = orderTmp;
        }
      }
      this.loadingStep2 = false;
    }
  }

  setPreviousSelected(e: Event) {
    this.previousSelected = (e.target as HTMLSelectElement).value;
  }

  selectContentFromOption(e: Event) {
    // this.step = 2;
    // this.resetToStep2();
    if (this.step !== 2) return;

    if (this.previousSelected) {
      this.content.splice(this.content.indexOf(this.previousSelected), 1);
    }

    if ((e.target as HTMLSelectElement).value) {
      this.content.push((e.target as HTMLSelectElement).value);
    }
  }

  selectContent(id: string, e: Event) {
    if (this.step !== 2) return;

    if ((e.target as HTMLInputElement).checked) {
      // this.content.push(id);
      this.content = [id];
    } else {
      // this.content.splice(this.content.indexOf(id), 1);
      this.content = [];
    }
  }
  // ===============================
  // ===============================
  // ===============================

  // ===============================
  // ========== Step 3 =============
  // ===============================
  async getContent() {
    this.loadingStep3 = true;
    let result = await ConfiguratorRepository.getContent(this.content);

    if (result.type == ResultType.SUCCESS) {
      this.preview = result.value;
      this.step = 3;
    }
    this.loadingStep3 = false;
    this.addEventListener();
  }

  addEventListener() {
    Vue.nextTick(() => {
      Helper.crawlDomAndAddEventlistenerToAllLinks();
    });
  }
  // ===============================
  // ===============================
  // ===============================

  // ===============================
  // ========== Step 4 =============
  // ===============================
  async create() {
    this.step = 4;

    this.loadingStep4 = true;

    const packageCode = uuidv4();
    this.generatedUrl = `https://cancersupportplus.com/package-view?code=${packageCode}`;

    let result = await ConfiguratorRepository.createPackage(
      packageCode,
      this.selectedPageSlugs.join(" ")
    );

    if (result.type == ResultType.SUCCESS) {
      await this.qrCode();
    } else {
      this.step = 3;
      this.resetToStep3();
    }

    this.loadingStep4 = false;
  }

  async qrCode() {
    this.generatedQRCode = await QRCode.toDataURL(this.patientHubLink, {
      scale: 6,
      margin: 0,
      color: {
        dark: "#000",
        light: "#F9FAF6"
      }
    });

    this.generatedQRCodePrint = await QRCode.toDataURL(this.patientHubLink, {
      scale: 6,
      margin: 0,
      color: {
        dark: "#000",
        light: "#fff"
      }
    });
  }
  // ===============================
  // ===============================
  // ===============================

  public algoliaHitsFilteredBySelectedContentPageSlugs: AlgoliaHit[] = [];

  get nextButtonText() {
    if (this.step <= 2) return "Preview Content";
    if (this.step === 3) return "Share Content";
    if (this.step === 4) return "Start a New Package";

    return "";
  }

  async handleGoToNextProgressSection() {
    if (this.step === 2) {
      this.selectedPageSlugs = [...this.selectedContentPageSlugs];
      this.contentTab = "review";
      await this.getContent();
      return;
    }

    if (this.step === 3) {
      await this.create();
      return;
    }

    if (this.step === 4) {
      this.step = 1;
      this.choosableIndications.forEach(p => (p.settings.class = ""));
      this.resetToStep1();
    }
  }

  async handleGoToPreviousProgressSection() {
    if (this.step === 1 || this.step === 2) {
      this.step = 1;
      this.resetToStart();
      return;
    }

    if (this.step === 3) {
      this.step = 2;
      this.resetToStep2();
      return;
    }

    if (this.step === 4) {
      this.step = 3;
      this.resetToStep3();
      return;
    }
  }

  resetToStart() {
    this.$router.push("patient-content-config-start");
  }

  resetToStep1() {
    this.condition = null;
    this.indication = null;
    this.indicationId = null;
    this.choosableContent = [];
    this.conditionPageSlug = "";
    this.content = [];
    this.resetToStep2();
  }

  resetToStep2() {
    this.preview = [];
    this.selectedPageSlugs = [this.conditionPageSlug];
    this.resetToStep3();
  }

  resetToStep3() {
    this.packageCode = "";
    this.generatedUrl = "";
    this.generatedQRCode = "";
  }

  toggleElement(id: string) {
    let el = document.getElementById(id);
    let elToggler = document.getElementById("t-" + id);

    if (el != null) {
      el.classList.toggle("d-none");
    }

    if (elToggler != null) {
      elToggler.classList.toggle("active");
    }
  }

  copyToClipboard() {
    const el = document.createElement("textarea");
    el.value = this.patientHubLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.copied = true;
    let self = this;
    setTimeout(function() {
      self.copied = false;
    }, 700);
  }

  async printPage() {
    this.loadingPrintPage = true;

    await Vue.nextTick(async () => {
      const pageWidth = 210;
      const pageHeight = 297;

      const heroHeadlineEl = document.querySelector(
        "div.hero-headline"
      ) as HTMLElement;
      const heroHeadlineElArea = heroHeadlineEl.getBoundingClientRect();

      const heroHeadlineCanvas = await html2canvas(heroHeadlineEl, {
        backgroundColor: null,
        width: heroHeadlineElArea.width,
        height: heroHeadlineElArea.height,
        scrollX: 0,
        scrollY: -window.scrollY,
        onclone: (clonedDocument: any) => {
          const headline = clonedDocument.querySelector("div.hero-headline");
          headline.innerHTML = this.indication;
        }
      });
      const heroHeadline = heroHeadlineCanvas.toDataURL("image/png");
      const heroHeadlineWidth = 162;
      const heroHeadlineHeight =
        (heroHeadlineCanvas.height * heroHeadlineWidth) /
        heroHeadlineCanvas.width;

      const doc = new jsPDF({ orientation: "p", unit: "mm", format: "a4" });
      let pageBackgroundImg = document.createElement("img");
      let qrCodeImg = document.createElement("img");

      pageBackgroundImg.src =
        "/assets/patient-cockpit/patient_cockpit_print_bg.png";
      qrCodeImg.src = this.generatedQRCodePrint;

      // Add page background image & hero headline
      doc.addImage(pageBackgroundImg, "PNG", 0, 0, pageWidth, pageHeight);
      doc.addImage(
        heroHeadline,
        "PNG",
        25,
        37,
        heroHeadlineWidth,
        heroHeadlineHeight
      );

      // Add patient hub link
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      doc.setTextColor("#882474");
      doc.textWithLink(this.patientHubLink, 15.5, 113, {
        url: this.patientHubLink
      });

      // Add QR Code image
      doc.addImage(qrCodeImg, "PNG", 15.5, 148, 31, 31);

      await doc.save("patienteninformationen.pdf");

      this.loadingPrintPage = false;
    });
  }

  get has_access() {
    return !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT);
  }
}
