import { Store } from "../util/Store";
import router from "../router";
import User, { UserProfile } from "../models/User";

/** Not yet used */
export enum MatomoCampaignQueryParameters {
  pk_campaign,
  pk_kwd,
  pk_source,
  pk_medium,
  pk_content,
  pk_cid,
  pk_group,
  pk_placement
}

export enum MatomoEventNames {
  CLICK_LINK = "Click_Link",
  CLICK_BANNER = "Click_Banner",
  CLICK_PRESENTATION = "Click_Presentation",
  PLAY_YOUTUBE_VIDEO = "Play_Youtube_Video",
  STOP_YOUTUBE_VIDEO = "Stop_Youtube_Video",
  COMPLETE_YOUTUBE_VIDEO = "Complete_Youtube_Video",
  BOOKMARK_VIDEO = "Bookmark_Video",
  BOOKMARK_ARTICLE = "Bookmark_Article",
  BOOKMARK_3D_MODEL = "Bookmark_3D_Model",
  BOOKMARK_INFORMATION_PORTAL = "Bookmark_External_Link",
  BOOKMARK_INFORMATION_MATERIAL = "Bookmark_Information_Material",
  BOOKMARK_DELETE_ALL = "Bookmark_Delete_All",
  ACCOUNT_SIGNUP_PATIENT = "Account_Sign-Up_Patient",
  ACCOUNT_LOGIN_PATIENT = "Account_Login_Patient",
  ACCOUNT_LOGIN_HCP = "Account_Login_HCP",
  ACCOUNT_DELETE = "Delete_Profile",
  CLICK_BUTTON_CANCER_STADIUM = "Click_Button_Stadium_2D",
  CLICK_BUTTON_CANCER_TYPE = "Click_Button_Status_2D",
  CLICK_ARTICLE_WIDGET_BUTTON = "Click_Button_Article",
  CLICK_BK_3D_LINK = "Click_Button_2D_to_3D_Modell",
  CLICK_DOWNLOAD = "Download_PDF",
  SELECT_TNM_CLASSIFICATION = "Search_TNM_Classification_2D",
  SELECT_TNM_SUBTYPE = "Select_TNM_Subtype_2D",
  SELECT_TNM_STADIUM = "Select_Stadium_2D",
  QUIZ_START = "Quiz_Start",
  QUIZ_CORRECT_ANSWER = "Quiz_Correct_Answer",
  QUIZ_WRONG_ANSWER = "Quiz_Wrong_Answer",
  QUIZ_FINISH = "Quiz_Finish",
  SHARE_FACEBOOK = "Sharing_Facebook",
  SHARE_WHATSAPP = "Sharing_WhatsApp",
  SHARE_EMAIL = "Sharing_EMail",
  SHARE_LINK = "Sharing_Link",
  KALTURA_PAGE_VIEW = "Kaltura_Page_View",
  KALTURA_VIDEO_PLAY = "Kaltura_Video_Play",
  KALTURA_VIDEO_FINISHED = "Kaltura_Video_Finished",
  KALTURA_VIDEO_VIEW_TIME = "Kaltura_Video_View_Time",
  KALTURA_VIDEO_COMPLETION_RATE = "Kaltura_Video_Completion_Rate",
  EXPERT_FORUM_REGISTER = "Expert_Forum_Register",
  EXPERT_FORUM_SEND_QUESTION = "Expert_Forum_Send_Question"
}

export class MatomoConstants {
  public static SET_CUSTOM_VAR = "setCustomVariable";
  public static DEL_CUSTOM_VAR = "deleteCustomVariable";
  public static PAGE = "page";
  public static VISIT = "visit";

  public static CONTENT_ID = "contentId";
  public static CONTENT_ID_NUMBER = 1;

  public static CONTENT_VERSION = "contentVersion";
  public static CONTENT_VERSION_NUMBER = 2;

  public static CONTENT_TITLE = "contentTitle";
  public static CONTENT_TITLE_NUMBER = 3;

  public static CONTENT_PRODUCT_ID = "productId";
  public static CONTENT_PRODUCT_ID_NUMBER = 4;

  public static CONTENT_PERSONA = "persona";
  public static CONTENT_PERSONA_NUMBER = 5;

  public static CONTENT_CAMPAIGN_ID = "campaignId";
  public static CONTENT_CAMPAIGN_ID_NUMBER = 6;

  public static CONTENT_SUBTERRITORY_ID = "subterritoryId";
  public static CONTENT_SUBTERRITORY_ID_NUMBER = 7;

  public static CONTENT_TAGS = "tags";
  public static CONTENT_TAGS_NUMBER = 8;

  public static CONTENT_ROOT_ID = "rootId";
  public static CONTENT_ROOT_ID_NUMBER = 9;

  /*
   * the following custom variables (10 - 18) came
   * in ticket https://redmine.kf-interactive.com/issues/12431
   */

  public static CONTENT_INDICATION = "indication";
  public static CONTENT_INDICATION_NUMBER = 10;

  public static CONTENT_INDICATION_SPECIFIC_TAGS = "indication_specific_tags";
  public static CONTENT_INDICATION_SPECIFIC_TAGS_NUMBER = 11;

  public static CONTENT_TAGS_ACROSS_INDICATIONS = "tags_across_indications";
  public static CONTENT_TAGS_ACROSS_INDICATIONS_NUMBER = 12;

  public static CONTENT_SUBJECT_TARGET_GROUP = "subject_target_group";
  public static CONTENT_SUBJECT_TARGET_GROUP_NUMBER = 13;

  public static CONTENT_ROLE_TARGET_GROUP = "role_target_group";
  public static CONTENT_ROLE_TARGET_GROUP_NUMBER = 14;

  public static CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE =
    "customer_adoption_ladder_phase";
  public static CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE_NUMBER = 15;

  public static CONTENT_CUSTOMER_ADOPTION_SCORE = "customer_adoption_score";
  public static CONTENT_CUSTOMER_ADOPTION_SCORE_NUMBER = 16;

  public static CONTENT_CHANNEL = "channel";
  public static CONTENT_CHANNEL_NUMBER = 17;

  // needs clarification
  // public static CONTENT_SEGMENTS = "segments";
  // public static CONTENT_SEGMENTS_NUMBER = 18;

  public static CONTENT_I_AM_STILL_FREE_19 = "RENAME ME WHENEVER YOU USE ME";
  public static CONTENT_I_AM_STILL_FREE_NUMBER_19 = 19;

  public static CONTENT_I_AM_STILL_FREE_20 = "RENAME ME WHENEVER YOU USE ME";
  public static CONTENT_I_AM_STILL_FREE_NUMBER_20 = 20;
}

export class MatomoParam {
  public customType!: string;
  public id!: number;
  public contentType!: string;
  public value!: string;
  public monitorType!: string;

  constructor(
    customType: string,
    id: number,
    contentType: string,
    value: string,
    monitorType: string
  ) {
    this.customType = customType;
    this.id = id;
    this.contentType = contentType;
    this.value = value;
    this.monitorType = monitorType;
  }

  public static createInstance(monitorType: string, value: string) {
    if (monitorType == MatomoConstants.CONTENT_SUBTERRITORY_ID) {
      return this.createSubTerritory(value);
    }
  }

  private static createSubTerritory(value: string) {
    return new MatomoParam(
      MatomoConstants.SET_CUSTOM_VAR,
      MatomoConstants.CONTENT_SUBTERRITORY_ID_NUMBER,
      MatomoConstants.CONTENT_SUBTERRITORY_ID,
      value,
      MatomoConstants.VISIT
    );
  }

  // TODO - add other factory methods here if necessary

  public toArray() {
    return [
      this.customType,
      this.id,
      this.contentType,
      this.value,
      this.monitorType
    ];
  }
}

class Matomo {
  private static instance?: Matomo;

  readonly MATOMO_EVENT_CATEGORY =
    process.env.VUE_APP_MATOMO_EVENT_CATEGORY || "WebWorld_Unbranded";

  /*
   * to be able to use more than 10 custom variables
   * see https://matomo.org/faq/how-to/faq_17931/
   */

  constructor() {
    if (Store.matomoEnabled()) {
      document.addEventListener("cookiechange", Matomo.handleConsentChange);
    }
  }

  public trackView(post: any) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    const Piwik = window.Piwik || null;
    const tracker = Piwik
      ? Piwik.getTracker(
          process.env.VUE_APP_MATOMO_HOST + "/matomo.php",
          process.env.VUE_APP_MATOMO_SITE_ID
        )
      : null;

    if (this.shouldTriggerEvent() && tracker) {
      // Set content id
      tracker.setCustomVariable(
        MatomoConstants.CONTENT_ID_NUMBER,
        MatomoConstants.CONTENT_ID,
        post._id,
        MatomoConstants.PAGE
      );

      // Set content version
      tracker.setCustomVariable(
        MatomoConstants.CONTENT_VERSION_NUMBER,
        MatomoConstants.CONTENT_VERSION,
        post._modified,
        MatomoConstants.PAGE
      );

      // Set content title (post.id is a user defined field)
      // Other possible fields instead:
      // - post.seo_title
      // - post.slug
      // - post.name
      tracker.setCustomVariable(
        MatomoConstants.CONTENT_TITLE_NUMBER,
        MatomoConstants.CONTENT_TITLE,
        post.id,
        MatomoConstants.PAGE
      );

      // Set document title
      tracker.setDocumentTitle(post.id);

      // Set products as comma separated string
      if ("products" in post && post.products) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_PRODUCT_ID_NUMBER,
          MatomoConstants.CONTENT_PRODUCT_ID,
          post.products
            .map(function(p: any) {
              return p.display;
            })
            .join(","),
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(MatomoConstants.CONTENT_PRODUCT_ID_NUMBER);
      }

      // Set personas as comma separated string
      if ("personas" in post && post.personas) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_PERSONA_NUMBER,
          MatomoConstants.CONTENT_PERSONA,
          post.personas
            .map(function(p: any) {
              return p.display;
            })
            .join(","),
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(MatomoConstants.CONTENT_PERSONA_NUMBER);
      }

      // Set campaign id and campaign step
      if ("campaign_id" in post && post.campaign_id) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_CAMPAIGN_ID_NUMBER,
          MatomoConstants.CONTENT_CAMPAIGN_ID,
          post.campaign_id + " - " + post.campaign_step,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_CAMPAIGN_ID_NUMBER
        );
      }

      // Set tags as comma separated string
      if ("tags" in post && post.tags) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_TAGS_NUMBER,
          MatomoConstants.CONTENT_TAGS,
          post.tags
            .map(function(t: any) {
              return t.display;
            })
            .join(","),
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(MatomoConstants.CONTENT_TAGS_NUMBER);
      }

      /*
       * the following custom variables (10 - 18) came
       * in ticket https://redmine.kf-interactive.com/issues/12431
       */

      // indication
      if (MatomoConstants.CONTENT_INDICATION in post && post.indication) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_INDICATION_NUMBER,
          MatomoConstants.CONTENT_INDICATION,
          post.indication,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(MatomoConstants.CONTENT_INDICATION_NUMBER);
      }

      // indication specific tags
      if (
        MatomoConstants.CONTENT_INDICATION_SPECIFIC_TAGS in post &&
        post.indication_specific_tags
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_INDICATION_SPECIFIC_TAGS_NUMBER,
          MatomoConstants.CONTENT_INDICATION_SPECIFIC_TAGS,
          post.indication_specific_tags,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_INDICATION_SPECIFIC_TAGS_NUMBER
        );
      }

      // tags across indications
      if (
        MatomoConstants.CONTENT_TAGS_ACROSS_INDICATIONS in post &&
        post.tags_across_indications
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_TAGS_ACROSS_INDICATIONS_NUMBER,
          MatomoConstants.CONTENT_TAGS_ACROSS_INDICATIONS,
          post.tags_across_indications,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_TAGS_ACROSS_INDICATIONS_NUMBER
        );
      }

      // subject target group
      if (
        MatomoConstants.CONTENT_SUBJECT_TARGET_GROUP in post &&
        post.subject_target_group
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_SUBJECT_TARGET_GROUP_NUMBER,
          MatomoConstants.CONTENT_SUBJECT_TARGET_GROUP,
          post.subject_target_group,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_SUBJECT_TARGET_GROUP_NUMBER
        );
      }

      // role target group
      if (
        MatomoConstants.CONTENT_ROLE_TARGET_GROUP in post &&
        post.role_target_group
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_ROLE_TARGET_GROUP_NUMBER,
          MatomoConstants.CONTENT_ROLE_TARGET_GROUP,
          post.role_target_group,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_ROLE_TARGET_GROUP_NUMBER
        );
      }

      // customer adoption ladder phase
      if (
        MatomoConstants.CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE in post &&
        post.customer_adoption_ladder_phase
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE_NUMBER,
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE,
          post.customer_adoption_ladder_phase,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_LADDER_PHASE_NUMBER
        );
      }

      // customer adoption score
      if (
        MatomoConstants.CONTENT_CUSTOMER_ADOPTION_SCORE in post &&
        post.customer_adoption_score
      ) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_SCORE_NUMBER,
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_SCORE,
          post.customer_adoption_score,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(
          MatomoConstants.CONTENT_CUSTOMER_ADOPTION_SCORE_NUMBER
        );
      }

      // channel
      if (MatomoConstants.CONTENT_CHANNEL in post && post.channel) {
        tracker.setCustomVariable(
          MatomoConstants.CONTENT_CHANNEL_NUMBER,
          MatomoConstants.CONTENT_CHANNEL,
          post.channel,
          MatomoConstants.PAGE
        );
      } else {
        tracker.deleteCustomVariable(MatomoConstants.CONTENT_CHANNEL_NUMBER);
      }

      tracker.trackPageView(post.id);
    }
  }

  public trackArticleView(post: any) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      paq.push([
        "trackEvent",
        process.env.VUE_APP_MATOMO_EVENT_CATEGORY,
        "View_Article",
        post._id
      ]);
    }
  }

  public static handleConsentChange(ev: Event) {
    const previousConsentValue = Matomo.parseConsentCookie(
      (ev as CustomEvent).detail.oldValue
    );
    const currentConsentValue = User.getTrackingConsent();

    if (previousConsentValue !== currentConsentValue) {
      if (currentConsentValue) {
        Matomo.optUserIn();
      } else {
        Matomo.optUserOut();
      }
    }
  }

  private static parseConsentCookie(changedCookies: string) {
    if (changedCookies) {
      const cookies = changedCookies.split(";");
      const wscrCookies = cookies.find(cookie =>
        cookie.trim().startsWith("wscr")
      );

      if (wscrCookies) {
        const isCookieConsentGiven = wscrCookies.split("&")[1].endsWith("true");
        return isCookieConsentGiven;
      }
    }

    return false;
  }

  public updateVariables(optionalParam?: MatomoParam) {
    if (!Store.matomoEnabled()) {
      return;
    }

    // @ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      paq.push(["setCustomUrl", router.currentRoute.fullPath]);

      if (User.hasUserProfile(UserProfile.MMC_PLUS)) {
        paq.push([
          MatomoConstants.SET_CUSTOM_VAR,
          MatomoConstants.CONTENT_SUBTERRITORY_ID_NUMBER,
          MatomoConstants.CONTENT_SUBTERRITORY_ID,
          User.getProperty("subTerritory"),
          MatomoConstants.VISIT
        ]);
        paq.push(["setUserId", User.getProperty("matomoTrackingId")]);
      }

      if (Object.keys(router.app.$route.query).indexOf("rootid") != -1) {
        paq.push([
          MatomoConstants.SET_CUSTOM_VAR,
          MatomoConstants.CONTENT_ROOT_ID_NUMBER,
          MatomoConstants.CONTENT_ROOT_ID,
          router.app.$route.query.rootid,
          MatomoConstants.VISIT
        ]);
      }

      if (optionalParam) {
        paq.push(optionalParam.toArray());
      }
    }
  }

  public playPodcast(id: string, category: string) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      paq.push(["trackEvent", category, "Play_Podcast", id]);
    }
  }

  public stopPodcast(id: string, category: string, playtime: number) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      paq.push(["trackEvent", category, "Stop_Podcast", id, playtime]);
    }
  }

  public youtubeVideoEvents(
    id: string,
    category: string,
    eventName: MatomoEventNames,
    details?: string | number
  ) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      if (details === undefined) {
        paq.push(["trackEvent", category, eventName, id]);
      } else {
        paq.push(["trackEvent", category, eventName, id, details]);
      }
    }
  }

  public quizQuestionEvent(
    id: string,
    category: string,
    eventName: MatomoEventNames,
    details: number
  ) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (User.getTrackingConsent()) {
      paq.push(["setConsentGiven"]);
    }

    paq.push(["trackEvent", category, eventName, id, details]);
  }

  public genericDetailedEvent(
    id: string,
    category: string,
    eventName: MatomoEventNames,
    details: string | number
  ) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (User.getTrackingConsent()) {
      paq.push(["setConsentGiven"]);
    }

    paq.push(["trackEvent", category, eventName, id, details]);
  }

  public static optUserIn() {
    //@ts-ignore
    let paq = window._paq || [];
    /**
     * https://developer.matomo.org/guides/tracking-javascript-guide
     * Check if user opted out and re-opt in.
     */
    paq.push([
      function() {
        //@ts-ignore
        if (this.isUserOptedOut()) {
          //@ts-ignore
          this.forgetUserOptOut();
        }
      }
    ]);

    paq.push(["setConsentGiven"]);
    paq.push(["setCookieConsentGiven"]);
  }

  public static optUserOut() {
    //@ts-ignore
    let paq = window._paq || [];
    paq.push(["forgetConsentGiven"]);
    paq.push(["forgetCookieConsentGiven"]);
    paq.push(["optUserOut"]);
  }

  public click(id: string, category: string, description: string) {
    if (!Store.matomoEnabled()) {
      return;
    }

    //@ts-ignore
    let paq = window._paq || [];

    if (this.shouldTriggerEvent()) {
      paq.push(["trackEvent", category, description, id]);
    }
  }

  public genericEvent(id: string, category: string, eventName: string) {
    this.click(id, category, eventName);
  }

  public static getInstance(): Matomo {
    if (!this.instance) {
      this.instance = new Matomo();
    }
    return this.instance;
  }

  private shouldTriggerEvent() {
    if (!Store.matomoEnabled()) {
      return false;
    }

    //@ts-ignore
    const paq = window._paq || [];

    paq.push(["requireConsent"]);
    paq.push(["requireCookieConsent"]);
    if (User.getTrackingConsent()) {
      paq.push(["setConsentGiven"]);
      paq.push(["setCookieConsentGiven"]);
      return true;
    }

    return false;
  }
}

export default Matomo.getInstance();
