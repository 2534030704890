
import BaseComponent from "../../base/BaseComponent.vue";
import {
  EventBus,
  TNM_FILTER_RESETTED,
  TNM_FILTER_SUBMITTED,
  RECEPTOR_FILTER_SUBMITTED,
  matomo,
  MatomoEventNames,
  Storage,
  TNMFilterElement,
  TNM_FILTERS_STORAGE_KEY
} from "common";
import validator from "validator";
import { Component } from "vue-property-decorator";

@Component
export default class TnmFilter extends BaseComponent {
  public t!: TNMFilterElement[];
  public n!: TNMFilterElement[];
  public m!: TNMFilterElement[];
  public type!: TNMFilterElement[];

  public selectedValues!: { [key: string]: TNMFilterElement };

  constructor() {
    super();

    this.t = [
      { key: "Tis", value: "Tis" },
      { key: "T0", value: "T0" },
      { key: "T1", value: "T1" },
      { key: "T2", value: "T2" },
      { key: "T3", value: "T3" },
      { key: "T4", value: "T4" }
    ];

    this.n = [
      { key: "Nmi", value: "Nmi" },
      { key: "N0", value: "N0" },
      { key: "N1", value: "N1" },
      { key: "N2", value: "N2" },
      { key: "N3", value: "N3" }
    ];

    this.m = [
      { key: "M0", value: "M0" },
      { key: "M1", value: "M1" }
    ];

    this.type = [
      { key: "tnbc", value: "TNBC" },
      { key: "hrplus", value: "HR+" },
      { key: "her2plus", value: "HER2+" },
      { key: "hrplusher2plus", value: "HR+/HER2+" },
      { key: "no_selection", value: "keine Selektion" }
    ];

    this.selectedValues = {
      t: { key: "", value: "T" },
      n: { key: "", value: "N" },
      m: { key: "", value: "M" },
      type: { key: "-", value: "Rezeptorstatus" }
    };

    Storage.deleteLocal(TNM_FILTERS_STORAGE_KEY, false);
  }

  getComponentType() {
    return "";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
  }

  beforeDestroy() {
    Storage.deleteLocal(TNM_FILTERS_STORAGE_KEY, false);
  }

  get validateT() {
    return !validator.isEmpty(this.selectedValues.t.key);
  }

  get validateN() {
    return !validator.isEmpty(this.selectedValues.n.key);
  }

  get validateM() {
    return !validator.isEmpty(this.selectedValues.m.key);
  }

  get validateType() {
    switch (this.selectedValues.type.key) {
      case "tnbc":
      case "hrplus":
      case "her2plus":
      case "hrplusher2plus":
        return true;
      case "no_selection":
      default:
        return false;
    }
  }

  get showResetButton() {
    return this.validateType || this.validateT;
  }

  selectValue(type: string, element: TNMFilterElement) {
    this.selectedValues[type] = element;

    // Matomo tracking
    switch (type) {
      case "t":
      case "n":
      case "m":
        matomo.genericEvent(
          element.value,
          matomo.MATOMO_EVENT_CATEGORY,
          MatomoEventNames.SELECT_TNM_CLASSIFICATION
        );
        break;
      case "type":
      default:
        matomo.genericEvent(
          element.value,
          matomo.MATOMO_EVENT_CATEGORY,
          MatomoEventNames.SELECT_TNM_SUBTYPE
        );
        break;
    }
  }

  setDefaultValues() {
    if (!this.validateN || !this.validateM) {
      switch (this.selectedValues.t.key) {
        case "Tis":
        case "T1":
        case "T2":
        case "T3":
        case "T4": {
          if (!this.validateN) {
            this.selectValue("n", this.n[1]);
          }

          if (!this.validateM) {
            this.selectValue("m", this.m[0]);
          }
          break;
        }
        case "T0": {
          if (!this.validateN) {
            this.selectValue("n", this.n[1]);
          }

          if (!this.validateM) {
            this.selectValue("m", this.m[1]);
          }
          break;
        }
        default:
          return;
      }
    }
  }

  submit() {
    if (this.validateT) {
      this.setDefaultValues();
      Storage.saveLocal(
        TNM_FILTERS_STORAGE_KEY,
        JSON.stringify(this.selectedValues),
        false,
        false
      );
      this.selectTab("stages");
      EventBus.$emit(TNM_FILTER_SUBMITTED, this.selectedValues);
    }

    if (this.validateType) {
      this.selectedValues.type = this.mapReceptorType(this.selectedValues.type);
      Storage.saveLocal(
        TNM_FILTERS_STORAGE_KEY,
        JSON.stringify(this.selectedValues),
        false,
        false
      );

      // Switch to receptors tab only if we don't have stages selected
      if (!this.validateT) {
        // Reset the TNM values
        this.selectedValues = {
          ...this.selectedValues,
          t: { key: "", value: "T" },
          n: { key: "", value: "N" },
          m: { key: "", value: "M" }
        };

        this.selectTab("receptors");
      }
      EventBus.$emit(RECEPTOR_FILTER_SUBMITTED, this.selectedValues);
    }
  }

  /**
   * Remove the `+` character so we can scroll to the receptor
   */
  mapReceptorType(receptor: TNMFilterElement): TNMFilterElement {
    const formattedReceptor: TNMFilterElement = { ...receptor };
    const receptorLength = receptor.value.length;
    switch (receptor.key) {
      case "hrplus":
      case "her2plus": {
        formattedReceptor.value = formattedReceptor.value.substring(
          0,
          receptorLength - 1
        );
        break;
      }
      case "hrplusher2plus": {
        formattedReceptor.value = formattedReceptor.value
          .split("/")
          .map((value: string) => {
            const len = value.length;
            return value.substring(0, len - 1);
          })
          .join("/");
        break;
      }
    }

    return formattedReceptor;
  }

  /**
   * If the stages and receptors are placed in a tab bar,
   * assign the "stages-and-receptors" id to the tab bar.
   * <!> The order should be:
   *  - 1st tab stages
   *  - 2nd tab receptors
   * Toggle between the tabs
   */
  selectTab(which: "stages" | "receptors") {
    const tabbar = document.querySelector("#stages-and-receptors");

    if (tabbar) {
      const tabItems = tabbar.querySelector(".tabbar__items");
      if (tabItems) {
        switch (which) {
          case "stages": {
            (tabItems.firstElementChild as HTMLElement).click();
            break;
          }
          case "receptors": {
            (tabItems.lastElementChild as HTMLElement).click();
            break;
          }
          default:
            (tabItems.firstElementChild as HTMLElement).click();
            break;
        }
      }
    }
  }

  reset() {
    this.selectedValues = {
      t: { key: "", value: "T" },
      n: { key: "", value: "N" },
      m: { key: "", value: "M" },
      type: { key: "-", value: "Rezeptorstatus" }
    };
    Storage.deleteLocal(TNM_FILTERS_STORAGE_KEY, false);
    EventBus.$emit(TNM_FILTER_RESETTED);
  }
}
