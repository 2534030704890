
import { Component, Vue } from "vue-property-decorator";
import { config, ConfigurationEnvironment, User, CryptoHelper } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class DocCheckLogin extends BaseComponent {
  checkSum!: string;
  encrpytedSessionId!: string;
  encryptionKey!: string;

  readonly reference: string = "dc_login_iframe";

  constructor() {
    super();
    this.checkSum = CryptoHelper.generateUniqueKey();
    this.encrpytedSessionId = "";
    this.encryptionKey = CryptoHelper.generateUniqueKey(3);
  }

  getComponentType() {
    return "doc_check_logins";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      this.encrpytedSessionId = CryptoHelper.encrypt(
        this.checkSum,
        this.encryptionKey
      );
      // User.setDocCheckCheckSum(this.checkSum);
      User.updateAndSaveToStorage({
        docCheckCheckSum: this.checkSum
      });
    });
  }

  get id() {
    return this.getContentAttribute("id");
  }

  /**
   * https://login.doccheck.com/code/de/2000000016971/login_xl/
   */
  get login_url() {
    let url = this.loginUrlForEnvironment; //this.getContentAttribute("iframe_url") as string;

    if (!url) {
      return "";
    }

    /* Add session id to iframe url */
    url += !url.endsWith("/") ? "/" : "";
    url += `session_id=${this.encrpytedSessionId}/dccr=${this.encryptionKey}`;

    return url;
  }

  get iFrameSize() {
    let sizes: { [key: string]: any } = {
      login_s: {
        width: "156",
        height: "203"
      },
      login_m: {
        width: "311",
        height: "188"
      },
      login_l: {
        width: "424",
        height: "215"
      },
      login_xl: {
        width: "467",
        height: "231"
      }
    };

    let url = this.login_url;

    for (let key of Object.keys(sizes)) {
      if (url.includes(key)) {
        return sizes[key];
      }
    }

    // default size
    return sizes.login_s;
  }

  get login_url_local() {
    return this.getContentAttribute("login_url_local") as string;
  }

  get login_url_dev() {
    return this.getContentAttribute("login_url_dev") as string;
  }

  get login_url_test() {
    return this.getContentAttribute("login_url_test") as string;
  }

  get login_url_stage() {
    return this.getContentAttribute("login_url_stage") as string;
  }

  get login_url_prod() {
    return this.getContentAttribute("login_url_prod") as string;
  }

  get loginUrlForEnvironment() {
    switch (config.getEnv()) {
      case ConfigurationEnvironment.PRODUCTION:
        return this.login_url_prod;
      case ConfigurationEnvironment.STAGING:
        return this.login_url_stage;
      case ConfigurationEnvironment.TEST:
        return this.login_url_test;
      case ConfigurationEnvironment.DEVELOP:
        return this.login_url_dev;
      case ConfigurationEnvironment.LOCAL:
        return this.login_url_local;
      default:
        return "";
    }
  }
}
