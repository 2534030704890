
import { Component, Prop } from "vue-property-decorator";
import { EventBus, SLIDE_LINK_CLICKED } from "common";
import BaseComponent from "../base/BaseComponent.vue";
import { SlideTemplate } from "./TestGuidelineSlide.vue";

export interface SlideLinkTarget {
  default: string;
  conditional: string;
}

@Component
export default class SlideLink extends BaseComponent {
  @Prop({ default: SlideTemplate.DEFAULT }) slideTemplate!: SlideTemplate;

  SlideTemplate = SlideTemplate;

  getComponentType() {
    return "slide_links";
  }

  constructor() {
    super();
  }

  async mounted() {
    await super.mounted();
  }

  get layout() {
    return this.getContentAttribute("layout");
  }

  get slide_link() {
    return this.getContentAttribute("slide_link") || "";
  }
  get conditionalSlideLink() {
    return this.getContentAttribute("conditional_slide_link") || "";
  }

  get radioBtnLabel() {
    if (
      this.layout &&
      Object.prototype.hasOwnProperty.call(this.layout[0], "settings")
    ) {
      return this.layout[0].settings.content.display;
    }

    return "";
  }

  emitClick() {
    const slideLinkTarget: SlideLinkTarget = {
      default: this.slide_link.display,
      conditional: this.conditionalSlideLink.display
    };

    if (slideLinkTarget.default.length || slideLinkTarget.conditional.length) {
      EventBus.$emit(SLIDE_LINK_CLICKED, slideLinkTarget);
    }
  }
}
