
import { Component, Vue } from "vue-property-decorator";
import { Helper } from "common";

@Component
export default class KvIframe extends Vue {
  async mounted() {
    let self = this;
    var iFrame = document.getElementById("iframe") as HTMLIFrameElement;

    if (!iFrame) {
      return;
    }

    iFrame.onload = () => {
      self.addEventlisteners();
    };
  }

  addEventlisteners() {
    var iFrame = document.getElementById("iframe") as HTMLIFrameElement;

    if (iFrame != null) {
      var iframeDocument =
        iFrame.contentDocument || iFrame.contentWindow!.document;

      if (iframeDocument) {
        var allLinks = iframeDocument.getElementsByTagName("a");
        if (allLinks) {
          Helper.crawlDomAndAddEventlistenerToAllLinks(allLinks);
        }
      }
    }
  }
}
