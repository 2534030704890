
import { Component, Vue } from "vue-property-decorator";
import {
  AccessControlManager,
  Resources,
  LayoutComponent,
  ResultType,
  Store,
  User,
  UserLoginStatus,
  UserProfile,
  UserRepository
} from "common";

@Component
export default class AccountTab extends Vue {
  store = Store;

  public trackingConsent!: boolean;
  public lastTrackingConsent!: boolean;

  public labelLastName = new LayoutComponent({
    component: "Label",
    settings: {
      text: "Nachname"
    }
  });

  public labelFirstName = new LayoutComponent({
    component: "Label",
    settings: {
      text: "Vorname"
    }
  });

  public labelTitle = new LayoutComponent({
    component: "Label",
    settings: {
      text: "Titel"
    }
  });

  public labelEmail = new LayoutComponent({
    component: "Label",
    settings: {
      text: "E-Mail Adresse"
    }
  });

  constructor() {
    super();
    this.trackingConsent = User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)
      ? false
      : User.hasUserProfile(UserProfile.MMC_PLUS);
    this.lastTrackingConsent = this.trackingConsent;
  }

  showModal() {
    Store.modal.title = "Passwort ändern";
    Store.modal.className = "modal-md";
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("ChangePassword")
    );
    Store.modal.showModal();
  }

  disableTrackingConsent() {
    this.lastTrackingConsent = this.trackingConsent;
    this.trackingConsent = false;
    this.updateTrackingConsent();
  }

  enableTrackingConsent() {
    this.lastTrackingConsent = this.trackingConsent;
    this.trackingConsent = true;
    this.updateTrackingConsent();
  }

  async updateTrackingConsent() {
    User.setTrackingConsent(this.trackingConsent);

    let response = await UserRepository.updateUser(User.asApiUser());
    if (response.type == ResultType.FAIL) {
      User.setTrackingConsent(this.lastTrackingConsent);
    }
  }

  get hcpStatus() {
    return User.getProperty("hcpStatus") || "";
  }

  get lastName() {
    return this.hasAccess ? User.getProperty("lastName") : "";
  }

  get firstName() {
    return this.hasAccess ? User.getProperty("firstName") : "";
  }

  get title() {
    return this.hasAccess ? User.getProperty("title") : "";
  }

  get email() {
    return this.hasAccess ? User.getProperty("email") : "";
  }

  get hasAccess() {
    return AccessControlManager.userHasAccess(Resources.HCP_ACCOUNT);
    // if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
    //   return false;
    // }

    // return (
    //   User.getProperty("login_with_pw") && User.hasUserState(UserState.ACTIVE)
    // );
  }

  get user() {
    return User;
  }

  toggleDraftMode() {
    User.update({
      draftMode: !User.getProperty("draftMode")
    }).saveToStorage();
  }
}
