
import { Component, Watch, Prop } from "vue-property-decorator";
import { User } from "common";
import EventProgramBaseLayout from "./EventProgramBaseLayout.vue";
import { EventExportList, eventExportList, EventExportDBData } from "common";

@Component
export default class EventProgramGroupsWithPDFExportLayout extends EventProgramBaseLayout {
  private favs: any[];

  eventExportList = eventExportList;
  @Prop() useDatabase!: boolean;

  constructor() {
    super();
    this.favs = [];
  }

  mounted() {
    this.updateLikeIcons();
    this.checkIfDbIsInitialized();
  }

  @Watch("eventExportList.updated")
  updateLikeIcons() {
    this.favs = [];
    eventExportList.getArray().forEach((id: string) => {
      this.favs.push(id);
    });
  }

  isFav(sid: number, gid: number, cid: number) {
    if (!this.useDatabase) {
      return this.favs.includes(this.getId(sid, gid, cid));
    }

    return eventExportList.isDbChecked(this.getDatabaseData(sid, gid, cid));
  }

  getId(sid: number, gid: number, cid: number) {
    return EventExportList.generateID(this.program.id, sid, gid, cid);
  }

  get heartEmpty() {
    return "/assets/eventprogram/heart.svg";
  }

  get heartFill() {
    return "/assets/eventprogram/heart-fill.svg";
  }

  toggleFavorite(segmentId: number, groupId: number, contentId: number) {
    if (!this.useDatabase) {
      return eventExportList.toggleItem(
        this.getId(segmentId, groupId, contentId)
      );
    }

    const dbData = this.getDatabaseData(segmentId, groupId, contentId);
    const id = this.getId(segmentId, groupId, contentId);

    eventExportList.toggleDBItem(id, dbData);
  }

  checkIfDbIsInitialized() {
    if (!this.useDatabase) {
      return;
    }

    const data = [];

    this.program.segments.forEach((segment, sid) => {
      segment.groups.forEach((group, gid) => {
        group.content.forEach((content, cid) => {
          const dbData = this.getDatabaseData(sid, gid, cid);
          const id = this.getId(sid, gid, cid);

          data.push({
            localId: id,
            dbData,
            localFavorite: this.favs.includes(id),
            dbFavorite: eventExportList.isDbChecked(dbData)
          });
        });
      });
    });

    const hasDbFavorites = data.some(item => item.dbFavorite);

    // if it has db favorites, the database is the source of truth and we need to update the local favorites
    if (hasDbFavorites) {
      const dbFavoriteButNotLocal = data.filter(
        item => item.dbFavorite && !item.localFavorite
      );

      dbFavoriteButNotLocal.forEach(item => {
        eventExportList.toggleItem(item.localId);
      });
    } else {
      // if it has no db favorites, the local favorites are the source of truth and we need to update the database
      const localFavoriteButNotDb = data.filter(
        item => item.localFavorite && !item.dbFavorite
      );

      localFavoriteButNotDb.forEach(item => {
        eventExportList.toggleDBItem(item.localId, item.dbData);
      });
    }
  }

  getDatabaseData(
    segmentId: number,
    groupId: number,
    contentId: number
  ): EventExportDBData {
    const session = this.program.segments[segmentId];
    const sessionName = session.segmentContentText
      ? session.segmentContentText
      : session.segmentContent;
    const sessionTime = `${session.startTime} - ${session.endTime}`;
    const sessionId = sessionName
      .replace(/<[^>]*>/g, "")
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/-$/, "")
      .toLowerCase();

    const group = session.groups[groupId];
    const workshopName = group.content[contentId].descriptionText
      ? group.content[contentId].descriptionText
      : group.content[contentId].description;
    const workshopId = workshopName
      .replace(/<[^>]*>/g, "")
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/-$/, "")
      .toLowerCase();
    const userId = User.getProperty("id");

    const nameParts = [];

    if (User.getProperty("title")) {
      nameParts.push(User.getProperty("title"));
    }

    if (User.getProperty("firstName")) {
      nameParts.push(User.getProperty("firstName"));
    }

    if (User.getProperty("lastName")) {
      nameParts.push(User.getProperty("lastName"));
    }

    const userName = nameParts.join(" ");

    return {
      sessionId,
      sessionName,
      sessionTime,
      workshopId,
      workshopName,
      userId,
      userName
    } as EventExportDBData;
  }
}
