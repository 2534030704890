
import { Component } from "vue-property-decorator";
import { isNil } from "lodash";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class HighlightBox extends BaseComponent {
  getComponentType() {
    return "link_boxes";
  }

  async mounted() {
    super.mounted();
  }

  get headlines() {
    if (this.headline) {
      let headlines: LayoutComponent[] = [];
      this.headline.forEach((hl: any) => {
        headlines.push(new LayoutComponent(hl));
      });
      return headlines;
    }
    return [];
  }

  get headline() {
    return this.getContentAttribute("headline");
  }

  get texts() {
    if (this.text) {
      let texts: LayoutComponent[] = [];
      this.text.forEach((t: any) => {
        texts.push(new LayoutComponent(t));
      });
      return texts;
    }
    return [];
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get images() {
    if (this.image) {
      let images: LayoutComponent[] = [];
      this.image.forEach((img: any) => {
        images.push(new LayoutComponent(img));
      });
      return images;
    }
    return [];
  }

  get image() {
    return this.getContentAttribute("image");
  }

  get label() {
    return this.getContentAttribute("label");
  }

  get link() {
    return this.getContentAttribute("link");
  }

  get secondary_label() {
    return this.getContentAttribute("secondary_label");
  }

  get secondary_link() {
    return this.getContentAttribute("secondary_link");
  }

  get target() {
    return isNil(this.layoutComponent.settings.target)
      ? "_self"
      : this.layoutComponent.settings.target;
  }
}
