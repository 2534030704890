var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"expertforum-patient-change-password",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Benutzername")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",attrs:{"id":"username","type":"text","readonly":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"currentPassword"}},[_vm._v("Derzeitiges Passwort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateCurrentPassword,
              'is-valid': _vm.attemptSubmit && _vm.validateCurrentPassword
            },attrs:{"id":"currentPassword","type":"password","required":"","autocomplete":"new-password"},domProps:{"value":(_vm.currentPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.currentPassword=$event.target.value}}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"new-password"}},[_vm._v("Neues Passwort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePassword,
              'is-valid': _vm.attemptSubmit && _vm.validatePassword
            },attrs:{"id":"new-password","type":"password","required":""},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.newPassword=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.errorPassword)}})])]),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Passwort wiederholen*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateConfirmPassword,
              'is-valid': _vm.attemptSubmit && _vm.validateConfirmPassword
            },attrs:{"id":"confirm-password","type":"password","required":""},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}})])])]),(_vm.requestSent)?_c('div',{staticClass:"form-feedback"},[(_vm.apiError)?_c('div',{staticClass:"row submit-error"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.resultMessage)}}),_c('div',{staticClass:"divider small"})])]):_c('div',{staticClass:"row submit-success"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")]),_c('div',{staticClass:"divider small"})])])]):_vm._e(),_c('div',{staticClass:"controls"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-primary"},[_c('b',[_vm._v("Speichern")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"waiting"},[_c('span',{staticClass:"spinner-border spinner-border-md mr-2"}),_c('span',[_vm._v("Bitte warten...")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }