import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

class FeedbackRepository extends AbstractRepository {
  async save(data: any) {
    const dataToSave = {
      data: {
        feedback_form: {
          _id: data.feedbackId
        },
        answers: data.answers
      }
    };

    const response = await Client.post(
      `${Constants.collectionsUrl}/save/feedback_answers`,
      dataToSave,
      this.getAdditionalHeader()
    );

    const result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new FeedbackRepository();
