import ContentRepository from "../api/repositories/ContentRepository";
import config, { MappedRoute } from "../config";
import { EventBus, ROUTES_LOADED } from "./EventBus";
import { Store } from "./Store";
import { RoutesMapping } from "./RoutingHelper";
import { RouteConfig } from "vue-router/types/router";

class RouteLoader {
  public static routeLoader?: RouteLoader;

  public static getInstance(): RouteLoader {
    if (!this.routeLoader) {
      this.routeLoader = new RouteLoader();
    }
    return this.routeLoader;
  }

  getDebugRoute() {
    return [
      {
        path: "/debug",
        component: () =>
          import(/* webpackChunkName: "Debug" */ "../views/Debug.vue"),
        meta: { css: "" }
      }
    ];
  }

  getPublicRoutes() {
    let publicRoutes: RouteConfig[] = [
      {
        path: "/404",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "404" */ "../views/Page.vue"),
        props: { id: "64ccf0f5cdf2adf58607e996" },
        meta: { id: "64ccf0f5cdf2adf58607e996", type: "static_pages" }
      },
      {
        path: "/error",
        component: () =>
          import(/* webpackChunkName: "500" */ "../views/500.vue"),
        meta: { css: "" }
      },
      {
        path: "/null",
        redirect: "/404"
      }
    ];

    config.getMappedPublicRoutes().forEach((mappedRoute: MappedRoute) => {
      publicRoutes.push({
        path: mappedRoute.slug,
        component: () =>
          import(/* webpackChunkName: "Page" */ "../views/Page.vue"),
        props: { id: mappedRoute.id },
        meta: { id: mappedRoute.id, type: "static_pages" }
      });
    });

    return publicRoutes;
  }

  /**
   * Get all the routes for the current app brand
   * and adds them in the global store
   */
  async loadPrivateRoutes() {
    // Get all routes for the current frontend
    if (!Store.routesLoaded) {
      await ContentRepository.getRoutes(Store.appBrand).then(
        (routesMapping: RoutesMapping) => {
          Store.allRoutes = { ...routesMapping };
          Store.routesLoaded = true;
          EventBus.$emit(ROUTES_LOADED);
        }
      );
    }
  }
}

export default RouteLoader.getInstance();
