
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class SliderItem extends BaseComponent {
  constructor() {
    super();
  }

  getComponentType() {
    return "slider_items";
  }

  async mounted() {
    await super.mounted();
  }

  get contentItems() {
    return this.getContentAttribute("content");
  }
}
