var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visible)?_c('div',{staticClass:"eventlist",class:_vm.cssClass,attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[(_vm.list_type == 'all')?_c('div',{staticClass:"eventlist-all row"},[_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-12 col-12"},[(_vm.selectable)?_c('div',[_c('label',{staticClass:"eventlist-btn-check eventlist-btn-check-all"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allEvents),expression:"allEvents"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allEvents)?_vm._i(_vm.allEvents,null)>-1:(_vm.allEvents)},on:{"click":_vm.selectAllEvents,"change":function($event){var $$a=_vm.allEvents,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allEvents=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allEvents=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allEvents=$$c}}}}),_vm._m(0)])]):_vm._e(),_vm._l((_vm.events),function(event){return _c('EventListItem',{key:event.id,class:{
          active: event.registered,
          disabled: event.expired
        },attrs:{"event":event,"displayOptions":_vm.eventDisplayOptions,"selected":_vm.isSelected(event)},on:{"selectEvent":function($event){return _vm.selectEvent(event)},"selectEventSpeaker":function($event){return _vm.selectEventSpeaker(event)}}})})],2)]):(_vm.list_type == 'live')?_c('div',{staticClass:"eventlist-live"},[(_vm.liveEvents.length === 0)?_c('div',[_vm._v(" Aktuell finden keine Live Events statt. ")]):_c('div',_vm._l((_vm.liveEvents),function(event){return _c('EventListItemLive',{key:event.id,class:{
          active: event.registered
        },attrs:{"event":event,"displayOptions":_vm.eventDisplayOptions}})}),1)]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('span',[_vm._v("Alle")]),_c('b',[_vm._v(" Ich möchte an allen Veranstaltungen teilnehmen ")])])
}]

export { render, staticRenderFns }