
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";
import { RatingRepository } from "common";

@Component
export default class RatingModal extends BaseComponent {
  public ratingValue!: number;
  public loading!: boolean;

  constructor() {
    super();
    this.ratingValue = 0;
    this.loading = false;
  }

  onRatingValueChange() {
    if (this.ratingValue && !this.loading) {
      this.loading = true;
      RatingRepository.postRating(window.location.href, this.ratingValue).then(
        () => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }
}
