
import { Component } from "vue-property-decorator";
import BaseComponent from "../../base/BaseComponent.vue";
import {
  Feedback,
  matomo,
  ResultType,
  Spendings,
  TransparencyRepository
} from "common";
import ButtonWebWorld from "../../content/ButtonWebWorld.vue";

export enum TransparencyState {
  CHECK_SPENDINGS,
  FEEDBACK_NEEDED,
  FEEDBACK_GIVEN,
  ERROR
}

export enum Error {
  SPENDINGS_ERROR,
  FEEDBACK_ERROR
}

@Component({
  components: { ButtonWebWorld }
})
export default class TransparencyComponent extends BaseComponent {
  //TODO default with ""
  transparencyState: TransparencyState = TransparencyState.CHECK_SPENDINGS;
  spendingsError: boolean = false;
  feedbackError: boolean = false;

  public spendings: Spendings;
  public feedback: Feedback;

  public constructor() {
    super();
    this.spendings = new Spendings();
    this.feedback = new Feedback();
  }

  get TransparencyStates() {
    return TransparencyState;
  }

  async mounted() {
    await super.mounted();
    await this.getSpendings();
  }

  scrollToElement() {
    let element = this.$refs.anchor as HTMLElement;
    let offsetTop = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo(0, offsetTop);
  }

  track(btnAction: string) {
    matomo.click(
      btnAction,
      process.env.VUE_APP_MATOMO_EVENT_CATEGORY as string,
      "transparency_click"
    );
  }

  public setConsent(consent: boolean) {
    this.feedback.publicationConsent = consent;
  }

  public async getSpendings() {
    this.track("getSpendings");
    this.transparencyState = TransparencyState.CHECK_SPENDINGS;

    let response = await TransparencyRepository.getSpendings();

    if (response.type == ResultType.SUCCESS) {
      this.spendings = new Spendings(response.value);

      if (this.spendings.feedbackDate) {
        this.transparencyState = TransparencyState.FEEDBACK_GIVEN;
      } else {
        this.transparencyState = TransparencyState.FEEDBACK_NEEDED;
      }
    } else {
      this.spendingsError = true;
      this.transparencyState = TransparencyState.ERROR;
      this.spendings = new Spendings();
    }
  }

  public async putFeedback() {
    if (this.feedback.isValid()) {
      let response = await TransparencyRepository.putFeedback(
        this.feedback.toSend()
      );
      if (response.type == ResultType.SUCCESS) {
        await this.getSpendings();
        this.transparencyState = TransparencyState.FEEDBACK_GIVEN;
      } else {
        this.resetForm(Error.FEEDBACK_ERROR);
      }
    }
  }

  private resetErrors() {
    this.feedbackError = false;
    this.spendingsError = false;
  }

  private resetForm(error: Error) {
    this.resetErrors();
    switch (error) {
      case Error.FEEDBACK_ERROR:
        this.feedbackError = true;
        break;
      case Error.SPENDINGS_ERROR:
        this.spendingsError = true;
        break;
      default:
    }
    this.transparencyState = TransparencyState.ERROR;
    this.spendings = new Spendings();
  }
}
