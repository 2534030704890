
import { Component } from "vue-property-decorator";
import { LayoutComponent, Store, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

const ALL_USERS: string = "all_users";

@Component
export default class DelayedPopup extends BaseComponent {
  private delayedPopupTimer: any = null;

  constructor() {
    super();
  }

  getComponentType() {
    return "delayed_popups";
  }

  async mounted() {
    await super.mounted();
    this.init(false);
    document.addEventListener("mouseleave", this.leavePageFromTop);
  }

  beforeDestroy() {
    clearTimeout(this.delayedPopupTimer);
    document.removeEventListener("mouseleave", this.leavePageFromTop);
  }

  leavePageFromTop(event: any) {
    if (
      event.clientY <= 0 ||
      event.clientX <= 0 ||
      event.clientX >= window.innerWidth ||
      event.clientY >= window.innerHeight
    ) {
      this.init(true);
    }
  }

  init(showImmediately: any) {
    clearTimeout(this.delayedPopupTimer);
    const delayTime: any = showImmediately
      ? 0
      : parseInt(this.content[0].delay_time, 10);
    const secondsDelayTime: any =
      delayTime || delayTime === 0 ? delayTime * 1000 : null;
    const availableForUserType: string = this.content[0].user_type || ALL_USERS;
    let currentUserType: any;

    try {
      // eslint-disable-next-line
      // @ts-ignore
      currentUserType = (User.getUser() || {}).accessProfile || null;
    } catch (e) {
      currentUserType = null;
    }

    try {
      if (currentUserType == null) {
        // eslint-disable-next-line
        // @ts-ignore
        currentUserType = User.getUserProfile() || null;
      }
    } catch (e) {
      currentUserType = null;
    }

    const delayedPopupId: string = `_${this.content[0]._id}`;

    if (localStorage.getItem(delayedPopupId)) {
      return;
    }

    if (
      secondsDelayTime !== null &&
      (availableForUserType === currentUserType ||
        availableForUserType === ALL_USERS)
    ) {
      this.delayedPopupTimer = setTimeout(() => {
        this.openDelayedPopup();
        localStorage.setItem(delayedPopupId, "1");
      }, secondsDelayTime);
    }
  }

  openDelayedPopup() {
    const items = this.getContentAttribute("content");
    const itemsToRender: LayoutComponent[] = [];

    if (items && items.length > 0) {
      items.forEach((currentItem: any) => {
        const popupLayoutItemContent: LayoutComponent | null = currentItem
          ? new LayoutComponent(currentItem)
          : null;

        itemsToRender.push(popupLayoutItemContent as LayoutComponent);
      });
    }

    if (itemsToRender.length > 0) {
      Store.modal.className = "modal-lg modal--delayed-popup";
      Store.modal.setLayoutComponent(
        LayoutComponent.createEmpty("DelayedPopupTemplate", {
          childrenComponents: itemsToRender
        })
      );
      Store.modal.showModal();
    }
  }
}
