
import { Component, Vue } from "vue-property-decorator";
import { Constants, matomo } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Podcast extends BaseComponent {
  readonly EVENT_PLAY = "PLAYER_REQUEST_PLAY";
  readonly EVENT_PAUSE = "PLAYER_REQUEST_PAUSE";
  readonly EVENT_PLAYTIME = "PLAYER_BACKEND_PLAYTIME";

  private episode!: any;
  private playerConfig!: any;
  public id!: string;

  private startPlayTime!: number | null;
  private stopPlayTime!: number | null;

  constructor() {
    super();
    this.id = Math.random()
      .toString(36)
      .substr(2, 9);
    this.episode = { chapters: null };
    this.playerConfig = {
      theme: {
        main: "#024d84",
        brand: "#166255",
        brandDark: "#166255",
        brandDarkest: "#1A3A4A",
        brandLightest: "#E5EAECFF",
        shadeDark: "#807E7C",
        shadeBase: "#807E7C",
        contrast: "#000",
        alt: "#fff"
      },
      tabs: {
        chapters: true
      },
      visibleComponents: [
        "showTitle",
        "episodeTitle",
        "subtitle",
        "progressbar",
        "controlSteppers",
        "controlChapters",
        "poster",
        "tabInfo",
        "tabChapters",
        "tabFiles",
        "tabAudio"
      ],
      activeTab: "chapters"
    };

    this.startPlayTime = null;
    this.stopPlayTime = null;
  }

  getComponentType() {
    return "podcasts";
  }

  get episodeContent() {
    return this.getContentAttribute("episode");
  }

  get chapters() {
    return this.getContentAttribute("chapters");
  }

  get transcripts() {
    return this.getContentAttribute("transcripts");
  }

  get config() {
    return this.getContentAttribute("config");
  }

  get playlist() {
    return this.getContentAttribute("playlist");
  }

  get playerId() {
    return this.getContentAttribute("id");
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    if (this.episodeContent) {
      Object.assign(this.episode, this.episodeContent);
    }

    if (this.chapters) {
      this.episode.chapters = this.chapters;
    }

    if (this.transcripts) {
      this.episode.transcripts = this.transcripts;
    }

    if (this.config) {
      Object.assign(this.playerConfig, this.config);
    }

    this.prefixPaths();

    Vue.nextTick(() => {
      // @ts-ignore
      window
        // @ts-ignore
        .podlovePlayer("#lovepod-" + this.id, this.episode, this.playerConfig)
        // @ts-ignore
        .then(store => {
          store.subscribe(() => {
            if (store.getState().lastAction.type == this.EVENT_PLAY) {
              // @ts-ignore
              matomo.playPodcast(
                this.playerId,
                // @ts-ignore
                process.env.VUE_APP_MATOMO_EVENT_CATEGORY
              );
            }

            if (store.getState().lastAction.type == this.EVENT_PAUSE) {
              let playedTime = 0;
              if (this.stopPlayTime != null && this.startPlayTime != null) {
                playedTime = (this.stopPlayTime - this.startPlayTime) / 1000;
              }

              // @ts-ignore
              matomo.stopPodcast(
                this.playerId,
                // @ts-ignore
                process.env.VUE_APP_MATOMO_EVENT_CATEGORY,
                playedTime
              );

              setTimeout(() => {
                this.startPlayTime = null;
                this.stopPlayTime = null;
              }, 200);
            }

            if (store.getState().lastAction.type == this.EVENT_PLAYTIME) {
              if (this.startPlayTime == null) {
                this.startPlayTime = store.getState().lastAction.payload;
              }
              this.stopPlayTime = store.getState().lastAction.payload;
            }
          });
        });
    });
  }

  prefixPaths() {
    if (this.episode.poster) {
      this.episode.poster = Constants.storageUrl + "/" + this.episode.poster;
    }
    this.episode.audio.forEach((audio: object, index: any) => {
      // @ts-ignore
      this.episode.audio[index].url = Constants.storageUrl + "/" + audio.url;
    });
    this.episode.contributors.forEach((contributor: object, index: any) => {
      // @ts-ignore
      this.episode.contributors[index].avatar =
        // @ts-ignore
        Constants.storageUrl + "/" + contributor.avatar;
    });
  }
}
