import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Event from "../../models/Event";
import Client from "../util/Client";
import User from "../../models/User";
import { EventExportDBData } from "../../util/EventExportList";

class EventRepository extends AbstractRepository {
  async getEvents(type = "all") {
    let data = {};

    let response = await Client.getJson(
      Constants.eventsListUrl(type),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, Event[]>(response, (r: any) => {
      let events: Event[] = [];
      r.forEach((item: any) => {
        events.push(new Event(item));
      });
      return events;
    });
    return result;
  }

  async getEvent(eventId: any) {
    let data = {};

    let response = await Client.getJson(
      Constants.eventShowUrl(eventId, User.getProperty("id")),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, Event>(response, (r: any) => {
      return new Event(r);
    });
    return result;
  }

  async getVeevaEvent(veevaEventId: any) {
    let data = {};

    let response = await Client.getJson(
      Constants.veevaEventShowUrl(veevaEventId),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, Event>(response, (r: any) => {
      return new Event(r);
    });
    return result;
  }

  async sendEventRegister(eventId: any) {
    let data = {
      eventId: eventId,
      userId: User.getProperty("id")
    };

    let response = await Client.post(
      Constants.eventRegisterUrl(),
      data,
      this.getAdditionalHeader()
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async sendEventSelfregister(data: any) {
    let response = await Client.postWithJsonResult(
      Constants.eventSelfregisterUrl(),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async sendEventRegisterMulti(data: any) {
    let response = await Client.post(
      Constants.eventRegisterMultiUrl(),
      data,
      this.getAdditionalHeader(),
      true
    );
    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async checkUserRegistrationForEvent(veevaId: any) {
    let data = {};

    let response = await Client.getJson(
      Constants.veevaEventShowUrl(veevaId),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, Event>(response, (r: any) => {
      return new Event(r);
    });

    return result;
  }

  async getSpeakerImage(imageId: any) {
    let response = await Client.getFile(
      Constants.speakerImageUrl(imageId),
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async getEventDocument(docId: any) {
    let response = await Client.getFile(
      Constants.eventDocumentUrl(docId),
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  async sendEventSelfregisterPdf(data: any) {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    let response = await Client.postFile(
      Constants.eventSelfregisterPdfUrl(),
      formData
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });
    return result;
  }

  async postEventUserSessionData(data: EventExportDBData) {
    const response = await Client.post(
      Constants.eventUserSessionsUrl(),
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }

  async getEventUserSessionData(userId: string) {
    const response = await Client.getJson(
      `${Constants.eventUserSessionsUrl()}?userId=${userId}`,
      {},
      this.getAdditionalHeader()
    );

    return this.getResult<any, EventExportDBData[]>(response, (r: any) => {
      return r;
    });
  }

  async deleteEventUserSessionData(userId: string, sessionId: string) {
    const response = await Client.delete(
      `${Constants.eventUserSessionsUrl()}/${userId}/${sessionId}`,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new EventRepository();
