var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider",class:[
    {
      tiny: _vm.height == _vm.HEIGHT_TINY,
      small: _vm.height == _vm.HEIGHT_SMALL,
      medium: _vm.height == _vm.HEIGHT_MEDIUM,
      big: _vm.height == _vm.HEIGHT_BIG,
      huge: _vm.height == _vm.HEIGHT_HUGE,
      enormous: _vm.height == _vm.HEIGHT_ENORMOUS,
      'horizontal-line': _vm.withLine
    },
    _vm.layoutComponent.class
  ],attrs:{"id":_vm.layoutComponent.id}})
}
var staticRenderFns = []

export { render, staticRenderFns }