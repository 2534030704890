import Constants from "../../models/Constants";
import Client from "../util/Client";
import AbstractRepository from "./AbstractRepository";

class ArticleRepository extends AbstractRepository {
  public async save(articleId: string) {
    const data = {
      articleId: articleId
    };

    let response = await Client.post(
      Constants.getArticleListUrl("saved"),
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async get() {
    let response = await Client.getJson(
      Constants.getArticleListUrl("saved"),
      null,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async delete(articleId: string) {
    let response = await Client.delete(
      Constants.getArticleListUrl(`saved/${articleId}`),
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }

  public async getDynamicArticlesBySegmentation(
    currentArticleId: string,
    excludeIds: Array<string>
  ) {
    const data: any = {
      articleId: currentArticleId,
      exclude: [...excludeIds]
    };

    const response = await Client.postWithJsonResult(
      Constants.dynamicArticlesUrl,
      data,
      this.getAdditionalHeader()
    );

    return this.getResult<any, any>(response, (r: any) => {
      return r;
    });
  }
}

export default new ArticleRepository();
