
import { Component, Vue } from "vue-property-decorator";
import { LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LcBusinessCard extends BaseComponent {
  getComponentType() {
    return "business_cards";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(() => {
      //empty
    });
  }

  get name(): string {
    return this.getContentAttribute("name");
  }

  get image() {
    let image = this.getContentAttribute("image");
    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }
    return null;
  }

  get show_image(): boolean {
    return this.getSettingsAttribute("show_image");
  }

  get show_name(): boolean {
    return this.getSettingsAttribute("show_name");
  }

  get profession(): string {
    return this.getContentAttribute("profession");
  }

  get show_profession(): boolean {
    return this.getSettingsAttribute("show_profession");
  }

  get workplace(): string {
    return this.getContentAttribute("workplace");
  }

  get show_workplace(): boolean {
    return this.getSettingsAttribute("show_workplace");
  }
}
