
import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "common";

@Component
export default class LoginSuccess extends Vue {
  shared = Store;

  mounted() {
    this.dismissWithDelay();
  }

  dismiss() {
    Store.showSuccessfullLoginPopup = false;
  }

  @Watch("shared.showSuccessfullLoginPopup")
  dismissWithDelay() {
    if (Store.showSuccessfullLoginPopup) {
      setTimeout(() => {
        this.dismiss();
      }, 5000);
    }
  }

  get success() {
    return "Sie haben sich erfolgreich angemeldet!";
  }
}
