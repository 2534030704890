
import { Component } from "vue-property-decorator";
import {
  LikePostRepository,
  ResultType,
  Storage,
  Store,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class LikePost extends BaseComponent {
  public likesNum!: number;
  public alreadyLiked!: boolean;
  protected storageParameter!: string;

  constructor() {
    super();
    this.likesNum = 0;
    this.alreadyLiked = false;
    this.storageParameter = "_ww_lk";
  }

  getComponentType() {
    return "";
  }

  async mounted() {
    await super.mounted();

    // Check if like is saved on local storage (if no user is authenticated)
    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      this.checkIfStorageLiked();
    }
    this.likesNum = await this.getLikes();
  }

  get textLabel() {
    return this.getSettingsAttribute("text_label") || "";
  }

  get userId(): string | null {
    if (
      User.hasUserLoginStatus(UserLoginStatus.LOGGED_IN) &&
      User.hasProperty("id")
    ) {
      return User.getProperty("id").toString();
    }

    return null;
  }

  get storageLikes() {
    return Storage.getLocal(this.storageParameter, false, false) || [];
  }

  set storageLikes(likes: string[]) {
    Storage.saveLocal(this.storageParameter, likes, false, false);
  }

  checkIfStorageLiked() {
    if (this.storageLikes.includes(Store.activePost)) {
      this.alreadyLiked = true;
    } else {
      this.alreadyLiked = false;
    }
  }

  saveLikeToStorage() {
    let likes = this.storageLikes;

    // Push the post id to list if is not in
    if (!likes.includes(Store.activePost)) {
      likes.push(Store.activePost);
      this.storageLikes = likes;
    }

    this.alreadyLiked = true;
  }

  async getLikes() {
    let response = await LikePostRepository.get(Store.activePost, this.userId);

    if (response.type == ResultType.SUCCESS) {
      // Check already liked if user is authenticated
      if (this.userId && response.value.already_liked) {
        this.alreadyLiked = true;
      }

      return response.value.likes_count || this.likesNum;
    }

    return 0;
  }

  async saveLike() {
    let response = await LikePostRepository.save(Store.activePost, this.userId);

    if (response.type == ResultType.SUCCESS) {
      this.likesNum = response.value.likes_count || this.likesNum;

      // Check already liked if user is authenticated
      if (this.userId && response.value.already_liked) {
        this.alreadyLiked = true;
      }

      // Save like to local storage if no user is authenticated
      if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
        this.saveLikeToStorage();
      }
    }
  }

  clickHandler() {
    if (!this.alreadyLiked) {
      this.saveLike();
    }
  }
}
