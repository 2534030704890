
import { Vue, Component } from "vue-property-decorator";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import BaseComponent from "../base/BaseComponent.vue";
import {
  ContentRepository,
  FeedbackRepository,
  LayoutComponent,
  Result,
  ResultType,
  Helper
} from "common";

interface FeedbackFormItem {
  groupId: string;
  groupDisplay?: string;
  questions: LayoutComponent[];
}

interface FeedbackFormDataItem {
  questionId: string;
  value: string;
  isValid: boolean;
  answerId: string;
}

@Component
export default class FeedbackForm extends BaseComponent {
  public id: string;
  public items: FeedbackFormItem[];
  private questionIds: string[];
  private formState: FeedbackFormDataItem[];
  public shouldReset: boolean;
  public shouldValidate: boolean;
  private groupType: string;
  public groupsLoaded: boolean;
  public loading: boolean;
  public saveSuccess: boolean;
  public readonly NO_GROUP: string = "";

  constructor() {
    super();
    this.id = `feedback-${this.getUniqueKey()}`;
    this.items = [];
    this.questionIds = [];
    this.formState = [];
    this.shouldReset = false;
    this.shouldValidate = false;
    this.groupType = "";
    this.groupsLoaded = false;
    this.loading = false;
    this.saveSuccess = false;
  }

  getComponentType() {
    return "feedback_forms";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.setItems();
      await this.loadGroups(
        uniq(
          this.items
            .map((item: FeedbackFormItem) => item.groupId)
            .filter((id: string) => id !== this.NO_GROUP)
        )
      );
    });
  }

  /************************************/
  // Map Cockpit props
  get title() {
    let title = this.getContentAttribute("title");
    if (title && title.length > 0) {
      return new LayoutComponent(title[0]);
    }
    return null;
  }

  get successMessage() {
    return this.getContentAttribute("success_message");
  }

  setItems() {
    const questions = this.getContentAttribute("questions");
    if (questions && questions.length > 0) {
      questions.forEach((currentQuestion: any) => {
        const { group, content } = currentQuestion.settings;
        const groupId: string = group ? `${group._id}` : this.NO_GROUP;
        const questionLayoutComponent: LayoutComponent | null = currentQuestion
          ? new LayoutComponent(currentQuestion)
          : null;

        this.questionIds.push(content._id);

        if (groupId) {
          this.groupType = group.link;
          const itemId: number = this.items.findIndex(
            (item: FeedbackFormItem) => item.groupId === groupId
          );
          if (itemId === -1) {
            this.items.push({
              groupId,
              questions: [questionLayoutComponent as LayoutComponent]
            });
          } else {
            this.items[itemId] = {
              ...this.items[itemId],
              questions: [
                ...this.items[itemId].questions,
                questionLayoutComponent as LayoutComponent
              ]
            };
          }
        } else {
          this.items.push({
            groupId,
            questions: [questionLayoutComponent as LayoutComponent]
          });
        }
      });
    }
  }

  async loadGroups(groupIds: string[]) {
    if (groupIds.length === 0) {
      this.groupsLoaded = true;
      return;
    }
    const groupsResponse: Result<Array<
      any
    >> = await ContentRepository.getCollectionItemByTypeAndId(
      this.groupType,
      groupIds,
      0
    );
    if (
      groupsResponse.type == ResultType.SUCCESS &&
      groupsResponse.value &&
      groupsResponse.value.length > 0
    ) {
      groupsResponse.value.forEach(({ _id, text }) => {
        const itemId: number = this.items.findIndex(
          (item: FeedbackFormItem) => item.groupId === _id
        );
        this.items[itemId] = {
          ...this.items[itemId],
          groupDisplay: text
        };
      });
    }
    this.groupsLoaded = true;
  }

  /************************************/
  // Business Logic

  setQuestionState(state: FeedbackFormDataItem) {
    this.formState = sortBy(
      [...this.formState, state],
      (item: FeedbackFormDataItem) => {
        return this.questionIds.indexOf(item.questionId);
      }
    );
  }

  setAnswerState(state: FeedbackFormDataItem) {
    const index = this.formState.findIndex(
      (question: FeedbackFormDataItem) =>
        question.questionId === state.questionId
    );

    if (index !== -1) {
      this.formState[index] = {
        ...this.formState[index],
        answerId: state.answerId,
        value: state.value,
        isValid: state.isValid
      };
    }
  }

  async validate(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const index = this.formState.findIndex(
      (questionState: FeedbackFormDataItem) => !questionState.isValid
    );

    if (index === -1) {
      this.loading = true;

      const feedbackResponse = await FeedbackRepository.save(
        this.getFormData()
      );
      if (feedbackResponse.type === ResultType.SUCCESS) {
        // Show Notification
        this.shouldValidate = false;
        this.loading = false;
        this.saveSuccess = true;
        this.formState = [];
      }

      this.resetForm();
    } else {
      this.scrollToFirstError(index);
      this.shouldValidate = true;
    }
  }

  getFormData() {
    return {
      feedbackId: this.layoutComponent.settings.content._id,
      answers: this.formState.map((inputState: FeedbackFormDataItem) => {
        return { [inputState.questionId]: inputState.answerId };
      })
    };
  }

  resetForm() {
    this.shouldReset = !this.shouldReset;
    this.shouldValidate = false;
    this.formState = [];
  }

  /************************************/
  // Utils

  scrollToFirstError(index: number) {
    const inputElem = document.querySelector(
      `#${this.id} #question-${this.formState[index].questionId}`
    );
    if (inputElem !== null) {
      Helper.smoothScrollTo(
        inputElem as HTMLElement,
        inputElem.getBoundingClientRect().top + window.scrollY
      );
    }
  }

  getUniqueKey() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }
}
