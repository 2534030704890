var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.contentLoaded)?_c('div',[_c('div',{staticClass:"skeleton__accordion"})]):_c('div',{staticClass:"accordion",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":'accordion-' + _vm.id,"data-component":_vm.componentType,"data-id":_vm.internalId}},[(_vm.expandAllButton)?_c('button',{staticClass:"expand-all-btn",attrs:{"data-target":_vm.toggleAllState === _vm.ToggleAllState.CLOSE
        ? '.multi-collapse-' + _vm.id + ':not(.show)'
        : '.multi-collapse-' + _vm.id + '.show',"data-toggle":"collapse"},on:{"click":function($event){return _vm.changeToggleState()}}},[_vm._v(" "+_vm._s(_vm.getExpandBtnText())+" ")]):_vm._e(),_vm._l((_vm.content),function(item,idx){return _c('div',{key:idx,staticClass:"card",class:{
      expanded: _vm.toggledItems.indexOf(`${idx}`) > -1,
      'chevr-up': _vm.itemIndices[idx] == 'up'
    }},[_c('div',{ref:item.anchor,refInFor:true,staticClass:"card-header mb-0 d-flex justify-content-between align-items-center",attrs:{"id":'accordion-item-heading-' + idx + _vm.id,"data-anchor":item.anchor}},[_c('button',{staticClass:"btn btn-link mb-0",attrs:{"data-toggle":"collapse","data-target":'#accordion-item-collapse-' + idx + _vm.id,"aria-controls":'accordion-item-collapse-' + idx + _vm.id,"aria-expanded":"false"},domProps:{"innerHTML":_vm._s(item.headline)},on:{"click":function($event){return _vm.handleToggleItem(idx)}}}),_c('img',{attrs:{"src":_vm.getHeadlineIcon(idx)}})]),(item.preview && item.preview.length > 0)?_c('div',{staticClass:"accordion__preview-text"},_vm._l((_vm.getLayoutComponentsFromLayout(item.preview)),function(comp,cidx){return _c('div',{key:cidx},[_c(comp.component,{tag:"component",attrs:{"layoutComponent":comp}})],1)}),0):_vm._e(),_c('div',{class:'collapse multi-collapse-' + _vm.id,attrs:{"id":'accordion-item-collapse-' + idx + _vm.id,"aria-labelledby":_vm.id + '-accordion-item-heading-' + idx,"data-parent":_vm.keepAllOpen || _vm.expandAllButton ? false : '#accordion-' + _vm.id}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.getLayoutComponentsFromLayout(
              item.content
            )),function(comp,cidx){return _c('div',{key:cidx,class:'article-column ' + _vm.getColumns(comp.settings.width)},[_c(comp.component,{tag:"component",attrs:{"layoutComponent":comp}})],1)}),0)])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }