
import { Component } from "vue-property-decorator";
import { LayoutComponent, ResultType, WidgetExternalRepository } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class WidgetExternalApi extends BaseComponent {
  public mainValue: any = null;
  public secondValue: any = null;
  public date: any = null;

  async mounted() {
    await super.mounted();
    await this.getValue();
  }

  currentDate(date: any = null) {
    if (date) {
      date = new Date(date);
    } else {
      date = new Date();
    }

    this.date = date.toLocaleString("de-de", {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  }

  async getValue() {
    this.mainValue = "NaN";
    let result = await WidgetExternalRepository.get(this.type);

    if (result.type !== ResultType.SUCCESS) {
      this.mainValue = "NaN";
      return;
    }

    if (this.type == "AnzAktiv") {
      this.getDataAktuellInfizierte(result);
    } else if (this.type == "Inzidenz_7-Tage") {
      this.getData7TageInzidenz(result);
    } else if (this.type == "PS_7_Tage_R_Wert") {
      this.getDataRWert(result);
    } else if (this.type == "7T_Hospitalisierung_Inzidenz") {
      let secondary_result = await WidgetExternalRepository.get(
        "fixierte_7T_Hospitalisierung_Inzidenz"
      );
      this.getDataHospitalisierungen(result, secondary_result);
    }
  }

  getDataAktuellInfizierte(result: any) {
    let value = JSON.parse(result.value);

    if (!value.features && !value.features[0].properties[this.type]) {
      return;
    }

    let number = value.features[0].properties[this.type];
    this.mainValue = this.convertToHuman(number);

    this.currentDate();
    this.secondValue = this.formatNumber(
      value.features[0].properties["AnzAktivNeu"]
    );
  }

  getData7TageInzidenz(result: any) {
    let csv = this.csvJSON(result.value, false, 100);

    if (!csv && !Object.keys(csv)) {
      return;
    }

    csv = csv.filter(function(el) {
      return el["Inzidenz_7-Tage"] != "" && el.Altersgruppe == "00+";
    });

    let lastIndex = Object.keys(csv).length - 1;
    let mainValue = parseFloat(csv[lastIndex][this.type]);
    this.mainValue = this.convertToHuman(mainValue || 0);

    let date = csv[lastIndex].Meldedatum;
    if (date) {
      this.currentDate(date);
    }

    lastIndex -= 7;

    if (!csv[lastIndex] || !csv[lastIndex][this.type]) {
      return;
    }

    this.secondValue = this.formatNumber(mainValue - csv[lastIndex][this.type]);
  }

  getDataRWert(result: any) {
    let csv = this.csvJSON(result.value, false, 20);

    if (!csv && !Object.keys(csv)) {
      return;
    }

    csv = csv.filter(function(el) {
      return el.PS_7_Tage_R_Wert != "";
    });

    let lastIndex = Object.keys(csv).length - 1;
    let mainValue = parseFloat(csv[lastIndex][this.type]);

    this.mainValue = this.convertToHuman(mainValue || 0);

    if (csv[lastIndex].Datum) {
      this.currentDate(csv[lastIndex].Datum);
    }

    if (!csv[lastIndex - 7] || !csv[lastIndex - 7][this.type]) {
      return;
    }

    this.secondValue = this.formatNumber(
      mainValue - csv[lastIndex - 7][this.type]
    );
  }

  getDataHospitalisierungen(result: any, secondary_result: any) {
    let csv = this.csvJSON(result.value, true, 100);
    let secondary_csv = this.csvJSON(secondary_result.value, true, 10);
    if (!csv && !Object.keys(csv)) {
      return;
    }

    csv = csv.filter(function(el) {
      return (
        el["7T_Hospitalisierung_Inzidenz"] != "" &&
        el.Bundesland == "Bundesgebiet" &&
        el.Bundesland_Id == "00" &&
        el.Altersgruppe == "00+"
      );
    });

    let index = 0;
    let mainValue = parseFloat(csv[index][this.type]);
    this.mainValue = this.convertToHuman(mainValue || 0);

    let date = csv[index].Datum;
    if (date) {
      this.currentDate(date);
    }

    if (
      !secondary_csv[7] ||
      !secondary_csv[7]["fixierte_7T_Hospitalisierung_Inzidenz"]
    ) {
      return;
    }

    this.secondValue = this.formatNumber(
      mainValue - secondary_csv[7]["fixierte_7T_Hospitalisierung_Inzidenz"]
    );
  }

  csvJSON(
    csv: any,
    startWithFirstLine: boolean,
    numberOfLines: number
  ): Array<any> {
    let lines = csv.split("\n");
    let result = [];
    let headers: Array<string> = lines[0].split(",");
    numberOfLines = Math.min(numberOfLines, lines.length - 1);

    let startIndex, maxIndex;
    if (startWithFirstLine) {
      // we take the first <numberOfLines> lines
      startIndex = 1;
      maxIndex = numberOfLines + 1;
    } else {
      // we take the last <numberOfLines> lines
      maxIndex = lines.length - 1;
      startIndex = maxIndex - numberOfLines;
    }

    for (let i = startIndex; i < maxIndex; i++) {
      let obj: Object = {};
      let currentline = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        if (!headers[j]) {
          continue;
        }

        // @ts-ignore
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result;
  }

  convertToHuman(number: number) {
    return Math.abs(Number(number)) >= 1.0e6
      ? // Millions
        (number / 1.0e6)
          .toFixed(2)
          .toString()
          .replace(".", ",") + " Mio."
      : // Thousands
      Math.abs(Number(number)) >= 1.0e3
      ? (Math.round(number * 100) / 100)
          .toString()
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number
          .toFixed(2)
          .toString()
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  formatNumber(number: number) {
    return (
      (number > 0 ? "+" : "") +
      (Math.round(number * 100) / 100)
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  get title() {
    return this.getSettingsAttribute("title");
  }

  get popover() {
    const popover = this.getSettingsAttribute("popover");
    if (popover) {
      return LayoutComponent.createEmpty("popover", {
        content: {
          _id: popover._id
        },
        placement: "top",
        trigger: "click"
      });
    }

    return null;
  }

  get link() {
    return this.getSettingsAttribute("link");
  }

  get linkLabel() {
    return this.getSettingsAttribute("link_label");
  }

  get showDate() {
    return this.getSettingsAttribute("show_date");
  }
}
