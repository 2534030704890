
import { Component, Vue, Prop } from "vue-property-decorator";
import { InputConfig } from "../ContactForm.vue";

const indications = {
  gallengangskrebs: { label: "Gallengangskrebs" },
  leberkrebs: { label: "Leberkrebs" },
  chronischeNierenerkrankungen: { label: "Chronische Nierenerkrankungen" },
  respiratorischesSyncytialVirusRSV: {
    label: "Respiratorisches Syncytial-Virus (RSV)"
  },
  systemischerLupusErythematodesSLE: {
    label: "Systemischer Lupus erythematodes (SLE)"
  },
  prostatakrebs: { label: "Prostatakrebs" },
  bauchspeicheldruesenkrebs: { label: "Bauchspeicheldrüsenkrebs" },
  nasenpolypen: { label: "Nasenpolypen" },
  brustkrebs: { label: "Brustkrebs" },
  blutkrebs: { label: "Blutkrebs" },
  diabetes: { label: "Diabetes" },
  eierstockkrebs: { label: "Eierstockkrebs" },
  kardiovaskulaereErkrankungen: { label: "Kardiovaskuläre Erkrankungen" },
  atemwegserkrankungenZBAsthmaCOPD: {
    label: "Atemwegserkrankungen z. B. Asthma, COPD"
  },
  lungenkrebs: { label: "Lungenkrebs" }
};

@Component
export default class SelectIndication extends Vue {
  @Prop() formConfig!: InputConfig<string>;
  @Prop() isValid!: boolean;
  @Prop() isSubmitted!: boolean;
  @Prop() indicationSettings!: string;

  constructor() {
    super();
  }

  get indications() {
    return indications;
  }
}
