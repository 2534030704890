
import { Component, Vue } from "vue-property-decorator";
import { ExecutionQueue, router } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component({
  components: {}
})
export default class TabBar extends BaseComponent {
  selectedItem: any = null;

  getComponentType() {
    return "tab_bar_items";
  }

  constructor() {
    super();
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      let selectedItem = null;
      let activeTab = router.app.$route.query.active;

      // scroll inside tab content
      if (activeTab) {
        selectedItem = this.content.find((item: any) => item.slug == activeTab);

        this.selectedItem = selectedItem || this.content[0];
        return;
      }

      // scroll to tab header
      let hash = this.$router.currentRoute.hash;

      selectedItem = this.content.find((item: any) => `#${item.slug}` == hash);

      if (selectedItem) {
        this.selectedItem = selectedItem;
        ExecutionQueue.addElement(this.scrollToItem);
      } else {
        this.selectedItem = this.content[0];
      }
    });
  }

  selectItem(item: any): void {
    if (item.id != this.selectedItem.id) {
      this.selectedItem = item;
    }
  }

  scrollToItem(): void {
    if (this.selectedItem) {
      let element = document.querySelector(
        `#${this.selectedItem.slug}`
      ) as HTMLElement;
      let offsetTop = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo(0, offsetTop);
    }
  }
}
