
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Event,
  EventRepository,
  LayoutComponent,
  ResultType,
  Store,
  User,
  UserLoginStatus
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

/**
 * @todo event images when api delivers them
 */

@Component
export default class Detail extends BaseComponent {
  @Prop() event!: Event;
  private speakerImages!: any;
  private eventImage!: string;

  constructor() {
    super();
    this.speakerImages = {};
    this.eventImage = "";
  }

  get has_access() {
    if (User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      return false;
    }

    return User.getProperty("login_with_pw");
  }

  async mounted() {
    if (Store.matomoEnabled()) {
      // @ts-ignore
      window._paq.push([
        "trackEvent",
        // @ts-ignore
        process.env.VUE_APP_MATOMO_EVENT_CATEGORY,
        "View_Event",
        this.event.idVeeva
      ]);
    }

    await this.loadSpeakerImages();

    if (this.event.documents && this.event.documents.length > 0) {
      this.eventImage = await this.eventImageUrl(
        this.event.documents[0].documentURL
      );
    }
  }

  get lastName() {
    return this.has_access ? User.getProperty("lastName") : "";
  }

  get firstName() {
    return this.has_access ? User.getProperty("firstName") : "";
  }

  get title() {
    return this.has_access ? User.getProperty("title") : "";
  }

  get email() {
    return this.has_access ? User.getProperty("email") : "";
  }

  getSpeakerImageForId(speakerId: any) {
    return this.speakerImages[speakerId];
  }

  async loadSpeakerImages() {
    if (!this.event.sessions) {
      return;
    }

    this.event.sessions.forEach(async (session: any) => {
      let speakerImageURL = await this.speakerImageUrl(session.speakerImageURL);
      let speakerId = session.speakerId;

      /* see https://vuejs.org/v2/guide/reactivity.html#For-Objects */
      Vue.set(this.speakerImages, speakerId, speakerImageURL);
    });
  }

  async speakerImageUrl(url: string) {
    return this.imageAPICall(url, "speaker");
  }

  async eventImageUrl(url: string) {
    return this.imageAPICall(url, "event");
  }

  async imageAPICall(url: string, type: string) {
    try {
      if (url) {
        let docId: any = url.split("/").slice(-1);
        let promise: any = null;

        switch (type) {
          case "event":
            promise = EventRepository.getEventDocument(docId);
            break;
          case "speaker":
            promise = EventRepository.getSpeakerImage(docId);
            break;
          default:
            break;
        }

        if (!promise) {
          return "";
        }

        let imageUrl = await promise
          .then(this.validateResponse)
          .then((response: any) => response.blob())
          .then((blob: any) => {
            return URL.createObjectURL(blob);
          });
        return imageUrl;
      }
      return "";
    } catch (e) {
      return "";
    }
  }

  validateResponse(response: any) {
    if (response.type == ResultType.FAIL) {
      throw Error(response.statusText);
    }

    return response.value;
  }

  showModal() {
    Store.modal.title = `Anmeldung für ${this.event.eventTitle}`;
    Store.modal.setLayoutComponent(
      LayoutComponent.createEmpty("register_modal", {
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        event: this.event
      })
    );
    Store.modal.showModal();
  }

  replaceNewLines(value: any) {
    return value.replace(/\r\n/g, "<br />");
  }
}
