import config, { FrontendModules } from "../config";
import { EventBus, READSPEAKER_LOADED } from "../util/EventBus";

export default class ReadSpeaker {
  private static instance?: ReadSpeaker;

  public static getInstance(): ReadSpeaker {
    if (!this.instance) {
      this.instance = new ReadSpeaker();
    }

    return this.instance;
  }

  initialize() {
    if (
      config
        .getFrontendConfiguration()
        .modules.includes(FrontendModules.READSPEAKER)
    ) {
      let scriptUrl =
        "//cdn-eu.readspeaker.com/script/12461/webReader/webReader.js?pids=wr";

      //@ts-ignore
      window.rsConf = {
        params: scriptUrl,
        general: { usePost: true, skipHiddenContent: true }
      };

      let readSpeakerHead = document.createElement("script");
      readSpeakerHead.setAttribute("id", "rs_req_Init");
      readSpeakerHead.setAttribute("type", "text/javascript");
      readSpeakerHead.setAttribute("src", scriptUrl);

      readSpeakerHead.onload = () => {
        EventBus.$emit(READSPEAKER_LOADED);
      };

      document.head.appendChild(readSpeakerHead);
    }
  }
}
