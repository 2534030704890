
import {
  LayoutComponent,
  ResultType,
  Store,
  User,
  UserRepository
} from "common";
import validator from "validator";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Profile extends Vue {
  showChangePasswordMask!: boolean;

  oldPassword!: string;
  newPassword!: string;
  newPasswordRepeat!: string;

  attemptSubmit!: boolean;

  changePasswordResult!: string;
  passwordResult!: "success" | "error";

  constructor() {
    super();
    this.showChangePasswordMask = false;
    this.oldPassword = "";
    this.newPassword = "";
    this.newPasswordRepeat = "";
    this.attemptSubmit = false;
    this.changePasswordResult = "";
    this.passwordResult = "success";
  }

  get user() {
    return User;
  }

  get divider() {
    return LayoutComponent.createEmpty("divider", {
      height: "enormous",
      with_line: true
    });
  }

  get validateOldPassword() {
    return !validator.isEmpty(this.oldPassword);
  }

  get validateNewPassword() {
    return (
      !validator.isEmpty(this.newPassword) &&
      validator.equals(this.newPassword, this.newPasswordRepeat)
    );
  }

  get validateNewPasswordRepeat() {
    return (
      !validator.isEmpty(this.newPasswordRepeat) &&
      validator.equals(this.newPassword, this.newPasswordRepeat)
    );
  }

  triggerChangePassword() {
    this.showChangePasswordMask = true;
  }

  triggerDeleteProfile() {
    this.showModal("Profil löschen", "delete_profile");
  }

  triggerDeleteBookmarks() {
    this.showModal("Bookmarkliste löschen", "delete_bookmark_list");
  }

  cancel() {
    this.showChangePasswordMask = false;
  }

  showModal(title: string, component: string) {
    Store.modal.title = title;
    Store.modal.className = "modal-md";
    Store.modal.setLayoutComponent(LayoutComponent.createEmpty(component));
    Store.modal.showModal();
  }

  resetPasswordValues() {
    this.oldPassword = "";
    this.newPassword = "";
    this.newPasswordRepeat = "";
  }

  async validate() {
    this.attemptSubmit = true;

    if (
      this.validateOldPassword &&
      this.validateNewPassword &&
      this.validateNewPasswordRepeat
    ) {
      let result = await UserRepository.changePassword(
        this.oldPassword,
        this.newPassword,
        this.newPasswordRepeat
      );

      if (result.type == ResultType.SUCCESS) {
        this.passwordResult = "success";
        this.showChangePasswordMask = false;
        this.attemptSubmit = false;
        this.changePasswordResult = "Ihr Passwort wurde erfolgreich geändert.";
        this.resetPasswordValues();
        setTimeout(() => {
          this.changePasswordResult = "";
        }, 2000);
      } else {
        this.passwordResult = "error";
        this.attemptSubmit = false;
        this.changePasswordResult =
          "Das von Ihnen angegebene Passwort war falsch. Bitte versuchen Sie es erneut.";
      }
    }
  }
}
