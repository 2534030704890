
import { Component } from "vue-property-decorator";
import validator from "validator";
import {
  ExpertForumRepository,
  router,
  Store,
  matomo,
  MatomoEventNames
} from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ThreadForm extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;
  public requestSent: boolean;
  public questionSaved: boolean;

  public title: string;
  public body: string;

  public apiError: boolean;
  public resultMessage: string;
  public token: string;
  public expertforumSlug: string;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.requestSent = false;
    this.questionSaved = false;
    this.title = "";
    this.body = "";
    this.apiError = false;
    this.resultMessage = "";
    this.token = sessionStorage.getItem(`expert-forum-${Store.frontend}`) || "";
    this.expertforumSlug = `/${process.env.VUE_APP_EXPERTFORUM_SLUG}/forum`;
  }

  async mounted() {
    super.mounted();

    if (!this.token) {
      router.push(`/login`);
    }
  }

  get validateTitle() {
    return !validator.isEmpty(this.title);
  }

  get validateBody() {
    return !validator.isEmpty(this.body);
  }

  autoGrow() {
    let textarea = this.$refs.textarea as HTMLElement;
    textarea.style.height = textarea.scrollHeight + "px";
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validateTitle && this.validateBody) {
      this.submitThread();
    } else {
      this.focusFirstError();
    }
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
        this.requestSent = false;
        this.isLoading = false;
      }
    }, 1);
  }

  async submitThread() {
    const thread = {
      title: this.title,
      body: this.body
    };
    const website = Store.frontend;

    let result = await ExpertForumRepository.postThread(
      this.token,
      website,
      thread
    );
    this.isLoading = false;
    this.requestSent = true;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      // Matomo tracking
      matomo.genericEvent(
        sessionStorage.getItem(`expert-forum-${Store.frontend}-user`) || "",
        matomo.MATOMO_EVENT_CATEGORY,
        MatomoEventNames.EXPERT_FORUM_SEND_QUESTION
      );

      this.resultMessage = "Anfrage übermittelt.";
      this.apiError = false;
      this.title = "";
      this.body = "";
      this.attemptSubmit = false;
      this.questionSaved = true;

      setTimeout(() => {
        router.push(this.expertforumSlug);
      }, 5000);
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
