
import { Component, Vue } from "vue-property-decorator";
import { LayoutComponent } from "common";
import moment from "moment";
import BaseWidget from "./BaseWidget.vue";

@Component
export default class Countdown extends BaseWidget {
  private timeLeft!: any;
  public days!: number;
  public hours!: number;
  public minutes!: number;
  private interval!: ReturnType<typeof setInterval>;
  public countdownEnded!: boolean;

  constructor() {
    super();

    this.timeLeft = null;
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.countdownEnded = false;
  }

  async mounted() {
    super.mounted();
  }

  async contentDidLoad() {
    super.contentDidLoad();

    Vue.nextTick(() => {
      if (this.date && this.time) {
        this.setTime();
      }
    });
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  getComponentType() {
    return "countdowns";
  }

  get date() {
    const date = this.getContentAttribute("end_date");
    return date;
  }

  get time() {
    const time = this.getContentAttribute("end_time");
    return time;
  }

  get link() {
    const link = this.getContentAttribute("link");

    if (link && link.length > 0) {
      return new LayoutComponent(link[0]);
    }

    return null;
  }

  setTime() {
    const now = Date.now();
    const endDate = parseInt(
      moment(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm")
        .zone("+02:00")
        .format("x")
    );
    this.timeLeft = endDate - now;

    if (this.timeLeft <= 0) {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.countdownEnded = true;
      return;
    }

    const millisecondsPerHour = 3600 * 1000;

    this.days = Math.floor(this.timeLeft / (24 * millisecondsPerHour));
    this.hours = Math.floor(
      (this.timeLeft - this.days * 24 * millisecondsPerHour) /
        millisecondsPerHour
    );
    this.minutes = Math.floor(
      (this.timeLeft -
        this.days * 24 * millisecondsPerHour -
        this.hours * millisecondsPerHour) /
        (60 * 1000)
    );

    const firstMinuteExtra =
      this.timeLeft -
      this.days * 24 * millisecondsPerHour -
      this.hours * millisecondsPerHour -
      this.minutes * 60000;

    /**
     * We shouldn't start the interval countdown right away, because the
     * number of milliseconds left may be under a minute.
     *
     * So the first minute will pass after the difference of the milliseconds passed
     * & afterwards we start the interval.
     */
    new Promise((resolve, reject) => {
      // Wait the extra milliseconds
      setTimeout(() => {
        resolve("");
      }, firstMinuteExtra);
    }).then(() => {
      this.interval = setInterval(() => {
        if (this.minutes - 1 >= 0) {
          this.minutes -= 1;

          // Check if we should stop
          if (this.minutes === 0 && this.days === 0 && this.hours === 0) {
            this.countdownEnded = true;

            // Stop the interval
            clearInterval(this.interval);
          }
        } else {
          // Reset the minutes
          this.minutes = 59;

          // Reduce the hours
          if (this.hours - 1 >= 0) {
            this.hours -= 1;
          } else {
            // Reduce the days & reset the hours
            if (this.days - 1 >= 0) {
              this.days -= 1;
              this.hours = 23;
            }
          }
        }
      }, 60000);
    });
  }
}
