
import { Component } from "vue-property-decorator";
import { router, User, UserLoginStatus } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class OfflabelAccess extends BaseComponent {
  get username() {
    if (!User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT)) {
      return `${User.getProperty("title")} ${User.getProperty(
        "firstName"
      )} ${User.getProperty("lastName")}`;
    }
    return false;
  }

  get logged_in() {
    return !User.hasUserLoginStatus(UserLoginStatus.LOGGED_OUT);
  }

  get crossIcon() {
    return require("@/assets/cross-dark.svg");
  }

  back() {
    router.push("/esc-kongress-2022");
  }
}
