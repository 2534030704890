
import { Component } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class DosageCalculator extends BaseComponent {
  public mlDisplayValue: string;

  constructor() {
    super();

    this.mlDisplayValue = "0,00";
  }

  getComponentType() {
    return "dosage_calculator";
  }

  async mounted() {
    super.mounted();
  }

  get cssClasses() {
    return this.layoutComponent.settings.class;
  }

  get visibility() {
    return this.layoutComponent.settings.visibility;
  }

  handleCalculatorChange(e: any) {
    const kgValue = e.target
      ? parseFloat(e.target.value ? e.target.value.replace(",", ".") : null)
      : null;

    if (kgValue) {
      const mlValue = (kgValue * 15) / 100;

      if (mlValue) {
        this.mlDisplayValue = `${mlValue.toFixed(2)}`.replace(".", ",");
      } else {
        this.mlDisplayValue = "0,00";
      }
    } else {
      this.mlDisplayValue = "0,00";
    }
  }
}
