
import { Component, Vue } from "vue-property-decorator";
import BaseComponent from "../base/BaseComponent.vue";

export enum Placement {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left"
}

@Component
export default class Drawer extends BaseComponent {
  public visible!: boolean;
  public placement!: Placement;
  public showOpenButton!: boolean;

  constructor() {
    super();
    this.visible = false;
    this.placement = Placement.RIGHT;
    this.showOpenButton = false;
  }

  getComponentType() {
    return "drawers";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    Vue.nextTick(() => {
      const customPlacement = this.getSettingsAttribute("placement");
      if (customPlacement) {
        this.placement = customPlacement;
      }
      this.showOpenButton = this.getSettingsAttribute("show_open_button");
    });
  }

  get contentItems() {
    return this.getContentAttribute("content");
  }

  get headerText() {
    const text = this.getSettingsAttribute("header_text");
    if (text && text.trim().length > 0) {
      return text;
    }
    return null;
  }

  get buttonText() {
    const text = this.getSettingsAttribute("button_text");
    if (text && text.trim().length > 0) {
      return text;
    }
    return "Open Drawer";
  }

  showDrawer() {
    this.visible = true;
  }

  onClose() {
    this.visible = false;
  }

  toggleVisibility() {
    this.visible = !this.visible;
  }
}
