
import { Vue, Component, Prop } from "vue-property-decorator";
import QRCode from "qrcode";
import _, { result } from "lodash";
import { BaseComponent, LoadingAnimation } from "vue-ui";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  ConfiguratorRepository,
  Helper,
  LayoutComponent,
  ResultType,
  User,
  UserLoginStatus,
  ContentRepository,
  Store
} from "common";
import uniq from "lodash/uniq";

@Component({
  components: {
    LoadingAnimation
  }
})
export default class ConfiguratorProgress1 extends BaseComponent {
  /**
   * Current step and progress, the user is in
   */
  @Prop() public step!: number;

  // ==== Progress 2, Step 3 ====
  @Prop() public content: any[] = [];
  @Prop() public indicationHasSubPages!: boolean;

  get selectedContent() {
    return this.content;
  }

  get selectedContentChildren() {
    return this.choosableContent[0].selects.find(
      (sel: any) => sel.title == (this.content as any).title
    ).children;
  }

  /**
   * filled by api requests
   */
  @Prop() public choosableIndications!: LayoutComponent[];
  @Prop() public choosableContent!: any[];
  @Prop() public indicationsKey!: string;

  @Prop() public loadingStep1!: boolean;
  @Prop() public loadingStep2!: boolean;
  @Prop() public isChoosableIndicationsLoading!: boolean;

  constructor() {
    super();
  }

  ommitedContent(content: any, key: any): Pick<any, any> {
    return _.omit(content, key);
  }

  // async mounted() {
  //   super.mounted();
  //   this.loadingStep1 = true;
  //   let result = await ConfiguratorRepository.getIndications();

  //   if (result.type == ResultType.SUCCESS) {
  //     this.choosableIndications = result.value;
  //   }

  //   this.resetToStep1();
  //   this.loadingStep1 = false;
  // }
}
