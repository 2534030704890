import AbstractRepository from "./AbstractRepository";
import Constants from "../../models/Constants";
import Client from "../util/Client";

export enum ContactContext {
  Quiz = "quiz",
  Tagrisso = "tagrisso",
  Imfinzi = "imfinzi",
  Calquence = "calquence",
  Lynparza = "lynparza",
  Medical = "medical",
  Lokelma = "lokelma",
  Fasttrack = "fasttrack"
}

class ContactRepository extends AbstractRepository {
  async save(
    context: ContactContext,
    slug: String,
    options: Object,
    contact: Object
  ) {
    const data = {
      source: context,
      slug: slug,
      options: options,
      contact: contact
    };

    let response = await Client.postWithJsonResult(
      `${Constants.apiUrl}/contact`,
      data,
      this.getAdditionalHeader()
    );

    let result = this.getResult<any, any>(response, (r: any) => {
      return r;
    });

    return result;
  }
}

export default new ContactRepository();
