var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"patient-password-reset"},[(!_vm.passUpdateSucceeded)?_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label-comp",attrs:{"for":"password"}},[_vm._v("Neues Passwort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePassword,
              'is-valid': _vm.attemptSubmit && _vm.validatePassword
            },attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v(" Hinweis: Passwörter müssen mindestens 8 Zeichen lang sein und mindestens 1 Großbuchstaben (A-Z), 1 Zahl (0-9) und 1 Sonderzeichen (!”§$%&/()=?*#) enthalten. ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label-comp",attrs:{"for":"passwordRepeat"}},[_vm._v(" Neues Passwort wiederholen* ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordRepeat),expression:"passwordRepeat"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validatePasswordRepeat,
              'is-valid': _vm.attemptSubmit && _vm.validatePasswordRepeat
            },attrs:{"type":"password","id":"passwordRepeat"},domProps:{"value":(_vm.passwordRepeat)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordRepeat=$event.target.value}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner-border",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Absenden ")])])]),(_vm.showError)?_c('div',{staticClass:"patient-pass-reset__errors"},_vm._l((_vm.errorMessages),function(error,idx){return _c('div',{key:idx,staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e()]):_c('div',{staticClass:"patient-pass-reset__success p-3 mt-5 mb-8 rounded"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._m(0),_c('div',{staticClass:"patient-pass-reset__success-text px-3 flex-fill",domProps:{"innerHTML":_vm._s(_vm.successMessage)}})]),_c('div',{staticClass:"text-right mt-4"},[_c('router-link',{staticClass:"btn btn-link",attrs:{"to":"/"}},[_vm._v(" Zurück zur Startseite ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"patient-pass-reset__success-icon"},[_c('i',{staticClass:"far fa-check-circle display-4 text-success"})])
}]

export { render, staticRenderFns }