
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class InformationPortalTemplate extends Vue {
  @Prop() content!: any;

  get address() {
    try {
      return this.content[0].address;
    } catch (e) {
      return null;
    }
  }

  get email() {
    try {
      return this.content[0].email;
    } catch (e) {
      return null;
    }
  }

  get id() {
    try {
      return this.content[0].id;
    } catch (e) {
      return null;
    }
  }

  get name() {
    try {
      return this.content[0].name;
    } catch (e) {
      return null;
    }
  }

  get phone() {
    try {
      return this.content[0].phone;
    } catch (e) {
      return null;
    }
  }

  get website() {
    try {
      return this.content[0].website;
    } catch (e) {
      return null;
    }
  }

  get link() {
    if (this.website) {
      if (!this.website.includes("http")) {
        return `https://${this.website}`;
      }
      return this.website;
    }

    return "#";
  }
}
