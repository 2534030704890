
import { Component } from "vue-property-decorator";
import validator from "validator";
import { ExpertForumRepository, router, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class ExpertforumPatientChangePassword extends BaseComponent {
  public isLoading: boolean;
  public attemptSubmit: boolean;

  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;

  public errorPassword: string;
  public apiError: boolean;
  public resultMessage: string;
  public requestSent: boolean;
  public token: string;
  public username: string;
  public passwordOptions: any;

  constructor() {
    super();

    this.isLoading = false;
    this.attemptSubmit = false;
    this.currentPassword = "";
    this.newPassword = "";
    this.confirmPassword = "";
    this.errorPassword = "";
    this.apiError = false;
    this.resultMessage = "";
    this.requestSent = false;
    this.token = sessionStorage.getItem(`expert-forum-${Store.frontend}`) || "";
    this.username =
      sessionStorage.getItem(`expert-forum-${Store.frontend}-user`) || "";
    this.passwordOptions = {
      minLength: 10,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1
    };
  }

  async mounted() {
    super.mounted();

    if (!this.token || !this.username) {
      router.push(`/login`);
    }
  }

  get validatePassword() {
    if (
      validator.isEmpty(this.newPassword) ||
      !validator.isStrongPassword(this.newPassword, this.passwordOptions)
    ) {
      this.errorPassword =
        "Das Passwort muss mindestens 10 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten";
    } else {
      this.errorPassword = "";
    }

    return (
      !validator.isEmpty(this.newPassword) &&
      validator.isStrongPassword(this.newPassword, this.passwordOptions)
    );
  }

  get validateConfirmPassword() {
    return (
      !validator.isEmpty(this.confirmPassword) &&
      validator.equals(this.confirmPassword, this.newPassword)
    );
  }

  get validateCurrentPassword() {
    return !validator.isEmpty(this.currentPassword);
  }

  validate(e: any) {
    this.isLoading = true;
    this.attemptSubmit = true;
    e.preventDefault();
    e.stopPropagation();

    if (this.validatePassword && this.validateConfirmPassword) {
      const website = Store.frontend;
      this.sendRequest(
        this.currentPassword,
        this.newPassword,
        website,
        this.token
      );
    } else {
      this.focusFirstError();
    }
  }

  focusFirstError() {
    setTimeout(() => {
      const invalid = this.$el.querySelector(".is-invalid");
      if (invalid && invalid instanceof HTMLElement) {
        (invalid as HTMLElement).focus();
      }
      this.requestSent = false;
      this.isLoading = false;
    }, 1);
  }

  async sendRequest(
    currentPassword: string,
    newPassword: string,
    website: string,
    token: string
  ) {
    let result = await ExpertForumRepository.changePassword(
      currentPassword,
      newPassword,
      website,
      token
    );
    this.requestSent = true;
    this.isLoading = false;
    this._processResult(result);
  }

  _processResult(result: any) {
    if (result.value && result.value.success) {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.attemptSubmit = false;
      this.resultMessage = "Passwort erfolgreich geändert.";
      this.apiError = false;
    } else {
      try {
        this.resultMessage = "";

        let errors = Object.values(JSON.parse(result.raw).errors);

        // @ts-ignore
        errors.forEach(lines => {
          // @ts-ignore
          lines.forEach(error => {
            this.resultMessage += `<div>${error}</div>`;
          });
        });
      } catch (error) {
        this.resultMessage =
          "Ein Fehler ist aufgetreten. Bitte versuche es erneut.";
      }

      this.apiError = true;
    }
  }
}
