
import { Component } from "vue-property-decorator";
import $ from "jquery";
import { EventBus, LCMODAL_CLOSED, LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";
import KalturaVideo from "../media/KalturaVideo.vue";

@Component
export default class CardVideo extends BaseComponent {
  public uuid!: string;

  constructor() {
    super();

    this.uuid = this.generateUuid();
  }

  generateUuid() {
    return (
      Date.now().toString(36) +
      Math.random()
        .toString(36)
        .substring(2)
    );
  }

  getComponentType() {
    return "card_videos";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();

    this.$nextTick(() => {
      if (!this.video) {
        return;
      }

      $(this.$refs.modal).on("hidden.bs.modal", () => {
        EventBus.$emit(LCMODAL_CLOSED, [this.videoIdentifier]);
      });
    });
  }

  get video() {
    let video = this.getContentAttribute("video");

    if (video && video.length > 0) {
      return new LayoutComponent(video[0]);
    }

    return null;
  }

  get videoIdentifier() {
    if (!this.video) {
      return "";
    }

    if (this.video.component === "KalturaVideo") {
      return this.video.settings.content.display;
    }

    if (this.video.component === "YoutubeVideo") {
      return this.video._id;
    }

    return "";
  }

  get image() {
    let image = this.getContentAttribute("video_image");

    if (image && image.length > 0) {
      return new LayoutComponent(image[0]);
    }

    return null;
  }

  get contentItems() {
    let contentItems = this.getContentAttribute("content");

    if (contentItems && contentItems.length > 0) {
      return contentItems.map((comp: any) => new LayoutComponent(comp));
    }

    return [];
  }
}
