
import { Component } from "vue-property-decorator";
import { OfflabelRepository, ResultType, router, Store } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class OfflabelModal extends BaseComponent {
  checkedSnippetIds: Array<any> = [];
  offlabelSnippets: Array<any> = [];
  veevaEventIds: Array<string> = [];
  showErrorText: boolean = false;
  isSelected: boolean = false;

  async mounted() {
    let result = await OfflabelRepository.getOfflabelSnippetsForOfflineStub(
      this.offlabelStubId
    );
    if (result.type == ResultType.SUCCESS) {
      this.offlabelSnippets = result.value;
      for (let i = 0, len = this.offlabelSnippets.length; i < len; i++) {
        this.veevaEventIds.push(this.offlabelSnippets[i].event_id);
      }
    }
    await super.mounted();
  }

  get itemOfInterest() {
    return this.layoutComponent
      ? this.layoutComponent.settings.itemOfInterest
      : null;
  }

  get email() {
    return this.layoutComponent ? this.layoutComponent.settings.email : null;
  }

  get showContactDetails() {
    return this.layoutComponent
      ? this.layoutComponent.settings.showContactDetails
      : false;
  }

  get offlabelStubId() {
    return this.layoutComponent
      ? this.layoutComponent.settings.offlabelStubId
      : null;
  }

  allOfflabelSnippetsIds(): any[] {
    const selected: any[] = [];

    if (!this.isSelected) {
      this.offlabelSnippets.forEach(val => selected.push(val._id));
      if (selected.length > 0) {
        this.checkedSnippetIds = selected;
        this.isSelected = !this.isSelected;
      }
      return selected;
    } else {
      selected.splice(0, selected.length);
      this.checkedSnippetIds = selected;
      this.isSelected = !this.isSelected;
    }
    return [];
  }

  async requestArticleCreation() {
    if (this.checkedSnippetIds.length < 1) {
      this.showErrorText = true;
      return;
    }

    let offlabelContent: Array<Object> = [];

    for (let i = 0, len = this.checkedSnippetIds.length; i < len; i++) {
      let snippet = this.offlabelSnippets.find(
        offlabelSnippet => offlabelSnippet._id == this.checkedSnippetIds[i]
      );
      offlabelContent.push({
        veevaEventId: snippet.event_id,
        title: snippet.label
      });
    }

    let result = await OfflabelRepository.requestOfflabelArticleCreation(
      this.offlabelStubId,
      this.checkedSnippetIds,
      offlabelContent
    );

    if (result.type == ResultType.SUCCESS) {
      let link = "/" + result.value.slug;
      Store.modal.hideModal();
      await router.push(link);
    } else {
      await router.push("/error");
    }
  }
}
