
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { isEmpty, has } from "lodash";
import $ from "jquery";
import { Helper, regExpEscape, User } from "common";
import BaseComponent from "../base/BaseComponent.vue";

interface Tooltips {
  trigger: string;
  placement: string;
  data: object;
}

@Component
export default class LCText extends BaseComponent {
  @Prop({ default: -1 }) maxWords!: number;

  public tooltips!: Tooltips;
  public textToRender!: string;
  public tooltipTimeout!: any;

  constructor() {
    super();
    this.tooltips = {
      trigger: "hover focus",
      placement: "top",
      data: {}
    };
    this.textToRender = "";
  }

  getComponentType() {
    return "texts";
  }

  async mounted() {
    super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();

    if (
      this.hasTooltips &&
      !isEmpty(this.metaContent) &&
      has(this.metaContent, "tooltips")
    ) {
      Object.assign(this.tooltips, this.metaContent.tooltips);
    }

    Vue.nextTick(() => {
      if (Object.entries(this.tooltips.data).length) {
        this.textToRender = this.getTextWithTooltips();

        // wait for tooltips to be rendered in DOM before initializing them
        this.tooltipTimeout = setTimeout(() => {
          ($('[data-toggle="tooltip"]') as any).tooltip();
        }, 100);
      } else {
        this.textToRender = this.reduceWords(this.replaceMarker(this.text));
      }

      // @ts-ignore
      let text = this.$refs.text;
      let counter = 0;
      let intervalCheckLinks = setInterval(checkLinks, 500, text, counter);

      function checkLinks(text: any, counter: number) {
        counter++;

        if (text.innerHTML) {
          let allLinks = text.getElementsByTagName("a");
          if (allLinks.length) {
            Helper.crawlDomAndAddEventlistenerToAllLinks(Array.from(allLinks));
          }
          clearInterval(intervalCheckLinks);
        } else if (counter > 10) {
          clearInterval(intervalCheckLinks);
        }
      }
    });
  }

  beforeUnmount() {
    clearTimeout(this.tooltipTimeout);
  }

  get text() {
    return this.getContentAttribute("text");
  }

  get type() {
    return this.getSettingsAttribute("type");
  }

  get metaContent() {
    return this.getContentAttribute("meta");
  }

  get hasTooltips() {
    return !!this.getSettingsAttribute("has_tooltip");
  }

  get textToRenderIncludingEllipsis() {
    let ellipsis = "";
    if (this.maxWords > -1 && this.wordCount() >= this.maxWords) {
      ellipsis = " ...";
    }

    return this.textToRender + ellipsis;
  }

  getTextWithTooltips() {
    let tooltipsKeys: Array<string> = [];
    let matchedText: Array<string> = [];
    let result: Array<string> = [];

    if (
      !isEmpty(this.tooltips) &&
      Object.prototype.hasOwnProperty.call(this.tooltips, "data")
    ) {
      const tooltipsData: any = this.tooltips.data;
      let pattern: RegExp | null = null;

      tooltipsKeys = Object.keys(tooltipsData).map(key => regExpEscape(key));

      // generate regex pattern
      pattern = tooltipsKeys.length
        ? new RegExp(`(${tooltipsKeys.join("|")}w*)`, "gi")
        : null;

      // split html based on regex pattern
      matchedText = pattern ? this.text.split(pattern) : [];

      // add tooltips on matched words/expressions
      result = matchedText.map(item => {
        if (Object.prototype.hasOwnProperty.call(tooltipsData, item)) {
          return `<i class="tooltip-toggle text-primary" data-toggle="tooltip" data-placement="${this.tooltips.placement}" data-trigger="${this.tooltips.trigger}" title="${tooltipsData[item]}" data-container="#app > div:first-child">${item}</i>`;
        }
        return item;
      });
    } else {
      tooltipsKeys = [];
      matchedText = [];
      result = [];
    }

    return result ? result.join("").trim() : this.text;
  }

  replaceMarker(text: string) {
    if (text != null) {
      text = text.replace("$username", User.getProperty("username") || "");
    }
    return text;
  }

  reduceWords(text: string): string {
    if (this.maxWords > -1) {
      text = text
        .split(" ")
        .slice(0, this.maxWords)
        .join(" ");
    }

    return text;
  }

  @Watch("maxWords")
  updateText() {
    this.textToRender = this.reduceWords(this.replaceMarker(this.text));
  }

  wordCount() {
    return this.text.split(" ").length;
  }
}
