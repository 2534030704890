
import { Vue, Component } from "vue-property-decorator";
import { GlossaryRepository, Helper, LayoutComponent } from "common";
import BaseComponent from "../base/BaseComponent.vue";

@Component
export default class Glossary extends BaseComponent {
  private items: LayoutComponent[];
  public itemsToRender: LayoutComponent[];
  private selectedLetter: any = null;

  constructor() {
    super();
    this.items = [];
    this.itemsToRender = [];
    this.handleLettersClick();
  }

  getComponentType() {
    return "glossaries";
  }

  async mounted() {
    await super.mounted();
  }

  contentDidLoad() {
    super.contentDidLoad();
    Vue.nextTick(async () => {
      this.setItems();
    });
  }

  get scrollOffset() {
    const offset = this.getContentAttribute("scroll_offset").trim();

    if (offset) {
      return parseInt(offset, 10);
    }
    return 0;
  }

  get isFilterActive() {
    return !!this.getContentAttribute("filter");
  }

  get useAccordionItems() {
    return !!this.getContentAttribute("use_accordion_items");
  }

  private setItems() {
    const items = this.getContentAttribute("content");

    if (items && items.length > 0) {
      items.forEach((currentItem: any) => {
        const glossaryLayoutComp: LayoutComponent | null = currentItem
          ? new LayoutComponent(currentItem)
          : null;

        this.items.push(glossaryLayoutComp as LayoutComponent);
        this.itemsToRender = [...this.items];
      });
    }
  }

  scrollToGlossaryLetter(elementHref: any) {
    const glossaryElement: any = document.querySelector(elementHref);
    if (glossaryElement) {
      Helper.smoothScrollTo(
        glossaryElement as HTMLElement,
        (glossaryElement as HTMLElement).getBoundingClientRect().top +
          window.scrollY -
          this.scrollOffset
      );
    }
  }

  filterGlossaryItems(event: any) {
    let selectedLetter =
      event && event.target ? event.target.textContent : null;
    selectedLetter = selectedLetter ? selectedLetter.toLowerCase() : null;

    if (selectedLetter) {
      this.removeAllDOMSelectedLetters();

      let hasToReset = false;
      if (
        event.target.href &&
        event.target.href.substr(-15) === "#glossary-reset"
      ) {
        hasToReset = true;
      }

      if (
        (this.selectedLetter && this.selectedLetter === selectedLetter) ||
        hasToReset
      ) {
        this.selectedLetter = null;
        this.itemsToRender = [...this.items];
        return;
      }

      this.itemsToRender = [
        this.items[0] // this is the navigation letters component
      ];

      event.target.classList.add("selected");

      this.selectedLetter = selectedLetter;

      GlossaryRepository.getFilteredItems(
        this.items.map(item => item.settings.content._id),
        selectedLetter,
        "glossary_items"
      ).then((res: any) => {
        this.itemsToRender = [
          this.items[0], // this is the navigation letters component
          ...res.value.map((item: any) => {
            return LayoutComponent.createFromCollectionItem(
              "glossary_item",
              item
            );
          })
        ];
      });
    }
  }

  handleLetterClick(event: any) {
    const elementClicked: any = event.target;
    const elementHref: string | null = elementClicked
      ? elementClicked.getAttribute("href")
      : null;

    if (elementHref && elementHref.indexOf("#glossary-") > -1) {
      event.preventDefault();

      if (this.isFilterActive) {
        this.filterGlossaryItems(event);
      } else {
        this.scrollToGlossaryLetter(elementHref);
      }
    }
  }

  handleLettersClick() {
    document.addEventListener("click", this.handleLetterClick);
  }

  /**
   * Remove selected class from letters navigation
   */
  removeAllDOMSelectedLetters() {
    const lettersElements = document.querySelectorAll(
      ".glossary__letters-navigation a"
    );

    for (let i = 0; i < lettersElements.length; i++) {
      lettersElements[i].classList.remove("selected");
    }
  }

  beforeUnmount() {
    document.removeEventListener("click", this.handleLetterClick);
  }
}
