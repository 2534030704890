var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"quiz__result-body",attrs:{"data-component":_vm.componentType,"data-id":_vm.internalId}},[_c('div',{staticClass:"quiz__category"},[_vm._v(_vm._s(_vm.trainingCategory))]),_c('span',{staticClass:"quiz__result-checkmark"}),_c('div',{staticClass:"quiz__result-headline"},[(_vm.thankyou)?_c(_vm.thankyou.component,{tag:"component",attrs:{"layoutComponent":_vm.thankyou}}):_vm._e()],1),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 px-10"},[_c('form',{staticClass:"quiz__result-form",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Vorname und Nachname*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{
            'is-invalid': _vm.attemptSubmit && !_vm.validateName,
            'is-valid': _vm.attemptSubmit && _vm.validateName
          },attrs:{"name":"name","type":"text","placeholder":"Vorname und Nachname"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('label',{attrs:{"for":"praxisort"}},[_vm._v("Praxisort*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.praxisort),expression:"praxisort"}],class:{
            'is-invalid': _vm.attemptSubmit && !_vm.validatePraxisort,
            'is-valid': _vm.attemptSubmit && _vm.validatePraxisort
          },attrs:{"name":"praxisort","type":"text","placeholder":"Praxisort"},domProps:{"value":(_vm.praxisort)},on:{"input":function($event){if($event.target.composing)return;_vm.praxisort=$event.target.value}}}),_c('label',{attrs:{"for":"efn"}},[_vm._v("EFN-Nummer*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.efn),expression:"efn"}],class:{
            'is-invalid': _vm.attemptSubmit && !_vm.validateEfn,
            'is-valid': _vm.attemptSubmit && _vm.validateEfn
          },attrs:{"name":"efn","type":"text","placeholder":"EFN-Nummer"},domProps:{"value":(_vm.efn)},on:{"input":function($event){if($event.target.composing)return;_vm.efn=$event.target.value}}}),_c('button',{staticClass:"link btn btn-primary training-btn training-btn--native",class:{
            disabled: _vm.isDisabled
          },attrs:{"disabled":_vm.isDisabled}},[_vm._v(" Senden ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12 col-md-6 px-10"},[_c('p',{staticStyle:{"color":"#830351"}},[_vm._v(" Glückwunsch zum bestandenen Trainingsmodul ")]),_c('p',[_vm._v(" Bitte tragen Sie nun Ihren Namen und Ihre EFN-Nummer für die Ärztekammer ein. ")])])
}]

export { render, staticRenderFns }