var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"thread-form",class:_vm.layoutComponent.class,style:(_vm.layoutComponent.style),attrs:{"id":_vm.layoutComponent.id,"data-component":_vm.componentType}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[(!_vm.requestSent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Betreff*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateTitle,
              'is-valid': _vm.attemptSubmit && _vm.validateTitle
            },attrs:{"id":"title","type":"text","required":""},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}})])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"body"}},[_vm._v("Ihre Nachricht*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.body),expression:"body"}],ref:"textarea",staticClass:"form-control",class:{
              'is-invalid': _vm.attemptSubmit && !_vm.validateBody,
              'is-valid': _vm.attemptSubmit && _vm.validateBody
            },attrs:{"id":"body","required":""},domProps:{"value":(_vm.body)},on:{"input":[function($event){if($event.target.composing)return;_vm.body=$event.target.value},function($event){return _vm.autoGrow()}]}})])])]):_vm._e(),(_vm.requestSent)?_c('div',{staticClass:"form-feedback"},[(_vm.apiError)?_c('div',{staticClass:"row submit-error"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.resultMessage)}}),_c('div',{staticClass:"divider small"})])]):_c('div',{staticClass:"row submit-success"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")]),_c('div',{staticClass:"divider small"})])])]):_vm._e(),_c('div',{staticClass:"controls d-md-flex text-center text-md-left"},[_c('div',{staticClass:"w-100 order-2 order-md-1"},[_c('a',{staticClass:"btn btn-primary mr-auto px-15 px-md-10",attrs:{"href":_vm.expertforumSlug}},[_vm._v(" Zurück zum Forum ")])]),(!_vm.requestSent)?_c('div',{staticClass:"w-100 order-1 order-md-2 text-md-right"},[(!_vm.isLoading)?_c('button',{staticClass:"btn btn-primary px-10"},[_c('b',[_vm._v("Frage abschicken")])]):_c('div',{staticClass:"waiting"},[_c('span',{staticClass:"spinner-border spinner-border-md mr-2"}),_c('span',[_vm._v("Bitte warten...")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }